import { DashboardActionType, IBaseCreateDashboardSliceParams, createDashboardSlice } from 'DashboardSlice/createDashboardSlice';
import {
  DataExtractionResult,
  DataExtractionResultFilters,
  DataExtractionResultSortingField,
  DataExtractionResultsSortingType,
} from '../../../api/DataExtractionResultApi';
import createDataExtractionResultsViewPagination, { DATA_EXTRACTION_RESULTS_PER_PAGE_DEFAULT } from './Pagination';
import { DataExtractionResultsViewActionType } from './DataExtractionResultsViewActionType';
import { getDataExtractionResults } from '../../../DataExtractionResults/Thunks';
import { DataExtractionResultsViewFiltersActionType } from './Filters/DataExtractionResultsViewFiltersActionType';
import createDataExtractionResultsFiltersSlice, {
  DataExtractionResultsViewFiltersState,
} from './Filters/DataExtractionResultsViewFiltersStore';
import { Reducer } from '@reduxjs/toolkit';

export interface DataExtractionResultsViewState {
  items: string[];
  sortingType: DataExtractionResultsSortingType;
}

const dataExtractionResultsViewActionOrigin = 'defaultDataExtractionResultsView';
const dataExtractionResultsViewStatePath = ['generalSettings', 'ui', 'dataExtractionResultsView'];

export const createDataExtractionResultsViewSlice = ({
  namespace,
  path,
  actionOrigins,
}: IBaseCreateDashboardSliceParams) => {
  const {
    actions: filtersActions,
    getInitialState: getFiltersInitialState,
    reducer: filtersReducer,
  } = createDataExtractionResultsFiltersSlice(namespace);

  const pagination = createDataExtractionResultsViewPagination(path);

  const actionTypes = {
    [DashboardActionType.ResetState]: DataExtractionResultsViewActionType.ResetState,
    [DashboardActionType.ChangeFilters]: DataExtractionResultsViewFiltersActionType.ChangeFilters,
    [DashboardActionType.ChangeSortingType]: DataExtractionResultsViewActionType.ChangeSortingType,
  };

  const defaultDataExtractionResultsViewSlice = createDashboardSlice<
    DataExtractionResult,
    DataExtractionResultFilters,
    DataExtractionResultsSortingType,
    DataExtractionResultsViewFiltersState,
    typeof actionTypes
  >({
    namespace,
    path,
    actionOrigins,
    itemsPerPage: DATA_EXTRACTION_RESULTS_PER_PAGE_DEFAULT,
    defaultSorting: { field: DataExtractionResultSortingField.FileName, ascending: true },
    getItems: getDataExtractionResults,
    actionTypes,
    filtersAndSortingProps: [
      'dataExtractionId',
      'fileName',
      'fileType',
      'pagesCount',
      'documentTypeName',
    ],
    filtersReducer: filtersReducer as Reducer<DataExtractionResultsViewFiltersState>,
    getFiltersInitialState,
    filtersActions,
  });

  const {
    actions,
    reducer,
    filtersAndSortingSaver,
    getInitialState,
  } = defaultDataExtractionResultsViewSlice;

  return {
    actions,
    reducer: pagination.wrapReducer(reducer),
    pagination,
    filtersAndSortingSaver,
    getInitialState,
    filtersActions,
  };
};

const dataExtractionResultsViewSlice = createDataExtractionResultsViewSlice({
  namespace: 'dataExtractionResultsView',
  path: dataExtractionResultsViewStatePath,
  actionOrigins: [dataExtractionResultsViewActionOrigin],
});

const {
  actions,
  reducer,
  pagination,
  filtersAndSortingSaver,
  getInitialState,
  filtersActions,
} = dataExtractionResultsViewSlice;

export {
  getInitialState,
  filtersAndSortingSaver,
  pagination,
  dataExtractionResultsViewActionOrigin,
  dataExtractionResultsViewStatePath,
};

export const {
  resetState,
  changeSortingType,
} = actions;

export const {
  changeFilters,
} = filtersActions;

export default reducer;
