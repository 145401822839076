import React, { FC, DetailedHTMLProps, HTMLAttributes, forwardRef, Ref } from 'react';
import clsx from 'clsx';

import styles from './Tag.module.scss';

type HTMLDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface TagCoreProps extends Omit<HTMLDivProps, 'ref'> {
  medium?: boolean;
}

const TagCore: FC<TagCoreProps> = forwardRef(({ className, medium, ...tagProps }, ref: Ref<HTMLDivElement>) => {
  const mergedClassName = clsx(className, medium && styles.mediumSize, styles.tag);

  return <div ref={ref} className={mergedClassName} {...tagProps} />;
});

export default TagCore;
