import { DashboardActionType, IBaseCreateDashboardSliceParams, createDashboardSlice } from 'DashboardSlice/createDashboardSlice';
import EndpointsDashboardActionType from './EndpointsDashboardActionType';
import createEndpointsDashboardPagination, { WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT } from './Pagination';
import { ItemsStateWithPagination } from 'pagination';
import { WebhookEndpoint, WebhookEndpointSortingField, WebhookEndpointSortingType } from 'api/WebhookEndpointsApi';
import { getWebhookEndpoints } from 'Webhooks/Endpoints/Thunks';
import createEndpointsDashboardFiltersSlice from './Filters/EndpointsDashboardFiltersStore';
import { BaseDashboardFiltersState } from 'DashboardSlice/createDashboardFiltersSlice';

export interface EndpointsDashboardState extends ItemsStateWithPagination<string> {
  sortingType: WebhookEndpointSortingType;
}

const endpointsDashboardActionOrigin = 'defaultEndpointsDashboard';
const endpointsDashboardStatePath = ['generalSettings', 'ui', 'webhooksDashboards', 'endpointsDashboard'];

export const createEndpointsDashboardSlice = ({
  namespace,
  path,
  actionOrigins,
}: IBaseCreateDashboardSliceParams) => {
  const {
    actions: filtersActions,
    getInitialState: getFiltersInitialState,
    reducer: filtersReducer,
  } = createEndpointsDashboardFiltersSlice(namespace);
  const pagination = createEndpointsDashboardPagination(path);

  const actionTypes = {
    [DashboardActionType.ResetState]: EndpointsDashboardActionType.ResetState,
    [DashboardActionType.ChangeSortingType]: EndpointsDashboardActionType.ChangeSortingType,
  };

  const defaultEndpointsDashboardSlice = createDashboardSlice<
    WebhookEndpoint,
    {},
    WebhookEndpointSortingType,
    BaseDashboardFiltersState,
    typeof actionTypes
  >({
    namespace,
    path,
    actionOrigins,
    itemsPerPage: WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT,
    defaultSorting: {field: WebhookEndpointSortingField.UpdatedAt, ascending: false},
    getItems: getWebhookEndpoints,
    actionTypes,
    filtersAndSortingProps: [
      'url',
      'description',
      'events',
      'status',
      'updatedAt',
    ],
    filtersReducer,
    getFiltersInitialState,
    filtersActions,
  });

  const {
    actions,
    reducer,
    filtersAndSortingSaver,
    getInitialState,
  } = defaultEndpointsDashboardSlice;

  return {
    actions,
    reducer: pagination.wrapReducer(reducer),
    pagination,
    filtersAndSortingSaver,
    getInitialState,
    filtersActions,
  }
};

const endpointsDashboardSlice = createEndpointsDashboardSlice({
  namespace: 'endpointsDashboard',
  path: endpointsDashboardStatePath,
  actionOrigins: [endpointsDashboardActionOrigin],
});

const {
  actions,
  reducer,
  pagination,
  filtersAndSortingSaver,
  getInitialState,
} = endpointsDashboardSlice;

export {
  getInitialState,
  filtersAndSortingSaver,
  pagination,
  endpointsDashboardActionOrigin,
  endpointsDashboardStatePath,
}

export const {
  resetState,
  changeSortingType,
} = actions;

export default reducer;
