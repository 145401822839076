import React, { FC, useState } from 'react';
import getAvatarUrl from 'api/utils/getAvatarUrl';
import { User, UserStatus } from 'api/UserApi';
import { TableColumnSize } from 'components/Users/Dashboards/UsersDashboard/TableColumnSize';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import UserStatusTag from 'components/UserStatusTag/UserStatusTag';
import UserAvatar from 'components/UserAvatar';
import SearchHighlight from 'components/SearchHighlight';
import { TableBodyCellWithHighlight } from 'components/Table';
import { capitalize } from 'lodash';

import styles from './UserRow.module.scss';

interface UserRowProps {
  user: User;
  onEditUser: (user: User) => void;
  onCancelInvitation: (user: User) => void;
  onUpdateUserStatus: (user: User, status: UserStatus) => Promise<void>;
  isCurrentUser: boolean;
  getEditUserPageLink: (user: User) => string;
  search?: string;
}

const UserRow: FC<UserRowProps> = ({
  user,
  onEditUser,
  onUpdateUserStatus,
  onCancelInvitation,
  isCurrentUser,
  getEditUserPageLink,
  search,
}) => {
  const [
    statusUpdateInProgress,
    setStatusUpdateInProgress,
  ] = useState(false);

  const handleChangeUserStatus = async () => {
    if (user.status === UserStatus.Pending) {
      return;
    }

    setStatusUpdateInProgress(true);

    const statusToSet = user.status === UserStatus.Active
      ? UserStatus.Inactive
      : UserStatus.Active;

      try {
        await onUpdateUserStatus(user, statusToSet);
      } finally {
        setStatusUpdateInProgress(false);
      }
  };

  const handleTableRowClick = () => {
    if (statusUpdateInProgress) {
      return;
    }

    onEditUser(user);
  };

  return (
    <RowActionsContainer>
      <TableRow
        disabled={statusUpdateInProgress}
        link={getEditUserPageLink(user)}
        onClick={handleTableRowClick}
      >
        <TableBodyCell width={TableColumnSize.FirstName} noPadding>
          <UserAvatar
            size="small"
            className={styles.userAvatar}
            firstName={user.firstName || ''}
            lastName={user.lastName || ''}
            imageUrl={getAvatarUrl(user.avatarId)}
            color={user.color}
          />
          <SearchHighlight search={search || ''}>
            {user.firstName ? user.firstName : '—'}
          </SearchHighlight>
        </TableBodyCell>
        <TableBodyCellWithHighlight overflowed search={search} width={TableColumnSize.LastName}>
          {user.lastName ? user.lastName : '—'}
        </TableBodyCellWithHighlight>
        <TableBodyCellWithHighlight overflowed search={search} width={TableColumnSize.Email}>
          {user.email}
        </TableBodyCellWithHighlight>
        <TableBodyCell overflowed width={TableColumnSize.PermissionGroup}>
          {capitalize(user.permissionGroup)}
        </TableBodyCell>
        <TableBodyCell overflowed width={TableColumnSize.PhoneAuthentication}>
          <p>{user.isMfaEnabled ? 'Enabled' : 'Disabled'}</p>
        </TableBodyCell>
        <TableBodyCell width={TableColumnSize.AccountStatus}>
          <UserStatusTag status={user.status} />
        </TableBodyCell>
        <RowActions
          actions={[
            {
              title: 'Edit User',
              handler: () => onEditUser(user),
            },
            ...(user.status !== UserStatus.Pending ? [{
              danger: user.status !== UserStatus.Inactive,
              handler: () => handleChangeUserStatus(),
              title: user.status === UserStatus.Inactive ? 'Activate Account' : 'Deactivate Account',
              disabled: isCurrentUser,
              separatorBeforeRequired: true,
            }] : []),
            ...(user.status === UserStatus.Pending ? [{
              danger: true,
              handler: () => onCancelInvitation(user),
              title: 'Cancel Invitation',
              disabled: isCurrentUser,
              separatorBeforeRequired: true,
            }] : []),
          ]}
          isLoading={statusUpdateInProgress}
        />
      </TableRow>
    </RowActionsContainer>
  );
};

export default UserRow;
