import { PublicApiWhitelist } from 'api/PublicApiWhitelistApi';
import getAvatarUrl from 'api/utils/getAvatarUrl';
import IpAddressTableColumnSizes from 'components/IpAddressWhitelist/IpAddressTable/IpAddressTableColumnSizes';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { SkeletonRectangle, SkeletonCircle } from 'components/Skeleton';
import Table, { TableBodyCell, TableHeadCell, TableActionCell, TableRow, TableHead, TableBody } from 'components/Table';
import TableStub from 'components/TableStub';
import UserAvatar from 'components/UserAvatar';
import React, { FC, ReactElement } from 'react';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import styles from './IpAddressTable.module.scss';

interface IpAddressTableProps {
  isDisabled: boolean;
  disableTooltip: ReactElement | null;
  ipAddresses?: PublicApiWhitelist[];
  onDelete: (id: string) => void;
  onSort: (field: keyof PublicApiWhitelist) => () => void;
  getSortingType: (field: keyof PublicApiWhitelist) => boolean | undefined;
}

const SKELETON_ROWS = Array.from({ length: 10 }).map(() => null);

const IpAddressTable: FC<IpAddressTableProps> = ({
  disableTooltip,
  ipAddresses,
  isDisabled,
  onDelete,
  getSortingType,
  onSort,
}) => {
  const members = useOrganizationMembers({ includeInactive: true });

  const tableColumns = [
    { title: 'IP Address', width: IpAddressTableColumnSizes.Ip },
    { title: 'Added', width: IpAddressTableColumnSizes.Added, onClick: onSort('createdAt'), ascending: getSortingType('createdAt') },
    { title: 'Last Used', width: IpAddressTableColumnSizes.LastUsed },
    { title: 'Description', width: IpAddressTableColumnSizes.Description },
  ];

  if (ipAddresses?.length === 0) {
    return (
      <TableStub
        columns={tableColumns}
        description="Your account's API is currently open to all traffic. Whitelist IP addresses to increase security."
        rows={5}
      />
    );
  }

  const renderRow = (whitelist: PublicApiWhitelist | null, index: number) => {
    const isLoading = !whitelist;
    const createdBy = members.find(({ id }) => whitelist?.createdBy === id);

    return (
      <TableRow disableHover useActions key={whitelist?.id || index}>
        <TableBodyCell className={styles.cell} width={IpAddressTableColumnSizes.Ip}>
          {!isLoading && whitelist.ip}
          {isLoading && <SkeletonRectangle width="140px" height="16px" color="primary6" />}
        </TableBodyCell>
        <TableBodyCell className={styles.cell} width={IpAddressTableColumnSizes.Added}>
          {!isLoading && (
            <>
              <UserAvatar
                color={createdBy?.color}
                imageUrl={getAvatarUrl(createdBy?.avatarId)}
                className={styles.avatar}
                firstName={createdBy?.firstName || ''}
                lastName={createdBy?.lastName || ''}
                size={10}
                tooltip
              />
              <div>{formatDate(whitelist.createdAt, DateTimeFormat.Long)}</div>
            </>
          )}
          {isLoading && <SkeletonCircle width="28px" height="28px" color="primary6" marginRight="8px" />}
          {isLoading && <SkeletonRectangle width="120px" height="16px" color="primary6" />}
        </TableBodyCell>
        <TableBodyCell className={styles.cell} width={IpAddressTableColumnSizes.LastUsed}>
          {(!isLoading && !whitelist.lastUsedAt) && <TableBodyCellNoContent />}
          {(!isLoading && whitelist.lastUsedAt) && formatDate(whitelist.lastUsedAt, DateTimeFormat.Long)}
          {isLoading && <SkeletonRectangle width="120px" height="16px" color="primary6" />}
        </TableBodyCell>
        <TableBodyCell className={styles.cell} width={IpAddressTableColumnSizes.Description}>
          {!isLoading && whitelist.description}
          {isLoading && <SkeletonRectangle width="280px" height="16px" color="primary6" />}
        </TableBodyCell>
        <RowActionsContainer>
          <RowActions
            actions={[
              {
                title: 'Delete IP Address',
                danger: true,
                handler: () => onDelete(whitelist!.id),
                disabled: isDisabled || isLoading,
                tooltip: disableTooltip,
              },
            ]}
          />
        </RowActionsContainer>
      </TableRow>
    );
  };

  return (
    <Table>
      <TableHead>
        {tableColumns.map(({ title, width, ascending, onClick }) => (
          <TableHeadCell width={width} key={title} onClick={onClick} ascending={ascending}>{title}</TableHeadCell>
        ))}
        <TableActionCell />
      </TableHead>
      <TableBody>
        {(ipAddresses || SKELETON_ROWS).map(renderRow)}
      </TableBody>
    </Table>
  );
};

export default IpAddressTable;
