import { AnyAction } from 'redux';

import { LeftNavState } from './LeftNavTypes';

import { LEFT_NAV_TOGGLE_EXPAND } from './ActionTypes';

const getInitialState: () => LeftNavState = () => ({
  isLeftNavOpen: Boolean(localStorage.getItem('isLeftNavOpen')),
});

const LeftNavReducer = (state: LeftNavState = getInitialState(), action: AnyAction) => {
  switch (action.type) {
    case LEFT_NAV_TOGGLE_EXPAND:
      return { ...state, isLeftNavOpen: action.payload };
    default:
      return state;
  }
};

export default LeftNavReducer;
