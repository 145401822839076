import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import WithExternalProviderConfirmActionPopupContent from 'components/WithIdentityConfirmationActionPopup/WithExternalProviderConfirmActionPopupContent';
import WithPasswordIdentityConfirmActionPopupContent from 'components/WithIdentityConfirmationActionPopup/WithPasswordIdentityConfirmActionPopupContent';
import PopUpContent from 'components/PopUps/PopUpContent';
import PopUp from 'components/PopUps/PopUp';
import { UserAuthProvider } from 'api/UserApi';

export interface WithIdentityConfirmActionPopupProps {
  actionName: string;
  onClose: () => void;
  onConfirmationComplete: () => Promise<void>;
  children?: React.ReactNode;
  showChildren?: boolean;
}

const WithIdentityConfirmActionPopup: FC<WithIdentityConfirmActionPopupProps> = ({
  onClose,
  onConfirmationComplete,
  actionName,
  children,
  showChildren,
}) => {
  const currentUser = useSelector((state: ReduxState) => state.users.currentUser);

  if (!currentUser) {
    return null;
  }

  const renderContent = () => {
    if (showChildren) {
      return (
        <>
          {children}
        </>
      );
    }

    if (currentUser.authProvider !== UserAuthProvider.Synthetiq) {
      return (
        <WithExternalProviderConfirmActionPopupContent
          onSubmit={onConfirmationComplete}
          actionName={actionName}
          currentUser={currentUser}
        />
      );
    }

    return (
      <WithPasswordIdentityConfirmActionPopupContent
        onSubmit={onConfirmationComplete}
        actionName={actionName}
        currentUser={currentUser}
      />
    );
  };

  return (
    <PopUp title={actionName} closePopUp={onClose}>
      <PopUpContent>
        {renderContent()}
      </PopUpContent>
    </PopUp>
  );
};

export default WithIdentityConfirmActionPopup;
