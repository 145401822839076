import useRouteBuilder from './useRouteBuilder';

const DEFAULT_QUERY_PARAM = 'edit';

const useActiveTableRow = (entityId: string, queryParam: string | string[] = DEFAULT_QUERY_PARAM): boolean => {
  const routeBuilder = useRouteBuilder();

  const params = Array.isArray(queryParam) ? queryParam : [queryParam];

  return params.some((param) => routeBuilder.currentQueryParams.get(param) === entityId);
};

export default useActiveTableRow;
