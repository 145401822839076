import { createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import { DataExtraction } from 'api/DataExtractionApi';
import { DataExtractionActionType } from './DataExtractionsActionType';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import { createBatchDataExtraction, createDataExtraction, getDataExtractions } from './Thunks';

export interface DataExtractionsState {
  dataExtractionsById: Record<string, DataExtraction>
}

export const getInitialState = (): DataExtractionsState => ({
  dataExtractionsById: {},
});

export const setDataExtractions = createAction<DataExtraction[]>(DataExtractionActionType.SetDataExtractions);

const dataExtractionsReducer = createReducer<DataExtractionsState>(getInitialState(), (builder) => {
  builder.addCase(setDataExtractions, (state, action) => {
    state.dataExtractionsById = {
      ...state.dataExtractionsById,
      ...normalizeEntityArray(action.payload),
    };
  });

  builder.addCase(getDataExtractions.fulfilled, (state, action) => {
    state.dataExtractionsById = {
      ...state.dataExtractionsById,
      ...normalizeEntityArray(action.payload.items),
    };
  });

  builder.addMatcher(isAnyOf(
    createDataExtraction.fulfilled,
    createBatchDataExtraction.fulfilled,
  ), (state, action) => {
    state.dataExtractionsById = {
      ...state.dataExtractionsById,
      [action.payload.id]: action.payload,
    };
  });
});

export default dataExtractionsReducer;
