import React, { FC, useState } from 'react';
import { formatPhoneNumber } from 'components/PhoneInput';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import notification from 'handlers/notification/notificationActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { confirmPassword, sendChangePhoneNumberCode, verifyDeletePhoneCode } from 'MyAccount/ActionCreator';
import ConfirmPasswordStep from 'components/VerificationSteps/ConfirmPasswordStep';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import VerifyCodeComponent from 'components/VerifyCode';

enum DisablePhoneVerificationPopupStep {
  ConfirmPassword = 'ConfirmPassword',
  VerifyCode = 'VerifyCode'
}

interface DisablePhoneVerificationPopupProps {
  phone: string;
  onClose: () => void;
}

const DisablePhoneVerificationPopup: FC<DisablePhoneVerificationPopupProps> = ({ phone, onClose }) => {
  const dispatch = useDispatchWithUnwrap();

  const [step, setStep] = useState(DisablePhoneVerificationPopupStep.ConfirmPassword);

  const handleConfirmPassword = async (password: string) => {
    if (password) {
      await dispatch(confirmPassword(password));
      await dispatch(sendChangePhoneNumberCode(undefined));

      setStep(DisablePhoneVerificationPopupStep.VerifyCode);
    }
  };

  const handleResendCode = async () => {
    await dispatch(sendChangePhoneNumberCode(undefined));
  };

  const [
    isCodeVerificationInProgress,
    handleVerifyCode,
  ] = useAsyncActionCallback(async (code: string) => {
    if (code) {
      await dispatch(verifyDeletePhoneCode(code));

      notification.createNotification(`The phone number ${formatPhoneNumber(phone)} has been removed from your account`, 'success', dispatch);

      onClose();
    }
  }, [onClose, phone]);

  return (
    <PopUp title="Phone Setup & Verification" closePopUp={onClose}>
      <PopUpContent>
        {step === DisablePhoneVerificationPopupStep.ConfirmPassword && (
          <ConfirmPasswordStep
            onContinue={handleConfirmPassword}
          />
        )}
        {step === DisablePhoneVerificationPopupStep.VerifyCode && (
          <VerifyCodeComponent
            onSubmit={handleVerifyCode}
            buttonText="Disable Phone"
            message={`Please enter the code sent to ${formatPhoneNumber(phone)}`}
            onResendCode={() => handleResendCode()}
            loading={isCodeVerificationInProgress}
          />
        )}
      </PopUpContent>
    </PopUp>
  );
};

export default DisablePhoneVerificationPopup;
