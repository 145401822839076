import { ChangeEvent, FC } from 'react';
import { DocumentTypeFieldViewDataType } from 'enums/DocumentTypeFieldViewDataType';
import { DocumentTypeFieldChildrenViewData } from 'components/DocumentType/Forms/DocumentTypeFieldForm/DocumentTypeFieldForm';
import TextInput from 'components/TextInput';
import AutoCompletion from 'components/AutoCompletion';
import LinkButton from 'components/LinkButton';
import clsx from 'clsx';
import { optionDescriptionByViewDataType } from 'components/DocumentType/documentTypeUtils';

import styles from './DocumentTypeFieldChildrenFormRow.module.scss';

interface DocumentTypeFieldChildrenFormRowProps {
  field: DocumentTypeFieldChildrenViewData;
  position: number;
  isOnlyChild: boolean;
  onNameChange: (name: string, position: number) => void;
  onDataTypeChange: (dataType: DocumentTypeFieldViewDataType, position: number) => void;
  onRemoveChildren: (position: number) => void;
}

const CHILDREN_SELECT_OPTIONS = [
  DocumentTypeFieldViewDataType.String,
  DocumentTypeFieldViewDataType.Number,
  DocumentTypeFieldViewDataType.Boolean,
  DocumentTypeFieldViewDataType.Date,
];

const selectOptions = CHILDREN_SELECT_OPTIONS.map((dataType) => ({
  value: dataType,
  name: dataType,
  description: optionDescriptionByViewDataType[dataType],
}));

const DocumentTypeFieldChildrenFormRow: FC<DocumentTypeFieldChildrenFormRowProps> = ({
  field,
  position,
  isOnlyChild,
  onNameChange,
  onDataTypeChange,
  onRemoveChildren,
}) => {
  return (
    <div className={styles.rowContainer}>
      <div className={styles.inputsContainer}>
        <TextInput
          required
          labelTitle={`Object Field ${position + 1}`}
          containerClassName={styles.textInputContainer}
          value={field.name}
          placeholder='Field Name'
          error={!!field.error}
          hasRightNeighbour
          onChange={(event: ChangeEvent<HTMLInputElement>) => onNameChange(event.target.value, position)}
        />
        <AutoCompletion
          options={selectOptions}
          className={styles.selectContainer}
          placeholder='Data Type'
          hasLeftNeighbour
          hasError={!!field.error}
          value={field.dataType}
          onChange={(option) => onDataTypeChange(option.value as DocumentTypeFieldViewDataType, position)}
          clearIconClassName={styles.clearIcon}
        />
      </div>
      <LinkButton
        className={clsx(styles.removeButton, isOnlyChild && styles.disabled)}
        disabled={isOnlyChild}
        tooltip={isOnlyChild && 'Cannot remove the only field'}
        onClick={() => onRemoveChildren(position)}
      >
        Remove
      </LinkButton>
      {field.error && <p className={styles.errorMessage}>{field.error}</p>}
    </div>
  )
};

export default DocumentTypeFieldChildrenFormRow;
