import React, { FC } from 'react';
import Button, { ButtonProps } from 'components/Button/Button';
import { ShareImageCustomColor } from 'static/images';
import clsx from 'clsx';

import styles from './ShareButton.module.scss';

const ShareButton: FC<ButtonProps> = ({ children, className, kind = 'secondary', ...buttonProps }) => {
  const colorClassName = clsx(kind === 'primary' && styles.primary, kind === 'secondary' && styles.secondary);

  return (
    <Button kind={kind} className={clsx(styles.button, colorClassName, className)} {...buttonProps}>
      <ShareImageCustomColor />
      {children}
    </Button>
  );
};

export default ShareButton;
