import React, { forwardRef, ReactNode } from 'react';
import FocusLock from 'react-focus-lock';

import styles from 'components/MainLayout/MainLayout.module.scss';

interface ContextualViewWrapperProps {
  children: ReactNode;
}

const ContextualViewWrapper = forwardRef<HTMLDivElement | null, ContextualViewWrapperProps>(
  ({ children }: ContextualViewWrapperProps, ref) => {
    return (
      <FocusLock autoFocus={false}>
        <div ref={ref} className={styles.contextualViewWrapper}>
          {children}
        </div>
      </FocusLock>
    );
  },
);

export default ContextualViewWrapper;
