import React, { FC } from 'react';
import formatDate from 'utils/dateFormat';
import StatisticBlock from 'components/StatisticBlock/StatisticBlock';
import formatMonetaryValue from 'utils/formatMonetaryValue';

interface StatisticsProps {
  amountOwed: number;
  date: Date | null;
  currency: string;
}

const Statistics: FC<StatisticsProps> = ({ amountOwed, date, currency }) => {
  const formattedOwedAmount = currency && formatMonetaryValue(amountOwed, {
    currency,
    alwaysShowFractionalPart: true,
  });

  return (
    <StatisticBlock
      title="Amount Owed"
      value={formattedOwedAmount}
      subTitle={date === null ? null : `as of ${formatDate(date)}`}
    />
  );
};

export default Statistics;
