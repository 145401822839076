import React, { FC, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import notification from 'handlers/notification/notificationActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { confirmPassword, sendChangeEmailCode, verifyChangeEmailCode } from 'MyAccount/ActionCreator';
import ConfirmPasswordStep from 'components/VerificationSteps/ConfirmPasswordStep';
import ProvideEmailStep from 'components/VerificationSteps/ProvideEmailStep';
import VerifyCodeComponent from 'components/VerifyCode';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';

enum ChangeEmailPopupStep {
  ConfirmPassword = 'ConfirmPassword',
  EnterEmail = 'EnterEmail',
  VerifyCode = 'VerifyCode',
}

export interface ChangeEmailPopupProps {
  onClose: () => void;
}

const ChangeEmailPopup: FC<ChangeEmailPopupProps> = ({ onClose }) => {
  const dispatch = useDispatchWithUnwrap();

  const [
    step,
    setStep,
  ] = useState(ChangeEmailPopupStep.ConfirmPassword);
  const [email, setEmail] = useState('');

  const handleConfirmPassword = async (password: string) => {
    if (password) {
      await dispatch(confirmPassword(password));

      setStep(ChangeEmailPopupStep.EnterEmail);
    }
  };

  const [
    isCodeSendingInProgress,
    handleSendCode,
  ] = useAsyncActionCallback(async (newEmail: string) => {
    if (newEmail) {
      setEmail(newEmail);

      await dispatch(sendChangeEmailCode(newEmail));

      setStep(ChangeEmailPopupStep.VerifyCode);
    }
  }, []);

  const [
    isCodeVerificationInProgress,
    handleVerifyCode,
  ] = useAsyncActionCallback(async (code: string) => {
    if (code) {
      await dispatch(verifyChangeEmailCode(code));

      notification.createNotification(
        `Your email address has been updated to ${email}`,
        'success',
        dispatch,
      );

      onClose();
    }
  }, [onClose, email]);

  return (
    <PopUp title="Email Setup & Verification" closePopUp={onClose}>
      <PopUpContent>
        {step === ChangeEmailPopupStep.ConfirmPassword && (
          <ConfirmPasswordStep
            onContinue={handleConfirmPassword}
          />
        )}
        {step === ChangeEmailPopupStep.EnterEmail && (
          <ProvideEmailStep
            initialEmail=""
            buttonText="Continue"
            onSubmit={handleSendCode}
            loading={isCodeSendingInProgress}
          />
        )}
        {step === ChangeEmailPopupStep.VerifyCode && (
          <VerifyCodeComponent
            onSubmit={handleVerifyCode}
            buttonText="Verify Email"
            message={`A verification email has been sent to ${email}. 
             Please check your junk folder if you don't find it in your inbox.`}
            onResendCode={() => handleSendCode(email)}
            loading={isCodeVerificationInProgress}
          />
        )}
      </PopUpContent>
    </PopUp>
  );
};

export default ChangeEmailPopup;
