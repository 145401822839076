import { FC } from 'react';
import styles from './DotFallingLoader.module.scss';

interface DotFallingLoaderProps {
  className?: string;
}

const DotFallingLoader: FC<DotFallingLoaderProps> = ({
  className,
}) => {
  return (
    <div className={className}>
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className={styles.dot} />
      ))}
    </div>
  );
};

export default DotFallingLoader;
