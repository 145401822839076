import { FC } from 'react'
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { TableBodyCell, TableBodyCellWithHighlight, TableRow } from 'components/Table';
import { TableColumnSize } from 'components/ExtractionHistory/Tables/DataExtractionsTable/TableColumnSize';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import { DataExtraction, DataExtractionStatus } from 'api/DataExtractionApi';
import { User } from 'api/UserApi';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import DotFallingLoader from 'components/DotFallingLoader';
import DocumentTypeLabel from 'components/DocumentTypeLabel/DocumentTypeLabel';
import DataExtractionStatusTag from 'components/DataExtractionStatusTag';
import styles from './DataExtractionDesktopRow.module.scss';
import getFormattedDurationTime from '../../../../../../DataExtractions/getFormattedDurationTime';
import { sum } from 'lodash';
import { ExportFileType } from '../../../../../../api/DataExtractionExportApi';
import useActiveTableRow from '../../../../../../hooks/useActiveTableRow';
import {
  DataExtractionQueryAttribute,
} from '../../../../../../GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryQueryAttribute';

interface DataExtractionDesktopRowProps {
  className?: string;
  dataExtraction: DataExtraction;
  user?: User;
  search?: string;
  onViewDataExtraction: (dataExtraction: DataExtraction) => void;
  onDeleteDataExtraction: (dataExtractionId: string) => void;
  onExportDataExtraction: (dataExtractionId: string, exportFileType: ExportFileType) => void;
}

const DataExtractionDesktopRow: FC<DataExtractionDesktopRowProps> = ({
  className,
  dataExtraction,
  user,
  search,
  onViewDataExtraction,
  onDeleteDataExtraction,
  onExportDataExtraction,
}) => {
  const isActive = useActiveTableRow(
    dataExtraction.id,
    DataExtractionQueryAttribute.ExtractionIdQueryAttribute,
  );

  const handleTableRowClick = () => {
    onViewDataExtraction(dataExtraction);
  };

  const itemsInProcessingStatusCount = dataExtraction.statusSummary?.[DataExtractionStatus.Processing]
    ? dataExtraction.statusSummary[DataExtractionStatus.Processing]
    : 0;

  const isProcessingInProgress = itemsInProcessingStatusCount > 0;

  const rowActions = [
    {
      title: 'View Results',
      handler: () => onViewDataExtraction(dataExtraction),
    },
    {
      title: 'Export Results to CSV',
      handler: () => onExportDataExtraction(dataExtraction.id, ExportFileType.Csv),
      disabled: isProcessingInProgress,
    },
    {
      title: 'Export Results to JSON',
      handler: () => onExportDataExtraction(dataExtraction.id, ExportFileType.Json),
      disabled: isProcessingInProgress,
    },
    {
      danger: true,
      handler: () => onDeleteDataExtraction(dataExtraction.id),
      title: 'Delete Results',
      separatorBeforeRequired: true,
      disabled: isProcessingInProgress,
    },
  ]

  const durationTime = getFormattedDurationTime(dataExtraction.totalDurationTime) || '—';

  const renderLoadingState = () => {
    return (
      <DotFallingLoader />
    )
  };

  const isBatchProcessingRun = sum(Object.values(dataExtraction.statusSummary)) > 1;
  const statusSummaryItems = Object.entries(dataExtraction.statusSummary);

  return (
    <RowActionsContainer>
      <TableRow
        selected={isActive}
        onClick={handleTableRowClick}
        className={className}
      >
        <TableBodyCell width={TableColumnSize.Date} noPadding>
          <WithSystemApiUserAvatar
            size="small"
            className={styles.userAvatar}
            user={user}
          />
          <div>{formatDate(dataExtraction.createdAt, DateTimeFormat.Long)}</div>
        </TableBodyCell>
        <TableBodyCellWithHighlight overflowed search={search} width={TableColumnSize.FileName}>
          {dataExtraction.fileNames?.length === 1 ? dataExtraction.fileNames[0] : 'Multiple Files'}
        </TableBodyCellWithHighlight>
        <TableBodyCell width={TableColumnSize.FileType}>
          {dataExtraction.fileTypes?.length === 1 ? dataExtraction.fileTypes[0].toUpperCase() : null}
        </TableBodyCell>
        <TableBodyCell width={TableColumnSize.DocumentType}>
          <DocumentTypeLabel
            isProcessing={!!dataExtraction.statusSummary?.[DataExtractionStatus.Processing]}
            isAutoDetected={dataExtraction.isAutoDetected}
            documentType={dataExtraction.documentTypeNames?.[0]}
          />
        </TableBodyCell>
        <TableBodyCell width={TableColumnSize.Pages}>
          {isProcessingInProgress ? renderLoadingState() : dataExtraction.pagesCount}
        </TableBodyCell>
        <TableBodyCell width={TableColumnSize.ProcessingTime}>
          {isProcessingInProgress ? renderLoadingState() : durationTime}
        </TableBodyCell>
        <TableBodyCell width={TableColumnSize.Result}>
          {statusSummaryItems.map(([status, value]) => {
            return <DataExtractionStatusTag
              status={status as DataExtractionStatus}
              count={isBatchProcessingRun ? value as number : undefined}
            />
          })}
        </TableBodyCell>
        <RowActionsContainer>
          <RowActions
            actions={rowActions}
          />
        </RowActionsContainer>
      </TableRow>
    </RowActionsContainer>
  )
}

export default DataExtractionDesktopRow;
