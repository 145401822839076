import React, { FC } from 'react';
import MyAccountOverlays from 'pages/Main/MyAccountOverlays';

const GlobalOverlays: FC = () => {
  return (
    <>
      <MyAccountOverlays />
    </>
  );
};

export default GlobalOverlays;
