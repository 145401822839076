import { NoItems } from 'static/images';
import styles from './NoPreview.module.scss';

const NoPreview = () => {
  return (
    <div className={styles.container}>
      <NoItems />
      <p>No preview is available.</p>
    </div>
  )
}

export default NoPreview;
