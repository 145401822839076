import React, { FC, PropsWithChildren } from 'react';
import ExternalSourceButton from 'components/ExternalSourceButton';
import { ButtonProps } from '../../Button/Button';
import clsx from 'clsx';

import styles from './AuthExternalSourceButton.module.scss';

const AuthExternalSourceButton: FC<PropsWithChildren<ButtonProps & { externalLink: string }>> = ({
  className,
  ...props
}) => {
  return <ExternalSourceButton
    className={clsx(styles.button, className)}
    kind="primary"
    {...props}
  />;
};

export default AuthExternalSourceButton;
