export enum DocumentTypeFieldViewDataType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
  Object = 'Object',
  ArrayOfStrings = 'Array of Strings',
  ArrayOfNumbers = 'Array of Numbers',
  ArrayOfBooleans = 'Array of Booleans',
  ArrayOfDates = 'Array of Dates',
  ArrayOfObjects = 'Array of Objects',
}
