import { TouchList, WheelEvent, useCallback, useRef } from 'react';

interface Point {
  x: number;
  y: number;
}

interface MouseOrTouchEvent {
  currentTarget: HTMLDivElement;
  clientX?: number;
  clientY?: number;
  touches?: TouchList;
}

const useSmartScrolling = () => {
  let startPoint = useRef<Point | null>(null);

  const onWheel = useCallback((event: WheelEvent<HTMLDivElement>) => {
    event.currentTarget.scrollLeft += event.deltaX;
    event.currentTarget.scrollTop += event.deltaY;
  }, []);

  const onMouseDown = useCallback((event: MouseOrTouchEvent) => {
    startPoint.current = {
      x: event.clientX || event.touches?.item(0)?.clientX || 0,
      y: event.clientY || event.touches?.item(0)?.clientY || 0,
    };

    event.currentTarget.style.cursor = 'grabbing';
  }, []);

  const onMouseUpOrLeave = useCallback((event: MouseOrTouchEvent) => {
    startPoint.current = null;

    event.currentTarget.style.cursor = '';
  }, []);

  const onMouseMove = useCallback((event: MouseOrTouchEvent) => {
    if (startPoint.current) {
      const x = event.clientX || event.touches?.item(0)?.clientX || 0;
      const y = event.clientY || event.touches?.item(0)?.clientY || 0;

      event.currentTarget.scrollLeft += startPoint.current.x - x;
      event.currentTarget.scrollTop += startPoint.current.y - y;

      startPoint.current = {
        x,
        y,
      };
    }
  }, []);

  return {
    onWheel,
    onMouseDown,
    onMouseUp: onMouseUpOrLeave,
    onMouseLeave: onMouseUpOrLeave,
    onMouseMove,
    onTouchStart: onMouseDown,
    onTouchEnd: onMouseUpOrLeave,
    onTouchCancel: onMouseUpOrLeave,
    onTouchMove: onMouseMove,
  };
};

export default useSmartScrolling;
