import usersDashboardReducer, {
  getInitialState as getUsersDashboardInitialState,
  UsersDashboardState,
} from './UsersDashboard/UsersDashboardStore';
import documentTypesDashboardReducer, {
  getInitialState as getDocumentTypesDashboardInitialState,
  DocumentTypesDashboardState,
} from './DocumentTypesDashboard/DocumentTypesDashboardStore';
import { createReducer } from '@reduxjs/toolkit';
import mountChildReducers from 'utils/childReducers';
import dataExtractionsDashboardReducer, {
  getInitialState as getExtractionHistoryDashboardInitialState,
  ExtractionHistoryDashboardState,
} from './ExtractionHistoryDashboard/ExtractionHistoryDashboardStore';
import dataExtractionResultsViewReducer, {
  getInitialState as getDataExtractionResultsViewReducerInitialState,
  DataExtractionResultsViewState,
} from './DataExtractionResultsView/DataExtractionResultsViewStore';
import webhooksDashboardsReducer, {
  getInitialState as getWebhooksDashboardsInitialState,
  WebhooksDashboardsState,
}  from './WebhooksDashboards/WebhooksDashboardsStore';

export interface GeneralSettingsUIState {
  usersDashboard: UsersDashboardState;
  documentTypesDashboard: DocumentTypesDashboardState;
  extractionHistoryDashboard: ExtractionHistoryDashboardState,
  webhooksDashboards: WebhooksDashboardsState,
  dataExtractionResultsView: DataExtractionResultsViewState,
}

export const getInitialState = (): GeneralSettingsUIState => ({
  usersDashboard: getUsersDashboardInitialState(),
  documentTypesDashboard: getDocumentTypesDashboardInitialState(),
  extractionHistoryDashboard: getExtractionHistoryDashboardInitialState(),
  dataExtractionResultsView: getDataExtractionResultsViewReducerInitialState(),
  webhooksDashboards: getWebhooksDashboardsInitialState(),
});

const generalSettingsUiReducer = createReducer<
  GeneralSettingsUIState
>(getInitialState(), () => {});

const childReducers = {
  usersDashboard: usersDashboardReducer,
  documentTypesDashboard: documentTypesDashboardReducer,
  extractionHistoryDashboard: dataExtractionsDashboardReducer,
  dataExtractionResultsView: dataExtractionResultsViewReducer,
  webhooksDashboards: webhooksDashboardsReducer,
};

export default mountChildReducers(generalSettingsUiReducer, childReducers);
