import React, { useState, FC, ChangeEvent } from 'react';
import { OrganizationShortInfo } from 'api/OrganizationApi';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import validateCompanyInformation, {
  OrganizationInformationValidationResult,
} from 'components/BillingDashboard/Overview/EditCompanyInformationPopup/validateCompanyInformation';
import styles from './EditCompanyInformationPopup.module.scss';

interface EditCompanyInformationPopupProps {
  companyInfo: OrganizationShortInfo;
  onSaveChanges: (updatedCompanyInfo: OrganizationShortInfo) => void;
  onClose: () => void;
  isEditInProgress: boolean;
  usePortal?: boolean;
}

const EditCompanyInformationPopup: FC<EditCompanyInformationPopupProps> = ({
  companyInfo,
  onSaveChanges,
  onClose,
  isEditInProgress,
  usePortal,
}) => {
  const [name, setName] = useState(companyInfo.name ?? '');

  const [errors, setErrors] = useState<OrganizationInformationValidationResult>({});

  const handleChange = (setValue: (value: string) => void, key: keyof OrganizationInformationValidationResult) => (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(event.target.value);

    setErrors({ ...errors, [key]: '' });
  };

  const handleBlur = (key: keyof OrganizationInformationValidationResult, value: string) => () => {
    const validationResult = validateCompanyInformation({ [key]: value });
    setErrors({ ...errors, [key]: validationResult[key] });
  };

  const isValid = () => {
    return Object.keys(validateCompanyInformation({ name })).length === 0;
  };

  const isChanged = () => {
    return name !== companyInfo.name;
  };

  const handleSaveChanges = () => {
    if (isValid()) {
      onSaveChanges({ name });
    }
  };

  return (
    <PopUp usePortal={usePortal} title="Edit Company Information" closePopUp={onClose}>
      <PopUpContent>
        <TextInput
          type="text"
          labelTitle="Company Name"
          value={name}
          onChange={handleChange(setName, 'name')}
          onBlur={handleBlur('name', name)}
          errorMessage={errors.name}
          required
        />

        <Button
          className={styles.saveChangesButton}
          kind="primary"
          size="form"
          onClick={handleSaveChanges}
          disabled={!isValid() || !isChanged()}
          isLoading={isEditInProgress}
        >
          Save Changes
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default EditCompanyInformationPopup;
