import { getLoaderState } from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/BatchFiles/BatchFiles';
import {
  IFileState,
  validateFileState,
} from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/validateFileState';
import { LoaderState } from 'components/LoaderWithState';
import Table, { TableHead, TableHeadCell } from 'components/Table';
import React, { FC } from 'react';
import { DataExtractionMode } from 'api/DataExtractionApi';
import FileTableRow from './FileTableRow';
import { TableColumnSizes } from './TableColumnSizes';
import { IExtendedFileState } from './FileTableRow/FileTableRow';

interface FilesTableProps {
  files: IFileState[];
  mode: DataExtractionMode;
  onDelete: (id: string) => void;
  className: string;
}

const FilesTable: FC<FilesTableProps> = ({
  files,
  mode,
  onDelete,
  className,
}) => {
  const groupedFiles = files.reduce<{ [key in LoaderState]?: IExtendedFileState[] }>((
    result,
    file,
  ) => {
    const error = validateFileState(file, mode);
    const fileState = getLoaderState(file, error);

    if (!result[fileState]) {
      result[fileState] = [];
    }

    result[fileState]!.push({
      ...file,
      error,
      state: fileState,
    });

    return result;
  }, {});

  const fileToRender = [
    ...(groupedFiles[LoaderState.Updating] || []),
    ...(groupedFiles[LoaderState.Failure] || []),
    ...(groupedFiles[LoaderState.Success] || []),
  ];

  return (
    <Table className={className}>
      <TableHead>
        <TableHeadCell width={TableColumnSizes.Name}>File</TableHeadCell>
        <TableHeadCell width={TableColumnSizes.Error} />
      </TableHead>
      {fileToRender.map((file) => {
        return <FileTableRow key={file.id} file={file} onDelete={onDelete} />;
      })}
    </Table>
  );
};

export default FilesTable;
