import React, { FC } from 'react';
import { BillingImage } from 'static/images';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../types/ReduxState';
import { PermissionGroup } from '../../../enums/PermissionGroup';
import BillingPortalLink from 'components/BillingPortalLink';

import styles from './WarningAlert.module.scss';

interface WarningAlertProps {
  text: string;
}

const WarningAlert: FC<WarningAlertProps> = ({
  text,
}) => {
  const { user } = useSelector((state: ReduxState) => state.sessionInfo);
  const userIsOwner = user?.permissionGroup === PermissionGroup.Owner;

  return (
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        <BillingImage className={styles.icon} />
        <p className={styles.message}>
          {text}
        </p>
      </div>
      {userIsOwner && <BillingPortalLink />}
    </div>
  );
};

export default WarningAlert;
