import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { env } from 'env';
import { EnvironmentType } from 'constants/EnvironmentType';

const CaptchaContext = createContext<ReCaptchaInstance | null>(null);

type ExecuteCaptchaAction = (action: string) => Promise<string | null>;

export const useRecaptcha = (): ExecuteCaptchaAction => {
  const context = useContext(CaptchaContext);

  if (env.REACT_APP_ENVIRONMENT === EnvironmentType.Development) {
    return async () => 'sample_captcha_token';
  }

  return async (action: string) => {
    return context?.execute(action) || null;
  };
};

const CaptchaProvider: FC = ({ children }) => {
  const [recaptcha, setRecaptcha] = useState<ReCaptchaInstance | null>(null);

  const loadCaptcha = async () => {
    setRecaptcha(await load(env.REACT_APP_CAPTCHA_KEY as string, { autoHideBadge: true }));
  };

  useEffect(() => {
    loadCaptcha();
  }, []);

  return <CaptchaContext.Provider value={recaptcha}>{children}</CaptchaContext.Provider>;
};

export default CaptchaProvider;
