import { FC } from 'react';
import QuestionIcon from 'components/QuestionIcon';
import Button from 'components/Button';
import { NullableItems } from 'pagination';
import { WebhookEndpoint, WebhookEndpointSortingField, WebhookEndpointSortingType } from 'api/WebhookEndpointsApi';
import NeedHelpSection from 'components/NeedHelpSection';
import { WebhooksIllustration } from 'static/images';
import { User } from 'api/UserApi';
import { EndpointsTable } from 'components/WebhooksOverview/Tables';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './EndpointsDashboard.module.scss';

const MAXIMUM_ENDPOINTS = 10;

interface EndpointsDashboardProps {
  endpoints: NullableItems<WebhookEndpoint>
  users: User[];
  sortingType: WebhookEndpointSortingType;
  onEdit: (id: string) => void;
  onCreate: () => void;
  onSort: (field: WebhookEndpointSortingField, ascending: boolean) => void;
  onDelete: (id: string) => void;
  onStatusChange: (id: string, status: boolean) => void;
};

const EndpointsDashboard: FC<EndpointsDashboardProps> = ({
  endpoints,
  users,
  sortingType,
  onCreate,
  onEdit,
  onSort,
  onDelete,
  onStatusChange,
}) => {
  const renderTooltip = () => (
    <>
      <p>Endpoints are locations (URLs) that we</p>
      <p>will send events to when data is updated</p>
    </>
  );

  const renderContent = () => {
    if (endpoints.length === 0) {
      return (
        <div className={styles.noItemsAddedSection}>
          <h5 className={styles.noItemsAddedSectionTitle}>Listen For Events</h5>
          <div className={styles.noItemsAddedSectionDescription}>
            <p>Set up an endpoint to receive results when data extraction is complete.</p>
          </div>
          <WebhooksIllustration />
          <NeedHelpSection
            link="https://synthetiq.readme.io/reference" //TODO: change link
            linkTitle="User Guide"
            className={styles.needHelpSection}
          />
        </div>
      );
    }

    return (
      <EndpointsTable
        source={endpoints}
        onSort={onSort}
        sortingType={sortingType}
        users={users}
        onDelete={onDelete}
        onEdit={onEdit}
        onStatusChange={onStatusChange}
      />
    );
  }

  const isMaximumEndpoints = endpoints.length === MAXIMUM_ENDPOINTS;

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerTitleWithTooltip}>
          <h4 className={styles.headerTitle}>Endpoints</h4>
          <QuestionIcon size={24} tooltip={renderTooltip()} />
        </div>
        <WrapperWithTooltip tooltip={isMaximumEndpoints && 'You already have the maximum number of endpoints.'}>
          <Button
            onClick={onCreate}
            className={styles.addEndpointButton}
            kind="primary"
            disabled={isMaximumEndpoints}
          >
            Add Endpoint
          </Button>
        </WrapperWithTooltip>
      </div>
      {renderContent()}
    </div>
  );
};

export default EndpointsDashboard;
