import { useSelector } from 'react-redux';
import { ReduxState } from '../types/ReduxState';

const useSubscriptionStatus = () => {
  const { organization } = useSelector((state: ReduxState) => state.sessionInfo);

  return {
    subscriptionStatus: organization?.subscriptionStatus,
    hasDefaultPaymentMethod: organization?.hasDefaultPaymentMethod,
    isLegalAddressProvided: organization?.isLegalAddressProvided,
    hasFreeBalance: organization?.hasFreeBalance,
  };
};

export default useSubscriptionStatus;
