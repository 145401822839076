import React, { FC, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import UploadImageStep from 'components/UploadImagePopup/Steps/UploadImageStep';
import CropImageStep from 'components/UploadImagePopup/Steps/CropImageStep';
import { ImageParams } from 'api/UserApi';
import styles from './UploadImagePopup.module.scss';

export enum UploadImagePopupStep {
  Upload = 'Upload',
  Crop = 'Edit',
}

interface UploadImagePopupProps {
  title: string;
  aspectRation: number;
  outputMaxWidth: number;
  outputMaxHeight: number;
  maxSizeBytes: number;
  supportedExtensions: string[];
  image?: Blob | null;
  initialStep?: UploadImagePopupStep;
  imageParams?: ImageParams | null;
  onDelete?: () => Promise<void>;
  onSubmit: (file: Blob, croppedFile: Blob, params: ImageParams) => Promise<void>;
  onClose: () => void;
}

const DEFAULT_FILE_NAME = 'image.png';

const UploadImagePopup: FC<UploadImagePopupProps> = ({
  title,
  aspectRation,
  outputMaxWidth,
  outputMaxHeight,
  image: previouslySelectedImage,
  initialStep,
  maxSizeBytes,
  supportedExtensions,
  imageParams: previouslySelectedImageParams,
  onClose,
  onSubmit,
  onDelete,
}) => {
  const [
    step,
    setStep,
  ] = useState(initialStep || UploadImagePopupStep.Upload);
  const [file, setFile] = useState<File | null>(null);
  const [
    imageParams,
    setImageParams,
  ] = useState<ImageParams | null | undefined>(undefined);

  const handleUploadFile = (newFile: File) => {
    setFile(newFile);
    setImageParams(null);
    setStep(UploadImagePopupStep.Crop);
  };

  const handleCancel = () => {
    setStep(UploadImagePopupStep.Upload);
  };

  const previouslySelectedFile = previouslySelectedImage
    ? new File([previouslySelectedImage], imageParams?.originalFileName || DEFAULT_FILE_NAME)
    : null;

  return (
    <PopUp
      title={`${step} ${title}`}
      closePopUp={onClose}
      classNames={{ sectionClassName: step === UploadImagePopupStep.Crop ? styles.popup : undefined }}
    >
      <PopUpContent>
        <div className={styles.container}>
          {step === UploadImagePopupStep.Upload && (
            <UploadImageStep
              file={file || previouslySelectedFile}
              onSubmit={handleUploadFile}
              maxSizeBytes={maxSizeBytes}
              supportedExtensions={supportedExtensions}
            />
          )}
          {step === UploadImagePopupStep.Crop && (
            <CropImageStep
              title={title}
              file={file || previouslySelectedFile}
              aspectRation={aspectRation}
              imageParams={imageParams === undefined ? previouslySelectedImageParams : imageParams}
              onSave={onSubmit}
              onDelete={onDelete}
              onCancel={handleCancel}
              outputMaxWidth={outputMaxWidth}
              outputMaxHeight={outputMaxHeight}
            />
          )}
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default UploadImagePopup;
