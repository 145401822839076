import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './TableActionCell.module.scss';

export interface TableActionCellProps {
  className?: string;
}

const TableActionCell: FC<TableActionCellProps> = ({ className }) => {
  return <div className={clsx(styles.tableActionCell, className)} />;
};

export default TableActionCell;
