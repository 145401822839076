import { EnvironmentType } from 'constants/EnvironmentType';

type EnvType = {
  /**
   * Staging/production/development/test
   * Used to initialize env specific tools/logic
   */
  REACT_APP_ENVIRONMENT: EnvironmentType;
  /**
   * Url to Synthetiq API
   */
  REACT_APP_API_URL: string;
  /**
   * Google captcha key(used in login page)
   */
  REACT_APP_CAPTCHA_KEY: string;
  /**
   * Sentry DSN
   */
  REACT_APP_SENTRY_DSN: string;
  /**
   * Google OAuth client id
   */
  REACT_APP_GOOGLE_AUTH_CLIENT_ID: string;
}

declare global {
  interface Window {
    env: EnvType;
  }
}

export const env: EnvType = { ...process.env, ...window.env };
