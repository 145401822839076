import { DocumentExtension } from 'enums/DocumentExtension';
import heic2any from 'heic2any';
import React, { FC, useEffect, useState } from 'react';

interface ImagePreviewProps {
  previewLink: string;
  fileExtension: string;
  onLoad: () => void;
  onError: () => void;
  width: number | null;
}

const IMAGE_FORMATS_TO_CONVERT = [
  DocumentExtension.Heic,
];

const ImagePreview: FC<ImagePreviewProps> = ({
  fileExtension,
  previewLink,
  onLoad,
  onError,
  width,
}) => {
  const [src, setSrc] = useState<string | null>(null);

  const convertImage = async () => {
    const downloadResponse = await fetch(previewLink);

    const convertedFile = await heic2any({
      blob: await downloadResponse.blob(),
    }) as Blob;

    return window.URL.createObjectURL(convertedFile);
  };

  const processImage = async () => {
    if (IMAGE_FORMATS_TO_CONVERT.includes(fileExtension as DocumentExtension)) {
      const convertedPreviewLink = await convertImage();

      setSrc(convertedPreviewLink);

      return;
    }

    setSrc(previewLink);
  };

  useEffect(() => {
    processImage();
  }, [previewLink]);


  if (!src) {
    return null;
  }

  return (
    <img
      src={src}
      onLoad={onLoad}
      onError={onError}
      style={{ width: width || undefined }}
    />
  );
};

export default ImagePreview;
