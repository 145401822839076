import { useRecaptcha } from 'CaptchaProvider';
import { createBatchDataExtraction, createDataExtraction } from 'DataExtractions/Thunks';
import {
  extractionHistoryDashboardActionOrigin,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryDashboardStore';
import { CreateBatchDataExtractionParams, DataExtractionMode, DocumentTypeDetectionResult } from 'api/DataExtractionApi';
import { IDocumentTokenInfo } from 'api/DocumentTokenApi';
import { DocumentTypeSortingField } from 'api/DocumentTypeApi';
import {
  CreateDataExtractionFormParams,
} from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/CreateDataExtractionForm';
import RunExtractionForm from 'components/HomeDashboard/RunExtractionForm/RunExtractionForm';
import CaptchaAction from 'enums/CaptchaAction';
import DataExtractionUiAction from 'enums/DataExtractionUiAction';
import ExtractionFormMode from 'enums/ExtractionFormMode';
import notification from 'handlers/notification/notificationActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { useDocumentTokenApi } from 'providers/ApiServiceProvider';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { AppRoutes } from 'routes/RouteBuilder';
import { dataExtractionsApi, documentTypeApi } from 'stores/api';

interface RunExtractionFormWrapperProps {
  className?: string;
}

const RunExtractionFormWrapper: FC<RunExtractionFormWrapperProps> = ({
  className,
}) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const routeBuilder = useRouteBuilder();
  const [documentContentId, setDocumentContentId] = useState<string | undefined>();
  const executeCaptcha = useRecaptcha();
  const documentTokenApi = useDocumentTokenApi();

  const [
    mode,
    setMode,
  ] = useState(ExtractionFormMode.SingleExtraction);

  const [
    isFileProcessing,
    setIsFileProcessing,
  ] = useState<boolean>(false);

  const documentTypeIdToExtract = routeBuilder.currentQueryParams.get(
    DataExtractionUiAction.Run,
  );

  const [
    dataExtractionFormParams,
    setDataExtractionFormParams,
  ] = useState<CreateDataExtractionFormParams>({
    file: null,
    mode: DataExtractionMode.Quality,
    useForAutoDetect: !!documentTypeIdToExtract,
    documentTypeId: documentTypeIdToExtract,
  });

  const [fileTokenInfo, setFileTokenInfo] = useState<IDocumentTokenInfo | null>(null);

  const previousDataExtractionFormParams = useRef<CreateDataExtractionFormParams>(dataExtractionFormParams);

  const [
    documentTypeDetectionResult,
    setDocumentTypeDetectionResult,
  ] = useState<DocumentTypeDetectionResult | null>(null);

  const handleCreateDataExtraction = async (params: CreateDataExtractionFormParams) => {
    if (!params.file || !documentContentId) {
      return;
    }

    const dataExtraction = await dispatchWithUnwrap(createDataExtraction({
      mode: params.mode,
      useForAutoDetect: params.useForAutoDetect,
      documentTypeId: params.documentTypeId,
      actionOrigin: extractionHistoryDashboardActionOrigin,
      documentContentId,
    }));

    routeBuilder.go(AppRoutes.extractionHistory, dataExtraction.id);
  };

  const handleCreateBatchDataExtraction = async (params: CreateBatchDataExtractionParams) => {
    await dispatchWithUnwrap(createBatchDataExtraction({
      mode: params.mode,
      documentTypeId: params.documentTypeId,
      documentContentIds: params.documentContentIds,
      useForAutoDetect: params.useForAutoDetect,
    }));

    routeBuilder.go(AppRoutes.extractionHistory);
  };

  const handleFetchDocumentTypesOptions = useCallback(
    async (search: string) => {
      const { items: documentTypes } = await documentTypeApi.search(
        { search },
        { field: DocumentTypeSortingField.Name, ascending: true },
      );

      return documentTypes.map((documentType) => ({
        name: documentType.name,
        value: documentType.id,
      }));
    },
    [],
  );

  const validateFile = async (file: File) => {
    const captchaToken = await executeCaptcha(CaptchaAction.ValidateTokenLimit);

    if (!captchaToken) {
      return;
    }

    return documentTokenApi.getTokenInfo({
      file,
      captchaToken,
    });
  };

  const uploadDocumentTokenInfo = async () => {
    if (!dataExtractionFormParams.file) {
      return;
    }

    const captchaToken = await executeCaptcha(CaptchaAction.ValidateTokenLimit);

    if (!captchaToken) {
      return;
    }

    const info = await documentTokenApi.getTokenInfo({
      file: dataExtractionFormParams.file,
      captchaToken,
      documentContentId,
    });

    setDocumentContentId(info.documentContentId);
    setFileTokenInfo(info);

    return info.documentContentId;
  };

  const handleDetectDocumentType = async (parsedDocumentContentId: string) => {
    if (!dataExtractionFormParams.file || dataExtractionFormParams.documentTypeId) {
      return;
    }

    const captchaToken = await executeCaptcha(CaptchaAction.DetectDocumentType);

    if (!captchaToken) {
      return;
    }

    try {
      const result = await dataExtractionsApi.detectDocumentType({
        captchaToken,
        documentContentId: parsedDocumentContentId,
      });

      setDocumentTypeDetectionResult(result);
    } catch (error) {
      setDataExtractionFormParams({
        file: null,
        mode: DataExtractionMode.Quality,
        useForAutoDetect: !!documentTypeIdToExtract,
        documentTypeId: documentTypeIdToExtract,
      });

      notification.errorNotification(error, dispatchWithUnwrap);

      throw error;
    }
  };

  const processUploadedDocument = async () => {
    setIsFileProcessing(true);

    try {
      const parsedDocumentContentId = await uploadDocumentTokenInfo();

      if (parsedDocumentContentId) {
        await handleDetectDocumentType(parsedDocumentContentId);
      }
    } catch (error) {
      setDataExtractionFormParams((previousParams) => {
        return {
          ...previousParams,
          file: null,
        };
      });

      notification.errorNotification(error, dispatchWithUnwrap);

      throw error;
    } finally {
      setIsFileProcessing(false);
    }
  };

  const handleChangeParams = useCallback((params: Partial<CreateDataExtractionFormParams>) => {
    setDataExtractionFormParams((oldParams) => {
      const newParams: CreateDataExtractionFormParams = {
        ...oldParams,
        ...params,
      };

      if (params.file === null) {
        newParams.documentTypeId = null;
        newParams.useForAutoDetect = false;
      }

      return newParams;
    });
  }, [setDataExtractionFormParams]);

  const handleModeSwitch = (newMode: ExtractionFormMode) => {
    setDataExtractionFormParams({
      file: null,
      mode: DataExtractionMode.Quality,
      useForAutoDetect: !!documentTypeIdToExtract,
      documentTypeId: documentTypeIdToExtract,
    });

    setMode(newMode);
  };

  useEffect(() => {
    if (dataExtractionFormParams.file !== previousDataExtractionFormParams.current.file) {
      setDocumentTypeDetectionResult(null);
      setFileTokenInfo(null);
      setDocumentContentId(undefined);
    }

    if (previousDataExtractionFormParams.current.file === null && dataExtractionFormParams.file) {
      processUploadedDocument();
    }

    previousDataExtractionFormParams.current = dataExtractionFormParams;
  }, [dataExtractionFormParams]);

  const detectedDocumentTypes = documentTypeDetectionResult?.documentTypes || null;

  return (
    <RunExtractionForm
      mode={mode}
      className={className}
      onFetchDocumentTypeOptions={handleFetchDocumentTypesOptions}
      onCreateDataExtraction={handleCreateDataExtraction}
      onCreateBatchDataExtraction={handleCreateBatchDataExtraction}
      params={dataExtractionFormParams}
      onChange={handleChangeParams}
      onModeSwitch={handleModeSwitch}
      detectedDocumentTypes={detectedDocumentTypes}
      isLoading={isFileProcessing}
      fileTokenInfo={fileTokenInfo}
      onValidateFile={validateFile}
      isSwitchModeDisabled={isFileProcessing}
    />
  );
};

export default RunExtractionFormWrapper;
