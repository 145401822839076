import { FC } from 'react';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import clsx from 'clsx';
import { SkeletonRectangle } from 'components/Skeleton';
import { Link } from 'react-router-dom';
import DotFallingLoader from 'components/DotFallingLoader';
import OverflowedText from 'components/OverflowedText';
import styles from './DataExtractionResultInfoPanel.module.scss';
import DocumentTypeLabel from 'components/DocumentTypeLabel/DocumentTypeLabel';
import getFormattedDurationTime from '../../../DataExtractions/getFormattedDurationTime';
import { DataExtractionResult, DataExtractionStatus } from 'api/DataExtractionResultApi';

interface DataExtractionResultInfoPanelField {
  label: InfoPanelLabel;
  value: React.ReactNode;
  link?: string;
}

enum InfoPanelLabel {
  FileName = 'File Name',
  FileType = 'File Type',
  DocumentType = 'Document Type',
  Source = 'Source',
  ProcessingTime = 'Processing Time',
  PagesProcessed = 'Pages Processed',
}

interface DataExtractionResultInfoPanelProps {
  dataExtractionResult?: DataExtractionResult;
  className?: string;
}

const ITEMS_PER_ROW = 3;
const SKELETON_FIELDS = 6;

const DataExtractionResultInfoPanel: FC<DataExtractionResultInfoPanelProps> = ({
  dataExtractionResult,
  className,
}) => {
  const routeBuilder = useRouteBuilder();

  const infoItems: DataExtractionResultInfoPanelField[] | null = dataExtractionResult ? [{
    label: InfoPanelLabel.FileName,
    value: dataExtractionResult.originalFileName,
  },
  {
    label: InfoPanelLabel.FileType,
    value: dataExtractionResult.fileType.toUpperCase(),
  },
  {
    label: InfoPanelLabel.DocumentType,
    value: <DocumentTypeLabel
      isAutoDetected={dataExtractionResult.isAutoDetected}
      isProcessing={dataExtractionResult.status === DataExtractionStatus.Processing}
      documentType={dataExtractionResult.documentType?.name}
    />,
    link: dataExtractionResult.documentType
      ? routeBuilder.withParams({ edit: dataExtractionResult.documentType.id }).build(AppRoutes.documentTypes)
      : undefined,
  }, {
    label: InfoPanelLabel.Source,
    value: dataExtractionResult.createdBy ? 'Web' : 'API',
  }, {
    label: InfoPanelLabel.ProcessingTime,
    value: getFormattedDurationTime(dataExtractionResult.totalDurationTime),
  },
  {
    label: InfoPanelLabel.PagesProcessed,
    value: dataExtractionResult.pagesCount,
  },
  ] : null;

  const renderFieldValue = (field: DataExtractionResultInfoPanelField) => {
    if (dataExtractionResult?.status === DataExtractionStatus.Processing &&
      (field.label === InfoPanelLabel.ProcessingTime || field.label === InfoPanelLabel.PagesProcessed)
    ) {
      return <DotFallingLoader className={styles.dotFallingLoader} />
    }

    if (field.link && field.value) {
      return <Link to={field.link} className={styles.fieldLink}>{field.value}</Link>;
    }

    return <OverflowedText className={styles.fieldValue}>{field.value || <span>&#8212;</span>}</OverflowedText>
  };

  const renderField = (field: any, index: number, fields: DataExtractionResultInfoPanelField[]) => (
    <div key={field.label} className={clsx(styles.field, index + ITEMS_PER_ROW >= fields.length && styles.withoutBorderField)}>
      <p className={styles.fieldLabel}>{field.label}</p>
      {renderFieldValue(field)}
    </div>
  );

  const renderInfoPanelFields = () => {
    if (!infoItems) {
      return new Array(SKELETON_FIELDS).fill(null).map((_, index, fields) => (
        <div key={index} className={clsx(styles.field, index + ITEMS_PER_ROW > fields.length && styles.withoutBorderField)}>
          <SkeletonRectangle width="100px" height="12px" color="primary20" marginBottom="4px" />
          <SkeletonRectangle width="200px" height="12px" color="primary20" />
        </div>
      ));
    }

    return infoItems.map(renderField)
  };

  return (
    <div className={clsx(styles.dataExtractionResultInfoPanel, className)}>
      <div className={styles.dataExtractionResultInfoPanelFields}>
        {renderInfoPanelFields()}
      </div>
    </div>
  )
}

export default DataExtractionResultInfoPanel;
