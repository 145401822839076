import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { TextSize } from 'components/Avatar';
import RestUsersPopupList, { AvatarUserInfo } from 'components/UserAvatarInlineList/RestUsersPopupList';
import RestUsersAvatar from 'components/UserAvatarInlineList/RestUsersAvatar';
import UserAvatar from 'components/UserAvatar';
import getAvatarUrl from 'api/utils/getAvatarUrl';
import { SkeletonCircle } from 'components/Skeleton';

import styles from './UserAvatarInlineList.module.scss';

interface UserAvatarInlineListProps<UserType extends AvatarUserInfo> {
  source: UserType[];
  className?: string;
  size?: TextSize;
  maxCount?: number;
  onSelect?: (user: UserType) => void;
  selectedUsers?: UserType[];
  avatarClassName?: string;
  loading?: boolean;
}

const SKELETON_USERS_COUNT = 3;
const SKELETON_USERS = Array.from({ length: SKELETON_USERS_COUNT }, () =>  null);

const UserAvatarInlineList = <UserType extends AvatarUserInfo>({
  source,
  className,
  maxCount,
  size,
  selectedUsers = [],
  onSelect,
  avatarClassName,
  loading,
}: UserAvatarInlineListProps<UserType>) => {
  const [restUsersPopupOpen, setRestUsersPopupOpen] = useState(false);

  const usersCountToView = !maxCount || source.length === maxCount ? source.length : maxCount - 1;
  const usersToView = source.slice(0, usersCountToView);
  const restUsers = source.slice(usersCountToView);
  const avatarCounterRef = useRef<HTMLDivElement>(null);

  const isSelected = (userId: string) => selectedUsers.some(({ id }) => id === userId);

  /**
   * Need to open only users dropdown
   * and stop propagation of another unwanted parent event (redirection, modal opening and etc.)
   * @param event
   */
  const handleRestUsersAvatarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const renderContent = () => {
    if (loading) {
      return SKELETON_USERS.map(() => (
        <SkeletonCircle width="36px" height="36px" color="primary6" className={clsx(styles.listItem, styles.skeletonCircle)} />
      ));
    }

    return (
      <>
        {usersToView.map((user) => (
          <UserAvatar
            firstName={user.firstName || ''}
            lastName={user.lastName || ''}
            color={user.color}
            imageUrl={getAvatarUrl(user.avatarId)}
            size={size}
            tooltip
            active={isSelected(user.id)}
            textClassName={styles.avatarText}
            className={clsx(styles.userAvatar, styles.listItem, avatarClassName)}
            onClick={() => onSelect?.(user)}
            key={user.id}
          />
        ))}
        {restUsers.length > 0 && (
          <div onClick={handleRestUsersAvatarClick} className={styles.listItem} >
            <RestUsersAvatar
              hasItemsSelected={restUsers.some(({ id }) => isSelected(id))}
              usersCount={restUsers.length}
              onClick={() => setRestUsersPopupOpen(true)}
              ref={avatarCounterRef}
              className={styles.userAvatar}
              size={size}
              isActive={restUsersPopupOpen}
            />
            <RestUsersPopupList
              open={restUsersPopupOpen}
              anchorEl={avatarCounterRef.current}
              users={restUsers}
              selectedUsers={selectedUsers}
              onUserSelect={onSelect}
              onClose={() => setRestUsersPopupOpen(false)}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={clsx(styles.list, className)}>
      {renderContent()}
    </div>
  );
};

export default UserAvatarInlineList;
