// @ts-nocheck
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import PdfWorkerSrc from 'worker-plugin/loader?name=PdfWorker!pdfjs-dist/build/pdf.worker.js';
// @ts-nocheck

const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${PdfWorkerSrc}`;

pdfjs.GlobalWorkerOptions.workerPort = new Worker(
  new URL(url, import.meta.url),
);
