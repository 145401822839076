import React, { DetailedHTMLProps, useRef } from 'react';
import clsx from 'clsx';
import { ClearIcon, SearchImage } from 'static/images';
import Spinner from 'components/Spinner';
import InputWithValidation from 'components/InputWithValidation';

import styles from './SearchInput.module.scss';

type InputProps = DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface SearchInputProps extends InputProps {
  containerClassName?: string;
  showLoader?: boolean;
  onClear: () => void;
  inputValueValidator?: (value: string) => boolean;
  value?: string;
}

const SPINNER_SIZE = 20;

const SearchInput = ({
  containerClassName,
  showLoader,
  onClear,
  inputValueValidator,
  value,
  ...inputProps
}: SearchInputProps) => {
  const inputElement = useRef<HTMLInputElement>(null);

  return (
    <div className={clsx(styles.search, containerClassName)}>
      <SearchImage className={styles.searchIcon} />
      <InputWithValidation {...inputProps} value={value} valueValidator={inputValueValidator} ref={inputElement} />
      {value && !showLoader && (
        <div className={styles.clearIconContainer}>
          <ClearIcon className={styles.clearIcon} onClick={onClear} />
        </div>
      )}
      {showLoader && (
        <div className={styles.loader}>
          <Spinner size={SPINNER_SIZE} />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
