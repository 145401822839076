import ipRegex from 'ip-regex';
import cidrRegex from 'cidr-regex';

export interface WhitelistParams {
  id: string; // generated only for using on the frontend side
  ip: string;
  description: string;
}

export interface WhitelistValidationResult {
  ip?: string;
  description?: string;
}

const IpV4ValidationRegex = ipRegex.v4({ exact: true, includeBoundaries: true });
const IpV4WithCidrValidationRegex = cidrRegex.v4({ exact: true });

const validateWhitelists = (whitelist: WhitelistParams): WhitelistValidationResult  => {
  const errors: WhitelistValidationResult = {};

  if (!whitelist.ip.match(IpV4ValidationRegex) && !whitelist.ip.match(IpV4WithCidrValidationRegex)) {
    errors.ip = 'IP Address must be a valid IPv4 address or CIDRv4'
  }

  if (whitelist.ip.length === 0) {
    errors.ip = 'IP Address is required.'
  }

  if (whitelist.description.length < 2) {
    errors.description = 'Description must be at least 2 characters';
  }

  if (whitelist.description.length === 0) {
    errors.description = 'Description is required.'
  }

  return errors;
};

export default validateWhitelists;
