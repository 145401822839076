import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataWithActionOrigin, ActionOriginData } from 'types/action-origin';
import { DataExtractionResultsActionType } from './DataExtractionResultsActionType';
import { dataExtractionResultsApi } from 'stores/api';
import {
  DataExtractionResult,
  DataExtractionResultFilters,
  DataExtractionResultsSortingType,
} from '../api/DataExtractionResultApi';
import { TableViewData } from '../api/Types';

export const getDataExtractionResult = createAsyncThunk<
  DataExtractionResult,
  DataWithActionOrigin<{ id: string }>,
  { fulfilledMeta: ActionOriginData }
>(
  DataExtractionResultsActionType.GetDataExtractionResult,
  async ({
    id,
    actionOrigin,
  }, thunkApi) => {
    return thunkApi.fulfillWithValue(await dataExtractionResultsApi.findById(id), { actionOrigin });
  },
);

export const getDataExtractionResults = createAsyncThunk<
  TableViewData<DataExtractionResult>,
  DataWithActionOrigin<{ filters: DataExtractionResultFilters, sortingType: DataExtractionResultsSortingType }>,
  { fulfilledMeta: DataWithActionOrigin<{ search?: string; }> }
>(
  DataExtractionResultsActionType.GetDataExtractionResults,
  async ({
    filters,
    sortingType,
    actionOrigin,
  }, thunkApi) => {
    return thunkApi.fulfillWithValue(await dataExtractionResultsApi.search(filters, sortingType), {
      ...filters,
      actionOrigin,
    });
  },
);
