import { PublicApiKey } from 'api/PublicApiKeyApi';
import getAvatarUrl from 'api/utils/getAvatarUrl';
import ApiKeyTableColumnSizes from 'components/ApiCredentials/ApiKeyTable/ApiKeyTableColumnSizes';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import Table, { TableActionCell, TableBody, TableBodyCell, TableHead, TableHeadCell, TableRow } from 'components/Table';
import TableStub from 'components/TableStub';
import UserAvatar from 'components/UserAvatar';
import React, { FC, ReactElement } from 'react';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import styles from './ApiKeyTable.module.scss';

interface ApiKeyTableProps {
  isDeleteActionDisabled: boolean;
  deleteActionTooltip: ReactElement | null;
  apiKeys?: PublicApiKey[];
  onDeleteKey: (id: string) => void;
  className?: string;
  onSort: (field: keyof PublicApiKey) => () => void;
  getSortingType: (field: keyof PublicApiKey) => boolean | undefined;
}

const SKELETON_ROWS = Array.from({ length: 5 }).map(() => null);

const ApiKeyTable: FC<ApiKeyTableProps> = ({
  className,
  apiKeys,
  isDeleteActionDisabled,
  deleteActionTooltip,
  onDeleteKey,
  getSortingType,
  onSort,
}) => {
  const members = useOrganizationMembers({ includeInactive: true });

  const tableColumns = [
    { title: 'API Key', width: ApiKeyTableColumnSizes.ApiKey },
    {
      title: 'Generated',
      width: ApiKeyTableColumnSizes.Generated,
      onClick: onSort('createdAt'),
      ascending: getSortingType('createdAt'),
    },
    { title: 'Last Used', width: ApiKeyTableColumnSizes.LastUsed },
  ];

  if (apiKeys?.length === 0) {
    return (
      <TableStub
        columns={tableColumns}
        description="Your account does not have API credentials yet. Generate an API Key to get started."
        rows={5}
      />
    );
  }

  const renderRow = (apiKey: PublicApiKey | null, index: number) => {
    const isLoading = !apiKey;
    const createdBy = members.find(({ id }) => apiKey?.createdBy === id);

    return (
      <TableRow disableHover useActions key={apiKey?.id || index}>
        <TableBodyCell className={styles.cell} width={ApiKeyTableColumnSizes.ApiKey}>
          {!isLoading && '******************'}
          {isLoading && <SkeletonRectangle width="140px" height="16px" color="primary6" />}
        </TableBodyCell>
        <TableBodyCell className={styles.cell} width={ApiKeyTableColumnSizes.Generated}>
          {!isLoading && (
            <>
              <UserAvatar
                color={createdBy?.color}
                imageUrl={getAvatarUrl(createdBy?.avatarId)}
                className={styles.avatar}
                firstName={createdBy?.firstName || ''}
                lastName={createdBy?.lastName || ''}
                size={10}
                tooltip
              />
              <div>{formatDate(apiKey.createdAt, DateTimeFormat.Long)}</div>
            </>
          )}
          {isLoading && <SkeletonCircle width="28px" height="28px" color="primary6" marginRight="8px" />}
          {isLoading && <SkeletonRectangle width="140px" height="16px" color="primary6" />}
        </TableBodyCell>
        <TableBodyCell className={styles.cell} width={ApiKeyTableColumnSizes.LastUsed}>
          {(!isLoading && !apiKey.lastUsedAt) && <TableBodyCellNoContent />}
          {(!isLoading && apiKey.lastUsedAt) && formatDate(apiKey.lastUsedAt, DateTimeFormat.Long)}
          {isLoading && <SkeletonRectangle width="140px" height="16px" color="primary6" />}
        </TableBodyCell>
        <RowActionsContainer>
          <RowActions
            actions={[
              {
                title: 'Delete Key',
                danger: !isDeleteActionDisabled,
                handler: () => onDeleteKey(apiKey!.id),
                disabled: isDeleteActionDisabled || isLoading,
                tooltip: deleteActionTooltip,
              },
            ]}
          />
        </RowActionsContainer>
      </TableRow>
    );
  };

  return (
    <Table className={className}>
      <TableHead>
        {tableColumns.map(({
          title,
          width,
          ascending,
          onClick,
        }) => (
          <TableHeadCell width={width} key={title} onClick={onClick} ascending={ascending}>{title}</TableHeadCell>
        ))}
        <TableActionCell />
      </TableHead>
      <TableBody>
        {(apiKeys || SKELETON_ROWS).map(renderRow)}
      </TableBody>
    </Table>
  );
};

export default ApiKeyTable;
