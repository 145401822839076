import React, { FC, useEffect } from 'react';
import { ButtonType } from 'components/Button/Button';
import notification from 'handlers/notification/notificationActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import {
  confirmEmailCode,
  sendEmailConfirmationCode,
} from 'MyAccount/ActionCreator';
import VerifyCode from 'components/VerifyCode';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import { User } from 'api/UserApi';

export interface EmailCodeConfirmationStepProps {
  currentUser: User
  buttonText: string;
  buttonKind?: ButtonType;
  onSubmit: () => void;
}

const EmailCodeConfirmationStep: FC<EmailCodeConfirmationStepProps> = ({
  buttonText,
  buttonKind,
  onSubmit,
  currentUser,
}) => {
  const dispatch = useDispatchWithUnwrap();

  const handleResendCode = async () => {
    dispatch(sendEmailConfirmationCode());
  };

  const [isCodeVerificationInProgress, handleCodeVerifySubmit] = useAsyncActionCallback(async (code: string) => {
    const result = await dispatch(confirmEmailCode(code));

    if (result.invalidCode) {
      await dispatch(sendEmailConfirmationCode());

      notification.createNotification('Invalid code, a new code has been sent.', 'error', dispatch);

      return;
    }

    onSubmit();
  }, [onSubmit]);

  useEffect(() => {
    dispatch(sendEmailConfirmationCode());
  }, []);

  return (
    <VerifyCode
      buttonKind={buttonKind}
      onSubmit={handleCodeVerifySubmit}
      buttonText={buttonText}
      message={`Please enter the code sent to ${currentUser.email}`}
      onResendCode={handleResendCode}
      loading={isCodeVerificationInProgress}
    />
  );
};

export default EmailCodeConfirmationStep;
