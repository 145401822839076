import React, { FC, ReactNode, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import usePageWrapperOffset from './usePageWrapperOffset';
import PAST_DUE_SUBSCRIPTION_STATUSES from '../../constants/PastDueSubscriptionStatuses';
import PaymentProcessingFailureAlert from './PaymentProcessingFailureAlert';
import WarningAlert from './WarningAlert';
import useSubscriptionStatus from '../../hooks/useSubscriptionStatus';

import styles from './PageWrapper.module.scss';

const NO_CREDIT_CARD_AND_ADDRESS_WARNING_TEXT = 'Please add your credit card and billing address to avoid losing access.';
const NO_CREDIT_CARD_WARNING_TEXT = 'Please add your credit card to avoid losing access.';
const NO_LEGAL_ADDRESS_WARNING_TEXT = 'Please add your billing address to avoid losing access.';

interface PageWrapperProps {
  className?: string;
  children: ReactNode;
}

const PageWrapper: FC<PageWrapperProps> = ({ className, children, ...props }) => {
  const mergedClassName = clsx(styles.pageWrapper, className);
  const wrapperElementRef = useRef<HTMLDivElement>(null);
  const setOffset = usePageWrapperOffset();

  const {
    subscriptionStatus,
    hasDefaultPaymentMethod,
    isLegalAddressProvided,
  } = useSubscriptionStatus();

  useLayoutEffect(() => {
    const { width } = wrapperElementRef.current!.getBoundingClientRect();
    const { width: parentWidth } = wrapperElementRef.current!.parentElement!.getBoundingClientRect();
    const rightOffset = parentWidth - width;
    setOffset(rightOffset);
  });

  const renderWarningAlert = () => {
    if (subscriptionStatus && PAST_DUE_SUBSCRIPTION_STATUSES.includes(subscriptionStatus)) {
      return (
        <PaymentProcessingFailureAlert />
      )
    }

    if (!hasDefaultPaymentMethod && isLegalAddressProvided) {
      return (
        <WarningAlert text={NO_CREDIT_CARD_WARNING_TEXT} />
      )
    }

    if (hasDefaultPaymentMethod && !isLegalAddressProvided) {
      return (
        <WarningAlert text={NO_LEGAL_ADDRESS_WARNING_TEXT} />
      )
    }

    if (!hasDefaultPaymentMethod) {
      return (
        <WarningAlert text={NO_CREDIT_CARD_AND_ADDRESS_WARNING_TEXT} />
      )
    }

    return null;
  }

  return (
    <div ref={wrapperElementRef} className={mergedClassName} {...props}>
      {renderWarningAlert()}
      {children}
    </div>
  );
};

export default PageWrapper;
