import formatDate from 'utils/dateFormat';
import formatWithPostfix from 'utils/formatWithPostfix';

export const getTitleWithCurrency = (title: string) => {
  return (currencySymbol?: string) => (currencySymbol ? `${title} (${currencySymbol})` : title);
};

export const formatXValue = (value: number) => {
  return formatDate(new Date(value), 'MMM DD, YYYY');
};

export const formatYValue = (value: number) => {
  if (value === 0) {
    return '0';
  }

  const millifiedValue = formatWithPostfix(value);

  return millifiedValue.length > 2 ? millifiedValue : formatWithPostfix(value, Number.isInteger(value) ? 0 : 2);
};

export const formatPercentageYValue = (value: number) => {
  const millifiedValue = value === 0 || value === Infinity ? '0' : formatWithPostfix(value * 100);

  return `${millifiedValue}%`;
};
