import React from 'react';
import { DocumentType } from 'api/DocumentTypeApi';
import { NullableItems } from 'pagination';
import DocumentTypesListItem from './DocumentTypesListItem';
import SkeletonDocumentTypesListItem from './SkeletonDocumentTypesListItem';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import { User } from 'api/UserApi';
import NoItems from 'components/NoItems';
import { NoItems as NoItemsIcon } from 'static/images';

import styles from './DocumentTypesList.module.scss';

export interface ProductsListProps {
  documentTypes: NullableItems<DocumentType>;
  hasMore?: boolean;
  noItems: boolean;
  containerId: string;
  members: User[];
  onDocumentTypeSelect: (documentTypeId: string) => void;
  onDocumentTypeDuplicate: (documentType: DocumentType) => void;
  onDocumentTypeDelete: (documentType: DocumentType) => void;
  onDocumentTypeEdit: (documentType: DocumentType) => void;
  onDocumentTypeAdd: () => void;
  onLoadMore: () => void;
}

const DocumentTypesList = ({
  documentTypes,
  hasMore,
  noItems,
  containerId,
  members,
  onDocumentTypeSelect,
  onDocumentTypeDuplicate,
  onDocumentTypeDelete,
  onDocumentTypeEdit,
  onDocumentTypeAdd,
  onLoadMore,
}: ProductsListProps) => {
  const renderListItem = (documentType: DocumentType | null, index: number) => {
    if (!documentType) {
      return <SkeletonDocumentTypesListItem key={index} />;
    }

    return (
      <DocumentTypesListItem
        key={documentType.id}
        documentType={documentType}
        members={members}
        onDocumentTypeSelect={onDocumentTypeSelect}
        onDuplicate={onDocumentTypeDuplicate}
        onDelete={onDocumentTypeDelete}
        onEdit={onDocumentTypeEdit}
      />
    );
  };

  const renderNoItems = () => {
    return (
      <NoItems
        className={styles.noItemsContainer}
        titleClassName={styles.noItemsTitle}
        title='Document types have not been added yet!'
        buttonMessage='Add document type'
        onButtonClick={onDocumentTypeAdd}
        icon={<NoItemsIcon />}
      />
    )
  };

  return (
    <>
      {noItems && renderNoItems()}
      <InfiniteScroll
        containerId={containerId}
        dataLength={documentTypes.length}
        onLoadMore={onLoadMore}
        hasMore={hasMore}
        className={styles.container}
      >
        {documentTypes.map(renderListItem)}
      </InfiniteScroll>
    </>
  )
};

export default DocumentTypesList;
