import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import clsx from 'clsx';

import styles from './AuthLink.module.scss';

const AuthLink: FC<LinkProps> = (props) => {
  return (
    <Link {...props} className={clsx(styles.link, props.className)} />
  );
};

export default AuthLink;
