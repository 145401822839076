import MainLayout, { PageContent, PageWrapperWithFooter } from 'components/MainLayout';
import { useParams } from 'react-router';
import DataExtractionResultOverview from 'components/DataExtractionResultOverview';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { exportDataExtraction } from 'DataExtractions/Thunks';
import { ExportFileType } from '../../api/DataExtractionExportApi';

const DataExtractionResult = () => {
  const { dataExtraction: dataExtractionId } = useParams<{ dataExtraction: string }>();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const handleExportDataExtractionResult = async (
    resultId: string,
    exportFileType: ExportFileType,
  ) => {
    await dispatchWithUnwrap(exportDataExtraction({
      dataExtractionId,
      resultId,
      exportFileType,
    }));
  };

  return (
    <MainLayout
      overwriteMobilePageTitle='Processing Results'
    >
    <PageWrapperWithFooter>
      <PageContent noPadding>
        <DataExtractionResultOverview
          dataExtractionId={dataExtractionId}
          onExportDataExtractionResult={handleExportDataExtractionResult}
        />
      </PageContent>
    </PageWrapperWithFooter>
  </MainLayout>
  )
}

export default DataExtractionResult;
