import WrapperWithTooltip from '../Tooltip';
import Button from '../Button';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { ButtonProps } from '../Button/Button';
import useSubscriptionStatus from '../../hooks/useSubscriptionStatus';
import PAST_DUE_SUBSCRIPTION_STATUSES from '../../constants/PastDueSubscriptionStatuses';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../types/ReduxState';
import { PermissionGroup } from '../../enums/PermissionGroup';
import { noop } from 'lodash';
import BillingPortalLink from '../BillingPortalLink';

import styles from './ButtonWithSubscriptionCheck.module.scss';

const ButtonWithSubscriptionCheck: FC<PropsWithChildren<ButtonProps & { tooltip?: ReactNode | null }>> = ({
  tooltip,
  disabled,
  onClick,
  alwaysClickable,
  children,
  ...props
}) => {
  const { user } = useSelector((state: ReduxState) => state.sessionInfo);

  const {
    subscriptionStatus,
    isLegalAddressProvided,
    hasDefaultPaymentMethod,
    hasFreeBalance,
  } = useSubscriptionStatus();

  const isSubscriptionPastDue = subscriptionStatus && PAST_DUE_SUBSCRIPTION_STATUSES.includes(subscriptionStatus);

  const isLegalAddressNotProvidedWithPaymentMethod = !isLegalAddressProvided && hasDefaultPaymentMethod;
  const isFreeBalanceEndedWithoutPaymentDetails = !hasFreeBalance
    && (!hasDefaultPaymentMethod || !isLegalAddressProvided);

  const geTooltip = () => {
    const userIsOwner = user?.permissionGroup === PermissionGroup.Owner;

    if (isSubscriptionPastDue) {
      return (
        <div className={styles.tooltipContainer}>
          <p>Your subscription is past due. <br />Please make a payment to avoid losing account access.</p>
          {userIsOwner && <BillingPortalLink className={styles.linkBtn} />}
        </div>
      );
    }

    if (isLegalAddressNotProvidedWithPaymentMethod) {
      return (
        <div className={styles.tooltipContainer}>
          <p>Legal address is not provided. <br />Please add legal address.</p>
          {userIsOwner && <BillingPortalLink className={styles.linkBtn} />}
        </div>
      )
    }

    if (isFreeBalanceEndedWithoutPaymentDetails) {
      return (
        <div className={styles.tooltipContainer}>
          <p>Your trial subscription has expired. <br />Please provide billing details to continue using the system.</p>
          {userIsOwner && <BillingPortalLink className={styles.linkBtn} />}
        </div>
      );
    }

    return null;
  };

  const isActionBlocked = isSubscriptionPastDue
    || isLegalAddressNotProvidedWithPaymentMethod
    || isFreeBalanceEndedWithoutPaymentDetails;

  return (
    <WrapperWithTooltip tooltip={geTooltip() || tooltip} interactive>
      <Button
        {...props}
        disabled={isActionBlocked || disabled}
        alwaysClickable={isActionBlocked || alwaysClickable}
        onClick={isSubscriptionPastDue ? noop : onClick}
      >
        {children}
      </Button>
    </WrapperWithTooltip>
  );
};

export default ButtonWithSubscriptionCheck;
