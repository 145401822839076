import { FC } from 'react';
import StyledSwitch from 'components/StyledSwitch';
import clsx from 'clsx';
import styles from './DataExtractionResultOutputSectionHeader.module.scss';

interface DataExtractionResultOutputSectionHeaderProps {
  disabled?: boolean;
  isJsonShown: boolean;
  onToggleShowJson: (status: boolean) => void;
}

const DataExtractionResultOutputSectionHeader: FC<DataExtractionResultOutputSectionHeaderProps> = ({
  disabled,
  isJsonShown,
  onToggleShowJson,
}) => {
  const handleShowJsonSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (disabled) {
      return;
    }

    onToggleShowJson(checked);
  }

  return (
    <div className={styles.container}>
      <h6 className={styles.title}>Output Data</h6>
      <div className={styles.showJsonSwitchContainer}>
        <StyledSwitch
          title='Show JSON'
          className={styles.showJsonSwitch}
          onChange={handleShowJsonSwitchChange}
          checked={isJsonShown}
          disabled={disabled}
        />
        <p className={clsx(styles.showJsonTitle, isJsonShown && styles.active)}>Show JSON</p>
      </div>
    </div>
  )
}

export default DataExtractionResultOutputSectionHeader;
