import React from 'react';
import clsx from 'clsx';
import DropdownButton, { DropdownButtonOption } from 'components/DropdownButton/DropdownButton';
import { SortByImage } from 'static/images';
import styles from './SortBySelect.module.scss';

interface SortBySelectProps<OptionType> {
  onSelect: (option: OptionType) => void;
  selectedOption: OptionType | null;
  className?: string;
  options: OptionType[];
  disabled?: boolean;
}

const SORT_BY_BUTTON_TOOLTIP_TEXT = 'Sort Options';

const SortBySelect = <OptionType extends DropdownButtonOption>({
  onSelect,
  selectedOption,
  className,
  options,
  disabled,
}: SortBySelectProps<OptionType>) => {
  return (
    <DropdownButton
      options={options}
      onSelect={(option) => onSelect(option as OptionType)}
      selectedOption={selectedOption}
      className={clsx(styles.sortBySelect, className)}
      popupClassName={styles.dropdownList}
      buttonClassName={styles.sortButton}
      tooltip={SORT_BY_BUTTON_TOOLTIP_TEXT}
      disabled={disabled}
    >
      <SortByImage className={styles.sortByIcon} />
    </DropdownButton>
  );
};

export default SortBySelect;
