import { isValidPhoneNumber } from 'react-phone-number-input';
import { MessageType } from 'constants/messages';

const ID_NUMBER_DEFAULT_LENGTH = 9;
const HTTP_SCHEME = 'http:';
const HTTPS_SCHEME = 'https:';
const LOCAL_HOSTNAME = 'localhost';
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;

export const isEmptyField = (value: string): boolean => !value.trim();

export const validateEmail = (email: string): string => {
  return email && EMAIL_REGEX.test(email) ? '' : 'Invalid email address';
};

export const validatePassword = (password: string): MessageType | null => {
  if (password.length === 0) {
    return MessageType.PasswordRequired;
  }

  if (password.length < 8 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
    return MessageType.PasswordRequirementsNotMet;
  }

  return null;
};

export const removeWhiteSpace = <Value>(value: Value): Value | string => {
  if (typeof value === 'string') {
    return value.replace(/^\s+/, '');
  }

  return value;
};

export const validateIDNumber = (value: string, template?: string) => {
  if (!template || template === 'Other') {
    return '';
  }

  return value.length === ID_NUMBER_DEFAULT_LENGTH ? '' : 'Invalid identification number';
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return isValidPhoneNumber(phoneNumber) ? '' : 'Invalid phone number';
};

export const validateUrl = (urlString: string) => {
  try {
    const url = new URL(urlString);

    if (url.hostname === LOCAL_HOSTNAME) {
      return 'Invalid URL: URL must be publicly accessible.';
    }

    return url.protocol === HTTP_SCHEME || url.protocol === HTTPS_SCHEME
      ? ''
      : 'Invalid URL: An explicit scheme (such as https or http) must be provided.';
  } catch (err) {
    return 'Invalid URL: An explicit scheme (such as https or http) must be provided.';
  }
};

const validateNumberCap = (
  value: string | number,
  cap: string | null | undefined,
  minCap?: boolean,
  formatCap?: (cap: string) => string,
) => {
  if (!cap) {
    return '';
  }

  const numberValue = typeof value === 'string' ? Number.parseFloat(value) : value;
  const numberCap = Number.parseFloat(cap);

  const formattedCap = formatCap ? formatCap(cap) : cap;

  if (!minCap) {
    return numberValue <= numberCap ? '' : `The maximum value is ${formattedCap}.`;
  }

  return numberValue >= numberCap ? '' : `The minimum value is ${formattedCap}.`;
};

const validateDateCap = (
  value: string,
  cap: string | null | undefined,
  minCap?: boolean,
  formatCap?: (cap: string) => string,
) => {
  if (!cap) {
    return '';
  }

  const dateCap = Date.parse(cap);
  const dateValue = Date.parse(value);

  const formattedCap = formatCap ? formatCap(cap) : cap;

  if (!minCap) {
    return dateValue <= dateCap ? '' : `The maximum value is ${formattedCap}.`;
  }

  return dateValue >= dateCap ? '' : `The minimum value is ${formattedCap}.`;
};

export const validateMinNumberCap = (
  value: string | number,
  minAllowedValue: string | null | undefined,
  formatCap?: (cap: string) => string,
) => {
  return validateNumberCap(value, minAllowedValue, true, formatCap);
};

export const validateMaxNumberCap = (
  value: string | number,
  maxAllowedValue: string | null | undefined,
  formatCap?: (cap: string) => string,
) => {
  return validateNumberCap(value, maxAllowedValue, false, formatCap);
};

export const validateMinDateCap = (
  value: string,
  minAllowedValue: string | null | undefined,
  formatCap?: (cap: string) => string,
) => {
  return validateDateCap(value, minAllowedValue, true, formatCap);
};

export const validateMaxDateCap = (
  value: string,
  maxAllowedValue: string | null | undefined,
  formatCap?: (cap: string) => string,
) => {
  return validateDateCap(value, maxAllowedValue, false, formatCap);
};

export const validateNameField = (name: string) => {
  const maxLength = 60;

  if (name.length > maxLength) {
    return 'The maximum length is 60 characters.';
  }

  const validationRegex = /^[a-zA-Z\s,\-_\(\)\&0-9]+$/gi;

  if (!name.match(validationRegex)) {
    return 'Allowed characters: a-Z, 0-9, space and ( ) & - _ ,';
  }

  return '';
};
