import React, { FC } from 'react';
import InfiniteScrollComponent from 'react-infinite-scroll-component';
import Spinner from 'components/Spinner';
import styles from './InfiniteScroll.module.scss';

interface InfiniteScrollProps {
  containerId: string;
  dataLength: number;
  onLoadMore: () => void;
  hasMore?: boolean;
  renderLoader?: () => void;
  className?: string;
}

const InfiniteScroll: FC<InfiniteScrollProps> = ({
  containerId,
  dataLength,
  onLoadMore,
  hasMore,
  renderLoader,
  children,
  className,
}) => {
  const renderDefaultLoader = () => (
    <div className={styles.loaderContainer}>
      <Spinner size={24} />
    </div>
  );

  return (
    <InfiniteScrollComponent
      dataLength={dataLength}
      next={onLoadMore}
      hasMore={hasMore || false}
      loader={renderLoader?.() || renderDefaultLoader()}
      scrollableTarget={containerId}
      className={className}
    >
      {children}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;
