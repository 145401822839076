import DateTimeUtils from 'utils/dateUtils';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export type DateRange = {
  from: Date | null;
  to: Date | null;
};

export enum DateTimeFormat {
  Long = 'MMM D, YYYY, h:mm A',
  LongTheSameSize = 'MMM DD, YYYY, hh:mm A',
  Short = 'lll',
  ShortWithSlashes = 'DD/MM/YYYY',
  UsShortWithSlashes = 'MM/DD/YYYY',
  LongWithSeconds = 'MMM D, YYYY h:mm:ss A',
  ShortDate = 'MMMM DD, YYYY',
  DateWithShowMonth = 'MMM DD, YYYY',
  LongWithDayOfTheWeek = 'dddd, MMMM D, YYYY h:mm A',
  ISOString = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  ShortTime = 'h:mm A',
  IsoStringDateOnly = 'YYYY-MM-DD',
  ShortForMobile = 'MMM D, YYYY'
}

const formatDate = (date: string | Date | number, format: string = DEFAULT_DATE_FORMAT) => {
  return DateTimeUtils.parse(date).format(format);
};

export default formatDate;
