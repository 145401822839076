import { FC } from 'react'
import { MobileTableColumnSize } from '../../TableColumnSize';
import { TableBodyCell, TableRow } from 'components/Table';
import { SkeletonRectangle } from 'components/Skeleton';

import styles from './SkeletonDataExtractionResultMobileRow.module.scss';

interface SkeletonDataExtractionResultMobileRowProps {
  className?: string;
}

const SkeletonDataExtractionResultMobileRow: FC<SkeletonDataExtractionResultMobileRowProps> = ({
  className,
}) => {
  return (
    <TableRow disableHover className={className}>
      <TableBodyCell width={MobileTableColumnSize.Date} noPadding className={styles.fileNameCell}>
        <div className={styles.fileNameContainer}>
          <SkeletonRectangle width="80%" height="16px" color="primary6" />
        </div>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={MobileTableColumnSize.Result} className={styles.resultCell}>
        <SkeletonRectangle className={styles.status} width="80%" height="16px" color="primary6" />
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
    </TableRow>
  )
}

export default SkeletonDataExtractionResultMobileRow;
