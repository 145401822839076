import React from 'react';
import { TableColumnSize } from 'components/Users/Dashboards/UsersDashboard/TableColumnSize';
import TableRow from 'components/Table/TableRow';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableActionCell from 'components/Table/TableActionCell';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';

const SkeletonUserRow = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={TableColumnSize.FirstName} noPadding>
        <SkeletonCircle width="28px" height="28px" color="primary6" marginRight="8px" />
        <SkeletonRectangle width="60%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.LastName}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.Email}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.PermissionGroup}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.PhoneAuthentication}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.AccountStatus}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonUserRow;
