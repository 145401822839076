export enum DocumentTypesActionType {
  SetDocumentTypes = 'documentTypes/setDocumentTypes',
  GetDocumentTypes = 'documentTypes/getDocumentTypes',
  CreateDocumentType = 'documentTypes/createDocumentType',
  UpdateDocumentType = 'documentTypes/updateDocumentType',
  DeleteDocumentType = 'documentTypes/deleteDocumentType',
  GetDocumentType = 'documentTypes/getDocumentType',
  DuplicateDocumentType = 'documentTypes/duplicateDocumentType',
  ResetDocumentTypes = 'documentTypes/resetDocumentTypes',
}
