import React, { FC } from 'react';
import useConfirmChanges from 'hooks/useConfirmChanges';
import ConfirmPopup from 'components/ConfirmPopup';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { DataExtraction } from '../../../api/DataExtractionApi';
import { deleteDataExtraction, exportDataExtraction } from '../../../DataExtractions/Thunks';
import { ExportFileType } from '../../../api/DataExtractionExportApi';
import {
  dataExtractionResultsViewActionOrigin,
} from '../../../GeneralSettings/ui/DataExtractionResultsView/DataExtractionResultsViewStore';

interface IChildrenParams {
  handleDeleteDataExtraction: (dataExtraction: DataExtraction) => void;
  handleDownloadResults: (dataExtractionId: string, exportFileType: ExportFileType) => void;
  isResultsDownloadInProgress?: boolean;
  downloadFor?: string | null;
}

export interface IDataExtractionBasicActionsConnectorProps {
  onDeleteComplete?: () => void;
  children: (params: IChildrenParams) => React.ReactNode;
}

const DataExtractionBasicActionsConnector: FC<IDataExtractionBasicActionsConnectorProps> = ({
  children,
  onDeleteComplete,
}) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [
    displayConfirmDeleteDataExtractionPopup,
    onResetConfirmDeleteDataExtraction,
    onConfirmDeleteDataExtraction,
    createConfirmDeleteDataExtractionCallback,
  ] = useConfirmChanges();

  const [
    isDeleteInProgress,
    handleDeleteDecision,
  ] = useAsyncActionCallback(async (dataExtraction: DataExtraction) => {
    await dispatchWithUnwrap(deleteDataExtraction({
        id: dataExtraction.id,
        actionOrigin: dataExtractionResultsViewActionOrigin,
      },
    ));

    onDeleteComplete?.();
  }, [onDeleteComplete]);

  const [
    isResultsDownloadInProgress,
    handleDataExtractionResultsDownload,
    [downloadFor],
  ] = useAsyncActionCallback(async (dataExtractionId: string, exportFileType: ExportFileType) => {
    await dispatchWithUnwrap(exportDataExtraction({
      dataExtractionId,
      exportFileType,
    }));
  }, []);

  const handleDeleteDecisionWithConfirm = createConfirmDeleteDataExtractionCallback(handleDeleteDecision);

  const renderConfirmDeleteDecisionPopup = () => {
    if (!displayConfirmDeleteDataExtractionPopup) {
      return null;
    }

    return (
      <ConfirmPopup
        title="Delete Results"
        message="Are you sure you want to delete processing results?"
        confirmText="Delete Results"
        declineText="No, Go Back"
        onPopupClose={onResetConfirmDeleteDataExtraction}
        onConfirmClick={onConfirmDeleteDataExtraction}
        loading={isDeleteInProgress}
        usePortal
      />
    );
  };

  return (
    <>
      {renderConfirmDeleteDecisionPopup()}
      {children({
        handleDeleteDataExtraction: handleDeleteDecisionWithConfirm,
        handleDownloadResults: handleDataExtractionResultsDownload,
        isResultsDownloadInProgress,
        downloadFor,
      })}
    </>
  )
};

export default DataExtractionBasicActionsConnector;
