import DateTimeUtils from 'utils/dateUtils';

const DATE_FORMAT = 'YYYY-MM-DD';

const splitDateRange = (startDate: Date, endDate: Date) => {
  const dates = new Set<string>();

  let currentDate = startDate;

  while (currentDate <= endDate) {
    dates.add(DateTimeUtils.parse(currentDate).format(DATE_FORMAT));

    currentDate = DateTimeUtils.parse(currentDate)
      .add({ days: 1 })
      .toDate();
  }

  dates.add(DateTimeUtils.parse(endDate).format(DATE_FORMAT));

  const result = [...dates.values()];
  result[0] = DateTimeUtils.parse(startDate).format(DATE_FORMAT);

  return result;
};

export default splitDateRange;
