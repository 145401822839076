import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import styles from './Divider.module.scss';

interface RuleBuilderDividerProps {
  className?: string;
  children: ReactNode;
}

const Divider: FC<RuleBuilderDividerProps> = ({ className, children }) => (
  <div className={clsx(styles.titleContainer, className)}>{children}</div>
);

export default Divider;
