import React, { FC } from 'react';
import Table from 'components/Table';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import { SkeletonDataExtractionDesktopRow, SkeletonDataExtractionMobileRow } from './SkeletonDataExtractionRow';
import Pagination from 'components/Pagination';
import useSorting from 'hooks/useSorting';
import { NullableItems, PaginationProps } from 'pagination';
import { DataExtraction, DataExtractionSortingField, DataExtractionSortingType } from 'api/DataExtractionApi';
import { User } from 'api/UserApi';
import { DataExtractionDesktopHeaderRow, DataExtractionMobileHeaderRow } from './DataExtractionHeaderRow';
import { DataExtractionDesktopRow, DataExtractionMobileRow } from './DataExtractionRow';
import styles from './DataExtractionsTable.module.scss';
import { ExportFileType } from '../../../../api/DataExtractionExportApi';

interface DataExtractionsTableProps {
  dataExtractions: NullableItems<DataExtraction>;
  sortingType: DataExtractionSortingType;
  paginationProps: PaginationProps;
  members: User[]
  search?: string;
  onSort: (field: DataExtractionSortingField, ascending: boolean) => void;
  onViewDataExtraction: (dataExtraction: DataExtraction) => void;
  onDeleteDataExtraction: (dataExtractionId: string) => void;
  onExportDataExtraction: (dataExtractionId: string, exportFileType: ExportFileType) => void;
}

const DataExtractionsTable: FC<DataExtractionsTableProps> = ({
  dataExtractions,
  sortingType,
  paginationProps,
  members,
  search,
  onSort,
  onViewDataExtraction,
  onDeleteDataExtraction,
  onExportDataExtraction,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderRow = (dataExtraction: DataExtraction) => {
    const user = members.find(({ id }) => id === dataExtraction?.createdBy)

    const rowProps = {
      dataExtraction,
      user,
      search,
      onViewDataExtraction,
    };

    return (
      <>
        <DataExtractionDesktopRow
          className={styles.tableRowDesktop}
          onDeleteDataExtraction={onDeleteDataExtraction}
          onExportDataExtraction={onExportDataExtraction}
          key={dataExtraction.id + 'desktop'}
          {...rowProps}
        />
        <DataExtractionMobileRow
          className={styles.tableRowMobile}
          key={dataExtraction.id + 'mobile'}
          {...rowProps}
        />
      </>
    );
  };

  const renderHeaderRow = () => {
    return (
      <>
        <DataExtractionDesktopHeaderRow
          className={styles.tableRowDesktop}
          getSortingType={getSortingType}
          onSortingChange={changeSorting}
        />
        <DataExtractionMobileHeaderRow
          className={styles.tableRowMobile}
          getSortingType={getSortingType}
          onSortingChange={changeSorting}
        />
      </>
    );
  };

  const renderSkeletonRow = (index: number) => {
    return (
      <>
        <SkeletonDataExtractionDesktopRow key={index + 'desktop'} className={styles.tableRowDesktop} />
        <SkeletonDataExtractionMobileRow key={index + 'mobile'} className={styles.tableRowMobile} />
      </>
    );
  };

  return (
    <>
      <Table>
        {renderHeaderRow()}
        <TableBody>
          <TableBodyContent
            rows={dataExtractions}
            renderSkeletonTableRow={renderSkeletonRow}
            renderTableRow={renderRow}
          />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </>
  );
};

export default DataExtractionsTable;
