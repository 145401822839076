import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import ConnectedNotifications from 'Notifications/Notifications';

import styles from './Notifications.module.scss';

interface MainLayoutNotificationsProps {
  right: number;
}

const MainLayoutNotifications = ({ right }: MainLayoutNotificationsProps) => {
  const isLeftNavigationOpen = useSelector((state: ReduxState) => state.leftNavigation.isLeftNavOpen);
  const notificationsList = useSelector((state: ReduxState) => state.notification);

  const className = clsx(
    styles.mainLayoutNotifications,
    isLeftNavigationOpen ? styles.leftNavigationExpanded : styles.leftNavigationCollapsed,
    notificationsList.length === 0 && styles.empty,
  );

  return <ConnectedNotifications className={className} style={{ right }} />;
};

export default MainLayoutNotifications;
