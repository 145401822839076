import React, { FC, ReactElement, useRef } from 'react'
import HomeDashboardCardLayout from '../HomeDashboardCardLayout';
import { FormatXValueType, FormatYValueType } from 'components/Charts/LineChart/LineChart';
import { Point } from 'components/Charts/components/Types';
import PerformanceOverviewChart from './PerformanceOverviewChart';
import { PerformanceOverviewDashboardChart } from 'api/PerformanceOverviewMapper';

interface PerformanceOverviewProps {
  performanceOverview: PerformanceOverviewDashboardChart | null;
  className?: string;
  timeRangeSelect: ReactElement;
}

export interface ChartSettings {
  forceMaxY?: number;
  data?: Point[];
  total?: number;
  formatXValue: FormatXValueType;
  formatYValue: FormatYValueType;
}

const PerformanceOverview: FC<PerformanceOverviewProps> = ({
  className,
  performanceOverview,
  timeRangeSelect,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const renderChart = () => {
    const defaultPerformanceOverview: PerformanceOverviewDashboardChart = {
      points: [],
      total: 0,
    };

    return (
      <>
        <PerformanceOverviewChart
          performanceOverview={performanceOverview || defaultPerformanceOverview}
          targetRef={containerRef}
        />
      </>
    );
  }

  return (
    <HomeDashboardCardLayout
      title='Pages Processed'
      titleControl={timeRangeSelect}
      className={className}
      ref={containerRef}
      tag={`${performanceOverview?.total || 0}`}
    >
      <>
        {renderChart()}
      </>
    </HomeDashboardCardLayout>
  )
}

export default PerformanceOverview;
