import IpAddressWhitelist from 'components/IpAddressWhitelist';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'components/MainLayout';
import CreatePublicApiWhitelistPopup from 'pages/ApiCredentials/CreatePublicApiWhitelistPopup';
import { deletePublicApiWhitelist, getPublicApiKeys, getPublicApiWhitelists } from 'PublicApi/ActionCreator';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import notification from '../../handlers/notification/notificationActionCreator';
import WithIdentityConfirmActionPopup from '../../components/WithIdentityConfirmationActionPopup';

const IpAddressWhitelistPage: FC = () => {
  const dispatch = useDispatchWithUnwrap();

  const [
    isCreatePublicApiWhitelistPopupOpen,
    setIsCreatePublicApiWhitelistPopupOpen,
  ] = useState(false);
  const [
    publicApiWhitelistIdToDelete,
    setPublicApiWhitelistIdToDelete,
  ] = useState<string | null>(null);

  const { whitelists } = useSelector((state: ReduxState) => state.publicApi);
  const currentUser = useSelector((state: ReduxState) => state.users.currentUser);

  const handleCreatePublicApiWhitelistPopupClose = async () => {
    await dispatch(getPublicApiWhitelists());
    setIsCreatePublicApiWhitelistPopupOpen(false);
  };

  const handleDeleteApiWhitelistPopupClose = async () => {
    setPublicApiWhitelistIdToDelete(null);
  };

  const handleDeleteWhitelist = async () => {
    if (!publicApiWhitelistIdToDelete) {
      return;
    }

    await dispatch(deletePublicApiWhitelist(publicApiWhitelistIdToDelete));
    await dispatch(getPublicApiWhitelists());

    notification.createNotification('The IP Address has been removed.', 'success', dispatch);

    setPublicApiWhitelistIdToDelete(null);
  };

  const getOverlay = (): ReactElement | null => {
    if (isCreatePublicApiWhitelistPopupOpen) {
      return (
        <CreatePublicApiWhitelistPopup
          ipsTotalCount={whitelists!.length}
          onClose={handleCreatePublicApiWhitelistPopupClose}
        />
      );
    }

    if (publicApiWhitelistIdToDelete) {
      return (
        <WithIdentityConfirmActionPopup
          actionName="Delete IP Address"
          onConfirmationComplete={handleDeleteWhitelist}
          onClose={handleDeleteApiWhitelistPopupClose}
        />
      );
    }

    return null;
  };


  useEffect(() => {
    dispatch(getPublicApiKeys());
    dispatch(getPublicApiWhitelists());
  }, []);

  return (
    <MainLayout overlay={getOverlay()}>
      <PageWrapperWithFooter>
        <PageContent>
          <IpAddressWhitelist
            whitelists={whitelists}
            onAddIpAddress={() => setIsCreatePublicApiWhitelistPopupOpen(true)}
            onDeleteIpAddress={(id: string) => setPublicApiWhitelistIdToDelete(id)}
            currentUser={currentUser}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default IpAddressWhitelistPage;
