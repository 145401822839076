import { FC, useState } from 'react';
import { User } from 'api/UserApi';
import { DocumentTypeField } from 'api/DocumentTypeFieldApi';
import { DocumentType } from 'api/DocumentTypeApi';
import ContextualViewContainer from 'components/ContextualViewContainer/ContextualViewContainer';
import DetailsHeader from 'components/DetailsHeader/DetailsHeader';
import DocumentTypeRowActions from 'components/DocumentType/DocumentTypeRowActions/DocumentTypeRowActions';
import DataToExtractSection from './DataToExtractSection/DataToExtractSection';
import { SkeletonRectangle } from 'components/Skeleton';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch/TabSwitch';
import AutoDetectionSection from 'components/DocumentType/DocumentTypeContextualView/AutoDetectionSection/AutoDetectionSection';
import styles from './DocumentTypeContextualView.module.scss';

enum DocumentTypeTab {
  DataToExtract = 'dataToExtract',
  AutoDetection = 'autoDetection',
}

const TABS: TabSwitchOption[] = [{
  id: DocumentTypeTab.DataToExtract,
  label: 'Data to Extract',
}, {
  id: DocumentTypeTab.AutoDetection,
  label: 'Auto-Detection',
}];

interface DocumentTypeContextualViewProps {
  documentType: DocumentType;
  documentTypeFields: DocumentTypeField[];
  updatedBy?: User;
  onClose: () => void;
  onEditDocumentType: (documentType: DocumentType) => void;
  onDeleteDocumentType: (documentType: DocumentType) => void;
  onDuplicateDocumentType: (documentType: DocumentType) => void;
  onAddDocumentTypeField: () => void;
  onEditDocumentTypeField: (documentTypeField: DocumentTypeField) => void;
  onDeleteDocumentTypeField: (documentTypeField: DocumentTypeField) => void;
  onUpdateDocumentType: (documentType: DocumentType) => Promise<void>;
}

const DocumentTypeContextualView: FC<DocumentTypeContextualViewProps> = ({
  documentType,
  documentTypeFields,
  updatedBy,
  onClose,
  onEditDocumentType,
  onDeleteDocumentType,
  onDuplicateDocumentType,
  onAddDocumentTypeField,
  onEditDocumentTypeField,
  onDeleteDocumentTypeField,
  onUpdateDocumentType,
}) => {
  const [selectedTab, setSelectedTab] = useState(DocumentTypeTab.DataToExtract);

  const documentTypeActionInfo = documentType ? {
    performedBy: updatedBy,
    actionDateAt: documentType.updatedAt,
  } : null;

  const handleAutoDetectionChange = async (checked: boolean) => {
    return onUpdateDocumentType({
      ...documentType,
      isAutoDetectionEnabled: checked,
    });
  };

  const renderActions = () => {
    return documentType
      ? <DocumentTypeRowActions
        onDelete={() => onDeleteDocumentType(documentType)}
        onDuplicate={() => onDuplicateDocumentType(documentType)}
        onEdit={() => onEditDocumentType(documentType)}
        className={styles.actionsButton}
      />
      : <SkeletonRectangle marginLeft="14px" marginRight="22px" width="24px" height="24px" color="primary6" />;
  }

  return (
    <ContextualViewContainer onClose={onClose}>
      <DetailsHeader
        title={documentType?.name}
        actionInfo={documentTypeActionInfo}
        referenceId={documentType?.id}
        actionsButtonComponent={renderActions()}
        titleClassName={styles.title}
      />
      {documentType && (
        <TabSwitch
          tabs={TABS}
          selectedTabId={selectedTab}
          onSelect={({ id }) => setSelectedTab(id as DocumentTypeTab)}
          className={styles.tabSwitch}
        />
      )}
      {selectedTab === DocumentTypeTab.DataToExtract && (
        <DataToExtractSection
          documentTypeId={documentType?.id}
          documentTypeFields={documentTypeFields}
          onAddDocumentTypeField={onAddDocumentTypeField}
          onEditDocumentTypeField={onEditDocumentTypeField}
          onDeleteDocumentTypeField={onDeleteDocumentTypeField}
        />
      )}
      {selectedTab === DocumentTypeTab.AutoDetection && (
        <AutoDetectionSection documentType={documentType} onAutoDetectionChange={handleAutoDetectionChange} />
      )}
    </ContextualViewContainer>
  )
}

export default DocumentTypeContextualView
