import React, { FC } from 'react';
import RightSidePopupContentLayout from 'components/RightSidePopupContentLayout';

interface ApplicationFilterLayoutProps {
  onClose: () => void;
  onClearFiltersClick: () => void;
  filterTitleClassName?: string;
}

const FilterLayout: FC<ApplicationFilterLayoutProps> = ({
  children,
  onClose,
  onClearFiltersClick,
  filterTitleClassName,
}) => {
  return (
    <RightSidePopupContentLayout
      title="Filters"
      onTitleButtonClick={onClearFiltersClick}
      titleButtonName="Reset"
      onClose={onClose}
      titleClassName={filterTitleClassName}
    >
      {children}
    </RightSidePopupContentLayout>
  );
};

export default FilterLayout;
