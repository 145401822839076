import { Reducer } from '@reduxjs/toolkit';
import {
  DashboardActionType,
  IBaseCreateDashboardSliceParams,
  createDashboardSlice,
} from 'DashboardSlice/createDashboardSlice';
import {
  DataExtraction,
  DataExtractionFilters,
  DataExtractionSortingField,
  DataExtractionSortingType,
} from 'api/DataExtractionApi';
import { ItemsStateWithPagination } from 'pagination';
import { ExtractionHistoryDashboardActionType } from './ExtractionHistoryDashboardActionType';
import { ExtractionHistoryDashboardFiltersActionType } from './Filters/ExtractionHistoryDashboardFiltersActionType';
import createExtractionHistoryDashboardFiltersSlice, {
  ExtractionHistoryDashboardFiltersState,
} from './Filters/ExtractionHistoryDashboardFiltersStore';
import createExtractionHistoryDashboardPagination, { DATA_EXTRACTIONS_RESULTS_PER_PAGE_DEFAULT } from './Pagination';
import { getDataExtractions } from 'DataExtractions/Thunks';

export interface ExtractionHistoryDashboardState extends ItemsStateWithPagination<string> {
  sortingType: DataExtractionSortingType;
  searched: {
    [index: string]: ItemsStateWithPagination<string>;
  },
  filters: ExtractionHistoryDashboardFiltersState;
}

const extractionHistoryDashboardActionOrigin = 'defaultDataExtractionDashboard';
const extractionHistoryDashboardStatePath = ['generalSettings', 'ui', 'extractionHistoryDashboard'];

export const createExtractionHistoryDashboardSlice = ({ namespace, path, actionOrigins }: IBaseCreateDashboardSliceParams) => {
  const {
    actions: filtersActions,
    getInitialState: getFiltersInitialState,
    reducer: filtersReducer,
  } = createExtractionHistoryDashboardFiltersSlice(namespace);

  const pagination = createExtractionHistoryDashboardPagination(path);

  const actionTypes = {
    [DashboardActionType.ChangeSortingType]: ExtractionHistoryDashboardActionType.ChangeSortingType,
    [DashboardActionType.ResetState]: ExtractionHistoryDashboardActionType.ResetState,
    [DashboardActionType.ChangeMembers]: ExtractionHistoryDashboardFiltersActionType.ChangeMembers,
    [DashboardActionType.ChangeFilters]: ExtractionHistoryDashboardFiltersActionType.ChangeFilters,
    [DashboardActionType.ClearFilters]: ExtractionHistoryDashboardFiltersActionType.ClearFilters,
  };

  const defaultExtractionHistoryDashboardSlice = createDashboardSlice<
    DataExtraction,
    DataExtractionFilters,
    DataExtractionSortingType,
    ExtractionHistoryDashboardFiltersState,
    typeof actionTypes
  >({
    namespace,
    path,
    actionOrigins,
    itemsPerPage: DATA_EXTRACTIONS_RESULTS_PER_PAGE_DEFAULT,
    defaultSorting: { field: DataExtractionSortingField.CreatedAt, ascending: false },
    getItems: getDataExtractions,
    actionTypes,
    filtersAndSortingProps: [
      'createdAt',
      'originalFileName',
      'fileType',
      'status',
      'pagesCount',
      'totalDurationTime',
      'teamMembers',
      'documentTypeId',
      'createdAtRange',
    ],
    filtersReducer: filtersReducer as Reducer<ExtractionHistoryDashboardFiltersState>,
    getFiltersInitialState,
    filtersActions,
  });

  const {
    actions,
    reducer,
    filtersAndSortingSaver,
    getInitialState,
  } = defaultExtractionHistoryDashboardSlice;

  return {
    actions,
    reducer: pagination.wrapReducer(reducer),
    filtersAndSortingSaver,
    getFiltersInitialState,
    getInitialState,
    pagination,
    filtersActions,
  }
};

const extractionHistoryDashboardSlice = createExtractionHistoryDashboardSlice({
  namespace: 'extractionHistoryDashboard',
  path: extractionHistoryDashboardStatePath,
  actionOrigins: [extractionHistoryDashboardActionOrigin],
});

const {
  actions,
  reducer,
  filtersAndSortingSaver,
  getInitialState,
  pagination,
  filtersActions,
} = extractionHistoryDashboardSlice;

export {
  getInitialState,
  pagination,
  filtersAndSortingSaver,
  extractionHistoryDashboardActionOrigin,
  extractionHistoryDashboardStatePath,
};

export const {
  changeSortingType,
  resetState,
} = actions;

export const {
  changeMembers,
  clearFilters,
  changeFilters,
  changeSearchValue,
  hideFiltersPanel,
  showFiltersPanel,
} = filtersActions;

export default reducer;
