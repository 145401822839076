import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import { RowActionsContainer } from 'components/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import { DocumentType } from 'api/DocumentTypeApi';
import DocumentTypeRowActions from 'components/DocumentType/DocumentTypeRowActions/DocumentTypeRowActions';
import { User } from 'api/UserApi';
import { ReactComponent as AutoDetectionLabel } from './auto-detection-label.svg';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './DocumentTypesListItem.module.scss';

export interface DocumentTypesListItemProps {
  documentType: DocumentType;
  members: User[]
  onDuplicate: (documentType: DocumentType) => void;
  onDelete: (documentType: DocumentType) => void;
  onEdit: (documentType: DocumentType) => void;
  onDocumentTypeSelect: (documentTypeId: string) => void;
}

const DocumentTypesListItem = ({
  documentType,
  members,
  onDuplicate,
  onDelete,
  onEdit,
  onDocumentTypeSelect,
}: DocumentTypesListItemProps) => {
  const user = members.find((member) => member.id === documentType.updatedBy);

  const handleDocumentTypeSelect = () => {
    onDocumentTypeSelect(documentType.id);
  };

  const handleDuplicate = () => {
    onDuplicate(documentType);
  };

  const handleDelete = () => {
    onDelete(documentType);
  };

  const handleEdit = () => {
    onEdit(documentType);
  };

  const renderAutoDetectionLabel = () => {
    if (documentType.isAutoDetectionEnabled) {
      return (
        <WrapperWithTooltip tooltip="Document type is available for auto-detection.">
          <AutoDetectionLabel className={styles.autoDetectionLabel} />
        </WrapperWithTooltip>
      );
    }

    return null;
  };

  return (
    <RowActionsContainer>
        <div className={styles.container} onClick={handleDocumentTypeSelect}>
          <div className={styles.content}>
            <div className={styles.documentTypeNameContainer}>
              <span className={styles.documentTypeName}>{documentType.name}</span>
            </div>
            <div className={styles.documentTypeFooter}>
              <WithSystemApiUserAvatar className={styles.avatar} size={7.15} user={user || null} />
              <p>Updated {formatDate(documentType.updatedAt, DateTimeFormat.Long)}</p>
            </div>
          </div>
          <DocumentTypeRowActions
            className={styles.rowActionsContainer}
            onDuplicate={handleDuplicate}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          {renderAutoDetectionLabel()}
        </div>
    </RowActionsContainer>
  );
};

export default DocumentTypesListItem;
