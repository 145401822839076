import React from 'react';
import clsx from 'clsx';
import SkeletonColorType from 'components/Skeleton/SkeletonColorType';

import styles from './SkeletonRectangle.module.scss';

export interface SkeletonRectangleProps {
  width: string;
  height: string;
  radius?: string;
  color: SkeletonColorType;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  marginTop?: string;
  className?: string;
  stopAnimation?: boolean;
}

const getElementStyle = (color: SkeletonColorType) => {
  switch (color) {
    case 'primary6':
      return styles.skeletonPrimary6;
    case 'primary10':
      return styles.skeletonPrimary10;
    case 'primary20':
      return styles.skeletonPrimary20;
    default:
      return styles.skeletonPrimary6;
  }
};

const getElementAnimation = (color: SkeletonColorType) => {
  switch (color) {
    case 'primary6':
      return styles.skeletonAnimationPrimary6;
    case 'primary10':
      return styles.skeletonAnimationPrimary10;
    case 'primary20':
      return styles.skeletonAnimationPrimary20;
    default:
      return styles.skeletonAnimationPrimary6;
  }
};

const SkeletonRectangle = ({
  width,
  height,
  radius,
  color,
  className,
  marginLeft,
  marginRight,
  marginBottom,
  marginTop,
  stopAnimation,
}: SkeletonRectangleProps) => {
  const style = {
    width,
    height,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    borderRadius: radius,
  };

  return (
    <div
      style={style}
      className={clsx(
        getElementStyle(color),
        !stopAnimation && getElementAnimation(color),
        styles.skeletonRectangle,
        className,
      )}
    />
  );
};

export default SkeletonRectangle;
