import createDashboardFiltersSlice, {
  BaseDashboardFiltersState,
  DashboardFiltersActionType,
  getDashboardFiltersInitialState,
} from 'DashboardSlice/createDashboardFiltersSlice';
import { DataExtractionResultsViewFiltersActionType } from './DataExtractionResultsViewFiltersActionType';
import { createReducer } from '@reduxjs/toolkit';
import reduceReducers from 'reduce-reducers';

export interface ChangeFiltersPayload {
  dataExtractionId: string | null;
}

export interface DataExtractionResultsViewFiltersState extends BaseDashboardFiltersState {
  dataExtractionId: string | null;
}

const createDataExtractionResultsFiltersSlice = (namespace: string) => {
  const getInitialState = (): DataExtractionResultsViewFiltersState => ({
    ...getDashboardFiltersInitialState(),
    dataExtractionId: null,
  });

  const actionTypes = {
    [DashboardFiltersActionType.ChangeFilters]: DataExtractionResultsViewFiltersActionType.ChangeFilters,
    [DashboardFiltersActionType.ClearFilters]: DataExtractionResultsViewFiltersActionType.ClearFilters,
  };

  const {
    reducer,
    actions,
  } = createDashboardFiltersSlice<
    DataExtractionResultsViewFiltersState,
    ChangeFiltersPayload,
    typeof actionTypes
  >(
    namespace,
    getInitialState,
    actionTypes,
  );

  const dataExtractionResultsViewFiltersReducer = createReducer(getInitialState(), (builder) => {
    builder.addCase(actions.changeFilters, (state, action) => {
      state.dataExtractionId = action.payload.dataExtractionId;
    });

    builder.addCase(actions.clearFilters, (state) => {
      state.dataExtractionId = null;
    });
  });

  return {
    actions,
    reducer: reduceReducers(dataExtractionResultsViewFiltersReducer, reducer),
    getInitialState,
  };
};

export default createDataExtractionResultsFiltersSlice;
