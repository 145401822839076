import { createAsyncThunk } from '@reduxjs/toolkit';
import { UsersActionType } from 'Users/ActionTypes';
import { userApi, userInviteApi } from 'stores/api';
import { UpdateUserPrams, User, UsersDataFilter, UsersSortingType } from 'api/UserApi';
import { TableViewData } from 'api/Types';
import { DataWithActionOrigin } from 'types/action-origin';
import { InviteUserParams } from 'api/UserInviteApi';

export const getUsers = createAsyncThunk<
  TableViewData<User>,
  DataWithActionOrigin<{ filters: UsersDataFilter, sortingType: UsersSortingType; }>,
  { fulfilledMeta: DataWithActionOrigin<{ search?: string; }> }
>(
  UsersActionType.GetUsers,
  async ({
    filters,
    sortingType,
    actionOrigin,
  }, thunkAPI) => {
    return thunkAPI.fulfillWithValue(await userApi.find(filters, sortingType), {
      search: filters?.search,
      actionOrigin,
    });
  },
);

export const getCurrentUser = createAsyncThunk(
  UsersActionType.GetCurrentUser,
  async () => {
    return userApi.getCurrentUser();
  },
);

export const getUser = createAsyncThunk(
  UsersActionType.GetUser,
  (userId: string) => {
    return userApi.getUserById(userId);
  },
);

export const inviteUser = createAsyncThunk(
  UsersActionType.InviteUser,
  (params: InviteUserParams) => {
    return userInviteApi.sendInvite(params);
  },
);

export const cancelInvitation = createAsyncThunk(
  UsersActionType.CancelInvitation,
  (userId: string) => {
    return userInviteApi.cancelInvitation(userId);
  },
);

export const resendUserInvite = createAsyncThunk(
  UsersActionType.ResendUserInvite,
  (userId: string) => {
    return userInviteApi.resendInvite(userId);
  },
);

export const updateUser = createAsyncThunk(
  UsersActionType.UpdateUser,
  ({ id, ...restParams }: UpdateUserPrams & { id: string }) => {
    return userApi.update(id, restParams);
  },
);
