import ActionPopUp from 'components/ActionPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { openMyAccount } from 'MyAccount/ActionCreator';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { AppRoutes } from 'routes/RouteBuilder';
import Separator from 'components/Separator';
import styles from './AccountMenu.module.scss';

interface AccountMenuProps {
  onClose: () => void;
  anchorElement: Element | null;
}

const AccountMenu: FC<AccountMenuProps> = ({
  anchorElement,
  onClose,
}) => {
  const routeBuilder = useRouteBuilder();
  const dispatch = useDispatch();

  const handleOpenMyAccount = () => {
    dispatch(openMyAccount());
    onClose();
  };
  const handleOpenTeamMembers = () => {
    routeBuilder.go(AppRoutes.teamMembers);
    onClose();
  };
  const handleOpenBilling = () => {
    routeBuilder.go(AppRoutes.billing);
    onClose();
  };

  const handleLogout = async () => {
    onClose();

    routeBuilder.go(AppRoutes.logout);
  };

  return (
    <ContextualPopUp
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorEl={anchorElement}
      open
      onClose={onClose}
      classes={{ paper: styles.popupContainer }}
      PopoverClasses={{ root: styles.root }}
    >
      <ActionPopUp title="Actions" className={styles.popup} onClose={onClose}>
        <ActionPopUpItem
          onClick={handleOpenMyAccount}
        >
          My Account
        </ActionPopUpItem>
        <ActionPopUpItem
          onClick={handleOpenTeamMembers}
        >
          Team Members
        </ActionPopUpItem>
        <ActionPopUpItem
          onClick={handleOpenBilling}

        >
          Billing
        </ActionPopUpItem>
        <Separator />
        <ActionPopUpItem
          onClick={handleLogout}
          danger
        >
          Log Out
        </ActionPopUpItem>
      </ActionPopUp>
    </ContextualPopUp>
  );
};

export default AccountMenu;
