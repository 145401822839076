export interface BaseActionPayload {
  id: string;
}

export interface Options<ActionPayload extends BaseActionPayload> {
  parallelActions?: number;
  throttlingPerParallelActions?: number;
  onError: (payload: ActionPayload[], error: Error) => void;
  onProcess: (payload: ActionPayload[]) => Promise<ActionPayload[]>;
  onCommit: (payload: ActionPayload[]) => void;
}

const DEFAULT_PARALLEL_ACTIONS = 3;

export default class QueueActions<ActionPayload extends BaseActionPayload> {
  protected isInProgress: boolean = false;

  protected queue: ActionPayload[] = [];

  constructor(protected readonly options: Options<ActionPayload>) {

  }

  public add(...payloads: ActionPayload[]) {
    this.queue.push(...payloads);

    this.start();
  }

  public async start() {
    if (this.isInProgress) {
      return;
    }

    this.isInProgress = true;

    while (this.queue.length > 0) {
      const actionsToProcess = this.queue.splice(0, this.options.parallelActions || DEFAULT_PARALLEL_ACTIONS);

      try {
        const startTime = Date.now();

        const processedActionPayloads = await this.options.onProcess(actionsToProcess);

        const duration = Date.now() - startTime;

        const throttleTime = this.options.throttlingPerParallelActions
          ? this.options.throttlingPerParallelActions - duration
          : 0;

        if (throttleTime > 0) {
          await this.sleep(throttleTime);
        }

        this.options.onCommit(processedActionPayloads);
      } catch (error) {
        this.options.onError(actionsToProcess, error);
      }
    }

    this.isInProgress = false;
  }

  public destroy() {
    this.queue.splice(0);
    this.isInProgress = false;
  }

  protected async sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
