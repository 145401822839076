import { DocumentTypeField } from 'api/DocumentTypeFieldApi';
import { FC, useMemo } from 'react';
import Table, {
  TableActionCell,
  TableBody,
  TableBodyContent,
  TableHead,
  TableHeadCell,
} from 'components/Table';
import DocumentTypeFieldsTableRow from './DocumentTypeFieldsTableRow/DocumentTypeFieldsTableRow';
import DocumentTypeFieldsTableCollapsibleRow from './DocumentTypeFieldsTableCollapsibleRow';
import { DocumentTypeFieldsTableColumnSize } from './DocumentTypeFieldsTableColumnSize';
import SkeletonFieldRow from './SkeletonFieldRow';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import TableStub from 'components/TableStub';

import styles from './DocumentTypeFieldsTable.module.scss';

interface DocumentTypeFieldsTableProps {
  documentTypeFields: DocumentTypeField[];
  onEditField: (field: DocumentTypeField) => void;
  onDeleteField: (field: DocumentTypeField) => void;
}

const NO_PARENT_KEY = 'NO_PARENT';

const DocumentTypeFieldsTable: FC<DocumentTypeFieldsTableProps> = ({
  documentTypeFields,
  onEditField,
  onDeleteField,
}) => {
  const { isLoading } = useSelector((state: ReduxState) => state.documentTypeFields);

  const documentTypeFieldsByParent = useMemo(() => {
    return documentTypeFields.reduce((result, field) => {
      if (field.parent) {
        const parent = documentTypeFields.find(({ id }) => id === field.parent);

        if (parent) {
          result[parent.id] ??= []
          result[parent.id].push(field);
        }
      } else {
        result[NO_PARENT_KEY].push(field);
      }

      return result;
    }, { [NO_PARENT_KEY]: [] } as Record<string, DocumentTypeField[]>);
  }, [documentTypeFields]);

  const renderField = (field: DocumentTypeField) => {
    const children = documentTypeFieldsByParent[field.id];

    if (children) {
      return (
        <DocumentTypeFieldsTableCollapsibleRow
          key={field.id}
          field={field}
          fieldChildren={children}
          onEditField={onEditField}
          onDeleteField={onDeleteField}
          nameCellClassName={styles.mobileNameTableCell}
          typeCellClassName={styles.mobileTypeTableCell}
        />
      );
    }

    return (
      <DocumentTypeFieldsTableRow
        key={field.id}
        field={field}
        onEditField={onEditField}
        onDeleteField={onDeleteField}
        nameCellClassName={styles.mobileNameTableCell}
        typeCellClassName={styles.mobileTypeTableCell}
      />
    );
  }

  const renderTableBody = () => {
    if (isLoading) {
      return Array.from({ length: 10 }).map((item, index) => (
        <SkeletonFieldRow
          key={index}
          nameCellClassName={styles.mobileNameTableCell}
          typeCellClassName={styles.mobileTypeTableCell}
        />
      ));
    }

    if (documentTypeFields.length === 0) {
      const sizes = Object.values(DocumentTypeFieldsTableColumnSize).map((value) => ({
        width: value as number,
      }))

      return (
        <TableStub
          columns={sizes}
          rows={5}
          description='No fields have been added to extract'
        />
      );
    }

    return (
      <TableBodyContent
        rows={documentTypeFieldsByParent.NO_PARENT || []}
        renderTableRow={renderField}
      />
    )
  }

  return (
    <Table>
      <TableHead sticky>
        <TableHeadCell
          width={DocumentTypeFieldsTableColumnSize.FieldName}
          className={styles.mobileNameTableCell}
        >
          Field Name
        </TableHeadCell>
        <TableHeadCell
          width={DocumentTypeFieldsTableColumnSize.FieldType}
          className={styles.mobileTypeTableCell}
        >
          Field Type
        </TableHeadCell>
        <TableActionCell />
      </TableHead>
      <TableBody>
        {renderTableBody()}
      </TableBody>
    </Table>
  )
}

export default DocumentTypeFieldsTable
