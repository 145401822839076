import { AnyAction, CombinedState, combineReducers, ReducersMapObject } from '@reduxjs/toolkit';
import { ReduxState } from 'types/ReduxState';
import organizationReducer from '../Organization/OrganizationStore';
import myAccountReducer from '../MyAccount/Reducer';
import leftNavReducer from '../components/MainLayout/leftNavReducer/LeftNavReducer';
import sessionInfoReducer from '../SessionInfo/Reducer';
import notificationReducer from './notification';
import usersReducer from 'Users/UsersStore';
import generalSettingsReducer from 'GeneralSettings';
import documentTypesReducer from 'DocumentTypes/DocumentTypesStore';
import documentTypeFieldsReducer from 'DocumentTypeFields/DocumentTypeFieldsStore';
import { doesActionRequireStateReset } from '../stores/Actions';
import dataExtractionsReducer from 'DataExtractions/DataExtractionsStore';
import dataExtractionResultsReducer from 'DataExtractionResults/DataExtractionResultsStore';
import dashboardReducer from 'Dashboard/Reducer';
import publicApiReducer from '../PublicApi/Reducer';
import billingPageReducer from '../BillingPage/BillingPageStore';
import webhooksReducer from 'Webhooks/WebhooksStore';

const appReducer = combineReducers<ReduxState>({
  myAccount: myAccountReducer,
  leftNavigation: leftNavReducer,
  sessionInfo: sessionInfoReducer,
  organization: organizationReducer,
  notification: notificationReducer,
  users: usersReducer,
  documentTypes: documentTypesReducer,
  documentTypeFields: documentTypeFieldsReducer,
  generalSettings: generalSettingsReducer,
  dataExtractions: dataExtractionsReducer,
  dataExtractionResults: dataExtractionResultsReducer,
  dashboard: dashboardReducer,
  publicApi: publicApiReducer,
  billingPage: billingPageReducer,
  webhooks: webhooksReducer,
} as unknown as ReducersMapObject<ReduxState, AnyAction>);

const rootReducer = (state: ReduxState | undefined, action: AnyAction): CombinedState<ReduxState> => {
  if (!state) {
    return appReducer(state, action);
  }

  let nextState: Partial<ReduxState> = state || {};

  if (state && doesActionRequireStateReset(action)) {
    nextState = {
      notification: state.notification,
    };
  }

  return appReducer(nextState as ReduxState, action);
};

export default rootReducer;
