import { FC } from 'react';
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { GoogleIcon } from 'static/images';
import { GoogleSignInData } from 'api/AuthApi';

import styles from './GoogleAuthButton.module.scss';

interface GoogleAuthButtonProps {
  onSignIn: (data: GoogleSignInData) => void;
};

const GoogleAuthButton: FC<GoogleAuthButtonProps> = ({
  onSignIn,
}) => {
  // This is default flow with button click
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      onSignIn({ code: tokenResponse.code });
    },
    flow: 'auth-code',
  });

  // This is google one tap flow with login from popup window
  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      if (!credentialResponse.credential) {
        return;
      }

      onSignIn({ token: credentialResponse.credential });
    },
  })

  return (
    <button onClick={() => login()} className={styles.button}>
      <GoogleIcon className={styles.icon} />
      Continue with Google
    </button>
  );
};

export default GoogleAuthButton;
