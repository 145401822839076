import { createSelector } from 'reselect';
import { ReduxState } from 'types/ReduxState';
import { NullableItems } from 'pagination';

export const getEventsById = (state: ReduxState) => {
  return state.webhooks.events.eventsById;
};

export const createGetEventsSelector = (ids: NullableItems<string>) => {
  return createSelector([getEventsById], (dataById) => {
    return ids.map((id) => id? dataById[id]: null);
  });
};
