import { ChangeEvent, FC, useEffect, useState } from 'react'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import { DocumentTypeFieldViewDataType } from 'enums/DocumentTypeFieldViewDataType';
import AutoCompletion from 'components/AutoCompletion';
import { DocumentTypeField } from 'api/DocumentTypeFieldApi';
import {
  getDataTypeByViewData,
  getViewDataType,
  isObjectViewDataType,
  optionDescriptionByViewDataType,
  removeSpecialCharactersFromString,
} from 'components/DocumentType/documentTypeUtils';
import { Option } from 'components/SelectInput/SelectInput';
import DocumentTypeFieldChildrenForm from 'components/DocumentType/Forms/DocumentTypeFieldChildrenForm';
import { trim } from 'lodash';
import styles from './DocumentTypeFieldsForm.module.scss';

interface DocumentTypeFieldFormProps {
  isActionOnProgress: boolean;
  submitButtonTitle: string;
  field?: DocumentTypeField;
  children?: DocumentTypeField[];
  onSubmit: (fieldViewData: DocumentTypeFieldViewData) => void;
}

export interface DocumentTypeFieldViewData {
  name: string;
  id?: string;
  dataType?: DocumentTypeFieldViewDataType;
  children?: DocumentTypeFieldChildrenViewData[];
}

export interface DocumentTypeFieldChildrenViewData extends Omit<DocumentTypeFieldViewData, 'children'> {
  error?: string;
}

const selectOptions: Option[] = Object.values(DocumentTypeFieldViewDataType).map((dataType) => ({
  value: dataType,
  name: dataType,
  description: optionDescriptionByViewDataType[dataType],
}));

const DUPLICATE_CHILDREN_NAME_ERROR = 'Field name should be unique.';

const DocumentTypeFieldForm: FC<DocumentTypeFieldFormProps> = ({
  field,
  children,
  isActionOnProgress,
  submitButtonTitle,
  onSubmit,
}) => {
  const [
    fieldData,
    setFieldData,
  ] = useState<DocumentTypeFieldViewData>({ name: '' });

  useEffect(() => {
    const initialChildren = children && children.map((child) => ({
      id: child.id,
      name: child.name,
      dataType: child.dataType && getViewDataType(child),
    }));

    setFieldData({
      name: field?.name || '',
      id: field?.id,
      dataType: field?.dataType && getViewDataType(field),
      children: initialChildren,
    })
  }, []);

  const validateChildrenName = (childrenViewData: DocumentTypeFieldChildrenViewData[]) => {
    const existingNames: Record<string, boolean> = {};

    return childrenViewData.map((child) => {
      if (!child.name) {
        return child;
      }

      const trimmedName = trim(child.name);

      if (existingNames[trimmedName]) {
        child.error = DUPLICATE_CHILDREN_NAME_ERROR;

        return child;
      }

      existingNames[trimmedName] = true;
      child.error = '';

      return child;
    });
  }

  const handleFieldNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = removeSpecialCharactersFromString(event.target.value);

    setFieldData((prevState) => ({ ...prevState, name }));
  };

  const handleFieldDataTypeChange = (option: Option) => {
    setFieldData((prevState) => ({ ...prevState, dataType: option.value }));
  };

  const handleFieldChildrenNameChange = (name: string, position: number) => {
    setFieldData((prevState) => {
      const fieldChildren = prevState.children || [];
      fieldChildren[position].name = removeSpecialCharactersFromString(name);

      const validatedChildren = validateChildrenName(fieldChildren)

      return {
        ...prevState,
        children: validatedChildren,
      }
    });
  };

  const handleFieldChildrenDataTypeChange = (dataType: DocumentTypeFieldViewDataType, position: number) => {
    setFieldData((prevState) => {
      const fieldChildren = prevState.children || [];
      fieldChildren[position].dataType = dataType;

      return {
        ...prevState,
        children: fieldChildren,
      }
    });
  };

  const handleAddChildren = () => {
    setFieldData((prevState) => ({ ...prevState, children: [...(prevState.children || []), { name: '' }] }));
  };

  const handleRemoveChildren = (position: number) => {
    setFieldData((prevState) => {
      const fieldChildren = prevState.children || [];
      fieldChildren.splice(position, 1);

      const validatedChildren = validateChildrenName(fieldChildren);

      return {
        ...prevState,
        children: validatedChildren,
      };
    });
  };

  const renderChildrenForm = () => {
    if (
      fieldData.dataType === DocumentTypeFieldViewDataType.Object ||
      fieldData.dataType === DocumentTypeFieldViewDataType.ArrayOfObjects
    ) {
      return (
        <DocumentTypeFieldChildrenForm
          fieldDataChildren={fieldData.children || []}
          onNameChange={handleFieldChildrenNameChange}
          onDataTypeChange={handleFieldChildrenDataTypeChange}
          onAddChildren={handleAddChildren}
          onRemoveChildren={handleRemoveChildren}
        />
      )
    }

    return null;
  };

  const isBaseFieldWasChanged = () => {
    if (!field) {
      return true;
    }

    const fieldDataTypeFromView = fieldData.dataType ? getDataTypeByViewData(fieldData) : undefined;

    return (
      field.name !== fieldData.name ||
      field.dataType !== fieldDataTypeFromView?.dataType ||
      field.isArray !== fieldDataTypeFromView?.isArray
    )
  };

  const isChildrenWasChanged = () => {
    if (!field || children?.length !== fieldData.children?.length) {
      return true;
    }

    return fieldData.children?.some((childViewData, index) => {
      const childrenDataTypeByView = childViewData.dataType
        ? getDataTypeByViewData(childViewData)
        : undefined;

      return (
        children?.[index]?.name !== childViewData.name ||
        children?.[index]?.dataType !== childrenDataTypeByView?.dataType
      )
    });
  };

  const isSubmitDisabled = () => {
    if (!fieldData.name.trim() || !fieldData.dataType) {
      return true;
    }

    const isChildrenValid = fieldData.children?.length
      ? fieldData.children.every((child) =>
        child.name.trim() && child.dataType && !child.error)
      : true;

    return (
      (isObjectViewDataType(fieldData.dataType) && !isChildrenValid) ||
      (!isBaseFieldWasChanged() && !isChildrenWasChanged())
    );
  };

  const handleSubmit = () => {
    const childrenWithTrimmedNames = fieldData.children
      ? fieldData.children.map((child) => ({ ...child, name: trim(child.name) }))
      : undefined;

    onSubmit({
      ...fieldData,
      name: trim(fieldData.name),
      children: childrenWithTrimmedNames,
    })
  };

  return (
    <>
      <TextInput
        required
        disabled={isActionOnProgress}
        labelTitle='Field Name'
        value={fieldData.name}
        placeholder='Field Name'
        containerClassName={styles.inputContainer}
        onChange={handleFieldNameChange}
      />
      <AutoCompletion
        disabled={isActionOnProgress}
        options={selectOptions}
        labelTitle='Data Type'
        required
        placeholder='Data Type'
        value={fieldData.dataType}
        onChange={handleFieldDataTypeChange}
      />
      {renderChildrenForm()}
      <Button
        kind='primary'
        size='form'
        disabled={isSubmitDisabled()}
        isLoading={isActionOnProgress}
        className={styles.submitButton}
        onClick={handleSubmit}
      >
        {submitButtonTitle}
      </Button>
    </>
  );
}

export default DocumentTypeFieldForm;
