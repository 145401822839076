import React, { FC } from 'react';
import { WebhookEndpoint } from 'api/WebhookEndpointsApi';
import DetailsForm from 'components/DetailsForm';
import Button from 'components/Button';
import NeedHelpSection from 'components/NeedHelpSection';
import styles from './EndpointIntegration.module.scss';

export interface EndpointIntegrationProps {
  webhookEndpoint: WebhookEndpoint | null;
  onGenerateNewSecret: () => void;
}

const INTEGRATION_COLUMNS = 1;

const EndpointIntegration: FC<EndpointIntegrationProps> = ({
  webhookEndpoint,
  onGenerateNewSecret,
}) => {
  const fields = webhookEndpoint ? [{
    label: 'Webhook Secret Key',
    value: webhookEndpoint.endpointSecret,
  }] : null

  return (
    <div className={styles.webhookEndpointIntegrationContainer}>
      <DetailsForm
        labelWidth="210px"
        title="Integration"
        columns={INTEGRATION_COLUMNS}
        fields={fields}
        hideEditButton
      />
      <Button
        disabled={!webhookEndpoint}
        kind="primary"
        className={styles.generateNewKeyButton}
        onClick={onGenerateNewSecret}
      >
        Generate New Key
      </Button>
      <NeedHelpSection
        className={styles.needHelpSection}
        link="https://synthetiq.readme.io/reference"
        linkTitle="User Guide"
      />
    </div>
  );
};

export default EndpointIntegration;
