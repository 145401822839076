import { PermissionGroup } from '../enums/PermissionGroup';
import EventEmitterWithUnsubscribeCallback, { UnsubscribeCallback } from './EventHandler';
import { SessionInfo } from 'SessionInfo/Types';

export enum AuthEventMessage {
  SessionInfoUpdate = 'authEvents/sessionInfoUpdate',
  UnauthorizedRequest = 'authEvents/unauthorizedRequest',
  Logout = 'authEvents/logout',
}

export interface UserInfoHeader {
  isMfaIncomplete?: true;
  isActive: boolean;
  isOwner: boolean;
  isEmailNotVerified?: boolean;
  permissionGroup: PermissionGroup;
}

export enum OrganizationSubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export interface OrganizationInfoHeader {
  id: string;
  subscriptionStatus: OrganizationSubscriptionStatus;
  hasDefaultPaymentMethod: boolean;
  isLegalAddressProvided: boolean;
  hasFreeBalance: boolean;
}

type Callback<Param> = (params: Param) => void;

export type PendingOperation = Promise<unknown>;

export interface AuthEventEmitterType {
  on(message: AuthEventMessage.SessionInfoUpdate, callback: Callback<Partial<SessionInfo>>): UnsubscribeCallback;
  on(message: AuthEventMessage.UnauthorizedRequest, callback: () => void): UnsubscribeCallback;
  on(message: AuthEventMessage.Logout, callback: () => void): UnsubscribeCallback;

  emit(message: AuthEventMessage.SessionInfoUpdate, userInfo: Partial<SessionInfo>): void;
  emit(message: AuthEventMessage.UnauthorizedRequest): void;
  emit(message: AuthEventMessage.Logout): void;
}

const AuthEventEmitter: AuthEventEmitterType = new EventEmitterWithUnsubscribeCallback();

export default AuthEventEmitter;
