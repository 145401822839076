import { AsyncThunkAction } from '@reduxjs/toolkit';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useOnce from './useOnce';

const createSelectorWithDispatch = <State, TSelected>(
  getAction: () => AsyncThunkAction<unknown, unknown, {}>,
  selector: (state: State) => TSelected,
  shouldRunActionSelector?: (state: State) => boolean,
) => {
  return () => {
    const dispatchWithUnwrap = useDispatchWithUnwrap();

    const dispatchAction = useOnce(() => {
      dispatchWithUnwrap(getAction());
    });

    const shouldRunAction = useSelector((state: State) => !shouldRunActionSelector || shouldRunActionSelector(state));

    useEffect(() => {
      if (shouldRunAction) {
        dispatchAction();
      }
    }, []);

    return useSelector(selector);
  };
};

export default createSelectorWithDispatch;
