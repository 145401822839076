import React, { FC } from 'react';
import clsx from 'clsx';
import { BillingInfo } from 'api/BillingApi';
import { OrganizationShortInfo } from 'api/OrganizationApi';
import PlanDetails from 'components/BillingDashboard/Overview/PlanDetails';
import CompanyInformation from 'components/BillingDashboard/Overview/CompanyInformation';
import Statistics from './Statistics';
import styles from './Overview.module.scss';

interface OverviewProps {
  billingInfo: BillingInfo | null;
  companyInfo: OrganizationShortInfo | null;
  onCompanyInfoEdit: (companyInfo: OrganizationShortInfo) => void;
}

const Overview: FC<OverviewProps> = ({
  billingInfo,
  companyInfo,
  onCompanyInfoEdit,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.columns}>
        <div className={styles.leftSideContainer}>
          <div className={styles.companyInformation}>
            <CompanyInformation info={companyInfo} onCompanyInfoEdit={onCompanyInfoEdit} />
          </div>

          <div className={styles.planDetails}>
            <PlanDetails
              freeCreditsRemaining={billingInfo?.freeCreditsRemainingCents}
              pricePerPage={billingInfo?.pricePerPageCents}
              currency={billingInfo?.currency}
            />
          </div>
        </div>

        <div className={clsx(styles.rightSideContainer, styles.statistics)}>
          <Statistics
            amountOwed={billingInfo?.amountOwed || 0}
            date={billingInfo?.reportDate || null}
            currency={billingInfo?.currency || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
