import moment from 'moment';
import { DateRange } from 'api/Types';
import TimeRange from 'enums/TimeRange';

const getDateRange = (timeRange: TimeRange): DateRange => {
  switch (timeRange) {
    case TimeRange.Last7Days:
      return {
        from: moment().subtract(7, 'days').toDate(),
        to: null,
      };
    case TimeRange.ThisMonth:
      return {
        from: moment().startOf('month').toDate(),
        to: null,
      };
    case TimeRange.AllTime:
      return {
        from: null,
        to: null,
      };
  }
};

export default getDateRange;
