import { createSelector } from 'reselect';
import { ReduxState } from 'types/ReduxState';
import { NullableItems } from 'pagination';

export const getDataExtractionsById = (state: ReduxState) => {
  return state.dataExtractions.dataExtractionsById;
}

export const createGetDataExtractionsSelector = (ids: NullableItems<string>) => {
  return createSelector([getDataExtractionsById], (dataExtractionsById) => {
    return ids.map((id) => id? dataExtractionsById[id]: null);
  });
};
