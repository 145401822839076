import React, { FC, useEffect } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import SecurePhoneAuthenticationForm from '../../components/Auth/SecurePhoneAuthenticationForm/SecurePhoneAuthenticationForm';
import AuthFormFooter from '../../components/Auth/AuthFormFooter/AuthFormFooter';
import { useAuthProvider } from '../../providers/AuthProvider';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { ReduxState } from '../../types/ReduxState';
import notification from '../../handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from '../../constants/messages';
import useTimer from '../../hooks/useTimer';
import { RESEND_CODE_TIMEOUT } from '../../constants/AuthenticationCodeConstants';

const EmailVerification: FC = () => {
  const authProvider = useAuthProvider();
  const currentUser = useAppSelector((state: ReduxState) => state.users.currentUser);
  const dispatch = useAppDispatch();
  const { seconds, restart, isRunning } = useTimer(RESEND_CODE_TIMEOUT);

  const handleLogout = () => {
    authProvider.emitLogout()
  };

  const resendCode = async () => {
    try {
      await authProvider.resendVerificationEmail();

      notification.createNotification(
        getMessage(MessageType.AuthenticationCodeSent, { target: currentUser?.email }),
        'success',
        dispatch,
      );

      restart();
    } catch (error) {
      notification.createNotification(error.message, 'error', dispatch);
    }
  };

  const handleSubmit = async (code: string) => {
    try {
      await authProvider.verifyEmailVerificationCode(code);
    } catch (error) {
      notification.createNotification(error.message, 'error', dispatch);
    }
  };

  useEffect(() => {
    resendCode();
  }, []);

  return (
    <AuthLayout
      title="Email Verification"
      subTitle={`Please enter the code sent to ${currentUser?.email}.`}
      formFooter={<AuthFormFooter onClick={handleLogout} linkText="Back to Sign In" />}
    >
      <SecurePhoneAuthenticationForm
        onResendCode={resendCode}
        onSubmit={handleSubmit}
        resendInSeconds={isRunning ? seconds : 0}
        buttonText="Verify Email"
      />
    </AuthLayout>
  );
};

export default EmailVerification;
