import { DashboardActionType, IBaseCreateDashboardSliceParams, createDashboardSlice } from 'DashboardSlice/createDashboardSlice';
import createEventsDashboardFiltersSlice, {
  EventsDashboardFiltersState,
} from './Filters/EventsDashboardFiltersStore';
import EventsDashboardActionType from './EventsDashboardActionType';
import EventsDashboardFiltersActionType from './Filters/EventsDashboardFiltersActionType';
import createEventsDashboardPagination, { WEBHOOK_EVENTS_PER_PAGE_DEFAULT } from './Pagination';
import { WebhookEvent, WebhookEventDataFilter, WebhookEventSortingField, WebhookEventSortingType } from 'api/WebhookEventsApi';
import { WebhookEventsActionOrigin, getWebhookEvents } from 'Webhooks/Events/Thunks';
import { Reducer } from '@reduxjs/toolkit';

const eventsDashboardStatePath = ['generalSettings', 'ui', 'webhooksDashboards', 'eventsDashboard'];

export const createEventsDashboardSlice = ({
  namespace,
  path,
  actionOrigins,
}: IBaseCreateDashboardSliceParams) => {
  const {
    actions: filtersActions,
    getInitialState: getFiltersInitialState,
    reducer: filtersReducer,
  } = createEventsDashboardFiltersSlice(namespace);

  const pagination = createEventsDashboardPagination(path);

  const actionTypes = {
    [DashboardActionType.ResetState]: EventsDashboardActionType.ResetState,
    [DashboardActionType.ChangeSortingType]: EventsDashboardActionType.ChangeSortingType,
    [DashboardActionType.ClearFilters]: EventsDashboardFiltersActionType.ClearFilters,
    [DashboardActionType.ChangeFilters]: EventsDashboardFiltersActionType.ChangeFilters,
  };

  const defaultEventsDashboardSlice = createDashboardSlice<
    WebhookEvent,
    WebhookEventDataFilter,
    WebhookEventSortingType,
    EventsDashboardFiltersState,
    typeof actionTypes
  >({
    namespace,
    path,
    actionOrigins,
    itemsPerPage: WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
    defaultSorting: { field: WebhookEventSortingField.CreatedAt, ascending: false },
    getItems: getWebhookEvents,
    actionTypes,
    filtersAndSortingProps: [
      'eventTypes',
      'statuses',
      'dueCreatedDateRange',
      'documentTypeId',
    ],
    filtersReducer: filtersReducer as Reducer<EventsDashboardFiltersState>,
    getFiltersInitialState,
    filtersActions,
  });

  const {
    actions,
    reducer,
    filtersAndSortingSaver,
    getInitialState,
  } = defaultEventsDashboardSlice;

  return {
    actions,
    reducer: pagination.wrapReducer(reducer),
    pagination,
    filtersAndSortingSaver,
    getInitialState,
    filtersActions,
  }
};

const eventsDashboardSlice = createEventsDashboardSlice({
  namespace: 'EventsDashboard',
  path: eventsDashboardStatePath,
  actionOrigins: [WebhookEventsActionOrigin.WebhookEventsDashboardTable],
});

const {
  actions,
  reducer,
  pagination,
  filtersAndSortingSaver,
  getInitialState,
  filtersActions,
} = eventsDashboardSlice;

const eventsDashboardActionOrigin = WebhookEventsActionOrigin.WebhookEventsDashboardTable;

export {
  getInitialState,
  filtersAndSortingSaver,
  pagination,
  eventsDashboardActionOrigin,
  eventsDashboardStatePath,
}

export const {
  resetState,
  changeSortingType,
} = actions;

export const {
  changeFilters,
  changeSearchValue,
  clearFilters,
  showFiltersPanel,
  hideFiltersPanel,
} = filtersActions;

export default reducer;
