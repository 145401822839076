import createDashboardFiltersSlice, {
  BaseDashboardFiltersState,
  getDashboardFiltersInitialState,
} from 'DashboardSlice/createDashboardFiltersSlice';

const createEndpointsDashboardFiltersSlice = (namespace: string) => {
  const getInitialState = (): BaseDashboardFiltersState => ({
    ...getDashboardFiltersInitialState(),
  });

  const actionTypes = {};

  const { reducer, actions } = createDashboardFiltersSlice<
    BaseDashboardFiltersState,
    {},
    typeof actionTypes
  >(namespace,
    getInitialState,
    actionTypes,
  );

  return {
    actions,
    reducer,
    getInitialState,
  };
};

export default createEndpointsDashboardFiltersSlice;
