import { FC } from 'react';
import GoogleAuthButton from './GoogleAuthButton';
import { useAuthProvider } from 'providers/AuthProvider';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getCurrentUser } from 'Users/Thunks';
import ExternalProvidersSeparator from './ExternalProvidersSeparator';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import { GoogleSignInData } from 'api/AuthApi';
import notification from 'handlers/notification/notificationActionCreator';
import { useDispatch } from 'react-redux';

interface ExternalProvidersAuthSectionProps {
  redirectOnSuccess?: boolean;
  inviteToken?: string;
  onSignInStarted: () => void;
  onSignInFailed: () => void;
}

const ExternalProvidersAuthSection: FC<ExternalProvidersAuthSectionProps> = ({
  redirectOnSuccess = false,
  inviteToken,
  onSignInStarted,
  onSignInFailed,
}) => {
  const authProvider = useAuthProvider();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const routeBuilder = useRouteBuilder();
  const dispatch = useDispatch();

  const handleGoogleSignIn = async (data: GoogleSignInData) => {
    try {
      onSignInStarted();

      await authProvider.signInWithGoogle({
        ...data,
        inviteToken,
      });

      await dispatchWithUnwrap(getCurrentUser());

      if (redirectOnSuccess) {
        routeBuilder.go(AppRoutes.extractData);
      }
    } catch (error) {
      onSignInFailed();
      notification.createNotification(error.message, 'error', dispatch);
    }
  }

  return (
    <div>
      <GoogleAuthButton onSignIn={handleGoogleSignIn} />
      <ExternalProvidersSeparator />
    </div>
  );
};

export default ExternalProvidersAuthSection;
