import AuthEventEmitter, { AuthEventMessage } from 'eventHandlers/AuthEventEmitter';
import { SessionInfo } from 'SessionInfo/Types';
import getUserInfo from './GetUserInfo';
import getOrganizationInfo from './GetOrganizationInfo';
import { pickBy } from 'lodash';

const emitSessionInfo = (response: Response): void => {
  const user = getUserInfo(response);
  const organization = getOrganizationInfo(response);

  const sessionInfo: Partial<SessionInfo> = pickBy({
    user,
    organization,
  });

  AuthEventEmitter.emit(AuthEventMessage.SessionInfoUpdate, sessionInfo);
};

export default emitSessionInfo;
