import { FC } from 'react';
import styles from './Controls.module.scss';
import clsx from 'clsx';
import { MinusIcon, PlusIcon } from 'static/images';

interface ControlsProps {
  onZoom: (zoom: number) => void;
  onNext?: () => void;
  onPrevious?: () => void;
  zoom: number;
  className?: string;
  showPageControls?: boolean;
  isNextDisabled?: boolean;
  isPreviousDisabled?: boolean;
}

const MIN_ZOOM = 0.25;
const MAX_ZOOM = 5;
const ZOOM_STEP = 0.25;
export const INITIAL_ZOOM = 1;

const Controls: FC<ControlsProps> = ({
  onZoom,
  onNext,
  onPrevious,
  zoom,
  className,
  isNextDisabled,
  isPreviousDisabled,
  showPageControls,
}) => {
  return (
    <div className={clsx(styles.tools, className)}>
      {showPageControls && (
        <div
          onClick={() => !isPreviousDisabled && onPrevious?.()}
          className={clsx(styles.controlButton, isPreviousDisabled && styles.disabled)}
        >
          Prev
        </div>
      )}
      <div
        onClick={() => zoom > MIN_ZOOM && onZoom(zoom - ZOOM_STEP)}
        className={clsx(styles.zoomIcon, zoom === MIN_ZOOM && styles.disabled)}
      >
        <MinusIcon />
      </div>
      <div
        onClick={() => onZoom(INITIAL_ZOOM)}
        className={styles.controlButton}
      >
        {Math.round(zoom * 100)}%
      </div>
      <div
        onClick={() => zoom < MAX_ZOOM && onZoom(zoom + ZOOM_STEP)}
        className={clsx(styles.zoomIcon, zoom === MAX_ZOOM && styles.disabled)}
      >
        <PlusIcon />
      </div>
      {showPageControls && (
        <div
          onClick={() => !isNextDisabled && onNext?.()}
          className={clsx(styles.controlButton, isNextDisabled && styles.disabled)}
        >
          Next
        </div>
      )}
    </div>
  );
};

export default Controls;
