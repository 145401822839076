import React, { FC } from 'react';
import Button from 'components/Button';
import InputWithCopyButton from 'components/InputWithCopyButton';
import WithIdentityConfirmActionPopup from 'components/WithIdentityConfirmationActionPopup';
import styles from './GeneratePublicApiKeyPopup.module.scss';

interface GeneratePublicApiKeyPopupProps {
  onClose: () => void;
  onConfirmCreate: () => Promise<void>;
  apiKey: string;
}

const GeneratePublicApiKeyPopup: FC<GeneratePublicApiKeyPopupProps> = ({
  onClose,
  onConfirmCreate,
  apiKey,
}) => {
  return (
    <WithIdentityConfirmActionPopup
      actionName="Generate API Key"
      onClose={onClose}
      onConfirmationComplete={onConfirmCreate}
      showChildren={!!apiKey}
    >
      <div className={styles.container}>
        <div className={styles.text}>
          Your API key has been generated.
          You can use this to validate API requests from external systems to the Synthetiq platform.
        </div>
        <InputWithCopyButton className={styles.apiKey} value={apiKey || ''} />
        <div className={styles.text}>
          For security reasons:
          <ul className={styles.list}>
            <li>This will only be visible once and should be saved to a secure location.</li>
            <li>Limit sharing of credentials only to developers that need access.</li>
            <li>Ensure that everyone who is given this secret treat it with strict confidentiality.</li>
          </ul>
        </div>
        <Button className={styles.closeButton} kind="secondary" size="form" onClick={onClose}>Close Window</Button>
      </div>
    </WithIdentityConfirmActionPopup>
  );
};

export default GeneratePublicApiKeyPopup;
