import LoaderWithState, { LoaderState } from 'components/LoaderWithState';
import React, { FC, useState } from 'react';
import styles from './BatchFiles.module.scss';
import clsx from 'clsx';
import QuestionIcon from 'components/QuestionIcon';
import { BoldCloseIcon } from 'static/images';
import { IFileState, validateFileState } from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/validateFileState';
import { DataExtractionMode } from 'api/DataExtractionApi';
import OverflowedText from '../../../../OverflowedText';

interface BatchFilesProps {
  filesState: Record<string, IFileState>;
  mode: DataExtractionMode;
  onDelete: (id: string) => void;
}

export const getLoaderState = (fileState: IFileState, error?: string | null) => {
  if (fileState.validationInfo === undefined) {
    return LoaderState.Updating;
  }

  if (fileState.validationInfo === null) {
    return LoaderState.Failure;
  }

  return error
    ? LoaderState.Failure
    : LoaderState.Success;
};

const mapLoaderStateToWeight = (loaderState: LoaderState) => {
  switch (loaderState) {
    case LoaderState.Updating:
      return 0;
    case LoaderState.Failure:
      return 1;
    case LoaderState.Success:
      return 2;
  }
};

// Temp "hack" to avoid issues with scroll,
// for now it will work in all cases with the current design
export const MAX_FILES_BEFORE_OVERFLOW = 4;

const BatchFiles: FC<BatchFilesProps> = ({
  filesState,
  mode,
  onDelete,
}) => {
  const [activeFileId, setActiveFileId] = useState<string | null>(null);

  const files = Object.keys(filesState)
    .map((id) => ({ ...filesState[id], weight: mapLoaderStateToWeight(getLoaderState(filesState[id])) }))
    .sort((a, b) => a.weight - b.weight);

  const renderTooltip = (text: string) => {
    return (
      <div className={styles.tooltipBody}>
        {text}
      </div>
    );
  };

  return (
    <div className={clsx(styles.filesContainer, files.length > MAX_FILES_BEFORE_OVERFLOW && styles.overflow)}>
      {files.map((fileState) => {
        const error = validateFileState(fileState, mode);
        const state = getLoaderState(fileState, error);

        return (
          <div
            key={fileState.id}
            className={clsx(styles.fileContainer, error && styles.error)}
            onMouseEnter={() => setActiveFileId(fileState.id)}
            onMouseLeave={() => setActiveFileId(null)}
          >
            <LoaderWithState className={styles.fileIcon} state={state} />
            <OverflowedText className={styles.fileName}>
              {fileState.file.name}
            </OverflowedText>
            {error && (
              <QuestionIcon
                className={styles.errorIcon}
                tooltip={renderTooltip(error)}
                size={20}
              />
            )}
            <BoldCloseIcon
              className={clsx(
                styles.closeImage,
                state !== LoaderState.Updating && fileState.id === activeFileId && styles.active,
                state === LoaderState.Updating && styles.disabled,
              )}
              onClick={() => onDelete(fileState.id)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BatchFiles;
