import BaseUploadFile from 'components/UploadFile/BaseUploadFile';
import { FC, ReactElement, ReactNode } from 'react';

export interface UploadFromFileProps {
  files: File[];
  onFilesChange: (files: File[]) => void;
  accept?: string | string[];
  maxFileSize?: number;
  text?: ReactNode;
  closeIcon?: React.ReactNode;
  noMargin?: boolean;
  disabled?: boolean;
  inputLabel?: string;
  className?: string;
  inputWrapperClassName?: string;
  uploadIcon?: React.ReactNode;
  requirementsText?: string | ReactElement;
}

const MAX_FILES = 50;

const UploadMultipleFiles: FC<UploadFromFileProps> = ({
  files,
  onFilesChange,
  accept,
  text,
  closeIcon,
  noMargin,
  inputLabel,
  className,
  inputWrapperClassName,
  uploadIcon,
  disabled,
  requirementsText,
}) => {
  return (
    <BaseUploadFile
      disablePreview
      files={files}
      onFilesChange={onFilesChange}
      accept={accept}
      text={text}
      closeIcon={closeIcon}
      className={className}
      errorMessage=''
      inputLabel={inputLabel}
      inputWrapperClassName={inputWrapperClassName}
      noMargin={noMargin}
      requirementsText={requirementsText}
      uploadIcon={uploadIcon}
      maxFiles={MAX_FILES}
      disabled={disabled}
    />
  );
};

export default UploadMultipleFiles;
