import React from 'react';
import { TableHead, TableBodyCell, TableRow } from 'components/Table';
import { SkeletonText } from 'components/Skeleton';
import PlanDetailsColumnSize from 'components/BillingDashboard/Overview/PlanDetails/PlanDetailsColumnSize';
import styles from 'components/BillingDashboard/Overview/PlanDetails/PlanDetails.module.scss';

const PlanDetailsSkeleton = () => {
  return (
    <>
      <SkeletonText color="primary20" width="360px" height="18px" lineHeight="28px" marginBottom="24px" />
      <TableHead />
      <TableRow disableHover>
        <TableBodyCell width={PlanDetailsColumnSize.Name} className={styles.nameCell}>
          <SkeletonText width="260px" lineHeight="20px" height="16px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={PlanDetailsColumnSize.Value} className={styles.valueCell}>
          <SkeletonText width="260px" lineHeight="20px" height="16px" color="primary6" />
        </TableBodyCell>
      </TableRow>
      <TableRow disableHover>
        <TableBodyCell width={PlanDetailsColumnSize.Name} className={styles.nameCell}>
          <SkeletonText width="260px" lineHeight="20px" height="16px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={PlanDetailsColumnSize.Value} className={styles.valueCell}>
          <SkeletonText width="260px" lineHeight="20px" height="16px" color="primary6" />
        </TableBodyCell>
      </TableRow>
    </>
  );
};

export default PlanDetailsSkeleton;
