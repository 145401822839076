import { DashboardActionType, IBaseCreateDashboardSliceParams, createDashboardSlice } from 'DashboardSlice/createDashboardSlice';
import createDocumentTypesDashboardFiltersSlice, {
  DocumentTypesDashboardFiltersState,
} from './Filters/DocumentTypesDashboardFiltersStore';
import { DocumentTypesDashboardActionType } from './DocumentTypesDashboardActionType';
import { DocumentTypesDashboardFiltersActionType } from './Filters/DocumentTypesDashboardFiltersActionType';
import { DocumentTypeFilters, DocumentType, DocumentTypeSortingField, DocumentTypeSortingType } from 'api/DocumentTypeApi';
import { getDocumentTypes } from 'DocumentTypes/Thunks';
import createDocumentTypesDashboardPagination, { calculateDocumentTypesPerPage } from './Pagination';

export interface DocumentTypesDashboardState {
  items: string[];
  sortingType: DocumentTypeSortingType;
  filters: DocumentTypesDashboardFiltersState;
}

const documentTypesDashboardActionOrigin = 'defaultDocumentTypesDashboard';
const documentTypesDashboardStatePath = ['generalSettings', 'ui', 'documentTypesDashboard'];

export const createDocumentTypeDashboardSlice = ({
  namespace,
  path,
  actionOrigins,
}: IBaseCreateDashboardSliceParams) => {
  const {
    actions: filtersActions,
    getInitialState: getFiltersInitialState,
    reducer: filtersReducer,
  } = createDocumentTypesDashboardFiltersSlice(namespace);

  const pagination = createDocumentTypesDashboardPagination(path);

  const actionTypes = {
    [DashboardActionType.ResetState]: DocumentTypesDashboardActionType.ResetState,
    [DashboardActionType.ChangeMembers]: DocumentTypesDashboardFiltersActionType.ChangeMembers,
  };

  const defaultDocumentTypesDashboardSlice = createDashboardSlice<
    DocumentType,
    DocumentTypeFilters,
    DocumentTypeSortingType,
    DocumentTypesDashboardFiltersState,
    typeof actionTypes
  >({
    namespace,
    path,
    actionOrigins,
    itemsPerPage: calculateDocumentTypesPerPage(),
    defaultSorting: { field: DocumentTypeSortingField.UpdatedAt, ascending: false },
    getItems: getDocumentTypes,
    actionTypes,
    filtersAndSortingProps: [
      'teamMembers',
    ],
    filtersReducer,
    getFiltersInitialState,
    filtersActions,
  });

  const {
    actions,
    reducer,
    filtersAndSortingSaver,
    getInitialState,
  } = defaultDocumentTypesDashboardSlice;

  return {
    actions,
    reducer: pagination.wrapReducer(reducer),
    pagination,
    filtersAndSortingSaver,
    getInitialState,
    filtersActions,
  }
};

const documentTypesDashboardSlice = createDocumentTypeDashboardSlice({
  namespace: 'documentTypesDashboard',
  path: documentTypesDashboardStatePath,
  actionOrigins: [documentTypesDashboardActionOrigin],
});

const {
  actions,
  reducer,
  pagination,
  filtersAndSortingSaver,
  getInitialState,
  filtersActions,
} = documentTypesDashboardSlice;

export {
  getInitialState,
  filtersAndSortingSaver,
  pagination,
  documentTypesDashboardActionOrigin,
  documentTypesDashboardStatePath,
}

export const {
  resetState,
} = actions;

export const {
  changeMembers,
  changeSearchValue,
} = filtersActions;

export default reducer;
