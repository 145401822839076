import React, { FC } from 'react';
import AuthLink from '../Inputs/AuthLink/AuthLink';

import styles from './AuthFormFooter.module.scss';

interface AuthFormFooterProps {
  message?: string;
  linkText: string;
  linkTo?: string;
  onClick?: () => void;
}

const AuthFormFooter: FC<AuthFormFooterProps> = ({
  linkText,
  linkTo,
  message,
  onClick,
}) => {
  return (
    <div className={styles.container}>
      {message}{message && ' '}
      {linkTo && <AuthLink to={linkTo}>{linkText}</AuthLink>}
      {!linkTo && <p className={styles.fakeLink} onClick={onClick}>{linkText}</p>}
    </div>
  );
};

export default AuthFormFooter;
