import React, { FC } from 'react';
import { Tag } from 'components/Tag';
import { UserStatus } from 'api/UserApi';

interface UserStatusTagProps {
  status: UserStatus;
}

const UserStatusTag: FC<UserStatusTagProps> = ({ status }) => {
  switch (status) {
    case UserStatus.Active: {
      return <Tag color="green">Active</Tag>;
    }
    case UserStatus.Inactive: {
      return <Tag color="gray">Inactive</Tag>;
    }
    case UserStatus.Pending: {
      return <Tag color="blue">Pending</Tag>;
    }
    default: {
      return null;
    }
  }
};

export default UserStatusTag;
