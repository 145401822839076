import { FC } from 'react';
import { TableBodyCell, TableRow } from 'components/Table';
import OverflowedText from 'components/OverflowedText';
import { DataExtractionResultValue, MappedDataExtractionResult } from '../mapDataExtractionResult';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import clsx from 'clsx';
import styles from './DataExtractionResultOutputTableRow.module.scss';

interface DataExtractionResultOutputTableRowProps {
  result: MappedDataExtractionResult;
  arrowIcon?: JSX.Element;
  valueClassName?: string;
  disableHover?: boolean;
  nameCellClassName?: string;
  hasTopBorder?: boolean;
}

const NUMBER_FORMATTING_START_VALUE = 10000;
const CHILD_OBJECT_VALUE = 'Object';

const DataExtractionResultOutputTableRow: FC<DataExtractionResultOutputTableRowProps> = ({
  result,
  arrowIcon,
  valueClassName,
  nameCellClassName,
  disableHover = true,
  hasTopBorder = false,
}) => {
  const formatValue = (value: DataExtractionResultValue) => {
    if (typeof value === 'number' && value >= NUMBER_FORMATTING_START_VALUE) {
      return formatMonetaryValue(value, { disableRounding: true });
    }

    if (value && typeof value === 'object') {
      return CHILD_OBJECT_VALUE;
    }

    return value?.toString() || '—';
  };

  return (
    <TableRow disableHover={disableHover} hasTopBorder={hasTopBorder}>
      <TableBodyCell className={clsx(styles.nameCell, styles.mobilePadding, nameCellClassName)}>
        <OverflowedText className={styles.name}>{result.key}</OverflowedText>
        {arrowIcon}
      </TableBodyCell>
      <TableBodyCell className={clsx(styles.mobilePadding, valueClassName)}>
        <OverflowedText>{formatValue(result.value)}</OverflowedText>
      </TableBodyCell>
    </TableRow>
  );

}

export default DataExtractionResultOutputTableRow;
