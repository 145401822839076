import React, { FC, useState } from 'react';
import { formatPhoneNumber } from 'components/PhoneInput';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import notification from 'handlers/notification/notificationActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { confirmPassword, sendChangePhoneNumberCode, verifyChangePhoneCode } from 'MyAccount/ActionCreator';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import VerifyCodeComponent from 'components/VerifyCode';
import ProvidePhoneNumberStep from 'components/VerificationSteps/ProvidePhoneNumberStep';
import ConfirmPasswordStep from 'components/VerificationSteps/ConfirmPasswordStep';

enum SetupPhoneVerificationPopupStep {
  ConfirmPassword = 'ConfirmPassword',
  ProvidePhone = 'ProvidePhone',
  VerifyCode = 'VerifyCode',
}

interface SetupPhoneVerificationPopupProps {
  phoneNumberFormat: string;
  onClose: () => void;
}

const SetupPhoneVerificationPopup: FC<SetupPhoneVerificationPopupProps> = ({ phoneNumberFormat, onClose }) => {
  const dispatch = useDispatchWithUnwrap();

  const [
    step,
    setStep,
  ] = useState(SetupPhoneVerificationPopupStep.ConfirmPassword);
  const [phone, setPhone] = useState('');

  const handleConfirmPassword = async (password: string) => {
    if (password) {
      await dispatch(confirmPassword(password));

      setStep(SetupPhoneVerificationPopupStep.ProvidePhone);
    }
  };

  const [
    isCodeSendInProgress,
    handleSendCode,
  ] = useAsyncActionCallback(async (newPhone: string) => {
    if (newPhone) {
      setPhone(newPhone);

      await dispatch(sendChangePhoneNumberCode(newPhone));

      setStep(SetupPhoneVerificationPopupStep.VerifyCode);
    }
  }, []);

  const [
    isCodeVerificationInProgress,
    handleVerifyCode,
  ] = useAsyncActionCallback(async (code: string) => {
    if (code) {
      await dispatch(verifyChangePhoneCode(code));

      notification.createNotification(`The phone number ${formatPhoneNumber(phone)} has been added to your account`, 'success', dispatch);

      onClose();
    }
  }, [phone, onClose]);

  return (
    <PopUp title="Phone Setup & Verification" closePopUp={onClose}>
      <PopUpContent>
        {step === SetupPhoneVerificationPopupStep.ConfirmPassword && (
          <ConfirmPasswordStep
            onContinue={handleConfirmPassword}
          />
        )}
        {step === SetupPhoneVerificationPopupStep.ProvidePhone && (
          <ProvidePhoneNumberStep
            initialPhone=""
            buttonText="Continue"
            phoneNumberFormat={phoneNumberFormat}
            onSubmit={handleSendCode}
            loading={isCodeSendInProgress}
          />
        )}
        {step === SetupPhoneVerificationPopupStep.VerifyCode && (
          <VerifyCodeComponent
            onSubmit={handleVerifyCode}
            buttonText="Verify Phone"
            message={`Please enter the code sent to ${formatPhoneNumber(phone)}`}
            onResendCode={() => handleSendCode(phone)}
            loading={isCodeVerificationInProgress}
          />
        )}
      </PopUpContent>
    </PopUp>
  );
};

export default SetupPhoneVerificationPopup;
