import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import { User } from 'api/UserApi';
import SearchInput from 'components/SearchInput';
import FilterButton from 'components/FilterButton';
import UserFilter from 'components/UserFilter';
import StyledSwitch from 'components/StyledSwitch';
import { SortBySelect } from 'components/SortBySelect';
import { DropdownButtonOption } from 'components/DropdownButton';
import styles from './SearchWithFilters.module.scss';

export interface FiltersSortingOption {
  name: string;
  field: string;
  ascending: boolean;
}

export interface SearchWithFiltersProps {
  className?: string;
  filtersButtonsClassName?: string;
  search: string;
  onSearch: (updatedSearch: string) => void;
  onChangeSelectedUsers?: (members: User[]) => void;
  onChangeToggle?: (active: boolean) => void;
  areFiltersActive?: boolean;
  showFiltersButton?: boolean;
  showToggleButton?: boolean;
  toggleButtonActive?: boolean;
  onFiltersButtonClick?: () => void;
  availableUsersForFilter?: User[];
  selectedUsers?: User[];
  selectedSortingType?: { field: string; ascending: boolean } | null;
  sortingOptions?: FiltersSortingOption[];
  onChangeSortingType?: (field: string, ascending: boolean) => void;
  loading?: boolean;
  usersLoading?: boolean;
  toggleSwitchLabel?: string;
}

const SearchWithFilters: FC<SearchWithFiltersProps> = ({
  className,
  filtersButtonsClassName,
  search,
  areFiltersActive,
  showFiltersButton,
  showToggleButton,
  onFiltersButtonClick,
  onChangeToggle,
  onSearch,
  availableUsersForFilter,
  selectedUsers,
  loading,
  toggleButtonActive,
  selectedSortingType,
  sortingOptions,
  onChangeSortingType,
  onChangeSelectedUsers,
  toggleSwitchLabel,
  usersLoading,
}) => {
  const handleSearchInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const handleSearchInputClear = () => {
    onSearch('');
  };

  const dropdownSortingOptions = useMemo(() => {
    return sortingOptions?.map((option) => ({
      text: option.name,
      id: `${option.field}_${option.ascending}`,
      ...option,
    }));
  }, [sortingOptions]);

  const selectedSortingOption = useMemo(() => {
    if (!selectedSortingType) {
      return null;
    }

    return dropdownSortingOptions?.find((option) => {
      return option.id === `${selectedSortingType.field}_${selectedSortingType.ascending}`;
    });
  }, [selectedSortingType, dropdownSortingOptions]);

  const handleSortSelectOptionChange = (option: DropdownButtonOption & FiltersSortingOption) => {
    onChangeSortingType?.(option.field, option.ascending);
  };

  return (
    <div className={clsx(styles.header, className)}>
      <div className={styles.searchWithMemberFilters}>
        <SearchInput
          placeholder="Search"
          containerClassName={styles.searchInput}
          value={search}
          onChange={handleSearchInputValueChange}
          onClear={handleSearchInputClear}
          showLoader={loading}
        />
        {onChangeSelectedUsers && <UserFilter
          users={availableUsersForFilter || []}
          selectedUsers={selectedUsers || []}
          onChange={onChangeSelectedUsers}
          loading={loading || usersLoading}
        />}
      </div>
      <div className={clsx(styles.filtersButtons, filtersButtonsClassName)}>
        {showFiltersButton && <FilterButton active={areFiltersActive} onClick={onFiltersButtonClick} />}
        {dropdownSortingOptions && (
          <SortBySelect
            className={styles.sortBySelect}
            onSelect={handleSortSelectOptionChange}
            selectedOption={selectedSortingOption || null}
            options={dropdownSortingOptions}
            disabled={loading}
          />
        )}
        {showToggleButton && (
          <div className={styles.toggleSwitch}>
            <StyledSwitch
              id="show-archived"
              color="primary"
              className={styles.switchButton}
              checked={toggleButtonActive}
              onChange={(event, checked) => onChangeToggle?.(checked)}
            />
            <label
              htmlFor="show-archived"
              className={clsx(styles.toggleSwitchLabel, toggleButtonActive && styles.activeToggleSwitchLabel)}
            >
              {toggleSwitchLabel || 'Show Archived'}
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchWithFilters;
