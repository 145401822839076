import { FC } from 'react';
import Table from 'components/Table/Table';
import { TableBody, TableBodyContent, TableRow } from 'components/Table';
import DataExtractionResultOutputTableRow from './DataExtractionResultOutputTableRow/DataExtractionResultOutputTableRow';
import DataExtractionResultOutputTableCollapsibleRow from './DataExtractionResultOutputTableCollapsibleRow';
import DataExtractionResultOutputSkeletonRow from './DataExtractionResultOutputSkeletonRow/DataExtractionResultOutputSkeletonRow';
import { MappedDataExtractionResult, mapDataExtractionResult } from './mapDataExtractionResult';
import { DataExtractionStatus } from 'api/DataExtractionApi';
import TableBodyCell from 'components/Table/TableBodyCell';

import styles from './DataExtractionResultOutputTable.module.scss';

interface DataExtractionResultOutputTableProps {
  dataExtractionResult?: string;
  dataExtractionStatus?: DataExtractionStatus;
}

const SKELETON_ROWS_COUNT = 11;

const DataExtractionResultOutputTable: FC<DataExtractionResultOutputTableProps> = ({
  dataExtractionResult,
  dataExtractionStatus,
}) => {
  const mappedDataExtractionResults = dataExtractionResult
    ? mapDataExtractionResult(dataExtractionResult)
    : new Array(SKELETON_ROWS_COUNT).fill(null);

  const isDataExtractionFailed = dataExtractionStatus === DataExtractionStatus.Failed
    || dataExtractionStatus === DataExtractionStatus.Undetected;

  const renderRow = (result: MappedDataExtractionResult, index: number) => {
    if (typeof result.value === 'object' && result.value !== null) {
      return <DataExtractionResultOutputTableCollapsibleRow key={result.key} result={result} hasTopBorder={!index} />
    }

    return (
      <DataExtractionResultOutputTableRow key={result.key} result={result} hasTopBorder={!index} />
    );
  }

  const renderFailedRow = ({ value }: { value: string }, index: number) => {
    return (
      <TableRow key={index} disableHover hasTopBorder>
        <TableBodyCell className={styles.noDataText}>
          {value}
        </TableBodyCell>
      </TableRow>
    );
  }

  return (
    <Table className={styles.tableContainer}>
      <TableBody>
        {!isDataExtractionFailed && (
          <TableBodyContent
          rows={mappedDataExtractionResults}
          renderTableRow={renderRow}
          renderSkeletonTableRow={(index) => <DataExtractionResultOutputSkeletonRow key={index} />}
        />
        )}
        {isDataExtractionFailed && (
          <TableBodyContent
            rows={[{ value: 'No output data' }]}
            renderTableRow={renderFailedRow}
          />
          )}
      </TableBody>
    </Table>
  )
}

export default DataExtractionResultOutputTable;
