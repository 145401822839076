import React, { FC } from 'react';
import clsx from 'clsx';

import styles from './Separator.module.scss';

interface SeparatorProps {
  className?: string;
}
const Separator: FC<SeparatorProps> = ({ className }) => {
  return <div className={clsx(styles.separator, className)} />;
}

export default Separator;
