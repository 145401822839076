import { FC } from 'react';
import { ProcessingDocumentImage } from 'static/images';
import clsx from 'clsx';

import styles from './ProcessingDocumentLoader.module.scss';

export enum ProcessingDocumentLoaderStatus {
  Analyzing = 'analyzing',
  Loading = 'loading',
}

interface ProcessingDocumentLoaderProps {
  status: ProcessingDocumentLoaderStatus;
}

const ProcessingDocumentLoader: FC<ProcessingDocumentLoaderProps> = ({
  status,
}) => {
  const getStatusStyle = () => {
    switch (status) {
      case ProcessingDocumentLoaderStatus.Analyzing:
        return styles.analyzingText;
      case ProcessingDocumentLoaderStatus.Loading:
        return styles.loadingText;
    }
  }

  return (
    <div className={styles.container}>
      <ProcessingDocumentImage />
      {status === ProcessingDocumentLoaderStatus.Analyzing && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
        </div>
      )}
      <p className={clsx(styles.text, getStatusStyle())}>{status}</p>
    </div>
  )
}

export default ProcessingDocumentLoader;
