import React, { FC, useEffect } from 'react';
import { ButtonType } from 'components/Button/Button';
import { formatPhoneNumber } from 'components/PhoneInput';
import notification from 'handlers/notification/notificationActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { confirmPhoneCode, sendPhoneConfirmationCode } from 'MyAccount/ActionCreator';
import VerifyCode from 'components/VerifyCode';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import { User } from 'api/UserApi';
import getMessage, { MessageType } from 'constants/messages';

export interface PhoneCodeConfirmationStepProps {
  buttonText: string;
  buttonKind?: ButtonType;
  onSubmit: () => Promise<void>;
  currentUser: User;
}

const PhoneCodeConfirmationStep: FC<PhoneCodeConfirmationStepProps> = ({
  buttonText,
  buttonKind,
  onSubmit,
  currentUser,
}) => {
  const dispatch = useDispatchWithUnwrap();

  const handleResendCode = async () => {
    dispatch(sendPhoneConfirmationCode());
  };

  const [
    isCodeVerificationInProgress,
    handleCodeVerifySubmit,
  ] = useAsyncActionCallback(async (code: string) => {
    const result = await dispatch(confirmPhoneCode(code));

    if (result.invalidCode) {
      await dispatch(sendPhoneConfirmationCode());

      notification.createNotification(getMessage(MessageType.InvalidVerificationCode), 'error', dispatch);

      return;
    }

    await onSubmit();
  }, [onSubmit]);

  useEffect(() => {
    dispatch(sendPhoneConfirmationCode());
  }, []);

  if (!currentUser.phone) {
    return null;
  }

  return (
    <VerifyCode
      buttonKind={buttonKind}
      onSubmit={handleCodeVerifySubmit}
      buttonText={buttonText}
      message={`Please enter the code sent to ${formatPhoneNumber(currentUser.phone)}`}
      onResendCode={handleResendCode}
      loading={isCodeVerificationInProgress}
    />
  );
};

export default PhoneCodeConfirmationStep;
