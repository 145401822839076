import BaseSystemApi from './BaseApi';

export interface PublicApiWhitelist {
  id: string;
  ip: string;
  description: string;
  createdAt: Date;
  createdBy: string;
  lastUsedAt: number | null;
}

export interface CreatePublicApiWhitelistParams {
  ip: string;
  description: string;
}

export interface PublicApiWhitelistApi {
  getAll(): Promise<PublicApiWhitelist[]>;
  create(whitelists: CreatePublicApiWhitelistParams[]): Promise<PublicApiWhitelist>;
  delete(id: string): Promise<void>;
}

export default class PublicApiWhitelistRestApi
  extends BaseSystemApi<PublicApiWhitelist>
  implements PublicApiWhitelistApi
{
  protected resourceName = 'api-ips';

  public getAll(): Promise<PublicApiWhitelist[]> {
    return this.getResources();
  }

  public create(whitelists: CreatePublicApiWhitelistParams[]): Promise<PublicApiWhitelist> {
    return this.createResource({ whitelists });
  }

  public delete(id: string): Promise<void> {
    return this.deleteResource(id);
  }
}
