import React, { FC } from 'react';
import { User, UserAuthProvider } from 'api/UserApi';
import { PublicApiKey } from '../../api/PublicApiKeyApi';
import useLocalSortingWithSaving from '../../hooks/useLocalSortingWithSaving';
import { MAX_API_KEYS_PER_ORGANIZATION } from '../../PublicApi/constants';
import QuestionIcon from '../QuestionIcon';
import ApiKeyTable from './ApiKeyTable';
import ButtonWithSubscriptionCheck from '../ButtonWithSubscriptionCheck';

import styles from './ApiCredentials.module.scss';

enum TooltipActionType {
  Generating = 'generating',
  Deleting = 'deleting',
}

interface DigifiApiProps {
  currentUser?: User;
  apiKeys?: PublicApiKey[];
  onDeleteKey: (id: string) => void;
  onCreateKey: () => void;
}

const ApiCredentials: FC<DigifiApiProps> = ({
  apiKeys,
  currentUser,
  onDeleteKey,
  onCreateKey,
}) => {
  const sortedApiKeys = useLocalSortingWithSaving<PublicApiKey>(
    apiKeys,
    'createdAt',
    'api-key-sorting',
  );

  const isExternalAuthProvider = currentUser?.authProvider !== UserAuthProvider.Synthetiq;

  const isUserShouldHaveEnabledMfa = (!isExternalAuthProvider && !currentUser?.isMfaEnabled);

  const isMaxApiKeyLimitReached = () => {
    return !!apiKeys && apiKeys.length >= MAX_API_KEYS_PER_ORGANIZATION;
  };

  const renderEditActionTooltip = (tooltipActionType: TooltipActionType) => {
    if (!currentUser || isExternalAuthProvider) {
      return null;
    }

    if (isUserShouldHaveEnabledMfa) {
      return (
        <div className={styles.tooltip}>
          Enable 2-factor phone authentication in your <br />
          user profile before {tooltipActionType} API credentials
        </div>
      );
    }

    return null;
  };

  const renderTooltip = () => {
    if (isMaxApiKeyLimitReached()) {
      return (
        <div className={styles.tooltip}>
          You can't generate more than {MAX_API_KEYS_PER_ORGANIZATION} API Keys
        </div>
      );
    }

    return null;
  }

  const apiKeyTooltip = (
    <div className={styles.tooltip}>
      API keys are required to make API <br />
      requests to Synthetiq. API keys can only be viewed <br />
      when they are initially generated.
    </div>
  );

  const isButtonDisabled = isMaxApiKeyLimitReached() || (!isExternalAuthProvider && isUserShouldHaveEnabledMfa);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>
        API Keys
        <QuestionIcon className={styles.questionIcon} tooltip={apiKeyTooltip} size={20} />
        <ButtonWithSubscriptionCheck
          tooltip={renderEditActionTooltip(TooltipActionType.Generating) || renderTooltip()}
          disabled={isButtonDisabled}
          kind="primary"
          size="default"
          onClick={onCreateKey}
          alwaysClickable
        >
          Generate API Key
        </ButtonWithSubscriptionCheck>
      </div>
      <ApiKeyTable
        isDeleteActionDisabled={isUserShouldHaveEnabledMfa}
        deleteActionTooltip={renderEditActionTooltip(TooltipActionType.Deleting)}
        apiKeys={sortedApiKeys.items}
        onDeleteKey={onDeleteKey}
        className={styles.productionKeysTable}
        getSortingType={sortedApiKeys.getSortingType}
        onSort={sortedApiKeys.changeSorting}
      />
    </div>
  );
};

export default ApiCredentials;
