import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import UserCard from 'components/UserCard';
import { useAuthProvider } from 'providers/AuthProvider';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import getAvatarUrl from 'api/utils/getAvatarUrl';
import { PermissionGroup } from 'enums/PermissionGroup';
import AuthExternalSourceButton from 'components/Auth/AuthExternalSourceButton';
import { AppRoutes } from '../../routes/RouteBuilder';

import styles from './SubscriptionPastDue.module.scss';

const SubscriptionPastDue: FC = () => {
  const authProvider = useAuthProvider();
  const { user } = useSelector((state: ReduxState) => state.sessionInfo);
  const isOwner = user?.permissionGroup === PermissionGroup.Owner;
  const accountDetails = useSelector((state: ReduxState) => state.users.currentUser);

  const subTitle = isOwner ? (
    <div>
      Please make a payment to re-activate your account.
      Please note that API access will also be unavailable until this payment is made.
    </div>
  ) : (
    <div>Please contact the administrator of your organization to make a payment.
      Please note that API access will also be unavailable until this payment is made.</div>
  );

  const title = <>Your Subscription <br />Is Past Due</>;

  return (
    <AuthLayout title={title} subTitle={subTitle}>
      <div>
        {isOwner && <AuthExternalSourceButton externalLink={AppRoutes.customerPortal}>
            Go To Payment Portal
        </AuthExternalSourceButton>}
        <UserCard
          className={styles.userCard}
          firstName={accountDetails?.firstName || ''}
          lastName={accountDetails?.lastName || ''}
          color={accountDetails?.color || ''}
          avatarUrl={getAvatarUrl(accountDetails?.avatarId)}
          onLogOutClick={() => authProvider.emitLogout()}
          noMargin
        />
      </div>
    </AuthLayout>
  );
};

export default SubscriptionPastDue;
