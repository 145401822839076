import Pagination from 'pagination';
import { ReduxState } from 'types/ReduxState';
import { WebhookEndpointSortingType } from 'api/WebhookEndpointsApi';

export const WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT = 10;

export interface EndpointsDashboardPaginationParams {
  sortingType: WebhookEndpointSortingType;
}

const createEndpointsDashboardPagination = <Params extends EndpointsDashboardPaginationParams>(namespace: string[] | string) => {
  return Pagination<ReduxState, string, Params>(
    namespace,
    WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT,
  );
};

export default createEndpointsDashboardPagination;
