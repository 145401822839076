import TIMEZONES from 'utils/detect-country/timezones';

export const detectCountryCode = (): string | null => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezone === '' || !timezone || !TIMEZONES[timezone]) {
    return null;
  }

  const { codes } = TIMEZONES[timezone];

  if (codes && codes.length) {
		return codes[0]
  }

  return null
};
