import React, { FC, useMemo } from 'react';
import RowActions from 'components/RowActions';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import WrapperWithLink from 'components/WrapperWithLink';
import { DataExtractionResult } from 'api/DataExtractionResultApi';
import { ExportFileType } from '../../../../../../api/DataExtractionExportApi';
import { DataExtractionResultsTableColumnSize } from '../../TableColumnSize';
import DocumentTypeLabel from '../../../../../DocumentTypeLabel';
import { DataExtractionStatus } from '../../../../../../api/DataExtractionApi';

interface IDataExtractionResultRowProps {
  className: string;
  result: DataExtractionResult;
  onResultClick: (result: DataExtractionResult) => void;
  onExportDataExtractionResult: (resultId: string, exportFileType: ExportFileType) => void;
  downloadingResultId?: string | null;
  rowLink?: string;
}

const DataExtractionResultDesktopRow: FC<IDataExtractionResultRowProps> = ({
  className,
  result,
  rowLink,
  onResultClick,
  onExportDataExtractionResult,
  downloadingResultId,
}) => {
  const actions = useMemo(() => {
    return [{
      title: 'View Results',
      handler: () => onResultClick(result),
    }, {
      title: 'Export Results to CSV',
      handler: () => onExportDataExtractionResult(
        result.id,
        ExportFileType.Csv,
      ),
      disabled: !!downloadingResultId,
    }, {
      title: 'Export Results to JSON',
      handler: () => onExportDataExtractionResult(
        result.id,
        ExportFileType.Json,
      ),
      disabled: !!downloadingResultId,
    }]
  }, [result, onResultClick, onExportDataExtractionResult]);

  return (
    <WrapperWithLink
      className={className}
      link={rowLink || ''}
      onClick={() => onResultClick(result)}
    >
      <TableRow useActions>
        <TableBodyCell width={DataExtractionResultsTableColumnSize.FileName} overflowed>
          {result.originalFileName}
        </TableBodyCell>
        <TableBodyCell width={DataExtractionResultsTableColumnSize.FileType} overflowed>
          {result.fileType.toUpperCase()}
        </TableBodyCell>
        <TableBodyCell width={DataExtractionResultsTableColumnSize.DocumentType} overflowed>
          <DocumentTypeLabel
            isProcessing={result.status === DataExtractionStatus.Processing}
            isAutoDetected={result.isAutoDetected}
            documentType={result.documentType?.name}
          />
        </TableBodyCell>
        <TableBodyCell width={DataExtractionResultsTableColumnSize.Pages}>
          {result.pagesCount}
        </TableBodyCell>
        <RowActions isLoading={downloadingResultId === result.id} actions={actions} />
      </TableRow>
    </WrapperWithLink>
  );
};

export default DataExtractionResultDesktopRow;
