import clsx from 'clsx';
import { FC, useState } from 'react';
import DataExtractionResultOutputSectionHeader from './DataExtractionResultOutputSectionHeader/DataExtractionResultOutputSectionHeader';
import JsonView from 'components/JsonView';
import DataExtractionResultOutputTable from './DataExtractionResultOutputTable/DataExtractionResultOutputTable';
import { DataExtractionStatus } from 'api/DataExtractionApi';
import DataExtractionResultOutputInfoPanel from './DataExtractionResultOutputInfoPanel';
import useRouteBuilder from 'hooks/useRouteBuilder';
import LinkButton from 'components/LinkButton';
import { AppRoutes } from 'routes/RouteBuilder';

import styles from './DataExtractionResultOutputSection.module.scss';

interface DataExtractionResultOutputSectionProps {
  dataExtractionResult?: string;
  className?: string;
  documentType?: { id: string; name: string; };
  dataExtractionResultId?: string;
  dataExtractionStatus?: DataExtractionStatus;
}

const DataExtractionResultOutputSection: FC<DataExtractionResultOutputSectionProps> = ({
  dataExtractionResult,
  className,
  documentType,
  dataExtractionResultId,
  dataExtractionStatus,
}) => {
  const [showJson, setShowJson] = useState(false);
  const routeBuilder = useRouteBuilder();

  const renderJson = () => {
    if (!dataExtractionResult) {
      return;
    }

    return (
      <JsonView
        data={JSON.parse(dataExtractionResult)}
        className={styles.jsonView}
        showCopyButton
      />
    );
  }

  const renderTable = () => {
    if (dataExtractionStatus === DataExtractionStatus.Processing) {
      return (
        <div className={styles.processingContainer}>
          <div className={styles.processingValueContainer}>
            <h5 className={styles.processingTitle}>Analyzing document. Please wait.</h5>
            <p className={styles.processingText}>
              This may take up to a few minutes depending on the complexity of your request.
            </p>
          </div>
        </div>
      )
    }

    return (
      <DataExtractionResultOutputTable
        dataExtractionResult={dataExtractionResult}
        dataExtractionStatus={dataExtractionStatus}
      />
    );
  };

  const renderSaveDocumentTypePanel = () => {
    if (
      !dataExtractionResult
      || !dataExtractionResultId
      || documentType
      || dataExtractionStatus === DataExtractionStatus.Undetected
    ) {
      return null;
    }

    const handleSaveAsNewDocumentType = () => {
      routeBuilder.withParams({ dataExtractionResultId, new: 'true' }).go(AppRoutes.documentTypes)
    };

    const infoText = (
      <>
        <LinkButton className={styles.linkButton} onClick={handleSaveAsNewDocumentType}>
          Create Document Type
        </LinkButton>
        based on the output data to ensure consistent results and retrieve only the necessary information in the future
      </>
    )

    return (
      <DataExtractionResultOutputInfoPanel
        type='info'
        text={infoText}
      />
    )
  }

  const renderNoDataReturnedPanel = () => {
    if (
      !dataExtractionResult
      || !dataExtractionResultId
      || !documentType
      || dataExtractionStatus === DataExtractionStatus.Undetected
    ) {
      return null;
    }

    const parsedResult = JSON.parse(dataExtractionResult);
    const isResultEmpty = Object.values(parsedResult).every(value => !value);

    if (!isResultEmpty) {
      return null;
    }

    const warningText = `No data extracted? You can adjust the document type or proceed without specifying it, 
      and we will automatically extract all the data for you.`

    return (
      <DataExtractionResultOutputInfoPanel
        type='warning'
        text={warningText}
      />
    )
  };

  return (
    <div className={clsx(styles.container, className)}>
      <DataExtractionResultOutputSectionHeader
        disabled={!dataExtractionResult || dataExtractionStatus === DataExtractionStatus.Processing}
        isJsonShown={showJson}
        onToggleShowJson={setShowJson}
      />
      {renderSaveDocumentTypePanel()}
      {renderNoDataReturnedPanel()}
      {!showJson && renderTable()}
      {showJson && renderJson()}
    </div>
  )
}

export default DataExtractionResultOutputSection;
