import React from 'react';
import { BasicUser } from 'api/UserApi';
import { RobotImage } from 'static/images';
import { TextSize } from 'components/Avatar';
import UserAvatar from 'components/UserAvatar';
import getAvatarUrl from 'api/utils/getAvatarUrl';

export interface WithSystemApiUser extends Omit<BasicUser, 'color'> {
  imageUrl?: string;
  color?: string;
}

export interface WithSystemApiUserAvatarProps {
  className?: string;
  user?: WithSystemApiUser | null;
  size?: TextSize;
}

const SYSTEM_API_USER: WithSystemApiUser = {
  id: 'SystemApiId',
  firstName: 'System',
  lastName: 'API',
  email: 'digifi',
};

const WithSystemApiUserAvatar = ({ user, className, size }: WithSystemApiUserAvatarProps) => {
  const avatarUser = user || SYSTEM_API_USER;

  return (
    <UserAvatar
      className={className}
      size={size}
      firstName={avatarUser.firstName || ''}
      lastName={avatarUser.lastName || ''}
      imageUrl={user ? getAvatarUrl(avatarUser.avatarId) : RobotImage}
      tooltip
      color={user?.color}
    />
  );
};

export default WithSystemApiUserAvatar;
