import { TableHead, TableHeadCell } from 'components/Table';
import React, { FC } from 'react'
import { DataExtractionResultsTableColumnSize } from '../TableColumnSize';
import { DataExtractionResultSortingField } from '../../../../../api/DataExtractionResultApi';
import TableActionCell from '../../../../Table/TableActionCell';

interface DataExtractionResultDesktopHeaderRowProps {
  className?: string;
  getSortingType: (field: DataExtractionResultSortingField) => boolean | undefined;
  onSortingChange: (field: DataExtractionResultSortingField) => void;
}

const DataExtractionResultDesktopHeaderRow: FC<DataExtractionResultDesktopHeaderRowProps> = ({
  className,
  getSortingType,
  onSortingChange,
}) => {
  return (
    <TableHead sticky className={className}>
      <TableHeadCell
        width={DataExtractionResultsTableColumnSize.FileName}
        ascending={getSortingType(DataExtractionResultSortingField.FileName)}
        onClick={() => onSortingChange(DataExtractionResultSortingField.FileName)}
      >
        File Name
      </TableHeadCell>
      <TableHeadCell
        width={DataExtractionResultsTableColumnSize.FileType}
        ascending={getSortingType(DataExtractionResultSortingField.FileType)}
        onClick={() => onSortingChange(DataExtractionResultSortingField.FileType)}
      >
        File Type
      </TableHeadCell>
      <TableHeadCell
        width={DataExtractionResultsTableColumnSize.DocumentType}
      >
        Document Type
      </TableHeadCell>
      <TableHeadCell
        width={DataExtractionResultsTableColumnSize.Pages}
        ascending={getSortingType(DataExtractionResultSortingField.PagesCount)}
        onClick={() => onSortingChange(DataExtractionResultSortingField.PagesCount)}
      >
        Pages
      </TableHeadCell>
      <TableActionCell />
    </TableHead>
  )
}

export default DataExtractionResultDesktopHeaderRow;
