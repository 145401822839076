import { FC, useEffect, useState } from 'react'
import { useDataExtractionResultsApi } from 'providers/ApiServiceProvider';
import FilePreview, { PreviewMode } from './FilePreview';
import { DocumentExtension } from 'enums/DocumentExtension';

interface FilePreviewConnectorProps {
  fileExtension: DocumentExtension;
  filename: string;
  fileId: string;
  isLoading?: boolean;
  loadingIcon?: React.ReactNode;
  className?: string;
  isFullscreen?: boolean;
  onDocumentLoad: () => void;
  onExitFullScreen?: () => void;
  onFullscreen?: () => void;
  mode: PreviewMode;
}

const FilePreviewConnector: FC<FilePreviewConnectorProps> = ({
  fileId,
  isFullscreen,
  fileExtension,
  filename,
  className,
  isLoading,
  loadingIcon,
  mode,
  onExitFullScreen,
  onFullscreen,
  onDocumentLoad,
}) => {
  const dataExtractionResultsApi = useDataExtractionResultsApi();

  const [previewLink, setPreviewLink] = useState<string>();

  useEffect(() => {
    (async () => {
      const link = await dataExtractionResultsApi.getPublicLink(fileId);

      setPreviewLink(link);
    })();
  }, []);

  return (
    <FilePreview
      previewLink={previewLink}
      isFullscreen={isFullscreen}
      fileExtension={fileExtension}
      filename={filename}
      className={className}
      isLoading={isLoading}
      loadingIcon={loadingIcon}
      mode={mode}
      onExitFullScreen={onExitFullScreen}
      onDocumentLoad={onDocumentLoad}
      onFullscreen={onFullscreen}
    />
  )
}

export default FilePreviewConnector;
