import createDashboardFiltersSlice, {
  BaseDashboardFiltersState,
  DashboardFiltersActionType,
  getDashboardFiltersInitialState,
} from 'DashboardSlice/createDashboardFiltersSlice';
import EndpointEventsTableFiltersActionType from './EndpointEventsTableFiltersActionType';
import { DataFilter } from 'api/Types';

const createEndpointEventsTableFiltersSlice = (namespace: string) => {
  const getInitialState = (): BaseDashboardFiltersState => ({
    ...getDashboardFiltersInitialState(),
  });

  const actionTypes = {
    [DashboardFiltersActionType.ChangeSearchValue]: EndpointEventsTableFiltersActionType.ChangeSearchValue,
  };

  const { reducer, actions } = createDashboardFiltersSlice<
    BaseDashboardFiltersState,
    DataFilter,
    typeof actionTypes
  >(namespace,
    getInitialState,
    actionTypes,
  );

  return {
    actions,
    reducer,
    getInitialState,
  };
};

export default createEndpointEventsTableFiltersSlice;
