import { FC } from 'react';
import useRouteBuilder from 'hooks/useRouteBuilder';
import TableBodyCellNoContent from 'components/Table/TableBodyCell';
import ButtonWithImage from 'components/ButtonWithImage';
import { AppRoutes } from 'routes/RouteBuilder';
import styles from './EventDetailsRowWithLink.module.scss';
import { DATA_EXTRACTION_VIEW_QUERY_PARAM } from '../../../../../pages/ExtractionHistory/ExtractionHistory';

export enum EventDetailsLinkDestination {
  DataExtractionResult = 'dataExtractionResult',
  DataExtraction = 'dataExtraction',
  DocumentType = 'documentType',
}

interface EventDetailsRowWithLinkProps {
  label: string;
  value: string;
  linkDestination: EventDetailsLinkDestination;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
}

const EventDetailsRowWithLink: FC<EventDetailsRowWithLinkProps> = ({
  label,
  value,
  linkDestination,
  className,
  labelClassName,
  valueClassName,
}) => {
  const routeBuilder = useRouteBuilder();

  const handleLinkClick = () => {
    switch (linkDestination) {
      case EventDetailsLinkDestination.DataExtractionResult:
        return routeBuilder.go(AppRoutes.extractionHistory, value);
      case EventDetailsLinkDestination.DataExtraction:
        return routeBuilder.withParams({
          [DATA_EXTRACTION_VIEW_QUERY_PARAM]: value,
        }).go(AppRoutes.extractionHistory);
      case EventDetailsLinkDestination.DocumentType:
        return routeBuilder.withParams({ edit: value }).go(AppRoutes.documentTypes);
    }
  }

  return (
    <div key={label} className={className}>
      <p className={labelClassName}>{label}</p>
      <p className={valueClassName}>{value || <TableBodyCellNoContent />}</p>
      {value && (
        <ButtonWithImage
          title='Link'
          kind='link'
          className={styles.buttonWithImage}
          onClick={handleLinkClick}
        />
      )}
    </div>
  );
};

export default EventDetailsRowWithLink;
