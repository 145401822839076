import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import PasswordInput from 'components/PasswordInput';
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import styles from './ConfirmPasswordStep.module.scss';

interface ConfirmPasswordStepProps {
  onContinue: (password: string) => Promise<void>;
}

const ConfirmPasswordStep: FC<ConfirmPasswordStepProps> = ({ onContinue }) => {
  const [password, setPassword] = useState('');
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isPasswordValid = password.length >= 1;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <div>
      <div className={styles.message}>Please verify your password to confirm your identity.</div>
      <PasswordInput
        placeholder="Enter your password"
        labelTitle="Your Password"
        value={password}
        required
        onChange={handleOnChange}
        onKeyDown={TriggerEventOnEnterKeyDown(buttonRef, 'click')}
      />
      <ButtonWithLoadingState
        ref={buttonRef}
        className={styles.button}
        disabled={!isPasswordValid}
        size="form"
        onClick={() => onContinue(password)}
      >
        Continue
      </ButtonWithLoadingState>
    </div>
  );
};

export default ConfirmPasswordStep;
