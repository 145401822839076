import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import Title from './Title';
import Overview from './Overview';
import Invoices from './Invoices';
import { OrganizationShortInfo } from '../../api/OrganizationApi';
import { getBillingInfo } from '../../BillingPage/Thunks';
import useDispatchWithUnwrap from '../../hooks/useDispatchWithUnwrap';
import styles from './BillingDashboard.module.scss';

interface BillingDashboardProps {
  onCompanyInfoEdit: (companyInfo: OrganizationShortInfo) => void;
}

const BillingDashboard = ({ onCompanyInfoEdit }: BillingDashboardProps) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const {
    billingInfo,
    invoices,
  } = useSelector((state: ReduxState) => state.billingPage);
  const {
    detailedInfo: companyInfo,
    isOrganizationLoading,
  } = useSelector((state: ReduxState) => state.organization);

  useEffect(() => {
    dispatchWithUnwrap(getBillingInfo());
  }, []);

  const isDataLoading = !billingInfo || isOrganizationLoading;

  return (
    <div>
      <Title className={styles.title} />
      <Overview
        billingInfo={isDataLoading ? null : billingInfo}
        companyInfo={isDataLoading ? null : companyInfo}
        onCompanyInfoEdit={onCompanyInfoEdit}
      />
      <Invoices invoices={invoices} />
    </div>
  );
};

export default BillingDashboard;
