import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './RunExtractionForm.module.scss';
import HomeDashboardCardLayout from 'components/HomeDashboard/HomeDashboardCardLayout/HomeDashboardCardLayout';
import { Option } from 'components/SelectInput/SelectInput';
import { CreateDataExtractionForm } from 'components/ExtractionHistory/Forms';
import {
  CreateDataExtractionFormParams,
} from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/CreateDataExtractionForm';
import { CreateBatchDataExtractionParams, IClassificationResult } from 'api/DataExtractionApi';
import { IDocumentTokenInfo } from 'api/DocumentTokenApi';
import ModeSwitcherButton from '../../ModeSwitcherButton';
import ExtractionFormMode from 'enums/ExtractionFormMode';
import CreateBatchDataExtractionForm from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/CreateBatchDataExtractionForm';

const FORM_MODES = [{
  key: ExtractionFormMode.SingleExtraction,
  name: 'Single',
}, {
  key: ExtractionFormMode.MultipleExtractions,
  name: 'Multiple',
}];

interface RunExtractionFormProps {
  className?: string;
  onCreateDataExtraction: (params: CreateDataExtractionFormParams) => Promise<void>;
  onCreateBatchDataExtraction: (params: CreateBatchDataExtractionParams) => Promise<void>;
  onFetchDocumentTypeOptions: (search: string) => Promise<Option[]>;
  params: CreateDataExtractionFormParams;
  onChange: (params: Partial<CreateDataExtractionFormParams>) => void;
  detectedDocumentTypes: IClassificationResult[] | null;
  isLoading: boolean;
  fileTokenInfo: IDocumentTokenInfo | null;
  mode: ExtractionFormMode;
  onModeSwitch: (mode: ExtractionFormMode) => void;
  onValidateFile: (file: File) => Promise<IDocumentTokenInfo | undefined>;
  isSwitchModeDisabled: boolean;
}

const RunExtractionForm: FC<RunExtractionFormProps> = ({
  className,
  params,
  detectedDocumentTypes,
  isLoading,
  fileTokenInfo,
  onCreateDataExtraction,
  onCreateBatchDataExtraction,
  onFetchDocumentTypeOptions,
  onChange,
  mode,
  onModeSwitch,
  onValidateFile,
  isSwitchModeDisabled,
}) => {
  const multipleFilesAllowedMode = mode === ExtractionFormMode.MultipleExtractions;

  return (
    <HomeDashboardCardLayout
      title={`Analyze Document${multipleFilesAllowedMode ? 's' : ''}`}
      titleControl={
        <ModeSwitcherButton<ExtractionFormMode>
          modes={FORM_MODES}
          mode={mode}
          onChange={onModeSwitch}
          disabled={isSwitchModeDisabled}
        />
      }
      className={clsx(styles.container, className)}
      bodyClassName={styles.body}
      headerClassName={styles.header}
      useMobileLayout
    >
      {!multipleFilesAllowedMode && (
        <CreateDataExtractionForm
          onFetchDocumentTypeOptions={onFetchDocumentTypeOptions}
          onCreateDataExtraction={onCreateDataExtraction}
          params={params}
          onChange={onChange}
          detectedDocumentTypes={detectedDocumentTypes}
          isLoading={isLoading}
          fileTokenInfo={fileTokenInfo}
          mode={mode}
        />
      )}
      {multipleFilesAllowedMode && (
        <CreateBatchDataExtractionForm
          onFetchDocumentTypeOptions={onFetchDocumentTypeOptions}
          onCreateDataExtraction={onCreateBatchDataExtraction}
          params={params}
          onChange={onChange}
          detectedDocumentTypes={detectedDocumentTypes}
          isLoading={isLoading}
          fileTokenInfo={fileTokenInfo}
          mode={mode}
          onValidateFile={onValidateFile}
        />
      )}
    </HomeDashboardCardLayout>
  );
};

export default RunExtractionForm;
