import React, { FC, useState } from 'react';
import { UpdateWebhookEndpointParams, WebhookEndpoint } from 'api/WebhookEndpointsApi';
import { WebhookEvent } from 'api/WebhookEventsApi';
import { TabSwitchOption } from 'components/TabSwitch/TabSwitch';
import DetailsHeader from 'components/DetailsHeader';
import { RowAction } from 'components/RowActions/RowActions';
import { SkeletonRectangle } from 'components/Skeleton';
import { Tag } from 'components/Tag';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import EndpointEvents from './EndpointEvents/EndpointEvents';
import EndpointOverview from './EndpointOverview/EndpointOverview';
import EndpointIntegration from './EndpointIntegration/EndpointIntegration';
import styles from './EndpointDetails.module.scss';

enum EndpointDetailsTab {
  Endpoint = 'endpoint',
  SecretKey = 'secret-key',
  Events = 'events',
}

export interface EndpointDetailsProps {
  webhookEndpoint: WebhookEndpoint | null;
  webhookEndpointId: string;
  onDeleteWebhook: (id: string) => void;
  onChangeWebhookStatus: (id: string, active: boolean) => void;
  onGenerateNewWebhookEndpointSecret: (id: string) => void;
  onDeleteWebhookEvent: (webhookEventId: string) => void;
  onWebhookEventClick: (webhookEvent: WebhookEvent) => void;
  onResendWebhookEvent: (webhookEventId: string) => void;
  onUpdateWebhookEndpoint: (webhookEndpointId: string, params: Partial<UpdateWebhookEndpointParams>) => Promise<void>;
  isWebhookEndpointUpdateInProgress?: boolean;
}

const TABS: TabSwitchOption[] = [{
  id: EndpointDetailsTab.Endpoint,
  label: 'Endpoint',
}, {
  id: EndpointDetailsTab.SecretKey,
  label: 'Secret Key',
}, {
  id: EndpointDetailsTab.Events,
  label: 'Events',
}];

const EndpointDetails: FC<EndpointDetailsProps> = ({
  webhookEndpoint,
  webhookEndpointId,
  onDeleteWebhook,
  onChangeWebhookStatus,
  onGenerateNewWebhookEndpointSecret,
  onDeleteWebhookEvent,
  onWebhookEventClick,
  onResendWebhookEvent,
  onUpdateWebhookEndpoint,
  isWebhookEndpointUpdateInProgress,
}) => {
  const [selectedTab, setSelectedTab] = useState<EndpointDetailsTab>(EndpointDetailsTab.Endpoint);
  const members = useOrganizationMembers();

  const renderTab = () => {
    if (selectedTab === EndpointDetailsTab.Endpoint) {
      return (
        <EndpointOverview
          webhookEndpoint={webhookEndpoint}
          onUpdateWebhookEndpoint={onUpdateWebhookEndpoint}
          isUpdateInProgress={isWebhookEndpointUpdateInProgress}
        />
      );
    }

    if (selectedTab === EndpointDetailsTab.SecretKey) {
      return (
        <EndpointIntegration
          webhookEndpoint={webhookEndpoint}
          onGenerateNewSecret={() => onGenerateNewWebhookEndpointSecret(webhookEndpointId)}
        />
      );
    }

    return (
      <EndpointEvents
        webhookEndpointId={webhookEndpointId}
        onDeleteWebhookEvent={onDeleteWebhookEvent}
        onWebhookEventClick={onWebhookEventClick}
        onResendWebhookEvent={onResendWebhookEvent}
      />
    );
  };

  const handleTabSelect = ({ id }: TabSwitchOption) => setSelectedTab(id as EndpointDetailsTab);

  const getActionInfo = () => {
    const user = members.find(({ id }) => id === webhookEndpoint?.updatedBy);

    return webhookEndpoint
      ? { performedBy: user, actionDateAt: webhookEndpoint.updatedAt }
      : null;
  }

  const actions: RowAction[] | null = webhookEndpoint ? [{
    title: webhookEndpoint.active ? 'Set As Inactive' : 'Set As Active',
    handler: () => onChangeWebhookStatus(webhookEndpointId, !webhookEndpoint.active),
    separatorRequired: true,
  }, {
    title: 'Delete Webhook',
    handler: () => onDeleteWebhook(webhookEndpointId),
    danger: true,
  }] : null;

  const renderSubtitleTags = () => {
    if (!webhookEndpoint) {
      return (
        <SkeletonRectangle width="59px" height="24px" color="primary6" />
      );
    }

    if (webhookEndpoint.active) {
      return (
        <Tag color="green">Active</Tag>
      );
    }

    return (
      <Tag color="gray">Inactive</Tag>
    );
  };

  return (
    <div>
      <DetailsHeader
        className={styles.detailsHeader}
        title="Endpoint Details"
        actions={actions}
        actionInfo={getActionInfo()}
        referenceId={webhookEndpoint?.id || null}
        tabs={TABS}
        selectedTabId={selectedTab}
        onSelectTab={handleTabSelect}
        subtitleTags={renderSubtitleTags()}
      />
      {renderTab()}
    </div>
  );
};

export default EndpointDetails;
