import React, { forwardRef, ReactElement } from 'react';
import clsx from 'clsx';
import {
  DownloadImage,
  AddImage,
  ShareImageCustomColor,
  MoveBackImage,
  EditImage,
  LeftArrowImage,
  ChevronIcon,
  UploadImage,
  ExpandIcon,
} from 'static/images';
import WrapperWithTooltip from '../Tooltip';
import { noop } from 'lodash';
import styles from './ButtonWithImage.module.scss';

type ButtonWithImageKind = 'download' | 'add' | 'link' | 'back' | 'edit' | 'leftArrow' | 'topArrow' | 'upload' | 'expand';

interface ButtonWithImageProps {
  title: ReactElement | string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  kind?: ButtonWithImageKind;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
  reverse?: boolean;
  tooltip?: React.ReactNode | null;
}

// eslint-disable-next-line consistent-return
const getButtonImage = (kind: ButtonWithImageKind): ReactElement => {
  // eslint-disable-next-line default-case
  switch (kind) {
    case 'download':
      return <DownloadImage className={styles.downloadImage} />;
    case 'add':
      return <AddImage className={styles.addImage} />;
    case 'link':
      return <ShareImageCustomColor className={styles.shareImage} />;
    case 'back':
      return <MoveBackImage className={styles.imageStyles} />;
    case 'edit':
      return <EditImage className={styles.editImage} />;
    case 'leftArrow':
      return <LeftArrowImage className={styles.leftArrowImage} />;
    case 'topArrow':
      return <ChevronIcon className={styles.chevronIcon} />;
    case 'upload':
      return <UploadImage className={styles.uploadImage} />
    case 'expand':
      return <ExpandIcon className={styles.expandIcon} />;
  }
};

const ButtonWithImage = forwardRef<HTMLButtonElement, ButtonWithImageProps>(
  ({ reverse, title, onClick, kind = 'download', className, disabled, tabIndex, tooltip }, ref) => (
    <WrapperWithTooltip tooltip={tooltip} interactive>
      <button
        ref={ref}
        type="button"
        onClick={disabled ? noop : onClick}
        className={clsx(styles.buttonWithImage, className, disabled && styles.disabled, reverse && styles.reversedButton)}
        tabIndex={tabIndex}
      >
        {getButtonImage(kind)}
        {title}
      </button>
    </WrapperWithTooltip>
  ),
);

export default ButtonWithImage;
