import React, { FC, useState } from 'react';
import AddIpAddressStep from 'pages/ApiCredentials/CreatePublicApiWhitelistPopup/AddIpAddressStep';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createPublicApiWhitelist } from 'PublicApi/ActionCreator';
import { CreatePublicApiWhitelistParams } from 'api/PublicApiWhitelistApi';
import notification from 'handlers/notification/notificationActionCreator';
import WithIdentityConfirmActionPopup from 'components/WithIdentityConfirmationActionPopup';

export interface CreatePublicApiWhitelistPopupProps {
  ipsTotalCount: number;
  onClose: () => void;
}

const CreatePublicApiWhitelistPopup: FC<CreatePublicApiWhitelistPopupProps> = ({ ipsTotalCount, onClose }) => {
  const dispatch = useDispatchWithUnwrap();

  const [displayAddIpAddressStep, setDisplayAddIpAddressStep] = useState(false);

  const handleConfirmationComplete = async () => {
    setDisplayAddIpAddressStep(true);
  };

  const handleSubmit = async (whitelists: CreatePublicApiWhitelistParams[]) => {
    await dispatch(createPublicApiWhitelist(whitelists));

    notification.createNotification('IP Addresses have been successfully added.', 'success', dispatch);

    onClose();
  };

  return (
    <WithIdentityConfirmActionPopup
      actionName="Add IP Address"
      onClose={onClose}
      onConfirmationComplete={handleConfirmationComplete}
      showChildren={displayAddIpAddressStep}
    >
      <AddIpAddressStep ipsTotalCount={ipsTotalCount} onSubmit={handleSubmit} />
    </WithIdentityConfirmActionPopup>
  );
};

export default CreatePublicApiWhitelistPopup;
