import { FC, useState } from 'react';
import copy from 'copy-to-clipboard';
import WrapperWithTooltip from 'components/Tooltip';
import { SmallCopy } from 'static/images';
import styles from './EventDetailsRowWithCopy.module.scss';

interface EventDetailsRowWithCopyProps {
  label: string;
  value: string;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
};

enum State {
  Default = 'Default',
  Copied = 'Copied',
}

const STATE_RESET_TIME = 2000; //2s

const EventDetailsRowWithCopy: FC<EventDetailsRowWithCopyProps> = ({
  label,
  value,
  className,
  labelClassName,
  valueClassName,
}) => {
  const [state, setState] = useState(State.Default);

  const handleCopyClick = () => {
    if (state === State.Copied) {
      return;
    }

    const isCopied = copy(value!);

    if (isCopied) {
      setState(State.Copied);

      setTimeout(() => setState(State.Default), STATE_RESET_TIME);
    }
  };

  return (
    <div key={label} className={className}>
      <p className={labelClassName}>{label}</p>
      <p className={valueClassName}>{value}</p>
      {value && (
        <WrapperWithTooltip tooltip={state === State.Copied ? 'Copied!' : `Click to copy`}>
          <SmallCopy className={styles.icon} onClick={handleCopyClick} />
        </WrapperWithTooltip>
      )}
    </div>
  );
};

export default EventDetailsRowWithCopy;
