import { FC, ReactElement, ReactNode } from 'react';
import ConnectedNotifications from 'Notifications/Notifications';
import { ReactComponent as LogoIcon } from './logo.svg';
import AuthFooter from '../AuthFooter/AuthFooter';
import clsx from 'clsx';

import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
  title?: string | ReactNode;
  subTitle?: ReactElement | string;
  children: ReactNode;
  secondaryBlock?: React.ReactElement;
  formFooter?: React.ReactElement;
  subTitleClassName?: string;
}

const AuthLayout: FC<AuthLayoutProps> = ({
  children,
  secondaryBlock,
  title,
  subTitle,
  formFooter,
  subTitleClassName,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {secondaryBlock && (
          <div className={styles.secondaryBlockContainer}>
            {secondaryBlock}
          </div>
        )}
        <div className={styles.mainFormOuterContainer}>
          <div className={styles.mainFormContainer}>
            <div className={styles.mainFormHeader}>
              <LogoIcon className={styles.logo} />
            </div>
            <div className={styles.mainFormBody}>
              <div className={styles.title}>{title}</div>
              <div className={clsx(styles.subTitle, subTitleClassName)}>{subTitle}</div>
              <div className={styles.formContent}>
                {children}
              </div>
            </div>
          </div>
          {formFooter && <div className={styles.formFooter}>{formFooter}</div>}
        </div>
      </div>
      <AuthFooter className={styles.footer} />
      <ConnectedNotifications className={styles.notifications} />
    </div>
  );
};

export default AuthLayout;
