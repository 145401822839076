enum PublicApiActionType {
  GetAllApiKeys = 'publicApi/getAllApiKeys',
  CreateApiKey = 'publicApi/createApiKey',
  DeleteApiKey = 'publicApi/deleteApiKey',
  GetAllWhitelists = 'publicApi/getAllWhitelists',
  CreateApiWhitelist = 'publicApi/createApiWhitelist',
  DeleteApiWhitelist = 'publicApi/deleteApiWhitelist',
}

export default PublicApiActionType
