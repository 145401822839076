import BaseSystemApi from './BaseApi';

export type OrganizationShortInfo = Pick<OrganizationInfo, 'name'>;

export interface OrganizationInfo {
  id: string;
  name: string;
  legalAddress?: string;
  stripeCustomerId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateOrganizationParams {
  legalAddress?: string;
  name?: string;
}

export interface OrganizationApi {
  getCurrent(): Promise<OrganizationInfo>;
  update(params: UpdateOrganizationParams): Promise<OrganizationInfo>;
}

export default class OrganizationRestApi extends BaseSystemApi implements OrganizationApi {
  protected resourceName = 'organization';

  public async getCurrent() {
    return this.fetch<OrganizationInfo>(`/${this.resourceName}`, 'GET');
  }

  public async update(params: UpdateOrganizationParams) {
    return this.fetch<OrganizationInfo>(`/${this.resourceName}`, 'PUT', params);
  }
}
