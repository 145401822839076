import MainLayout, { PageContent, PageWrapperWithFooter } from 'components/MainLayout';
import DocumentTypesDashboard from 'components/DocumentType/DocumentTypesDashboard';
import { useCloseContextualView } from 'components/MainLayout/utils';
import useBlockingRequest from 'hooks/useBlockingRequest';
import { useState } from 'react';
import DocumentTypePopup from 'components/DocumentType/Popups/DocumentTypePopup/DocumentTypePopup';
import {
  CreateDocumentTypeParams,
  DocumentType,
  DuplicateDocumentTypeParams,
  UpdateDocumentTypeParams,
} from 'api/DocumentTypeApi';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import {
  createDocumentType,
  deleteDocumentType,
  duplicateDocumentType,
  updateDocumentType,
} from 'DocumentTypes/Thunks';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import useConfirmChanges from 'hooks/useConfirmChanges';
import ConfirmPopup from 'components/ConfirmPopup/ConfirmPopup';
import { documentTypesDashboardActionOrigin } from 'GeneralSettings/ui/DocumentTypesDashboard/DocumentTypesDashboardStore';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import DocumentTypeContextualView from 'components/DocumentType/DocumentTypeContextualView';
import useOrganizationMembers from 'hooks/useOrganizationMembers';

const DOCUMENT_TYPES_CONTAINER_ID = 'DOCUMENT_TYPES_CONTAINER_ID';

const DocumentTypes = () => {
  const routeBuilder = useRouteBuilder();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const closeEditDocumentTypesContextualView = useCloseContextualView();
  const organizationMembers = useOrganizationMembers();
  const documentTypeId = routeBuilder.currentQueryParams.get('edit');
  const dataExtractionResultId = routeBuilder.currentQueryParams.get('dataExtractionResultId') || undefined;
  const isCreateNewDocumentTypeTriggered = routeBuilder.currentQueryParams.get('new');

  const [documentTypeToEdit, setDocumentTypeToEdit] = useState<DocumentType | null>(null);
  const [documentTypeToDuplicate, setDocumentTypeToDuplicate] = useState<DocumentType | null>(null);

  const showEditDocumentTypePopup = (documentType: DocumentType) => setDocumentTypeToEdit(documentType);
  const hideEditDocumentTypePopup = () => setDocumentTypeToEdit(null);

  const showDuplicateDocumentTypePopup = (documentType: DocumentType) => setDocumentTypeToDuplicate(documentType);
  const hideDuplicateDocumentTypePopup = () => setDocumentTypeToDuplicate(null);

  const [createDocumentTypeInProgress, useBlockingCreateDocumentTypeCallback] = useBlockingRequest();
  const [displayCreateDocumentTypePopup, setDisplayCreateDocumentTypePopup] = useState(!!isCreateNewDocumentTypeTriggered);

  const [deleteDocumentTypeInProgress, useBlockingDeleteDocumentTypeCallback] = useBlockingRequest();
  const [
    displayConfirmDocumentTypeDelete,
    resetConfirmDocumentTypeDelete,
    onConfirmDocumentTypeDelete,
    useConfirmDocumentTypeDeleteCallback,
    documentTypeToDelete,
  ] = useConfirmChanges<DocumentType>();

  const [isDocumentTypeEditInProgress, handleEditDocumentType] = useAsyncActionCallback(
    async (params: UpdateDocumentTypeParams) => {
      await dispatchWithUnwrap(updateDocumentType({ ...params, actionOrigin: documentTypesDashboardActionOrigin }));

      hideEditDocumentTypePopup();
    }, []);

  const [isDocumentTypeDuplicateInProgress, handleDuplicateDocumentType] = useAsyncActionCallback(
    async (params: DuplicateDocumentTypeParams) => {
      await dispatchWithUnwrap(duplicateDocumentType({ ...params, actionOrigin: documentTypesDashboardActionOrigin }));

      hideDuplicateDocumentTypePopup();
    }, []);

  const renderContextualViewPage = () => {
    if (documentTypeId) {
      return (
        <DocumentTypeContextualView
          documentTypeId={documentTypeId}
          members={organizationMembers}
          onClose={closeEditDocumentTypesContextualView}
          onDeleteDocumentType={handleDeleteDocumentType}
          onEditDocumentType={showEditDocumentTypePopup}
          onDuplicateDocumentType={showDuplicateDocumentTypePopup}
        />
      )
    }

    return null;
  }

  const renderOverlay = () => {
    if (displayCreateDocumentTypePopup) {
      return (
        <DocumentTypePopup
          title='Add Document Type'
          submitButtonTitle='Add Document Type'
          onClose={() => setDisplayCreateDocumentTypePopup(false)}
          onSubmit={handleCreateDocumentType}
          isActionOnProgress={createDocumentTypeInProgress}
        />
      );
    }

    if (displayConfirmDocumentTypeDelete) {
      return (
        <ConfirmPopup
          title='Delete Document Type'
          message={`Please confirm that you would like to delete your ${documentTypeToDelete.name} document type.`}
          confirmText='Delete Document Type'
          declineText='No, Go Back'
          onPopupClose={resetConfirmDocumentTypeDelete}
          onConfirmClick={onConfirmDocumentTypeDelete}
          loading={deleteDocumentTypeInProgress}
        />
      )
    }

    if (documentTypeToEdit) {
      return (
        <DocumentTypePopup
          title='Edit Document Type Name'
          isActionOnProgress={isDocumentTypeEditInProgress}
          documentType={documentTypeToEdit}
          submitButtonTitle='Save Changes'
          onClose={hideEditDocumentTypePopup}
          onSubmit={handleEditDocumentType}
        />
      )
    }

    if (documentTypeToDuplicate) {
      const message =
        `The document type named ${documentTypeToDuplicate.name} will \
          be duplicated as a new document type with the name provided below.`;

      return (
        <DocumentTypePopup
          title='Duplicate Document Type'
          message={message}
          isActionOnProgress={isDocumentTypeDuplicateInProgress}
          documentType={{...documentTypeToDuplicate, name: '' }}
          submitButtonTitle='Duplicate Document Type'
          onClose={hideDuplicateDocumentTypePopup}
          onSubmit={handleDuplicateDocumentType}
        />
      )
    }

    return null;
  }

  const handleDeleteDocumentType = useConfirmDocumentTypeDeleteCallback(
    useBlockingDeleteDocumentTypeCallback(async (documentType: DocumentType) => {
      await dispatchWithUnwrap(deleteDocumentType({ id: documentType.id, actionOrigin: documentTypesDashboardActionOrigin }));

      closeEditDocumentTypesContextualView();
    }),
    (documentType) => documentType,
  );

  const handleCreateDocumentType = useBlockingCreateDocumentTypeCallback(async (params: CreateDocumentTypeParams) => {
    const documentType = await dispatchWithUnwrap(createDocumentType({
      ...params,
      dataExtractionResultId,
      actionOrigin: documentTypesDashboardActionOrigin,
    }));

    setDisplayCreateDocumentTypePopup(false);
    routeBuilder.withParams({ edit: documentType.id }).go(AppRoutes.documentTypes);
  });

  return (
    <MainLayout
      overlay={renderOverlay()}
      contextualView={renderContextualViewPage()}
      closeContextualView={closeEditDocumentTypesContextualView}
      containerId={DOCUMENT_TYPES_CONTAINER_ID}
    >
      <PageWrapperWithFooter>
        <PageContent>
          <DocumentTypesDashboard
            containerId={DOCUMENT_TYPES_CONTAINER_ID}
            members={organizationMembers}
            onDeleteDocumentType={handleDeleteDocumentType}
            onCreateDocumentType={() => setDisplayCreateDocumentTypePopup(true)}
            onEditDocumentType={showEditDocumentTypePopup}
            onDuplicateDocumentType={showDuplicateDocumentTypePopup}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default DocumentTypes;
