import React, { FC } from 'react';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import styles from './OverlayScrollContainer.module.scss';

interface ScrollContainerWithOpacityProps {
  className?: string;
}

const OverlayScrollContainer: FC<ScrollContainerWithOpacityProps> = ({ children, className }) => {
  return (
    <OverlayScrollbarsComponent
      className={clsx(styles.container, className)}
      options={{
        overflowBehavior: { x: 'hidden', y: 'scroll' },
      }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
};

export default OverlayScrollContainer;
