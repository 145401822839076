import React, { forwardRef, PropsWithChildren } from 'react';
import Footer from 'components/Footer';
import clsx from 'clsx';

import styles from './TableWrapperWithFooter.module.scss';

interface TableWrapperWithFooterProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const TableWrapperWithFooter = forwardRef<HTMLDivElement, PropsWithChildren<TableWrapperWithFooterProps>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={clsx(styles.tableWrapperWithFooter, className)}>
        {children}
        <Footer separator className={styles.footer} />
      </div>
    );
  },
);

export default TableWrapperWithFooter;
