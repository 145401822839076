import PieChart, { PIE_CHART_COLORS, PieChartProps } from 'components/Charts/PieChart/PieChart';
import React, { FC } from 'react';
import styles from './PieChartWithLegend.module.scss';
import OverflowedText from 'components/OverflowedText/OverflowedText';

interface PieChartWithLegendProps extends PieChartProps {
}

const PieChartWithLegend: FC<PieChartWithLegendProps> = ({
  data,
  ...props
}) => {
  return (
    <div>
      <PieChart
        {...props}
        data={data}
      />
      <div className={styles.legend}>
        {data.map(({ name }, index) => (
          <div key={name} className={styles.legendItem}>
            <div
              className={styles.legendItemColor}
              style={{ backgroundColor: PIE_CHART_COLORS[index % PIE_CHART_COLORS.length] }}
            />
            <OverflowedText
              className={styles.legendItemText}
              useTooltip
            >
              {name}
            </OverflowedText>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChartWithLegend;
