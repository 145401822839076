import { TableActionCell, TableBodyCell, TableRow } from 'components/Table';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import { TableColumnSize } from '../TableColumnSize';
import { FC } from 'react';

interface SkeletonDataExtractionRowProps {
  className?: string;
}

const SkeletonDataExtractionDesktopRow: FC<SkeletonDataExtractionRowProps> = ({
  className,
}) => {
  return (
    <TableRow disableHover className={className}>
      <TableBodyCell width={TableColumnSize.Date} noPadding>
        <SkeletonCircle width="28px" height="28px" color="primary6" marginRight="8px" />
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.FileName}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.FileType}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.DocumentType}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.Pages}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.ProcessingTime}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSize.Result}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonDataExtractionDesktopRow;
