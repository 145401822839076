export type DataExtractionResultFlatValue = string | number | boolean | null;
export type DataExtractionResultObjectValue = Record<string, DataExtractionResultFlatValue>;
export type DataExtractionResultValue = DataExtractionResultFlatValue | DataExtractionResultObjectValue;

export interface MappedDataExtractionResult {
  key: string;
  value: DataExtractionResultValue;
}

export const mapDataExtractionResult = (dataExtractionResult: string): MappedDataExtractionResult[] => {
  const resultJson = JSON.parse(dataExtractionResult);

  const result: MappedDataExtractionResult[] = [];

  for (const [key, value] of Object.entries(resultJson)) {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        result.push({ key: `${key} [${index + 1}]`, value: item });
      });

      continue;
    }

    result.push({ key, value: value as DataExtractionResultValue });
  }

  return result;
};
