import BaseApi from 'api/BaseApi';
import { DataFilter, DateRange, TableViewData, DataViewSortingType } from './Types';
import AppHeader from 'enums/AppHeader';

export interface DataExtraction {
  id: string;
  documentTypeIds?: string[];
  documentTypeNames?: string[];
  statusSummary: {
    [DataExtractionStatus.Passed]?: number;
    [DataExtractionStatus.Failed]?: number;
    [DataExtractionStatus.Processing]?: number;
    [DataExtractionStatus.Undetected]?: number;
  };
  fileNames: string[];
  fileTypes: DataExtractionFileType[];
  totalDurationTime?: number;
  pagesCount?: number;
  createdBy?: string | null;
  createdAt: Date;
  isAutoDetected?: boolean;
}

export enum DataExtractionMode {
  Quality = 'quality',
  Speed = 'speed',
}

export enum DataExtractionFileType {
  Pdf = 'pdf',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Bmp = 'bmp',
  Webp = 'webp',
}

export enum DataExtractionStatus {
  Passed = 'passed',
  Failed = 'failed',
  Processing = 'processing',
  Undetected = 'undetected',
}

export interface DataExtractionFilters extends DataFilter {
  status?: DataExtractionStatus;
  documentTypeId?: string;
  fileType?: DataExtractionFileType;
  createdAtRange?: DateRange;
  teamMembers?: string[];
}

export enum DataExtractionSortingField {
  CreatedAt = 'createdAt',
  FileName = 'fileNames',
  Status = 'statuses',
  FileType = 'fileTypes',
  PagesCount = 'pagesCount',
  TotalDurationTime = 'totalDurationTime',
  DocumentTypeNames = 'documentTypeNames',
}

export interface CreateDataExtractionParams {
  documentTypeId: string | null;
  mode: DataExtractionMode;
  useForAutoDetect: boolean;
  documentContentId: string;
}

export interface CreateBatchDataExtractionParams {
  documentTypeId: string;
  mode: DataExtractionMode;
  documentContentIds: string[];
  useForAutoDetect: boolean;
}

export interface DetectDocumentTypeParams {
  documentContentId: string;
  captchaToken: string;
}

export interface IClassificationResult {
  documentTypeName: string;
  documentTypeId: string;
  precision: number;
}

export interface DocumentTypeDetectionResult {
  documentTypes: IClassificationResult[];
  documentContentId: string;
}

export type DataExtractionSortingType = DataViewSortingType<DataExtractionSortingField>

export interface DataExtractionApi {
  search(filters: DataExtractionFilters, sortingType?: DataExtractionSortingType): Promise<TableViewData<DataExtraction>>;
  findById(id: string): Promise<DataExtraction>;
  create(params: CreateDataExtractionParams): Promise<DataExtraction>;
  createBatch(params: CreateBatchDataExtractionParams): Promise<DataExtraction>;
  delete(id: string): Promise<void>;
  detectDocumentType(params: DetectDocumentTypeParams): Promise<DocumentTypeDetectionResult>;
}

export default class DataExtractionRestApi extends BaseApi<DataExtraction> implements DataExtractionApi {
  protected resourceName = 'data-extractions';

  public async search(
    filters: DataExtractionFilters,
    sortingType?: DataExtractionSortingType,
  ): Promise<TableViewData<DataExtraction>> {
    const urlSearchParams = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters.teamMembers) {
      filters.teamMembers.forEach((memberId) => urlSearchParams.append('teamMemberIds', memberId));
    }

    if (filters.createdAtRange?.from) {
      urlSearchParams.append('createdAtFrom', filters.createdAtRange.from.toISOString());
    }

    if (filters.createdAtRange?.to) {
      urlSearchParams.append('createdAtTo', filters.createdAtRange.to.toISOString());
    }

    if (filters.status) {
      urlSearchParams.append('status', filters.status);
    }

    if (filters.documentTypeId) {
      urlSearchParams.append('documentTypeId', filters.documentTypeId);
    }

    if (filters.fileType) {
      urlSearchParams.append('fileType', filters.fileType);
    }

    return this.getResources<TableViewData<DataExtraction>>(urlSearchParams);
  }

  public async findById(id: string) {
    return this.getResourceById(id);
  }

  public async create(params: CreateDataExtractionParams): Promise<DataExtraction> {
    const formData = new FormData();

    if (params.documentTypeId) {
      formData.append('documentTypeId', params.documentTypeId);
    }

    formData.append('mode', params.mode);
    formData.append('useForAutoDetect', params.useForAutoDetect ? 'true' : 'false');
    formData.append('documentContentId', params.documentContentId);

    return this.fetch<DataExtraction>(`/${this.resourceName}`, 'POST', formData, {
      contentType: null,
    });
  }

  public async detectDocumentType(params: DetectDocumentTypeParams): Promise<DocumentTypeDetectionResult> {
    const formData = new FormData();

    formData.append('documentContentId', params.documentContentId);

    return this.fetch<DocumentTypeDetectionResult>(`/document-type-detection`, 'POST', formData, {
      contentType: null,
      headers: {
        [AppHeader.CaptchaToken]: params.captchaToken,
      },
    });
  }

  public async createBatch(params: CreateBatchDataExtractionParams): Promise<DataExtraction> {
    return this.fetch<DataExtraction>(`/data-extraction-batches`, 'POST', params);
  }

  public async delete(id: string): Promise<void> {
    return this.deleteResource(id);
  }
}
