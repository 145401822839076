export enum UsersActionType {
  GetUsers = 'users/getUsers',
  GetCurrentUser = 'users/getCurrentUser',
  InviteUser = 'users/inviteUser',
  CancelInvitation = 'users/cancelInvitation',
  ResendUserInvite = 'users/resendUserInvite',
  UpdateUser = 'users/updateUser',
  GetUser = 'users/getUser',
  SaveUsers = 'users/saveUsers',
}
