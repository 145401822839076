import React, { FC, useState } from 'react'
import ChartLayout from 'components/Charts/ChartLayout';
import PerformanceOverviewIndicatorsLayout from './PerformanceOverviewIndicatorsLayout';
import LineChart from 'components/Charts/LineChart';

import styles from './PerformanceOverviewChart.module.scss';
import { PerformanceOverviewDashboardChart } from 'api/PerformanceOverviewMapper';

interface PerformanceOverviewChartProps {
  performanceOverview: PerformanceOverviewDashboardChart;
  targetRef: React.RefObject<HTMLDivElement>;
}

const WIDTH_OF_ELEMENTS_WITHOUT_CHART = 60;
const HEIGHT_OF_ELEMENTS_WITHOUT_CHART = 20;

const calculateChartSize = (width: number, height: number) => {
  return {
    width: width - WIDTH_OF_ELEMENTS_WITHOUT_CHART,
    height: height - HEIGHT_OF_ELEMENTS_WITHOUT_CHART,
  };
}

const PerformanceOverviewChart: FC<PerformanceOverviewChartProps> = ({
  performanceOverview,
  targetRef,
}) => {
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });

  const handleOnResize = (width: number, height: number) => {
    if (width !== chartSize.width || height !== chartSize.height) {
      setChartSize(calculateChartSize(width, height));
    }
  };

  return (
    <ChartLayout
      onResize={handleOnResize}
      className={styles.chartLayout}
      targetRef={targetRef}
    >
      <LineChart
        width={chartSize.width}
        height={chartSize.height}
        formatXValue={PerformanceOverviewIndicatorsLayout.formatXValue}
        formatYValue={PerformanceOverviewIndicatorsLayout.formatYValue}
        formatTooltipTitle={PerformanceOverviewIndicatorsLayout.formatXValue}
        tooltipLabel="Pages"
        points={performanceOverview.points}
      />
    </ChartLayout>
  );
}

export default PerformanceOverviewChart;
