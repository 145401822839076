import React, { FC, useEffect, useState } from 'react';
import { UpdateWebhookEndpointParams, WebhookEndpoint } from 'api/WebhookEndpointsApi';
import useInputValidation from 'hooks/useInputValidation';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import DetailsForm from 'components/DetailsForm';
import EventTypeMultiselect from 'components/EventTypeMultiselect';
import TextInput from 'components/TextInput';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import areArraysEqual from 'utils/areArraysEqual';
import { validateUrl } from 'utils/validation/validation';
import { trim } from 'lodash';
import useDocumentTypes from 'hooks/useDocumentTypes';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';

export interface EndpointOverviewProps {
  webhookEndpoint: WebhookEndpoint | null;
  onUpdateWebhookEndpoint: (webhookEndpointId: string, params: Partial<UpdateWebhookEndpointParams>) => Promise<void>;
  isUpdateInProgress?: boolean;
}

const URL_VALIDATORS = [validateUrl];

const INTEGRATION_COLUMNS = 1;

const EndpointOverview: FC<EndpointOverviewProps> = ({
  webhookEndpoint,
  onUpdateWebhookEndpoint,
  isUpdateInProgress,
}) => {
  const documentTypes = useDocumentTypes();

  const [isEditMode, setIsEditMode] = useState(false);
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [events, setEvents] = useState<string[]>([]);
  const [documentTypeIds, setDocumentTypeIds] = useState<string[]>(webhookEndpoint?.documentTypeIds || []);

  const documentTypeNames = webhookEndpoint?.documentTypeIds.map((id) => {
    return documentTypes?.find((documentType) => documentType.id === id)?.name || '';
  });

  const isFormDataWasChanged = () => {
    if (!webhookEndpoint) {
      return false;
    }

    return url !== webhookEndpoint.url
      || description !== webhookEndpoint.description
      || !areArraysEqual(events, webhookEndpoint.events)
      || !areArraysEqual(documentTypeIds, webhookEndpoint.documentTypeIds);
  };

  const [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChanges,
    useCallbackWithUnsavedChanges,
  ] = useUnsavedChanges(() => isFormDataWasChanged());

  useEffect(() => {
    if (!webhookEndpoint) {
      return;
    }

    setUrl(webhookEndpoint.url);
    setDescription(webhookEndpoint.description || '');
    setEvents(webhookEndpoint.events);
  }, [webhookEndpoint]);

  const handleSwitchEditMode = useCallbackWithUnsavedChanges(() => {
    if (isEditMode && webhookEndpoint) {
      setUrl(webhookEndpoint.url);
      setDescription(webhookEndpoint.description || '');
      setEvents(webhookEndpoint.events);
    }

    setIsEditMode(!isEditMode);
  });

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => setUrl(trim(event.currentTarget.value));
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.currentTarget.value);

  const handleSelectEvent = (eventType: string) => {
    setEvents([...events, eventType]);
  };

  const handleDeselectEvent = (eventType: string) => {
    setEvents(events.filter((event) => event !== eventType));
  };

  const handleSaveChanges = async () => {
    if (!webhookEndpoint) {
      return;
    }

    await onUpdateWebhookEndpoint(webhookEndpoint.id, {
      ...(url !== webhookEndpoint.url ? { url } : {}),
      ...(description !== webhookEndpoint.description ? { description } : {}),
      ...(!areArraysEqual(events, webhookEndpoint.events) ? { events } : {}),
      ...(!areArraysEqual(documentTypeIds, webhookEndpoint.documentTypeIds) ? { documentTypeIds } : {}),
    });

    setIsEditMode(false);
  };

  const [
    onUrlFieldBlur,
    onUrlFieldFocus,
    urlErrorMessage,
    isUrlFieldValid,
  ] = useInputValidation({
    validators: URL_VALIDATORS,
    value: url,
    required: true,
  });

  const isFormDataInvalid = () => {
    return isUrlFieldValid;
  };

  const fields = webhookEndpoint && documentTypes
    ? [{
      label: 'URL',
      value: webhookEndpoint.url,
    }, {
      label: 'Description',
      value: webhookEndpoint.description,
    }, {
      label: 'Listening For',
      value: webhookEndpoint.events.join(', ') || 'All Events',
    }, {
      label: 'Document Type',
      value: documentTypeNames!.join(', ') || 'All Document Types',
    }] : null;

  const documentTypeOptions = documentTypes?.map(({ name, id }) => ({ name, value: id })) || [];

  useEffect(() => {
    if (webhookEndpoint?.documentTypeIds) {
      setDocumentTypeIds(webhookEndpoint.documentTypeIds);
    }
  }, [webhookEndpoint?.documentTypeIds]);

  return (
    <>
      <DetailsForm
        title="Overview"
        labelWidth="150px"
        fields={fields}
        isEditMode={isEditMode}
        onSwitchEditMode={handleSwitchEditMode}
        columns={INTEGRATION_COLUMNS}
        isSaveChangesButtonDisabled={!isFormDataWasChanged() || !isFormDataInvalid()}
        isSaveChangesInProgress={isUpdateInProgress}
        onSaveChanges={handleSaveChanges}
        editButtonMessage='Edit'
      >
        <TextInput
          labelTitle="URL"
          value={url}
          onChange={handleUrlChange}
          onBlur={onUrlFieldBlur}
          onFocus={onUrlFieldFocus}
          errorMessage={urlErrorMessage}
        />
        <TextInput
          labelTitle="Description"
          value={description}
          onChange={handleDescriptionChange}
        />
        <EventTypeMultiselect
          placeholder={events.length === 0 ? 'All Events' : undefined}
          labelTitle="Listening For"
          id="listeningFor"
          selectedEventTypes={events}
          onSelectEventType={handleSelectEvent}
          onDeselectEventType={handleDeselectEvent}
        />
        <AutoCompletionMultiselect
          options={documentTypeOptions}
          onDeleteOption={(idToDelete) => setDocumentTypeIds((ids) => ids.filter((id) => id !== idToDelete))}
          onSelect={(id) => setDocumentTypeIds((ids) => [...ids, id])}
          selectedOptions={documentTypeIds}
          placeholder={documentTypeIds.length === 0 ? 'All Document Types' : undefined}
          id="documentTypes"
          labelTitle="Document Type"
          getOptionByOptionValue={(value) => documentTypeOptions.find((option) => option.value === value)}
          placeholderIsAlwaysVisible
        />
      </DetailsForm>
      {displayUnsavedChanges && <UnsavedChangesPopup
        onPopupClose={onLeaveUnsavedChanges}
        onLeaveClick={onConfirmUnsavedChanges}
        usePortal
      />}
    </>
  );
};

export default EndpointOverview;
