export enum DocumentExtension {
  Pdf = 'pdf',
  Txt = 'txt',
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xml = 'xml',
  Xlsx = 'xlsx',
  Json = 'json',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Jpeg = 'jpeg',
  Png = 'png',
  Jpg = 'jpg',
  Gif = 'gif',
  Csv = 'csv',
  Word = 'word',
  Zip = 'zip',
  Ico = 'ico',
  Heic = 'heic', // iphone image format
  Webp = 'webp', // android image format
  Bmp = 'bmp',
}
