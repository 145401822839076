import React from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import ResetPasswordForm from 'components/Auth/ResetPasswordForm';
import { useAuthProvider } from 'providers/AuthProvider';
import { useDispatch } from 'react-redux';
import notification from 'handlers/notification/notificationActionCreator';
import { AppRoutes } from 'routes/RouteBuilder';
import useQueryParam from 'hooks/useQueryParam';
import { NotificationComponentTypes } from 'handlers/notification/notificationTypes';
import useRouteBuilder from 'hooks/useRouteBuilder';
import AuthFormFooter from '../../components/Auth/AuthFormFooter/AuthFormFooter';

const ResetPassword = () => {
  const routeBuilder = useRouteBuilder();
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const token = useQueryParam('token');

  if (!token) {
    return null;
  }

  const handleSubmit = async (password: string) => {
    try {
      await authProvider.resetPassword(password, token);
    } catch (requestError) {
      if (requestError.data?.expired) {
        notification.createNotification(
          {
            componentType: NotificationComponentTypes.ResetPasswordExpiredErrorNotificationComponent,
            props: undefined,
          },
          'error',
          dispatch,
        );
        return;
      }
    }

    notification.createNotification('Your password has been updated. Please sign in.', 'success', dispatch);

    routeBuilder.go(AppRoutes.auth.signIn);
  };

  const renderFormFooter = () => {
    return (
      <AuthFormFooter
        linkText="Back To Sign In"
        linkTo={routeBuilder.build(AppRoutes.auth.signIn)}
      />
    );
  };

  return (
    <AuthLayout
      title="Create New Password"
      formFooter={renderFormFooter()}
    >
      <ResetPasswordForm onSubmit={handleSubmit} />
    </AuthLayout>
  );
};

export default ResetPassword;
