import React, { FC } from 'react';
import clsx from 'clsx';
import { BasicUser } from 'api/UserApi';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import RowActions, { RowAction } from 'components/RowActions/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import QuestionIcon from 'components/QuestionIcon';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import ClickToCopyButton from 'components/ClickToCopyButton';
import OverflowedText from 'components/OverflowedText';
import styles from './DetailsHeader.module.scss';

export interface DetailsHeaderProps {
  title?: string | null;
  actionInfo?: {
    performedBy?: BasicUser | null;
    actionDateAt?: Date;
    actionName?: string;
    shortActionName?: string;
  } | null;
  referenceId?: string | null;
  actions?: RowAction[] | null;
  className?: string;
  actionsButtonClassName?: string;
  actionsContainerClassName?: string;
  subtitleSectionClassName?: string;
  subtitleSectionContainerClassName?: string;
  titleSectionClassName?: string;
  copyReferenceIdButtonClassName?: string;
  tabsClassName?: string;
  tabs?: TabSwitchOption[];
  selectedTabId?: string;
  questionIconTooltip?: React.ReactNode;
  questionIconTooltipLoaded?: boolean;
  questionTooltipClassName?: string;
  titleClassName?: string;
  useQuestionIcon?: boolean;
  onSelectTab?: (tab: TabSwitchOption) => void;
  subtitleTags?: React.ReactNode;
  subtitleTagsPosition?: 'before' | 'after';
  renderAfterRowActions?: () => React.ReactNode;
  isActionsLoading?: boolean;
  overflowedTitle?: boolean;
  actionsButtonComponent?: React.ReactNode;
  tagsContainerClassName?: string;
  subtitleTagsClassName?: string;
}

const DetailsHeader: FC<DetailsHeaderProps> = ({
  title,
  className,
  referenceId,
  actionInfo,
  actions,
  tabs,
  selectedTabId,
  questionTooltipClassName,
  subtitleSectionClassName,
  titleClassName,
  actionsButtonClassName,
  actionsContainerClassName,
  titleSectionClassName,
  questionIconTooltip,
  questionIconTooltipLoaded,
  useQuestionIcon,
  onSelectTab,
  subtitleTags,
  renderAfterRowActions,
  subtitleTagsPosition = 'after',
  isActionsLoading,
  tabsClassName,
  subtitleSectionContainerClassName,
  copyReferenceIdButtonClassName,
  overflowedTitle,
  actionsButtonComponent,
  tagsContainerClassName,
  subtitleTagsClassName,
}) => {
  const renderTitle = () => {
    if (!title) {
      return <SkeletonRectangle width="400px" height="44px" color="primary6" />;
    }

    const TitleTag = overflowedTitle ? OverflowedText : 'h2';

    return (
      <TitleTag
        useTooltip
        className={clsx(styles.title, titleClassName)}
        tooltipClassName={styles.titleTooltip}
      >
        {title}
      </TitleTag>
    );
  };

  const renderRowActions = () => {
    if (actionsButtonComponent) {
      return actionsButtonComponent;
    }

    if (!actions) {
      return actions === undefined
        ? null
        : <SkeletonRectangle marginLeft="14px" marginRight="22px" width="44px" height="44px" color="primary6" />;
    }

    return (
      <RowActions
        className={clsx(styles.actionsButton, actionsButtonClassName)}
        actions={actions}
        isLoading={isActionsLoading}
      />
    );
  };

  const renderActionInformation = () => {
    if (actionInfo === undefined) {
      return null;
    }

    if (actionInfo === null) {
      return (
        <div className={styles.actionInformation}>
          <SkeletonCircle marginRight="8px" width="24px" height="24px" color="primary6" />
          <SkeletonRectangle width="241px" height="20px" color="primary6" />
        </div>
      );
    }

    const displayActionName = actionInfo.actionName || 'Updated';

    return (
      <div className={styles.actionInformation}>
        <WithSystemApiUserAvatar className={styles.avatar} size={8.57} user={actionInfo.performedBy} />
        {actionInfo.actionDateAt && (
          <p>
            <p className={styles.actionNameShort}>{actionInfo.shortActionName || displayActionName}</p>
            <p className={styles.actionName}>{displayActionName}</p>
            {' '}
            {formatDate(actionInfo.actionDateAt, DateTimeFormat.Long)}
          </p>
        )}
      </div>
    );
  };

  const renderQuestionIcon = () => {
    if (!useQuestionIcon) {
      return null;
    }

    if (!questionIconTooltipLoaded) {
      return (
        <SkeletonCircle marginLeft="12px" width="24px" height="24px" color="primary6" />
      );
    }

    return questionIconTooltip ? (
      <QuestionIcon
        className={styles.questionIcon}
        tooltipClassName={questionTooltipClassName}
        size={24}
        tooltip={questionIconTooltip}
      />
    ) : null;
  };

  const renderClickToCopyButton = () => {
    if (referenceId === undefined) {
      return null;
    }

    if (referenceId === null) {
      return (
        <SkeletonRectangle width="230px" height="24px" color="primary6" />
      );
    }

    return (
      <ClickToCopyButton value={referenceId} className={clsx(styles.clickToCopy, copyReferenceIdButtonClassName)} />
    );
  };

  const renderSubtitleTags = () => {
    if (!subtitleTags) {
      return null;
    }

    return (
      <div className={clsx(
        styles.subtitleTags,
        subtitleTagsPosition === 'before' && styles.beforeSubtitleTags,
        subtitleTagsClassName,
      )}>
        {subtitleTags}
      </div>
    );
  };

  const renderSubtitleSection = () => {
    const renderedClickToCopyButton = renderClickToCopyButton();
    const renderedSubtitleTags = renderSubtitleTags();
    const renderedActionInformation = renderActionInformation();

    if (!renderedClickToCopyButton && !renderedSubtitleTags && !renderedActionInformation) {
      return null;
    }

    return (
      <div className={clsx(styles.subtitleSectionContainer, subtitleSectionContainerClassName)}>
        {(renderedActionInformation || renderedSubtitleTags) &&
          <div className={clsx(styles.subtitleSection, subtitleSectionClassName)}>
            {renderedActionInformation}
            <div className={clsx(styles.tagsContainer, tagsContainerClassName)}>
              {renderedSubtitleTags}
              {renderedClickToCopyButton}
            </div>
          </div>}
        {renderedClickToCopyButton}
      </div>
    );
  };

  const renderSections = () => {
    return (
      <>
        <div className={clsx(styles.titleSection, titleSectionClassName)}>
          {renderTitle()}
          {renderQuestionIcon()}
          <div className={clsx(styles.actionsContainer, actionsContainerClassName)}>
            {renderRowActions()}
            {renderAfterRowActions?.()}
          </div>
        </div>
        {renderSubtitleSection()}
      </>
    );
  };

  return (
    <div className={clsx(styles.detailsHeader, className)}>
      {renderSections()}
      {tabs && <TabSwitch tabsClassName={tabsClassName} tabs={tabs} selectedTabId={selectedTabId} onSelect={onSelectTab} />}
    </div>
  );
};

export default DetailsHeader;
