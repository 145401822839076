import React, { ChangeEvent, FC, useRef, useState } from 'react';
import TextInput from 'components/TextInput';
import TopRightButton from 'components/TopRightInputButton';
import useTimer from 'hooks/useTimer';
import TimerMessage from 'TimerMessage/TimeMessage';
import Button, { ButtonType } from 'components/Button/Button';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import styles from 'components/VerifyCode/VerifyCode.module.scss';

export interface VerifyCodeProps {
  message: string;
  buttonText: string;
  buttonKind?: ButtonType;
  onResendCode: () => Promise<void>;
  onSubmit: (code: string) => void;
  loading?: boolean;
}

const VerifyCode: FC<VerifyCodeProps> = ({
  message,
  buttonText,
  buttonKind = 'primary',
  onResendCode,
  onSubmit,
  loading,
}) => {
  const [code, setCode] = useState('');
  const { isRunning, seconds, restart } = useTimer();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const resendInSeconds = isRunning ? seconds : 0;

  const isCodeInvalid = code.length < 6;

  const handleVerificationCodeFieldValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleResendCode = () => {
    onResendCode();

    restart();
  };

  const topRightElement = (
    <TopRightButton disabled={resendInSeconds > 0} onClick={handleResendCode}>
      Resend Code
    </TopRightButton>
  );

  return (
    <div>
      <div className={styles.message}>{message}</div>
      <TextInput
        placeholder="6 digits"
        labelTitle="Verification Code"
        value={code}
        required
        onChange={handleVerificationCodeFieldValueChange}
        maxLength={6}
        topRightElement={topRightElement}
        onKeyDown={TriggerEventOnEnterKeyDown(buttonRef, 'click')}
      />
      {isRunning && <TimerMessage className={styles.timerMessage}>
        You can request another code in <strong>{resendInSeconds}</strong>s
      </TimerMessage>}
      <Button
        ref={buttonRef}
        disabled={isCodeInvalid}
        kind={buttonKind}
        size="form"
        offset="form"
        isLoading={loading}
        onClick={() => onSubmit(code)}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default VerifyCode;
