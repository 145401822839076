export const getFilenameAndExtension = (filename: string) => {
  const fileNameWithExtensionRegex = /(.*)\.(.*)$/;

  const matches = filename.match(fileNameWithExtensionRegex);

  if (!matches) {
    throw new Error(`Could not extract filename and extension from ${filename}`);
  }

  return {
    filename: matches[1],
    extension: matches[2].toLowerCase(),
  };
};
