import { ChangeEvent, FC, useEffect, useState } from 'react';
import TextInput from 'components/TextInput/TextInput';
import Button from 'components/Button/Button';
import { trim } from 'lodash';

import styles from './DocumentTypeForm.module.scss';

interface DocumentTypeData {
  name?: string;
  id?: string
}

interface DocumentTypeFormProps {
  data: DocumentTypeData;
  isActionOnProgress: boolean;
  submitButtonTitle: string;
  message?: string;
  onSubmit: (data: DocumentTypeData) => Promise<void>;
}

const DocumentTypeForm: FC<DocumentTypeFormProps> = ({
  data,
  isActionOnProgress,
  submitButtonTitle,
  message,
  onSubmit,
}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (data.name) {
      setName(data.name);
    }
  }, []);

  const isNameChanged = data.name !== name;

  const isSubmitDisabled = !isNameChanged || isActionOnProgress || !name.trim();

  const handleSubmit = async () => {
    const trimmedName = trim(name);

    const submitData = data.id 
      ? { id: data.id, name: trimmedName }
      : { name: trimmedName };

    onSubmit(submitData);
  }

  return (
    <>
      {message && <div className={styles.contentMessage}>{message}</div>}
      <TextInput
        required
        labelTitle='Document Type Name'
        value={name}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
        containerClassName={styles.inputContainer}
      />
      <Button
        kind='primary'
        size='form'
        disabled={isSubmitDisabled}
        onClick={() => handleSubmit()}
        isLoading={isActionOnProgress}
        className={styles.submitButton}
      >
        {submitButtonTitle}
      </Button>
    </>
  )
}

export default DocumentTypeForm;
