import React from 'react';
import clsx from 'clsx';
import { AppRoutes } from 'routes/RouteBuilder';
import ExternalSourceButton from 'components/ExternalSourceButton';
import { env } from '../../../env';
import styles from './Title.module.scss';

interface TitleProps {
  className?: string;
}

const Title = ({ className }: TitleProps) => {
  return (
    <div className={clsx(styles.container, className)}>
      <h2 className={styles.title}>Billing</h2>
      <ExternalSourceButton
        externalLink={`${env.REACT_APP_API_URL}${AppRoutes.customerPortal}`}
      >
        Payment Portal
      </ExternalSourceButton>
    </div>
  );
};

export default Title;
