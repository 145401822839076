import { createSelector } from 'reselect';
import { ReduxState } from 'types/ReduxState';
import { NullableItems } from 'pagination';

export const getDocumentTypesById = (state: ReduxState) => {
  return state.documentTypes.documentTypesById;
};

export const createGetDocumentTypesSelector = (ids: NullableItems<string>) => {
  return createSelector([getDocumentTypesById], (documentTypesById) => {
    return ids.map((id) => id? documentTypesById[id]: null);
  });
};
