import BaseApi, { FetchMethodType } from 'api/BaseApi';

interface PerformanceData {
  took: number;
}

export interface DocumentData extends PerformanceData {
  result: string;
}

interface IDocumentParsingsApi {
  parseDocument(file: File): Promise<DocumentData>;
}

export default class DocumentParsingsRestApi extends BaseApi implements IDocumentParsingsApi {
  public parseDocument(file: File): Promise<DocumentData> {
    const formData = new FormData();

    formData.append('file', file);

    return this.fetch('/document-parsings', FetchMethodType.Post, formData, {
      contentType: null,
    });
  }
}
