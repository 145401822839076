import { FC, memo, useEffect, useRef } from 'react';
import styles from './PdfPreview.module.scss';
import clsx from 'clsx';
import { Document, Page } from 'react-pdf';
import { noop } from 'lodash';

interface PdfPreviewProps {
  previewLink: string;
  width: number | null;
  disabled?: boolean;
  page: number;
  onDocumentLoad?: (pages: number) => void;
  onLoad?: () => void;
  onError?: () => void;
}

const PdfPreview: FC<PdfPreviewProps> = memo(({
  previewLink,
  width,
  disabled,
  page,
  onDocumentLoad,
  onLoad,
  onError,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const resize = () => {
    if (canvasRef.current && width) {
      canvasRef.current.style.height = '';
      canvasRef.current.style.width = `${width}px`;
    }
  };

  useEffect(() => {
    resize();
  }, [width, canvasRef])

  const renderEmptyLoader = () => <></>;

  return (
    <Document
      file={previewLink}
      loading={renderEmptyLoader}
      onLoadSuccess={(event) => onDocumentLoad?.(event.numPages)}
      onLoadError={onError}
      onPassword={noop} // overriding default behavior of the library, to hide Enter Password popup for encrypted files
    >
      <Page
        pageNumber={page}
        canvasRef={canvasRef}
        _className={clsx(disabled && styles.disabled)}
        loading={renderEmptyLoader}
        onRenderSuccess={() => resize()}
        onLoadSuccess={onLoad}
        onLoadError={onError}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    </Document>
  );
});

export default PdfPreview;
