import { FC, useEffect } from 'react';
import { DocumentTypeFieldChildrenViewData } from 'components/DocumentType/Forms/DocumentTypeFieldForm/DocumentTypeFieldForm';
import DocumentTypeFieldChildrenFormRow from './DocumentTypeFieldChildrenFormRow/DocumentTypeFieldChildrenFormRow';
import { DocumentTypeFieldViewDataType } from 'enums/DocumentTypeFieldViewDataType';
import AddDocumentTypeFieldChildrenButton from './AddDocumentTypeFieldChildrenButton/AddDocumentTypeFieldChildrenButton';

const MAX_CHILDREN_FIELD_COUNT = 50;
const LIMIT_FIELDS_TOOLTIP_MESSAGE = 'Your field limit has been reached. Please email support@synthetiq.co to request an increase.';

interface DocumentTypeFieldChildrenFormProps {
  fieldDataChildren: DocumentTypeFieldChildrenViewData[];
  onNameChange: (name: string, position: number) => void;
  onDataTypeChange: (dataType: DocumentTypeFieldViewDataType, position: number) => void;
  onAddChildren: () => void;
  onRemoveChildren: (position: number) => void;
}

const DocumentTypeFieldChildrenForm: FC<DocumentTypeFieldChildrenFormProps> = ({
  fieldDataChildren,
  onNameChange,
  onDataTypeChange,
  onAddChildren,
  onRemoveChildren,
}) => {
  const isAddFieldButtonDisabled = () => {
    if (fieldDataChildren.length === 0) {
      return false;
    }

    const lastField = fieldDataChildren[fieldDataChildren.length - 1];

    return !lastField?.name || !lastField?.dataType || fieldDataChildren.length === MAX_CHILDREN_FIELD_COUNT;
  };

  useEffect(() => {
    if (fieldDataChildren.length === 0) {
      onAddChildren();
    }
  }, []);

  const renderChildrenFormRow = () => {
    return (
      fieldDataChildren.map((children, index) => (
        <DocumentTypeFieldChildrenFormRow
          key={index}
          field={children}
          position={index}
          isOnlyChild={fieldDataChildren.length === 1}
          onNameChange={onNameChange}
          onDataTypeChange={onDataTypeChange}
          onRemoveChildren={onRemoveChildren}
        />
      ))
    );
  };

  return (
    <>
      {renderChildrenFormRow()}
      <AddDocumentTypeFieldChildrenButton
        title='Add Object Field'
        disabled={isAddFieldButtonDisabled()}
        onAddChildren={onAddChildren}
        tooltip={fieldDataChildren.length === MAX_CHILDREN_FIELD_COUNT && LIMIT_FIELDS_TOOLTIP_MESSAGE}
      />
    </>
  );
}

export default DocumentTypeFieldChildrenForm;
