import React, { FC } from 'react';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';
import MainLayout, { PageContent, PageWrapperWithFooter } from '../../components/MainLayout';
import styles from './NotFound.module.scss';

const NotFound: FC<{}> = () => {
  return (
    <MainLayout>
      <PageWrapperWithFooter>
        <PageContent noPadding>
          <ErrorPageInfo
            errorType={ErrorPageType.NotFound}
            errorTitle="Oops. The page was not found."
            className={styles.errorInfo}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default NotFound;
