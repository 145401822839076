import React, { useRef, useState } from 'react';
import styles from './ButtonWithDropdown.module.scss';
import ContextualPopUp from '../PopUps/ContextualPopUp';
import ActionPopUp from '../ActionPopUp';
import ActionPopUpItem from '../ActionPopUpItem';
import Button from '../Button';
import { DownloadImage } from '../../static/images';
import clsx from 'clsx';

interface ButtonWithDropdownProps<ActionType> {
  onActionClick: (actionType: ActionType) => void;
  actions: {
    type: ActionType;
    title: string;
  }[];
  title: string;
  buttonClassName?: string;
  disabled?: boolean;
}

const ButtonWithDropdown = <ActionType extends string>({
  actions,
  title,
  onActionClick,
  disabled,
  buttonClassName,
}: ButtonWithDropdownProps<ActionType>) => {
  const statusButtonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleClick = () => {
    setIsDropdownOpen((previousState) => !previousState);
  };

  return (
    <>
      <Button
        kind="secondary"
        className={clsx(styles.titleButton, buttonClassName)}
        onClick={handleClick}
        title={title}
        ref={statusButtonRef}
        disabled={disabled}
      >
        <DownloadImage className={styles.downloadImage} />
        {title}
      </Button>
      <ContextualPopUp
        className={styles.contextualPopUp}
        anchorEl={statusButtonRef.current}
        open={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ActionPopUp className={styles.actionPopUp} onClose={() => setIsDropdownOpen(false)} hideTitle>
          {actions.map(({ type, title: actionTitle }) => (
            <div className={styles.statusDropdownItemContainer} key={type as string}>
              <ActionPopUpItem
                className={styles.actionDropdownItem}
                onClick={() => onActionClick(type)}
              >
                {actionTitle}
              </ActionPopUpItem>
            </div>
          ))}
        </ActionPopUp>
      </ContextualPopUp>
    </>
  );
};

export default ButtonWithDropdown;
