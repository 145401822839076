import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentTypesActionType } from './DocumentTypesActionType';
import {
  CreateDocumentTypeParams,
  DocumentTypeFilters,
  UpdateDocumentTypeParams,
  DocumentType,
  DuplicateDocumentTypeParams,
  DocumentTypeSortingType,
} from 'api/DocumentTypeApi';
import { documentTypeApi } from 'stores/api';
import { TableViewData } from 'api/Types';
import { ActionOriginData, DataWithActionOrigin } from 'types/action-origin';

export const getDocumentTypes = createAsyncThunk<
TableViewData<DocumentType>,
DataWithActionOrigin<{ filters: DocumentTypeFilters, sortingType: DocumentTypeSortingType }>,
{ fulfilledMeta: DataWithActionOrigin<{ search?: string; }> }
>(
  DocumentTypesActionType.GetDocumentTypes,
  async ({
    filters,
    sortingType,
    actionOrigin,
  }, thunkApi) => {
    return thunkApi.fulfillWithValue(await documentTypeApi.search(filters, sortingType), {
      ...filters,
      actionOrigin,
    });
  },
);

export const updateDocumentType = createAsyncThunk<
  DocumentType,
  DataWithActionOrigin<UpdateDocumentTypeParams>,
  { fulfilledMeta: ActionOriginData }
>(
  DocumentTypesActionType.UpdateDocumentType,
  async (params, thunkApi) => {
    const { actionOrigin, ...updateParams } = params;

    return thunkApi.fulfillWithValue(await documentTypeApi.update(updateParams), {
      actionOrigin,
    });
  },
);

export const createDocumentType = createAsyncThunk<
  DocumentType,
  DataWithActionOrigin<CreateDocumentTypeParams>,
  { fulfilledMeta: ActionOriginData }
>(
  DocumentTypesActionType.CreateDocumentType,
  async (params, thunkApi) => {
    const { actionOrigin, ...createParams } = params;

    return thunkApi.fulfillWithValue(await documentTypeApi.create(createParams), {
      actionOrigin,
    });
  },
);

export const deleteDocumentType = createAsyncThunk<
  void,
  DataWithActionOrigin<{id: string}>,
  { fulfilledMeta: ActionOriginData }
>(
  DocumentTypesActionType.DeleteDocumentType,
  async (params, thunkApi) => {
    const { actionOrigin, id } = params;

    return thunkApi.fulfillWithValue(await documentTypeApi.delete(id), {
      actionOrigin,
    });
  },
);

export const getDocumentType = createAsyncThunk(
  DocumentTypesActionType.GetDocumentType,
  (id: string) => {
    return documentTypeApi.findById(id);
  },
);

export const duplicateDocumentType = createAsyncThunk<
  DocumentType,
  DataWithActionOrigin<DuplicateDocumentTypeParams>,
  { fulfilledMeta: ActionOriginData }
>(
  DocumentTypesActionType.DuplicateDocumentType,
  async (params, thunkApi) => {
    const { actionOrigin, ...restParams } = params;

    return thunkApi.fulfillWithValue(await documentTypeApi.duplicate(restParams), {
      actionOrigin,
    });
  },
)
