import { DataExtractionMode } from 'api/DataExtractionApi';
import Accordion from 'components/Accordion/Accordion';
import AutoCompletion from 'components/AutoCompletion';
import { CreateDataExtractionFormParams } from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/CreateDataExtractionForm';
import QuestionIcon from 'components/QuestionIcon/QuestionIcon';
import { Option } from 'components/SelectInput/SelectInput';
import StyledSwitch from 'components/StyledSwitch';
import useUniqId from 'hooks/useUniqId';
import React, { FC } from 'react';
import { ArrowDownImage, ArrowUpImage } from 'static/images';
import styles from './AdvancedSettings.module.scss';

interface AdvancedSettingsProps {
  params: CreateDataExtractionFormParams;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  onModeChange: (mode: DataExtractionMode) => void;
  onUseForAutoDetectChange: (useForAutoDetect: boolean) => void;
}

export const getProcessingModeName = (model: DataExtractionMode) => {
  switch (model) {
    case DataExtractionMode.Quality:
      return 'Highest Quality (Recommended)';
    case DataExtractionMode.Speed:
      return 'Fastest Processing';
    default:
      return null as never;
  }
}

const processingModeOptions: Option[] = Object.values(DataExtractionMode).map((model) => ({
  name: getProcessingModeName(model),
  value: model,
}));

const USE_FOR_AUTO_DETECT_TOOLTIP = (
  <>
    Document(s) will be used to enhance auto-<br/>
    detection for the selected document type.
  </>
);

const AdvancedSettings: FC<AdvancedSettingsProps> = ({
  params,
  isOpen,
  onToggle,
  onModeChange,
  onUseForAutoDetectChange,
}) => {
  const useForAutoDetectSwitchId = useUniqId('use-for-auto-detect');

  const renderParentRow = () => {
    return (
      <p className={styles.advanceSettingsContainer}>
        <div className={styles.advanceSettingsTitle}>
          Advanced Settings {isOpen ? <ArrowUpImage /> : <ArrowDownImage />}
        </div>
      </p>
    );
  };

  return (
    <Accordion
      triggerComponent={renderParentRow()}
      open={isOpen}
      onOpen={onToggle}
      className={styles.collapsibleContainer}
    >
      <AutoCompletion
        required
        labelTitle='Processing Mode'
        value={params.mode}
        options={processingModeOptions}
        hideClearIcon
        className={styles.processingModeContainer}
        onChange={(option) => onModeChange(option.value as DataExtractionMode)}
      />
      <div className={styles.useForAutoDetectSwitchContainer}>
        <StyledSwitch
          id={useForAutoDetectSwitchId}
          className={styles.switch}
          checked={params.useForAutoDetect}
          onChange={(event, checked) => onUseForAutoDetectChange(checked)}
          disabled={!params.documentTypeId}
        />
        <label htmlFor={useForAutoDetectSwitchId} className={styles.switchLabel}>
          Use for auto-detection
        </label>
        <QuestionIcon tooltip={USE_FOR_AUTO_DETECT_TOOLTIP} />
      </div>
    </Accordion>
  );
};

export default AdvancedSettings;
