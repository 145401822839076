import { MiddlewareAPI, AnyAction, isRejected } from '@reduxjs/toolkit';
import tap from 'lodash/tap';
import notification from 'handlers/notification/notificationActionCreator';
import createNotificationRateLimit from 'middlewares/DebounceBy';

export interface AsyncThunkNotificationRejectionMetaData {
  disableNotificationErrorHandling?: boolean;
}

const checkNotificationRateLimit = createNotificationRateLimit();

const AsyncThunkRejectionNotificationMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) =>
  tap(next(action), () => {
    if (action.meta?.disableNotificationErrorHandling || !isRejected(action) || action.error.name === 'AbortError') {
      return;
    }

    const { message } = action.error;

    const formattedMessage = message ?? 'Error';

    if (checkNotificationRateLimit(formattedMessage)) {
      notification.createNotification(formattedMessage, 'error', dispatch);
    }
  });

export default AsyncThunkRejectionNotificationMiddleware;
