import { useMemo, FC } from 'react';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import { EventTypeOption } from 'types/EventTypeOption';
import EventType from 'enums/EventType';

export interface EventTypeMultiselectProps {
  id: string;
  selectedEventTypes: string[];
  labelTitle?: string;
  onSelectEventType: (eventType: string) => void;
  onDeselectEventType: (eventType: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  hideDescription?: boolean;
  placeholder?: string;
  errorMessage?: string;
  required?: boolean;
}

const DEFAULT_EVENT_TYPE_OPTIONS: EventTypeOption[] = [
  {
    id: EventType.ExtractionSucceeded,
    name: EventType.ExtractionSucceeded,
    description: 'Data extraction succeeded',
  },
  {
    id: EventType.ExtractionFailed,
    name: EventType.ExtractionFailed,
    description: 'Data extraction failed',
  },
  {
    id: EventType.ExtractionUndetected,
    name: EventType.ExtractionUndetected,
    description: 'Document type undetected',
  },
  {
    id: EventType.BatchExtractionSucceeded,
    name: EventType.BatchExtractionSucceeded,
    description: 'Batch Data extraction succeeded',
  },
  {
    id: EventType.BatchExtractionFailed,
    name: EventType.BatchExtractionFailed,
    description: 'Batch Data extraction failed',
  },
]

const EventTypeMultiselect: FC<EventTypeMultiselectProps> = ({
  selectedEventTypes,
  labelTitle,
  onDeselectEventType,
  onSelectEventType,
  id,
  onBlur,
  onFocus,
  placeholder,
  hideDescription,
  errorMessage,
  required,
}) => {
  const mappedEventTypeOptions = useMemo(() => {
    return DEFAULT_EVENT_TYPE_OPTIONS.map((eventTypeOption) => ({
      name: eventTypeOption.id,
      value: eventTypeOption.id,
      description: !hideDescription ? eventTypeOption.description : '',
    }));
  }, [hideDescription]);

  return (
    <AutoCompletionMultiselect
      placeholder={placeholder}
      labelTitle={labelTitle || ''}
      onSelect={onSelectEventType}
      id={id}
      options={mappedEventTypeOptions}
      selectedOptions={selectedEventTypes}
      onDeleteOption={onDeselectEventType}
      onBlur={onBlur}
      onFocus={onFocus}
      errorMessage={errorMessage}
      required={required}
      placeholderIsAlwaysVisible
    />
  );
};

export default EventTypeMultiselect;
