import React, { forwardRef, useEffect, useState } from 'react';
import Button, { ButtonProps } from 'components/Button/Button';

export interface ButtonWithLoadingStateProps extends Omit<ButtonProps, 'onClick'> {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown>;
}

const ButtonWithLoadingState = forwardRef<HTMLButtonElement, ButtonWithLoadingStateProps>((props, ref) => {
  const { onClick } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true);
    try {
      await onClick(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(!!props.isLoading);
  }, [props.isLoading]);

  return <Button ref={ref} {...props} isLoading={isLoading} onClick={handleClick} />;
});

export default ButtonWithLoadingState;
