import {
  ActionCreatorWithPayload,
  AnyAction,
  AsyncThunk,
  CaseReducer,
  createAction,
  createReducer,
  Draft,
  Reducer,
} from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import mountChildReducers from 'utils/childReducers';
import { ItemsStateWithPagination } from 'pagination';
import { TableViewData } from 'api/Types';
import { DataWithActionOrigin } from 'types/action-origin';
import createDashboardFiltersAndSortingSaver from './FiltersAndSortingSaver';
import { BaseDashboardFiltersState } from './createDashboardFiltersSlice';
import { FilterAndSortingSaverReturnType } from 'filterAndSortingSaver/Types';
import { RemoveUndefinedFields } from 'types/RemoveUndefinedFields';

export interface DashboardState<DataFilters, SortingType> extends ItemsStateWithPagination<string> {
  sortingType: SortingType;
  searched: {
    [index: string]: ItemsStateWithPagination<string>;
  };
  filters: DataFilters;
}

export enum DashboardActionType {
  ChangeSortingType = 'ChangeSortingType',
  ResetState = 'ResetState',
  ChangeMembers = 'ChangeMembers',
  ChangeFilters = 'ChangeFilters',
  ClearFilters = 'ClearFilters',
}

export interface IBaseCreateDashboardSliceParams {
  namespace: string;
  path: string | string[];
  actionOrigins: string[];
}

interface BaseDashboardSliceActions {
  [DashboardActionType.ChangeSortingType]?: string;
  [DashboardActionType.ResetState]?: string;
  [DashboardActionType.ChangeMembers]?: string;
  [DashboardActionType.ChangeFilters]?: string;
  [DashboardActionType.ClearFilters]?: string;
}

export interface ICreateDashboardSliceParams<
  ItemType extends { id: string },
  ItemProps,
  SortingType extends { field: string, ascending: boolean },
  FiltersState extends BaseDashboardFiltersState,
> extends IBaseCreateDashboardSliceParams {
  itemsPerPage: number;
  defaultSorting: SortingType;
  getItems: AsyncThunk<
    TableViewData<ItemType>,
    DataWithActionOrigin<{ filters: ItemProps, sortingType: SortingType; }>,
    { fulfilledMeta: DataWithActionOrigin<{ search?: string; }> }
  >;
  actionTypes: BaseDashboardSliceActions,
  filtersActions: Record<string, ActionCreatorWithPayload<any>>;
  filtersAndSortingCustomTriggerActions?: string[];
  getFiltersInitialState: () => FiltersState;
  filtersAndSortingProps: string[];
  filtersReducer: Reducer<FiltersState>;
}

const getActionByActionType = <Payload = void>(
  namespace: string,
  actionType: DashboardActionType,
  actionTypes: BaseDashboardSliceActions,
) => {
  return actionTypes[actionType] ? createAction<Payload>(
    `${namespace}/${actionTypes[actionType]}`,
  ) : undefined;
};

type GetChangeSortingTypeValue<Actions, Payload> = Actions extends {
  [DashboardActionType.ChangeSortingType]: infer ActionType extends string
} ? ActionCreatorWithPayload<Payload, ActionType> : undefined;

type GetResetStateValue<Actions> = Actions extends {
  [DashboardActionType.ResetState]: infer ActionType extends string
} ? ActionCreatorWithPayload<void, ActionType> : undefined;

interface CreateDashboardSliceResult<
  FiltersState extends BaseDashboardFiltersState,
  SortingType extends { field: string, ascending: boolean },
  DashboardSliceActions extends BaseDashboardSliceActions,
> {
  getInitialState: () => DashboardState<FiltersState, SortingType>;
  filtersAndSortingSaver: FilterAndSortingSaverReturnType<unknown, unknown>;
  actions: RemoveUndefinedFields<{
    changeSortingType: GetChangeSortingTypeValue<DashboardSliceActions, SortingType>;
    resetState: GetResetStateValue<DashboardSliceActions>;
  }>;
  reducer: (state: DashboardState<FiltersState, SortingType>, action: AnyAction) => DashboardState<FiltersState, SortingType>;
}

export const createDashboardSlice = <
  ItemType extends { id: string },
  ItemProps,
  SortingType extends { field: string, ascending: boolean },
  FiltersState extends BaseDashboardFiltersState,
  DashboardSliceActions extends BaseDashboardSliceActions = BaseDashboardSliceActions,
>({
  path,
  namespace,
  actionOrigins,
  itemsPerPage,
  defaultSorting,
  getItems,
  actionTypes,
  filtersAndSortingProps,
  filtersActions,
  getFiltersInitialState,
  filtersReducer,
  filtersAndSortingCustomTriggerActions,
}: ICreateDashboardSliceParams<ItemType, ItemProps, SortingType, FiltersState>): CreateDashboardSliceResult<
  FiltersState,
  SortingType,
  DashboardSliceActions
> => {
  const changeSortingType = getActionByActionType<SortingType>(namespace, DashboardActionType.ChangeSortingType, actionTypes);
  const resetState = getActionByActionType(namespace, DashboardActionType.ResetState, actionTypes);

  const filtersAndSortingSaver = createDashboardFiltersAndSortingSaver(namespace, path, {
    [DashboardActionType.ChangeSortingType]: actionTypes[DashboardActionType.ChangeSortingType],
    [DashboardActionType.ChangeMembers]: actionTypes[DashboardActionType.ChangeMembers],
    [DashboardActionType.ChangeFilters]: actionTypes[DashboardActionType.ChangeFilters],
    [DashboardActionType.ClearFilters]: actionTypes[DashboardActionType.ClearFilters],
    [DashboardActionType.ResetState]: actionTypes[DashboardActionType.ResetState],
  }, filtersAndSortingProps, filtersAndSortingCustomTriggerActions);

  const getInitialState = (): DashboardState<FiltersState, SortingType> => {
    return {
      items: [],
      page: 1,
      itemsPerPage: itemsPerPage,
      itemsTotal: undefined,
      error: null,
      sortingType: filtersAndSortingSaver.getSavedSorting() as SortingType || defaultSorting,
      searched: {},
      filters: {
        ...getFiltersInitialState(),
        ...filtersAndSortingSaver.getSavedFilters() || {},
      } as FiltersState,
    };
  };

  const customCaseReducer: CaseReducer<DashboardState<FiltersState, SortingType>, { payload: any; type: string; }> = (
    state,
    action,
  ) => {
    state.filters = filtersReducer(state.filters as FiltersState, action) as Draft<FiltersState>;

    if (state.filters.search) {
      state.searched[state.filters.search] = {
        items: [],
        page: 1,
        itemsPerPage: itemsPerPage,
        itemsTotal: undefined,
        error: null,
      };

      return;
    }

    state.error = null;
    state.items = [];
    state.itemsPerPage = itemsPerPage;
    state.itemsTotal = undefined;
  }

  const tableReducer = createReducer(getInitialState(), (builder) => {
    builder.addCase(getItems.fulfilled, (state, action) => {
      if (!action.meta.actionOrigin || !actionOrigins.includes(action.meta.actionOrigin)) {
        return;
      }

      if (action.meta.search) {
        state.searched[action.meta.search] = {
          error: '',
          page: 1,
          itemsPerPage: itemsPerPage,
          items: action.payload.items.map((item) => item.id),
          itemsTotal: action.payload.total,
        }

        return;
      }

      state.error = '';
      state.page = 1;
      state.itemsPerPage = itemsPerPage;
      state.items = action.payload.items.map((item) => item.id);
      state.itemsTotal = action.payload.total;
    });

    if (changeSortingType) {
      builder.addCase(changeSortingType, (state, action) => {
        state.sortingType = action.payload;
      });
    }

    if (filtersActions.clearFilters) {
      builder.addCase(filtersActions.clearFilters, customCaseReducer);
    }

    if (filtersActions.changeMembers) {
      builder.addCase(filtersActions.changeMembers, customCaseReducer);
    }
  });

  const childReducers = { filters: filtersReducer };

  return {
    getInitialState,
    filtersAndSortingSaver,
    actions: {
      changeSortingType,
      resetState,
    } as unknown as CreateDashboardSliceResult<FiltersState, SortingType, DashboardSliceActions>['actions'],
    reducer: withStateReset(
      mountChildReducers(tableReducer, childReducers),
      `${namespace}/${actionTypes[DashboardActionType.ResetState]}`,
      getInitialState,
    ),
  };
};
