import { FC } from 'react';
import { JsonView as JsonViewBase } from 'react-json-view-lite';
import { StyleProps } from 'react-json-view-lite/dist/DataRenderer';
import ClickToCopyButton from 'components/ClickToCopyButton/ClickToCopyButton';
import clsx from 'clsx';

import styles from './JsonViewer.module.scss';

interface JsonViewProps {
  data: Object;
  isLoading?: boolean;
  className?: string;
  showCopyButton?: boolean;
}

const jsonViewStyles: StyleProps = {
  container: '',
  basicChildStyle: styles.childStyle,
  expander: styles.punctuation,
  pointer: styles.pointer,
  punctuation: styles.punctuation,
  label: styles.label,
  booleanValue: styles.value,
  nullValue: styles.value,
  numberValue: styles.value,
  otherValue: styles.value,
  stringValue: styles.value,
  undefinedValue: styles.value,
}

const JsonView: FC<JsonViewProps> = ({
  data,
  isLoading,
  className,
  showCopyButton,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      {showCopyButton && (
        <ClickToCopyButton
          className={styles.copyButton}
          value={JSON.stringify(data, null, '\t')}
          title='Copy'
          copyIconClassName={styles.copyIcon}
          titleClassName={styles.copyTitle}
        />
      )}
      {!isLoading
        ? <JsonViewBase data={data} style={jsonViewStyles} />
        : <p className={styles.loadingText}>Loading JSON...</p>
      }
    </div>
  )
}

export default JsonView;
