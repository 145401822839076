import { ChangeEvent, FC } from 'react';
import QuestionIcon from 'components/QuestionIcon';
import {
  WebhookEvent,
  WebhookEventDataFilter,
  WebhookEventSortingField,
  WebhookEventSortingType,
} from 'api/WebhookEventsApi';
import { NullableItems, PaginationProps } from 'pagination';
import NoItems from 'components/NoItems';
import SearchNotFound from 'components/SearchNotFound';
import { NoResultsIcon } from 'static/images';
import FilterButton from 'components/FilterButton';
import SearchInput from 'components/SearchInput';
import { EventsTable } from 'components/WebhooksOverview/Tables';
import styles from './EventsDashboard.module.scss';

interface EventsDashboardProps {
  events: NullableItems<WebhookEvent>;
  filters: WebhookEventDataFilter;
  sortingType: WebhookEventSortingType;
  paginationProps: PaginationProps;
  areFiltersChanged: boolean;
  onSort: (field: WebhookEventSortingField, ascending: boolean) => void;
  onSearch: (search: string) => void;
  onClearFilters: () => void;
  onFiltersButtonClick: () => void;
  onDelete: (id: string) => void;
  onResend: (id: string) => void;
  onDisplayDetails: (event: WebhookEvent) => void;
};

const EventsDashboard: FC<EventsDashboardProps> = ({
  events,
  filters,
  areFiltersChanged,
  paginationProps,
  sortingType,
  onClearFilters,
  onFiltersButtonClick,
  onSearch,
  onSort,
  onDelete,
  onResend,
  onDisplayDetails,
}) => {
  const renderTooltip = () => (
    <>
      <p>Events include only the updated information</p>
      <p>and are automatically deleted after 3 days</p>
    </>
  );

  const renderContent = () => {
    if (shouldRenderNoItemsAddedSection) {
      return (
        <NoItems className={styles.noItemsAddedSection} title="No events have been emitted yet!" />
      );
    }

    if (filters.search && !events.length) {
      return (
        <SearchNotFound searchValue={filters.search} />
      );
    }

    if (areFiltersChanged && !events.length) {
      return (
        <NoItems
          className={styles.noResultsFoundContainer}
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          onButtonClick={onClearFilters}
        />
      );
    }

    return (
      <EventsTable
        source={events}
        paginationProps={paginationProps}
        sortingType={sortingType}
        searchInputValue={filters.search || ''}
        onSort={onSort}
        onRowClick={onDisplayDetails}
        onDelete={onDelete}
        onResend={onResend}
        withEndpointColumn
      />
    );
  }

  const shouldRenderNoItemsAddedSection = events.length === 0 && !filters.search && !areFiltersChanged;

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerTitleWithTooltip}>
          <h4 className={styles.headerTitle}>Events</h4>
          <QuestionIcon size={24} tooltip={renderTooltip()} />
        </div>
        {!shouldRenderNoItemsAddedSection && <FilterButton
          containerClassName={styles.filterButtonContainer}
          active={areFiltersChanged}
          onClick={onFiltersButtonClick}
        />}
      </div>
      {!shouldRenderNoItemsAddedSection && <SearchInput
        value={filters.search}
        containerClassName={styles.searchInputContainer}
        placeholder="Search"
        onClear={() => onSearch('')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onSearch(event.target.value)}
      />}
      {renderContent()}
    </div>
  );
};

export default EventsDashboard;
