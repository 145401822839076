import React, { FC } from 'react';
import styles from './components.module.scss';

export interface ChartPointProps {
  x: number;
  y: number;
  hovered?: boolean;
  disabled?: boolean;
}

const RADIUS = 2;
const HOVERED_RADIUS = 4;

const ChartPoint: FC<ChartPointProps> = ({ x, y, hovered = false, disabled }) => {
  const lineColor = disabled ? styles.lineColorDisabled : styles.lineColor;

  return (
    <circle
      r={!disabled && hovered ? HOVERED_RADIUS : RADIUS}
      cx={x}
      cy={y}
      stroke={!disabled && hovered ? styles.pointColor : lineColor}
      fill={!disabled && hovered ? styles.lineColor : styles.pointColor}
      strokeWidth={styles.chartDotWidth}
    />
  );
};

export default ChartPoint;
