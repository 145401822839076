import { pick, get } from 'lodash';
import { ReduxState } from 'types/ReduxState';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';

enum ActionType {
  ChangeMembers = 'ChangeMembers',
  ChangeFilters = 'ChangeFilters',
  ClearFilters = 'ClearFilters',
  ChangeSortingType = 'ChangeSortingType',
  ResetState = 'ResetState',
}

const createDashboardFiltersAndSortingSaver = <
  FiltersToSave,
  SortingType
>(namespace: string, path: string | string[], actionType: {
  [ActionType.ChangeMembers]?: string;
  [ActionType.ChangeFilters]?: string;
  [ActionType.ClearFilters]?: string;
  [ActionType.ChangeSortingType]?: string;
  [ActionType.ResetState]?: string;
}, props: string[], customTriggerActions?: string[]) => {
  const getFiltersToSave = (state: ReduxState) => {
    return pick(
      get(state, path).filters,
      props,
    );
  };

  const getSortingToSave = (state: ReduxState) => get(state, path).sortingType;

  const saveTriggerActions = [
    isActionOfType(`${namespace}/${actionType[ActionType.ChangeMembers]}`),
    isActionOfType(`${namespace}/${actionType[ActionType.ChangeFilters]}`),
    isActionOfType(`${namespace}/${actionType[ActionType.ClearFilters]}`),
    isActionOfType(`${namespace}/${actionType[ActionType.ChangeSortingType]}`),
    ...(customTriggerActions ? customTriggerActions.map((action) => isActionOfType(action)) : []),
  ];

  if (customTriggerActions) {
    saveTriggerActions.push(...customTriggerActions.map((action) => isActionOfType(action)));
  }

  return FilterAndSortingSaver<FiltersToSave, SortingType>({
    key: namespace,
    getFiltersToSave: getFiltersToSave as (state: ReduxState) => FiltersToSave,
    getSortingToSave,
    saveTriggerActions,
    resetActionType: `${namespace}/${actionType[ActionType.ResetState]}`,
  });
};

export default createDashboardFiltersAndSortingSaver;
