import BaseSystemApi from './BaseApi';
import { DataFilter, DataViewSortingType, TableViewData } from './Types';

export interface DocumentType {
  id: string;
  name: string;
  updatedBy: string | null;
  createdBy: string | null;
  updatedAt: Date;
  isAutoDetectionEnabled: boolean;
  doesTrainingDataExist: boolean;
}

export interface DocumentTypeFilters extends DataFilter {
  selectedMembers?: string[];
}

export enum DocumentTypeSortingField {
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type DocumentTypeSortingType = DataViewSortingType<DocumentTypeSortingField>;

export interface CreateDocumentTypeParams {
  name: string;
  dataExtractionResultId?: string;
}

export interface UpdateDocumentTypeParams {
  id: string;
  name: string;
  isAutoDetectionEnabled: boolean;
}

export interface DuplicateDocumentTypeParams extends UpdateDocumentTypeParams {}

type DocumentTypePreviewFlatValue = string | number | boolean;

type DocumentTypePreviewFlatArrayValue = Array<string> | Array<number> | Array<boolean> | Array<DocumentTypePreviewObjectValue>;

type DocumentTypePreviewObjectValue = Record<string, DocumentTypePreviewFlatValue | DocumentTypePreviewFlatArrayValue>;

type DocumentTypePreviewValue = DocumentTypePreviewFlatValue | DocumentTypePreviewFlatArrayValue | DocumentTypePreviewObjectValue;

export interface DocumentTypeApi {
  search(filters: DocumentTypeFilters, sortingType: DocumentTypeSortingType): Promise<TableViewData<DocumentType>>;
  create(params: CreateDocumentTypeParams): Promise<DocumentType>;
  findById(id: string): Promise<DocumentType>;
  update(params: UpdateDocumentTypeParams): Promise<DocumentType>;
  delete(id: string): Promise<void>;
  duplicate(params: DuplicateDocumentTypeParams): Promise<DocumentType>;
  preview(id: string): Promise<Record<string, DocumentTypePreviewValue>>;
}

export default class DocumentTypeRestApi extends BaseSystemApi implements DocumentTypeApi {
  protected resourceName = 'document-types';

  public async search(filters: DocumentTypeFilters, sortingType: DocumentTypeSortingType): Promise<TableViewData<DocumentType>> {
    const urlSearchParams = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters.selectedMembers) {
      filters.selectedMembers.forEach((memberId) => urlSearchParams.append('teamMemberIds', memberId));
    }

    return this.getResources<TableViewData<DocumentType>>(urlSearchParams);
  }

  public async create(params: CreateDocumentTypeParams): Promise<DocumentType> {
    return this.createResource<DocumentType>(params);
  }

  public async findById(id: string): Promise<DocumentType> {
    return this.getResourceById<DocumentType>(id);
  }

  public async update(params: UpdateDocumentTypeParams): Promise<DocumentType> {
    return this.updateResource<DocumentType>(params.id, {
      name: params.name,
      isAutoDetectionEnabled: params.isAutoDetectionEnabled,
    });
  }

  public async delete(id: string): Promise<void> {
    return this.deleteResource<void>(id);
  }

  public async duplicate(params: DuplicateDocumentTypeParams): Promise<DocumentType> {
    return this.fetch<DocumentType>(`/${this.resourceName}/${params.id}/duplicate`, 'POST', { duplicatedName: params.name });
  }

  public async preview(id: string): Promise<Record<string, DocumentTypePreviewValue>> {
    return this.fetch<Record<string, DocumentTypePreviewValue>>(`/${this.resourceName}/${id}/preview`);
  }
}
