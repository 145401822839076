import styles from './ExternalProvidersSeparator.module.scss';

const ExternalProvidersSeparator = () => {
  return (
    <div className={styles.container}>
      <div className={styles.line} />
      <p className={styles.text}>or</p>
      <div className={styles.line} />
    </div>
  );
};

export default ExternalProvidersSeparator;
