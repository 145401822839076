import useRouteBuilder from 'hooks/useRouteBuilder';
import React, { FC } from 'react';
import { AppRoutes } from 'routes/RouteBuilder';
import styles from './ResetPasswordExpiredErrorNotification.module.scss';

const ResetPasswordExpiredErrorNotification: FC = () => {
  const routeBuilder = useRouteBuilder();

  const handleSignInClick = () => {
    routeBuilder.go(AppRoutes.auth.signIn);
  };

  return (
    <div>
      It looks like you already reset your password.{' '}
      <strong className={styles.signInLink} onClick={handleSignInClick}>
        Sign In
      </strong>
    </div>
  );
};

export default ResetPasswordExpiredErrorNotification;
