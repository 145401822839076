import DateTimeUtils from 'utils/dateUtils';
import { DEFAULT_DATE_FORMAT } from 'constants/DateFormat';

const getDefaultDateFormat = (variableValue: string, dateFormat: string) => {
  const UTCFormatDate = DateTimeUtils.parse(variableValue.toString(), dateFormat).toDate();
  const normalizedDate = DateTimeUtils.parse(UTCFormatDate).format(DEFAULT_DATE_FORMAT);
  return normalizedDate;
};

export default getDefaultDateFormat;
