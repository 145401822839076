import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './Header.module.scss';

export type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeaderProps {
  type?: HeaderType;
  className?: string;
}

const Header: FC<HeaderProps> = ({
  type = 'h4',
  className,
  children,
}) => {
  return (
    <div className={clsx(styles[type], className)}>{children}</div>
  );
};

export default Header;
