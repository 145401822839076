import { createSelector } from 'reselect';
import { ReduxState } from 'types/ReduxState';
import { NullableItems } from 'pagination';

export const getDataExtractionResultsById = (state: ReduxState) => {
  return state.dataExtractionResults.resultsById;
}

export const createGetDataExtractionResultsSelector = (ids: NullableItems<string>) => {
  return createSelector([getDataExtractionResultsById], (resultsById) => {
    return ids.map((id) => id ? resultsById[id] : null);
  });
};
