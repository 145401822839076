import clsx from 'clsx';
import React, { FC } from 'react';
import Footer from 'components/Footer';

import styles from './SearchNotFound.module.scss';

interface SearchNotFoundProps {
  searchValue: string;
  className?: string;
  footer?: boolean;
  small?: boolean;
}

const SearchNotFound: FC<SearchNotFoundProps> = ({ searchValue, className, footer, small }) => {
  return (
    <>
      <div className={clsx(styles.notFoundContainer, small && styles.smallNotFoundContainer, className)}>
        <h4>
          Sorry, we couldn't find any matches for <span>{searchValue}</span>
        </h4>
        <ul>
          <li>
            <p>Please make sure that the spelling is correct</p>
          </li>
          <li>
            <p>Use fewer keywords in your search</p>
          </li>
        </ul>
      </div>
      {footer && <Footer separator className={styles.footer} />}
    </>
  );
};

export default SearchNotFound;
