import { createAction, createReducer } from '@reduxjs/toolkit';
import EventsActionType from './EventsActionType';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import { getWebhookEvents } from './Thunks';
import { WebhookEvent } from 'api/WebhookEventsApi';

export interface EventsState {
  eventsById: Record<string, WebhookEvent>;
}

export const getInitialState = (): EventsState => ({
  eventsById: {},
});

export const setWebhookEvents = createAction<WebhookEvent[]>(EventsActionType.SetWebhookEvents);

const eventsReducer = createReducer<EventsState>(getInitialState(), (builder) => {
  builder.addCase(getWebhookEvents.fulfilled, (state, action) => {
    state.eventsById = {
      ...state.eventsById,
      ...normalizeEntityArray(action.payload.items),
    };
  });

  builder.addCase(setWebhookEvents, (state, action) => {
    state.eventsById = {
      ...state.eventsById,
      ...normalizeEntityArray(action.payload),
    };
  });
});

export default eventsReducer;
