import React, { FC, useState, ChangeEvent, useRef, useCallback } from 'react';
import AuthInput from '../Inputs/AuthInput/AuthInput';
import AuthPasswordInput from '../Inputs/AuthPasswordInput/AuthPasswordInput';
import { useRecaptcha } from '../../../CaptchaProvider';
import CaptchaAction from '../../../enums/CaptchaAction';
import validateCreateAccountData, { CreateAccountDataValidationResult } from './validateCreateAccountData';
import TriggerEventOnEnterKeyDown from '../../../utils/TriggerEventOnEnterKeyDown';
import { CreateAccountData, CreateAccountInformation } from '../../../api/AuthApi';
import AuthButton from '../Inputs/AuthButton/AuthButton';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { ExternalRoutes } from '../../../routes/RouteBuilder';
import ExternalProvidersAuthSection from '../ExternalProvidersAuthSection';

import authLinkStyles from '../Inputs/AuthLink/AuthLink.module.scss';
import styles from './CreateAccountForm.module.scss';

interface CreateAccountFormProps {
  onSubmit: (data: CreateAccountData) => Promise<void>;
}

const CreateAccountForm: FC<CreateAccountFormProps> = ({
  onSubmit,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');

  const lastNameRef = useRef<HTMLInputElement>(null);
  const companyNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [errors, setErrors] = useState<CreateAccountDataValidationResult>({});

  const [isLoading, setIsLoading] = useState(false);

  const executeCaptcha = useRecaptcha();

  const handleChangeValue = (
    key: keyof CreateAccountInformation,
    setValue: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>,
    ) => {
      setErrors({ ...errors, [key]: '' });

      setValue(event.target.value);
    };

  const handleBlur = (key: keyof CreateAccountInformation, value: string) => () => {
    const validationResult = validateCreateAccountData({ [key]: value });

    setErrors({ ...errors, [key]: validationResult[key] });
  };

  const validateForm = () => {
    return validateCreateAccountData({
      firstName,
      lastName,
      email,
      password,
    });
  };

  const handleSubmit = async () => {
    const validationResult = validateForm();

    if (Object.keys(validationResult).length > 0) {
      setErrors(validationResult);

      return;
    }

    const token = await executeCaptcha(CaptchaAction.CreateAccount);

    if (!token) {
      return;
    }

    await onSubmit({
      accountInformation: {
        firstName,
        lastName,
        email,
        password,
        companyName: companyName || undefined,
      },
      token,
    });
  };

  const handleSignInStarted = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const handleSignInFailed = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const submitIsDisabled = Object.values(validateForm()).length > 0 || isLoading;

  return (
    <div>
      <ExternalProvidersAuthSection
        onSignInStarted={handleSignInStarted}
        onSignInFailed={handleSignInFailed}
      />
      <div className={styles.fullNameContainer}>
        <AuthInput
          labelTitle="First Name"
          containerClassName={styles.nameInput}
          required
          autoFocus
          onChange={handleChangeValue('firstName', setFirstName)}
          onBlur={handleBlur('firstName', firstName)}
          value={firstName}
          tabIndex={1}
          errorMessage={errors.firstName}
          onKeyDown={TriggerEventOnEnterKeyDown(lastNameRef, 'focus')}
        />
        <AuthInput
          labelTitle="Last Name"
          containerClassName={styles.nameInput}
          required
          onChange={handleChangeValue('lastName', setLastName)}
          onBlur={handleBlur('lastName', lastName)}
          value={lastName}
          tabIndex={2}
          errorMessage={errors.lastName}
          onKeyDown={TriggerEventOnEnterKeyDown(companyNameRef, 'focus')}
          inputRef={lastNameRef}
        />
      </div>
      <AuthInput
        labelTitle="Company Name"
        onChange={handleChangeValue('companyName', setCompanyName)}
        value={companyName}
        tabIndex={3}
        onKeyDown={TriggerEventOnEnterKeyDown(emailRef, 'focus')}
        inputRef={companyNameRef}
      />
      <AuthInput
        labelTitle="Work Email"
        required
        onChange={handleChangeValue('email', setEmail)}
        onBlur={handleBlur('email', email)}
        value={email}
        tabIndex={4}
        errorMessage={errors.email}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordRef, 'focus')}
        inputRef={emailRef}
      />
      <AuthPasswordInput
        labelTitle="Password"
        required
        onChange={handleChangeValue('password', setPassword)}
        onBlur={handleBlur('password', password)}
        value={password}
        tabIndex={5}
        autoComplete="new-password"
        errorMessage={errors.password}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'focus')}
        ref={passwordRef}
      />
      <div className={styles.footer}>
        By clicking Create Account you agree to our{' '}
        <ExternalLink className={authLinkStyles.link} href={ExternalRoutes.MasterAgreement}>Master Subscription Agreement</ExternalLink>{' '}
        and{' '}
        <ExternalLink className={authLinkStyles.link} href={ExternalRoutes.PrivacyPolicy}>Privacy Policy</ExternalLink>.
      </div>
      <AuthButton
        className={styles.submit}
        onClick={handleSubmit}
        tabIndex={6}
        ref={submitButtonRef}
        disabled={submitIsDisabled}
        kind="primary"
        isLoading={isLoading}
      >
        Create Account
      </AuthButton>
    </div>
  );
};

export default CreateAccountForm;
