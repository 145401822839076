import { FC } from 'react';
import {
  DocumentTypeFieldsTableColumnSize,
} from 'components/DocumentType/Tables/DocumentTypeFieldsTable/DocumentTypeFieldsTableColumnSize';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import { TableActionCell } from 'components/Table';

interface SkeletonFieldRowProps {
  nameCellClassName?: string;
  typeCellClassName?: string;
}

const SkeletonFieldRow: FC<SkeletonFieldRowProps> = ({
  nameCellClassName,
  typeCellClassName,
}) => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={DocumentTypeFieldsTableColumnSize.FieldName} className={nameCellClassName}>
        <SkeletonRectangle color="primary6" width="80%" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={DocumentTypeFieldsTableColumnSize.FieldType} className={typeCellClassName}>
        <SkeletonRectangle color="primary6" width="80%" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonFieldRow;
