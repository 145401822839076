import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateAccountDetailsParams } from 'api/AccountDetailsApi';
import { MyAccountType } from 'MyAccount/ActionTypes';
import { MyAccountPopupType } from 'MyAccount/Types';
import { RequestError } from 'utils/fetch';
import {
  accountDetailsApi,
  passwordConfirmationApi,
  phoneCodeConfirmationApi,
  accountAvatarRestApi,
  emailCodeConfirmationApi,
} from 'stores/api';
import { ImageParams } from '../api/UserApi';

interface UploadAvatarParams {
  avatar: Blob;
  croppedAvatar: Blob;
  params: ImageParams;
}

interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

export const updateAccountDetails = createAsyncThunk(MyAccountType.UpdateUser, (params: UpdateAccountDetailsParams) => {
  return accountDetailsApi.updateAccountDetails(params);
});

export const uploadAvatar = createAsyncThunk(MyAccountType.UploadAvatar, (params: UploadAvatarParams) => {
  return accountAvatarRestApi.updateAvatar(params.avatar, params.croppedAvatar, params.params);
});

export const deleteAvatar = createAsyncThunk(MyAccountType.DeleteAvatar, () => {
  return accountAvatarRestApi.deleteAccountPhoto();
});

export const getOriginalAvatar = createAsyncThunk(MyAccountType.GetOriginalAvatar, () => {
  return accountAvatarRestApi.getOriginalAvatar();
});

export const confirmPassword = createAsyncThunk(MyAccountType.ConfirmPassword, (password: string) => {
  return passwordConfirmationApi.confirmPassword(password);
});

export const sendChangeEmailCode = createAsyncThunk(MyAccountType.SendChangeEmailCode, (email: string) => {
  return accountDetailsApi.sendChangeEmailCode(email);
});

export const verifyChangeEmailCode = createAsyncThunk(MyAccountType.VerifyChangEmailCode, (code: string) => {
  return accountDetailsApi.changeEmail(code);
});

export const sendChangePhoneNumberCode = createAsyncThunk(MyAccountType.SendChangePhoneNumberCode, (
  phone: string | undefined,
) => {
  return accountDetailsApi.requestPhoneCode(phone);
});

export const verifyChangePhoneCode = createAsyncThunk(MyAccountType.VerifyChangPhoneCode, (code: string) => {
  return accountDetailsApi.enablePhoneAuthentication(code);
});

export const verifyDeletePhoneCode = createAsyncThunk(MyAccountType.VerifyDeletePhoneCode, (code: string) => {
  return accountDetailsApi.disablePhoneAuthentication(code);
});

export const changePassword = createAsyncThunk(MyAccountType.ChangePassword, (payload: ChangePasswordPayload) => {
  return accountDetailsApi.changePassword(payload.oldPassword, payload.newPassword);
});

export const openMyAccount = createAction(MyAccountType.OpenMyAccount);
export const closeMyAccount = createAction(MyAccountType.CloseMyAccount);

export const openMyAccountPopup = createAction<MyAccountPopupType>(MyAccountType.OpenPopup);
export const closeMyAccountPopup = createAction(MyAccountType.ClosePopup);

export const sendPhoneConfirmationCode = createAsyncThunk(MyAccountType.SendPhoneVerificationCode, () => {
  return phoneCodeConfirmationApi.sendCode();
});

export const confirmPhoneCode = createAsyncThunk(MyAccountType.ConfirmPhoneVerificationCode, async (code: string) => {
  try {
    await phoneCodeConfirmationApi.confirm(code);

    return {};
  } catch (error) {
    if ((error as RequestError).responseStatus === 400) {
      return {
        invalidCode: true,
      };
    }

    throw error;
  }
});

export const sendEmailConfirmationCode = createAsyncThunk(MyAccountType.SendEmailVerificationCode, async () => {
  return emailCodeConfirmationApi.sendCode();
});

export const confirmEmailCode = createAsyncThunk(MyAccountType.ConfirmEmailVerificationCode, async (code: string) => {
  try {
    await emailCodeConfirmationApi.confirm(code);

    return {};
  } catch (error) {
    if ((error as RequestError).responseStatus === 400) {
      return {
        invalidCode: true,
      };
    }

    throw error;
  }
});
