import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { User } from 'api/UserApi';
import DocumentTypeContextualView from './DocumentTypeContextualView';
import { DocumentType } from 'api/DocumentTypeApi';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useStateReset from 'hooks/useStateReset';
import { DocumentTypeFieldsActionType } from 'DocumentTypeFields/DocumentTypeFieldsActionType';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { getDocumentType, getDocumentTypes, updateDocumentType } from 'DocumentTypes/Thunks';
import {
  batchCreateDocumentTypeFields,
  batchUpdateDocumentTypeFields,
  createDocumentTypeField,
  deleteDocumentTypeField,
  getDocumentTypeFields,
  updateDocumentTypeField,
} from 'DocumentTypeFields/Thunks';
import useBlockingRequest from 'hooks/useBlockingRequest';
import {
  CreateDocumentTypeFieldParams,
  DocumentTypeField,
  DocumentTypeFieldDataType,
  UpdateDocumentTypeFieldParams,
} from 'api/DocumentTypeFieldApi';
import DocumentTypeFieldPopup from 'components/DocumentType/Popups/DocumentTypeFieldPopup/DocumentTypeFieldPopup';
import {
  DocumentTypeFieldChildrenViewData,
  DocumentTypeFieldViewData,
} from 'components/DocumentType/Forms/DocumentTypeFieldForm/DocumentTypeFieldForm';
import { getDataTypeByViewData, isObjectViewDataType } from 'components/DocumentType/documentTypeUtils';
import useConfirmChanges from 'hooks/useConfirmChanges';
import ConfirmPopup from 'components/ConfirmPopup/ConfirmPopup';
import { isBoolean } from 'lodash';
import { documentTypesDashboardActionOrigin } from 'GeneralSettings/ui/DocumentTypesDashboard/DocumentTypesDashboardStore';
import { resetDocumentTypes } from 'DocumentTypes/DocumentTypesStore';
import { useDispatch } from 'react-redux';

interface DocumentTypeContextualViewConnectorProps {
  documentTypeId: string;
  members: User[];
  onClose: () => void;
  onEditDocumentType: (documentType: DocumentType) => void;
  onDeleteDocumentType: (documentType: DocumentType) => void;
  onDuplicateDocumentType: (documentType: DocumentType) => void;
}

const DocumentTypeContextualViewConnector: FC<DocumentTypeContextualViewConnectorProps> = ({
  documentTypeId,
  members,
  onClose,
  onEditDocumentType,
  onDeleteDocumentType,
  onDuplicateDocumentType,
}) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();

  useStateReset(DocumentTypeFieldsActionType.ResetDocumentTypeFields);

  const documentType = useSelector((state: ReduxState) => state.documentTypes.documentTypesById[documentTypeId]);
  const { filters, sortingType } = useSelector((state: ReduxState) => state.generalSettings.ui.documentTypesDashboard);

  const fetchDocumentTypes = () => {
    dispatchWithUnwrap(getDocumentTypes({
      filters,
      sortingType,
      actionOrigin: documentTypesDashboardActionOrigin,
    }))
  }

  const documentTypeFields = useSelector((state: ReduxState) => {
    return Object.values(state.documentTypeFields.fieldsById);
  });

  const [addDocumentTypeFieldInProgress, useBlockingAddDocumentTypeFieldCallback] = useBlockingRequest();
  const [displayAddDocumentTypeFieldPopup, setDisplayAddDocumentTypeFieldPopup] = useState(false);

  const [editDocumentTypeFieldInProgress, useBlockingEditDocumentTypeFieldCallback] = useBlockingRequest();
  const [documentTypeFieldToEdit, setDocumentTypeFieldToEdit] = useState<DocumentTypeField | null>(null);

  const [deleteDocumentTypeFieldInProgress, useBlockingDeleteDocumentTypeFieldCallback] = useBlockingRequest();

  const [
    displayConfirmDocumentTypeFieldDelete,
    resetConfirmDocumentTypeFieldDelete,
    onConfirmDocumentTypeFieldDelete,
    useConfirmDocumentTypeFieldDeleteCallback,
    documentTypeFieldToDelete,
  ] = useConfirmChanges<DocumentTypeField>();

  const handleUpdateParent = useCallback(
    async (formData: DocumentTypeFieldViewData) => {
      if (!documentTypeFieldToEdit) {
        return;
      }

      const dataTypeToUpdate = getDataTypeByViewData(formData);

      const updateParams: UpdateDocumentTypeFieldParams = {
        id: formData.id!,
        name: formData.name !== documentTypeFieldToEdit.name ? formData.name : undefined,
        dataType: dataTypeToUpdate.dataType !== documentTypeFieldToEdit.dataType ? dataTypeToUpdate.dataType : undefined,
        isArray: dataTypeToUpdate.isArray !== documentTypeFieldToEdit.isArray ? dataTypeToUpdate.isArray : undefined,
      };

      if (updateParams.name || updateParams.dataType || isBoolean(updateParams.isArray)) {
        await dispatchWithUnwrap(updateDocumentTypeField(updateParams));
        dispatchWithUnwrap(getDocumentTypeFields(documentTypeId));
      };
    }, [documentTypeFieldToEdit]);

  const handleCreateChildren = useCallback(
    async (childrenViewData: DocumentTypeFieldChildrenViewData[], parentId: string) => {
      const childrenToCreate = childrenViewData.filter((child) => !child.id);

      if (childrenToCreate.length > 0) {
        await dispatchWithUnwrap(batchCreateDocumentTypeFields({
          documentTypeId,
          parent: parentId,
          fields: childrenToCreate.map((child) => ({
            name: child.name,
            ...getDataTypeByViewData(child),
          })),
        }))
      }
    }, []);

  const handleUpdateChildren = useCallback(
    async (childrenViewData: DocumentTypeFieldChildrenViewData[], parentId?: string) => {
      const childrenToUpdate = childrenViewData.filter((child) => child.id);

      if (childrenToUpdate.length > 0) {
        const updateFieldsParams = childrenToUpdate.reduce((params, child) => {
          const childBeforeUpdate = documentTypeFields.find((field) => field.id === child.id);

          if (!childBeforeUpdate) {
            return params;
          }

          const dataTypeToUpdate = getDataTypeByViewData(child);

          const updateParam = {
            id: child.id!,
            name: child.name !== childBeforeUpdate.name ? child.name : undefined,
            dataType: dataTypeToUpdate.dataType !== childBeforeUpdate.dataType ? dataTypeToUpdate.dataType : undefined,
            isArray: dataTypeToUpdate.isArray !== childBeforeUpdate.isArray ? dataTypeToUpdate.isArray : undefined,
          }

          if (updateParam.name || updateParam.dataType || isBoolean(updateParam.isArray)) {
            params.push(updateParam);
          }

          return params;
        }, [] as UpdateDocumentTypeFieldParams[]);

        if (updateFieldsParams.length > 0) {
          await dispatchWithUnwrap(batchUpdateDocumentTypeFields({
            documentTypeId,
            parent: parentId,
            fields: updateFieldsParams,
          }));
        }
      }
    }, [documentTypeFields]);

  const handleDeleteChildren = useCallback(
    async (childrenViewData: DocumentTypeFieldChildrenViewData[], parentId: string) => {
      const childrenToDelete = documentTypeFields.filter((field) => (
        field.parent === parentId &&
        !childrenViewData.find((child) => child.id === field.id)
      ));

      if (childrenToDelete.length > 0) {
        await Promise.all(childrenToDelete.map((child) => dispatchWithUnwrap(deleteDocumentTypeField(child.id))));
        dispatchWithUnwrap(getDocumentTypeFields(documentTypeId));
      }
    }, [documentTypeFields]);

  const handleAddDocumentTypeField = useBlockingAddDocumentTypeFieldCallback(async (formViewData: DocumentTypeFieldViewData) => {
    const createParams: CreateDocumentTypeFieldParams = {
      documentTypeId,
      name: formViewData.name,
      ...getDataTypeByViewData(formViewData),
    }
    const parentDocumentType = await dispatchWithUnwrap(createDocumentTypeField(createParams));

    if (formViewData.children && isObjectViewDataType(formViewData.dataType!)) {
      await handleCreateChildren(formViewData.children, parentDocumentType.id);
    }

    fetchDocumentTypes();
    setDisplayAddDocumentTypeFieldPopup(false);
  });

  const handleEditDocumentTypeField = useBlockingEditDocumentTypeFieldCallback(async (formData: DocumentTypeFieldViewData) => {
    if (!documentTypeFieldToEdit) {
      return;
    }

    await handleUpdateParent(formData);

    if (formData.children && isObjectViewDataType(formData.dataType!)) {
      await Promise.all([
        handleDeleteChildren(formData.children, documentTypeFieldToEdit.id),
        handleCreateChildren(formData.children, documentTypeFieldToEdit.id),
        handleUpdateChildren(formData.children, formData.id),
      ]);
    }

    fetchDocumentTypes();
    setDocumentTypeFieldToEdit(null);
  });

  const handleDeleteDocumentTypeField = useConfirmDocumentTypeFieldDeleteCallback(
    useBlockingDeleteDocumentTypeFieldCallback(async (documentTypeField: DocumentTypeField) => {
      await dispatchWithUnwrap(deleteDocumentTypeField(documentTypeField.id));

      dispatchWithUnwrap(getDocumentTypeFields(documentTypeId));

      fetchDocumentTypes();
    }),
    (documentTypeField) => documentTypeField,
  );

  const handleUpdateDocumentType = async (newDocumentType: DocumentType) => {
    await dispatchWithUnwrap(updateDocumentType({
      id: newDocumentType.id,
      name: newDocumentType.name,
      isAutoDetectionEnabled: newDocumentType.isAutoDetectionEnabled,
    }));
  };

  const renderOverlay = () => {
    if (displayAddDocumentTypeFieldPopup) {
      return (
        <DocumentTypeFieldPopup
          title='Add Field'
          isActionOnProgress={addDocumentTypeFieldInProgress}
          usePortal
          onClose={() => setDisplayAddDocumentTypeFieldPopup(false)}
          onSubmit={handleAddDocumentTypeField}
          submitButtonTitle='Add Field'
        />
      );
    }

    if (documentTypeFieldToEdit) {
      const documentTypeFieldChildren = documentTypeFields.filter((field) => field.parent === documentTypeFieldToEdit.id);

      return (
        <DocumentTypeFieldPopup
          documentTypeField={documentTypeFieldToEdit}
          documentTypeFieldChildren={documentTypeFieldChildren}
          title='Edit Field'
          isActionOnProgress={editDocumentTypeFieldInProgress}
          usePortal
          submitButtonTitle='Save Changes'
          onClose={() => setDocumentTypeFieldToEdit(null)}
          onSubmit={handleEditDocumentTypeField}
        />
      )
    }

    if (displayConfirmDocumentTypeFieldDelete) {
      const warningText = documentTypeFieldToDelete.dataType === DocumentTypeFieldDataType.Object
        ? 'If you delete this field, all object fields will also be permanently deleted.'
        : undefined;

      return (
        <ConfirmPopup
          title='Delete Field'
          message={`Please confirm that you would like to delete your ${documentTypeFieldToDelete.name} field.`}
          confirmText='Delete Field'
          declineText='No, Go Back'
          loading={deleteDocumentTypeFieldInProgress}
          usePortal
          warningText={warningText}
          onPopupClose={resetConfirmDocumentTypeFieldDelete}
          onConfirmClick={onConfirmDocumentTypeFieldDelete}
        />
      )
    }

    return null;
  }

  const loadData = async (id: string) => {
    // This await needs only for correct notifications
    // since same validation performs in these 2 actions and 2 same notifications are shown
    try {
      await dispatchWithUnwrap(getDocumentType(id));

      dispatchWithUnwrap(getDocumentTypeFields(id));
    } catch (e) {
      dispatch(resetDocumentTypes());

      fetchDocumentTypes();
    }
  };

  useEffect(() => {
    loadData(documentTypeId);
  }, [documentTypeId]);

  const updatedBy = useMemo(() => members.find(({ id }) => id === documentType?.updatedBy), [documentType, members]);

  return (
    <>
      <DocumentTypeContextualView
        documentType={documentType}
        documentTypeFields={documentTypeFields}
        updatedBy={updatedBy}
        onClose={onClose}
        onEditDocumentType={onEditDocumentType}
        onDeleteDocumentType={onDeleteDocumentType}
        onDuplicateDocumentType={onDuplicateDocumentType}
        onAddDocumentTypeField={() => setDisplayAddDocumentTypeFieldPopup(true)}
        onEditDocumentTypeField={setDocumentTypeFieldToEdit}
        onDeleteDocumentTypeField={handleDeleteDocumentTypeField}
        onUpdateDocumentType={handleUpdateDocumentType}
      />
      {renderOverlay()}
    </>
  )
}

export default DocumentTypeContextualViewConnector;
