import { useRef, useState } from 'react';
import { noop } from 'lodash';

const useConfirmChanges = <Data = void>(shouldShowConfirmChanges: boolean = true) => {
  const [displayConfirmChanges, setDisplayConfirmChanges] = useState(false);
  const afterConfirmChangesCallbackRef = useRef(noop);
  const confirmChangesDataRef = useRef<Data>();

  const resetConfirmChanges = () => {
    setDisplayConfirmChanges(false);
    afterConfirmChangesCallbackRef.current = noop;
    confirmChangesDataRef.current = undefined;
  };

  const onConfirmUnsavedChanges = async () => {
    await afterConfirmChangesCallbackRef.current();

    resetConfirmChanges();
  };

  const useCallbackWithConfirmChanges = <Args extends unknown[], ReturnType>(
    callback: (...args: Args) => Promise<ReturnType>,
    getConfirmChangesData?: (...args: Args) => Data,
  ) => {
    return async (...args: Args) => {
      if (!shouldShowConfirmChanges) {
        await callback(...args);

        return;
      }

      if (getConfirmChangesData) {
        confirmChangesDataRef.current = getConfirmChangesData(...args);
      }

      afterConfirmChangesCallbackRef.current = () => callback(...args);

      setDisplayConfirmChanges(true);
    };
  };

  return [
    displayConfirmChanges,
    resetConfirmChanges,
    onConfirmUnsavedChanges,
    useCallbackWithConfirmChanges,
    confirmChangesDataRef.current,
  ] as [boolean, () => void, () => void, typeof useCallbackWithConfirmChanges, Data];
};

export default useConfirmChanges;
