import { FC } from 'react';
import RowActions from 'components/RowActions';
import { RowAction, RowActionsProps } from 'components/RowActions/RowActions';

interface DocumentTypeRowActionsProps extends Partial<RowActionsProps> {
  onDuplicate: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

const DocumentTypeRowActions: FC<DocumentTypeRowActionsProps> = ({
  onDuplicate,
  onDelete,
  onEdit,
  ...rowActionsProps
}) => {
  const getRowActions = (): RowAction[] => {
    return [
      {
        title: 'Edit Document Type Name',
        handler: () => onEdit(),
      },
      {
        title: 'Duplicate Document Type',
        handler: () => onDuplicate(),
        separatorRequired: true,
      },
      {
        title: 'Delete Document Type',
        handler: () => onDelete(),
        danger: true,
      },
    ];
  };

  return <RowActions actions={getRowActions()} {...rowActionsProps} />;
};

export default DocumentTypeRowActions;
