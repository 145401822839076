import { validateEmail, validateNameField, validatePassword } from 'utils/validation/validation';
import getMessage from 'constants/messages';
import { CreateAccountInformation } from '../../../api/AuthApi';

const MIN_LENGTH = 2;

export type CreateAccountDataValidationResult = Partial<CreateAccountInformation>;

export const LABELS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Work Email',
  password: 'Create Password',
};

const validateCreateAccountData = (
  data: Partial<CreateAccountInformation>,
): CreateAccountDataValidationResult => {
  const errors: CreateAccountDataValidationResult = Object.keys(data).reduce((result, key) => {
    if (data[key].length === 0) {
      return { ...result, [key]: `${LABELS[key]} is required` };
    }

    if (data[key].length < MIN_LENGTH) {
      return { ...result, [key]: `Minimum length is ${MIN_LENGTH}` };
    }

    return result;
  }, {});

  const emailError = validateEmail(data.email || '');

  if (!errors.email && emailError) {
    errors.email = emailError;
  }

  const passwordErrorMessageType = validatePassword(data.password || '');

  if (passwordErrorMessageType) {
    errors.password = getMessage(passwordErrorMessageType);
  }

  const firstNameErrorMessage = validateNameField(data.firstName || '');

  if (!errors.firstName && firstNameErrorMessage) {
    errors.firstName = firstNameErrorMessage;
  }

  const lastNameErrorMessage = validateNameField(data.lastName || '');

  if (!errors.lastName && lastNameErrorMessage) {
    errors.lastName = lastNameErrorMessage;
  }

  return errors;
};

export default validateCreateAccountData;
