import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import Table, { TableBodyContent } from 'components/Table';
import TableBody from 'components/Table/TableBody';
import {
  SkeletonDataExtractionDesktopRow,
  SkeletonDataExtractionMobileRow,
} from './DataExtractionResultRow/SkeletonDataExtractionResultRow';
import {
  DataExtractionResultDesktopRow,
  DataExtractionResultMobileRow,
} from './DataExtractionResultRow';
import Pagination from 'components/Pagination';
import useSorting from 'hooks/useSorting';
import {
  DataExtractionResult,
  DataExtractionResultSortingField,
  DataExtractionResultsSortingType,
} from '../../../../api/DataExtractionResultApi';
import { ExportFileType } from '../../../../api/DataExtractionExportApi';
import styles from './DataExtractionResultsTable.module.scss';
import {
  DataExtractionResultDesktopHeaderRow,
  DataExtractionResultMobileHeaderRow,
} from './DataExtractionResultHeaderRow';

interface DataExtractionResultTableProps {
  results: NullableItems<DataExtractionResult>;
  paginationProps: PaginationProps;
  sortingType: DataExtractionResultsSortingType;
  onSort: (field: DataExtractionResultSortingField, ascending: boolean) => void;
  onResultClick: (result: DataExtractionResult) => void;
  onExportDataExtractionResult: (resultId: string, exportFileType: ExportFileType) => void;
  getRowLink?: (result: DataExtractionResult) => string;
  downloadingResultId?: string | null;
}

const DataExtractionResultsTable = ({
  sortingType,
  results,
  paginationProps,
  onSort,
  getRowLink,
  onResultClick,
  onExportDataExtractionResult,
  downloadingResultId,
}: DataExtractionResultTableProps) => {
  const [
    changeSorting,
    getSortingType,
  ] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderRow = (result: DataExtractionResult) => (
    <>
      <DataExtractionResultDesktopRow
        className={styles.tableRowDesktop}
        result={result}
        key={`${result.id}-desktop`}
        rowLink={getRowLink && getRowLink(result)}
        onResultClick={onResultClick}
        onExportDataExtractionResult={onExportDataExtractionResult}
        downloadingResultId={downloadingResultId}
      />
      <DataExtractionResultMobileRow
        className={styles.tableRowMobile}
        result={result}
        key={`${result.id}-module`}
        onView={onResultClick}
      />
    </>
  );

  const renderSkeletonRow = (index: number) => {
    return (
      <>
        <SkeletonDataExtractionDesktopRow
          className={styles.tableRowDesktop}
          key={`${index}-desktop`}
        />
        <SkeletonDataExtractionMobileRow
          className={styles.tableRowMobile}
          key={`${index}-mobile`}
        />
      </>
    );
  };

  const renderTableHead = () => {
    return (
      <>
        <DataExtractionResultDesktopHeaderRow
          className={styles.tableRowDesktop}
          getSortingType={getSortingType}
          onSortingChange={changeSorting}
        />
        <DataExtractionResultMobileHeaderRow
          className={styles.tableRowMobile}
          getSortingType={getSortingType}
          onSortingChange={changeSorting}
        />
      </>
    );
  };

  return (
    <>
      <Table>
        {renderTableHead()}
        <TableBody>
          <TableBodyContent
            rows={results}
            renderSkeletonTableRow={renderSkeletonRow}
            renderTableRow={renderRow}
          />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </>
  );
};

export default DataExtractionResultsTable;
