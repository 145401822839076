import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import ActionPopUp from 'components/ActionPopUp';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import { CheckedImage } from 'static/images';
import styles from './ContextualPopupDropdownWrapper.module.scss';

export interface ContextualPopupDropdownWrapperProps<
  RefElement extends Element,
  Item extends { label: React.ReactNode, id: string; }
> {
  className?: string;
  title?: string;
  children: (ref: React.RefObject<RefElement>, setShowContextualPopup: (show: boolean) => void) => React.ReactNode;
  items: Array<Item>;
  selectedItemId?: string;
  onSelectItem: (item: Item) => void;
  closeOnSelect?: boolean;
}

const ContextualPopupDropdownWrapper = <
  RefElement extends Element,
  Item extends { label: React.ReactNode, id: string; }
>({
  className,
  children,
  items,
  title,
  onSelectItem,
  selectedItemId,
  closeOnSelect,
}: ContextualPopupDropdownWrapperProps<RefElement, Item>) => {
  const [showContextualPopup, setShowContextualPopup] = useState(false);
  const ref = useRef<RefElement>(null);

  const handleActionItemClick = (item: Item) => {
    if (closeOnSelect) {
      setShowContextualPopup(false);
    }

    onSelectItem(item);
  };

  return (
    <>
      {children(ref, setShowContextualPopup)}
      <ContextualPopUp
        anchorEl={ref.current}
        open={showContextualPopup}
        onClose={() => setShowContextualPopup(false)}
        classes={{ paper: clsx(styles.popupRoot, className) }}
      >
        <ActionPopUp
          title={title}
          onClose={() => setShowContextualPopup(false)}
          hideTitle={!title}
        >
          {items.map((item) => (
            <ActionPopUpItem
              className={clsx(styles.actionItem, item.id === selectedItemId && styles.selectedActionItem)}
              key={item.id}
              onClick={() => handleActionItemClick(item)}
            >
              {item.label}
              {item.id === selectedItemId && <CheckedImage />}
            </ActionPopUpItem>
          ))}
        </ActionPopUp>
      </ContextualPopUp>
    </>
  );
};

export default ContextualPopupDropdownWrapper;
