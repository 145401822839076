import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import UserCard from 'components/UserCard';
import { useAuthProvider } from 'providers/AuthProvider';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import getAvatarUrl from 'api/utils/getAvatarUrl';

const UserNotActive: FC = () => {
  const authProvider = useAuthProvider();
  const accountDetails = useSelector((state: ReduxState) => state.users.currentUser);

  const subTitle = (
    <>
      <div>Your account is not active at this time.</div>
      <div>Please contact your account administrator.</div>
    </>
  );

  return (
    <AuthLayout title="User Not Active" subTitle={subTitle}>
      <UserCard
        firstName={accountDetails?.firstName || ''}
        lastName={accountDetails?.lastName || ''}
        color={accountDetails?.color || ''}
        avatarUrl={getAvatarUrl(accountDetails?.avatarId)}
        onLogOutClick={() => authProvider.emitLogout()}
        noMargin
      />
    </AuthLayout>
  );
};

export default UserNotActive;
