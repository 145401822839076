import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import AuthLayout from 'components/Auth/AuthLayout';
import CreateAccountForm from '../../components/Auth/CreateAccountForm/CreateAccountForm';
import TrialBlock from '../../components/Auth/TrialBlock/TrialBlock';
import AuthFormFooter from '../../components/Auth/AuthFormFooter/AuthFormFooter';
import useRouteBuilder from '../../hooks/useRouteBuilder';
import { AppRoutes } from '../../routes/RouteBuilder';
import notification from '../../handlers/notification/notificationActionCreator';
import { useAuthProvider } from '../../providers/AuthProvider';
import { CreateAccountData } from '../../api/AuthApi';
import { getCurrentUser } from '../../Users/Thunks';
import useDispatchWithUnwrap from '../../hooks/useDispatchWithUnwrap';

const CreateAccount: FC = () => {
  const routeBuilder = useRouteBuilder();
  const authProvider = useAuthProvider();

  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const renderFormFooter = () => {
    return (
      <AuthFormFooter
        linkText="Sign In"
        linkTo={routeBuilder.build(AppRoutes.auth.signIn)}
        message="Already have an account?"
      />
    );
  };

  const handleSubmit = async (data: CreateAccountData) => {
    try {
      await authProvider.createAccount(data);
      await dispatchWithUnwrap(getCurrentUser());
    } catch (error) {
      notification.createNotification(error.message, 'error', dispatch);
    }
  };

  return (
    <AuthLayout
      title="Create Your Account"
      subTitle="Getting started is fast and free. No credit card is required."
      secondaryBlock={<TrialBlock type="signUp" />}
      formFooter={renderFormFooter()}
    >
      <CreateAccountForm
        onSubmit={handleSubmit}
      />
    </AuthLayout>
  );
};

export default CreateAccount;
