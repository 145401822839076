import React, { FocusEvent } from 'react';
import TextInputWrapper, { TextInputWrapperProps } from 'components/TextInputWrapper/TextInputWrapper';
import { focusInputElement, useTrimmedPhoneNumber } from './utils';
import useSamplePhoneNumber from 'components/PhoneNumberInput/useSamplePhoneNumber';
import LoaderWithState, { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import PhoneInput, { flags } from 'components/PhoneInput';
import clsx from 'clsx';
import TextInput from 'components/TextInput';
import { CountryCode } from 'libphonenumber-js';
import countryCodeStyles from 'components/CountryCodeSelect/CountryCodeSelect.module.scss';
import styles from 'components/PhoneNumberInput/PhoneNumberInput.module.scss';

interface PhoneNumberInputProps extends Omit<TextInputWrapperProps, 'children'> {
  value: string;
  country: CountryCode | null | undefined;
  onChange: (value: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  containerClassName?: string;
  withFlag?: boolean;
  inputIcon?: React.ReactNode;
  raw?: boolean;
  required?: boolean;
  autoFocus?: boolean;
}

const PhoneNumberInput = ({
  value,
  country,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  disabled,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  withFlag,
  inputIcon,
  autoFocus,
  raw = false,
  required,
  ...wrapperProps
}: PhoneNumberInputProps) => {
  const placeholder = useSamplePhoneNumber(country || null);
  const [trimmedValue, handleChange] = useTrimmedPhoneNumber(value, onChange, country);

  if (raw) {
    return (
      <TextInput
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        tabIndex={tabIndex}
        inputIcon={inputIcon}
        {...wrapperProps}
      />
    )
  }

  const renderFlag = () => {
    if (!country || !flags[country]) {
      return;
    }

    const Flag = flags[country];
    return <Flag className={countryCodeStyles.flag} />;
  };

  return (
    <TextInputWrapper required={required} {...wrapperProps} disabled={disabled}>
      <div className={clsx(styles.container, disabled && styles.container__disabled)} onClick={focusInputElement}>
        {withFlag && renderFlag()}
        <PhoneInput
          className={styles.phoneNumberInput}
          value={trimmedValue}
          placeholder={placeholder}
          country={country}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
        />
        {showLoader && <LoaderWithState state={loaderState} onStateReset={onLoaderStateReset} />}
        {inputIcon && <div className={styles.inputIconContainer}>{inputIcon}</div>}
      </div>
    </TextInputWrapper>
  );
};

export default PhoneNumberInput;
