import { createReducer } from '@reduxjs/toolkit';
import { changeFilters, getPerformanceOverview, getPerformanceOverviewDocumentTypes } from './ActionCreator';
import { DashboardState } from './Types';
import TimeRange from 'enums/TimeRange';
import { mapData } from '../api/PerformanceOverviewMapper';

const initialState: DashboardState = {
  documentTypes: null,
  performanceOverview: null,
  filters: {
    documentTypes: TimeRange.Last7Days,
    performanceOverview: TimeRange.Last7Days,
  },
}

const dashboardReducer = createReducer(initialState, builder => {
  builder.addCase(getPerformanceOverviewDocumentTypes.fulfilled, (state, action) => {
    state.documentTypes = action.payload;
  });

  builder.addCase(changeFilters, (state, action) => {
    state.filters = action.payload;
  });

  builder.addCase(getPerformanceOverview.fulfilled, (
    state,
    action,
  ) => {
    const information = action.payload.performanceOverview;
    const dateRange = action.payload.dateRange;

    state.performanceOverview = mapData(
      information.totalPagesProcessed,
      information.groups,
      dateRange,
      'pagesProcessed',
      information.organizationCreatedAt,
    );
  });
});

export default dashboardReducer;
