import { FC, useEffect, useRef, useState } from 'react'
import { DocumentTypeField } from 'api/DocumentTypeFieldApi';
import DataToExtractSectionHeader from './DataToExtractSectionHeader/DataToExtractSectionHeader';
import DocumentTypeFieldsTable from 'components/DocumentType/Tables/DocumentTypeFieldsTable/DocumentTypeFieldsTable';
import JsonView from 'components/JsonView';
import { documentTypeApi } from 'stores/api';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';

import styles from './DataToExtractSection.module.scss';

interface DataToExtractSectionProps {
  documentTypeId?: string;
  documentTypeFields: DocumentTypeField[];
  onAddDocumentTypeField: () => void;
  onEditDocumentTypeField: (documentTypeField: DocumentTypeField) => void;
  onDeleteDocumentTypeField: (documentTypeField: DocumentTypeField) => void;
}

const DataToExtractSection: FC<DataToExtractSectionProps> = ({
  documentTypeId,
  documentTypeFields,
  onAddDocumentTypeField,
  onEditDocumentTypeField,
  onDeleteDocumentTypeField,
}) => {
  const [showJsonPreview, setShowJsonPreview] = useState(false);
  const [jsonData, setJsonData] = useState<Object>({});
  const [isJsonPreviewFetchInProgress, handleJsonPreviewFetch] = useAsyncActionCallback(
    async (id: string) => {
      const previewData = await documentTypeApi.preview(id);

      setJsonData(previewData);
    }, []);

  const documentTypeFieldsLengthRef = useRef<number>(documentTypeFields.length);

  if (documentTypeFieldsLengthRef.current !== documentTypeFields.length) {
    documentTypeFieldsLengthRef.current = documentTypeFields.length;
  }

  const handleShowJsonSwitchChange = (checked: boolean) => {
    setShowJsonPreview(checked);
  }

  useEffect(() => {
    if (showJsonPreview && documentTypeId) {
      handleJsonPreviewFetch(documentTypeId);
    }
  }, [showJsonPreview, documentTypeFieldsLengthRef.current]);

  const renderContent = () => {
    if (showJsonPreview) {
      return (
        <JsonView className={styles.jsonView} data={jsonData} isLoading={isJsonPreviewFetchInProgress} showCopyButton />
      );
    }

    return (
      <DocumentTypeFieldsTable
        documentTypeFields={documentTypeFields}
        onEditField={onEditDocumentTypeField}
        onDeleteField={onDeleteDocumentTypeField}
      />
    );
  }

  return (
    <div>
      <DataToExtractSectionHeader
        documentTypeId={documentTypeId}
        isChecked={showJsonPreview}
        onAddField={onAddDocumentTypeField}
        onShowJsonSwitchChange={handleShowJsonSwitchChange}
      />
      {renderContent()}
    </div>
  )
}

export default DataToExtractSection
