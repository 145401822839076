import clsx from 'clsx';
import MainLayout, { MainLayoutProps } from 'components/MainLayout/MainLayout';
import React, { FC, useEffect } from 'react';
import MyAccount from 'pages/MyAccount';
import { closeMyAccount } from 'MyAccount/ActionCreator';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import styles from './MainLayoutWrapper.module.scss';

const MainLayoutWrapper: FC<MainLayoutProps> = ({
  contextualView,
  closeContextualView,
  overwriteMobilePageTitle,
  ...restProps
}) => {
  const myAccount = useSelector((state: ReduxState) => state.myAccount);
  const dispatch = useDispatch();
  const routeBuilder = useRouteBuilder();

  const renderContextualView = () => {
    if (myAccount.isOpen) {
      return (
        <MyAccount />
      );
    }

    return null
  };

  const handleCloseContextualView = () => {
    if (myAccount.isOpen) {
      dispatch(closeMyAccount());

      return;
    }

    closeContextualView?.();
  };

  const primaryContextualView = renderContextualView();

  const renderContextualViewWrapper = () => {
    if (primaryContextualView) {
      // To not reset state of the opened contextualViews we just hide them via css and keep in DOM
      return (
        <>
          {primaryContextualView}
          <div className={clsx(primaryContextualView && styles.hidden)}>{contextualView}</div>
        </>
      );
    }

    if (contextualView) {
      return contextualView;
    }

    return null;
  };

  useEffect(() => {
    dispatch(closeMyAccount());
  }, [routeBuilder.currentPathname]);

  return (
    <MainLayout
      {...restProps}
      contextualView={renderContextualViewWrapper()}
      closeContextualView={handleCloseContextualView}
      overwriteMobilePageTitle={myAccount.isOpen ? 'My Account' : overwriteMobilePageTitle}
    />
  );
};

export default MainLayoutWrapper;
