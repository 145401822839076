import { TableRow, TableActionCell, TableBodyCell } from 'components/Table';
import { SkeletonRectangle } from 'components/Skeleton';
import { WebhookEndpointsColumnSize } from 'components/WebhooksOverview/Tables/EndpointsTable/TableColumnSize';

const SkeletonWebhookEndpointItemRow = () => (
  <TableRow disableHover useActions>
    <TableBodyCell width={WebhookEndpointsColumnSize.Url}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={WebhookEndpointsColumnSize.Description}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={WebhookEndpointsColumnSize.ListeningFor}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={WebhookEndpointsColumnSize.DocumentType}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={WebhookEndpointsColumnSize.Status}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={WebhookEndpointsColumnSize.UpdatedAt}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableActionCell />
  </TableRow>
);

export default SkeletonWebhookEndpointItemRow;
