import { createReducer } from '@reduxjs/toolkit';
import {
  closeMyAccount,
  closeMyAccountPopup,
  deleteAvatar,
  getOriginalAvatar,
  openMyAccount,
  openMyAccountPopup,
  uploadAvatar,
} from 'MyAccount/ActionCreator';
import { MyAccountState } from 'MyAccount/Types';

const initialState: MyAccountState = {
  isOpen: false,
  popup: null,
};

const myAccountReducer = createReducer(initialState, (builder) => {
  builder.addCase(getOriginalAvatar.fulfilled, (state, action) => {
    state.avatar = action.payload;
  });

  builder.addCase(getOriginalAvatar.rejected, (state) => {
    state.avatar = null;
  });

  builder.addCase(openMyAccount, (state) => {
    state.isOpen = true;
  });

  builder.addCase(closeMyAccount, (state) => {
    state.isOpen = false;
  });

  builder.addCase(openMyAccountPopup, (state, action) => {
    state.popup = action.payload;
  });

  builder.addCase(closeMyAccountPopup, (state) => {
    state.popup = null;
  });

  builder.addCase(uploadAvatar.fulfilled, (state) => {
    state.avatar = undefined;
  });

  builder.addCase(deleteAvatar.fulfilled, (state) => {
    state.avatar = undefined;
  });
});

export default myAccountReducer;
