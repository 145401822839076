import { createAsyncThunk } from '@reduxjs/toolkit';
import { billingApi } from 'stores/api';
import { BillingInfo, Invoice, InvoicesSortingField } from '../api/BillingApi';
import BillingPageActionType from './ActionTypes';
import { DataViewSortingType } from '../api/Types';

export const getBillingInfo = createAsyncThunk<
  BillingInfo
>(
  BillingPageActionType.GetBillingInfo,
  async () => {
    return await billingApi.getBillingInfo();
  },
);

export const getInvoices = createAsyncThunk<
  Invoice[],
  { sortingType: DataViewSortingType<InvoicesSortingField> }
>(
  BillingPageActionType.GetInvoices,
  async ({
    sortingType,
  }) => {
    return await billingApi.getInvoices({ sortingType });
  },
);

