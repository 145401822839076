import { DataFilter, DataViewSortingType, TableViewData } from 'api/Types';
import { PermissionGroup as PermissionGroupType } from 'enums/PermissionGroup';
import BaseSystemApi from './BaseApi';

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ImageParams {
  originalFileName: string;
  cropRect: Rect;
  imageRect: Rect;
  canvasWidth: number;
  canvasHeight: number;
}

export enum UsersSortingField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PermissionGroup = 'permissionGroup',
  PhoneAuthentication = 'isMfaEnabled',
  AccountStatus = 'status',
}

export enum UserStatus {
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
}

export interface UpdateUserPrams {
  status?: UserStatus;
}

export interface UsersDataFilter extends DataFilter {
  permissionGroup?: PermissionGroupType;
  isMfaEnabled?: boolean;
  isEmailVerified?: boolean;
  statuses?: UserStatus[];
}

export interface BasicUser {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  color: string;
  avatarId?: string;
}

export enum UserAuthProvider {
  Google = 'google',
  Synthetiq = 'synthetiq',
}

export interface User extends BasicUser {
  phone?: string | null;
  isMfaEnabled?: boolean;
  isEmailVerified?: boolean;
  isInviteAccepted?: boolean;
  status: UserStatus;
  color: string;
  organizationId: string;
  avatarParams?: ImageParams & { id: string };
  permissionGroup: PermissionGroupType;
  password?: string;
  isOwner?: boolean;
  lastActiveAt?: Date;
  authProvider: UserAuthProvider;
}

export interface CurrentUser extends User {
  hasSuperAdminPermissions?: boolean;
}

export type UsersSortingType = DataViewSortingType<UsersSortingField>;

export interface UserApi {
  find(filters?: UsersDataFilter, sortingType?: UsersSortingType): Promise<TableViewData<User>>;
  getCurrentUser(): Promise<CurrentUser>;
  getUserById(membershipId: string): Promise<User>;
  update(userId: string, params: UpdateUserPrams): Promise<User>;
}

export default class UserRestApi extends BaseSystemApi<User> implements UserApi {
  protected resourceName = 'users';

  public async find(filters?: UsersDataFilter, sortingType?: UsersSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters?.isMfaEnabled !== undefined) {
      params.append('isMfaEnabled', filters.isMfaEnabled.toString());
    }

    if (filters?.permissionGroup !== undefined) {
      params.append('permissionGroup', filters.permissionGroup);
    }

    if (filters?.statuses) {
      filters.statuses.forEach((status) => {
        params.append('statuses', status);
      });
    }

    return this.getResources<TableViewData<User>>(params);
  }

  public getCurrentUser() {
    return this.fetch<User>(`/${this.resourceName}/current`);
  }

  public getUserById(userId: string) {
    return this.getResourceById<User>(userId);
  }

  public async update(userId: string, params: UpdateUserPrams) {
    return this.updateResource(userId, params);
  }
}
