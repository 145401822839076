import MainLayoutWrapper from './Wrappers/MainLayoutWrapper';
import PageWrapper from './PageWrapper';
import PageContent from './PageContent';
import PageFooter from './PageFooter';
import PageWrapperWithFooter from './PageWrapperWithFooter';

import provideLayout from 'layout';

export default provideLayout(MainLayoutWrapper);

export { PageWrapper, PageContent, PageFooter, PageWrapperWithFooter };
