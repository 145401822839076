import { batch, useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import useUnmountAction from './useUnmountAction';
import { simpleAction } from 'utils/actions/SimpleAction';

const useStateReset = (...actions: Array<string | (() => AnyAction)>) => {
  const dispatch = useDispatch();

  useUnmountAction(() => batch(() => {
    actions.forEach((action) => {
      dispatch(typeof action === 'function' ? action() : simpleAction(action));
    });
  }));
};

export default useStateReset;
