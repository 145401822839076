import React, { forwardRef } from 'react';
import UserAvatar from 'components/UserAvatar';
import { ReactComponent as DotsIcon } from 'components/LeftNav/LeftNavigationHeader/Icons/dots-icon.svg';
import OverflowedText from 'components/OverflowedText';

import styles from './UserCard.module.scss';
import clsx from 'clsx';

type UserCardTypes = {
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  companyName: string;
  color?: string;
  onClick?: () => void;
  isActive: boolean;
  className?: string;
};

const UserCard = forwardRef<HTMLDivElement, UserCardTypes>(({
  firstName,
  lastName,
  avatarUrl,
  companyName,
  color,
  onClick,
  isActive,
  className,
}, ref) => {
  const userInfo = { firstName, lastName, avatarUrl };

  return (
    <div
      className={clsx(isActive ? styles.activeUserCardContainer : styles.userCardContainer, className)}
      ref={ref}
      onClick={onClick}
    >
      <div className={styles.imgContainer}>
        <UserAvatar
          size="medium"
          firstName={userInfo.firstName}
          lastName={userInfo.lastName}
          color={color}
          imageUrl={userInfo.avatarUrl}
        />
        {isActive && <div className={styles.activeCircle}> </div>}
      </div>
      <div className={styles.userInfo}>
        <OverflowedText
          className={isActive ? styles.activeUserName : styles.userName}
          useTooltip
        >
          {`${firstName} ${lastName}`}
        </OverflowedText>
        <OverflowedText useTooltip className={styles.companyName}>{companyName}</OverflowedText>
      </div>
      <DotsIcon className={styles.dotsIcon} />
    </div>
  );
});

export default UserCard;
