import { FC } from 'react';
import { DocumentTypeField } from 'api/DocumentTypeFieldApi';
import { TableActionCell, TableBodyCell, TableRow } from 'components/Table';
import OverflowedText from 'components/OverflowedText';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import {
  DocumentTypeFieldsTableColumnSize,
} from 'components/DocumentType/Tables/DocumentTypeFieldsTable/DocumentTypeFieldsTableColumnSize';
import { getViewDataType } from 'components/DocumentType/documentTypeUtils';

interface DocumentTypeFieldsTableRowProps {
  field: DocumentTypeField;
  disableHover?: boolean;
  nameCellClassName?: string;
  arrowIcon?: JSX.Element;
  useActions?: boolean;
  disableOnClick?: boolean;
  onEditField?: (field: DocumentTypeField) => void;
  onDeleteField?: (field: DocumentTypeField) => void;
  typeCellClassName?: string;
}

const DocumentTypeFieldsTableRow: FC<DocumentTypeFieldsTableRowProps> = ({
  field,
  disableHover,
  nameCellClassName,
  arrowIcon,
  disableOnClick = false,
  useActions = true,
  typeCellClassName,
  onEditField,
  onDeleteField,
}) => {
  const handleEditField = () => {
    if (!onEditField) {
      return;
    };

    onEditField(field);
  };

  const handleDeleteField = () => {
    if (!onDeleteField) {
      return;
    };

    onDeleteField(field);
  };

  const handleRowClick = () => {
    if (!onEditField || disableOnClick) {
      return;
    };

    onEditField(field);
  };

  const actions: RowAction[] = [
    {
      title: 'Edit Field',
      handler: handleEditField,
    },
    {
      title: 'Delete Field',
      danger: true,
      separatorBeforeRequired: true,
      handler: handleDeleteField,
    },
  ];

  return (
    <TableRow useActions={useActions} key={field.id} disableHover={disableHover} onClick={handleRowClick}>
      <TableBodyCell
        width={DocumentTypeFieldsTableColumnSize.FieldName}
        className={nameCellClassName}
      >
        <OverflowedText>{field.name}</OverflowedText>
        {arrowIcon}
      </TableBodyCell>
      <TableBodyCell
        width={DocumentTypeFieldsTableColumnSize.FieldType}
        className={typeCellClassName}
      >
        {getViewDataType(field)}
      </TableBodyCell>
      {useActions
        ? (
          <RowActionsContainer>
            <RowActions actions={actions} id={field.id} />
          </RowActionsContainer>
        )
        : <TableActionCell />}
    </TableRow>
  )
}

export default DocumentTypeFieldsTableRow;
