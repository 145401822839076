import InvoiceStatus from 'BillingPage/InvoiceStatus';
import { DataViewSortingType } from './Types';
import BaseApi from './BaseApi';

export interface BillingInfo {
  displayPrice: string;
  reportDate: Date;
  trialEndDate: Date | null;
  amountOwed: number | null;
  currency: string | null;
  pricePerPageCents: number;
  freeCreditsRemainingCents: number | null;
}

export interface Invoice {
  invoiceDate: Date;
  dueDate: Date;
  description: string;
  invoiceNumber: string;
  amount: number;
  status: InvoiceStatus;
  linkToPdf: string | null;
  linkToDetails: string | null;
  currency: string | null;
}

export enum InvoicesSortingField {
  InvoiceDate = 'invoiceDate',
  Description = 'description',
  InvoiceNumber = 'invoiceNumber',
  Amount = 'amount',
  Status = 'status',
}

export interface BillingApi {
  getBillingInfo(): Promise<BillingInfo>;
  getInvoices(params: { sortingType: DataViewSortingType<InvoicesSortingField> }): Promise<Invoice[]>;
}

export default class BillingRestApi extends BaseApi implements BillingApi {
  public getBillingInfo() {
    return this.fetch<BillingInfo>('/plan-and-billing');
  }

  public async getInvoices({
    sortingType,
  }: { sortingType: DataViewSortingType<InvoicesSortingField> }): Promise<Invoice[]> {
    const params = new URLSearchParams();

    params.set('sortField', sortingType.field);
    params.set('sortDirection', sortingType.ascending ? 'asc' : 'desc');

    return this.fetch<Invoice[]>(`/plan-and-billing/invoices?${params}`);
  }
}
