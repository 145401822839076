import { FC } from 'react';
import { ReactComponent as CheckedIcon } from './checked.svg';

import styles from './TrialBlock.module.scss';

interface TrialBlockProps {
  type: 'acceptInvite' | 'signUp';
}

const ROWS = [
  'Get $150 in free Synthetiq credits when you sign up',
  'Bulletproof security backed by our SOC 2 Type 2 certification',
  'Full product and feature access for every account, with no price gating',
  'Team-wide collaboration in a single organization with logical user roles',
];

const TrialBlock: FC<TrialBlockProps> = ({
  type,
}) => {
  const renderRow = (text: string) => {
    return (
      <div className={styles.row} key={text}>
        <CheckedIcon className={styles.rowIcon} />
        <div className={styles.rowText}>
          {text}
        </div>
      </div>
    );
  };

  const rows = type === 'acceptInvite' ? ROWS.slice(1) : ROWS;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {type === 'acceptInvite' && 'Join your team'}
        {type === 'signUp' && 'Start your trial'}
        {' '}
        <div className={styles.highlighted}>today</div>
      </div>
      <div className={styles.rowsContainer}>
        {rows.map(renderRow)}
      </div>
    </div>
  );
};

export default TrialBlock;
