import React, { FC } from 'react';
import ExternalLink from '../ExternalLink/ExternalLink';
import { env } from '../../env';
import { AppRoutes } from '../../routes/RouteBuilder';
import { ShareImageCustomColor } from '../../static/images';
import clsx from 'clsx';
import styles from './BillingPortalLink.module.scss';

interface BillingPortalLinkProps {
  className?: string;
}

const BillingPortalLink: FC<BillingPortalLinkProps> = ({ className }) => {
  return (
    <ExternalLink
      href={`${env.REACT_APP_API_URL}${AppRoutes.customerPortal}`}
      className={clsx(styles.paymentPortalLink, className)}
    >
      <ShareImageCustomColor className={styles.paymentPortalIcon} />
      <p className={styles.paymentPortalText}>Payment Portal</p>
    </ExternalLink>
  );
};

export default BillingPortalLink;
