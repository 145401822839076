import BaseSystemApi from './BaseApi';

export interface PhoneCodeConfirmationApi {
  sendCode(): Promise<void>;
  confirm(code: string): Promise<void>;
}

export default class PhoneCodeConfirmationRestApi extends BaseSystemApi implements PhoneCodeConfirmationApi {
  protected resourceName = '/phone-code-confirmations';

  public async sendCode() {
    await this.fetch(this.resourceName, 'POST');
  }

  public async confirm(code: string) {
    await this.fetch(`${this.resourceName}/${code}`, 'POST');
  }
}
