import { UserInfoHeader } from 'eventHandlers/AuthEventEmitter';
import base64ToObject from 'utils/base64ToObject';

const USER_HEADER = 'x-synthetic-user';

const getUserInfo = (response: Response): UserInfoHeader | null => {
  const userHeaderValue = response.headers.get(USER_HEADER);

  if (userHeaderValue) {
    const user = base64ToObject<UserInfoHeader>(userHeaderValue);

    return user;
  }

  return null;
};

export default getUserInfo;
