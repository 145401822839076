import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import DashboardActionType from './ActionType';
import { performanceOverviewApi } from 'stores/api';
import { DashboardFiltersState } from './Types';
import TimeRange from 'enums/TimeRange';
import getDateRange from 'utils/getDateRange';

export const getPerformanceOverviewDocumentTypes = createAsyncThunk(DashboardActionType.GetDocumentTypes,
  async (timeRange: TimeRange) => {
    const dateRange = getDateRange(timeRange);
    const documentTypes = await performanceOverviewApi.getDocumentTypes(dateRange);

    return documentTypes;
  },
);

export const changeFilters = createAction<DashboardFiltersState>(DashboardActionType.ChangeFilters);

export const getPerformanceOverview = createAsyncThunk(DashboardActionType.GetPerformanceOverview,
  async (timeRange: TimeRange) => {
    const dateRange = getDateRange(timeRange);
    const performanceOverview = await performanceOverviewApi.getPerformanceOverview(dateRange);

    return {
      dateRange,
      performanceOverview,
    };
  },
);
