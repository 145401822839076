import { FC, useMemo } from 'react'
import TableLayout from 'components/Table/TableLayout/TableLayout';
import HeaderWithTooltip from '../../../Base/HeaderWithTooltip';
import {
  DataExtraction,
  DataExtractionSortingField,
  DataExtractionSortingType,
} from 'api/DataExtractionApi';
import { NullableItems, PaginationProps } from 'pagination';
import {
  ExtractionHistoryDashboardFiltersState,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/Filters/ExtractionHistoryDashboardFiltersStore';
import { User } from 'api/UserApi';
import { DataExtractionsTable } from 'components/ExtractionHistory/Tables';
import { NoItems } from 'static/images';
import styles from './ExtractionHistoryDashboard.module.scss';
import { ExportFileType } from '../../../../api/DataExtractionExportApi';

interface ExtractionHistoryDashboardProps {
  dataExtractions: NullableItems<DataExtraction>;
  paginationProps: PaginationProps;
  filters: ExtractionHistoryDashboardFiltersState;
  sortingType: DataExtractionSortingType;
  members: User[];
  onSearch: (search: string) => void;
  onFiltersButtonClick: () => void;
  onClearFilters: () => void;
  onSort: (field: DataExtractionSortingField, ascending: boolean) => void;
  onChangeSelectedMembers: (members: User[]) => void;
  onViewDataExtraction: (dataExtraction: DataExtraction) => void;
  onCreateDataExtraction: () => void;
  onDeleteDataExtraction: (dataExtractionId: string) => void;
  onExportDataExtraction: (dataExtractionId: string, exportFileType: ExportFileType) => void;
}

const ExtractionHistoryDashboard: FC<ExtractionHistoryDashboardProps> = ({
  dataExtractions,
  paginationProps,
  filters,
  members,
  sortingType,
  onSearch,
  onFiltersButtonClick,
  onClearFilters,
  onChangeSelectedMembers,
  onSort,
  onViewDataExtraction,
  onCreateDataExtraction,
  onDeleteDataExtraction,
  onExportDataExtraction,
}) => {
  const renderDashboardHeader = () => {
    return (
      <div className={styles.dashboardHeader}>
        <HeaderWithTooltip
          type="h2"
        >
          Extraction History
        </HeaderWithTooltip>
      </div>
    );
  }

  const renderTable = () => {
    return (
      <DataExtractionsTable
        dataExtractions={dataExtractions}
        sortingType={sortingType}
        paginationProps={paginationProps}
        search={filters.search}
        members={members}
        onSort={onSort}
        onViewDataExtraction={onViewDataExtraction}
        onDeleteDataExtraction={onDeleteDataExtraction}
        onExportDataExtraction={onExportDataExtraction}
      />
    )
  }

  const areFiltersApplied = useMemo(() => {
    return (
      filters.createdAtRange.from !== null ||
      filters.createdAtRange.to !== null ||
      filters.documentTypeId !== null ||
      filters.fileType !== null ||
      filters.status !== null ||
      filters.teamMembers.length > 0
    );
  }, [
    filters.createdAtRange.from,
    filters.createdAtRange.to,
    filters.documentTypeId,
    filters.fileType,
    filters.status,
  ]);

  const selectedUsers = useMemo(() => {
    return members.filter(({ id }) => filters.teamMembers.includes(id));
  }, [filters.teamMembers]);

  return (
    <TableLayout
      renderHeader={renderDashboardHeader}
      items={dataExtractions}
      areItemsLoaded={paginationProps.itemsTotal !== undefined}
      areFiltersApplied={areFiltersApplied}
      search={filters.search}
      noItemsTitle="No documents have been analyzed yet!"
      renderTable={renderTable}
      onClearFilters={onClearFilters}
      onFiltersButtonClick={onFiltersButtonClick}
      onSearch={onSearch}
      availableUsersForFilter={members}
      onChangeSelectedUsers={onChangeSelectedMembers}
      selectedUsers={selectedUsers}
      noItemsContainerClassName={styles.noItemsContainer}
      noItemsIcon={<NoItems />}
      noItemsButtonMessage='Analyze Document'
      onNoItemsButtonClick={onCreateDataExtraction}
    />
  )
}

export default ExtractionHistoryDashboard;
