import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import UserCard from 'components/UserCard';
import { useAuthProvider } from 'providers/AuthProvider';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import getAvatarUrl from 'api/utils/getAvatarUrl';
import AuthExternalSourceButton from 'components/Auth/AuthExternalSourceButton';
import { ExternalRoutes } from '../../routes/RouteBuilder';

import styles from './SubscriptionCalncelled.module.scss';

const SubscriptionCancelled: FC = () => {
  const authProvider = useAuthProvider();
  const accountDetails = useSelector((state: ReduxState) => state.users.currentUser);

  const title = <>Your Subscription <br />Is Cancelled</>;
  const subTitle = <div>Please contact us to restart your subscription.</div>;

  return (
    <AuthLayout title={title} subTitle={subTitle}>
      <div>
        <AuthExternalSourceButton externalLink={ExternalRoutes.ContactSales}>
          Contact Sales
        </AuthExternalSourceButton>
        <UserCard
          className={styles.userCard}
          firstName={accountDetails?.firstName || ''}
          lastName={accountDetails?.lastName || ''}
          color={accountDetails?.color || ''}
          avatarUrl={getAvatarUrl(accountDetails?.avatarId)}
          onLogOutClick={() => authProvider.emitLogout()}
          noMargin
        />
      </div>
    </AuthLayout>
  );
};

export default SubscriptionCancelled;
