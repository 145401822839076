import { UpdateSessionInfoAction } from './ActionCreator';
import { SessionInfoActionType } from './ActionTypes';
import SessionInfoState from './Types';

const initialState: SessionInfoState = {
  organization: null,
  user: null,
};

type SessionInfoActions = UpdateSessionInfoAction;

const sessionInfoReducer = (state = initialState, action: SessionInfoActions): SessionInfoState => {
  switch (action.type) {
    case SessionInfoActionType.UpdateSessionInfo: {
      const { sessionInfo } = action.payload;

      return {
        ...state,
        ...sessionInfo,
      };
    }
    default:
      return state;
  }
};

export default sessionInfoReducer;
