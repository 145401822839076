import { FC } from 'react';
import ButtonWithImage from 'components/ButtonWithImage';
import StyledSwitch from 'components/StyledSwitch';
import clsx from 'clsx';
import styles from './DataToExtractSectionHeader.module.scss';

interface DataToExtractSectionHeaderProps {
  documentTypeId?: string;
  isChecked: boolean;
  onAddField: () => void;
  onShowJsonSwitchChange: (checked: boolean) => void;
}

const DataToExtractSectionHeader: FC<DataToExtractSectionHeaderProps> = ({
  documentTypeId,
  isChecked,
  onAddField,
  onShowJsonSwitchChange,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h4 className={styles.title}>Data to Extract</h4>
        <ButtonWithImage
          title='Add Field'
          kind='add'
          className={styles.addFieldButton}
          onClick={onAddField}
          disabled={!documentTypeId}
        />
      </div>
      <div className={styles.showJsonSwitchContainer}>
        <StyledSwitch
          title='Show JSON'
          onChange={(event) => onShowJsonSwitchChange(event.target.checked)}
          disabled={!documentTypeId}
        />
        <p className={clsx(styles.showJsonTitle, isChecked && styles.active)}>Show JSON</p>
      </div>
    </div>
  )
}

export default DataToExtractSectionHeader;
