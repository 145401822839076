import { User } from 'api/UserApi';
import { RequestError } from 'utils/fetch';
import { DigifiErrorCode } from 'enums/DigifiErrorCode';
import BaseSystemApi from './BaseApi';

export interface AcceptInviteData {
  firstName: string;
  lastName: string;
  password: string;
}

export interface InviteUserParams {
  permissionGroup: string;
  email: string;
}

export interface UserInviteApi {
  sendInvite(params: InviteUserParams): Promise<User | null>;
  cancelInvitation(userId: string): Promise<User>;
  validateToken(token: string): Promise<boolean>;
  acceptInvite(token: string, data: AcceptInviteData): Promise<void>;
  resendInvite(userId: string): Promise<void>;
}

export default class UserInviteRestApi extends BaseSystemApi implements UserInviteApi {
  protected resourceName = 'user-invites';

  public async sendInvite(params: InviteUserParams) {
    try {
      return await this.fetch<User>('/users', 'POST',  {
        email: params.email,
        permissionGroup: params.permissionGroup,
      });
    } catch (error) {
      if (error instanceof RequestError && error.code === DigifiErrorCode.NoSeatsAvailable) {
        return null;
      }

      throw error;
    }
  }

  public cancelInvitation(userId: string) {
    return this.deleteResource<User>(userId);
  }

  public async validateToken(token: string) {
    const { isValid } = await this.fetch<{
      isValid: boolean,
    }>(`/user-invite-accept/${token}/validate`, 'POST');

    return isValid;
  }

  public async acceptInvite(token: string, data: AcceptInviteData): Promise<void> {
    await this.fetch<{
      isValid: boolean,
    }>(`/user-invite-accept`, 'POST', {
      ...data,
      token,
    });
  }

  public async resendInvite(userId: string): Promise<void> {
    await this.fetch(`/user-invites/${userId}`, 'POST');
  }
}
