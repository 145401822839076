import { createSelector } from 'reselect';
import { ReduxState } from 'types/ReduxState';
import { NullableItems } from 'pagination';

export const getUserById = (state: ReduxState) => state.users.userById;

export const createGetUsersSelector = (userIds: NullableItems<string>) => {
  return createSelector([getUserById], (userById) => {
    return userIds.map((id) => id ? userById[id] : null);
  });
};
