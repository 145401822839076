import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  WebhookEvent,
  WebhookEventDataFilter,
  WebhookEventSortingType,
} from 'api/WebhookEventsApi';
import { TableViewData } from 'api/Types';
import EventsActionType from './EventsActionType';
import { webhookEventsApi } from 'stores/api';
import { ActionOriginData, DataWithActionOrigin } from 'types/action-origin';

export enum WebhookEventsActionOrigin {
  WebhookEventsDashboardTable = 'defaultEventsDashboard',
  WebhookEndpointEventsTable = 'endpointEventsTable',
}

export const getWebhookEvents = createAsyncThunk<
  TableViewData<WebhookEvent>,
  DataWithActionOrigin<{ filters: WebhookEventDataFilter, sortingType: WebhookEventSortingType }>,
  { fulfilledMeta: DataWithActionOrigin<{ search?: string; }> }
>(
  EventsActionType.GetWebhookEvents,
  async ({ filters, sortingType, actionOrigin }, thunkAPI) => {
    return thunkAPI.fulfillWithValue(await webhookEventsApi.find(filters, sortingType), {
      search: filters.search,
      actionOrigin,
    });
  },
);

export const retryWebhookEvent = createAsyncThunk<{ webhookEventId: string; }, string>(
  EventsActionType.RetryWebhookEvent,
  async (id) => {
    await webhookEventsApi.retry(id);

    return {
      webhookEventId: id,
    };
  },
);

export const deleteWebhookEvent = createAsyncThunk<
  WebhookEvent,
  DataWithActionOrigin<{ webhookEventId: string }>,
  { fulfilledMeta: ActionOriginData }
>(
  EventsActionType.DeleteWebhookEvent,
  async ({ webhookEventId, actionOrigin }, thunkAPI) => {
    return thunkAPI.fulfillWithValue(await webhookEventsApi.delete(webhookEventId), {
      actionOrigin,
    });
  },
);
