import { AnyAction } from 'redux';
import { SessionInfo } from './Types';
import { SessionInfoActionType } from './ActionTypes';

export interface UpdateSessionInfoAction extends AnyAction {
  type: SessionInfoActionType.UpdateSessionInfo;
  payload: {
    sessionInfo: Partial<SessionInfo>;
  };
}

export const updateSessionInfo = (sessionInfo: Partial<SessionInfo>): UpdateSessionInfoAction => ({
  type: SessionInfoActionType.UpdateSessionInfo,
  payload: {
    sessionInfo,
  },
});
