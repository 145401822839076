import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE } from 'components/ExtractionHistory/DataExtractionConstants';
import UploadMultipleFiles from 'components/UploadFile/UploadMultipleFiles';
import { FC, useEffect, useRef, useState } from 'react';
import { UploadFileIcon } from 'static/images';
import { CreateDataExtractionFormParams, DOCUMENT_TYPE_TOOLTIP, renderFileRequirements } from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/CreateDataExtractionForm';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import { CreateBatchDataExtractionParams, DataExtractionMode, IClassificationResult } from 'api/DataExtractionApi';
import { IDocumentTokenInfo } from 'api/DocumentTokenApi';
import ExtractionFormMode from 'enums/ExtractionFormMode';
import clsx from 'clsx';
import AdvancedSettings from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/AdvancedSettings';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import baseStyles from './CreateDataExtractionForm.module.scss';
import { v4 as uuid } from 'uuid';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import notification from 'handlers/notification/notificationActionCreator';
import BatchFiles from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/BatchFiles';
import { IFileState, validateFileState, validateMany } from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/validateFileState';
import QueueActions from 'utils/QueueActions';
import { MAX_FILES_BEFORE_OVERFLOW } from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/BatchFiles/BatchFiles';
import LinkButton from 'components/LinkButton';
import FilesDetailsPopup from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/FilesDetailsPopup';
import useFreeTrialStatus, { FreeTrialStatus } from 'hooks/useFreeTrialStatus';

interface CreateBatchDataExtractionFormProps {
  params: CreateDataExtractionFormParams;
  detectedDocumentTypes: IClassificationResult[] | null;
  isLoading: boolean;
  fileTokenInfo: IDocumentTokenInfo | null;
  mode: ExtractionFormMode;
  onChange: (params: Partial<CreateDataExtractionFormParams>) => void;
  onFetchDocumentTypeOptions: (search: string) => Promise<Option[]>;
  onValidateFile: (file: File) => Promise<IDocumentTokenInfo | undefined>;
  onCreateDataExtraction: (params: CreateBatchDataExtractionParams) => Promise<void>;
}

const MAX_ALLOWED_FILES_PER_BATCH = 50;
const MAX_ALLOWED_FILES_PER_BATCH_IN_FREE_TRIAL = 10;

const CreateBatchDataExtractionForm: FC<CreateBatchDataExtractionFormProps> = ({
  params,
  isLoading,
  onValidateFile,
  onChange,
  onFetchDocumentTypeOptions,
  onCreateDataExtraction,
}) => {
  const freeTrialStatus = useFreeTrialStatus();
  const [filesState, setFilesState] = useState<Record<string, IFileState>>({});
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState<boolean>(false);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [filesDetailsPopupOpen, setFilesDetailsPopupOpen] = useState<boolean>(false);

  const maxAllowedFilesCount = freeTrialStatus === FreeTrialStatus.INACTIVE
    ? MAX_ALLOWED_FILES_PER_BATCH_IN_FREE_TRIAL
    : MAX_ALLOWED_FILES_PER_BATCH;

  const processActions = async (files: IFileState[]) => {
    const validatedFiles = await validateMany(files, onValidateFile);

    const mappedFiles: IFileState[] = validatedFiles.map(({ id, error, validationInfo }) => {
      const file = files.find((fileToValidate) => fileToValidate.id === id)!;

      return {
        ...file,
        validationInfo,
        error,
      };
    });

    return mappedFiles;
  };

  const processCommit = (files: IFileState[]) => {
    const normalizedFiles = normalizeEntityArray<IFileState>(files);

    setFilesState((filesStateCurrent) => ({
      ...filesStateCurrent,
      ...normalizedFiles,
    }));
  };

  const processError = (files: IFileState[]) => {
    const mappedFiles: IFileState[] = files.map(({ id }) => {
      const file = files.find((fileToValidate) => fileToValidate.id === id)!;

      return {
        ...file,
        validationInfo: null,
        error: 'Internal error',
      };
    });

    const normalizedMappedFiles = normalizeEntityArray<IFileState>(mappedFiles);

    setFilesState((filesStateCurrent) => ({
      ...filesStateCurrent,
      ...normalizedMappedFiles,
    }));
  };

  const queueActions = useRef(new QueueActions<IFileState>({
    onError: processError,
    onProcess: processActions,
    onCommit: processCommit,
    parallelActions: 3,
    throttlingPerParallelActions: 8000, // approximately 22 requests per minute
  }));

  const uploadedFilesCount = Object.keys(filesState).length;
  const maxFilesCountExceeded = maxAllowedFilesCount === uploadedFilesCount;

  const showMaxFilesCountExceededNotification = () => {
    const message = freeTrialStatus === FreeTrialStatus.INACTIVE
      ? `The maximum limit of ${MAX_ALLOWED_FILES_PER_BATCH_IN_FREE_TRIAL} documents for trial subscription has been reached.`
      : `The maximum limit of ${MAX_ALLOWED_FILES_PER_BATCH} documents has been reached.`;

    notification.createNotification(message, 'warning', dispatchWithUnwrap);
  };

  const handleFileChange = (filesToUpload: File[]) => {
    const filesTotalCount = filesToUpload.length + uploadedFilesCount

    if (filesTotalCount >= maxAllowedFilesCount) {
      showMaxFilesCountExceededNotification();
    }

    if (filesTotalCount > maxAllowedFilesCount) {
      return;
    }

    const filesWithState: IFileState[] = filesToUpload.map((file) => {
      return {
        id: uuid(),
        file,
      };
    });

    queueActions.current.add(...filesWithState);

    setFilesState((fileStateCurrent) => ({
      ...fileStateCurrent,
      ...normalizeEntityArray(filesWithState),
    }));
  };

  const handleDocumentTypeChange = (documentTypeId: string) => {
    onChange({
      documentTypeId,
      useForAutoDetect: !documentTypeId ? false : params.useForAutoDetect,
    });
  };

  const handleModeChange = (mode: DataExtractionMode) => {
    onChange({
      mode,
    });
  };

  const handleUseForAutoDetectSwitchChange = (useForAutoDetect: boolean) => {
    onChange({
      useForAutoDetect,
    });
  };

  const handleDeleteFile = (id: string) => {
    const copy = { ...filesState };

    delete copy[id];

    setFilesState(copy);
  };

  const isSubmitDisabled = () => {
    const noFilesAdded = Object.keys(filesState).length === 0;

    const allFilesAreValid = Object.values(filesState)
      .every((fileState) => !validateFileState(fileState, params.mode) && !fileState.error && fileState.validationInfo);

    return !params.documentTypeId || noFilesAdded || isLoading || !allFilesAreValid;
  };

  const handleSubmit = async () => {
    if (isSubmitDisabled()) {
      return;
    }

    return onCreateDataExtraction({
      documentContentIds: Object.keys(filesState).map((id) => filesState[id].validationInfo!.documentContentId),
      documentTypeId: params.documentTypeId!,
      mode: params.mode,
      useForAutoDetect: params.useForAutoDetect,
    });
  };

  useEffect(() => {
    return () => {
      // clearing the queue on onMount to avoid unnecessary requests
      queueActions.current.destroy();
    }
  }, []);

  return (
    <div className={baseStyles.container}>
      <UploadMultipleFiles
        maxFileSize={MAX_FILE_SIZE}
        accept={ALLOWED_FILE_TYPES}
        files={[]}
        disabled={maxFilesCountExceeded}
        onFilesChange={handleFileChange}
        className={clsx(baseStyles.file, params.file && baseStyles.hidden)}
        inputWrapperClassName={baseStyles.inputWrapper}
        uploadIcon={<UploadFileIcon/>}
        requirementsText={renderFileRequirements()}
      />
      {uploadedFilesCount > 0 && (
        <BatchFiles
          filesState={filesState}
          mode={params.mode}
          onDelete={handleDeleteFile}
        />
      )}
      {uploadedFilesCount > MAX_FILES_BEFORE_OVERFLOW && (
        <LinkButton
          className={baseStyles.showAllFiles} onClick={() => setFilesDetailsPopupOpen(true)}>
            Show All Files ({uploadedFilesCount})
          </LinkButton>
      )}
      <AutoCompletion
        placeholder={'Required'}
        labelTitle='Document Type'
        titleHint={DOCUMENT_TYPE_TOOLTIP}
        value={params.documentTypeId || undefined}
        onChange={({ value }) => handleDocumentTypeChange(value)}
        fetchOptions={onFetchDocumentTypeOptions}
        loading={(Boolean(params.documentTypeId) && !params.documentTypeId) || isLoading}
        className={baseStyles.autoCompletionContainer}
        selectControlClassName={baseStyles.inputClassName}
        disabled={isLoading}
        required
        loadingIconClassName={baseStyles.documentTypeLoaderIcon}
      />
      <AdvancedSettings
        onModeChange={handleModeChange}
        onUseForAutoDetectChange={handleUseForAutoDetectSwitchChange}
        params={params}
        isOpen={isAdvancedSettingsOpen}
        onToggle={setIsAdvancedSettingsOpen}
      />
      <ButtonWithLoadingState
        kind='primary'
        size='form'
        disabled={isSubmitDisabled()}
        onClick={handleSubmit}
        className={baseStyles.submitButton}
      >
        Extract Data
      </ButtonWithLoadingState>
      {filesDetailsPopupOpen && (
        <FilesDetailsPopup
          mode={params.mode}
          filesState={filesState}
          onClose={() => setFilesDetailsPopupOpen(false)}
          onDelete={handleDeleteFile}
        />
      )}
    </div>
  );
};

export default CreateBatchDataExtractionForm;
