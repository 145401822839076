import React, { FC, useRef, useState } from 'react';
import ActionPopUp from 'components/ActionPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import Button from 'components/Button';
import { CheckedImage } from 'static/images';
import { ButtonType } from 'components/Button/Button';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';

import styles from './DropdownButton.module.scss';

export interface DropdownButtonOption {
  id: string;
  text: React.ReactNode;
  tooltip?: string;
  shouldShowTooltip?: boolean;
  disabled?: boolean;
  className?: string;
}

interface DropdownButtonProps {
  options: DropdownButtonOption[];
  onSelect: (option: DropdownButtonOption) => void;
  selectedOption: DropdownButtonOption | null;
  className?: string;
  buttonClassName?: string;
  kind?: ButtonType;
  popupClassName?: string;
  tooltip?: string;
  disabled?: boolean;
}

const DropdownButton: FC<DropdownButtonProps> = ({
  selectedOption,
  children,
  options,
  className,
  buttonClassName,
  onSelect,
  kind = 'with-state',
  popupClassName,
  tooltip,
  disabled,
}) => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const onItemClick = (option: DropdownButtonOption) => {
    if (option.disabled) {
      return;
    }

    setOpen(false);
    onSelect(option);
  };

  return (
    <div className={clsx(className, {[styles.disabled]: disabled})} ref={containerRef}>
      <WrapperWithTooltip tooltip={tooltip}>
        <div>
          <Button
            kind={kind}
            type="button"
            onClick={() => setOpen(true)}
            className={clsx(styles.button, buttonClassName)}
            active={open}
            disabled={disabled}
          >
            {children}
          </Button>
        </div>
      </WrapperWithTooltip>
      <ContextualPopUp
        anchorEl={containerRef.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        anchorPosition={{ top: 2, left: 0 }}
        classes={{ paper: styles.popupRoot }}
      >
        <ActionPopUp className={popupClassName} onClose={() => setOpen(false)} hideTitle>
          {options.map((option) => {
            const optionEl = (
              <ActionPopUpItem
                className={clsx(
                  option.className, styles.actionItem,
                  { [styles.selectedActionItem]: option.id === selectedOption?.id },
                )}
                key={option.id}
                onClick={() => onItemClick(option)}
              >
                {option.text}
                {option.id === selectedOption?.id && <CheckedImage className={styles.checkIcon} />}
              </ActionPopUpItem>
            );

            return option.shouldShowTooltip ? (
              <WrapperWithTooltip tooltip={option.tooltip}>
                <div className={styles.optionWrapper}>{optionEl}</div>
              </WrapperWithTooltip>
            ) : (
              optionEl
            );
          })}
        </ActionPopUp>
      </ContextualPopUp>
    </div>
  );
};

export default DropdownButton;
