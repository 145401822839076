import React, { FC, useState, ReactNode, ReactElement } from 'react';
import accepts from 'attr-accept';
import BaseUploadFile from 'components/UploadFile/BaseUploadFile';

const getMbFromBytes = (bytes: number) => bytes / 1024 / 1024;

export interface UploadFromFileProps {
  file: File | null;
  onFileChange: (file: File | null) => void;
  accept?: string | string[];
  maxFileSize?: number;
  text?: ReactNode;
  closeIcon?: React.ReactNode;
  noMargin?: boolean;
  inputLabel?: string;
  className?: string;
  inputWrapperClassName?: string;
  uploadIcon?: React.ReactNode;
  requirementsText?: string | ReactElement;
}

const UploadFile: FC<UploadFromFileProps> = ({
  file,
  onFileChange,
  accept,
  maxFileSize,
  text,
  closeIcon,
  noMargin,
  inputLabel,
  className,
  inputWrapperClassName,
  uploadIcon,
  requirementsText,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const maxFileSizeInMb = maxFileSize && getMbFromBytes(maxFileSize);

  const validateFileSize = (fileSize: number): boolean => {
    if (typeof maxFileSize === 'undefined') {
      return true;
    }

    return fileSize <= maxFileSize;
  };

  const validateFile = (newFile: File): boolean => {
    const isAcceptable = !accept || accepts(newFile, accept);

    if (!isAcceptable) {
      setErrorMessage('File format is not supported.');

      return false;
    }

    if (!validateFileSize(newFile.size)) {
      setErrorMessage(`Maximum file size exceeded. Please upload a file that is less than ${maxFileSizeInMb}MB`);

      return false;
    }

    if (errorMessage) {
      setErrorMessage('');
    }

    return true;
  };

  const handleFilesChange = ([newFile]: File[]) => {
    if (!newFile) {
      return onFileChange(null);
    }

    if (validateFile(newFile)) {
      onFileChange(newFile);
    }
  };

  return (
    <BaseUploadFile
      files={file ? [file] : []}
      maxFiles={1}
      onFilesChange={handleFilesChange}
      accept={accept}
      text={text}
      closeIcon={closeIcon}
      className={className}
      errorMessage=''
      inputLabel={inputLabel}
      inputWrapperClassName={inputWrapperClassName}
      noMargin={noMargin}
      requirementsText={requirementsText}
      uploadIcon={uploadIcon}
    />
  );
};

export default UploadFile;
