import React, { FC, useCallback, useState } from 'react'
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import UploadFile from 'components/UploadFile';
import { DataExtractionMode, IClassificationResult } from 'api/DataExtractionApi';
import {
  ALLOWED_FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_IN_MB,
  MAX_PAGES_COUNT,
} from '../../DataExtractionConstants';
import { UploadFileIcon } from 'static/images';
import styles from './CreateDataExtractionForm.module.scss';
import clsx from 'clsx';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import OtherDocumentTypes from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/OtherDocumentTypes/OtherDocumentTypes';
import AdvancedSettings from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/AdvancedSettings/AdvancedSettings';
import CreateDataExtractionFilePreview from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/CreateDataExtractionFilePreview/CreateDataExtractionFilePreview';
import { IDocumentTokenInfo } from 'api/DocumentTokenApi';
import ExtractionFormMode from 'enums/ExtractionFormMode';
import { validateDocumentTokenInfo } from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/validateFileState';

export interface CreateDataExtractionFormParams {
  file: File | null;
  mode: DataExtractionMode;
  documentTypeId: string | null;
  useForAutoDetect: boolean;
}

interface CreateDataExtractionFormProps {
  onFetchDocumentTypeOptions: (search: string) => Promise<Option[]>;
  onCreateDataExtraction: (params: CreateDataExtractionFormParams) => Promise<void>;
  params: CreateDataExtractionFormParams;
  onChange: (params: Partial<CreateDataExtractionFormParams>) => void;
  detectedDocumentTypes: IClassificationResult[] | null;
  isLoading: boolean;
  fileTokenInfo: IDocumentTokenInfo | null;
  mode: ExtractionFormMode;
}

export const renderFileRequirements = () => (
  <>
    We accept: {ALLOWED_FILE_TYPES.join(', ')} <br />
    {MAX_FILE_SIZE_IN_MB}MB max, {MAX_PAGES_COUNT} pages max
  </>
);

export const DOCUMENT_TYPE_TOOLTIP = (
  <>
    A template with predefined fields to be<br/>
    extracted during the data extraction process.
  </>
);

const CreateDataExtractionForm: FC<CreateDataExtractionFormProps> = ({
  params,
  isLoading,
  detectedDocumentTypes,
  fileTokenInfo,
  onFetchDocumentTypeOptions,
  onCreateDataExtraction,
  onChange,
}) => {
  const resizeContainerRef = React.useRef<HTMLDivElement | null>(null);
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState<boolean>(false);

  const handleFileChange = useCallback((fileToSet?: File | null) => {
    onChange({
      file: fileToSet || null,
    });
  }, [onChange]);

  const handleSubmit = async () => {
    if (!params.file) {
      return;
    }

    await onCreateDataExtraction(params);
  };

  const handleDocumentTypeChange = (documentTypeId: string | null) => {
    onChange({
      documentTypeId: documentTypeId,
      useForAutoDetect: !documentTypeId ? false : params.useForAutoDetect,
    });
  };

  const renderPreview = () => {
    if (!params.file) {
      return null;
    }

    return (
      <CreateDataExtractionFilePreview
        file={params.file}
        isLoading={isLoading}
        onClose={handleFileChange}
        resizeContainerRef={resizeContainerRef}
      />
    );
  };

  const handleModeChange = (mode: DataExtractionMode) => {
    onChange({
      mode,
    });
  };

  const handleUseForAutoDetectSwitchChange = (checked: boolean) => {
    onChange({
      useForAutoDetect: checked,
    });
  };

  const fileTokenLimitExceededError = fileTokenInfo && validateDocumentTokenInfo(fileTokenInfo, params.mode);

  const isSubmitDisabled = !params.file || isLoading || !!fileTokenLimitExceededError;

  return (
    <div className={styles.container} ref={resizeContainerRef}>
      {!params.file && (
        <UploadFile
          maxFileSize={MAX_FILE_SIZE}
          accept={ALLOWED_FILE_TYPES}
          file={params.file}
          onFileChange={handleFileChange}
          className={clsx(styles.file, params.file && styles.hidden)}
          inputWrapperClassName={styles.inputWrapper}
          uploadIcon={<UploadFileIcon />}
          requirementsText={renderFileRequirements()}
        />
      )}
      {renderPreview()}
      {!isLoading && fileTokenLimitExceededError && (
        <p className={styles.errorMessage}>
          {fileTokenLimitExceededError}
        </p>
      )}
      <AutoCompletion
        placeholder={'Optional'}
        labelTitle='Document Type'
        titleHint={DOCUMENT_TYPE_TOOLTIP}
        value={params.documentTypeId || undefined}
        onChange={({ value }) => handleDocumentTypeChange(value)}
        fetchOptions={onFetchDocumentTypeOptions}
        loading={(Boolean(params.documentTypeId) && !params.documentTypeId) || isLoading}
        className={styles.autoCompletionContainer}
        selectControlClassName={styles.inputClassName}
        disabled={isLoading}
        loadingIconClassName={styles.documentTypeLoaderIcon}
      />
      <OtherDocumentTypes
        detectedDocumentTypes={detectedDocumentTypes}
        selectedDocumentTypeId={params.documentTypeId}
        onDocumentTypeChange={handleDocumentTypeChange}
      />
      <AdvancedSettings
        onModeChange={handleModeChange}
        onUseForAutoDetectChange={handleUseForAutoDetectSwitchChange}
        params={params}
        isOpen={isAdvancedSettingsOpen}
        onToggle={setIsAdvancedSettingsOpen}
      />
      <ButtonWithLoadingState
        kind='primary'
        size='form'
        disabled={isSubmitDisabled}
        onClick={handleSubmit}
        className={styles.submitButton}
      >
        Extract Data
      </ButtonWithLoadingState>
    </div>
  );
}

export default CreateDataExtractionForm;
