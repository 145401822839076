import BaseSystemApi from 'api/BaseApi';
import { DataExtractionMode } from 'api/DataExtractionApi';
import AppHeader from 'enums/AppHeader';

export interface IDocumentTokenInfo {
  tokens: number;
  limits: Record<DataExtractionMode, number>;
  documentContentId: string;
}

export interface IGetTokenInfoParams {
  documentContentId?: string;
  file: File;
  captchaToken: string;
}

export interface IDocumentTokenApi {
  getTokenInfo(params: IGetTokenInfoParams): Promise<IDocumentTokenInfo>;
}

export default class DocumentTokenApi extends BaseSystemApi implements IDocumentTokenApi {
  protected resourceName = 'document-tokens';

  public async getTokenInfo(params: IGetTokenInfoParams): Promise<IDocumentTokenInfo> {
    const formData = new FormData();

    formData.append('file', params.file);

    if (params.documentContentId) {
      formData.append('documentContentId', params.documentContentId);
    }

    return this.createResource<IDocumentTokenInfo>(formData, {
      contentType: null,
      headers: {
        [AppHeader.CaptchaToken]: params.captchaToken,
      },
    });
  }
}
