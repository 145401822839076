import { round } from 'lodash';

const SECONDS_IN_MINUTE = 60;
const ONE_SECOND_IN_MS = 1000;

const getFormattedDurationTime = (totalDurationTime?: number) => {
  if (!totalDurationTime) {
    return null;
  }

  const durationTimeInMs = round(totalDurationTime);

  const durationTimeInSeconds = round(durationTimeInMs / ONE_SECOND_IN_MS);

  if (durationTimeInSeconds > SECONDS_IN_MINUTE) {
    const durationTimeInMinutes = Math.floor(durationTimeInSeconds / SECONDS_IN_MINUTE);

    const restSeconds = round(durationTimeInSeconds % SECONDS_IN_MINUTE);

    return `${round(durationTimeInMinutes)}m ${restSeconds}s`;
  }

  return `${durationTimeInSeconds}s`;
};

export default getFormattedDurationTime;
