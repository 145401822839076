import React, { FC } from 'react';
import { WebhookEvent, WebhookEventStatus } from 'api/WebhookEventsApi';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import RowActions from 'components/RowActions';
import SearchHighlight from 'components/SearchHighlight';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import { Tag } from 'components/Tag';
import {
  WebhookEventColumnSize,
  WebhookEventWithEndpointColumnSize,
} from 'components/WebhooksOverview/Tables/EventsTable/TableColumnSize';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import DateTimeUtils from 'utils/dateUtils';

interface EventItemRowProps {
  webhookEvent: WebhookEvent;
  searchInputValue: string;
  onClick: (id: string) => void;
  onDelete: (id: string) => void;
  onResend: (id: string) => void;
  withEndpointColumn?: boolean;
}

const EventItemRow: FC<EventItemRowProps> = ({
  webhookEvent,
  searchInputValue,
  onClick,
  onDelete,
  onResend,
  withEndpointColumn,
}) => {
  const renderCellContent = (content: React.ReactNode) => (
    <OverflowedText>
      <SearchHighlight search={searchInputValue}>{content || <TableBodyCellNoContent />}</SearchHighlight>
    </OverflowedText>
  );

  const renderStatusField = () => {
    if (webhookEvent.status === WebhookEventStatus.Succeeded) {
      return <Tag color="green">Succeeded</Tag>
    }

    return <Tag color="red">Failed</Tag>
  };

  const renderNextRunAtCellContent = () => {
    if (!webhookEvent.nextRunAt) {
      return null;
    }

    const nextRunDateTime = DateTimeUtils.parse(webhookEvent.nextRunAt).toDate().getTime();
    const currentDateTime = Date.now();

    if (nextRunDateTime < currentDateTime) {
      return null;
    }

    return formatDate(webhookEvent.nextRunAt, DateTimeFormat.Long)
  };

  renderNextRunAtCellContent();

  const ColumnSize = withEndpointColumn ? WebhookEventWithEndpointColumnSize : WebhookEventColumnSize;

  return (
    <TableRow useActions key={webhookEvent.id} onClick={() => onClick(webhookEvent.id)}>
      {withEndpointColumn && (
        <TableBodyCell width={WebhookEventWithEndpointColumnSize.Endpoint}>
          {renderCellContent(webhookEvent.webhook.url)}
        </TableBodyCell>
      )}
      <TableBodyCell width={ColumnSize.DocumentType}>
        {renderCellContent(webhookEvent.event.data.documentTypeName)}
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.EventType}>
        {renderCellContent(webhookEvent.event.type)}
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.EventDate}>
        {renderCellContent(formatDate(webhookEvent.createdAt, DateTimeFormat.Long))}
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.Status}>
        {renderStatusField()}
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.LastRunAt}>
        {renderCellContent(formatDate(webhookEvent.lastRunAt, DateTimeFormat.Long))}
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.NextRunAt} noPadding>
        {renderCellContent(renderNextRunAtCellContent())}
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Resend Event',
            handler: () => onResend(webhookEvent.id),
            danger: false,
            separatorRequired: true,
            disabled: webhookEvent.status === WebhookEventStatus.Succeeded,
            tooltip: webhookEvent.status === WebhookEventStatus.Succeeded
              ? 'This event was already sent successfully'
              : '',
          },
          {
            title: 'Delete Event',
            handler: () => onDelete(webhookEvent.id),
            danger: true,
          },
        ]}
      />
    </TableRow>
  );
};

export default EventItemRow;
