import React, { ReactElement, FocusEvent } from 'react';

import PhoneInput, {
  formatPhoneNumber,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input/input';
import flags from 'react-phone-number-input/flags';
import { HTMLInputProps } from 'components/TextInput/TextInput';

type StandardInputPropsWithoutOnChange = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
>;

export interface PhoneInputProps extends StandardInputPropsWithoutOnChange {
  value?: string;
  placeholder?: string;
  country: string | null | undefined;
  onChange: (value: string | undefined) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  inputComponent?: React.ElementType;
  inputProps?: HTMLInputProps;
  smartCaret?: boolean;
}

const PhoneInputTyped = PhoneInput as (props: PhoneInputProps) => ReactElement;
const formatPhoneNumberTyped = formatPhoneNumber as (phoneNumber: string) => string;
const getCountriesTyped = getCountries as () => string[];
const getCountryCallingCodeTyped = getCountryCallingCode as (countryCode: string) => string;
const isValidPhoneNumberTyped = isValidPhoneNumber as (phoneNumber: string) => boolean;
const flagsTyped = flags as ReactElement[];

export default PhoneInputTyped;

export {
  getCountriesTyped as getCountries,
  getCountryCallingCodeTyped as getCountryCallingCode,
  flagsTyped as flags,
  formatPhoneNumberTyped as formatPhoneNumber,
  isValidPhoneNumberTyped as isValidPhoneNumber,
};
