import React, { forwardRef } from 'react';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';

import styles from './Avatar.module.scss';

type PreSetTextSize = 'small' | 'medium' | 'tiny';

export type TextSize = number | PreSetTextSize;

interface AvatarProps {
  size?: TextSize | number;
  tooltip?: string;
  children?: React.ReactElement;
  className?: string;
  color?: string;
  hideColor?: boolean;
  onClick?: () => void;
}

const getComponentClassName = (size: TextSize | number): string | undefined => {
  if (typeof size === 'number') {
    return undefined;
  }

  switch (size) {
    case 'tiny': {
      return styles.tinyAvatar;
    }
    case 'small': {
      return styles.smallAvatar;
    }
    case 'medium': {
      return styles.mediumAvatar;
    }
    default: {
      return undefined;
    }
  }
};

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ size = 'medium', tooltip = '', children, className, color, hideColor, onClick }, ref) => {
    const fontSize = typeof size === 'number' ? size : undefined;

    return (
      <WrapperWithTooltip className={styles.tooltip} tooltip={tooltip}>
        <div className={clsx(styles.avatarContainer, className)} onClick={onClick} ref={ref}>
          <div
            className={clsx(styles.avatar, getComponentClassName(size))}
            style={{ fontSize, backgroundColor: hideColor ? 'transparent' : color }}
          >
            {children}
          </div>
        </div>
      </WrapperWithTooltip>
    );
  },
);

export default Avatar;
