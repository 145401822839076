import React, { ReactNode } from 'react';
import QuestionIcon from 'components/QuestionIcon';
import clsx from 'clsx';
import styles from './PopUpHeader.module.scss';

interface PopUpHeaderProps {
  title: ReactNode;
  children?: ReactNode;
  titleTooltip?: ReactNode;
  titleIcons?: ReactNode;
  titleClassName?: string;
}

const PopUpHeader = ({ title, titleTooltip, titleIcons, titleClassName, children }: PopUpHeaderProps) => {
  return (
    <div className={`${styles.popupHeader}`}>
      <div className={clsx(styles.popupTitle, titleClassName)}>
        <h3>{title}</h3>
        {titleIcons && <div className={styles.titleIconsContainer}>
          {titleIcons}
        </div>}
        {titleTooltip && <QuestionIcon className={styles.questionIcon} size={24} tooltip={titleTooltip} />}
      </div>
      {children}
    </div>
  );
};

export default PopUpHeader;
