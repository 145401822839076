import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonDocumentTypesListItem.module.scss';

const SkeletonDocumentTypesListItem = () => (
  <div className={styles.container}>
    <div>
      <div className={styles.header}>
        <SkeletonRectangle width="230px" height="16px" color="primary20" marginRight="10px" />
        <SkeletonRectangle width="51px" height="16px" color="primary6" className={styles.subheader} />
      </div>
      <div className={styles.footer}>
        <SkeletonCircle className={styles.avatar} width="20px" height="20px" color="primary6" />
        <SkeletonRectangle width="180px" height="16px" color="primary6" />
      </div>
    </div>
  </div>
);

export default SkeletonDocumentTypesListItem;
