import { ReactElement, ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import styles from './HomeDashboardCardLayout.module.scss';
import HomeDashboardChartNoData from 'components/HomeDashboard/HomeDashboardCardLayout/HomeDashboardChartNoData/HomeDashboardChartNoData';

interface HomeDashboardCardLayoutProps {
  className?: string;
  title: string;
  headerClassName?: string;
  children: ReactNode;
  tag?: string;
  showEmptyState?: boolean;
  bodyClassName?: string;
  secondaryTitle?: ReactElement;
  titleControl?: ReactElement;
  useMobileLayout?: boolean;
}

const HomeDashboardCardLayout = forwardRef<HTMLDivElement, HomeDashboardCardLayoutProps>(({
  title,
  children,
  className,
  headerClassName,
  tag,
  showEmptyState,
  bodyClassName,
  secondaryTitle,
  useMobileLayout = false,
  titleControl,
}, ref) => {
  return (
    <div className={clsx(styles.card, useMobileLayout && styles.useMobileLayout, className)}>
      <div className={clsx(styles.head, headerClassName, tag && styles.noMargin)}>
        <div className={styles.headContainer}>
          <div className={styles.title}>{title}</div>
          {titleControl}
          {secondaryTitle && <div className={styles.secondaryTitle}>{secondaryTitle}</div>}
        </div>
        {tag && <div className={styles.tag}>{tag}</div>}
      </div>
      <div className={clsx(styles.body, bodyClassName)} ref={ref}>
        {!showEmptyState && children}
        {showEmptyState && <HomeDashboardChartNoData />}
      </div>
    </div>
  );
});

export default HomeDashboardCardLayout;
