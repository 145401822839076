import { createReducer } from '@reduxjs/toolkit';
import { getPublicApiKeys, getPublicApiWhitelists } from 'PublicApi/ActionCreator';
import { PublicApiState } from 'PublicApi/Types';

const initialState: PublicApiState = {
  keys: undefined,
  whitelists: undefined,
}

const publicApiReducer = createReducer(initialState, (builder) => {
  builder.addCase(getPublicApiKeys.fulfilled, (state, action) => {
    state.keys = action.payload;
  });

  builder.addCase(getPublicApiWhitelists.fulfilled, (state, action) => {
    state.whitelists = action.payload;
  });
});

export default publicApiReducer;
