import React, { FC } from 'react';
import ButtonWithImage from 'components/ButtonWithImage';
import { OrganizationShortInfo } from 'api/OrganizationApi';
import Table, { TableRow, TableBodyCell, TableBody } from 'components/Table';
import CompanyInformationSkeleton from './CompanyInformationSkeleton';
import CompanyInformationColumnSize from './CompanyInformationColumnSize';
import styles from './CompanyInformation.module.scss';

interface CompanyInformationProps {
  info: OrganizationShortInfo | null;
  onCompanyInfoEdit: (companyInfo: OrganizationShortInfo) => void;
}

const CompanyInformation: FC<CompanyInformationProps> = ({
  info,
  onCompanyInfoEdit,
}) => {
  return info ? (
    <>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Company Information</div>

        <ButtonWithImage
          className={styles.editButton}
          onClick={() => onCompanyInfoEdit(info)}
          title="Edit Company Information"
          kind="edit"
        />
      </div>

      <Table>
        <TableBody>
          <TableRow disableHover>
            <TableBodyCell width={CompanyInformationColumnSize.Name} className={styles.tableRowLabel}>
              Company Name
            </TableBodyCell>
            <TableBodyCell width={CompanyInformationColumnSize.Value} className={styles.tableRowValue}>
              {info.name}
            </TableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  ) : (
    <CompanyInformationSkeleton />
  );
};

export default CompanyInformation;
