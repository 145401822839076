import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './TopRightButton.module.scss';

type TopRightLinkProps = {
  children: ReactNode;
  path: string;
};

const TopRightLink: FC<TopRightLinkProps> = ({ children, path }) => {
  return (
    <NavLink to={path} className={styles.navLink}>
      {children}
    </NavLink>
  );
};

export default TopRightLink;
