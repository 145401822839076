import React, { FC, ChangeEvent, useState } from 'react';
import { InviteUserParams } from 'api/UserInviteApi';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { validateEmail } from 'utils/validation/validation';
import { Option } from 'components/SelectInput/SelectInput';
import useInputValidation from 'hooks/useInputValidation';
import AutoCompletion from '../../../AutoCompletion';

export interface InviteUserFormProps {
  permissionGroups: Option[];
  onSubmit: (params: InviteUserParams) => void;
  isInviteSendingInProgress?: boolean;
}

const InviteUserForm: FC<InviteUserFormProps> = ({
  permissionGroups,
  onSubmit,
  isInviteSendingInProgress,
}) => {
  const [email, setEmail] = useState('');
  const [permissionGroup, setPermissionGroup] = useState('');
  const [
    onEmailFieldBlur,
    onEmailFieldFocus,
    emailFieldError,
    isEmailFieldValid,
  ] = useInputValidation({
    required: true,
    value: email,
    validators: [validateEmail],
  })

  const handleEmailInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePermissionGroupInputValueChange = (option: Option) => {
    setPermissionGroup(option.value);
  };

  const handleClickSubmitButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    onSubmit({ email, permissionGroup });
  };

  const isSubmitButtonDisabled = !isEmailFieldValid || !permissionGroup;

  return (
    <div>
      <TextInput
        type="text"
        labelTitle="Email"
        onChange={handleEmailInputValueChange}
        errorMessage={emailFieldError}
        value={email}
        onBlur={onEmailFieldBlur}
        onFocus={onEmailFieldFocus}
        required
      />
      <AutoCompletion
        required
        labelTitle="Permissions Group"
        onChange={handlePermissionGroupInputValueChange}
        value={permissionGroup}
        options={permissionGroups}
        hideClearIcon
      />
      <Button
        kind="primary"
        size="form"
        offset="form"
        onClick={handleClickSubmitButton}
        disabled={isSubmitButtonDisabled}
        isLoading={isInviteSendingInProgress}
      >
        Add User
      </Button>
    </div>
  );
};

export default InviteUserForm;
