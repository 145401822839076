import React, { FC, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { EmptyTableImage } from 'static/images';
import { WebhookEvent, WebhookEventSortingField, WebhookEventSortingType } from 'api/WebhookEventsApi';
import { EventsTable } from 'components/WebhooksOverview/Tables';
import QuestionIcon from 'components/QuestionIcon';
import SearchInput from 'components/SearchInput';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import { useDispatch, useSelector } from 'react-redux';
import useStateReset from 'hooks/useStateReset';
import { getWebhookEvents } from 'Webhooks/Events/Thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createGetEventsSelector } from 'Webhooks/Events/Selectors';
import { ReduxState } from 'types/ReduxState';
import {
  WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
} from 'GeneralSettings/ui/WebhooksDashboards/EndpointDetails/EndpointEventsTable/Pagination';
import
EndpointEventsTableActionType
  from 'GeneralSettings/ui/WebhooksDashboards/EndpointDetails/EndpointEventsTable/EndpointEventsTableActionType';
import {
  changeSearchValue,
  changeSortingType,
  endpointEventsTableActionOrigin,
  pagination,
} from 'GeneralSettings/ui/WebhooksDashboards/EndpointDetails/EndpointEventsTable/EndpointEventsTableStore';
import styles from './EndpointEvents.module.scss';

export interface EndpointEventsProps {
  webhookEndpointId: string;
  onDeleteWebhookEvent: (webhookEventId: string) => void;
  onResendWebhookEvent: (webhookEventId: string) => void;
  onWebhookEventClick: (webhookEvent: WebhookEvent) => void;
}

const EndpointEvents: FC<EndpointEventsProps> = ({
  webhookEndpointId,
  onDeleteWebhookEvent,
  onWebhookEventClick,
  onResendWebhookEvent,
}) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { sortingType, filters } = useSelector((state: ReduxState) => (
    state.generalSettings.ui.webhooksDashboards.endpointDetails.endpointEventsTable
  ));

  useStateReset(EndpointEventsTableActionType.ResetState);

  const initialFiltersAttributes = { offset: 0, count: WEBHOOK_EVENTS_PER_PAGE_DEFAULT };

  const params = {
    sortingType,
    ...filters,
  };

  const requestWebhookEvents = (sortingTypeToApply: WebhookEventSortingType) => {
    dispatchWithUnwrap(getWebhookEvents({
      filters: {
        ...initialFiltersAttributes,
        search: filters.search,
        webhookId: webhookEndpointId,
      },
      sortingType: sortingTypeToApply,
      actionOrigin: endpointEventsTableActionOrigin,
    }));
  };

  const webhookEventsIds = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  const getWebhookEventsSelector = useMemo(() => createGetEventsSelector(webhookEventsIds), [webhookEventsIds]);

  const webhookEvents = useSelector(getWebhookEventsSelector);

  useEffect(() => {
    requestWebhookEvents(sortingType);
  }, [filters, sortingType]);

  const debouncedSearch = useMemo(() => {
    return debounce(requestWebhookEvents, 300);
  }, []);

  const handleSearchChange = (search: string) => {
    dispatch(changeSearchValue(search));

    debouncedSearch(sortingType);
  };

  const handleSort = (sortingField: WebhookEventSortingField, ascending: boolean) => {
    dispatch(changeSortingType({
      field: sortingField,
      ascending,
    }));
  };

  const renderTooltip = () => (
    <>
      <p>Events include only the updated information</p>
      <p>and are automatically deleted after 3 days</p>
    </>
  );

  const shouldRenderNoItemsAddedSection = paginationProps.itemsTotal === 0 && !filters.search;

  const renderContent = () => {
    if (shouldRenderNoItemsAddedSection) {
      return (
        <NoItems
          className={styles.noItemsAddedSection}
          title="This endpoint has no associated events yet!"
          icon={<EmptyTableImage />}
        />
      );
    }

    if (filters.search && !webhookEvents.length) {
      return (
        <SearchNotFound searchValue={filters.search} />
      );
    }

    return (
      <EventsTable
        source={webhookEvents}
        paginationProps={paginationProps}
        sortingType={sortingType}
        searchInputValue={filters.search}
        onSort={handleSort}
        onRowClick={onWebhookEventClick}
        onDelete={onDeleteWebhookEvent}
        onResend={onResendWebhookEvent}
      />
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerTitleWithTooltip}>
          <h4 className={styles.headerTitle}>Events</h4>
          <QuestionIcon size={24} tooltip={renderTooltip()} />
        </div>
      </div>
      {!shouldRenderNoItemsAddedSection && <SearchInput
        value={filters.search}
        containerClassName={styles.searchInputContainer}
        placeholder="Search"
        onClear={() => handleSearchChange('')}
        onChange={(event) => handleSearchChange(event.target.value)}
      />}
      {renderContent()}
    </div>
  );
};

export default EndpointEvents;
