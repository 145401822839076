import { FC, useState } from 'react';
import { DocumentTypeField } from 'api/DocumentTypeFieldApi';
import DocumentTypeFieldsTableRow from 'components/DocumentType/Tables/DocumentTypeFieldsTable/DocumentTypeFieldsTableRow';
import Accordion from 'components/Accordion/Accordion';
import { DropdownImage } from 'static/images';
import clsx from 'clsx';

import styles from './DocumentTypeFieldsTableCollapsibleRow.module.scss';

interface DocumentTypeFieldsTableCollapsibleRowProps {
  field: DocumentTypeField;
  fieldChildren: DocumentTypeField[];
  nameCellClassName?: string;
  typeCellClassName?: string;
  onEditField: (field: DocumentTypeField) => void;
  onDeleteField: (field: DocumentTypeField) => void;
}

const DocumentTypeFieldsTableCollapsibleRow: FC<DocumentTypeFieldsTableCollapsibleRowProps> = ({
  field,
  fieldChildren,
  nameCellClassName,
  typeCellClassName,
  onEditField,
  onDeleteField,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderParentFieldRow = () => (
    <DocumentTypeFieldsTableRow
      field={field}
      arrowIcon={<DropdownImage className={clsx(styles.arrowIcon, isOpen && styles.arrowIconExpanded)} />}
      disableOnClick
      nameCellClassName={nameCellClassName}
      typeCellClassName={typeCellClassName}
      onEditField={onEditField}
      onDeleteField={onDeleteField}
    />
  )

  return (
    <Accordion
      triggerComponent={renderParentFieldRow()}
      open={isOpen}
      collapsibleContainerClassName={styles.collapsibleContainer}
      onOpen={setIsOpen}
    >
      {fieldChildren.map((child) => (
        <DocumentTypeFieldsTableRow
          field={child}
          key={child.id}
          disableHover
          nameCellClassName={clsx(styles.childNameCell, nameCellClassName)}
          useActions={false}
          typeCellClassName={typeCellClassName}
        />
      ))}
    </Accordion>
  )
}

export default DocumentTypeFieldsTableCollapsibleRow;
