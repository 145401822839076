import React, { FC } from 'react';
import NavigationLink from 'components/LeftNav/NavigationLinks/NavigationLink';
import { Link } from 'components/MainLayout/leftNavReducer/LeftNavTypes';
import { NavigationLinkIdType } from 'enums/NavigationLinkName';

import styles from './NavigationLinks.module.scss';

interface NavigationLinksType {
  isExpanded: boolean;
  isOpenedOnMobile: boolean;
  links?: Link[];
  activeLinkId?: NavigationLinkIdType;
  activeNestedLinkId?: NavigationLinkIdType;
  setActiveLinkTitle: (title: string) => void;
  disabled?: boolean;
}

const NavigationLinks: FC<NavigationLinksType> = ({
  isExpanded,
  links,
  activeLinkId,
  setActiveLinkTitle,
  disabled,
  activeNestedLinkId,
  isOpenedOnMobile,
}) => {
  return (
    <nav className={styles.navLinksContainer}>
      <ul>
        {(links || []).map((item) => {
          const { id, title, icon: Icon, nestedLinks, route = '/', iconType } = item;
          return (
            <NavigationLink
              id={id}
              title={title}
              Icon={Icon}
              iconType={iconType}
              key={title}
              isExpanded={isExpanded || isOpenedOnMobile}
              onUpdateActiveLinkTitle={setActiveLinkTitle}
              isActive={activeLinkId === id}
              nestedLinks={nestedLinks}
              route={route}
              activeLinkId={activeLinkId}
              disabled={disabled}
              activeNestedLinkId={activeNestedLinkId}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default NavigationLinks;
