import { Option } from 'components/SelectInput/SelectInput';

const currencies = [
  {
    symbol: '$', // MAIN SYMBOL + SEARCH
    description: 'United States Dollar', // SHOW AS DESCRIPTION IN UI + SEARCH
    altDescription: 'USD', // MAYBE USE FOR ALTERNATE SEARCH
  },
  {
    symbol: '$',
    description: 'Canadian Dollar',
    altDescription: 'CAD',
  },
  {
    symbol: '€',
    description: 'Euro',
    altDescription: 'EUR',
  },
  {
    symbol: 'AED',
    description: 'United Arab Emirates Dirham',
    altDescription: 'UAE Dirham',
  },
  {
    symbol: 'Af',
    description: 'Afghan Afghani',
    altDescription: 'AFN',
  },
  {
    symbol: 'ALL',
    description: 'Albanian Lek',
    altDescription: 'Lek',
  },
  {
    symbol: 'AMD',
    description: 'Armenian Dram',
    altDescription: 'AMD',
  },
  {
    symbol: '$',
    description: 'Argentine Peso',
    altDescription: 'ARS',
  },
  {
    symbol: '$',
    description: 'Australian Dollar',
    altDescription: 'AUD',
  },
  {
    symbol: 'AZN',
    description: 'Azerbaijani Manat',
    altDescription: 'AZN',
  },
  {
    symbol: 'KM',
    description: 'Bosnia-Herzegovina Convertible Mark',
    altDescription: 'BAM',
  },
  {
    symbol: 'Tk',
    description: 'Bangladeshi Taka',
    altDescription: 'BDT',
  },
  {
    symbol: 'BGN',
    description: 'Bulgarian Lev',
    altDescription: 'BGN',
  },
  {
    symbol: 'BD',
    description: 'Bahraini Dinar',
    altDescription: 'BHD',
  },
  {
    symbol: 'FBu',
    description: 'Burundian Franc',
    altDescription: 'BIF',
  },
  {
    symbol: 'B$',
    description: 'Brunei Dollar',
    altDescription: 'BND',
  },
  {
    symbol: 'Bs',
    description: 'Bolivian Boliviano',
    altDescription: 'BOB',
  },
  {
    symbol: 'R$',
    description: 'Brazilian Real',
    altDescription: 'BRL',
  },
  {
    symbol: 'BWP',
    description: 'Botswanan Pula',
    altDescription: 'BWP',
  },
  {
    symbol: 'BYN',
    description: 'Belarusian Ruble',
    altDescription: 'Br',
  },
  {
    symbol: '$',
    description: 'Belize Dollar',
    altDescription: 'BZD',
  },
  {
    symbol: 'CDF',
    description: 'Congolese Franc',
    altDescription: 'CDF',
  },
  {
    symbol: 'CHF',
    description: 'Swiss Franc',
    altDescription: 'CHF',
  },
  {
    symbol: '$',
    description: 'Chilean Peso',
    altDescription: 'CLP',
  },
  {
    symbol: '¥',
    description: 'Chinese Yuan',
    altDescription: 'CNY',
  },
  {
    symbol: '$',
    description: 'Colombian Peso',
    altDescription: 'COP',
  },
  {
    symbol: '₡',
    description: 'Costa Rican Colón',
    altDescription: 'CRC',
  },
  {
    symbol: 'CV$',
    description: 'Cape Verdean Escudo',
    altDescription: 'CVE',
  },
  {
    symbol: 'Kč',
    description: 'Czech Republic Koruna',
    altDescription: 'CZK',
  },
  {
    symbol: 'Fdj',
    description: 'Djiboutian Franc',
    altDescription: 'DJF',
  },
  {
    symbol: 'Kr',
    description: 'Danish Krone',
    altDescription: 'DKK',
  },
  {
    symbol: 'RD$',
    description: 'Dominican Peso',
    altDescription: 'DOP',
  },
  {
    symbol: 'DA',
    description: 'Algerian Dinar',
    altDescription: 'DZD',
  },
  {
    symbol: 'kr',
    description: 'Estonian Kroon',
    altDescription: 'EEK',
  },
  {
    symbol: 'EGP',
    description: 'Egyptian Pound',
    altDescription: 'EGP',
  },
  {
    symbol: 'Nfk',
    description: 'Eritrean Nakfa',
    altDescription: 'ERN',
  },
  {
    symbol: 'Br',
    description: 'Ethiopian Birr',
    altDescription: 'ETB',
  },
  {
    symbol: '£',
    description: 'British Pound Sterling',
    altDescription: 'GBP',
  },
  {
    symbol: 'GEL',
    description: 'Georgian Lari',
    altDescription: 'GEL',
  },
  {
    symbol: 'GH₵',
    description: 'Ghanaian Cedi',
    altDescription: 'GHS',
  },
  {
    symbol: 'FG',
    description: 'Guinean Franc',
    altDescription: 'GNF',
  },
  {
    symbol: 'GTQ',
    description: 'Guatemalan Quetzal',
    altDescription: 'GTQ',
  },
  {
    symbol: 'HK$',
    description: 'Hong Kong Dollar',
    altDescription: 'HKD',
  },
  {
    symbol: 'HNL',
    description: 'Honduran Lempira',
    altDescription: 'HNL',
  },
  {
    symbol: 'kn',
    description: 'Croatian Kuna',
    altDescription: 'HRK',
  },
  {
    symbol: 'Ft',
    description: 'Hungarian Forint',
    altDescription: 'HUF',
  },
  {
    symbol: 'Rp',
    description: 'Indonesian Rupiah',
    altDescription: 'IDR',
  },
  {
    symbol: '₪',
    description: 'Israeli New Sheqel',
    altDescription: 'ILS',
  },
  {
    symbol: 'Rs',
    description: 'Indian Rupee',
    altDescription: 'INR',
  },
  {
    symbol: 'IQD',
    description: 'Iraqi Dinar',
    altDescription: 'IQD',
  },
  {
    symbol: 'IRR',
    description: 'Iranian Rial',
    altDescription: 'IRR',
  },
  {
    symbol: 'kr',
    description: 'Icelandic Króna',
    altDescription: 'ISK',
  },
  {
    symbol: 'J$',
    description: 'Jamaican Dollar',
    altDescription: 'JMD',
  },
  {
    symbol: 'JD',
    description: 'Jordanian Dinar',
    altDescription: 'JOD',
  },
  {
    symbol: '¥',
    description: 'Japanese Yen',
    altDescription: 'JPY',
  },
  {
    symbol: 'Ksh',
    description: 'Kenyan Shilling',
    altDescription: 'KES',
  },
  {
    symbol: 'KHR',
    description: 'Cambodian Riel',
    altDescription: 'KHR',
  },
  {
    symbol: 'CF',
    description: 'Comorian Franc',
    altDescription: 'KMF',
  },
  {
    symbol: '₩',
    description: 'South Korean Won',
    altDescription: 'KRW',
  },
  {
    symbol: 'KD',
    description: 'Kuwaiti Dinar',
    altDescription: 'KWD',
  },
  {
    symbol: 'KZT',
    description: 'Kazakhstani Tenge',
    altDescription: 'KZT',
  },
  {
    symbol: 'LB£',
    description: 'Lebanese Pound',
    altDescription: 'LBP',
  },
  {
    symbol: 'SLR',
    description: 'Sri Lankan Rupee',
    altDescription: 'LKR',
  },
  {
    symbol: 'Lt',
    description: 'Lithuanian Litas',
    altDescription: 'LTL',
  },
  {
    symbol: 'Ls',
    description: 'Latvian Lats',
    altDescription: 'LVL',
  },
  {
    symbol: 'LD',
    description: 'Libyan Dinar',
    altDescription: 'LYD',
  },
  {
    symbol: 'MAD',
    description: 'Moroccan Dirham',
    altDescription: 'MAD',
  },
  {
    symbol: 'MDL',
    description: 'Moldovan Leu',
    altDescription: 'MDL',
  },
  {
    symbol: 'MGA',
    description: 'Malagasy Ariary',
    altDescription: 'MGA',
  },
  {
    symbol: 'MKD',
    description: 'Macedonian Denar',
    altDescription: 'MKD',
  },
  {
    symbol: 'MMK',
    description: 'Myanma Kyat',
    altDescription: 'MMK',
  },
  {
    symbol: 'MOP',
    description: 'Macanese Pataca',
    altDescription: 'MOP',
  },
  {
    symbol: 'MUR',
    description: 'Mauritian Rupee',
    altDescription: 'MUR',
  },
  {
    symbol: 'MX$',
    description: 'Mexican Peso',
    altDescription: 'MXN',
  },
  {
    symbol: 'RM',
    description: 'Malaysian Ringgit',
    altDescription: 'MYR',
  },
  {
    symbol: 'MTn',
    description: 'Mozambican Metical',
    altDescription: 'MZN',
  },
  {
    symbol: '$',
    description: 'Namibian Dollar',
    altDescription: 'NAD',
  },
  {
    symbol: '₦',
    description: 'Nigerian Naira',
    altDescription: 'NGN',
  },
  {
    symbol: 'C$',
    description: 'Nicaraguan Córdoba',
    altDescription: 'NIO',
  },
  {
    symbol: 'kr',
    description: 'Norwegian Krone',
    altDescription: 'NOK',
  },
  {
    symbol: 'NPR',
    description: 'Nepalese Rupee',
    altDescription: 'NPR',
  },
  {
    symbol: '$',
    description: 'New Zealand Dollar',
    altDescription: 'NZD',
  },
  {
    symbol: 'OMR',
    description: 'Omani Rial',
    altDescription: 'OMR',
  },
  {
    symbol: 'PAB',
    description: 'Panamanian Balboa',
    altDescription: 'PAB',
  },
  {
    symbol: 'PEN',
    description: 'Peruvian Nuevo Sol',
    altDescription: 'PEN',
  },
  {
    symbol: '₱',
    description: 'Philippine Peso',
    altDescription: 'PHP',
  },
  {
    symbol: 'PKR',
    description: 'Pakistani Rupee',
    altDescription: 'PKR',
  },
  {
    symbol: 'zł',
    description: 'Polish Zloty',
    altDescription: 'PLN',
  },
  {
    symbol: '₲',
    description: 'Paraguayan Guarani',
    altDescription: 'PYG',
  },
  {
    symbol: 'QR',
    description: 'Qatari Riyal',
    altDescription: 'QAR',
  },
  {
    symbol: 'RON',
    description: 'Romanian Leu',
    altDescription: 'RON',
  },
  {
    symbol: 'RSD',
    description: 'Serbian Dinar',
    altDescription: 'RSD',
  },
  {
    symbol: 'RUB',
    description: 'Russian Ruble',
    altDescription: 'RUB',
  },
  {
    symbol: 'RWF',
    description: 'Rwandan Franc',
    altDescription: 'RWF',
  },
  {
    symbol: 'SR',
    description: 'Saudi Riyal',
    altDescription: 'SAR',
  },
  {
    symbol: 'SDG',
    description: 'Sudanese Pound',
    altDescription: 'SDG',
  },
  {
    symbol: 'kr',
    description: 'Swedish Krona',
    altDescription: 'SEK',
  },
  {
    symbol: 'S$',
    description: 'Singapore Dollar',
    altDescription: 'SGD',
  },
  {
    symbol: 'Ssh',
    description: 'Somali Shilling',
    altDescription: 'SOS',
  },
  {
    symbol: 'SY£',
    description: 'Syrian Pound',
    altDescription: 'SYP',
  },
  {
    symbol: '฿',
    description: 'Thai Baht',
    altDescription: 'THB',
  },
  {
    symbol: 'DT',
    description: 'Tunisian Dinar',
    altDescription: 'TND',
  },
  {
    symbol: 'T$',
    description: 'Tongan Paʻanga',
    altDescription: 'TOP',
  },
  {
    symbol: 'TL',
    description: 'Turkish Lira',
    altDescription: 'TRY',
  },
  {
    symbol: 'TT$',
    description: 'Trinidad and Tobago Dollar',
    altDescription: 'TTD',
  },
  {
    symbol: 'NT$',
    description: 'New Taiwan Dollar',
    altDescription: 'TWD',
  },
  {
    symbol: 'TSh',
    description: 'Tanzanian Shilling',
    altDescription: 'TZS',
  },
  {
    symbol: '₴',
    description: 'Ukrainian Hryvnia',
    altDescription: 'UAH',
  },
  {
    symbol: 'USh',
    description: 'Ugandan Shilling',
    altDescription: 'UGX',
  },
  {
    symbol: '$U',
    description: 'Uruguayan Peso',
    altDescription: 'UYU',
  },
  {
    symbol: 'UZS',
    description: 'Uzbekistan Som',
    altDescription: 'UZS',
  },
  {
    symbol: 'Bs.F.',
    description: 'Venezuelan Bolívar',
    altDescription: 'VEF',
  },
  {
    symbol: '₫',
    description: 'Vietdescriptionse Dong',
    altDescription: 'VND',
  },
  {
    symbol: 'FCFA',
    description: 'CFA Franc BEAC',
    altDescription: 'XAF',
  },
  {
    symbol: 'CFA',
    description: 'CFA Franc BCEAO',
    altDescription: 'XOF',
  },
  {
    symbol: 'YR',
    description: 'Yemeni Rial',
    altDescription: 'YER',
  },
  {
    symbol: 'R',
    description: 'South African Rand',
    altDescription: 'ZAR',
  },
  {
    symbol: 'ZK',
    description: 'Zambian Kwacha',
    altDescription: 'ZMK',
  },
  {
    symbol: 'BTC',
    description: 'Bitcoin',
    altDescription: 'BTK',
  },
  {
    symbol: 'ETH',
    description: 'Ethereum',
    altDescription: 'ETH',
  },
];

export const getCurrencySymbol = (currencyCode: string): string =>
  currencies.find((currency) =>
    currency.altDescription.toLocaleLowerCase() === currencyCode.toLocaleLowerCase())?.symbol || '';

export const formattedCurrencies: Option[] = currencies.map(
  ({ symbol, description: currencyDescription, altDescription }) => ({
    description: currencyDescription,
    value: altDescription,
    name: symbol,
  }),
);

export default currencies;
