import React, { FC, useState, ComponentType } from 'react';
import clsx from 'clsx';
import { IconType, NestedLink } from 'components/MainLayout/leftNavReducer/LeftNavTypes';
import WrapperWithLink from 'components/WrapperWithLink';
import { VectorBottomImage } from 'static/images';
import DropdownContent from './DropdownContent';
import { NavigationLinkIdType } from 'enums/NavigationLinkName';

import styles from './NavigationLink.module.scss';

export interface NavigationLinkType {
  id: NavigationLinkIdType;
  title: string;
  Icon?: ComponentType<{ className?: string }>;
  iconType?: IconType;
  isExpanded: boolean;
  isActive: boolean;
  onUpdateActiveLinkTitle: (title: string) => void;
  nestedLinks?: NestedLink[];
  activeLinkId?: NavigationLinkIdType;
  activeNestedLinkId?: NavigationLinkIdType;
  route: string;
  disabled?: boolean;
}

const NavigationLink: FC<NavigationLinkType> = ({
  id,
  title,
  Icon,
  isExpanded,
  isActive,
  activeLinkId,
  iconType,
  onUpdateActiveLinkTitle,
  nestedLinks,
  disabled,
  route,
  activeNestedLinkId,
}) => {
  const hasActiveNestedLink = !!nestedLinks?.some((link) => link.id === activeNestedLinkId);

  const [isDropdownOpen, setIsDropdownOpen] = useState(hasActiveNestedLink);

  const handleOnLinkClick = (): void => {
    if (disabled) {
      return;
    }

    if (nestedLinks) {
      setIsDropdownOpen(!isDropdownOpen);
      return;
    }
    onUpdateActiveLinkTitle(route);
  };

  const dropdownIconStyle = isDropdownOpen ? styles.dropdownIconOpened : styles.dropdownIconClosed;
  const activeDropdownIconStyle = !activeNestedLinkId && isActive ? styles.activeDropdownIcon : '';
  const disabledDropdownIconClassName = disabled && !isActive && styles.disabledDropdownIcon;

  const iconTypeClasses: Record<IconType, string> = {
    stroke: styles.stroke,
    fill: styles.fill,
  };

  const Wrapper = nestedLinks ? 'div' : WrapperWithLink;

  const getTitleClassName = () => {
    if (isActive) {
      return styles.titleActive;
    }

    return disabled ? styles.disabledTitle : styles.title;
  };

  const renderLinkIcon = () => {
    if (!Icon || !iconType) {
      return;
    }

    const iconClasses = clsx(
      isActive ? styles.iconActive : styles.icon,
      iconTypeClasses[iconType], disabled && !isActive && styles.disabledIcon,
    );

    return <Icon
      className={iconClasses}
    />;
  }

  return (
    <li>
      <Wrapper
        link={route}
        className={clsx(styles.link, disabled && !isActive && styles.disabledLink)}
        onClick={handleOnLinkClick}
      >
        {renderLinkIcon()}
        <div className={getTitleClassName()}>
          <p>{title}</p>
        </div>
        {nestedLinks && (
          <VectorBottomImage className={clsx(dropdownIconStyle, activeDropdownIconStyle, disabledDropdownIconClassName)} />
          )}
        {!isExpanded && !disabled && (
          <div className={styles.subLinkList}>
            <div
              className={clsx(
                !activeNestedLinkId && id === activeLinkId && styles.activeSubTitleLink,
                nestedLinks ? styles.subTitleLinkNested : styles.subTitleLink,
              )}
            >
              {title.toUpperCase()}
            </div>
            {nestedLinks && (
              <div className={styles.nestedLinksList}>
                {nestedLinks.map((link, index) => (
                  <WrapperWithLink
                    key={index}
                    link={`${route}${link.route}`}
                    className={link.id === activeNestedLinkId ? styles.activeSubLink : styles.subLink}
                    onClick={() => onUpdateActiveLinkTitle(`${route}${link.route}`)}
                  >
                    {link.title}
                  </WrapperWithLink>
                ))}
              </div>
            )}
          </div>
        )}
      </Wrapper>
      {nestedLinks && (
        <DropdownContent
          disabled={disabled}
          isDropdownOpen={isDropdownOpen}
          isExpanded={isExpanded}
          nestedLinks={nestedLinks}
          onUpdateActiveLinkTitle={onUpdateActiveLinkTitle}
          activeNestedLinkId={activeNestedLinkId}
          nestedItemClassName={styles.nestedLinkItem}
          baseRoute={route}
        />
      )}
    </li>
  );
};

export default NavigationLink;
