import { ImageParams } from 'api/UserApi';
import UploadImagePopup from 'components/UploadImagePopup';
import { UploadImagePopupStep } from 'components/UploadImagePopup/UploadImagePopup';
import React, { FC } from 'react';

interface MyAccountUploadAvatarPopupProps {
  onClose: () => void;
  onDelete: () => Promise<void>;
  onSave: (file: Blob, croppedFile: Blob, params: ImageParams) => Promise<void>;
  profileImage?: Blob;
  profileImageParams?: ImageParams | null;
  initialStep?: UploadImagePopupStep;
}

const MAX_IMAGE_SIZE = 1024 * 1024 * 25; // 25MB

const MyAccountUploadAvatarPopup: FC<MyAccountUploadAvatarPopupProps> = ({
  onClose,
  onDelete,
  onSave,
  profileImage,
  initialStep,
  profileImageParams,
}) => {
  return (
    <UploadImagePopup
      onClose={onClose}
      title="Photo"
      onSubmit={onSave}
      outputMaxWidth={288}
      outputMaxHeight={288}
      aspectRation={1} // 1(w) X 1(h)
      image={profileImage}
      initialStep={initialStep}
      maxSizeBytes={MAX_IMAGE_SIZE}
      supportedExtensions={['.png', '.jpg', '.jpeg']}
      imageParams={profileImageParams}
      onDelete={onDelete}
    />
  );
};

export default MyAccountUploadAvatarPopup;
