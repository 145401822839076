import { Reducer } from '@reduxjs/toolkit';
import { ItemsStateWithPagination } from 'pagination';
import {
  createDashboardSlice,
  DashboardActionType,
} from 'DashboardSlice/createDashboardSlice';
import usersDashboardPagination, { USERS_PER_PAGE_DEFAULT } from './Pagination';
import usersDashboardFiltersReducer, {
  actions as filtersActions,
  getInitialState as getFiltersInitialState,
} from './Filters/UsersDashboardFiltersStore';
import { UsersDashboardFiltersActionType } from './Filters/UsersDashboardFiltersActionType';
import { UsersDashboardFiltersState } from './Filters/UsersDashboardFiltersStore';
import { UsersDashboardActionType } from './UsersDashboardActionType';
import { User, UsersDataFilter, UsersSortingField, UsersSortingType } from 'api/UserApi';
import { getUsers } from 'Users/Thunks';

export interface UsersDashboardState extends ItemsStateWithPagination<string> {
  sortingType: UsersSortingType;
  searched: {
    [index: string]: ItemsStateWithPagination<string>;
  };
  filters: UsersDashboardFiltersState;
}

const usersDashboardActionOrigin = 'usersDashboard';

const actionTypes = {
  [DashboardActionType.ChangeSortingType]: UsersDashboardActionType.ChangeSortingType,
  [DashboardActionType.ResetState]: UsersDashboardActionType.ResetState,
  [DashboardActionType.ChangeMembers]: UsersDashboardFiltersActionType.ChangeMembers,
  [DashboardActionType.ChangeFilters]: UsersDashboardFiltersActionType.ChangeFilters,
  [DashboardActionType.ClearFilters]: UsersDashboardFiltersActionType.ClearFilters,
};

const usersDashboardSlice = createDashboardSlice<
  User,
  UsersDataFilter,
  UsersSortingType,
  UsersDashboardFiltersState,
  typeof actionTypes
>({
  namespace: 'users',
  path: ['generalSettings', 'ui', 'usersDashboard'],
  actionOrigins: [usersDashboardActionOrigin],
  itemsPerPage: USERS_PER_PAGE_DEFAULT,
  defaultSorting: {
    field: UsersSortingField.FirstName,
    ascending: true,
  },
  getItems: getUsers,
  actionTypes,
  filtersAndSortingProps: [
    'statuses',
    'isMfaEnabled',
    'permissionGroup',
  ],
  filtersReducer: usersDashboardFiltersReducer as Reducer<UsersDashboardFiltersState>,
  getFiltersInitialState,
  filtersActions,
});

const {
  actions,
  reducer,
  filtersAndSortingSaver,
  getInitialState,
} = usersDashboardSlice;

export {
  getInitialState,
  filtersAndSortingSaver,
  usersDashboardActionOrigin,
  usersDashboardPagination as pagination,
};

export const {
  changeSortingType,
  resetState,
} = actions;

export const {
  changeMembers,
  clearFilters,
  changeFilters,
  changeSearchValue,
  hideFiltersPanel,
  showFiltersPanel,
} = filtersActions;

export default usersDashboardPagination.wrapReducer(reducer);
