import React, { FC } from 'react';
import ContextualViewContainer from 'components/ContextualViewContainer';
import DetailsHeader from 'components/DetailsHeader';
import { NullableItems, PaginationProps } from 'pagination';
import styles from './DataExtractionContextualView.module.scss';
import { DataExtractionResultsTable } from '../DataExtractionResults/Tables';
import { DataExtraction, DataExtractionStatus } from '../../api/DataExtractionApi';
import {
  DataExtractionResult,
  DataExtractionResultSortingField,
  DataExtractionResultsSortingType,
} from '../../api/DataExtractionResultApi';
import { User } from '../../api/UserApi';
import normalizeEntityArray from '../../utils/normalizeEntityArray';
import { ExportFileType } from '../../api/DataExtractionExportApi';
import DataExtractionStatusTag from '../DataExtractionStatusTag';

export interface IDataExtractionContextualViewProps {
  dataExtraction: DataExtraction | null;
  dataExtractionResults: NullableItems<DataExtractionResult>;
  members: User[];
  resultsPaginationProps: PaginationProps;
  resultsSortingType: DataExtractionResultsSortingType;
  onSortResults: (field: DataExtractionResultSortingField, ascending: boolean) => void;
  onResultClick: (result: DataExtractionResult) => void;
  onExportDataExtractionResult: (resultId: string, exportFileType: ExportFileType) => void;
  onDataExtractionDelete: (dataExtraction: DataExtraction) => void;
  onResultsDownload: (dataExtractionId: string, exportFileType: ExportFileType) => void;
  isResultsDownloadInProgress?: boolean;
  downloadingResultId: string | null;
  onClose: () => void;
}

const DataExtractionContextualView: FC<IDataExtractionContextualViewProps> = ({
  dataExtraction,
  dataExtractionResults,
  members,
  resultsPaginationProps,
  resultsSortingType,
  onSortResults,
  downloadingResultId,
  onExportDataExtractionResult,
  onResultClick,
  onDataExtractionDelete,
  onResultsDownload,
  isResultsDownloadInProgress,
  onClose,
}) => {
  const groupedUsers = normalizeEntityArray(members, 'id');
  const actions = dataExtraction ? [{
    title: 'Export Results to CSV',
    handler: () => onResultsDownload(dataExtraction.id, ExportFileType.Csv),
    disabled: isResultsDownloadInProgress || !!dataExtraction.statusSummary[DataExtractionStatus.Processing],
  }, {
    title: 'Export Results to JSON',
    handler: () => onResultsDownload(dataExtraction.id, ExportFileType.Json),
    disabled: isResultsDownloadInProgress || !!dataExtraction.statusSummary[DataExtractionStatus.Processing],
  }, {
    title: 'Delete Results',
    handler: () => onDataExtractionDelete(dataExtraction),
    separatorBeforeRequired: true,
    danger: true,
  }] : [];

  const actionInfo = dataExtraction ? {
    performedBy: dataExtraction.createdBy ? groupedUsers[dataExtraction.createdBy] : null,
    actionDateAt: dataExtraction.createdAt,
    actionName: 'Processed',
  } : null;

  const subtitleTags = dataExtractionResults[0]
    ? (<DataExtractionStatusTag status={dataExtractionResults[0].status} />)
    : null;

  return (
    <ContextualViewContainer onClose={onClose}>
      <DetailsHeader
        title={dataExtraction ? 'Multiple Files' : null}
        actions={actions}
        referenceId={dataExtraction?.id || null}
        actionInfo={actionInfo}
        isActionsLoading={isResultsDownloadInProgress}
        titleSectionClassName={styles.detailsHeaderTitleSection}
        subtitleTags={subtitleTags}
        subtitleTagsClassName={styles.subtitleTags}
      />
      <DataExtractionResultsTable
        results={dataExtractionResults}
        paginationProps={resultsPaginationProps}
        sortingType={resultsSortingType}
        onSort={onSortResults}
        onResultClick={onResultClick}
        onExportDataExtractionResult={onExportDataExtractionResult}
        downloadingResultId={downloadingResultId}
      />
    </ContextualViewContainer>
  );
}

export default DataExtractionContextualView;
