import React, { FC, useState } from 'react';
import Button from 'components/Button';
import CountryCodeSelect from 'components/CountryCodeSelect/CountryCodeSelect';
import useInputValidation from 'hooks/useInputValidation';
import { validatePhoneNumber } from 'utils/validation/validation';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { CountryCode } from 'libphonenumber-js';
import styles from './ProvidePhoneNumberStep.module.scss';

interface ProvidePhoneNumberStepProps {
  phoneNumberFormat: string;
  buttonText: string;
  name?: string;
  loading?: boolean;
  initialPhone: string;
  onSubmit: (phone: string) => void;
}

export const DEFAULT_PHONE_COUNTRY_CODE: CountryCode = 'US';

const ProvidePhoneNumberStep: FC<ProvidePhoneNumberStepProps> = ({
  name,
  phoneNumberFormat,
  initialPhone,
  buttonText,
  onSubmit,
  loading,
}) => {
  const [phone, setPhone] = useState(initialPhone);
  const [phoneFormat, setPhoneFormat] = useState<CountryCode>(phoneNumberFormat as CountryCode || DEFAULT_PHONE_COUNTRY_CODE);

  const [
    handlePhoneNumberFieldBlur,
    handlePhoneNumberFieldFocus,
    phoneNumberFieldErrorMessage,
    isPhoneNumberFieldValid,
  ] = useInputValidation({
    value: phone,
    required: true,
    validators: [validatePhoneNumber],
  });

  const handlePhoneNumberInputFieldValueChange = (value: string) => {
    setPhone(value);
  };

  return (
    <div>
      <div className={styles.message}>
        Please provide {name ? `${name}'s new` : 'your'} mobile phone number.
        This number must be able to receive text messages (standard rates apply).
      </div>

      <div className={styles.inputContainer}>
        <CountryCodeSelect
          hideClearIcon
          hasRightNeighbour
          labelTitle="Phone Number"
          className={styles.countryCodeSelectorContainer}
          value={phoneFormat}
          onChange={(option) => setPhoneFormat(option.value as CountryCode)}
          required
        />
        <PhoneNumberInput
          required
          country={phoneFormat}
          labelTitle=""
          hasLeftNeighbour
          onBlur={handlePhoneNumberFieldBlur}
          onFocus={handlePhoneNumberFieldFocus}
          containerClassName={styles.phoneNumberInputContainer}
          value={phone}
          errorMessage={phoneNumberFieldErrorMessage}
          onChange={handlePhoneNumberInputFieldValueChange}
        />
      </div>
      <Button
        disabled={!isPhoneNumberFieldValid}
        size="form"
        isLoading={loading}
        onClick={() => onSubmit(phone)}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ProvidePhoneNumberStep;
