import { DataExtractionMode } from 'api/DataExtractionApi';
import { IDocumentTokenInfo } from 'api/DocumentTokenApi';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_IN_MB } from 'components/ExtractionHistory/DataExtractionConstants';
import { RequestError } from 'utils/fetch';

export interface IFileState {
  id: string;
  file: File;
  validationInfo?: IDocumentTokenInfo | null;
  error?: string | null;
}

export const validateFile = (file: File) => {
  if (file.size > MAX_FILE_SIZE) {
    return `Maximum file size exceeded. Please upload a file that is less than ${MAX_FILE_SIZE_IN_MB}MB.`;
  }

  return null;
};

export const validateDocumentTokenInfo = (validationInfo: IDocumentTokenInfo, mode: DataExtractionMode) => {
  if (validationInfo.tokens > validationInfo.limits[mode]) {
    return `The file size limit has been exceeded. Please upload a smaller file or select another processing mode.`;
  }

  return null;
};

export const validateFileState = (fileState: IFileState, mode: DataExtractionMode) => {
  if (fileState.validationInfo === undefined) {
    return null;
  }

  const fileError = validateFile(fileState.file);

  if (fileError) {
    return fileError;
  }

  if (fileState.validationInfo) {
    return validateDocumentTokenInfo(fileState.validationInfo, mode);
  }

  if (fileState.error) {
    return fileState.error;
  }

  return 'Internal server error.'
};

export const validateMany = async (
  filesToValidate: IFileState[],
  validate: (file: File) => Promise<IDocumentTokenInfo | undefined>,
) => {
  return await Promise.all(filesToValidate.map(async (fileState) => {
    const baseError = validateFile(fileState.file);

    try {
      const validationInfo = baseError
        ? null
        : await validate(fileState.file);

      return {
        id: fileState.id,
        error: baseError,
        validationInfo,
      };
    } catch (error) {
      const requestError = error as RequestError;

      return{
        id: fileState.id,
        validationInfo: null,
        error: requestError.responseStatus >= 400 && requestError.responseStatus < 500
          ? requestError.message
          : 'Internal server error',
      } as IFileState;
    }
  }));
};
