import React, { FC, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { CreateWebhookEndpointParams } from 'api/WebhookEndpointsApi';
import EventTypeMultiselect from 'components/EventTypeMultiselect';
import CustomCheckbox from 'components/CustomCheckbox';
import useInputValidation from 'hooks/useInputValidation';
import { validateUrl } from 'utils/validation/validation';
import { trim } from 'lodash';
import styles from './CreateEndpointPopup.module.scss';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import useDocumentTypes from 'hooks/useDocumentTypes';

interface CreateEndpointPopupProps {
  onSubmit: (params: CreateWebhookEndpointParams) => void;
  onClose: () => void;
  isCreating?: boolean;
  usePortal?: boolean;
}

const URL_VALIDATORS = [validateUrl];

const CreateEndpointPopup: FC<CreateEndpointPopupProps> = ({
  onSubmit,
  onClose,
  isCreating,
  usePortal,
}) => {
  const documentTypes = useDocumentTypes();

  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [events, setEvents] = useState<string[]>([]);
  const [documentTypeIds, setDocumentTypeIds] = useState<string[]>([]);

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedUrl = trim(event.currentTarget.value);
    setUrl(trimmedUrl);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.currentTarget.value);
  };

  const handleSelectEvent = (eventType: string) => {
    setEvents([...events, eventType]);
  };

  const handleDeselectEvent = (eventType: string) => {
    setEvents(events.filter((event) => event !== eventType));
  };

  const handleAddEndpointButtonClick = () => {
    onSubmit({
      url,
      description,
      events,
      active,
      documentTypeIds,
    });
  };

  const [
    handleUrlBlur,
    handleUrlFocus,
    urlErrorMessage,
    isUrlFieldValid,
  ] = useInputValidation({
    validators: URL_VALIDATORS,
    value: url,
    required: true,
  });

  const isEndpointButtonDisabled = !isUrlFieldValid;

  const documentTypeOptions = documentTypes?.map(({ name, id }) => ({ name, value: id })) || [];

  return (
    <PopUp usePortal={usePortal} title="Add Endpoint" closePopUp={onClose}>
      <PopUpContent>
        <TextInput
          labelTitle="URL"
          required
          placeholder="https://yourdomain.com"
          onChange={handleUrlChange}
          value={url}
          onFocus={handleUrlFocus}
          onBlur={handleUrlBlur}
          errorMessage={urlErrorMessage}
        />
        <TextInput
          labelTitle="Description"
          onChange={handleDescriptionChange}
          value={description}
        />
        <EventTypeMultiselect
          placeholder={events.length === 0 ? 'All Events' : undefined}
          id="listeningFor"
          labelTitle="Listening For"
          selectedEventTypes={events}
          onSelectEventType={handleSelectEvent}
          onDeselectEventType={handleDeselectEvent}
        />
        <AutoCompletionMultiselect
          options={documentTypeOptions}
          onDeleteOption={(idToDelete) => setDocumentTypeIds((ids) => ids.filter((id) => id !== idToDelete))}
          onSelect={(id) => setDocumentTypeIds((ids) => [...ids, id])}
          selectedOptions={documentTypeIds}
          placeholder={documentTypeIds.length === 0 ? 'All Document Types' : undefined}
          id="documentTypes"
          labelTitle="Document Type"
          getOptionByOptionValue={(value) => documentTypeOptions.find((option) => option.value === value)}
          placeholderIsAlwaysVisible
        />
        <div className={styles.statusCheckboxContainer}>
          <label className={styles.label}>Status</label>
          <CustomCheckbox
            checked={active}
            onChange={(event, checked) => setActive(checked)}
            containerClassName={styles.checkboxContainer}
            label="Set endpoint as Active"
          />
        </div>
        <Button
          kind="primary"
          size="form"
          disabled={isEndpointButtonDisabled}
          className={styles.addEndpointButton}
          onClick={handleAddEndpointButtonClick}
          isLoading={isCreating}
        >
          Add Endpoint
        </Button>
        <Button
          kind="secondary"
          size="form"
          onClick={onClose}
        >
          No, Go Back
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default CreateEndpointPopup;
