import { FC } from 'react';
import { NullableItems } from 'pagination';
import {
  WebhookEndpoint,
  WebhookEndpointSortingField,
  WebhookEndpointSortingType,
} from 'api/WebhookEndpointsApi';
import useSorting from 'hooks/useSorting';
import Table, { TableHead, TableHeadCell, TableBody, TableActionCell, TableBodyContent } from 'components/Table';
import { WebhookEndpointsColumnSize } from './TableColumnSize';
import SkeletonEndpointItemRow from './SkeletonEndpointItemRow';
import EndpointItemRow from './EndpointItemRow';
import { User } from 'api/UserApi';

interface EndpointsTableProps {
  source: NullableItems<WebhookEndpoint>;
  sortingType: WebhookEndpointSortingType;
  users: User[]
  onSort: (field: WebhookEndpointSortingField, ascending: boolean) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onStatusChange: (id: string, active: boolean) => void;
}

const EndpointsTable: FC<EndpointsTableProps> = ({
  source,
  sortingType,
  users,
  onSort,
  onEdit,
  onDelete,
  onStatusChange,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderSkeletonTableRow = (index: number) => <SkeletonEndpointItemRow key={index} />;

  const renderTableRow = (item: WebhookEndpoint) => {
    const user = users.find(({ id }) => id === item.updatedBy)
    return (
      <EndpointItemRow
        key={item.id}
        webhookEndpoint={item}
        user={user}
        onClick={() => onEdit(item.id)}
        onDelete={onDelete}
        onEdit={onEdit}
        onStatusChange={onStatusChange}
      />
    );
  };

  return (
    <>
      <Table>
        <TableHead sticky>
          <TableHeadCell
            width={WebhookEndpointsColumnSize.Url}
            ascending={getSortingType(WebhookEndpointSortingField.Url)}
            onClick={() => changeSorting(WebhookEndpointSortingField.Url)}
          >
            Url
          </TableHeadCell>
          <TableHeadCell
            width={WebhookEndpointsColumnSize.Description}
            ascending={getSortingType(WebhookEndpointSortingField.Description)}
            onClick={() => changeSorting(WebhookEndpointSortingField.Description)}
          >
            Description
          </TableHeadCell>
          <TableHeadCell width={WebhookEndpointsColumnSize.ListeningFor}>
            Listening For
          </TableHeadCell>
          <TableHeadCell width={WebhookEndpointsColumnSize.DocumentType}>
            Document Type
          </TableHeadCell>
          <TableHeadCell
            width={WebhookEndpointsColumnSize.Status}
            ascending={getSortingType(WebhookEndpointSortingField.Status)}
            onClick={() => changeSorting(WebhookEndpointSortingField.Status)}
          >
            Status
          </TableHeadCell>
          <TableHeadCell
            width={WebhookEndpointsColumnSize.UpdatedAt}
            ascending={getSortingType(WebhookEndpointSortingField.UpdatedAt)}
            onClick={() => changeSorting(WebhookEndpointSortingField.UpdatedAt)}
          >
            Updated
          </TableHeadCell>
          <TableActionCell />
        </TableHead>
        <TableBody>
          <TableBodyContent
            renderSkeletonTableRow={renderSkeletonTableRow}
            renderTableRow={renderTableRow}
            rows={source}
          />
        </TableBody>
      </Table>
    </>
  );
};

export default EndpointsTable;
