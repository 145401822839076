import Pagination from 'pagination';
import { ReduxState } from 'types/ReduxState';
import { UsersSortingType, UserStatus } from 'api/UserApi';
import { PermissionGroup } from 'enums/PermissionGroup';

export const USERS_PER_PAGE_DEFAULT = 15;

export interface UsersDashboardPaginationParams {
  search: string;
  sortingType: UsersSortingType;
  permissionGroup?: PermissionGroup;
  isMfaEnabled?: boolean;
  isEmailVerified?: boolean;
  statuses: UserStatus[];
}

const usersDashboardPagination = Pagination<ReduxState, string, UsersDashboardPaginationParams>(
  ['generalSettings', 'ui', 'usersDashboard'],
  USERS_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
);

export default usersDashboardPagination;
