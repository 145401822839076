import React, { FC } from 'react';
import { AxisBottom } from '@visx/axis';
import { AnyD3Scale } from '@visx/scale';
import styles from 'components/Charts/components/components.module.scss';

interface ChartBottomAxisProps {
  x?: number;
  y?: number;
  padding?: number;
  scale: AnyD3Scale;
  formatValue: (value: number) => string;
  forceStartXAxisValue?: number;
  forceEndXAxisValue?: number;
}

type TextAnchorType = 'middle' | 'start' | 'end';

const LABEL_OFFSET_Y = 11;

const ChartBottomAxis: FC<ChartBottomAxisProps> = ({
  x,
  y,
  scale,
  padding = 0,
  formatValue,
  forceStartXAxisValue,
  forceEndXAxisValue,
}) => {
  const domain = scale.domain();

  const tickValues = domain.length > 1 ? [domain[0], domain[domain.length - 1]] : domain;

  const getLabelX = (value: number, index: number) => {
    const labelX = scale(value) || 0;

    return index === 0 ? labelX : labelX + padding * 2;
  };

  const getLabelAnchor = (index: number): TextAnchorType => {
    if (tickValues.length === 1) {
      return 'middle';
    }

    return index === 0 ? 'start' : 'end';
  };

  const handleFormatValue = (value: number, index: number) => {
    const forceValue = index === 0 ? forceStartXAxisValue : forceEndXAxisValue;

    return formatValue(forceValue || value);
  };

  return (
    <AxisBottom
      left={x}
      top={y}
      scale={scale}
      tickValues={tickValues}
      tickFormat={handleFormatValue}
      tickLabelProps={(value, index) => ({
        x: getLabelX(value, index),
        y: LABEL_OFFSET_Y,
        textAnchor: getLabelAnchor(index),
        verticalAnchor: 'start',
        fontSize: styles.textSize,
        fontFamily: styles.fontFamily,
        fontWeight: styles.textWeight,
        fill: styles.textColor,
      })}
      hideTicks
      stroke={styles.lineColor}
      hideAxisLine
    />
  );
};

export default ChartBottomAxis;
