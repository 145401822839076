import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

export enum Colors {
  Blue = '#1e95f8',
  LightBlue = '#e8f4ff',
  White = '#fff',
}

interface SpinnerProps extends CircularProgressProps {
  background?: 'light' | 'dark';
  rootClassName?: string;
}

const useStylesSpinner = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    top: {
      animationDuration: '3000ms',
      position: 'absolute',
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

const Spinner: FC<SpinnerProps> = ({ background, className, rootClassName, size, thickness, ...props }) => {
  const classes = useStylesSpinner();

  const getSpinnerBackgroundColor = (): Colors | string => {
    if (background === 'light') {
      return Colors.LightBlue;
    }
    return Colors.White;
  };

  return (
    <div className={clsx(classes.root, rootClassName)}>
      <CircularProgress
        variant="determinate"
        size={size ?? 36}
        thickness={thickness ?? 5}
        {...props}
        value={100}
        style={{ color: getSpinnerBackgroundColor() }}
      />
      <CircularProgress
        variant="indeterminate"
        className={clsx(classes.top, className)}
        classes={{
          circle: classes.circle,
        }}
        size={size ?? 36}
        thickness={thickness ?? 5}
        style={{ color: Colors.Blue }}
        {...props}
      />
    </div>
  );
};

export default Spinner;
