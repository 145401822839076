import useRouteBuilder from 'hooks/useRouteBuilder';
import productSectionList from 'components/LeftNav/productSectionList';
import urlJoin from 'url-join';
import { NavigationLinkIdType } from 'enums/NavigationLinkName';

export type LeftNavigationState = {
  activeNavigationLinkId?: NavigationLinkIdType;
  activeNestedLinkId?: NavigationLinkIdType;
  activeNestedLinkTitle?: string;
  activeNavigationLinkTitle?: string;
  noLinks?: boolean;
};

export const getLeftNavigationStateByLocation = (location: string): LeftNavigationState => {
  const activeSection = productSectionList.find((section) => {
    return location.startsWith(section.route);
  });

  const activeNestedLink = activeSection?.nestedLinks?.find(({ route }) => {
    return location.startsWith(urlJoin(activeSection.route, route));
  });

  return {
    activeNavigationLinkId: activeSection?.id,
    activeNavigationLinkTitle: activeSection?.title,
    activeNestedLinkId: activeNestedLink?.id,
    activeNestedLinkTitle: activeNestedLink?.title,
  };
};

export const useCloseContextualView = (queryAttributes?: string[] | string) => {
  const routeBuilder = useRouteBuilder();

  return () => {
    if (!queryAttributes) {
      routeBuilder.go(routeBuilder.currentPathname);

      return;
    }

    const urlSearchParams = routeBuilder.currentQueryParams;

    const arrayQueryAttributes = Array.isArray(queryAttributes) ? queryAttributes : [queryAttributes];

    arrayQueryAttributes.forEach((attribute) => {
      urlSearchParams.delete(attribute);
    });

    routeBuilder
      .withParams(urlSearchParams)
      .go(routeBuilder.currentPathname);
  }
};

export const useGenerateContextualViewLink = (...queryAttributes: string[]) => {
  const routeBuilder = useRouteBuilder();

  return (...queryAttributeValues: string[]) => {
    const urlSearchParams = routeBuilder.currentQueryParams;

    queryAttributes.forEach((queryAttribute, index) => {
      urlSearchParams.set(queryAttribute, queryAttributeValues[index] || '');
    })

    return `?${urlSearchParams.toString()}`;
  };
};

export const useOpenContextualView = (...queryAttributes: string[]) => {
  const routeBuilder = useRouteBuilder();
  const generateContextualViewLink = useGenerateContextualViewLink(...queryAttributes);

  return (...queryAttributeValues: string[]) => {
    routeBuilder.go(routeBuilder.currentPathname, generateContextualViewLink(...queryAttributeValues));  };
};
