import { lookup } from 'mime-types';

const OUTPUT_IMAGE_FORMAT = lookup('.png') || '';

const canvasToBlob = (canvas: HTMLCanvasElement, mimeType = OUTPUT_IMAGE_FORMAT): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject();
      }
    }, mimeType);
  });
};

export default canvasToBlob;
