import { UploadImagePopupStep } from 'components/UploadImagePopup/UploadImagePopup';
import notification from 'handlers/notification/notificationActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { closeMyAccountPopup, deleteAvatar, uploadAvatar } from 'MyAccount/ActionCreator';
import { MyAccountPopupType } from 'MyAccount/Types';
import ChangeEmailPopup from 'pages/MyAccount/ChangeEmailPopup';
import ChangePasswordPopup from 'pages/MyAccount/ChangePasswordPopup';
import DisablePhoneVerificationPopup from 'pages/MyAccount/DisablePhoneVerificationPopup';
import MyAccountUploadAvatarPopup from 'pages/MyAccount/MyAccountUploadAvatarPopup';
import SetupPhoneVerificationPopup from 'pages/MyAccount/SetupPhoneVerificationPopup';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { getCurrentUser } from 'Users/Thunks';
import { ImageParams } from 'api/UserApi';
import { detectCountryCode } from 'utils/detect-country';

const MyAccountOverlays: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const phoneNumberFormat = detectCountryCode() || '';

  const { popup, avatar } = useSelector((state: ReduxState) => state.myAccount);
  const currentUser = useSelector((state: ReduxState) => state.users.currentUser);

  const closePopup = () => {
    dispatch(closeMyAccountPopup());
  };

  const handleSaveAvatar = async (file: Blob, croppedFile: Blob, params: ImageParams) => {
    await dispatchWithUnwrap(uploadAvatar({
      avatar: file,
      croppedAvatar: croppedFile,
      params,
    }));

    await dispatchWithUnwrap(getCurrentUser());

    notification.createNotification('Your profile picture has been updated.', 'success', dispatch);

    dispatch(closeMyAccountPopup());
  };

  const handleDelete = async () => {
    await dispatchWithUnwrap(deleteAvatar());

    await dispatchWithUnwrap(getCurrentUser());

    notification.createNotification('Your profile picture has been deleted.', 'success', dispatch);

    dispatch(closeMyAccountPopup());
  };

  if (!currentUser) {
    return null;
  }

  if (popup === MyAccountPopupType.UploadAvatar) {
    return (
      <MyAccountUploadAvatarPopup
        onClose={closePopup}
        onDelete={handleDelete}
        onSave={handleSaveAvatar}
        initialStep={currentUser.avatarId && avatar !== null
          ? UploadImagePopupStep.Crop
          : UploadImagePopupStep.Upload}
        profileImage={avatar || undefined}
        profileImageParams={currentUser.avatarParams}
      />
    );
  }

  if (popup === MyAccountPopupType.ChangeEmail) {
    return (
      <ChangeEmailPopup
        onClose={closePopup}
      />
    );
  }

  if (popup === MyAccountPopupType.ChangePhone) {
    return (
      <SetupPhoneVerificationPopup
        phoneNumberFormat={phoneNumberFormat}
        onClose={closePopup}
      />
    );
  }

  if (currentUser.phone && popup === MyAccountPopupType.DeletePhone) {
    return (
      <DisablePhoneVerificationPopup
        phone={currentUser.phone}
        onClose={closePopup}
      />
    );
  }

  if (popup === MyAccountPopupType.ChangePassword) {
    return (
      <ChangePasswordPopup
        onClose={closePopup}
      />
    );
  }

  return null;
};

export default MyAccountOverlays;
