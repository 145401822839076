import HomeDashboardCardLayout from 'components/HomeDashboard/HomeDashboardCardLayout/HomeDashboardCardLayout';
import React, { FC, ReactElement, useRef } from 'react';
import styles from './DocumentTypeOverview.module.scss';
import { PerformanceOverviewDocumentType } from 'api/PerformanceOverviewApi';
import DocumentTypeChart from 'components/HomeDashboard/DocumentTypeOverview/DocumentTypeChart/DocumentTypeChart';
import clsx from 'clsx';

interface DocumentTypeOverviewProps {
  timeRangeSelect: ReactElement;
  documentTypes: PerformanceOverviewDocumentType[] | null;
  className?: string;
}

const DocumentTypeOverview: FC<DocumentTypeOverviewProps> = ({
  timeRangeSelect,
  documentTypes,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const renderChart = () => {
    return (
      <DocumentTypeChart
        documentTypes={documentTypes}
        targetRef={containerRef}
      />
    );
  };

  return (
    <HomeDashboardCardLayout
      title='Document Types'
      titleControl={timeRangeSelect}
      className={clsx(styles.container, className)}
      bodyClassName={clsx(!documentTypes && styles.noData)}
      ref={containerRef}
      showEmptyState={!documentTypes}
    >
      <>
        {renderChart()}
      </>
    </HomeDashboardCardLayout>
  );
};

export default DocumentTypeOverview;
