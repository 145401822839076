import React, { FC, useMemo, useState } from 'react';
import PasswordInput from 'components/PasswordInput';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import getMessage from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { changePassword } from 'MyAccount/ActionCreator';
import { validatePassword } from 'utils/validation/validation';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import Button from 'components/Button';
import useInputValidation from 'hooks/useInputValidation';

export interface ChangePasswordPopupProps {
  onClose: () => void;
}

const ChangePasswordPopup: FC<ChangePasswordPopupProps> = ({ onClose }) => {
  const dispatch = useDispatchWithUnwrap();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [isPasswordUpdateInProgress, handleUpdatePassword] = useAsyncActionCallback(async () => {
    await dispatch(changePassword({ oldPassword, newPassword }));

    notification.createNotification('Your password has been updated.', 'success', dispatch);

    onClose();
  }, [onClose, oldPassword, newPassword]);

  const newPasswordFieldValidators = useMemo(() => [(value: string) => {
    const messageType = validatePassword(value);

    return messageType === null ? '' : getMessage(messageType);
  }], []);

  const [
    handleOldPasswordFieldBlur,
    handleOldPasswordFieldFocus,
    oldPasswordFieldError,
    isOldPasswordFieldValid,
  ] = useInputValidation({
    value: oldPassword,
    required: true,
  });

  const [
    handleNewPasswordFieldBlur,
    handleNewPasswordFieldFocus,
    newPasswordFieldError,
    isNewPasswordFieldValid,
  ] = useInputValidation({
    value: newPassword,
    validators: newPasswordFieldValidators,
    required: true,
  });

  const handleChangeOldPasswordFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value);
  };

  const handleChangeNewPasswordFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const isSaveButtonDisabled = !isOldPasswordFieldValid || !isNewPasswordFieldValid;

  return (
    <PopUp title="Change Password" closePopUp={onClose}>
      <PopUpContent>
        <PasswordInput
          labelTitle="Old Password"
          required
          value={oldPassword}
          onBlur={handleOldPasswordFieldBlur}
          onFocus={handleOldPasswordFieldFocus}
          onChange={handleChangeOldPasswordFieldValue}
          errorMessage={oldPasswordFieldError}
        />
        <PasswordInput
          labelTitle="New Password"
          required
          value={newPassword}
          onChange={handleChangeNewPasswordFieldValue}
          onBlur={handleNewPasswordFieldBlur}
          onFocus={handleNewPasswordFieldFocus}
          errorMessage={newPasswordFieldError}
        />
        <Button
          size="form"
          kind="primary"
          onClick={handleUpdatePassword}
          disabled={isSaveButtonDisabled}
          isLoading={isPasswordUpdateInProgress}
          offset="form"
        >
          Save Password
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default ChangePasswordPopup;
