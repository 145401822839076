import { createAsyncThunk } from '@reduxjs/toolkit';
import { TableViewData } from 'api/Types';
import {
  CreateBatchDataExtractionParams,
  CreateDataExtractionParams,
  DataExtraction,
  DataExtractionFilters,
  DataExtractionSortingType,
} from 'api/DataExtractionApi';
import { DataWithActionOrigin, ActionOriginData } from 'types/action-origin';
import { DataExtractionActionType } from './DataExtractionsActionType';
import { dataExtractionsApi, dataExtractionExportApi } from 'stores/api';
import { downloadBlobFile } from '../utils/downloadBlobFile';
import { ExportFileType } from '../api/DataExtractionExportApi';

export const getDataExtractions = createAsyncThunk<
  TableViewData<DataExtraction>,
  DataWithActionOrigin<{ filters: DataExtractionFilters, sortingType: DataExtractionSortingType }>,
  { fulfilledMeta: DataWithActionOrigin<{ search?: string; }> }
>(
  DataExtractionActionType.GetDataExtractions,
  async ({
    filters,
    sortingType,
    actionOrigin,
  }, thunkApi) => {
    return thunkApi.fulfillWithValue(await dataExtractionsApi.search(filters, sortingType), {
      ...filters,
      actionOrigin,
    });
  },
);

export const getDataExtraction = createAsyncThunk<
  DataExtraction,
  string
>(
  DataExtractionActionType.GetDataExtraction,
  (id: string) => {
    return dataExtractionsApi.findById(id);
  },
);

export const createDataExtraction = createAsyncThunk<
  DataExtraction,
  DataWithActionOrigin<CreateDataExtractionParams>,
  { fulfilledMeta: ActionOriginData }
>(
  DataExtractionActionType.CreateDataExtraction,
  async ({
    documentTypeId,
    mode,
    useForAutoDetect,
    documentContentId,
    actionOrigin,
  }, thunkApi) => {
    return thunkApi.fulfillWithValue(await dataExtractionsApi.create({
      documentTypeId,
      mode,
      useForAutoDetect,
      documentContentId,
    }), { actionOrigin });
  },
);

export const createBatchDataExtraction = createAsyncThunk<
  DataExtraction,
  CreateBatchDataExtractionParams
>(
  DataExtractionActionType.CreateBatchDataExtraction,
  ({
    documentTypeId,
    mode,
    documentContentIds,
    useForAutoDetect,
  }) => {
    return dataExtractionsApi.createBatch({
      documentTypeId,
      mode,
      documentContentIds,
      useForAutoDetect,
    });
  },
);

export const deleteDataExtraction = createAsyncThunk<
  void,
  DataWithActionOrigin<{ id: string }>,
  { fulfilledMeta: ActionOriginData }
>(
  DataExtractionActionType.DeleteDataExtraction,
  async ({
    id,
    actionOrigin,
  }, thunkApi) => {
    return thunkApi.fulfillWithValue(await dataExtractionsApi.delete(id), { actionOrigin });
  },
);

export const exportDataExtraction = createAsyncThunk<
  void,
  { dataExtractionId: string, exportFileType: ExportFileType, resultId?: string }
>(
  DataExtractionActionType.ExportDataExtraction,
  async ({ dataExtractionId, exportFileType, resultId }) => {
    const response = await dataExtractionExportApi.export(dataExtractionId, {
      exportFileType,
      resultId,
    });

    downloadBlobFile(response);
  },
);
