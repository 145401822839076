import BaseSystemApi from './BaseApi';

export enum DocumentTypeFieldDataType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Boolean = 'boolean',
  Object = 'object',
}

export interface DocumentTypeField {
  id: string;
  documentTypeId: string;
  name: string;
  dataType: DocumentTypeFieldDataType;
  isArray?: boolean;
  parent?: string;
  updatedAt: Date;
  updatedBy: string | null;
}

interface BaseCreateDocumentTypeFieldParams {
  name: string;
  dataType: DocumentTypeFieldDataType;
  parent?: string
  isArray?: boolean;
}

export interface CreateDocumentTypeFieldParams extends BaseCreateDocumentTypeFieldParams {
  documentTypeId: string;
}

export interface BatchCreateDocumentTypeFieldParams {
  documentTypeId: string;
  parent?: string;
  fields: Omit<BaseCreateDocumentTypeFieldParams, 'parent'>[];
}

export interface UpdateDocumentTypeFieldParams extends Partial<BaseCreateDocumentTypeFieldParams> {
  id: string
}

export interface BatchUpdateDocumentTypeFieldParams {
  documentTypeId: string;
  parent?: string;
  fields: UpdateDocumentTypeFieldParams[];
};

export interface DocumentTypeFieldApi {
  create(params: CreateDocumentTypeFieldParams): Promise<DocumentTypeField>;
  batchCreate(params: BatchCreateDocumentTypeFieldParams): Promise<DocumentTypeField[]>;
  find(documentTypeId: string): Promise<DocumentTypeField[]>;
  update(params: UpdateDocumentTypeFieldParams): Promise<DocumentTypeField>;
  batchUpdate(params: BatchUpdateDocumentTypeFieldParams): Promise<DocumentTypeField[]>;
  delete(id: string): Promise<void>;
}

export default class DocumentTypeFieldRestApi extends BaseSystemApi implements DocumentTypeFieldApi {
  protected resourceName = 'document-type-fields';

  create(params: CreateDocumentTypeFieldParams): Promise<DocumentTypeField> {
    return this.createResource<DocumentTypeField>(params);
  }

  batchCreate(params: BatchCreateDocumentTypeFieldParams): Promise<DocumentTypeField[]> {
    return this.fetch<DocumentTypeField[]>(`/${this.resourceName}/batch`, 'POST', params);
  }

  find(documentTypeId: string): Promise<DocumentTypeField[]> {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.set('documentTypeId', documentTypeId);

    return this.getResources<DocumentTypeField[]>(urlSearchParams);
  }

  update(params: UpdateDocumentTypeFieldParams): Promise<DocumentTypeField> {
    const {id, ...restParams} = params;

    return this.updateResource<DocumentTypeField>(id, restParams);
  }

  batchUpdate(params: BatchUpdateDocumentTypeFieldParams): Promise<DocumentTypeField[]> {
    return this.fetch<DocumentTypeField[]>(`/${this.resourceName}/batch`, 'PUT', params);
  }

  delete(id: string): Promise<void> {
    return this.deleteResource<void>(id);
  }
}
