import React, { FC } from 'react';
import { Switch, Route } from 'react-router';
import SignIn from 'pages/SignIn';
import SecurePhoneAuthentication from 'pages/SecurePhoneAuthentication';
import ResetPassword from 'pages/ResetPassword';
import ForgotPassword from 'pages/ForgotPassword';
import CreateAccountInvite from 'pages/CreateAccountInvite';
import { AppRoutes } from 'routes/RouteBuilder';
import CreateAccount from 'pages/CreateAccount';
import UserNotActive from 'pages/UserNotActive';
import LoggedOut from 'pages/LoggedOut';
import EmailVerification from '../pages/EmailVerification/EmailVerification';
import SubscriptionPastDue from '../pages/SubscriptionPastDue';
import SubscriptionCancelled from '../pages/SubscriptionCancelled';

const Auth: FC = () => {
  return (
    <Switch>
      <Route path={AppRoutes.auth.signIn}>
        <SignIn />
      </Route>
      <Route path={AppRoutes.auth.mfa}>
        <SecurePhoneAuthentication />
      </Route>
      <Route path={AppRoutes.auth.forgotPassword}>
        <ForgotPassword />
      </Route>
      <Route path={AppRoutes.auth.resetPassword}>
        <ResetPassword />
      </Route>
      <Route path={AppRoutes.auth.acceptInvite}>
        <CreateAccountInvite />
      </Route>
      <Route path={AppRoutes.auth.createAccount}>
        <CreateAccount />
      </Route>
      <Route path={AppRoutes.auth.inactiveUser}>
        <UserNotActive />
      </Route>
      <Route path={AppRoutes.auth.emailVerification}>
        <EmailVerification />
      </Route>
      <Route path={AppRoutes.auth.loggedOut}>
        <LoggedOut />
      </Route>
      <Route path={AppRoutes.auth.pastDueSubscription}>
        <SubscriptionPastDue />
      </Route>
      <Route path={AppRoutes.auth.cancelledSubscription}>
        <SubscriptionCancelled />
      </Route>
    </Switch>
  );
};

export default Auth;
