import { DataViewSortingType } from 'api/Types';
import { orderBy } from 'lodash';
import { useState } from 'react';

interface SortingResponse<SourceItemType extends unknown> {
  items: SourceItemType[] | undefined;
  changeSorting: (field: keyof SourceItemType) => () => void;
  getSortingType: (field: keyof SourceItemType) => boolean | undefined;
}

const getSavedSorting = <SourceItemType>(storageKey: string): DataViewSortingType<keyof SourceItemType> | null => {
  const sortingJson = localStorage.getItem(storageKey);

  return sortingJson
    ? JSON.parse(sortingJson) as DataViewSortingType<keyof SourceItemType>
    : null;
};

const useLocalSortingWithSaving = <SourceItemType extends unknown>(
  source: SourceItemType[] | undefined,
  defaultField: keyof SourceItemType,
  storageKey: string,
): SortingResponse<SourceItemType> => {
  const [sorting, setSorting] = useState<DataViewSortingType<keyof SourceItemType>>(getSavedSorting(storageKey) || {
    field: defaultField,
    ascending: false,
  });

  const changeSorting = (field: keyof SourceItemType) => () => {
    const newSorting = { field, ascending: sorting.field === field ? !sorting.ascending : true };

    setSorting(newSorting);

    localStorage.setItem(storageKey, JSON.stringify(newSorting));
  };

  const getSortingType = (field: keyof SourceItemType) => (sorting.field === field ? sorting.ascending : undefined);

  const sortedSource = source && orderBy(source, [sorting.field as string], [sorting.ascending ? 'asc' : 'desc']);

  return {
    items: sortedSource,
    changeSorting,
    getSortingType,
  };
};

export default useLocalSortingWithSaving;
