import React, { FC, useEffect, useState } from 'react';
import { env } from 'env';
import Avatar, { TextSize } from 'components/Avatar';
import clsx from 'clsx';

import styles from './UserAvatar.module.scss';

export interface UserAvatarProps {
  firstName?: string;
  lastName?: string;
  color?: string;
  imageUrl?: string | null;
  imageId?: string | null;
  textClassName?: string;
  size?: TextSize;
  tooltip?: boolean;
  tooltipText?: string;
  className?: string;
  active?: boolean;
  online?: boolean;
  onClick?: () => void;
  errorState?: JSX.Element;
}

type ImageState = 'no-image' | 'overwriting' | 'loading' | 'error' | 'loaded';

const UserAvatar: FC<UserAvatarProps> = ({
  firstName,
  lastName,
  imageUrl,
  imageId,
  size = 'medium',
  tooltip = false,
  className,
  active,
  online,
  onClick,
  textClassName,
  tooltipText,
  color,
  errorState,
}) => {
  const url = imageUrl || imageId
    ? imageUrl || `${env.REACT_APP_API_URL}/assets/${imageId}`
    : null;

  const [imageState, setImageState] = useState<ImageState>('no-image');

  const handleImageLoadError = () => {
    setImageState('error');
  };

  const handleImageLoadSuccess = () => {
    setImageState('loaded');
  };

  useEffect(() => {
    if (imageState === 'loaded') {
      setImageState(url ? 'overwriting' : 'no-image');
    } else {
      setImageState(url ? 'loading' : 'no-image');
    }
  }, [url]);

  const showName = !url || imageState === 'error';

  return (
    <Avatar
      size={size}
      tooltip={tooltip ? tooltipText || `${firstName} ${lastName}` : undefined}
      className={clsx(styles.avatar, className, active && styles.active)}
      color={color}
      hideColor={!showName || !!errorState}
      onClick={onClick}
    >
      <>
        {(url && imageState !== 'error') && (
          <img
            src={url!}
            alt="User Avatar"
            className={styles.avatarImage}
            onError={handleImageLoadError}
            onLoad={handleImageLoadSuccess}
          />
        )}
        {showName && !errorState && (
          <p className={clsx(styles.avatarText, textClassName)}>
            {firstName && firstName[0]}
            {lastName && lastName[0]}
          </p>
        )}
        {showName && errorState}
        {online !== undefined && <div className={clsx(styles.status, online && styles.online)} />}
      </>
    </Avatar>
  );
};

export default UserAvatar;
