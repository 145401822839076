import { User } from './UserApi';
import BaseSystemApi from './BaseApi';

export interface UpdateAccountDetailsParams {
  firstName?: string;
  lastName?: string;
  notificationsBlacklist?: string[];
}

export interface IAccountDetailsApi {
  updateAccountDetails(userInfo: UpdateAccountDetailsParams): Promise<User>;
  requestPhoneCode(phone: string): Promise<void>;
  enablePhoneAuthentication(code: string): Promise<User>;
  disablePhoneAuthentication(code: string): Promise<User>;
  sendChangeEmailCode(email: string): Promise<void>;
  changeEmail(code: string): Promise<User>;
  changePassword(oldPassword: string, newPassword: string): Promise<void>;
}

export default class AccountDetailsRestApi extends BaseSystemApi<User> implements IAccountDetailsApi {
  protected resourceName = '/account-details';

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    await this.fetch(`${this.resourceName}/password`, 'PUT', { oldPassword, newPassword });
  }

  public async sendChangeEmailCode(email: string): Promise<void> {
    await this.fetch(`${this.resourceName}/emails`, 'POST', { email });
  }

  public async changeEmail(code: string): Promise<User> {
    return this.fetch<User>(`${this.resourceName}/emails/${code}`, 'PUT');
  }

  public updateAccountDetails(params: UpdateAccountDetailsParams): Promise<User> {
    return this.fetch<User>(`${this.resourceName}`, 'PUT', params);
  }

  public async requestPhoneCode(phone: string | undefined) {
    return this.fetch<void>(`${this.resourceName}/phone-authentication-requests`, 'POST', {
      phone,
    });
  }

  public async enablePhoneAuthentication(code: string) {
    return this.fetch<User>(`${this.resourceName}/phone-authentications/${code}`, 'PUT');
  }

  public async disablePhoneAuthentication(code: string) {
    return this.fetch<User>(`${this.resourceName}/phone-authentications/${code}`, 'DELETE');
  }
}
