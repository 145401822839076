import React, { FC } from 'react';
import clsx from 'clsx';
import UserAvatar from 'components/UserAvatar';

import styles from './UserCard.module.scss';

export interface UserCardAttrs {
  firstName: string;
  lastName: string;
  color?: string;
  avatarUrl?: string;
}

interface UserCardTypes extends UserCardAttrs {
  onLogOutClick?: () => void;
  noMargin?: boolean;
  className?: string;
}

const UserCard: FC<UserCardTypes> = ({ firstName, lastName, avatarUrl, noMargin = false, color, className, onLogOutClick }) => {
  return (
    <div className={clsx(styles.userCardContainer, noMargin && styles.noMargin, className)}>
      <div className={styles.imgContainer}>
        <UserAvatar
          textClassName={styles.avatarText}
          color={color || styles.userImgBackground}
          size="medium" firstName={firstName}
          lastName={lastName}
          imageUrl={avatarUrl}
        />
      </div>
      <div className={styles.userInfo}>
        <p className={styles.userName}>{`${firstName} ${lastName}`}</p>
        <button type="button" className={styles.link} onClick={onLogOutClick}>
          <p className={styles.linkText}>Log Out</p>
        </button>
      </div>
    </div>
  );
};

export default UserCard;
