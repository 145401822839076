import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/ReduxState';
import { isFulfilled } from '@reduxjs/toolkit';
import { cancelInvitation, getUsers, inviteUser, updateUser } from 'Users/Thunks';
import { pagination, usersDashboardActionOrigin } from './UsersDashboardStore';

const usersDashboardUpdatesMiddleware = (({
  dispatch,
  getState,
}: MiddlewareAPI<Dispatch<AnyAction>, ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (!isFulfilled(inviteUser)(action) && !isFulfilled(updateUser)(action) && !isFulfilled(cancelInvitation)(action)) {
    return result;
  }

  const state = getState();
  const { filters, sortingType } = state.generalSettings.ui.usersDashboard;

  dispatch(getUsers({
    filters: {
      search: filters.search,
      statuses: filters.statuses,
      permissionGroup: filters.permissionGroup ?? undefined,
      isMfaEnabled: filters.isMfaEnabled ?? undefined,
      count: pagination.getDefaultItemsCountPerPage(),
      offset: 0,
    },
    sortingType,
    actionOrigin: usersDashboardActionOrigin,
  }) as unknown as AnyAction);

  return result;
}) as Middleware;

export default usersDashboardUpdatesMiddleware;
