import { createAsyncThunk, createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  OrganizationInfo,
  UpdateOrganizationParams,
} from 'api/OrganizationApi';
import OrganizationActionType from './ActionTypes';
import { ReduxState } from 'types/ReduxState';
import { organizationsApi } from 'stores/api';

export const getOrganizationInfo = createAsyncThunk(
  OrganizationActionType.GetOrganizationInfo,
  async () => {
    return organizationsApi.getCurrent();
  }, {
    condition: (arg: void, thunkApi) => {
      return !(thunkApi.getState() as ReduxState).organization.isOrganizationInfoLoading;
    },
  },
);

export const updateOrganizationInfo = createAsyncThunk(
  OrganizationActionType.UpdateOrganizationInfo,
  async (organizationInfo: UpdateOrganizationParams) => {
    return organizationsApi.update(organizationInfo);
  },
);

export interface OrganizationState {
  detailedInfo: OrganizationInfo | null;
  isOrganizationLoading: boolean;
  isOrganizationInfoLoading: boolean;
}

const initialState: OrganizationState = {
  detailedInfo: null,
  isOrganizationLoading: false,
  isOrganizationInfoLoading: false,
};

const organizationReducer = createReducer<OrganizationState>(initialState, (builder) => {
  builder
    .addCase(getOrganizationInfo.pending, (state) => {
      state.isOrganizationInfoLoading = true;
    })
    .addMatcher(isAnyOf(getOrganizationInfo.fulfilled, getOrganizationInfo.rejected), (state) => {
      state.isOrganizationInfoLoading = false;
    })
    .addMatcher(
      isAnyOf(getOrganizationInfo.pending, updateOrganizationInfo.pending),
      (state) => {
        state.isOrganizationLoading = true;
      },
    )
    .addMatcher(
      isAnyOf(
        getOrganizationInfo.fulfilled,
        updateOrganizationInfo.fulfilled,
      ),
      (state, action) => {
        state.detailedInfo = action.payload;
        state.isOrganizationLoading = false;
      },
    );
});

export default organizationReducer;
