import React, { FC, useEffect, useState } from 'react';
import { TableHeadCell, TableRow } from '../../../../../../Table';
import OverflowedText from '../../../../../../OverflowedText';
import RowActions, { RowActionsContainer } from '../../../../../../RowActions';
import LoaderWithState, { LoaderState } from '../../../../../../LoaderWithState';
import { IFileState } from '../../../validateFileState';
import { CrossIcon, SuccessIcon } from '../../../../../../../static/images';
import { TableColumnSizes } from '../TableColumnSizes';
import styles from './FileTableRow.module.scss';

export interface IExtendedFileState extends IFileState {
  error: string | null;
  state: LoaderState;
}

interface IFileTableRowProps {
  file: IExtendedFileState;
  onDelete: (fileId: string) => void;
}

const FileTableRow: FC<IFileTableRowProps> = ({
  file,
  onDelete,
}) => {
  const [
    iconAnimationEnabled,
    setIconAnimationEnabled,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIconAnimationEnabled(file.state === LoaderState.Updating);
  }, []);

  const renderStateIcon = (state: LoaderState) => {
    if (iconAnimationEnabled) {
      return <LoaderWithState className={styles.fileIcon} state={state} />;
    }

    return state === LoaderState.Success ? <SuccessIcon /> : <CrossIcon />;
  };

  return (
    <TableRow key={file.id} useActions>
      <TableHeadCell pixels width={TableColumnSizes.Icon} className={styles.fileIconContainer}>
        {renderStateIcon(file.state)}
      </TableHeadCell>
      <TableHeadCell
        width={file.error ? TableColumnSizes.Name : TableColumnSizes.NameWithoutError}
        className={styles.nameCellContainer}
      >
        <OverflowedText open className={styles.name}>
          {file.file.name}
        </OverflowedText>
      </TableHeadCell>
      {file.error && <TableHeadCell width={TableColumnSizes.Error}>
        <OverflowedText open className={styles.error}>
          {file.error}
        </OverflowedText>
      </TableHeadCell>}
      <RowActionsContainer>
        <RowActions
          actions={[
            {
              title: 'Delete File',
              handler: () => onDelete(file.id),
              danger: true,
              disabled: file.state === LoaderState.Updating,
            },
          ]}
        />
      </RowActionsContainer>
    </TableRow>
  );
};

export default FileTableRow;
