import {
  ExtractionHistoryImage,
  DataTypeImage,
  DevelopersImage,
  ExtractDataImage,
} from 'static/images';
import NavigationLinkName, { NavigationLinkIdMap } from '../../enums/NavigationLinkName';
import { Link } from '../MainLayout/leftNavReducer/LeftNavTypes';

const productSectionList: Link[] = [
  {
    id: NavigationLinkIdMap.ExtractData,
    title: NavigationLinkName.ExtractData,
    icon: ExtractDataImage,
    iconType: 'stroke',
    route: '/extract-data',
  },
  {
    id: NavigationLinkIdMap.DocumentTypes,
    title: NavigationLinkName.DocumentTypes,
    icon: DataTypeImage,
    iconType: 'stroke',
    route: '/document-types',
  },
  {
    id: NavigationLinkIdMap.ExtractionHistory,
    title: NavigationLinkName.ExtractionHistory,
    icon: ExtractionHistoryImage,
    iconType: 'stroke',
    route: '/extraction-history',
  },
  {
    id: NavigationLinkIdMap.Developers,
    title: NavigationLinkName.Developers,
    icon: DevelopersImage,
    iconType: 'stroke',
    route: '/developers',
    nestedLinks: [
      {
        id: NavigationLinkIdMap.ApiCredentials,
        title: NavigationLinkName.ApiCredentials,
        route: '/api-credentials',
      },
      {
        id: NavigationLinkIdMap.Webhooks,
        title: NavigationLinkName.Webhooks,
        route: '/webhooks',
      },
      {
        id: NavigationLinkIdMap.IpAddressWhitelist,
        title: NavigationLinkName.IpAddressWhitelist,
        route: '/ip-address-whitelist',
      },
    ],
  },
];

export default productSectionList;
