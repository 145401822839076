import React, { FC } from 'react';
import { DashboardFiltersState } from 'Dashboard/Types';
import { changeFilters } from 'Dashboard/ActionCreator';
import { useDispatch } from 'react-redux';
import PerformanceOverview from './PerformanceOverview';
import styles from './HomeDashboard.module.scss';
import { PerformanceOverviewDashboardChart } from 'api/PerformanceOverviewMapper';
import { PerformanceOverviewDocumentType } from 'api/PerformanceOverviewApi';
import DocumentTypeOverview from 'components/HomeDashboard/DocumentTypeOverview/DocumentTypeOverview';
import RunExtractionFormWrapper from 'components/HomeDashboard/RunExtractionForm/RunExtractionFormWrapper';
import TimeRangeInput from '../TimeRangeInput';

interface HomeDashboardProps {
  performanceOverview: PerformanceOverviewDashboardChart | null;
  filters: DashboardFiltersState;
  documentTypes: PerformanceOverviewDocumentType[] | null;
}

const HomeDashboard: FC<HomeDashboardProps> = ({
  performanceOverview,
  filters,
  documentTypes,
}) => {
  const dispatch = useDispatch();

  const handleFiltersChange = (data: Partial<DashboardFiltersState>) => {
    dispatch(changeFilters({
      ...filters,
      ...data,
    }));
  };

  return (
    <div className={styles.dashboard}>
      <div className={styles.mainContent}>
        <div className={styles.leftColumn}>
          <RunExtractionFormWrapper
            className={styles.runExtractionForm}
          />
        </div>
        <div className={styles.rightColumn}>
          <PerformanceOverview
            className={styles.card}
            performanceOverview={performanceOverview}
            timeRangeSelect={
              <TimeRangeInput
                isLoading={false}
                selectedValue={filters.performanceOverview}
                className={styles.timeRangeInput}
                onChange={(timeRange) => handleFiltersChange({
                  performanceOverview: timeRange,
                })}
              />
            }
          />
          <DocumentTypeOverview
            className={styles.card}
            documentTypes={documentTypes && documentTypes.length > 0 ? documentTypes : null}
            timeRangeSelect={
              <TimeRangeInput
                isLoading={false}
                selectedValue={filters.documentTypes}
                className={styles.timeRangeInput}
                onChange={(timeRange) => handleFiltersChange({
                  documentTypes: timeRange,
                })}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

export default HomeDashboard;
