import React, { ChangeEvent, FC, useState } from 'react';
import clsx from 'clsx';
import getAvatarUrl from 'api/utils/getAvatarUrl';
import Button from 'components/Button';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import FlexHorizontalSpacer from 'components/FlexHorizontalSpacer/FlexHorizontalSpacer';
import LinkButton from 'components/LinkButton';
import validatePersonalDetails, {
  PersonalDetailsValidationResult,
} from 'components/MyAccountProfile/PersonalDetails/validatePersonalDetails';
import OverflowedText from 'components/OverflowedText';
import TextInput from 'components/TextInput';
import UserAvatar from 'components/UserAvatar';
import { User } from 'api/UserApi';
import { EditImage } from 'static/images';
import useHover from 'utils/useHover';
import { capitalize } from 'lodash';
import styles from './PersonalDetails.module.scss';

export interface UpdateParams {
  firstName?: string;
  lastName?: string;
}

interface PersonalDetailsProps {
  user: User;
  className: string;
  onUpdate: (params: UpdateParams) => Promise<void>;
  onEditAvatarClick: () => void;
}

const PersonalDetails: FC<PersonalDetailsProps> = ({ className, user, onUpdate, onEditAvatarClick }) => {
  const [isAvatarHovered, onMouseEnter, onMouseLeave] = useHover();
  const [isEdit, setIsEdit] = useState(false);

  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');

  const [
    errors,
    setErrors,
  ] = useState<PersonalDetailsValidationResult>({});

  const handleChange = (setValue: (value: string) => void, key: keyof PersonalDetailsValidationResult) => (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(event.target.value);

    setErrors({ ...errors, [key]: '' });
  };

  const handleBlur = (key: keyof PersonalDetailsValidationResult, value: string) => () => {
    const validationResult = validatePersonalDetails({ [key]: value });
    setErrors({ ...errors, [key]: validationResult[key] });
  };

  const renderReadView = () => {
    return (
      <div className={styles.readViewContainer}>
        <div
          className={styles.avatarContainer}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onEditAvatarClick}
        >
          <UserAvatar
            color={user.color}
            firstName={user.firstName || ''}
            lastName={user.lastName || ''}
            imageUrl={getAvatarUrl(user.avatarId)}
            size={26}
            className={styles.avatar}
          />
          {isAvatarHovered && (
            <div className={styles.hoverContent}>
              <EditImage />
            </div>
          )}
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.nameRow}>
            <OverflowedText useTooltip tooltipOnHover={`${user.firstName} ${user.lastName}`} className={styles.username}>
              {user.firstName} {user.lastName}
            </OverflowedText>
            {!isEdit && (
              <LinkButton className={styles.editLink} onClick={() => setIsEdit(true)}>
                <EditImage className={styles.editLinkIcon} />
                <p className={styles.editLinkText}>Edit</p>
              </LinkButton>
            )}
          </div>
          <div className={styles.role}>{capitalize(user.permissionGroup)}</div>
        </div>
      </div>
    );
  };

  const renderEditView = () => {
    return (
      <div className={styles.editViewContainer}>
        <TextInput
          labelTitle="First Name"
          value={firstName}
          onChange={handleChange(setFirstName, 'firstName')}
          onBlur={handleBlur('firstName', firstName)}
          errorMessage={errors.firstName}
          required
        />
        <FlexHorizontalSpacer />
        <TextInput
          labelTitle="Last Name"
          value={lastName}
          onChange={handleChange(setLastName, 'lastName')}
          onBlur={handleBlur('lastName', lastName)}
          errorMessage={errors.lastName}
          required
        />
      </div>
    );
  };

  const handleCloseEditor = () => {
    setIsEdit(false);
  };

  const isValid = () => {
    return Object.keys(validatePersonalDetails({ firstName, lastName })).length === 0;
  };

  const isDataChanged = () => {
    return user.firstName !== firstName || user.lastName !== lastName;
  };

  const handleSaveChanges = async () => {
    if (isValid()) {
      await onUpdate({
        firstName: firstName === user.firstName ? undefined : firstName,
        lastName: lastName === user.lastName ? undefined : lastName,
      });

      setIsEdit(false);
    }
  };

  const renderEditButtons = (containerClassName?: string) => {
    return (
      <div className={clsx(styles.controls, containerClassName)}>
        <Button
          className={styles.closeButton}
          size="default"
          kind="secondary"
          onClick={handleCloseEditor}
        >
          Close Editor
        </Button>
        <ButtonWithLoadingState
          size="default"
          kind="primary"
          disabled={!isValid() || !isDataChanged()}
          onClick={handleSaveChanges}
        >
          Save Changes
        </ButtonWithLoadingState>
      </div>
    )
  }

  return (
    <div className={clsx(styles.container, className)}>
      <div className={clsx(styles.firstRow)}>
        {renderReadView()}
        {isEdit && renderEditButtons(styles.buttonsDesktop)}
      </div>
      {isEdit && renderEditView()}
      {isEdit && renderEditButtons(styles.buttonsMobile)}
    </div>
  );
};

export default PersonalDetails;
