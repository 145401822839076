import DataExtractionRestApi from 'api/DataExtractionApi';
import DataExtractionResultRestApi from 'api/DataExtractionResultApi';
import DocumentParsingsRestApi from 'api/DocumentParsingsApi';
import OrganizationRestApi from 'api/OrganizationApi';
import responseHandlers from 'api/ResponseHandlers';
import UserInviteRestApi from 'api/UserInviteApi';
import UserRestApi from 'api/UserApi';
import AccountDetailsRestApi from 'api/AccountDetailsApi';
import AccountAvatarRestApi from 'api/AccountAvatarApi';
import PasswordConfirmationRestApi from 'api/PasswordConfirmationApi';
import DocumentTypeRestApi from 'api/DocumentTypeApi';
import DocumentTypeFieldRestApi from 'api/DocumentTypeFieldApi';
import PerformanceOverviewRestApi from 'api/PerformanceOverviewApi';
import PublicApiKeyApi from 'api/PublicApiKeyApi';
import PublicApiWhitelistApi from 'api/PublicApiWhitelistApi';
import PhoneCodeConfirmationRestApi from 'api/PhoneCodeConfirmationApi';
import BillingRestApi from 'api/BillingApi';
import WebhookEventsRestApi from 'api/WebhookEventsApi';
import WebhookEndpointsRestApi from 'api/WebhookEndpointsApi';
import EmailCodeConfirmationRestApi from 'api/EmailCodeConfirmationApi';
import PollingRestApi from 'api/PollingApi';
import DocumentTokenApi from 'api/DocumentTokenApi';
import DataExtractionExportRestApi from '../../api/DataExtractionExportApi';

export const dataExtractionsApi = new DataExtractionRestApi(responseHandlers);
export const dataExtractionExportApi = new DataExtractionExportRestApi(responseHandlers);
export const dataExtractionResultsApi = new DataExtractionResultRestApi(responseHandlers);
export const pollingApi = new PollingRestApi(responseHandlers);
export const billingApi = new BillingRestApi(responseHandlers);
export const documentParsingsApi = new DocumentParsingsRestApi(responseHandlers);
export const organizationsApi = new OrganizationRestApi(responseHandlers);
export const userInviteApi = new UserInviteRestApi(responseHandlers);
export const userApi = new UserRestApi(responseHandlers);
export const accountDetailsApi = new AccountDetailsRestApi(responseHandlers);
export const accountAvatarRestApi = new AccountAvatarRestApi(responseHandlers);
export const passwordConfirmationApi = new PasswordConfirmationRestApi(responseHandlers);
export const emailCodeConfirmationApi = new EmailCodeConfirmationRestApi(responseHandlers);
export const documentTypeApi = new DocumentTypeRestApi(responseHandlers);
export const documentTypeFieldApi = new DocumentTypeFieldRestApi(responseHandlers);
export const performanceOverviewApi = new PerformanceOverviewRestApi(responseHandlers);
export const publicApiKeyApi = new PublicApiKeyApi(responseHandlers);
export const publicApiWhitelistApi = new PublicApiWhitelistApi(responseHandlers);
export const phoneCodeConfirmationApi = new PhoneCodeConfirmationRestApi(responseHandlers);
export const webhookEventsApi = new WebhookEventsRestApi(responseHandlers);
export const webhookEndpointsApi = new WebhookEndpointsRestApi(responseHandlers);
export const documentTokenApi = new DocumentTokenApi(responseHandlers);
