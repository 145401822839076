import React, { forwardRef } from 'react';
import clsx from 'clsx';
import ButtonWithLoadingState, { ButtonWithLoadingStateProps } from '../../../ButtonWithLoadingState/ButtonWithLoadingState';

import styles from './AuthButton.module.scss';

const AuthButton = forwardRef<HTMLButtonElement, ButtonWithLoadingStateProps>((props, ref) => {
  return (
    <ButtonWithLoadingState
      {...props}
      className={clsx(styles.button, props.className)}
      loadingClassName={clsx(styles.loading, props.loadingClassName)}
      ref={ref}
    />
  );
});

export default AuthButton;
