import React, { FC } from 'react'
import { TableBodyCell, TableRow } from 'components/Table';
import DataExtractionStatusTag from 'components/DataExtractionStatusTag';
import { DataExtractionStatus } from 'api/DataExtractionApi';
import { MobileTableColumnSize } from '../../TableColumnSize';
import OverflowedText from 'components/OverflowedText';
import DocumentTypeLabel from 'components/DocumentTypeLabel';

import styles from './DataExtractionResultMobileRow.module.scss';
import { DataExtractionResult } from '../../../../../../api/DataExtractionResultApi';

interface DataExtractionMobileRowProps {
  className?: string;
  result: DataExtractionResult;
  onView: (dataExtractionResult: DataExtractionResult) => void;
}

const DataExtractionResultMobileRow: FC<DataExtractionMobileRowProps> = ({
  className,
  result,
  onView,
}) => {
  return (
    <TableRow
      onClick={() => onView(result)}
      className={className}
    >
      <TableBodyCell width={MobileTableColumnSize.Date} noPadding className={styles.fileNameCell}>
        <div className={styles.fileNameContainer}>
          <OverflowedText className={styles.overflowedText}>
            {result.originalFileName}
          </OverflowedText>
        </div>
        <div className={styles.documentTypeLabel}>
          <DocumentTypeLabel
            isProcessing={result.status === DataExtractionStatus.Processing}
            isAutoDetected={result.isAutoDetected}
            documentType={result.documentType?.name}
          />
        </div>
      </TableBodyCell>
      <TableBodyCell width={MobileTableColumnSize.Result} className={styles.resultCell}>
        <DataExtractionStatusTag status={result.status as DataExtractionStatus} className={styles.statusLabel} />
        <p className={styles.date}>{result.fileType.toUpperCase()}</p>
      </TableBodyCell>
    </TableRow>
  )
}

export default DataExtractionResultMobileRow;
