import React, { FC } from 'react';
import clsx from 'clsx';
import Header, { HeaderProps } from 'components/Base/Header/Header';
import QuestionIcon from 'components/QuestionIcon';
import styles from './HeaderWithTooltip.module.scss';

export interface HeaderWithTooltipProps extends HeaderProps {
  tooltip?: React.ReactNode;
  className?: string;
  containerClassName?: string;
}

const HeaderWithTooltip: FC<HeaderWithTooltipProps> = ({
  tooltip,
  containerClassName,
  ...headerProps
}) => (
  <div className={clsx(styles.headerWithTooltip, containerClassName)}>
    <Header {...headerProps} />
    {tooltip && <QuestionIcon size={24} className={styles.questionIcon} tooltip={tooltip} />}
  </div>
);

export default HeaderWithTooltip;
