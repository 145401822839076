import { createReducer } from '@reduxjs/toolkit';
import { getBillingInfo, getInvoices } from './Thunks';
import BillingPageState from './Types';

export const getInitialState = (): BillingPageState => ({
  billingInfo: null,
  invoices: null,
});

const billingPageReducer = createReducer<BillingPageState>(
  getInitialState(),
  (builder) => {
    builder.addCase(getBillingInfo.fulfilled, (
      state,
      action,
    ) => {
      state.billingInfo = action.payload;
    });
    builder.addCase(getInvoices.fulfilled, (
      state,
      action,
    ) => {
      state.invoices = action.payload;
    });
  },
);

export default billingPageReducer;
