import React, { PropsWithChildren, useState, useEffect } from 'react';
import clsx from 'clsx';
import useCachedForTransition from 'hooks/cachedForTransition';
import styles from './RightSidePopupLayout.module.scss';

export interface RightSidePopupLayoutProps {
  className?: string;
  useOffset?: boolean;
  onClose?: () => void;
}

const RightSidePopupLayout = ({ children, className, useOffset, onClose }: PropsWithChildren<RightSidePopupLayoutProps>) => {
  const [visibleChildren, onTransitionEnd] = useCachedForTransition(children);
  const [transitionEnabled, setTransitionEnabled] = useState(true);

  useEffect(() => {
    if (!children) {
      setTransitionEnabled(true);
    }
  }, [children]);

  const rightSidePopupOpenStyle = useOffset ? styles.rightSidePopupOpen : styles.rightSidePopupOpenWithTranslate;

  const handleContentWrapperTransitionEnd = (event: React.TransitionEvent<HTMLDivElement>) => {
    onTransitionEnd(event);

    if (event.currentTarget === event.target && children) {
      setTransitionEnabled(false);
    }
  };

  return (
    <>
      {visibleChildren && <div className={styles.overlay} onClick={onClose} />}
      <div
        style={{ transition: transitionEnabled ? undefined : 'none' }}
        className={clsx(styles.rightSidePopup, className, (children || !transitionEnabled) && rightSidePopupOpenStyle)}
        onTransitionEnd={handleContentWrapperTransitionEnd}
      >
        {visibleChildren}
      </div>
    </>
  );
};

export default RightSidePopupLayout;
