import React, { FC, useEffect, useMemo } from 'react'
import EndpointsDashboard from './EndpointsDashboard'
import { useDispatch } from 'react-redux';
import useRouteBuilder from 'hooks/useRouteBuilder';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import EndpointsDashboardActionType from 'GeneralSettings/ui/WebhooksDashboards/EndpointsDashboard/EndpointsDashboardActionType'
import useStateReset from 'hooks/useStateReset';
import { endpointsDashboardActionOrigin, pagination } from 'GeneralSettings/ui/WebhooksDashboards/EndpointsDashboard/EndpointsDashboardStore';
import { createGetEndpointsSelector } from 'Webhooks/Endpoints/Selectors';
import { WebhookEndpointSortingField, WebhookEndpointSortingType } from 'api/WebhookEndpointsApi';
import { getWebhookEndpoints } from 'Webhooks/Endpoints/Thunks';
import { WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT } from 'GeneralSettings/ui/WebhooksDashboards/EndpointsDashboard/Pagination';
import { changeSortingType } from 'GeneralSettings/ui/WebhooksDashboards/EndpointsDashboard/EndpointsDashboardStore'
import { AppRoutes } from 'routes/RouteBuilder';
import WebhookTab from 'pages/Webhooks/WebhookTab';
import useOrganizationMembers from 'hooks/useOrganizationMembers';

interface EndpointsDashboardConnectorProps {
  onCreate: () => void;
  onDelete: (id: string) => void;
  onStatusChange: (id: string, status: boolean) => void;
}

const EndpointsDashboardConnector:FC<EndpointsDashboardConnectorProps> = ({
  onCreate,
  onDelete,
  onStatusChange,
}) => {
  const dispatch = useDispatch();
  const routeBuilder = useRouteBuilder();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const organizationMembers = useOrganizationMembers();

  const {sortingType } = useSelector((state: ReduxState) => state.generalSettings.ui.webhooksDashboards.endpointsDashboard);

  useStateReset(EndpointsDashboardActionType.ResetState);

  const params = { sortingType };

  const webhookEndpointsIds = pagination.usePaginatedItems(params);

  const getWebhookEndpointsSelector = useMemo(() => createGetEndpointsSelector(webhookEndpointsIds), [webhookEndpointsIds]);

  const webhookEndpoints = useSelector(getWebhookEndpointsSelector);

  const requestWebhookEndpoints = (sortingTypeToApply: WebhookEndpointSortingType) => {
    dispatchWithUnwrap(getWebhookEndpoints({
      filters: { count: WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT, offset: 0 },
      sortingType: sortingTypeToApply,
      actionOrigin: endpointsDashboardActionOrigin,
    }));
  }

  useEffect(() => {
    requestWebhookEndpoints(sortingType);
  }, []);

  const handleSort = (field: WebhookEndpointSortingField, ascending: boolean) => {
    const updatedSortingType = { field, ascending };

    dispatch(changeSortingType(updatedSortingType));

    requestWebhookEndpoints(updatedSortingType);
  };

  const handleEditWebhookEndpoint = (id: string) => {
    routeBuilder
      .withParams({ edit: id })
      .go(AppRoutes.webhooks, WebhookTab.Endpoints);
  };

  return (
    <EndpointsDashboard
      endpoints={webhookEndpoints}
      users={organizationMembers}
      sortingType={sortingType}
      onEdit={handleEditWebhookEndpoint}
      onCreate={onCreate}
      onSort={handleSort}
      onDelete={onDelete}
      onStatusChange={onStatusChange}
    />
  )
}

export default EndpointsDashboardConnector;
