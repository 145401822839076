import { template, mapValues } from 'lodash';

export enum MessageType {
  PasswordRequired,
  PasswordRequirementsNotMet,
  AuthenticationCodeSent,
  PasswordResetEmailSent,
  InvitationSent,
  InvitationCancelled,
  InvalidVerificationCode,
  WebhookEndpointDeleted,
  WebhookEndpointCreated,
  WebhookEndpointUpdated,
  WebhookEventDeleted,
  WebhookEventRetried,
}

const messages = {
  [MessageType.PasswordRequired]: 'Password is required.',
  [MessageType.AuthenticationCodeSent]: 'A new authentication code has been sent to <%= target %>.',
  [MessageType.PasswordRequirementsNotMet]:
    'Password does not meet requirements (8+ characters in length, at least 1 uppercase letter, at least 1 lowercase letter, and at least 1 number).',
  [MessageType.PasswordResetEmailSent]:
    'If you have a Synthetiq account, a reset email will have been sent to <%= email %>! If you are unable to locate the email, please check your spam or junk folder.',
  [MessageType.InvitationSent]: 'Email invitation sent to <%= email %>.',
  [MessageType.InvitationCancelled]: 'Invitation for <%= email %> has been cancelled.',
  [MessageType.InvalidVerificationCode]: 'This verification code is either incorrect or expired. Please try again or request a new code.',
  [MessageType.WebhookEndpointDeleted]: 'Endpoint has been deleted',
  [MessageType.WebhookEndpointCreated]: 'Endpoint has been created',
  [MessageType.WebhookEndpointUpdated]: 'Endpoint has been updated',
  [MessageType.WebhookEventDeleted]: 'Event has been deleted.',
  [MessageType.WebhookEventRetried]: 'Your <%= eventType %> event has been resent to <%= endpoint %>.',
};

const messageTemplates = mapValues(messages, (templateString) =>
  template(templateString));

const getMessage = (messageType: MessageType, data?: any) => messageTemplates[messageType](data);

export default getMessage;
