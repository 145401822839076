enum EndpointsActionType {
  SetWebhookEndpoints = 'webhookEndpoints/setWebhookEndpoints',
  GetWebhookEndpoints = 'webhookEndpoints/getWebhookEndpoints',
  GetWebhookEndpoint = 'webhookEndpoints/getWebhookEndpoint',
  CreateWebhookEndpoint = 'webhookEndpoints/createWebhookEndpoint',
  GenerateNewWebhookEndpointSecret = 'webhookEndpoints/generateNewWebhookEndpointSecret',
  UpdateWebhookEndpoint = 'webhookEndpoints/updateWebhookEndpoint',
  DeleteWebhookEndpoint = 'webhookEndpoints/deleteWebhookEndpoint',
};

export default EndpointsActionType;
