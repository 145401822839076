import React from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import ForgotPasswordForm from 'components/Auth/ForgotPasswordForm';
import { useAuthProvider } from 'providers/AuthProvider';
import { ForgotPasswordFormData } from 'components/Auth/ForgotPasswordForm/validateForgotPasswordForm';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { useDispatch } from 'react-redux';
import { AppRoutes } from 'routes/RouteBuilder';
import useRouteBuilder from 'hooks/useRouteBuilder';
import AuthFormFooter from '../../components/Auth/AuthFormFooter/AuthFormFooter';

const ForgotPassword = () => {
  const routeBuilder = useRouteBuilder();
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();

  const handleSubmit = async ({ email }: ForgotPasswordFormData) => {
    await authProvider.sendForgotPasswordLink(email);

    notification.createNotification(getMessage(MessageType.PasswordResetEmailSent, { email }), 'success', dispatch);

    routeBuilder.go(AppRoutes.auth.signIn);
  };

  const renderFormFooter = () => {
    return (
      <AuthFormFooter
        linkText="Back To Sign In"
        linkTo={routeBuilder.build(AppRoutes.auth.signIn)}
      />
    );
  };

  return (
    <AuthLayout
      title="Reset Password"
      subTitle="Please enter your email address and we’ll send you a link to reset your password."
      formFooter={renderFormFooter()}
    >
      <ForgotPasswordForm onSubmit={handleSubmit} />
    </AuthLayout>
  );
};

export default ForgotPassword;
