import React, {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { CloseImage, DropdownImage, SelectInputSearchImage } from 'static/images';
import useRandomIdFallback from 'hooks/randomIdFallback';
import DropdownContentWithPortal from 'components/DropdownContentWithPortal';
import { KeyCode } from 'common/KeyCode';
import DropdownContent from 'components/SelectInput/DropdownContent';
import CustomBlurInput from 'components/SelectInput/CustomBlurInput';
import LoaderWithState, { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import Spinner from 'components/Spinner';
import useOutOfView from 'hooks/useOutOfView';
import useCombinedRefs from 'hooks/useCombinedRefs';
import WrapperWithTooltip from 'components/Tooltip';
import Label from 'components/Label';
import QuestionIcon from 'components/QuestionIcon';
import SelectTag from 'components/Tag/SelectTag';

import styles from './SelectInput.module.scss';

export interface Option {
  id?: string;
  selected?: boolean;
  focused?: boolean;
  name: string;
  value: string;
  description?: string;
  disabled?: boolean;
  disableType?: 'background' | 'text';
  icon?: ReactNode;
  iconAfter?: ReactNode;
  tooltip?: ReactNode;
  color?: string;
  withSeparator?: boolean;
  componentOption?: ReactNode;
}

export interface SelectInputProps<DropdownAnchor extends HTMLElement = HTMLElement>
  extends Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange'> {
  value?: string;
  selectedOption?: Option | null;
  userInput: string | null;
  onChange: (option: Option) => void;
  onInputChange: (value: string) => void;
  options: Option[];
  labelTitle?: string;
  titleHint?: string | ReactNode;
  placeholder?: string;
  id?: string;
  link?: ReactNode;
  hasNeighbours?: boolean;
  hasRightNeighbour?: boolean;
  hasLeftNeighbour?: boolean;
  lightLabel?: boolean;
  optionsListClassName?: string;
  loadingIconClassName?: string;
  clearIconClassName?: string;
  autoscroll?: boolean;
  dropdownAnchorRef?: RefObject<DropdownAnchor>;
  loading?: boolean;
  loaded?: boolean;
  hideClearIcon?: boolean;
  errorMessage?: string;
  hasError?: boolean;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  selectControlClassName?: string;
  isLabelTag?: boolean;
  tooltip?: string;
  questionsIconTooltip?: React.ReactNode;
  selectInputClassName?: string;
  inputIcon?: ReactNode;
  required?: boolean;
  onBlur?: () => void;
  inputValueContainerClassName?: string;
  forceValidate?: (value: string) => void;
  tooltipClassName?: string;
  inputHeaderContainerClassName?: string;
  customInputIconContainerClassName?: string;
  blurOnCustomIconClick?: boolean;
  autoFocus?: boolean;
}

const BLUR_DELAY_TIME = 10;
const HIDDEN_TEXT_PADDING = 26;

const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>(
  (
    {
      selectedOption,
      userInput,
      onChange,
      onFocus,
      onInputChange,
      options,
      labelTitle,
      id: providedId,
      loadingIconClassName,
      clearIconClassName,
      placeholder = selectedOption ? selectedOption.name : '',
      readOnly = false,
      disabled = false,
      hasNeighbours = false,
      hasRightNeighbour = false,
      hasLeftNeighbour = false,
      link,
      lightLabel,
      optionsListClassName,
      autoscroll = true,
      dropdownAnchorRef,
      className,
      loading = false,
      loaded = true,
      hideClearIcon = false,
      autoFocus = false,
      onBlur,
      errorMessage,
      hasError = false,
      showLoader,
      loaderState,
      onLoaderStateReset,
      tabIndex,
      selectControlClassName,
      isLabelTag,
      tooltip,
      selectInputClassName,
      inputIcon,
      required,
      forceValidate,
      questionsIconTooltip,
      inputValueContainerClassName,
      titleHint,
      tooltipClassName,
      inputHeaderContainerClassName,
      customInputIconContainerClassName,
      blurOnCustomIconClick,
    },
    ref,
  ) => {
    const id = useRandomIdFallback(providedId);
    const [optionToFocusId, setOptionToFocusId] = useState<string | null>(null);
    const [hasFocus, setHasFocus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [focusedItemId, setFocusedItemId] = useState<string | null>(null);
    const [currentInputWidth, setCurrentInputWidth] = useState(0);

    useEffect(() => {
      if (!isOpen) {
        setOptionToFocusId(null);
        setFocusedItemId(null);
      } else {
        setOptionToFocusId(selectedOption?.id || null);
        setFocusedItemId(selectedOption?.id || null);
      }
    }, [isOpen]);

    const inputClassName = clsx({
      [styles.selectControl]: true,
      [styles.readOnlySelectControl]: readOnly,
      [styles.disabledSelectControl]: disabled,
      [styles.selectControlWithRightNeighbour]: hasRightNeighbour,
      [styles.selectControlWithLeftNeighbour]: hasLeftNeighbour,
      [styles.selectControlWithNeighbours]: hasNeighbours,
      [styles.inputFocus]: hasFocus,
      [styles.highlightSelectControl]: errorMessage || hasError,
    });
    const labelRef = useRef<HTMLLabelElement>(null);
    const inputRef = useCombinedRefs(ref, useRef<HTMLInputElement>(null));
    const hiddenTextElement = useRef<HTMLParagraphElement>(null);
    const mainRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setIsOpen(typeof userInput === 'string' && !!loaded);
    }, [userInput, loaded]);

    const handleOptionSelect = useCallback((option: Option) => {
      if (option.disabled) {
        return;
      }

      setHasFocus(false);
      setIsOpen(false);
      onChange(option);
    }, [options, onChange]);

    const dropdownHandlerRef = dropdownAnchorRef || ((labelRef as unknown) as RefObject<HTMLElement>);

    const renderDropdown = () => (
      <DropdownContentWithPortal dropdownRef={dropdownRef} handlerRef={dropdownHandlerRef}>
        <DropdownContent
          options={options}
          optionToFocusId={optionToFocusId}
          onItemFocus={(itemId) => {
            setFocusedItemId(itemId);
            setOptionToFocusId(null);
          }}
          handleSelect={handleOptionSelect}
          autoscroll={autoscroll}
          selectedValue={selectedOption?.value}
          containerClassName={optionsListClassName}
          isLabelTag={isLabelTag}
          ref={dropdownRef}
        />
      </DropdownContentWithPortal>
    );

    const getFocusedOptionId = (eventType: KeyCode.ArrowUp | KeyCode.ArrowDown) => {
      const maxItemIndex = options.length - 1;
      const focusedOptionIndex = options.findIndex(({ id: itemId }) => itemId === focusedItemId);

      if (eventType === KeyCode.ArrowDown) {
        return focusedOptionIndex < maxItemIndex ? options[focusedOptionIndex + 1].id : options[0].id;
      }

      return focusedOptionIndex <= 0 ? options[maxItemIndex].id : options[focusedOptionIndex - 1].id;
    };

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
      const { keyCode } = event;
      if (keyCode === KeyCode.ArrowDown && !isOpen && hasFocus) {
        setIsOpen(true);
        return;
      }

      if (keyCode === KeyCode.ArrowDown || keyCode === KeyCode.ArrowUp) {
        const idToSet = getFocusedOptionId(keyCode)!;
        setFocusedItemId(idToSet);
        setOptionToFocusId(idToSet);
      }

      if (keyCode === KeyCode.Esc) {
        setIsOpen(false);
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      const { keyCode } = event;

      if (keyCode === KeyCode.Enter) {
        const option = options.find(({ id: itemId }) => itemId === focusedItemId)

        if (!!option && !option.disabled) {
          setHasFocus(false);
          setIsOpen(false);
          event.currentTarget.blur();
          onChange(option);
        }
      }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (readOnly) {
        return;
      }

      setHasFocus(true);

      onFocus?.(event);
    };

    const inputContainerClassName = clsx({
      [styles.inputContainer]: true,
      [styles.inputContainerWithRightNeighbour]: hasRightNeighbour,
      [styles.inputContainerWithLeftNeighbour]: hasLeftNeighbour,
      [styles.inputContainerWithNeighbours]: hasNeighbours,
      [styles.selectInputWithValue]: selectedOption || userInput,
    });

    const getInputValue = () => {
      if (hasFocus) {
        return userInput || '';
      }
      return selectedOption ? selectedOption.name : '';
    };

    useLayoutEffect(() => {
      if (selectedOption?.iconAfter && !hasFocus) {
        const { offsetWidth } = hiddenTextElement.current!;
        setCurrentInputWidth(offsetWidth);
      }
    }, [getInputValue()]);

    const blurInputWithDelay = () => {
      setTimeout(() => {
        inputRef.current!.blur();
        setHasFocus(false);
      }, BLUR_DELAY_TIME);
    };

    const handleInputClick = () => {
      if (readOnly || (!!userInput && isOpen) || !options.length) {
        return;
      }

      const openState = !isOpen;

      if (!openState && !userInput) {
        blurInputWithDelay();
      }

      setIsOpen(openState);
    };

    const handleClickOutside = useCallback(() => {
      if (hasFocus) {
        onBlur?.();
      }

      setIsOpen(false);
      setHasFocus(false);
    }, [hasFocus, onBlur]);

    const handleCustomIconClick = () => {
      if (blurOnCustomIconClick) {
        handleClickOutside();
      }
    };

    const onClear = () => {
      onChange({
        id: '',
        name: '',
        value: '',
      });
      onInputChange('');
      forceValidate?.('');
    };

    const renderClearIcon = () => {
      if (hideClearIcon || readOnly || disabled) {
        return null;
      }

      return (
        <CloseImage className={clsx(styles.clearIcon, hasFocus && styles.showClearIcon, clearIconClassName)} onClick={onClear} />
      );
    };

    const renderInputIcon = () => {
      if (loading) {
        return (
          <div className={clsx(styles.inputLoader, loadingIconClassName)}>
            <Spinner size={24} />
          </div>
        );
      }

      if (inputIcon) {
        return (
          <div
            onClick={handleCustomIconClick}
            className={clsx(styles.customInputIconContainer, customInputIconContainerClassName)}
          >
            {renderClearIcon()}
            {inputIcon}
          </div>
        );
      }

      return (
        <div className={styles.inputIconContainer}>
          {renderClearIcon()}
          {isOpen ? <SelectInputSearchImage /> : <DropdownImage />}
        </div>
      );
    };

    useOutOfView(mainRef, () => {
      setIsOpen(false);
    });

    const renderSelectedLabel = () => {
      if (isLabelTag && selectedOption) {
        return <SelectTag color={selectedOption.color}>{selectedOption.name}</SelectTag>;
      }
      return null;
    };

    const renderDesktopInput = () => {
      return (
        <div className={clsx(styles.desktop, inputContainerClassName, className)} ref={mainRef}>
          <div className={clsx(styles.headerContainer, inputHeaderContainerClassName)}>
            <div className={styles.labelWithIconContainer}>
              <Label
                htmlFor={id}
                className={clsx(lightLabel && styles.whiteLabel)}
                tooltip={titleHint}
                required={required}
                requiredSymbolClassName={clsx(lightLabel && styles.whiteLabel)}
                tooltipClassName={tooltipClassName}
              >
                {labelTitle}
              </Label>{questionsIconTooltip && <QuestionIcon className={styles.questionIcon} tooltip={questionsIconTooltip} />}
            </div>
            {link}
          </div>
          <ClickAwayListener onClickAway={() => handleClickOutside()}>
            <div className={styles.selectInputWrapper}>
              <WrapperWithTooltip tooltip={tooltip}>
                <div id={id} className={clsx(styles.select, selectInputClassName)}>
                  <label className={clsx(inputClassName, selectControlClassName)} ref={labelRef}>
                    <div
                      className={clsx(
                        isLabelTag ? styles.inputLabelValueContainer : styles.inputValueContainer,
                        inputValueContainerClassName,
                      )}
                    >
                      {renderSelectedLabel()}
                      {selectedOption?.icon}
                      <CustomBlurInput
                        ref={inputRef}
                        type="text"
                        value={getInputValue()}
                        placeholder={!isLabelTag ? placeholder : ''}
                        onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                          onInputChange(target.value);
                        }}
                        autoFocus={autoFocus}
                        onFocus={handleFocus}
                        onClick={() => handleInputClick()}
                        readOnly={readOnly}
                        disabled={disabled}
                        onKeyUp={handleKeyUp}
                        onKeyDown={handleKeyDown}
                        onCustomBlur={handleClickOutside}
                        tabIndex={tabIndex}
                        style={selectedOption?.iconAfter ? { width: currentInputWidth } : undefined}
                        className={clsx(
                          isLabelTag && !hasFocus && styles.emptyInput,
                          isLabelTag && selectedOption && hasFocus && styles.inputWithLabel,
                        )}
                      />
                    </div>
                    {selectedOption?.iconAfter && !hasFocus ? (
                      <>
                        <div
                          className={clsx(styles.iconAfter, hasFocus && styles.hiddenIconAfter)}
                          style={{ left: HIDDEN_TEXT_PADDING + currentInputWidth }}
                        >
                          {selectedOption.iconAfter}
                        </div>
                        <span
                          ref={hiddenTextElement}
                          className={clsx(
                            styles.textLabel,
                            (hasFocus || !selectedOption?.iconAfter) && styles.fullInputWidth,
                          )}
                        >
                          {getInputValue()}
                        </span>
                      </>
                    ) : null}
                    {renderInputIcon()}
                    {showLoader && (
                      <LoaderWithState
                        className={clsx(styles.loader, hideClearIcon && styles.fixedLoader)}
                        state={loaderState}
                        onStateReset={onLoaderStateReset}
                      />
                    )}
                  </label>
                  {isOpen && loaded && renderDropdown()}
                  {errorMessage ? <p className={styles.errorNotification}>{errorMessage}</p> : null}
                </div>
              </WrapperWithTooltip>
            </div>
          </ClickAwayListener>
        </div>
      );
    };

    return (
      <>
        {renderDesktopInput()}
        <div className={clsx(styles.mobile, className)}>
          <Label
            className={clsx(lightLabel && styles.whiteLabel)}
            tooltip={titleHint}
            required={required}
            requiredSymbolClassName={clsx(lightLabel && styles.whiteLabel)}
            tooltipClassName={tooltipClassName}
          >
            {labelTitle}

          </Label>
          <select
            className={clsx(styles.selectInput, !selectedOption?.value && styles.noValue, selectControlClassName)}
            value={selectedOption?.value || ''}
            onChange={(event) => onChange(options.find((option) => option.value === event.target.value)!)}
          >
            <option value="" disabled>{placeholder}</option>
            {options.map(({ name, value }) => (
              <option className={styles.mobileOption} value={value}>{name}</option>
            ))}
          </select>
          {selectedOption && !hideClearIcon && (
            <CloseImage
              className={clsx(styles.closeImage, clearIconClassName)}
              onClick={() => onChange({ name: '', value: '' })}
            />)
          }
          <DropdownImage className={styles.arrowDownImage} />
        </div>
      </>
    );
  },
);

export default SelectInput;
