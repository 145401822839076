import React from 'react';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';
import { DataExtractionResultsTableColumnSize } from '../../TableColumnSize';

const SkeletonDataExtractionResultRow = ({ className }: { className?: string }) => {
  return (
    <TableRow disableHover useActions className={className}>
      <TableBodyCell width={DataExtractionResultsTableColumnSize.FileName}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={DataExtractionResultsTableColumnSize.FileType}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={DataExtractionResultsTableColumnSize.DocumentType}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={DataExtractionResultsTableColumnSize.Pages}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonDataExtractionResultRow;
