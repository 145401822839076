import { FC } from 'react';
import ButtonWithImage from 'components/ButtonWithImage';

import styles from './AddDocumentTypeFieldChildrenButton.module.scss';

interface AddDocumentTypeFieldChildrenButtonProps {
  title: string;
  disabled: boolean;
  tooltip?: React.ReactNode;
  onAddChildren: () => void;
}

const AddDocumentTypeFieldChildrenButton: FC<AddDocumentTypeFieldChildrenButtonProps> = ({
  title,
  disabled,
  tooltip,
  onAddChildren,
}) => (
  <div className={styles.addBtnContainer}>
    <div className={styles.dashedLine} />
    <ButtonWithImage
      disabled={disabled}
      title={title}
      kind="add"
      onClick={onAddChildren}
      className={styles.addButton}
      tooltip={tooltip}
    />
    <div className={styles.dashedLine} />
  </div>
);

export default AddDocumentTypeFieldChildrenButton;
