import { AcceptInviteData } from 'api/UserInviteApi';
import getMessage from 'constants/messages';
import { validateNameField, validatePassword } from 'utils/validation/validation';

const MIN_LENGTH = 2;

export const LABELS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
};

const validateCreateAccountInviteForm = (data: Partial<AcceptInviteData>) => {
  const errors: Partial<AcceptInviteData> = Object.keys(data).reduce((result, key) => {
    if (data[key].length === 0) {
      return { ...result, [key]: `${LABELS[key]} is required` };
    }

    if (data[key].length < MIN_LENGTH) {
      return { ...result, [key]: `Minimum length is ${MIN_LENGTH}` };
    }

    return result;
  }, {});

  const passwordErrorMessageType = validatePassword(data.password || '');

  if (passwordErrorMessageType) {
    errors.password = getMessage(passwordErrorMessageType);
  }

  const firstNameErrorMessage = validateNameField(data.firstName || '');

  if (!errors.firstName && firstNameErrorMessage) {
    errors.firstName = firstNameErrorMessage;
  }

  const lastNameErrorMessage = validateNameField(data.lastName || '');

  if (!errors.lastName && lastNameErrorMessage) {
    errors.lastName = lastNameErrorMessage;
  }

  return errors;
};

export default validateCreateAccountInviteForm;
