import React from 'react';
import clsx from 'clsx';
import { User } from 'api/UserApi';
import { NullableItems } from 'pagination';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import SearchWithFilters from 'components/SearchWithFilters';
import { FiltersSortingOption } from 'components/SearchWithFilters/SearchWithFilters';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import { NoResultsIcon } from 'static/images';
import styles from './TableLayout.module.scss';

export interface TableLayoutProps<Item> {
  className?: string;
  renderHeader?: () => React.ReactNode;
  filtersButtonsClassName?: string;
  items: NullableItems<Item>;
  areItemsLoaded: boolean;
  search: string;
  noItemsTitle: React.ReactNode;
  selectedSortingType?: { field: string; ascending: boolean } | null;
  sortingOptions?: FiltersSortingOption[];
  areFiltersApplied?: boolean;
  noItemsButtonMessage?: string;
  noItemsContainerClassName?: string;
  noItemsTitleClassName?: string;
  noItemsButtonClassName?: string;
  searchNotFoundContainerClassName?: string;
  noResultFoundContainerClassName?: string;
  searchFiltersClassName?: string;
  noItemsIcon?: React.ReactNode;
  noItemsButtonTooltip?: React.ReactNode;
  noItemsButtonHoverableTooltip?: boolean;
  isNoItemsButtonDisabled?: boolean;
  renderTable: () => React.ReactNode;
  onSearch: (search: string) => void;
  onFiltersButtonClick?: () => void;
  onClearFilters?: () => void;
  onNoItemsButtonClick?: () => void;
  onChangeSelectedUsers?: (members: User[]) => void;
  onChangeSortingType?: (field: string, ascending: boolean) => void;
  onChangeToggle?: (active: boolean) => void;
  wrapNoItemsButton?: (button: React.ReactNode) => React.ReactNode;
  availableUsersForFilter?: User[];
  showToggleButton?: boolean;
  toggleButtonActive?: boolean;
  selectedUsers?: User[];
  hideFiltersButton?: boolean;
  hideSearchBar?: boolean;
  withFooter?: boolean;
  headerContainerClassName?: string;
  tableContainerClassName?: string;
  embedded?: boolean;
  toggleSwitchLabel?: string;
}

const TableLayout = <Item,>({
  renderHeader,
  className,
  filtersButtonsClassName,
  items,
  areItemsLoaded,
  search,
  areFiltersApplied,
  availableUsersForFilter,
  selectedUsers,
  noItemsIcon,
  noItemsTitle,
  noItemsButtonMessage,
  noItemsButtonHoverableTooltip,
  isNoItemsButtonDisabled,
  searchNotFoundContainerClassName,
  noResultFoundContainerClassName,
  wrapNoItemsButton,
  searchFiltersClassName,
  noItemsButtonTooltip,
  renderTable,
  onSearch,
  onClearFilters,
  onFiltersButtonClick,
  onNoItemsButtonClick,
  onChangeSortingType,
  onChangeSelectedUsers,
  onChangeToggle,
  showToggleButton,
  toggleButtonActive,
  noItemsContainerClassName,
  noItemsTitleClassName,
  noItemsButtonClassName,
  hideFiltersButton,
  hideSearchBar,
  selectedSortingType,
  sortingOptions,
  withFooter,
  headerContainerClassName,
  tableContainerClassName,
  embedded,
  toggleSwitchLabel,
}: TableLayoutProps<Item>) => {
  const renderTableContainer = () => {
    return (
      <div className={clsx(styles[`tableContainer${embedded ? '__embedded' : ''}`], tableContainerClassName)}>
        {renderContent()}
      </div>
    );
  };

  const renderContent = () => {
    if (!areItemsLoaded || items.length) {
      return renderTable();
    }

    if (search && !items.length) {
      return (
        <SearchNotFound searchValue={search} className={clsx(styles.searchNotFound, searchNotFoundContainerClassName)} />
      );
    }

    if ((areFiltersApplied || selectedUsers?.length) && !items.length) {
      return (
        <NoItems
          className={clsx(styles.noResultsFoundContainer, noResultFoundContainerClassName)}
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          onButtonClick={onClearFilters}
        />
      );
    }

    return (
      <NoItems
        className={clsx(styles.noItemsContainer, noItemsContainerClassName)}
        titleClassName={clsx(styles.noItemsTitle, noItemsTitleClassName)}
        buttonClassName={clsx(styles.noItemsButton, noItemsButtonClassName)}
        icon={noItemsIcon}
        title={noItemsTitle}
        buttonMessage={noItemsButtonMessage}
        onButtonClick={onNoItemsButtonClick}
        isButtonDisabled={isNoItemsButtonDisabled}
        buttonTooltip={noItemsButtonTooltip}
        buttonHoverableTooltip={noItemsButtonHoverableTooltip}
        wrapButton={wrapNoItemsButton}
      />
    );
  };

  const displayHeader = !areItemsLoaded
    || !!items.length
    || !!search
    || areFiltersApplied
    || !!selectedUsers?.length;

  return (
    <div className={clsx(className, withFooter && styles.fullHeightContainer)}>
      <div className={clsx(
        styles[`headerContainer${embedded ? '__embedded' : ''}`],
        headerContainerClassName,
      )}>
        {renderHeader?.()}
        {displayHeader && !hideSearchBar && <SearchWithFilters
          className={clsx(styles.tableLayoutSearchWithFilters, searchFiltersClassName)}
          filtersButtonsClassName={filtersButtonsClassName}
          search={search}
          loading={!!search && !areItemsLoaded}
          areFiltersActive={areFiltersApplied}
          onSearch={onSearch}
          onFiltersButtonClick={onFiltersButtonClick}
          showFiltersButton={!hideFiltersButton}
          availableUsersForFilter={availableUsersForFilter}
          selectedUsers={selectedUsers}
          onChangeSelectedUsers={onChangeSelectedUsers}
          onChangeToggle={onChangeToggle}
          showToggleButton={showToggleButton}
          toggleButtonActive={toggleButtonActive}
          selectedSortingType={selectedSortingType}
          sortingOptions={sortingOptions}
          onChangeSortingType={onChangeSortingType}
          toggleSwitchLabel={toggleSwitchLabel}
        />}
      </div>

      {withFooter
        ? <TableWrapperWithFooter className={styles.tableWrapperWithFooter}>
          {renderTableContainer()}
        </TableWrapperWithFooter>
        : renderTableContainer()}
    </div>
  );
};

export default TableLayout;
