import { AnyAction } from 'redux';

export enum GlobalStoreActions {
  LogOut = 'globalStoreActions/logOut',
}

export const logOut = () => ({
  type: GlobalStoreActions.LogOut,
});

export const doesActionRequireStateReset = (
  action: AnyAction,
): action is ReturnType<typeof logOut> =>
  action.type === GlobalStoreActions.LogOut;
