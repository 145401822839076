import { DocumentTypeField, DocumentTypeFieldDataType } from 'api/DocumentTypeFieldApi';
import { DocumentTypeFieldViewDataType } from 'enums/DocumentTypeFieldViewDataType';
import { DocumentTypeFieldViewData } from './Forms/DocumentTypeFieldForm/DocumentTypeFieldForm';

const SPECIAL_CHARACTERS_REGEX = /[[\]{}()<>,:"'&#$%@!?/|~\\]/g;

export const getViewDataType = (field: DocumentTypeField) => {
  switch (field.dataType) {
    case DocumentTypeFieldDataType.String:
      return field.isArray ? DocumentTypeFieldViewDataType.ArrayOfStrings : DocumentTypeFieldViewDataType.String;
    case DocumentTypeFieldDataType.Number:
      return field.isArray ? DocumentTypeFieldViewDataType.ArrayOfNumbers : DocumentTypeFieldViewDataType.Number;
    case DocumentTypeFieldDataType.Date:
      return field.isArray ? DocumentTypeFieldViewDataType.ArrayOfDates : DocumentTypeFieldViewDataType.Date;
    case DocumentTypeFieldDataType.Boolean:
      return field.isArray ? DocumentTypeFieldViewDataType.ArrayOfBooleans : DocumentTypeFieldViewDataType.Boolean;
    case DocumentTypeFieldDataType.Object:
      return field.isArray ? DocumentTypeFieldViewDataType.ArrayOfObjects : DocumentTypeFieldViewDataType.Object;
    default:
      return null as never;
  }
}

export const getDataTypeByViewData = (
  formData: DocumentTypeFieldViewData,
  ): { dataType: DocumentTypeFieldDataType, isArray?: boolean } => {
  switch (formData.dataType) {
    case DocumentTypeFieldViewDataType.String:
      return { dataType: DocumentTypeFieldDataType.String, isArray: false };
    case DocumentTypeFieldViewDataType.Number:
      return { dataType: DocumentTypeFieldDataType.Number, isArray: false };
    case DocumentTypeFieldViewDataType.Date:
      return { dataType: DocumentTypeFieldDataType.Date, isArray: false };
    case DocumentTypeFieldViewDataType.Boolean:
      return { dataType: DocumentTypeFieldDataType.Boolean, isArray: false };
    case DocumentTypeFieldViewDataType.Object:
      return { dataType: DocumentTypeFieldDataType.Object, isArray: false };
    case DocumentTypeFieldViewDataType.ArrayOfStrings:
      return { dataType: DocumentTypeFieldDataType.String, isArray: true };
    case DocumentTypeFieldViewDataType.ArrayOfNumbers:
      return { dataType: DocumentTypeFieldDataType.Number, isArray: true };
    case DocumentTypeFieldViewDataType.ArrayOfDates:
      return { dataType: DocumentTypeFieldDataType.Date, isArray: true };
    case DocumentTypeFieldViewDataType.ArrayOfBooleans:
      return { dataType: DocumentTypeFieldDataType.Boolean, isArray: true };
    case DocumentTypeFieldViewDataType.ArrayOfObjects:
      return { dataType: DocumentTypeFieldDataType.Object, isArray: true };
    default:
      return null as never;
  }
} 

export const optionDescriptionByViewDataType = {
  [DocumentTypeFieldViewDataType.String]: 'For any combination of letters, numbers and special characters',
  [DocumentTypeFieldViewDataType.Number]: 'For simple numerical data, including integers and decimals',
  [DocumentTypeFieldViewDataType.Date]: 'For data that represents a date (returned in the format "MM/DD/YYYY")',
  [DocumentTypeFieldViewDataType.Boolean]: 'For data that is either true or false',
  [DocumentTypeFieldViewDataType.Object]: 'For data that is contained in an object (additional details will be required)',
  [DocumentTypeFieldViewDataType.ArrayOfStrings]: 'For any array of strings.',
  [DocumentTypeFieldViewDataType.ArrayOfNumbers]: 'For an array of numbers',
  [DocumentTypeFieldViewDataType.ArrayOfDates]: 'For an array of dates',
  [DocumentTypeFieldViewDataType.ArrayOfBooleans]: 'For an array of boolean values',
  [DocumentTypeFieldViewDataType.ArrayOfObjects]: 'For an array of objects (additional details will be required)',
}

export const isObjectViewDataType = (dataType: DocumentTypeFieldViewDataType) => {
  return (
    dataType === DocumentTypeFieldViewDataType.Object ||
    dataType === DocumentTypeFieldViewDataType.ArrayOfObjects
  )
}

export const removeSpecialCharactersFromString = (str: string) => {
  return str.replace(SPECIAL_CHARACTERS_REGEX, '');
}
