import { NotificationType } from 'components/Notification';

export enum NotificationComponentTypes {
  ResetPasswordExpiredErrorNotificationComponent = 'ResetPasswordExpiredNotificationComponent',
}

export type ComponentTypeWithProps<ComponentType, Props> = {
  componentType: ComponentType;
  props: Props;
};

export type NotificationComponents =
  | ComponentTypeWithProps<NotificationComponentTypes.ResetPasswordExpiredErrorNotificationComponent, undefined>;

export interface NotificationItem {
  type: NotificationType;
  notification: string | NotificationComponents;
  timeout: number;
  id: string;
}

export interface ShowNotification {
  type: string;
  notification: string | NotificationComponents;
  timeout?: number;
  id: string;
}

export interface UserInfo {
  _id: string;
  first_name: string;
}
