import { createAsyncThunk } from '@reduxjs/toolkit';
import { documentTypeFieldApi } from 'stores/api';
import { DocumentTypeFieldsActionType } from './DocumentTypeFieldsActionType';
import {
  BatchCreateDocumentTypeFieldParams,
  BatchUpdateDocumentTypeFieldParams,
  CreateDocumentTypeFieldParams,
  UpdateDocumentTypeFieldParams,
} from 'api/DocumentTypeFieldApi';

export const getDocumentTypeFields = createAsyncThunk(
  DocumentTypeFieldsActionType.GetDocumentTypeFields,
  (documentTypeId: string) => {
    return documentTypeFieldApi.find(documentTypeId);
  },
);

export const updateDocumentTypeField = createAsyncThunk(
  DocumentTypeFieldsActionType.UpdateDocumentTypeField,
  (params: UpdateDocumentTypeFieldParams) => {
    return documentTypeFieldApi.update(params);
  },
);

export const batchUpdateDocumentTypeFields = createAsyncThunk(
  DocumentTypeFieldsActionType.BatchUpdateDocumentTypeFields,
  (params: BatchUpdateDocumentTypeFieldParams) => {
    return documentTypeFieldApi.batchUpdate(params);
  },
);

export const createDocumentTypeField = createAsyncThunk(
  DocumentTypeFieldsActionType.CreateDocumentTypeField,
  (params: CreateDocumentTypeFieldParams) => {
    return documentTypeFieldApi.create(params);
  },
);

export const batchCreateDocumentTypeFields = createAsyncThunk(
  DocumentTypeFieldsActionType.BatchCreateDocumentTypeFields,
  (params: BatchCreateDocumentTypeFieldParams) => {
    return documentTypeFieldApi.batchCreate(params);
  },
);

export const deleteDocumentTypeField = createAsyncThunk(
  DocumentTypeFieldsActionType.DeleteDocumentTypeField,
  (id: string) => {
    return documentTypeFieldApi.delete(id);
  },
);
