import Button from 'components/Button';
import UploadFile from 'components/UploadFile';
import React, { FC, useState } from 'react';
import styles from './UploadImageStep.module.scss';
import { UploadFileIcon } from 'static/images';
import formatBytes from 'utils/formatBytes';

interface UploadImageStepProps {
  file: File | null;
  onSubmit: (file: File) => void;
  maxSizeBytes: number;
  supportedExtensions: string[];
}

const UploadImageStep: FC<UploadImageStepProps> = ({
  file: defaultFile,
  maxSizeBytes,
  supportedExtensions,
  onSubmit,
}) => {
  const [file, setFile] = useState<File | null>(defaultFile);

  const handleOnUploadFile = () => {
    onSubmit(file!);
  };

  const renderFileRequirements = () => {
    return (
      <>
        We accept: {supportedExtensions.join(', ')} <br />
        {formatBytes(maxSizeBytes)} max
      </>
    );
  };

  return (
    <div>
      <UploadFile
        file={file}
        onFileChange={setFile}
        maxFileSize={maxSizeBytes}
        accept={supportedExtensions}
        uploadIcon={<UploadFileIcon />}
        requirementsText={renderFileRequirements()}
        inputWrapperClassName={styles.inputWrapper}
      />
      <Button
        size="form"
        kind="primary"
        disabled={!file}
        className={styles.uploadButton}
        onClick={handleOnUploadFile}
      >
        Upload File
      </Button>
    </div>
  );
};

export default UploadImageStep;
