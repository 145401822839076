import maskNumberValue from 'utils/masks/maskNumberValue';
import LimitDecimalNumbers from './LimitDecimalNumbers';
import { getCurrencySymbol } from './getCurrencySymbol';

const limitDecimalNumbers = LimitDecimalNumbers(2);

interface IMonetaryFormattingParams {
  currency?: string;
  alwaysShowFractionalPart?: boolean;
  disableRounding?: true;
}

const formatMonetaryValue = (
  value: number,
  params: IMonetaryFormattingParams = { currency: '', alwaysShowFractionalPart: false },
) => {
  const valueAsString = value.toString();

  const limitedValue = params.disableRounding
    ? valueAsString
    : limitDecimalNumbers(valueAsString);

  const maskedValue = maskNumberValue(limitedValue);
  const { currency = '', alwaysShowFractionalPart = false } = params;
  const currencySymbol = currency && getCurrencySymbol(currency);
  const currencySymbolText = `${currencySymbol}${currencySymbol.length > 1 ? ' ' : ''}`;

  const formattedValue = valueAsString.includes('-')
    ? maskedValue.replace('-', `-${currencySymbolText}`)
    : `${currencySymbolText}${maskedValue}`;

  if (!alwaysShowFractionalPart) {
    return formattedValue;
  }

  return `${formattedValue}${!formattedValue.includes('.') ? '.00' : ''}`;
}

export default formatMonetaryValue;
