import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './Footer.module.scss';

interface FooterProps {
  separator?: boolean;
  className?: string;
  hideLinks?: boolean;
}

interface IFooterLink {
  key: string;
  render: () => React.ReactElement | string;
  link: string;
}

const LINKS: IFooterLink[] = [
  {
    key: 'api-reference',
    render: () => 'API Reference',
    link: 'https://synthetiq.readme.io/reference',
  },
  {
    key: 'platform-status',
    render: () => <><div className={styles.statusIcon} />Platform Status</>,
    link: 'http://status.synthetiq.co',
  },
];

const Footer: FC<FooterProps> = ({ separator, className, hideLinks }) => {
  return (
    <div className={clsx(styles.footerContainer, separator && styles.separator, className)}>
      <div>© Synthetiq</div>
      {!hideLinks && (
        <div className={styles.links}>
          {LINKS.map(({ render, key, link }) => (
            <a target="_blank" className={styles.link} key={key} href={link}>{render()}</a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Footer;
