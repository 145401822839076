export enum TableColumnSize {
  Date = 27,
  FileName = 19,
  FileType = 7,
  DocumentType = 16,
  Pages = 7,
  ProcessingTime = 11,
  Result = 13,
}

export enum MobileTableColumnSize {
  Date = 70,
  Result = 30,
}
