import React, { FC } from 'react';
import { WarningNotificationIcon } from 'static/images';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './TableBodyCellNoContent.module.scss';

export interface TableBodeCellNoContentProps {
  text?: string;
  displayWarningIcon?: boolean;
  warningIconTooltip?: React.ReactNode;
}

const TableBodyCellNoContent: FC<TableBodeCellNoContentProps> = ({
  text,
  displayWarningIcon,
  warningIconTooltip,
}) => {
  if (!text) {
    // NOTE this is a long ("em") dash
    return <>—</>;
  }

  return (
    <div className={styles.noContentContainer}>
      <p className={styles.noContentText}>{text}</p>
      {displayWarningIcon && <WrapperWithTooltip tooltip={warningIconTooltip}>
        <WarningNotificationIcon className={styles.warningIcon} />
      </WrapperWithTooltip>}
    </div>
  );
};

export default TableBodyCellNoContent;
