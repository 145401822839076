const createNotificationRateLimit = () => {
  const ignoreTimeRangeMs = 250;

  const timings: Record<string, number> = {};

  return (key: string) => {
    const shouldPass = !timings[key] || Date.now() - timings[key] > ignoreTimeRangeMs;

    timings[key] = Date.now();

    return shouldPass;
  };
};

export default createNotificationRateLimit;
