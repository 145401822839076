import Pagination from 'pagination';
import { DataExtractionResultsSortingType } from '../../../api/DataExtractionResultApi';
import { ReduxState } from '../../../types/ReduxState';

export const DATA_EXTRACTION_RESULTS_PER_PAGE_DEFAULT = 20;

export interface DataExtractionResultsViewPaginationParams {
  sortingType: DataExtractionResultsSortingType;
  dataExtractionId: string;
}

const createDataExtractionResultsViewPagination = <
  Params extends DataExtractionResultsViewPaginationParams
>(namespace: string[] | string) => {
  return Pagination<ReduxState, string, Params>(
    namespace,
    DATA_EXTRACTION_RESULTS_PER_PAGE_DEFAULT,
  );
};

export default createDataExtractionResultsViewPagination;
