import React, { forwardRef } from 'react';
import PasswordInput, { InputProps } from '../../../PasswordInput/PasswordInput';
import clsx from 'clsx';

import styles from './AuthPasswordInput.module.scss';

const AuthPasswordInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <PasswordInput
      {...props}
      ref={ref}
      className={clsx(styles.input, props.className)}
      labelInnerContainerClassName={clsx(styles.labelInnerContainer, props.labelInnerContainerClassName)}
      containerClassName={clsx(styles.container, props.containerClassName)}
      eyeButtonClassName={clsx(styles.eyeButton, props.eyeButtonClassName)}
      passwordShownClassName={clsx(styles.passwordShown, props.passwordShownClassName)}
      passwordHiddenClassName={clsx(styles.passwordHidden, props.passwordHiddenClassName)}
      errorClassName={clsx(styles.inputError, props.errorClassName)}
      focusClassName={clsx(styles.inputFocus, props.focusClassName)}
    />
  );
});

export default AuthPasswordInput;
