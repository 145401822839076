import createDashboardFiltersSlice, {
  BaseDashboardFiltersState,
  DashboardFiltersActionType,
  getDashboardFiltersInitialState,
} from 'DashboardSlice/createDashboardFiltersSlice';
import EventsDashboardFiltersActionType from './EventsDashboardFiltersActionType';
import { WebhookEventStatus } from 'api/WebhookEventsApi';
import { DateRange } from 'api/Types';
import { createReducer } from '@reduxjs/toolkit';
import reduceReducers from 'reduce-reducers';

export interface ChangeFiltersPayload {
  eventTypes: string[];
  statuses: WebhookEventStatus[];
  dueCreatedDateRange: DateRange;
  documentTypeId: string | null;
}

export interface EventsDashboardFiltersState extends BaseDashboardFiltersState {
  statuses: WebhookEventStatus[],
  eventTypes: string[];
  dueCreatedDateRange: DateRange;
  documentTypeId: string | null;
}

const createEventsDashboardFiltersSlice = (namespace: string) => {
  const getInitialState = (): EventsDashboardFiltersState => ({
    ...getDashboardFiltersInitialState(),
    statuses: [],
    eventTypes: [],
    dueCreatedDateRange: {
      from: null,
      to: null,
    },
    documentTypeId: null,
  });

  const actionTypes = {
    [DashboardFiltersActionType.ChangeSearchValue]: EventsDashboardFiltersActionType.ChangeSearchValue,
    [DashboardFiltersActionType.ChangeFilters]: EventsDashboardFiltersActionType.ChangeFilters,
    [DashboardFiltersActionType.ClearFilters]: EventsDashboardFiltersActionType.ClearFilters,
    [DashboardFiltersActionType.ShowFiltersPanel] : EventsDashboardFiltersActionType.ShowFiltersPanel,
    [DashboardFiltersActionType.HideFiltersPanel] : EventsDashboardFiltersActionType.HideFiltersPanel,
  };

  const { reducer, actions } = createDashboardFiltersSlice<
    EventsDashboardFiltersState,
    ChangeFiltersPayload,
    typeof actionTypes
  >(namespace,
    getInitialState,
    actionTypes,
  );

  const eventsDashboardFiltersReducer = createReducer(getInitialState(), (builder) => {
    builder.addCase(actions.changeFilters, (state, action) => {
      state.dueCreatedDateRange = action.payload.dueCreatedDateRange || { from: null, to: null };
      state.documentTypeId = action.payload.documentTypeId || null;
      state.eventTypes = action.payload.eventTypes;
      state.statuses = action.payload.statuses;
    });

    builder.addCase(actions.clearFilters, (state) => {
      state.dueCreatedDateRange = { from: null, to: null };
      state.documentTypeId = null;
      state.eventTypes = [];
      state.statuses = [];
    });
  });

  return {
    actions,
    reducer: reduceReducers(eventsDashboardFiltersReducer, reducer),
    getInitialState,
  };
};

export default createEventsDashboardFiltersSlice;
