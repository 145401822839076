import React, { FC } from 'react';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import { TableColumnSize } from 'components/Users/Dashboards/UsersDashboard/TableColumnSize';
import { User, UsersSortingField, UsersSortingType, UserStatus } from 'api/UserApi';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import SkeletonUserRow from 'components/Users/Tables/UsersTable/SkeletonUserRow';
import Pagination from 'components/Pagination';
import useSorting from 'hooks/useSorting';
import UserRow from 'components/Users/Tables/UsersTable/UserRow';
import { NullableItems, PaginationProps } from 'pagination';

interface OrganizationUsersTableProps {
  users: NullableItems<User>;
  sortingType: UsersSortingType;
  paginationProps: PaginationProps;
  onEditUser: (user: User) => void;
  onCancelUserInvitation: (user: User) => void;
  onUpdateUserStatus: (user: User, status: UserStatus) => Promise<void>;
  currentUserId?: string;
  onSort: (field: UsersSortingField, ascending: boolean) => void;
  getEditUserPageLink: (user: User) => string;
  search?: string;
}

const UsersTable: FC<OrganizationUsersTableProps> = ({
  users,
  sortingType,
  onEditUser,
  onUpdateUserStatus,
  onCancelUserInvitation,
  currentUserId,
  paginationProps,
  onSort,
  getEditUserPageLink,
  search,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderRow = (user: User) => (
    <UserRow
      user={user}
      onEditUser={onEditUser}
      key={user.id}
      onUpdateUserStatus={onUpdateUserStatus}
      onCancelInvitation={onCancelUserInvitation}
      isCurrentUser={currentUserId === user.id}
      getEditUserPageLink={getEditUserPageLink}
      search={search}
    />
  );

  return (
    <>
      <Table>
        <TableHead sticky>
          <TableHeadCell
            width={TableColumnSize.FirstName}
            ascending={getSortingType(UsersSortingField.FirstName)}
            onClick={() => changeSorting(UsersSortingField.FirstName)}
          >
            First Name
          </TableHeadCell>
          <TableHeadCell
            width={TableColumnSize.LastName}
            ascending={getSortingType(UsersSortingField.LastName)}
            onClick={() => changeSorting(UsersSortingField.LastName)}
          >
            Last Name
          </TableHeadCell>
          <TableHeadCell
            width={TableColumnSize.Email}
            ascending={getSortingType(UsersSortingField.Email)}
            onClick={() => changeSorting(UsersSortingField.Email)}
          >
            Email
          </TableHeadCell>
          <TableHeadCell
            width={TableColumnSize.PermissionGroup}
            ascending={getSortingType(UsersSortingField.PermissionGroup)}
            onClick={() => changeSorting(UsersSortingField.PermissionGroup)}
          >
            Permission Group
          </TableHeadCell>
          <TableHeadCell
            width={TableColumnSize.PhoneAuthentication}
            ascending={getSortingType(UsersSortingField.PhoneAuthentication)}
            onClick={() => changeSorting(UsersSortingField.PhoneAuthentication)}
          >
            Phone Authentication
          </TableHeadCell>
          <TableHeadCell
            withActionsCell
            width={TableColumnSize.AccountStatus}
            ascending={getSortingType(UsersSortingField.AccountStatus)}
            onClick={() => changeSorting(UsersSortingField.AccountStatus)}
          >
            Account Status
          </TableHeadCell>
        </TableHead>
        <TableBody>
          <TableBodyContent
            rows={users}
            renderSkeletonTableRow={(index) => <SkeletonUserRow key={index} />}
            renderTableRow={renderRow}
          />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </>
  );
};

export default UsersTable;
