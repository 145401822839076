import React, { FC } from 'react';
import OverflowedText from 'components/OverflowedText';
import { OverflowedTextProps } from 'components/OverflowedText/OverflowedText';

import styles from './BlankText.module.scss';

export interface BlankTextProps {
  className?: string;
  blankText?: string;
  overflowed?: boolean;
  tag?: 'div' | 'span' | 'p';
  overflowedTextProps?: OverflowedTextProps;
}

const BLANK_TEXT = 'blank';

const BlankText: FC<BlankTextProps> = ({
  children,
  className,
  overflowed,
  tag = 'div',
  blankText = BLANK_TEXT,
  overflowedTextProps,
}) => {
  const style = !children ? { color: styles.blankColor } : undefined;
  const content = children || blankText;

  if (overflowed) {
    return (
      <OverflowedText {...(overflowedTextProps || {})} tag={tag}  style={style} className={className}>
        {content}
      </OverflowedText>
    );
  }

  const TagComponent = tag;

  return (
    <TagComponent style={style} className={className}>
      {content}
    </TagComponent>
  );
}

export default BlankText;
