import { FC } from 'react';
import Collapsible from 'react-collapsible';

interface AccordionProps {
  triggerComponent: JSX.Element;
  open: boolean;
  collapsibleContainerClassName?: string;
  onOpen: (isOpen: boolean) => void;
  className?: string;
}

const Accordion: FC<AccordionProps> = ({
  triggerComponent,
  open,
  children,
  collapsibleContainerClassName,
  onOpen,
  className,
}) => {
  return (
    <Collapsible
      trigger={triggerComponent}
      open={open}
      handleTriggerClick={() => onOpen(!open)}
      contentInnerClassName={collapsibleContainerClassName}
      classParentString={className}
    >
      {children}
    </Collapsible>
  )
}

export default Accordion;
