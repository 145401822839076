import clsx from 'clsx';
import { throttle } from 'lodash';
import React, { useEffect, useMemo, useRef, useState, FC } from 'react';
import { ChevronIcon } from 'static/images';
import styles from './ScrollableTabs.module.scss';

interface ScrollableTabsProps {
  scrollableTabContainerClassName?: string;
  className?: string;
  scrollableElementsClassName?: string;
  additionalScrollElements?: React.ReactNode;
}

const SCROLL_OFFSET = 160;
const THROTTLE_DELAY = 150;

const ScrollableTabs: FC<ScrollableTabsProps> = ({
  children,
  className,
  scrollableElementsClassName,
  scrollableTabContainerClassName,
  additionalScrollElements,
}) => {
  const [displayScrollElements, setDisplayScrollElements] = useState(false);
  const [isLeftChevronDisabled, setIsLeftChevronDisabled] = useState(false);
  const [isRightChevronDisabled, setIsRightChevronDisabled] = useState(false);
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

  const recalculateChevronsDisabledAttributes = () => {
    if (!scrollableContainerRef.current) {
      return;
    }

    const stepsElement = scrollableContainerRef.current;

    setIsLeftChevronDisabled(stepsElement.scrollLeft === 0);
    setIsRightChevronDisabled(
      stepsElement.scrollLeft + stepsElement.clientWidth >= stepsElement.scrollWidth,
    );
  };

  const handleScroll = useMemo(() => {
    return throttle(recalculateChevronsDisabledAttributes, THROTTLE_DELAY);
  }, []);

  useEffect(() => {
    if (!scrollableContainerRef.current) {
      return;
    }

    const { clientWidth, scrollWidth } = scrollableContainerRef.current;

    setDisplayScrollElements(scrollWidth > clientWidth);

    recalculateChevronsDisabledAttributes();
  });

  const handleLeftChevronClick = () => {
    if (!scrollableContainerRef.current) {
      return;
    }

    scrollableContainerRef.current.scrollBy({ left: -SCROLL_OFFSET, behavior: 'smooth' });
  };

  const handleRightChevronClick = () => {
    if (!scrollableContainerRef.current) {
      return;
    }

    scrollableContainerRef.current.scrollBy({ left: SCROLL_OFFSET, behavior: 'smooth' });
  }

  return (
    <div
      className={clsx(
        styles.scrollableTabContainer,
        !isLeftChevronDisabled && styles.scrollableTabContainerWithWhiteShadow,
        scrollableTabContainerClassName,
      )}
    >
      <div className={clsx(styles.overflowContainer, className)} ref={scrollableContainerRef} onScroll={handleScroll}>
        {children}
      </div>
      {displayScrollElements && (
        <div
          className={clsx(
            styles.scrollElements,
            !isRightChevronDisabled && styles.scrollElementsWithWhiteShadow,
            scrollableElementsClassName,
          )}
        >
          <div className={styles.scrollChevronsContainer}>
            <ChevronIcon
              className={clsx(styles.chevronLeft, isLeftChevronDisabled && styles.disabledChevron)}
              onClick={handleLeftChevronClick}
            />
            <ChevronIcon
              className={clsx(styles.chevronRight, isRightChevronDisabled && styles.disabledChevron)}
              onClick={handleRightChevronClick}
            />
          </div>
          {additionalScrollElements}
        </div>)}
    </div>
  );
};

export default ScrollableTabs;
