import React, { FC, SVGProps, useRef } from 'react';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import ChartPointGroup from './ChartPointGroup';
import { uniqueId } from 'lodash';
import { ChartPointProps } from './ChartPoint';
import * as allCurves from '@visx/curve';
import { Point } from './Types';
import styles from './components.module.scss';

interface ChartLineProps extends SVGProps<SVGGElement> {
  getX?: (value: Point) => number;
  getY?: (value: Point) => number;
  data: ChartPointProps[];
  gradientTargetY: number;
  padding?: number;
  hidePoints?: boolean;
  disabled?: boolean;
}

const ChartLine: FC<ChartLineProps> = ({
  getX,
  getY,
  data,
  padding = 0,
  gradientTargetY,
  hidePoints = false,
  disabled,
  ...svgProps
}) => {
  const gradientId = useRef(`chart-line-gradient-${uniqueId()}`);
  const disabledGradientId = useRef(`chart-line-disabled-gradient-${uniqueId()}`);

  const gradientData = [
    { x: data[0].x - padding, y: gradientTargetY },
    { x: data[0].x - padding, y: data[0].y },
    ...data,
    { x: data[data.length - 1].x + padding, y: data[data.length - 1].y },
    { x: data[data.length - 1].x + padding, y: gradientTargetY },
  ];

  const gradientFrom = disabled ? styles.gradientFromDisabled : styles.gradientFrom;
  const gradientTo = disabled ? styles.gradientToDisabled : styles.gradientTo;
  const lineColor = disabled ? styles.lineColorDisabled : styles.lineColor;

  return (
    <Group {...svgProps}>
      <LinearGradient id={gradientId.current} from={gradientFrom} to={gradientTo} />
      <LinearGradient id={disabledGradientId.current} from="rgba(255, 255, 255, 0)" to="rgba(255, 255, 255, 1)" />
      {data.length > 1 && (
        <LinePath
          x={getX}
          y={getY}
          fill={`url(#${gradientId.current})`}
          opacity={0.2}
          data={gradientData}
          strokeWidth={0}
          curve={allCurves.curveLinear}
        />
      )}
      <LinePath
        x={getX}
        y={getY}
        data={data}
        stroke={lineColor}
        strokeWidth={styles.lineWidth}
        curve={allCurves.curveLinear}
      />
      {disabled && (
        <LinePath
          x={getX}
          y={getY}
          data={data.slice(0, 2)}
          stroke={`url(#${disabledGradientId.current})`}
          strokeWidth={styles.lineWidth}
          curve={allCurves.curveLinear}
        />
      )}
      {disabled && (
        <LinePath
          x={getX}
          y={getY}
          data={data.slice(-2)}
          stroke={`url(#${disabledGradientId.current})`}
          strokeWidth={styles.lineWidth}
          curve={allCurves.curveLinear}
        />
      )}
      {!hidePoints && <ChartPointGroup data={disabled ? data.slice(1, -1) : data} disabled={disabled} />}
    </Group>
  );
};

export default ChartLine;
