import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import useSticky from 'hooks/useSticky';
import useCombinedRefs from 'hooks/useCombinedRefs';

import styles from './StickyContainer.module.scss';

interface ChildrenParams {
  isStuck?: boolean;
}

interface StickyContainerProps {
  sticky?: boolean;
  stickyRootElement?: HTMLElement;
  stickyTopOffset?: number;
  className?: string;
  children: (params: ChildrenParams) => React.ReactNode;
}

const getTopMargin = (element: HTMLElement, offset: number) => element.offsetHeight - offset;

const StickyContainer = forwardRef<HTMLDivElement | null, StickyContainerProps>(({
  sticky = false,
  stickyRootElement = document.body,
  stickyTopOffset = 0,
  className,
  children,
}, ref) => {
  const [stickyRootElementTopMargin, setStickyRootElementTopMargin] = useState(
    getTopMargin(stickyRootElement, stickyTopOffset),
  );

  const { isStuck, elementRef } = useSticky<HTMLDivElement>({
    rootMargin: `0px 0px -${stickyRootElementTopMargin}px 0px`,
    root: stickyRootElement,
  });

  const containerRef = useCombinedRefs(ref, elementRef);

  useEffect(() => {
    const handleResize = () =>
      sticky && setStickyRootElementTopMargin(getTopMargin(stickyRootElement, stickyTopOffset));

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [stickyRootElementTopMargin]);

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container, className, sticky && styles.stickyContainer, isStuck && styles.stuck)}
      style={{
        ...(sticky ? { top: stickyTopOffset } : {}),
      }}
    >
      {children({
        isStuck,
      })}
    </div>
  );
});

export default StickyContainer;
