import SearchWithFilters from 'components/SearchWithFilters/SearchWithFilters';
import {
  DocumentTypesDashboardFiltersState,
} from 'GeneralSettings/ui/DocumentTypesDashboard/Filters/DocumentTypesDashboardFiltersStore';
import { User } from 'api/UserApi';
import { FC, useMemo } from 'react';
import Button from 'components/Button';
import HeaderWithTooltip from '../../../Base/HeaderWithTooltip';

import styles from './DocumentTypeDashboardHeader.module.scss';

interface DocumentTypeDashboardHeaderProps {
  filters: DocumentTypesDashboardFiltersState;
  members: User[];
  noItems: boolean;
  className: string;
  onSearch: (search: string) => void;
  onChangeSelectedUsers: (members: User[]) => void;
  onAddDocumentType: () => void;
}

const DocumentTypeDashboardHeader: FC<DocumentTypeDashboardHeaderProps> = ({
  filters,
  members,
  className,
  onSearch,
  noItems,
  onChangeSelectedUsers,
  onAddDocumentType,
}) => {
  const selectedUsers = useMemo(() => {
    return members?.filter((member) => filters.teamMembers.includes(member.id));
  }, [filters.teamMembers, members]);

  const renderSearchBar = () => {
    if (noItems && filters.teamMembers.length === 0) {
      return null;
    }

    return (
      <SearchWithFilters
        search={filters.search}
        onSearch={onSearch}
        onChangeSelectedUsers={onChangeSelectedUsers}
        availableUsersForFilter={members}
        selectedUsers={selectedUsers}
        usersLoading={members.length === 0}
        className={styles.searchBar}
      />
    )
  };

  const TITLE_TOOLTIP = <>A template with predefined <br />
    fields to be extracted during <br />
    the data extraction process.</>;

  return (
    <div className={className}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <HeaderWithTooltip
            tooltip={TITLE_TOOLTIP}
            type="h2"
            className={styles.title}
          >Document Types</HeaderWithTooltip>
        </div>
        <Button kind='primary' onClick={onAddDocumentType} className={styles.button}>Add Document Type</Button>
      </div>
      {renderSearchBar()}
    </div>
  )
}

export default DocumentTypeDashboardHeader;
