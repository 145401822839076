import clsx from 'clsx';
import TextInput from 'components/TextInput';
import { TextInputPropsSingleLine } from 'components/TextInput/TextInput';
import React, { FC, FocusEvent, useState } from 'react';
import { ReactComponent as CopyImage } from 'static/images/copy.svg';
import copy from 'copy-to-clipboard';
import WrapperWithTooltip from 'components/Tooltip';
import { CheckedImage } from 'static/images';
import styles from './InputWithCopyButton.module.scss';

enum State {
  Default = 'Default',
  Copied = 'Copied',
}

interface InputWithCopyButtonProps extends TextInputPropsSingleLine {
  value: string;
}

const InputWithCopyButton: FC<InputWithCopyButtonProps> = ({ className, ...inputProps }) => {
  const [state, setState] = useState(State.Default);

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.selectionStart = 0;
    event.target.selectionEnd = event.target.value.length;
  };

  const handleCopyClick = () => {
    if (state === State.Copied) {
      return;
    }

    const isCopied = copy(inputProps.value!);

    if (isCopied) {
      setState(State.Copied);

      setTimeout(() => setState(State.Default), 2000);
    }
  };

  return (
    <div className={clsx(styles.container, className)}>
      <TextInput
        {...inputProps}
        containerClassName={styles.inputContainer}
        className={styles.input}
        onFocus={handleOnFocus}
      />
      <WrapperWithTooltip tooltip={state === State.Copied ? 'Copied!' : 'Copy Key'}>
        <div className={styles.iconContainer} onClick={handleCopyClick}>
          {state === State.Default && <CopyImage className={styles.copyIcon} />}
          {state === State.Copied && <CheckedImage className={styles.checkedIcon} />}
        </div>
      </WrapperWithTooltip>
    </div>
  );
};

export default InputWithCopyButton;
