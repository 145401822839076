import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { ReduxState } from 'types/ReduxState';
import { User, UsersDataFilter, UsersSortingField, UsersSortingType, UserStatus } from 'api/UserApi';
import useRouteBuilder from 'hooks/useRouteBuilder';
import {
  changeFilters,
  changeSearchValue,
  changeSortingType,
  pagination,
  resetState,
  usersDashboardActionOrigin,
} from 'GeneralSettings/ui/UsersDashboard/UsersDashboardStore';
import { createGetUsersSelector } from 'Users/Selectors';
import { AppRoutes } from 'routes/RouteBuilder';
import { getUsers, updateUser } from 'Users/Thunks';
import UsersDashboard from 'components/Users/Dashboards/UsersDashboard/UsersDashboard';
import useStateReset from 'hooks/useStateReset';

const REQUEST_USERS_DELAY = 300;

export interface UsersDashboardConnectorProps {
  showFiltersPanel: () => void;
  onClearFilters: () => void;
  onCancelUserInvitation: (user: User) => void;
}

const UsersDashboardConnector: FC<UsersDashboardConnectorProps> = ({
  showFiltersPanel,
  onClearFilters,
  onCancelUserInvitation,
}) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const routeBuilder = useRouteBuilder();

  const filters = useSelector((state: ReduxState) => state.generalSettings.ui.usersDashboard.filters);
  const sortingType = useSelector((state: ReduxState) => state.generalSettings.ui.usersDashboard.sortingType);

  useStateReset(resetState);

  const params = {
    search: filters.search,
    sortingType,
    statuses: filters.statuses,
    teamMembers: filters.teamMembers,
    permissionGroup: filters.permissionGroup ?? undefined,
    isMfaEnabled: filters.isMfaEnabled ?? undefined,
  };

  const usersIds = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  const getUsersSelector = useMemo(() => {
    return createGetUsersSelector(usersIds);
  }, [usersIds]);

  const users = useSelector((state: ReduxState) => getUsersSelector(state));

  const requestUsers = useCallback((filtersToApply: UsersDataFilter, sortingTypeToApply: UsersSortingType) => {
    dispatchWithUnwrap(getUsers({
      filters: filtersToApply,
      sortingType: sortingTypeToApply,
      actionOrigin: usersDashboardActionOrigin,
    }));
  }, []);

  useEffect(() => {
    requestUsers({
      search: filters.search,
      statuses: filters.statuses,
      permissionGroup: filters.permissionGroup ?? undefined,
      isMfaEnabled: filters.isMfaEnabled ?? undefined,
      offset: 0,
      count: pagination.getDefaultItemsCountPerPage(),
    }, sortingType);
  }, [
    filters.filtersId,
    filters.permissionGroup,
    filters.isMfaEnabled,
    filters.statuses,
    filters.filtersId,
    sortingType,
    requestUsers,
    pagination,
  ]);

  const debouncedRequestUsers = useMemo(() => {
    return debounce(requestUsers, REQUEST_USERS_DELAY);
  }, [requestUsers]);

  const handleUpdateUserStatus = async (user: User, status: UserStatus) => {
    await dispatchWithUnwrap(updateUser({ id: user.id, status }));
  };

  const handleSearch = (updatedSearchValue: string) => {
    dispatch(changeSearchValue(updatedSearchValue));

    debouncedRequestUsers({
      search: updatedSearchValue,
      statuses: filters.statuses,
      permissionGroup: filters.permissionGroup ?? undefined,
      isMfaEnabled: filters.isMfaEnabled ?? undefined,
      offset: 0,
      count: pagination.getDefaultItemsCountPerPage(),
    }, sortingType);
  };

  const handleSort = (field: UsersSortingField, ascending: boolean) => {
    dispatch(changeSortingType({ field, ascending }));
  };

  const generateEditUserPageLink = (user: User) => {
    return routeBuilder.withParams({ edit: user.id }).build(AppRoutes.teamMembers);
  };

  const generateAddUserPageLink = () => {
    return routeBuilder.withParams({ new: '' }).build(AppRoutes.teamMembers);
  }

  const handleEditUser = (user: User) => routeBuilder.go(generateEditUserPageLink(user));

  const handleAddUserButtonClick = () => {
    routeBuilder.go(generateAddUserPageLink());
  };

  const handleChangeToggle = (active: boolean) => {
    dispatch(changeFilters({
      statuses: active ? Object.values(UserStatus) : [UserStatus.Active, UserStatus.Pending],
    }));
  };

  return (
    <UsersDashboard
      users={users}
      paginationProps={paginationProps}
      onAddUserButtonClick={handleAddUserButtonClick}
      onEditUser={handleEditUser}
      onCancelUserInvitation={onCancelUserInvitation}
      onUpdateUserStatus={handleUpdateUserStatus}
      filters={filters}
      onSearch={handleSearch}
      onChangeToggle={handleChangeToggle}
      sortingType={sortingType}
      onSort={handleSort}
      getEditUserPageLink={generateEditUserPageLink}
      onFiltersButtonClick={showFiltersPanel}
      addNewUserPageLink={generateAddUserPageLink()}
      onClearFilters={onClearFilters}
    />
  );
};

export default UsersDashboardConnector;
