import React, { FC } from 'react'
import { TableHead, TableHeadCell } from 'components/Table';
import { MobileTableColumnSize } from '../TableColumnSize';
import { DataExtractionSortingField } from 'api/DataExtractionApi';

interface DataExtractionMobileHeaderRowProps {
  className?: string;
  getSortingType: (field: DataExtractionSortingField) => boolean | undefined;
  onSortingChange: (field: DataExtractionSortingField) => void;
}

const DataExtractionMobileHeaderRow: FC<DataExtractionMobileHeaderRowProps> = ({
  className,
  getSortingType,
  onSortingChange,
}) => {
  return (
    <TableHead sticky className={className}>
      <TableHeadCell
        width={MobileTableColumnSize.Date}
        ascending={getSortingType(DataExtractionSortingField.CreatedAt)}
        onClick={() => onSortingChange(DataExtractionSortingField.CreatedAt)}
      >
        Date
      </TableHeadCell>
    </TableHead>
  )
}

export default DataExtractionMobileHeaderRow;
