import React, { FC, FormEvent, useEffect, useState } from 'react';
import { UpdateUserPrams, User, UserStatus } from 'api/UserApi';
import TextInput from 'components/TextInput';
import ButtonLikeToggle from 'components/ButtonLikeToggle';
import { Option } from 'components/SelectInput/SelectInput';
import Label from 'components/Label';
import useTimer from 'hooks/useTimer';
import TimerMessage from 'TimerMessage/TimeMessage';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import Button from 'components/Button';
import AutoCompletion from 'components/AutoCompletion';
import { PermissionGroup } from 'enums/PermissionGroup';

import styles from './EditUserForm.module.scss';

export interface EditUserFormProps {
  user: User;
  onSubmit: (params: UpdateUserPrams) => void;
  selfEdit: boolean;
  permissionGroups: Option[];
  isUserUpdateInProgress?: boolean;
  onResendInvite: () => Promise<void>;
  onCancelInvitation: () => void;
}

const RESEND_INVITATION_TIMEOUT = 60;

const EditUserForm: FC<EditUserFormProps> = ({
  user,
  selfEdit,
  permissionGroups,
  isUserUpdateInProgress,
  onResendInvite,
  onSubmit,
  onCancelInvitation,
}) => {
  const { seconds, restart, isRunning } = useTimer(RESEND_INVITATION_TIMEOUT, { autoStart: false });

  const [permissionGroup, setPermissionGroup] = useState(user.permissionGroup);
  const [status, setStatus] = useState(user.status);

  useEffect(() => {
    setPermissionGroup(user.permissionGroup);
    setStatus(user.status);
  }, [user]);

  const handlePermissionGroupInputValueChange = ({ value }: Option) => {
    setPermissionGroup(value as PermissionGroup);
  };

  const handleSubmitButtonClick = async (event: FormEvent) => {
    event.preventDefault();

    onSubmit({
      ...(status !== user.status ? { status } : {}),
      ...(permissionGroup !== user.permissionGroup ? { permissionGroup } : {}),
    });
  };

  const handleStatusChange = async () => {
    setStatus(status !== UserStatus.Active ? UserStatus.Active : UserStatus.Inactive);
  };

  const handleResendInviteClick = async () => {
    await onResendInvite();

    restart();
  };

  const isDataChanged = user.status !== status || permissionGroup !== user.permissionGroup;

  const getStatusName = () => {
    switch (status) {
      case UserStatus.Active: return 'Active';
      case UserStatus.Pending: return 'Pending';
      case UserStatus.Inactive: return 'Inactive';
      default: return '';
    }
  };

  return (
    <div>
      <TextInput
        containerClassName={styles.firstNameInput}
        type="text"
        labelTitle="First Name"
        value={user.firstName || ''}
        disabled
        required
      />
      <TextInput type="text" labelTitle="Last Name" value={user.lastName || ''} disabled required />
      <TextInput type="text" labelTitle="Email" value={user.email} disabled required />
      <AutoCompletion
        required
        labelTitle="Permissions Group"
        onChange={handlePermissionGroupInputValueChange}
        value={permissionGroup}
        options={permissionGroups}
        disabled={selfEdit}
        hideClearIcon
      />
      <div className={styles.accountStatusSection}>
        <Label className={styles.accountStatusSectionLabel} required>
          Account Status
        </Label>
        <div className={styles.accountStatusSectionContent}>
          <h5>{getStatusName()}</h5>
          {user.status === UserStatus.Pending && (
            <ButtonWithLoadingState
              className={styles.resendInviteButton}
              kind="secondary"
              disabled={isRunning}
              onClick={handleResendInviteClick}
            >
              Re-Send Invitation
            </ButtonWithLoadingState>
          )}
          {user.status !== UserStatus.Pending && <ButtonLikeToggle
            checked={status !== UserStatus.Inactive}
            className={styles.changeStatusButton}
            onChange={handleStatusChange}
            disabled={selfEdit}
          >
            {status === UserStatus.Active ? 'Deactivate Account' : 'Activate Account'}
          </ButtonLikeToggle>}
          {user.status === UserStatus.Pending && <Button
              kind="warning"
              onClick={onCancelInvitation}
              disabled={selfEdit}
          >
            Cancel Invitation
          </Button>}
        </div>
        {isRunning && (
          <TimerMessage>
            You can re-send the invitation again in <strong>{isRunning ? seconds : 0}</strong>s
          </TimerMessage>
        )}
      </div>
      <Button
        type="submit"
        kind="primary"
        size="form"
        offset="form"
        disabled={!isDataChanged}
        isLoading={isUserUpdateInProgress}
        onClick={handleSubmitButtonClick}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default EditUserForm;
