
import { useDispatch } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import useStateReset from 'hooks/useStateReset';
import { WEBHOOK_EVENTS_PER_PAGE_DEFAULT } from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/Pagination';
import EventsDashboardActionType from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/EventsDashboardActionType';
import {
  WebhookEvent,
  WebhookEventDataFilter,
  WebhookEventSortingField,
  WebhookEventSortingType,
} from 'api/WebhookEventsApi';
import { getWebhookEvents } from 'Webhooks/Events/Thunks';
import {
  eventsDashboardActionOrigin,
  pagination,
  changeSearchValue,
  changeSortingType,
  showFiltersPanel,
  clearFilters,
} from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/EventsDashboardStore';
import { FC, useEffect, useMemo } from 'react';
import { createGetEventsSelector } from 'Webhooks/Events/Selectors';
import { debounce } from 'lodash';
import EventsDashboard from './EventsDashboard';

interface EventsDashboardConnectorProps {
  onDelete: (id: string) => void;
  onResend: (id: string) => void;
  onDisplayDetails: (event: WebhookEvent) => void;
}

const EventsDashboardConnector: FC<EventsDashboardConnectorProps> = ({
  onDelete,
  onResend,
  onDisplayDetails,
}) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { sortingType, filters } = useSelector((state: ReduxState) => (
    state.generalSettings.ui.webhooksDashboards.eventsDashboard
  ));

  useStateReset(EventsDashboardActionType.ResetState);

  const initialFiltersAttributes = { offset: 0, count: WEBHOOK_EVENTS_PER_PAGE_DEFAULT };

  const params = {
    sortingType,
    ...filters,
  };

  const requestWebhookEvents = (filtersToApply: WebhookEventDataFilter, sortingTypeToApply: WebhookEventSortingType) => {
    dispatchWithUnwrap(getWebhookEvents({
      filters: filtersToApply,
      sortingType: sortingTypeToApply,
      actionOrigin: eventsDashboardActionOrigin,
    }));
  };

  const webhookEventsIds = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  const getWebhookEventsSelector = useMemo(() => createGetEventsSelector(webhookEventsIds), [webhookEventsIds]);

  const webhookEvents = useSelector(getWebhookEventsSelector);

  useEffect(() => {
    requestWebhookEvents({
      ...filters,
      dueCreatedDateFrom: filters.dueCreatedDateRange.from,
      dueCreatedDateTo: filters.dueCreatedDateRange.to,
      ...initialFiltersAttributes,
    }, sortingType);
  }, [filters, sortingType]);

  const debouncedSearch = useMemo(() => {
    return debounce(requestWebhookEvents, 300);
  }, []);

  const handleSearchChange = (search: string) => {
    dispatch(changeSearchValue(search));

    debouncedSearch({
      ...filters,
      ...initialFiltersAttributes,
      dueCreatedDateFrom: filters.dueCreatedDateRange.from,
      dueCreatedDateTo: filters.dueCreatedDateRange.to,
      search: search,
    }, sortingType);
  };

  const handleSort = (sortingField: WebhookEventSortingField, ascending: boolean) => {
    dispatch(changeSortingType({
      field: sortingField,
      ascending,
    }));

    requestWebhookEvents(
      {
        ...filters,
        ...initialFiltersAttributes,
        dueCreatedDateFrom: filters.dueCreatedDateRange.from,
        dueCreatedDateTo: filters.dueCreatedDateRange.to,
      },
      { field: sortingField, ascending },
    );
  };

  const areFiltersChanged = () => {
    return filters.dueCreatedDateRange.to !== null
      || filters.dueCreatedDateRange.from !== null
      || !!filters.eventTypes.length
      || !!filters.statuses.length
      || filters.documentTypeId !== null;
  };

  const handleFiltersButtonClick = () => {
    dispatch(showFiltersPanel());
  }

  const handleClearFilters = () => {
    dispatch(clearFilters());
  }

  return (
    <EventsDashboard
      events={webhookEvents}
      filters={filters}
      sortingType={sortingType}
      paginationProps={paginationProps}
      areFiltersChanged={areFiltersChanged()}
      onSort={handleSort}
      onSearch={handleSearchChange}
      onClearFilters={handleClearFilters}
      onFiltersButtonClick={handleFiltersButtonClick}
      onDelete={onDelete}
      onResend={onResend}
      onDisplayDetails={onDisplayDetails}
    />
  )
}

export default EventsDashboardConnector;
