import React, { FC } from 'react';
import { CloseImage } from 'static/images';
import clsx from 'clsx';

import styles from './RightSidePopupContentLayout.module.scss';

interface RightSidePopupContentLayoutProps {
  title: string | React.ReactElement;
  titleButtonName?: string;
  titleClassName?: string;
  onTitleButtonClick?: () => void;
  onClose: () => void;
}

const RightSidePopupContentLayout: FC<RightSidePopupContentLayoutProps> = ({
  children,
  title,
  titleButtonName,
  titleClassName,
  onClose,
  onTitleButtonClick,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {typeof title === 'string' && <h3 className={clsx(styles.title, titleClassName)}>{title}</h3>}
        {typeof title !== 'string' && title}
        {titleButtonName && (
          <button type="button" className={styles.titleButton} onClick={onTitleButtonClick}>
            {titleButtonName}
          </button>
        )}
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <CloseImage />
        </button>
      </div>
      {children}
    </div>
  );
};

export default RightSidePopupContentLayout;
