import { DocumentType, DocumentTypeSortingField } from 'api/DocumentTypeApi';
import { useEffect, useState } from 'react';
import { documentTypeApi } from 'stores/api';

const useDocumentTypes = () => {
  const [documentTypes, setDocumentTypes] = useState<DocumentType[] | null>(null);

  const load = async () => {
    const loadedDocumentTypes = await documentTypeApi.search({
    }, {
      ascending: true,
      field: DocumentTypeSortingField.Name,
    });

    setDocumentTypes(loadedDocumentTypes.items);
  };

  useEffect(() => {
    load();
  }, []);

  return documentTypes;
};

export default useDocumentTypes;
