/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import TopRightLink from 'components/Auth/TopRightInputLink';
import validateSignInForm, { ValidateSignInFormResult } from './validateSignInForm';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import { SignInData } from 'api/AuthApi';
import isAutofilled, { AUTOFILL_DETECTION_TIMEOUT } from 'utils/isAutofilled';
import AuthInput from '../Inputs/AuthInput/AuthInput';
import AuthPasswordInput from '../Inputs/AuthPasswordInput/AuthPasswordInput';
import AuthButton from '../Inputs/AuthButton/AuthButton';
import { useRecaptcha } from '../../../CaptchaProvider';
import CaptchaAction from '../../../enums/CaptchaAction';
import ExternalProvidersAuthSection from '../ExternalProvidersAuthSection';

import styles from './SignInForm.module.scss';

interface SignInFormProps {
  onSubmit: (userCredentials: SignInData) => Promise<void>;
}

const SignInForm: FC<SignInFormProps> = ({ onSubmit }) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<ValidateSignInFormResult>({});
  const [hasAutofilled, setHasAutofilled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const executeCaptcha = useRecaptcha();

  const handleChangeEmailValue = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({ password: errors.password });

    setEmail(e.target.value);
  };

  const handleChangePasswordValue = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({ email: errors.email });

    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    const validationResult = validateSignInForm({ email, password });

    setErrors(validationResult);

    if (Object.keys(validationResult).length === 0) {
      const token = await executeCaptcha(CaptchaAction.SignIn);

      if (!token) {
        return;
      }

      await onSubmit({ password, email, token });
    }
  };

  const handleSignInStarted = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const handleSignInFailed = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    setTimeout(() => {
      setHasAutofilled(
        !!emailInputRef.current
        && !!passwordInputRef.current
        && isAutofilled(emailInputRef.current)
        && isAutofilled(passwordInputRef.current),
      );
    }, AUTOFILL_DETECTION_TIMEOUT);
  }, []);

  const submitIsDisabled = (Object.keys(validateSignInForm({ email, password })).length > 0 && !hasAutofilled) || isLoading;

  return (
    <div className={styles.loginFormContent}>
      <ExternalProvidersAuthSection onSignInStarted={handleSignInStarted} onSignInFailed={handleSignInFailed} />
      <AuthInput
        inputRef={emailInputRef}
        labelTitle="Email Address"
        onChange={handleChangeEmailValue}
        value={email}
        tabIndex={1}
        errorMessage={errors.email}
        containerClassName={styles.emailInput}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordInputRef, 'focus')}
        autoFocus
        required
      />
      <AuthPasswordInput
        ref={passwordInputRef}
        labelTitle="Password"
        topRightElement={<TopRightLink path="/auth/forgot">Reset Password</TopRightLink>}
        tabIndex={2}
        onChange={handleChangePasswordValue}
        value={password}
        errorMessage={errors.password}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        required
      />
      <AuthButton
        ref={submitButtonRef}
        disabled={submitIsDisabled}
        tabIndex={3}
        kind="primary"
        size="form"
        onClick={handleSubmit}
        fullWidth
        className={styles.submitButton}
        isLoading={isLoading}
      >
        Sign In
      </AuthButton>
    </div>
  );
};

export default SignInForm;
