import React, { DetailedHTMLProps, HTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import TagCore from './TagCore';

import styles from './Tag.module.scss';

export type TagColor = 'green' | 'gray' | 'red' | 'blue' | 'yellow' | 'white' | 'violet' | 'electric-blue';

type HTMLDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface TagProps extends HTMLDivProps {
  color?: TagColor;
  medium?: boolean;
  className?: string;
  invertColors?: boolean;
}

const getColorTagClassName = (type: TagColor): string => {
  switch (type) {
    case 'green':
      return styles.green;
    case 'gray':
      return styles.gray;
    case 'red':
      return styles.red;
    case 'blue':
      return styles.blue;
    case 'yellow':
      return styles.yellow;
    case 'white':
      return styles.white;
    case 'electric-blue':
      return styles.electricBlue;
    default:
      return styles.gray;
  }
};

const Tag = forwardRef<HTMLDivElement, TagProps>(({ medium, color = 'gray', className, invertColors, ...tagProps }, ref) => {
  return (
    <TagCore
      ref={ref}
      className={clsx(getColorTagClassName(color), invertColors && styles.inverted, className)}
      medium={medium}
      {...tagProps}
    />
  );
});

export default Tag;
