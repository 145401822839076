import { useEffect } from 'react';
import MainLayout, { PageContent } from 'components/MainLayout';
import PageWrapperWithFooter from 'components/MainLayout/PageWrapperWithFooter';
import HomeDashboard from 'components/HomeDashboard';
import { ReduxState } from 'types/ReduxState';
import { useSelector } from 'react-redux';
import { getPerformanceOverview, getPerformanceOverviewDocumentTypes } from 'Dashboard/ActionCreator';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import styles from './Home.module.scss';

const Home = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const {
    performanceOverview,
    filters,
    documentTypes,
  } = useSelector((state: ReduxState) => state.dashboard);

  useEffect(() => {
    dispatchWithUnwrap(getPerformanceOverviewDocumentTypes(filters.documentTypes));
  }, [filters.documentTypes]);

  useEffect(() => {
    dispatchWithUnwrap(getPerformanceOverview(filters.performanceOverview));
  }, [filters.performanceOverview]);

  return (
    <MainLayout>
      <PageWrapperWithFooter>
        <PageContent className={styles.pageContent} noPadding>
          <HomeDashboard
            filters={filters}
            performanceOverview={performanceOverview}
            documentTypes={documentTypes}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Home;
