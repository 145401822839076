import {
  pagination as endpointsDashboardPagination,
  endpointsDashboardActionOrigin,
} from './EndpointsDashboardStore';
import { AnyAction, MiddlewareAPI, Dispatch, Middleware } from 'redux';
import { ReduxState } from 'types/ReduxState';
import { isFulfilled } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT } from './Pagination';
import { WebhookEndpointsApi } from 'api/WebhookEndpointsApi';
import { setWebhookEndpoints } from 'Webhooks/Endpoints/EndpointsStore';
import {
  createWebhookEndpoint,
  deleteWebhookEndpoint,
  getWebhookEndpoints,
  updateWebhookEndpoint,
} from 'Webhooks/Endpoints/Thunks';

const createEndpointsDashboardMiddlewares = (
  api: WebhookEndpointsApi,
  pagination: typeof endpointsDashboardPagination,
  path: string | string[],
  actionOrigins: string[],
) => {
  const paginationMiddleware = pagination.getItemsFetcherMiddleware(async (params, dispatch) => {
    const { items, total } = await api.find(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
      },
      params.sortingType,
    );

    dispatch(setWebhookEndpoints(items));

    return {
      items: items.map((documentType) => documentType.id),
      total,
    };
  });

  const updatesMiddleware = (({ dispatch, getState }: MiddlewareAPI<Dispatch<AnyAction>, ReduxState>) => (
    next: (action: AnyAction) => any,
  ) => (action: AnyAction) => {
    const result = next(action);

    if (
      !isFulfilled(createWebhookEndpoint)(action) &&
      !isFulfilled(updateWebhookEndpoint)(action) &&
      !isFulfilled(deleteWebhookEndpoint)(action)
    ) {
      return result;
    }

    if (!action.meta.actionOrigin || !actionOrigins.includes(action.meta.actionOrigin)) {
      return result;
    }

    const state = getState();
    const { filters, sortingType } = get(state, path);

    dispatch(getWebhookEndpoints({
      filters: {
        search: filters.search,
        offset: 0,
        count: WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT,
      },
      sortingType,
      actionOrigin: endpointsDashboardActionOrigin,
    }) as unknown as AnyAction)

    return result;
  }) as Middleware;

  return {
    paginationMiddleware,
    updatesMiddleware,
  }
};

export default createEndpointsDashboardMiddlewares;
