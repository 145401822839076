import { ActionCreatorWithPayload, createAction, createReducer } from '@reduxjs/toolkit';
import { ReducerWithInitialState } from '@reduxjs/toolkit/dist/createReducer';
import { RemoveUndefinedFields } from 'types/RemoveUndefinedFields';
import { nanoid } from 'nanoid';

export enum DashboardFiltersActionType {
  ChangeSearchValue = 'ChangeSearchValue',
  ChangeFilters = 'ChangeFilters',
  ClearFilters = 'ClearFilters',
  ChangeMembers = 'ChangeMembers',
  ShowFiltersPanel = 'ShowFiltersPanel',
  HideFiltersPanel = 'HideFiltersPanel',
}

export interface BaseDashboardFiltersState {
  filtersId: string;
  showFiltersPanel: boolean;
  search: string;
  teamMembers: string[] | null;
}

interface BaseDashboardFiltersSliceActions {
  [DashboardFiltersActionType.ChangeSearchValue]?: string;
  [DashboardFiltersActionType.ChangeFilters]?: string;
  [DashboardFiltersActionType.ChangeMembers]?: string;
  [DashboardFiltersActionType.ClearFilters]?: string;
  [DashboardFiltersActionType.ShowFiltersPanel]?: string;
  [DashboardFiltersActionType.HideFiltersPanel]?: string;
}

type GetChangeSearchValue<Actions> = Actions extends {
  [DashboardFiltersActionType.ChangeSearchValue]: infer ActionType extends string;
} ? ActionCreatorWithPayload<string, ActionType> : undefined;

type GetChangeFiltersValue<Actions extends BaseDashboardFiltersSliceActions, Payload> = Actions extends {
  [DashboardFiltersActionType.ChangeFilters]: infer ActionType extends string;
} ? ActionCreatorWithPayload<Payload, ActionType> : undefined;

type GetChangeMembersValue<Actions> = Actions extends {
  [DashboardFiltersActionType.ChangeMembers]: infer ActionType extends string;
} ? ActionCreatorWithPayload<string[] | null, ActionType> : undefined;

type GetClearFiltersValue<Actions> = Actions extends {
  [DashboardFiltersActionType.ClearFilters]: infer ActionType extends string;
} ? ActionCreatorWithPayload<void, ActionType> : undefined;

type GetShowFiltersPanelValue<Actions> = Actions extends {
  [DashboardFiltersActionType.ShowFiltersPanel]: infer ActionType extends string;
} ? ActionCreatorWithPayload<void, ActionType> : undefined;

type GetHideFiltersPanelValue<Actions> = Actions extends {
  [DashboardFiltersActionType.HideFiltersPanel]: infer ActionType extends string;
} ? ActionCreatorWithPayload<void, ActionType> : undefined;

interface CreateDashboardFiltersSliceResult<
  DashboardFiltersState extends BaseDashboardFiltersState,
  DashboardFiltersSliceActions extends BaseDashboardFiltersSliceActions,
  ChangeFiltersPayload,
> {
  actions: RemoveUndefinedFields<{
    changeSearchValue: GetChangeSearchValue<DashboardFiltersSliceActions>;
    changeFilters: GetChangeFiltersValue<DashboardFiltersSliceActions, ChangeFiltersPayload>;
    changeMembers: GetChangeMembersValue<DashboardFiltersSliceActions>;
    clearFilters: GetClearFiltersValue<DashboardFiltersSliceActions>;
    showFiltersPanel: GetShowFiltersPanelValue<DashboardFiltersSliceActions>;
    hideFiltersPanel: GetHideFiltersPanelValue<DashboardFiltersSliceActions>;
  }>;
  reducer: ReducerWithInitialState<DashboardFiltersState>;
  getInitialState: () => DashboardFiltersState;
}

export const getDashboardFiltersInitialState = (): BaseDashboardFiltersState => ({
  filtersId: nanoid(),
  showFiltersPanel: false,
  search: '',
  teamMembers: null,
});

const getActionByActionType = <Payload = void>(
  namespace: string,
  actionType: DashboardFiltersActionType,
  actionTypes: BaseDashboardFiltersSliceActions,
) => {
  return actionTypes[actionType] ? createAction<Payload>(`${namespace}/${actionTypes[actionType]}`) : undefined;
};

const createDashboardFiltersSlice = <
  DashboardFiltersState extends BaseDashboardFiltersState,
  ChangeFiltersPayload extends {},
  DashboardFiltersSliceActions extends BaseDashboardFiltersSliceActions = BaseDashboardFiltersSliceActions,
>(
  namespace: string,
  getInitialState: () => DashboardFiltersState,
  actionTypes: BaseDashboardFiltersSliceActions,
): CreateDashboardFiltersSliceResult<DashboardFiltersState, DashboardFiltersSliceActions, ChangeFiltersPayload> => {
  const changeSearchValue = getActionByActionType<string>(namespace, DashboardFiltersActionType.ChangeSearchValue, actionTypes);
  const changeFilters = getActionByActionType<ChangeFiltersPayload>(
    namespace,
    DashboardFiltersActionType.ChangeFilters,
    actionTypes,
  );
  const clearFilters = getActionByActionType(namespace, DashboardFiltersActionType.ClearFilters, actionTypes);
  const changeMembers = getActionByActionType<string[] | null>(namespace, DashboardFiltersActionType.ChangeMembers, actionTypes);
  const showFiltersPanel = getActionByActionType(namespace, DashboardFiltersActionType.ShowFiltersPanel, actionTypes);
  const hideFiltersPanel = getActionByActionType(namespace, DashboardFiltersActionType.HideFiltersPanel, actionTypes);

  const dashboardFiltersReducer = createReducer(getInitialState(), (builder) => {
    if (changeMembers) {
      builder.addCase(changeMembers, (state, action) => {
        state.teamMembers = action.payload;
      });
    }

    if (changeSearchValue) {
      builder.addCase(changeSearchValue, (state, action) => {
        state.search = action.payload;
      });
    }

    if (showFiltersPanel) {
      builder.addCase(showFiltersPanel, (state) => {
        state.showFiltersPanel = true;
      });
    }

    if (hideFiltersPanel) {
      builder.addCase(hideFiltersPanel, (state) => {
        state.showFiltersPanel = false;
      });
    }

    if (clearFilters) {
      builder.addCase(clearFilters, (state) => {
        state.filtersId = nanoid();
      });
    }
  });

  return {
    actions: {
      changeSearchValue,
      changeFilters,
      clearFilters,
      changeMembers,
      showFiltersPanel,
      hideFiltersPanel,
    } as unknown as CreateDashboardFiltersSliceResult<
      DashboardFiltersState,
      DashboardFiltersSliceActions,
      ChangeFiltersPayload
    >['actions'],
    reducer: dashboardFiltersReducer,
    getInitialState,
  };
};

export default createDashboardFiltersSlice;
