import React, { Ref } from 'react';
import clsx from 'clsx';
import { RowActionsContainer } from 'components/RowActions';
import WrapperWithLink from 'components/WrapperWithLink';
import { CrossIcon } from 'static/images';
import styles from './TableRow.module.scss';

interface TableRowProps {
  useActions?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  disableHover?: boolean;
  className?: string;
  error?: boolean;
  disabled?: boolean;
  noBottomBorder?: boolean;
  noTopBorder?: boolean;
  hasTopBorder?: boolean;
  selected?: boolean;
  disabledClassName?: string;
  link?: string;
}

const TableRow = React.forwardRef<HTMLDivElement | HTMLAnchorElement, React.PropsWithChildren<TableRowProps>>(
  (props, ref) => {
    const {
      children,
      useActions,
      onClick,
      disableHover = false,
      className,
      error,
      disabled,
      noBottomBorder,
      noTopBorder,
      hasTopBorder,
      selected,
      disabledClassName,
      link,
    } = props;

    const rowWrapperProps = {
      className: clsx(
        disableHover ? styles.tableRow : styles.tableRowHover,
        className,
        disabled && styles.disabledTableRow,
        selected && styles.selected,
        noBottomBorder && styles.noBottomBorder,
        noTopBorder && styles.noTopBorder,
        hasTopBorder && styles.hasTopBorder,
        disabled && disabledClassName,
      ),
      onClick: disabled ? undefined : onClick,
    };

    const renderContainer = () =>
      link ? (
        <WrapperWithLink ref={ref as Ref<HTMLAnchorElement>} link={link} {...rowWrapperProps}>
          {children}
          {error && <CrossIcon className={styles.alertIcon} />}
        </WrapperWithLink>
      ) : (
        <div ref={ref as Ref<HTMLDivElement>} {...rowWrapperProps}>
          {children}
          {error && <CrossIcon className={styles.alertIcon} />}
        </div>
      );

    return useActions ? <RowActionsContainer>{renderContainer()}</RowActionsContainer> : renderContainer();
  },
);

export default TableRow;
