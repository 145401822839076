import { AuthApi, CreateAccountData, GoogleSignInData, SignInData } from 'api/AuthApi';
import { AcceptInviteData } from 'api/UserInviteApi';
import { AuthEventEmitterType, AuthEventMessage } from 'eventHandlers/AuthEventEmitter';
import { userInviteApi } from './stores/api';

interface AuthHandlerDependencies {
  authApi: AuthApi;
  localStorage: Storage;
  authEvents: AuthEventEmitterType;
}

const IS_LOGGED_IN_KEY = 'isLoggedIn';
const TRUE = 'true';

export const isLoggedIn = () => localStorage.getItem(IS_LOGGED_IN_KEY) === TRUE;

export interface AuthHandler {
  signIn: (data: SignInData) => Promise<void>;
  acceptInvite: (inviteToken: string, data: AcceptInviteData) => Promise<void>;
  validateInviteToken: (inviteToken: string) => Promise<boolean>;
  resendPhoneVerificationCode: () => Promise<void>;
  verifyPhoneVerificationCode: (code: string) => Promise<void>;
  sendForgotPasswordLink: (email: string) => Promise<void>;
  resetPassword: (password: string, token: string) => Promise<void>;
  createAccount: (data: CreateAccountData) => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
  clearState: () => void;
  emitLogout: () => void;
  logout: () => Promise<void>;
  isLoggedIn: () => boolean;
  verifyEmailVerificationCode(code: string): Promise<void>;
  signInWithGoogle(data: GoogleSignInData): Promise<void>;
}

const createAuthHandler = ({ localStorage, authApi, authEvents }: AuthHandlerDependencies): AuthHandler => {
  const authHandler = {
    signIn: async (data: SignInData) => {
      localStorage.setItem(IS_LOGGED_IN_KEY, TRUE);

      await authApi.signIn(data);
    },
    acceptInvite: async (inviteToken: string, acceptInviteData: AcceptInviteData) => {
      await userInviteApi.acceptInvite(inviteToken, acceptInviteData);
    },
    validateInviteToken: (inviteToken: string) => {
      return userInviteApi.validateToken(inviteToken);
    },
    resendPhoneVerificationCode: async () => {
      await authApi.resendPhoneVerificationCode();
    },
    verifyPhoneVerificationCode: async (code: string) => {
      await authApi.verifyPhoneVerificationCode(code);
    },
    sendForgotPasswordLink: async (email: string) => {
      await authApi.sendForgotPasswordLink(email);
    },
    resetPassword: async (password: string, token: string) => {
      await authApi.resetPassword(password, token);
    },
    createAccount: async (data: CreateAccountData) => {
      await authApi.createAccount(data);

      localStorage.setItem(IS_LOGGED_IN_KEY, TRUE);
    },
    resendVerificationEmail: async () => {
      await authApi.resendVerificationEmail();
    },
    clearState: () => {
      localStorage.removeItem(IS_LOGGED_IN_KEY);
    },
    emitLogout: () => {
      authEvents.emit(AuthEventMessage.Logout);
    },
    logout: () => {
      return authApi.logout();
    },
    isLoggedIn: () => {
      return isLoggedIn();
    },
    verifyEmailVerificationCode: async (code: string) => {
      await authApi.verifyEmailVerificationCode(code);
    },
    signInWithGoogle: async (data: GoogleSignInData) => {
      await authApi.signInWithGoogle(data);

      localStorage.setItem(IS_LOGGED_IN_KEY, TRUE);
    },
  };

  window.addEventListener('storage', (event) => {
    if (event.key === IS_LOGGED_IN_KEY && event.newValue !== TRUE) {
      authHandler.emitLogout();
    }
  });

  return authHandler;
};

export default createAuthHandler;
