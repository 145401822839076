import clsx from 'clsx';
import Button from 'components/Button';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import Spinner from 'components/Spinner';
import React, { FC, useState, useRef, useEffect } from 'react';
import ReactCrop, { Crop } from 'react-image-crop'
import canvasToBlob from 'utils/canvasToBlob';
import scaleImageToRect from 'utils/scaleImageToRect';
import { ImageParams, Rect } from 'api/UserApi';
import 'react-image-crop/src/ReactCrop.scss';
import styles from './CropImageStep.module.scss';

interface CropImageStepProps {
  file: File | null;
  title: string;
  aspectRation: number;
  outputMaxWidth: number;
  outputMaxHeight: number;
  imageParams?: ImageParams | null;
  onSave: (file: Blob, croppedFile: Blob, params: ImageParams) => Promise<void>
  onDelete?: () => Promise<void>;
  onCancel: () => void;
}

const TRANSPARENT_REACT_SIZE = 8;
const WIDTH = Number(styles.width);
const HEIGHT = Number(styles.height);

interface CropImageResult {
  imageParams: ImageParams;
  croppedFile: Blob;
}

const CropImageStep: FC<CropImageStepProps> = ({
  file,
  title,
  aspectRation,
  outputMaxWidth,
  outputMaxHeight,
  imageParams,
  onSave,
  onDelete,
  onCancel,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [crop, setCrop] = useState<Crop>({
    height: imageParams?.cropRect.height || 0,
    width: imageParams?.cropRect.width || 0,
    x: imageParams?.cropRect.x || 0,
    y: imageParams?.cropRect.y || 0,
    unit: 'px',
  });

  const clearCanvas = (context: CanvasRenderingContext2D) => {
    const columnsCount = context.canvas.width / TRANSPARENT_REACT_SIZE;
    const rowsCount = context.canvas.height / TRANSPARENT_REACT_SIZE;

    for (let columnIndex = 0; columnIndex < columnsCount; columnIndex++) {
      for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
        context.fillStyle = (columnIndex + rowIndex) % 2 === 0 ? styles.white : styles.gray;

        context.fillRect(
          columnIndex * TRANSPARENT_REACT_SIZE,
          rowIndex * TRANSPARENT_REACT_SIZE,
          TRANSPARENT_REACT_SIZE,
          TRANSPARENT_REACT_SIZE,
        );
      }
    }
  };

  const getBlankImage = (): string => {
    const canvas = document.createElement('canvas');
    canvas.width = outputMaxWidth;
    canvas.height = outputMaxHeight;

    return canvas.toDataURL('image/png');
  };

  const drawImage = (context: CanvasRenderingContext2D, fileSrc?: string): Promise<[HTMLImageElement, Rect]> => {
    return new Promise((resolve) => {
      const image = new Image();

      image.onload = () => {
        const size = scaleImageToRect(image.width, image.height, context.canvas.width, context.canvas.height);

        const x = context.canvas.width / 2 - size.width / 2;
        const y = context.canvas.height / 2 - size.height / 2;

        context.drawImage(image, x, y, size.width, size.height);

        resolve([image, { ...size, x, y }]);
      };

      image.onerror = () => {
        drawImage(context, getBlankImage()).then(resolve);
      };

      image.src = fileSrc || URL.createObjectURL(file!);
    });
  };

  const calculateCrop = (canvas: HTMLCanvasElement, image: HTMLImageElement): Partial<Crop> => {
    const maxCropWidth = canvas.clientWidth;
    const maxCropHeight = maxCropWidth / aspectRation;

    const {
      width: scaledImageWidth,
      height: scaledImageHeight,
    } = scaleImageToRect(image.width, image.height, canvas.width, canvas.height);

    let width;
    let height;

    if (scaledImageHeight > maxCropHeight || scaledImageWidth === scaledImageHeight) {
      width = scaledImageWidth;
      height = scaledImageWidth / aspectRation;
    } else {
      const targetSideSize = aspectRation <= 1
        ? Math.min(scaledImageWidth, scaledImageHeight)
        : Math.max(scaledImageWidth, scaledImageHeight);

      width = targetSideSize === image.width ? targetSideSize : targetSideSize * aspectRation;
      height = targetSideSize === image.height ? targetSideSize : targetSideSize / aspectRation;
    }

    return {
      width,
      height,
      x: canvas.width / 2 - width / 2,
      y: canvas.height / 2 - height / 2,
    };
  };

  const reinitializeCanvas = async (targetCanvas: HTMLCanvasElement) => {
    const context = targetCanvas.getContext('2d');

    if (!context) {
      return;
    }

    clearCanvas(context);

    const [image] = (await drawImage(context)) || [];

    // react-image-crop expecting this event to be dispatched after image is loaded
    image?.dispatchEvent(new Event('medialoaded', { bubbles: true }));

    setCrop({
      ...crop,
      ...(imageParams?.cropRect || calculateCrop(targetCanvas, image)),
    });
  };

  const drawImageDataAndResize = (
    targetContext: CanvasRenderingContext2D,
    imageData: ImageData,
    width: number,
    height: number,
  ) => {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = imageData.width;
    tempCanvas.height = imageData.height;

    const tempContext = tempCanvas.getContext('2d');

    if (!tempContext) {
      return;
    }

    tempContext.putImageData(imageData, 0, 0);

    targetContext.canvas.width = width;
    targetContext.canvas.height = height;
    targetContext.drawImage(tempContext.canvas, 0, 0, width, height);
  };

  const calculateCroppedImage = async (): Promise<CropImageResult | null> => {
    const canvas = document.createElement('canvas');
    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    const context = canvas.getContext('2d');

    if (!context) {
      return null;
    }

    const [, imageRect] = await drawImage(context);

    const imageData = context.getImageData(
      crop.x || 0,
      crop.y || 0,
      crop.width || canvas.width,
      crop.height || canvas.height,
    );

    const resultCanvas = document.createElement('canvas');
    const resultContext = resultCanvas.getContext('2d');

    if (!resultContext) {
      return null;
    }

    if (imageData.width > outputMaxWidth || imageData.height > outputMaxHeight) {
      drawImageDataAndResize(resultContext, imageData, outputMaxWidth, outputMaxHeight);
    } else {
      drawImageDataAndResize(resultContext, imageData, imageData.width, imageData.height);
    }

    const croppedFile = await canvasToBlob(resultCanvas);

    return {
      imageParams: {
        canvasHeight: canvas.width,
        canvasWidth: canvas.height,
        cropRect: {
          x: crop.x!,
          y: crop.y!,
          width: crop.width!,
          height: crop.height!,
        },
        imageRect,
        originalFileName: file!.name,
      },
      croppedFile,
    };
  };

  const handleSaveImage = async () => {
    const cropImageResult = await calculateCroppedImage();

    if (!cropImageResult) {
      return;
    }

    await onSave(file!, cropImageResult.croppedFile, cropImageResult.imageParams);
  };

  useEffect(() => {
    if (file && canvasRef.current) {
      reinitializeCanvas(canvasRef.current);
    }
  }, [file, canvasRef]);

  useEffect(() => {
    if (imageParams) {
      setCrop({
        ...crop,
        ...imageParams.cropRect,
      });
    }
  }, [imageParams]);

  const isLoading = !file || imageParams === undefined;

  return (
    <div className={styles.container}>
      {isLoading && <div className={styles.spinnerContainer}><Spinner className={styles.spinner} size={52}/></div>}
      {!isLoading && (
        <ReactCrop
          keepSelection
          ruleOfThirds
          crop={crop}
          onChange={setCrop}
          className={styles.reactCropContainer}
          aspect={aspectRation}
        >
          <canvas width={WIDTH} height={HEIGHT} ref={canvasRef} />
        </ReactCrop>
      )}
      <div className={clsx(styles.buttonsContainer, onDelete && styles.withDelete)}>
        <ButtonWithLoadingState
          kind="primary"
          size="form"
          onClick={handleSaveImage}
          disabled={!file}
          className={styles.submitButton}
        >
          Save {title}
        </ButtonWithLoadingState>
        <Button disabled={!file} className={styles.cancelButton} size="form" onClick={onCancel}>
          Change {title}
        </Button>
        {onDelete && (
          <ButtonWithLoadingState
            disabled={!file}
            className={styles.deleteButton}
            size="form"
            kind="warning"
            onClick={onDelete}
          >
            Delete {title}
          </ButtonWithLoadingState>
        )}
      </div>
    </div>
  );
};

export default CropImageStep;
