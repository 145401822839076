import React, { FC } from 'react'
import ButtonWithImage from 'components/ButtonWithImage'
import { StylesProvider } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import styles from './ImagePreviewHeader.module.scss';
import clsx from 'clsx';

interface ImagePreviewHeaderProps {
  fileName: string;
  isLoading?: boolean;
  onGoBack: () => void;
  className?: string;
}

const ImagePreviewHeader: FC<ImagePreviewHeaderProps> = ({
  fileName,
  isLoading,
  className,
  onGoBack,
}) => {
  return (
    <>
      <div className={clsx(styles.header, className)}>
        <ButtonWithImage title="Back" kind="back" onClick={onGoBack} />
          <h6 className={styles.fileName}>{fileName}</h6>
      </div>
      {isLoading && (
        <StylesProvider injectFirst>
          <LinearProgress className={styles.progressBar} />
        </StylesProvider>
      )}
    </>
  )
}

export default ImagePreviewHeader;
