import React, { FC } from 'react';
import clsx from 'clsx';
import WrapperWithLink from 'components/WrapperWithLink';
import { NestedLink } from 'components/MainLayout/leftNavReducer/LeftNavTypes';
import { NavigationLinkIdType } from 'enums/NavigationLinkName';

import styles from './DropdownContent.module.scss';

export interface DropdownContentType {
  isDropdownOpen: boolean;
  isExpanded: boolean;
  nestedLinks: NestedLink[];
  onUpdateActiveLinkTitle: (title: string) => void;
  activeNestedLinkId?: NavigationLinkIdType;
  nestedItemClassName?: string;
  baseRoute: string;
  disabled?: boolean;
}

const DropdownContent: FC<DropdownContentType> = ({
  isDropdownOpen,
  isExpanded,
  nestedLinks,
  onUpdateActiveLinkTitle,
  activeNestedLinkId,
  nestedItemClassName,
  baseRoute,
  disabled,
}) => {
  return (
    <div
      className={
        isDropdownOpen && isExpanded ? styles.nestedLinksContainerExpanded : styles.nestedLinksContainerCollapsed
      }
    >
      {isExpanded &&
        nestedLinks.map((link, ind) => {
          const { id, title, route } = link;
          const isActive = id === activeNestedLinkId;

          return (
            <WrapperWithLink
              link={`${baseRoute}${route}`}
              className={clsx({
                [styles.disabledNestedItem]: !isActive && disabled,
                [styles.activeNestedItem]: isActive,
                [styles.nestedItem]: !isActive,
              }, nestedItemClassName)}
              key={ind}
              onClick={() => onUpdateActiveLinkTitle(`${baseRoute}${route}`)}
            >
              {title}
            </WrapperWithLink>
          );
        })}
    </div>
  );
};

export default DropdownContent;
