import React, { FC, useState, ReactNode, ReactElement } from 'react';
import { CloseImage, DocumentIcon, PptIcon, XlsIcon, DocIcon, JsonIcon, ImageIcon, PdfIcon } from '../../static/images';
import clsx from 'clsx';
import { isArray } from 'lodash';
import Label from '../Label';
import { DocumentExtension } from '../../enums/DocumentExtension';
import pluralize from 'pluralize';
import styles from './UploadFile.module.scss';

export const iconsByExtension = new Map([
  [DocumentExtension.Xls, <XlsIcon />],
  [DocumentExtension.Xlsx, <XlsIcon />],
  [DocumentExtension.Csv, <XlsIcon />],
  [DocumentExtension.Doc, <DocIcon />],
  [DocumentExtension.Docx, <DocIcon />],
  [DocumentExtension.Xml, <DocIcon />],
  [DocumentExtension.Txt, <DocIcon />],
  [DocumentExtension.Ppt, <PptIcon />],
  [DocumentExtension.Pptx, <PptIcon />],
  [DocumentExtension.Pdf, <PdfIcon />],
  [DocumentExtension.Json, <JsonIcon />],
  [DocumentExtension.Gif, <ImageIcon />],
  [DocumentExtension.Png, <ImageIcon />],
  [DocumentExtension.Jpeg, <ImageIcon />],
  [DocumentExtension.Jpg, <ImageIcon />],
  [DocumentExtension.Svg, <ImageIcon />],
  [DocumentExtension.Ico, <ImageIcon />],
  [DocumentExtension.Heic, <ImageIcon />],
  [DocumentExtension.Webp, <ImageIcon />],
]);

export interface BaseUploadFromFileProps {
  files: File[];
  accept?: string | string[];
  maxFiles: number;
  text?: ReactNode;
  closeIcon?: React.ReactNode;
  noMargin?: boolean;
  disabled?: boolean;
  inputLabel?: string;
  className?: string;
  inputWrapperClassName?: string;
  uploadIcon?: React.ReactNode;
  requirementsText?: string | ReactElement;
  disablePreview?: boolean;
  errorMessage?: string;
  onFilesChange: (files: File[]) => void;
  children?: React.ReactNode;
}

export const getFileName = (fileName: string): string =>
  fileName
    .split('.')
    .slice(0, -1)
    .join('.');

export const getFileFormat = (fileName: string): string =>
  fileName
    .split('.')
    .slice(-1)
    .join();

const INPUT_ID = 'fileInput';

const BaseUploadFile: FC<BaseUploadFromFileProps> = ({
  files,
  disablePreview,
  errorMessage,
  onFilesChange,
  accept,
  text,
  closeIcon,
  noMargin,
  inputLabel,
  className,
  inputWrapperClassName,
  uploadIcon,
  requirementsText,
  maxFiles = 1,
  disabled,
  children,
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const handleDeleteFile = () => onFilesChange([]);

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target) {
      return;
    }

    const newFiles = Array.from(event.target.files || []);

    onFilesChange(newFiles);
  };

  const handleFileDrag = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const newFiles = Array.from(event.dataTransfer.files);

    onFilesChange(newFiles);

    setIsDragOver(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDragEnter = (event: React.DragEvent<HTMLLabelElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLLabelElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.currentTarget.contains(event.relatedTarget as Node)) {
      return
    }

    setIsDragOver(false);
  };

  const labelClassName = clsx(
    styles.dragZoneLabel,
    isDragOver ? styles.dragOverInputWrapper : styles.inputWrapper,
    errorMessage && styles.errorInputWrapper,
    inputWrapperClassName,
  );

  const renderCloseImage = () => (
    <div className={styles.fileDeleteContainer} onClick={handleDeleteFile}>
      {closeIcon || <CloseImage className={styles.fileDelete} />}
    </div>
  );

  const renderBrowserLink = () => {
    return (
      <span className={clsx(styles.link, disabled && styles.linkDisabled)}>
        browse
      </span>
    );
  };

  return (
    <div className={clsx(styles.container, disabled && styles.disabled, className)}>
      {inputLabel ? (
        <Label htmlFor={INPUT_ID} required>
          {inputLabel}
        </Label>
      ) : null}
      {(!disablePreview && files.length > 0) ? (
        <div className={clsx(styles.fileInfoContainer, noMargin && styles.noMargin, inputWrapperClassName)}>
          <div className={styles.fileImage}>
            {iconsByExtension.get(files[0].name.split('.').pop()?.toLowerCase() as DocumentExtension) || <DocumentIcon />}
          </div>
          <div className={styles.fileInfo}>
            <span className={styles.fileName}>{getFileName(files[0].name)}</span>
            <span className={styles.fileFormat}>{getFileFormat(files[0].name)}</span>
          </div>
          {renderCloseImage()}
        </div>
      ) : (
        <label
          className={clsx(labelClassName, noMargin && styles.noMargin)}
          onDrop={handleFileDrag}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          {uploadIcon}
          <p className={styles.textContainer}>
            {text || (
              <>
                Drag {maxFiles === 1 ? 'a ' : ''}{pluralize('file', maxFiles)} here or {renderBrowserLink()} for {maxFiles === 1 ? 'a file' : 'files'} to upload.
              </>
            )}
          </p>
          {requirementsText && <p className={styles.secondaryTextContainer}>
            {requirementsText}
          </p>}
          <input
            type="file"
            id={INPUT_ID}
            className={styles.fileInput}
            onChange={handleUploadFile}
            accept={isArray(accept) ? accept.join(',') : accept}
            multiple={maxFiles > 1}
            disabled={disabled}
          />
        </label>
      )}
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      {children}
    </div>
  );
};

export default BaseUploadFile;
