import React, { FC, ReactNode } from 'react';
import Portal from 'components/Portal';
import clsx from 'clsx';
import styles from './ChartTooltip.module.scss';

interface ChartTooltipProps {
  className?: string;
  x: number;
  y: number;
  xData: ReactNode;
  yData: ReactNode;
  label: string;
  color: string;
}

const ChartTooltip: FC<ChartTooltipProps> = ({ x, y, xData, yData, className, label, color }) => {
  return (
    <Portal>
      <div className={clsx(styles.tooltip, className)} style={{ left: x, top: y }}>
        <p className={styles.xData}>{xData}</p>
        <div className={styles.bottomContainer}>
          <div className={styles.labelContainer}>
            <div className={styles.bar} style={{ backgroundColor: color }} />
            {label}
          </div>
          <p className={styles.yData}>{yData}</p>
        </div>
      </div>
    </Portal>
  );
};

export default ChartTooltip;
