import React, { FC } from 'react';
import { WebhookEndpoint } from 'api/WebhookEndpointsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import RowActions from 'components/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import { Tag } from 'components/Tag';
import { WebhookEndpointsColumnSize } from 'components/WebhooksOverview/Tables/EndpointsTable/TableColumnSize';
import { User } from 'api/UserApi';
import styles from './EndpointItemRow.module.scss';
import useDocumentTypes from 'hooks/useDocumentTypes';

interface EndpointItemRowProps {
  webhookEndpoint: WebhookEndpoint;
  user?: User;
  onClick: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onStatusChange: (id: string, active: boolean) => void;
}

const EndpointItemRow: FC<EndpointItemRowProps> = ({
  webhookEndpoint,
  user,
  onClick,
  onEdit,
  onDelete,
  onStatusChange,
}) => {
  const documentTypes = useDocumentTypes();

  const documentTypeNames = webhookEndpoint.documentTypeIds.map((id) => {
    return documentTypes?.find((documentType) => documentType.id === id)?.name;
  });

  const renderCellContent = (content: React.ReactNode) => (
    <OverflowedText>
      {content || <TableBodyCellNoContent />}
    </OverflowedText>
  );

  const renderStatusTag = () => {
    if (webhookEndpoint.active) {
      return <Tag color="green">Active</Tag>
    }

    return <Tag color="gray">Inactive</Tag>
  }

  return (
    <TableRow useActions key={webhookEndpoint.id} onClick={() => onClick(webhookEndpoint.id)}>
      <TableBodyCell width={WebhookEndpointsColumnSize.Url}>
        {renderCellContent(webhookEndpoint.url)}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.Description}>
        {webhookEndpoint.description && renderCellContent(webhookEndpoint.description)}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.ListeningFor}>
        {renderCellContent(webhookEndpoint.events.join(', ') || 'All Events')}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.DocumentType}>
        {renderCellContent(documentTypeNames.join(', ') || 'All Document Types')}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.Status}>
        {renderStatusTag()}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.UpdatedAt} noPadding>
        <WithSystemApiUserAvatar className={styles.avatar} user={user} size="small" />
        <OverflowedText>{formatDate(webhookEndpoint.updatedAt, DateTimeFormat.Long)}</OverflowedText>
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Edit Endpoint',
            handler: () => onEdit(webhookEndpoint.id),
          },
          {
            title: webhookEndpoint.active ? 'Set as Inactive' : 'Set as Active',
            handler: () => onStatusChange(webhookEndpoint.id, !webhookEndpoint.active),
            separatorRequired: true,
          },
          {
            title: 'Delete Endpoint',
            handler: () => onDelete(webhookEndpoint.id),
            danger: true,
          },
        ]}
      />
    </TableRow>
  );
};

export default EndpointItemRow;
