import React, { ComponentType, createContext, FC, ReactNode, useLayoutEffect, useState } from 'react';
import { nanoid } from 'nanoid';

type LayoutComponentSetter = (layoutComponent: JSX.Element) => void;

const { Consumer, Provider } = createContext<LayoutComponentSetter>(() => {});

type MountPointProps = {
  children: ReactNode;
};

const LayoutMountPoint: FC<MountPointProps> = (props) => {
  const { children } = props;
  const [layoutComponent, setLayoutComponent] = useState<ReactNode>(null);

  return (
    <>
      <Provider value={setLayoutComponent}>{children}</Provider>
      {layoutComponent}
    </>
  );
};

const provideLayout = <Props extends {}>(LayoutComponent: ComponentType<Props>) => {
  type SetterProps = {
    layoutComponentProps: Props;
    setLayoutComponent: LayoutComponentSetter;
  };

  const LayoutSetter: FC<SetterProps> = (props) => {
    const [key] = useState(nanoid());

    const { layoutComponentProps, setLayoutComponent } = props;

    useLayoutEffect(() => {
      setLayoutComponent(<LayoutComponent key={key} {...layoutComponentProps} />);
    }, [layoutComponentProps]);

    return null;
  };

  return (props: Props) => (
    <Consumer>
      {(setLayoutComponent) => <LayoutSetter layoutComponentProps={props} setLayoutComponent={setLayoutComponent} />}
    </Consumer>
  );
}

export { LayoutMountPoint };

export default provideLayout;
