import createDashboardFiltersSlice, {
  BaseDashboardFiltersState,
  DashboardFiltersActionType,
  getDashboardFiltersInitialState,
} from 'DashboardSlice/createDashboardFiltersSlice';
import { DocumentTypesDashboardFiltersActionType } from './DocumentTypesDashboardFiltersActionType';

export interface DocumentTypesDashboardFiltersState extends BaseDashboardFiltersState {
  teamMembers: string[];
}

const createDocumentTypesDashboardFiltersSlice = (namespace: string) => {
  const getInitialState = (): DocumentTypesDashboardFiltersState => ({
    ...getDashboardFiltersInitialState(),
    teamMembers: [],
  });

  const actionTypes = {
    [DashboardFiltersActionType.ChangeSearchValue]: DocumentTypesDashboardFiltersActionType.ChangeSearchValue,
    [DashboardFiltersActionType.ChangeMembers]: DocumentTypesDashboardFiltersActionType.ChangeMembers,
  };

  const { reducer, actions } = createDashboardFiltersSlice<DocumentTypesDashboardFiltersState, {}, typeof actionTypes>(
    namespace,
    getInitialState,
    actionTypes,
  );

  return {
    actions,
    reducer,
    getInitialState,
  };
};

export default createDocumentTypesDashboardFiltersSlice;
