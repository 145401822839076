import React from 'react';
import DatePicker from 'components/DatePicker';
import { formatDate, getOnDateChange } from 'components/DatePicker/utils';
import clsx from 'clsx';
import DateTimeUtils from 'utils/dateUtils';

import styles from './DateRangePicker.module.scss';

interface DateRangePickerProps {
  label?: string;
  from: Date | null;
  to: Date | null;
  onChange: (from: Date | null, to: Date | null) => void;
  datePickerFromClassName?: string;
  datePickerToClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  nullable?: boolean;
  onBlur?: () => void;
  calendarInputOverlayClassName?: string;
}

const DateRangePicker = ({
  label,
  from,
  to,
  onChange,
  datePickerFromClassName,
  datePickerToClassName,
  inputClassName,
  disabled,
  minDate,
  maxDate,
  nullable,
  onBlur,
  calendarInputOverlayClassName,
}: DateRangePickerProps) => {
  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    const startDateTime = startDate
      ? DateTimeUtils.parse(startDate)
          .startOf('day')
          .toDate()
      : null;
    const endDateTime = endDate
      ? DateTimeUtils.parse(endDate)
          .endOf('day')
          .toDate()
      : null;

    onChange(startDateTime, endDateTime);
  };

  return (
    <div>
      {label && <p className={styles.datePickersLabel}>{label}</p>}
      <div className={styles.datePickersInner}>
        <div className={clsx(styles.datePickerFrom, datePickerFromClassName)}>
          <DatePicker
            value={formatDate(from)}
            onChange={getOnDateChange((date) => handleDateChange(date, to))}
            labelTitle=""
            placeholder="Start"
            inputClassName={inputClassName}
            disabled={disabled}
            minDate={minDate}
            nullable={nullable}
            onBlur={onBlur}
            calendarInputOverlayClassName={calendarInputOverlayClassName}
          />
        </div>
        <div className={clsx(styles.datePickerTo, datePickerToClassName)}>
          <DatePicker
            value={formatDate(to)}
            onChange={getOnDateChange((date) => handleDateChange(from, date))}
            labelTitle=""
            placeholder="End"
            inputClassName={inputClassName}
            disabled={disabled}
            maxDate={maxDate}
            nullable={nullable}
            onBlur={onBlur}
            calendarInputOverlayClassName={calendarInputOverlayClassName}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
