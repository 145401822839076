import { DocumentType } from 'api/DocumentTypeApi';
import { FC } from 'react';
import {
  DocumentTypesDashboardFiltersState,
} from 'GeneralSettings/ui/DocumentTypesDashboard/Filters/DocumentTypesDashboardFiltersStore';
import { User } from 'api/UserApi';
import DocumentTypeHeader from './DocumentTypeDashboardHeader';
import DocumentTypesList from './DocumentTypesList/DocumentTypesList';
import { NullableItems, PaginationProps } from 'pagination';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import { NoResultsIcon } from 'static/images';

import styles from './DocumentTypesDashboard.module.scss';

interface DocumentTypesDashboardProps {
  documentTypes: NullableItems<DocumentType>;
  filters: DocumentTypesDashboardFiltersState;
  members: User[],
  paginationProps: PaginationProps;
  hasMore?: boolean;
  noItems: boolean;
  containerId: string;
  onSearch: (search: string) => void;
  onChangeSelectedUsers: (members: User[]) => void;
  onCreateDocumentType: () => void;
  onLoadMore: () => void;
  onDeleteDocumentType: (documentType: DocumentType) => void;
  onEditDocumentType: (documentType: DocumentType) => void;
  onDuplicateDocumentType: (documentType: DocumentType) => void;
  onDocumentTypeSelect: (documentTypeId: string) => void;
}

const DocumentTypesDashboard: FC<DocumentTypesDashboardProps> = ({
  documentTypes,
  filters,
  members,
  hasMore,
  noItems,
  containerId,
  onSearch,
  onChangeSelectedUsers,
  onCreateDocumentType,
  onLoadMore,
  onDeleteDocumentType,
  onEditDocumentType,
  onDuplicateDocumentType,
  onDocumentTypeSelect,
}) => {
  const renderContent = () => {
    if (filters.search && !documentTypes.length) {
      return (
        <SearchNotFound searchValue={filters.search} />
      )
    }


    if (filters.teamMembers.length && !documentTypes.length) {
      return (
        <NoItems
          className={styles.noItems}
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          onButtonClick={() => onChangeSelectedUsers([])}
        />
      )
    }

    return <DocumentTypesList
      documentTypes={documentTypes}
      hasMore={hasMore}
      noItems={noItems}
      containerId={containerId}
      members={members}
      onDocumentTypeSelect={onDocumentTypeSelect}
      onDocumentTypeDelete={onDeleteDocumentType}
      onDocumentTypeDuplicate={onDuplicateDocumentType}
      onDocumentTypeEdit={onEditDocumentType}
      onDocumentTypeAdd={onCreateDocumentType}
      onLoadMore={onLoadMore}
    />
  }

  return (
    <section className={styles.container}>
      <DocumentTypeHeader
        noItems={noItems}
        className={styles.documentTypeHeader}
        filters={filters}
        members={members}
        onSearch={onSearch}
        onChangeSelectedUsers={onChangeSelectedUsers}
        onAddDocumentType={onCreateDocumentType}
      />
      {renderContent()}
    </section>
  )
}

export default DocumentTypesDashboard;
