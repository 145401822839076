import Pagination from 'pagination';
import { ReduxState } from 'types/ReduxState';
import { DocumentTypeSortingType } from 'api/DocumentTypeApi';

const MAX_WIDTH_OF_DOCUMENT_TYPE_CONTAINER = 2100;
const CARD_WIDTH = 700;
const CARD_HEIGHT = 140;

interface ContainerSize {
  width: number;
  height: number;
}

const getDefaultContainerSize = () => {
  return {
    width: window.innerWidth > MAX_WIDTH_OF_DOCUMENT_TYPE_CONTAINER ? MAX_WIDTH_OF_DOCUMENT_TYPE_CONTAINER : window.innerWidth,
    height: window.innerHeight,
  };
}

const getCardWidthByContainerSize = (containerSize: ContainerSize) => {
  if (containerSize.width < CARD_WIDTH) {
    return containerSize.width;
  }

  return CARD_WIDTH;
}

export const calculateDocumentTypesPerPage = (containerSize: ContainerSize = getDefaultContainerSize()) => {
  const windowSquared = containerSize.width * containerSize.height;

  const oneCardSquared = getCardWidthByContainerSize(containerSize) * CARD_HEIGHT;

  const totalCards = Math.ceil(windowSquared / oneCardSquared);

  return totalCards;
}

export interface DocumentTypesPaginationParams {
  search: string;
  selectedMembers: string[];
  sortingType: DocumentTypeSortingType;
}

const createDocumentTypesDashboardPagination = <Params extends DocumentTypesPaginationParams>(namespace: string[] | string) => {
  return Pagination<ReduxState, string, Params>(
    namespace,
    calculateDocumentTypesPerPage(),
    ({ search }) => (search ? ['searched', search] : []),
    { sliceItems: false },
  );
};

export default createDocumentTypesDashboardPagination;
