import joinUrls from 'url-join';

export type QueryParams = Record<string, string | null | undefined>;

export default class PoorRouteBuilder {
  constructor(
    protected queryParams?: URLSearchParams,
  ) {
  }

  public withParams(queryParams: QueryParams | URLSearchParams): PoorRouteBuilder {
    const newQueryParams = queryParams instanceof URLSearchParams
      ? queryParams
      : this.getQueryParamsString(queryParams);

    return this.clone(newQueryParams);
  }

  public open(...routes: string[]): void {
    const targetRoute = this.build(window.location.origin, ...routes);

    window.open(targetRoute);
  }

  public build(...routes: string[]): string {
    const targetRoute = joinUrls(...routes);

    if (this.queryParams?.toString()) {
      return `${targetRoute}?${this.queryParams}`;
    }

    return targetRoute;
  }

  protected getQueryParamsString(params: QueryParams): URLSearchParams {
    const queryParams = new URLSearchParams();

    if (params) {
      Object.entries(params).forEach(([key, value]) => typeof value === 'string' && queryParams.set(key, value));
    }

    return queryParams;
  };

  protected clone(queryParams?: URLSearchParams): PoorRouteBuilder {
    return new PoorRouteBuilder(queryParams);
  }
}
