import Pagination from 'pagination';
import { ReduxState } from 'types/ReduxState';
import { WebhookEventSortingType } from 'api/WebhookEventsApi';

export const WEBHOOK_EVENTS_PER_PAGE_DEFAULT = 15;

export interface WebhookEndpointEventsPaginationParams {
  search?: string;
  sortingType: WebhookEventSortingType;
  webhookId?: string;
}

const createEndpointEventsTablePagination = <Params extends WebhookEndpointEventsPaginationParams>(
  namespace: string[] | string,
) => {
  return Pagination<ReduxState, string, Params>(
    namespace,
    WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
    ({ search }) => (search ? ['searched', search] : []),
  );
};

export default createEndpointEventsTablePagination;
