import { validateNameField } from 'utils/validation/validation';

const MIN_LENGTH = 2;

interface OrganizationInformation {
  name: string;
}

export interface OrganizationInformationValidationResult {
  name?: string;
}

export const LABELS = {
  name: 'Company Name',
};

const validateCompanyInformation = (
  data: Partial<OrganizationInformation>,
): OrganizationInformationValidationResult => {
  const errors: OrganizationInformationValidationResult = Object.keys(data).reduce((result, key) => {
    if (!data[key] || data[key].length === 0) {
      return { ...result, [key]: `${LABELS[key]} is required` };
    }

    if (data[key]?.length < MIN_LENGTH) {
      return { ...result, [key]: `Minimum length is ${MIN_LENGTH}` };
    }

    return result;
  }, {});

  const companyNameErrorMessage = validateNameField(data.name || '');

  if (!errors.name && companyNameErrorMessage) {
    errors.name = companyNameErrorMessage;
  }

  return errors;
};

export default validateCompanyInformation;
