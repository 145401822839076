import { getLeftNavigationStateByLocation } from 'components/MainLayout/utils';
import useRouteBuilder from 'hooks/useRouteBuilder';
import React, { FC, useRef, useState } from 'react';
import styles from './MobileHeader.module.scss';
import { ReactComponent as MenuBurgerIcon } from './menu-burger-icon.svg';
import UserCard from 'components/LeftNav/UserCard';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import getAvatarUrl from 'utils/getAvatarUrl';
import AccountMenu from 'components/LeftNav/AccountMenu';

interface MobileHeaderProps {
  onOpenLeftNavigation: () => void;
  overwritePageTitle?: string;
}

const MobileHeader: FC<MobileHeaderProps> = ({
  onOpenLeftNavigation,
  overwritePageTitle,
}) => {
  const routeBuilder = useRouteBuilder();
  const currentUser = useSelector((state: ReduxState) => state.users.currentUser);
  const organizationInfo = useSelector((state: ReduxState) => state.organization.detailedInfo);

  const { isOpen } = useSelector((state: ReduxState) => state.myAccount);

  const [
    isMyAccountMenuOpen,
    setIsMyAccountMenuOpen,
  ] = useState(false);

  const myAccountRef = useRef<HTMLDivElement>(null);

  const navigationState = getLeftNavigationStateByLocation(routeBuilder.currentPathname);

  if (!currentUser || !organizationInfo) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <MenuBurgerIcon className={styles.burgerMenuIcon} onClick={onOpenLeftNavigation} />
        <div>{overwritePageTitle || navigationState.activeNestedLinkTitle || navigationState.activeNavigationLinkTitle}</div>
      </div>
      {isMyAccountMenuOpen && (
        <AccountMenu
          onClose={() => setIsMyAccountMenuOpen(false)}
          anchorElement={myAccountRef?.current}
        />
      )}
      <UserCard
        firstName={currentUser.firstName || ''}
        lastName={currentUser.lastName || ''}
        color={currentUser.color}
        avatarUrl={getAvatarUrl(currentUser.avatarId)}
        companyName={organizationInfo.name}
        onClick={() => setIsMyAccountMenuOpen(true)}
        isActive={isOpen}
        ref={myAccountRef}
      />
    </div>
  );
};

export default MobileHeader;
