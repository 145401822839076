export enum MyAccountType {
  UpdateUser = 'myAccount/updateUser',
  UploadAvatar = 'myAccount/uploadAvatar',
  GetOriginalAvatar = 'myAccount/getOriginalAvatar',
  ConfirmPassword = 'myAccount/confirmPassword',
  SendChangeEmailCode = 'myAccount/sendChangeEmailCode',
  VerifyChangEmailCode = 'myAccount/verifyChangEmailCode',
  SendChangePhoneNumberCode = 'myAccount/sendUpdatePhoneNumberCode',
  VerifyChangPhoneCode = 'myAccount/verifyChangPhoneCode',
  VerifyDeletePhoneCode = 'myAccount/verifyDeletePhoneCode',
  ChangePassword = 'myAccount/changePassword',
  OpenMyAccount = 'myAccount/openMyAccount',
  CloseMyAccount = 'myAccount/closeMyAccount',
  OpenPopup = 'myAccount/openPopup',
  ClosePopup = 'myAccount/closePopup',
  DeleteAvatar = 'myAccount/deleteAvatar',
  SendPhoneVerificationCode = 'myAccount/sendPhoneVerificationCode',
  ConfirmPhoneVerificationCode = 'myAccount/confirmPhoneVerificationCode',
  SendEmailVerificationCode = 'myAccount/sendEmailVerificationCode',
  ConfirmEmailVerificationCode = 'myAccount/confirmEmailVerificationCode',
}
