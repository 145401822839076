import DateTimeUtils from 'utils/dateUtils';
import { DEFAULT_DATE_FORMAT } from 'constants/DateFormat';

export const formatDate = (date: Date | null) => {
  return date ? DateTimeUtils.parse(date).format(DEFAULT_DATE_FORMAT) : '';
};

export const getOnDateChange = (setDate: (date: Date | null) => void) => (value: string) => {
  try {
    setDate(value ? DateTimeUtils.parse(value, DEFAULT_DATE_FORMAT).toDate() : null);
  }
  catch {
    setDate(null);
  }
};
