import { createReducer } from '@reduxjs/toolkit';
import createDashboardFiltersSlice, {
  BaseDashboardFiltersState,
  DashboardFiltersActionType,
  getDashboardFiltersInitialState,
} from 'DashboardSlice/createDashboardFiltersSlice';
import { DateRange } from 'utils/dateFormat';
import { ExtractionHistoryDashboardFiltersActionType } from './ExtractionHistoryDashboardFiltersActionType';
import reduceReducers from 'reduce-reducers';
import { DataExtractionFileType, DataExtractionStatus } from 'api/DataExtractionApi';

export interface ChangeFiltersPayload {
  createdAtRange?: DateRange;
  documentTypeId?: string | null;
  fileType?: DataExtractionFileType | null;
  status?: DataExtractionStatus | null
}

export interface ExtractionHistoryDashboardFiltersState extends BaseDashboardFiltersState {
  teamMembers: string[];
  createdAtRange: DateRange;
  documentTypeId: string | null;
  fileType: DataExtractionFileType | null;
  status: DataExtractionStatus | null;
}

const createExtractionHistoryDashboardFiltersSlice = (namespace: string) => {
  const getInitialState = (): ExtractionHistoryDashboardFiltersState => ({
    ...getDashboardFiltersInitialState(),
    createdAtRange: {
      from: null,
      to: null,
    },
    documentTypeId: null,
    fileType: null,
    status: null,
    teamMembers: [],
  });

  const actionTypes = {
    [DashboardFiltersActionType.ChangeSearchValue]: ExtractionHistoryDashboardFiltersActionType.ChangeSearchValue,
    [DashboardFiltersActionType.ChangeMembers]: ExtractionHistoryDashboardFiltersActionType.ChangeMembers,
    [DashboardFiltersActionType.ChangeFilters]: ExtractionHistoryDashboardFiltersActionType.ChangeFilters,
    [DashboardFiltersActionType.ClearFilters]: ExtractionHistoryDashboardFiltersActionType.ClearFilters,
    [DashboardFiltersActionType.ShowFiltersPanel]: ExtractionHistoryDashboardFiltersActionType.ShowFiltersPanel,
    [DashboardFiltersActionType.HideFiltersPanel]: ExtractionHistoryDashboardFiltersActionType.HideFiltersPanel,
  }

  const { reducer, actions } = createDashboardFiltersSlice<
    ExtractionHistoryDashboardFiltersState,
    ChangeFiltersPayload,
    typeof actionTypes
  >(
    namespace,
    getInitialState,
    actionTypes,
  );

  const extractionHistoryDashboardFiltersReducer = createReducer(getInitialState(), (builder) => {
    builder.addCase(actions.changeFilters, (state, action) => {
      state.createdAtRange = action.payload.createdAtRange || { from: null, to: null };
      state.documentTypeId = action.payload.documentTypeId || null;
      state.fileType = action.payload.fileType || null;
      state.status = action.payload.status || null;
    });

    builder.addCase(actions.clearFilters, (state) => {
      state.createdAtRange = { from: null, to: null };
      state.documentTypeId = null;
      state.fileType = null;
      state.status = null;
    });
  });

  return {
    actions,
    reducer: reduceReducers(extractionHistoryDashboardFiltersReducer, reducer),
    getInitialState,
  };
};

export default createExtractionHistoryDashboardFiltersSlice;
