import { CreatePublicApiWhitelistParams } from 'api/PublicApiWhitelistApi';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import LinkButton from 'components/LinkButton';
import TextInput from 'components/TextInput';
import { uniqueId } from 'lodash';
import validateWhitelists, {
  WhitelistParams,
  WhitelistValidationResult,
} from 'pages/ApiCredentials/CreatePublicApiWhitelistPopup/AddIpAddressStep/validateWhitelists';
import { MAX_API_WHITELISTS_PER_ORGANIZATION } from 'PublicApi/constants';
import React, { ChangeEvent, FC, useState } from 'react';
import { PlusIcon } from 'static/images';
import Divider, { DividerTextWrapper } from 'components/Divider';
import styles from './AddIpAddressStep.module.scss';

interface AddIpAddressStepProps {
  ipsTotalCount: number;
  onSubmit: (whitelists: CreatePublicApiWhitelistParams[]) => Promise<void>;
}

const MAX_IP_ADDRESSES_PER_MODAL = 5;

const generateEmptyWhitelistParameter = (): WhitelistParams => {
  return {
    id: uniqueId('whitelist-params'),
    ip: '',
    description: '',
  };
};

const AddIpAddressStep: FC<AddIpAddressStepProps> = ({ ipsTotalCount, onSubmit }) => {
  const [
    whitelists,
    setWhitelists,
  ] = useState<WhitelistParams[]>([generateEmptyWhitelistParameter()]);
  const [
    errors,
    setErrors,
  ] = useState<Record<string, WhitelistValidationResult>>({});

  const removeIpAddress = (targetId: string) => () => {
    setWhitelists(whitelists.filter(({ id }) => id !== targetId));
  };

  const handleOnInputChange = (
    targetId: string,
    field: keyof CreatePublicApiWhitelistParams,
  ) => (event: ChangeEvent<HTMLInputElement>) => {
    const newWhitelists = whitelists.map(({
      id,
      ip,
      description,
    }) => {
      if (targetId !== id) {
        return { id, ip, description };
      }

      return {
        id,
        ip: field === 'ip' ? event.target.value : ip,
        description: field === 'description' ? event.target.value : description,
      };
    });

    setWhitelists(newWhitelists);
    setErrors({
      ...errors,
      [targetId]: {
        ...(errors[targetId] || {}),
        [field]: '',
      },
    });
  };

  const handleOnInputBlur = (targetId: string, field: keyof CreatePublicApiWhitelistParams) => () => {
    const targetWhitelist = whitelists.find(({ id }) => id === targetId)!;

    const newErrors = validateWhitelists(targetWhitelist);

    setErrors({
      ...errors,
      [targetId]: {
        ...(errors[targetId] || {}),
        [field]: newErrors[field],
      },
    });
  };

  const renderRemoveIpButton = (id: string, index: number) => {
    if (index === 0) {
      return null;
    }

    return (
      <LinkButton className={styles.removeIpButton} onClick={removeIpAddress(id)}>
        Remove IP Address
      </LinkButton>
    );
  };

  const renderInputs = (whitelist: WhitelistParams, index: number) => {
    return (
      <div key={whitelist.id}>
        <Divider className={styles.divider}>
          <DividerTextWrapper>{`IP Address ${index + 1}`}</DividerTextWrapper>
        </Divider>
        <TextInput
          placeholder="0.0.0.0"
          required
          labelTitle="IP Address"
          value={whitelist.ip}
          topRightElement={renderRemoveIpButton(whitelist.id, index)}
          onChange={handleOnInputChange(whitelist.id, 'ip')}
          onBlur={handleOnInputBlur(whitelist.id, 'ip')}
          error={!!errors[whitelist.id]?.ip}
          errorMessage={errors[whitelist.id]?.ip}
        />
        <TextInput
          placeholder="IP Address Description"
          required
          labelTitle="Description"
          value={whitelist.description}
          onChange={handleOnInputChange(whitelist.id, 'description')}
          onBlur={handleOnInputBlur(whitelist.id, 'description')}
          error={!!errors[whitelist.id]?.description}
          errorMessage={errors[whitelist.id]?.description}
        />
      </div>
    );
  };

  const handleSubmit = () => {
    return onSubmit(whitelists.map(({ ip, description }) => ({
      ip,
      description,
    })));
  };

  const hasErrors = whitelists.some(
    (whitelist) => Object.values(validateWhitelists(whitelist)).length > 0,
  );

  const canAddMore = whitelists.length < MAX_IP_ADDRESSES_PER_MODAL &&
    ipsTotalCount + whitelists.length < MAX_API_WHITELISTS_PER_ORGANIZATION;

  return (
    <div>
      {whitelists.map(renderInputs)}

      {canAddMore && (
        <Divider className={styles.divider}>
          <LinkButton
            className={styles.addIpButton}
            onClick={() => setWhitelists([...whitelists, generateEmptyWhitelistParameter()])}
          >
            <PlusIcon />
            Add IP Address
          </LinkButton>
        </Divider>
      )}

      <ButtonWithLoadingState
        kind="primary"
        size="form"
        disabled={hasErrors}
        onClick={handleSubmit}
        className={styles.submitButton}
      >
        Add IP Address
      </ButtonWithLoadingState>
    </div>
  );
};

export default AddIpAddressStep;
