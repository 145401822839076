import React, { FC } from 'react';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { confirmPassword } from 'MyAccount/ActionCreator';
import ConfirmPasswordStep from 'components/VerificationSteps/ConfirmPasswordStep';

interface PasswordConfirmationStepProps {
  onSubmit: () => void;
}

const PasswordConfirmationStep: FC<PasswordConfirmationStepProps> = ({ onSubmit }) => {
  const dispatch = useDispatchWithUnwrap();

  const handleOnContinue = async (password: string) => {
    await dispatch(confirmPassword(password));

    onSubmit();
  };

  return (
    <ConfirmPasswordStep
      onContinue={handleOnContinue}
    />
  );
};

export default PasswordConfirmationStep;
