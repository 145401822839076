import { FC, useCallback, useEffect, useRef, useState } from 'react';
import useRouteBuilder from 'hooks/useRouteBuilder';
import ImagePreviewHeader from './FilePreviewHeader/ImagePreviewHeader';
import clsx from 'clsx';
import { DocumentExtension } from 'enums/DocumentExtension';
import NoPreview from './NoPreview/NoPreview';
import styles from './FilePreview.module.scss';
import { CloseImage } from 'static/images';
import Controls, { INITIAL_ZOOM } from 'components/FilePreview/Controls/Controls';
import { useResizeDetector } from 'react-resize-detector';
import PdfPreview from 'components/FilePreview/PdfPreview/PdfPreview';
import ImagePreview from 'components/FilePreview/ImagePreview/ImagePreview';
import useSmartScrolling from 'hooks/useSmartScrolling';

export const IMAGE_EXTENSIONS = [
  DocumentExtension.Gif,
  DocumentExtension.Jpeg,
  DocumentExtension.Jpg,
  DocumentExtension.Png,
  DocumentExtension.Svg,
  DocumentExtension.Heic,
  DocumentExtension.Webp,
  DocumentExtension.Bmp,
  DocumentExtension.Pdf,
];

export enum PreviewMode {
  ReadOnly = 'readonly',
  Interactive = 'interactive',
}

export interface ImagePreviewProps {
  previewLink?: string;
  filename: string;
  fileExtension: DocumentExtension;
  isFullscreen?: boolean;
  isLoading?: boolean;
  loadingIcon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  mode: PreviewMode;
  onDocumentLoad?: () => void;
  onExitFullScreen?: () => void;
  onFullscreen?: () => void;
  onClose?: () => void;
};

const IMAGE_PADDINGS = 96;
const BLUR_DIVIDER = 350;

const FilePreview: FC<ImagePreviewProps> = ({
  previewLink,
  filename,
  fileExtension,
  isFullscreen,
  isLoading,
  className,
  loadingIcon,
  disabled,
  mode,
  onDocumentLoad,
  onExitFullScreen,
  onClose,
}) => {
  const routeBuilder = useRouteBuilder();
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(IMAGE_EXTENSIONS.includes(fileExtension));
  const decodedFileName = decodeURIComponent(filename);
  const divRef = useRef<HTMLDivElement>(null);
  const [pagesNumber, setPagesNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const smartScrollHandlers = useSmartScrolling();

  const imagePaddings = mode === PreviewMode.ReadOnly ? IMAGE_PADDINGS : 0;

  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [imageWidth, setImageWidth] = useState<number | null>(null);

  useResizeDetector({
    targetRef: divRef,
    onResize: (width) => width && setImageWidth(width),
  });

  const handleResize = (width: number) => {
    setImageWidth(width);
  };

  const handleGoBack = () => {
    if (onExitFullScreen) {
      onExitFullScreen()
    } else {
      routeBuilder.goBack();
    }
  };

  const handleLoadSuccess = useCallback(() => {
    onDocumentLoad?.();
  }, [onDocumentLoad]);

  const handleLoadFailure = useCallback(() => {
    onDocumentLoad?.();
    setIsPreviewAvailable(false);
  }, [onDocumentLoad, setIsPreviewAvailable]);

  const renderLoadingOverlay = () => {
    return (
      <div>
        <div className={styles.loading} />
        {loadingIcon && <div className={styles.loadingIcon}>{loadingIcon}</div>}
      </div>
    );
  }

  const handlePdfLoad = useCallback((newPagesNumber: number) => {
    setPagesNumber(newPagesNumber);
  }, [setPagesNumber]);

  const renderMainFrame = () => {
    if (!previewLink) {
      return null;
    }

    const width = imageWidth === null
      ? null
      : imageWidth * zoom - imagePaddings;

    const blur = mode === PreviewMode.Interactive || width === null
      ? null
      : `blur(${width / BLUR_DIVIDER}px)`;

    if (isPreviewAvailable) {
      const scrollingProps = mode === PreviewMode.Interactive
        ? smartScrollHandlers
        : {};

      return (
        <div
          {...scrollingProps}
          className={styles.previewContainer}
          style={{ filter: blur || undefined }}
        >
          {fileExtension === DocumentExtension.Pdf && (
            <PdfPreview
              previewLink={previewLink}
              width={width}
              disabled={mode === PreviewMode.ReadOnly}
              onLoad={handleLoadSuccess}
              onError={handleLoadFailure}
              onDocumentLoad={handlePdfLoad}
              page={currentPage}
            />
          )}
          {fileExtension !== DocumentExtension.Pdf && (
            <ImagePreview
              fileExtension={fileExtension}
              width={width}
              previewLink={previewLink}
              onLoad={handleLoadSuccess}
              onError={handleLoadFailure}
            />
          )}
        </div>
      );
    }

    return (
      <NoPreview />
    );
  };

  const renderFileInfo = () => {
    return (
      <div className={styles.fileInfo}>
        {filename}
      </div>
    );
  }

  useEffect(() => {
    if (divRef.current) {
      const { width } = divRef.current.getBoundingClientRect();

      handleResize(width);
    }
  }, [divRef]);

  const classes = clsx(
    styles.container,
    isFullscreen && styles.fullScreen,
    disabled && styles.disabled,
    className,
  );

  return (
    <div className={classes} ref={divRef}>
      {isFullscreen && (
        <ImagePreviewHeader
          fileName={decodedFileName}
          isLoading={isLoading}
          onGoBack={handleGoBack}
          className={styles.header}
        />
      )}
      {renderMainFrame()}
      {!isLoading && !disabled && isPreviewAvailable && (
        <Controls
          onZoom={setZoom}
          zoom={zoom}
          className={styles.controls}
          showPageControls={fileExtension === DocumentExtension.Pdf}
          onNext={() => setCurrentPage(currentPage + 1)}
          isNextDisabled={currentPage === pagesNumber}
          onPrevious={() => setCurrentPage(currentPage - 1)}
          isPreviousDisabled={currentPage === 1}
        />
      )}
      {isLoading && renderLoadingOverlay()}
      {mode === PreviewMode.ReadOnly && renderFileInfo()}
      {onClose && <CloseImage className={styles.closeIcon} onClick={() => onClose()} />}
    </div>
  );
};

export default FilePreview;
