import React, { FC } from 'react';
import TableBodyCell, { TableBodyCellProps } from 'components/Table/TableBodyCell';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import SearchHighlight from 'components/SearchHighlight';

export interface TableBodyCellWithHighlightProps extends TableBodyCellProps {
  search?: string;
  disableHighlight?: boolean;
  withoutHighlightContent?: React.ReactNode;
  withoutOverflowContent?: React.ReactNode;
}

const TableBodyCellWithHighlight: FC<TableBodyCellWithHighlightProps> = ({
  children,
  search,
  disableHighlight,
  withoutHighlightContent,
  noContentWarningIconTooltip,
  noContentText,
  displayNoContentWarningIcon,
  ...cellProps
}) => {
  return (
    <TableBodyCell {...cellProps}>
      {withoutHighlightContent}
      <SearchHighlight search={!disableHighlight ? (search || '') : ''}>
        {children || (
          <TableBodyCellNoContent
            text={noContentText}
            displayWarningIcon={displayNoContentWarningIcon}
            warningIconTooltip={noContentWarningIconTooltip}
          />
        )}
      </SearchHighlight>
    </TableBodyCell>
  );
};

export default TableBodyCellWithHighlight;
