import { ImageParams, User } from './UserApi';
import BaseSystemApi from './BaseApi';


export interface IAccountAvatarApi {
  updateAvatar(avatar: Blob, croppedAvatar: Blob, params: ImageParams): Promise<void>;
  deleteAccountPhoto(): Promise<void>;
  getOriginalAvatar(): Promise<Blob>;
}

export default class AccountAvatarRestApi extends BaseSystemApi<User> implements IAccountAvatarApi {
  protected resourceName = 'account-avatar';

  public async deleteAccountPhoto(): Promise<void> {
    await this.deleteResource('');
  }

  public async getOriginalAvatar(): Promise<Blob> {
    const avatar = await this.download(`/${this.resourceName}`, true);

    return avatar.file;
  }

  public async updateAvatar(avatar: Blob, croppedAvatar: Blob, params: ImageParams): Promise<void> {
    const formData = new FormData();

    formData.append('files', avatar);
    formData.append('files', croppedAvatar);
    formData.append('params', JSON.stringify(params));

    await this.fetch(`/${this.resourceName}`, 'PUT', formData, {
      contentType: null,
    });
  }
}
