import React, { FC, useState } from 'react';
import QuestionIcon from 'components/QuestionIcon/QuestionIcon';
import { ReactComponent as AutoDetectionEmptyIcon } from './auto-detection-empty.svg';
import { ReactComponent as AutoDetectionIcon } from './auto-detection.svg';
import ShareButton from 'components/ShareButton/ShareButton';
import StyledSwitch from 'components/StyledSwitch';
import useUniqId from 'hooks/useUniqId';
import { DocumentType } from 'api/DocumentTypeApi';
import clsx from 'clsx';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import DataExtractionUiAction from 'enums/DataExtractionUiAction';
import styles from './AutoDetectionSection.module.scss';

interface AutoDetectionSectionProps {
  documentType: DocumentType;
  onAutoDetectionChange: (checked: boolean) => Promise<void>;
}

const renderTooltipForTitle = () => (
  <>
    Auto-detection recognizes document <br/>
    type during data extraction, erasing the <br/>
    need for manual selection.
  </>
);

const renderTooltipForAutoDetectionSwitch = () => (
  <>
    Enable automated recognition for <br/>
    this document type.
  </>
);

const AutoDetectionSection: FC<AutoDetectionSectionProps> = ({
  documentType,
  onAutoDetectionChange,
}) => {
  const switchId = useUniqId('auto-detection-switch');
  const [isAutoDetectionEnabled, setIsAutoDetectionEnabled] = useState(documentType.isAutoDetectionEnabled);
  const [isDisabled, setIsDisabled] = useState(false);
  const routeBuilder = useRouteBuilder();

  const handleAutoDetectionChange = async (checked: boolean) => {
    setIsAutoDetectionEnabled(checked);

    setIsDisabled(true);

    try {
      await onAutoDetectionChange(checked);
    } catch {
      setIsAutoDetectionEnabled(!checked);
    }

    setIsDisabled(false);
  };

  const handleAnalyzeDocumentClick = () => {
    routeBuilder.withParams({
      [DataExtractionUiAction.Run]: documentType.id,
    }).open(AppRoutes.extractData);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Auto-Detection
        <QuestionIcon tooltip={renderTooltipForTitle()} size={24} />
      </div>
      {documentType.doesTrainingDataExist && (
        <div className={styles.switchContainer}>
          <StyledSwitch
            id={switchId}
            className={styles.switch}
            title="Enable auto-detection"
            color="primary"
            checked={isAutoDetectionEnabled}
            onChange={(event, checked) => handleAutoDetectionChange(checked)}
            disabled={isDisabled}
          />
          <label className={styles.switchLabel} htmlFor={switchId}>Enable auto-detection</label>
          <QuestionIcon tooltip={renderTooltipForAutoDetectionSwitch()} />
        </div>
      )}
      <div className={styles.body}>
          {!documentType.doesTrainingDataExist && <AutoDetectionEmptyIcon className={styles.autoDetectionEmptyIcon} />}
          {documentType.doesTrainingDataExist && <AutoDetectionIcon />}
          <div className={styles.message}>
            {documentType.doesTrainingDataExist ? 'Inconsistent document type auto-detection?' : 'Enable auto-detection'}
          </div>
          <div className={styles.secondaryMessage}>
            {documentType.doesTrainingDataExist ? 'Analyze more documents to enhance accuracy.' : 'by analyzing at least 1 document.'}
          </div>
          <ShareButton
            kind="primary"
            className={clsx(styles.analyzeDocument, documentType.doesTrainingDataExist && styles.topRight)}
            onClick={handleAnalyzeDocumentClick}
          >
            Analyze Document
          </ShareButton>
        </div>
    </div>
  );
};

export default AutoDetectionSection;
