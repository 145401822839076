import { useHistory, useLocation } from 'react-router';
import RouteBuilder from 'routes/RouteBuilder';
import { useMemo } from 'react';

const useRouteBuilder = (): RouteBuilder => {
  const history = useHistory();
  const location = useLocation();

  return useMemo(() => new RouteBuilder(history, location), [history, location]);
};

export default useRouteBuilder;
