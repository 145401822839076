import TableRow from 'components/Table/TableRow';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';

const DataExtractionResultOutputSkeletonRow = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
    </TableRow>
  );
};

export default DataExtractionResultOutputSkeletonRow;
