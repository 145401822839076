import AutoDetectedLabel from 'components/AutoDetectedLabel/AutoDetectedLabel';
import DotFallingLoader from 'components/DotFallingLoader/DotFallingLoader';
import React, { FC } from 'react';
import OverflowedText from 'components/OverflowedText';

import styles from './DocumentTypeLabel.module.scss';

interface DocumentTypeLabelProps {
  isProcessing?: boolean;
  isAutoDetected?: boolean;
  documentType?: string;
}

const DocumentTypeLabel: FC<DocumentTypeLabelProps> = ({
  isProcessing,
  isAutoDetected,
  documentType,
}) => {
  if (isProcessing) {
    return <DotFallingLoader />;
  }

  if (!documentType) {
    return <>—</>;
  }

  if (isAutoDetected) {
    return <AutoDetectedLabel text={documentType} />
  }

  return (
    <OverflowedText className={styles.overflowedText}>
      {documentType}
    </OverflowedText>
  );
};

export default DocumentTypeLabel;
