import React, { FC } from 'react';
import { User } from 'api/UserApi';
import ContextualViewContainer from 'components/ContextualViewContainer';
import DetailsHeader from 'components/DetailsHeader';
import EditUserForm, { EditUserFormProps } from 'components/Users/Forms/EditUserForm/EditUserForm';
import EditUserFormSkeleton from 'components/Users/Forms/EditUserForm/EditUserFormSkeleton';

import styles from './EditUserContextualView.module.scss';

export interface EditUserContextualViewProps extends Omit<EditUserFormProps, 'user'> {
  onClose: () => void;
  user: User | null;
}

const EditUserContextualView: FC<EditUserContextualViewProps> = ({
  onClose,
  user,
  ...restProps
}) => {
  return (
    <ContextualViewContainer minimizeSize onClose={onClose}>
      <DetailsHeader title="Edit User" referenceId={user?.id ?? null} className={styles.detailsHeader} />
      {user ? <EditUserForm user={user} {...restProps} /> : <EditUserFormSkeleton />}
    </ContextualViewContainer>
  );
};

export default EditUserContextualView;
