import { useCallback } from 'react';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'components/MainLayout';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import useStateReset from 'hooks/useStateReset';
import {
  extractionHistoryDashboardActionOrigin,
  hideFiltersPanel,
  resetState,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryDashboardStore';
import { useDispatch } from 'react-redux';
import { ExtractionHistoryDashboard } from 'components/ExtractionHistory/Dashboards';
import { DataExtractionFilters } from 'components/ExtractionHistory/Filters';
import useBlockingRequest from 'hooks/useBlockingRequest';
import { useDocumentTypeApi } from 'providers/ApiServiceProvider';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { deleteDataExtraction } from 'DataExtractions/Thunks';
import useConfirmChanges from 'hooks/useConfirmChanges';
import ConfirmPopup from 'components/ConfirmPopup';
import { DocumentTypeSortingField } from 'api/DocumentTypeApi';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import DataExtractionUiAction from 'enums/DataExtractionUiAction';
import useQueryParam from '../../hooks/useQueryParam';
import DataExtractionContextualView from '../../components/DataExtractionContextualView';
import { useCloseContextualView } from '../../components/MainLayout/utils';

export const DATA_EXTRACTION_VIEW_QUERY_PARAM = 'dataExtractionId';

const ExtractionHistory = () => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const documentTypeApi = useDocumentTypeApi();
  const routeBuilder = useRouteBuilder();

  const dataExtractionId = useQueryParam(DATA_EXTRACTION_VIEW_QUERY_PARAM);

  const { filters } = useSelector((state: ReduxState) =>
    state.generalSettings.ui.extractionHistoryDashboard,
  );

  useStateReset(resetState);

  const [deleteDataExtractionInProgress, useBlockingDeleteDataExtractionCallback] = useBlockingRequest();
  const [
    displayConfirmDataExtractionDelete,
    resetConfirmDataExtractionDelete,
    onConfirmDataExtractionDelete,
    useConfirmDataExtractionDeleteCallback,
  ] = useConfirmChanges();

  const handleCloseFiltersPanel = () => {
    dispatch(hideFiltersPanel());
  };

  const handleFetchDocumentTypesOptions = useCallback(
    async (search: string) => {
      const { items: documentTypes } = await documentTypeApi.search(
        { search },
        { field: DocumentTypeSortingField.Name, ascending: true },
      );

      return documentTypes.map((documentType) => ({
        name: documentType.name,
        value: documentType.id,
      }));
    },
    [],
  );

  const renderFiltersView = () => {
    if (filters.showFiltersPanel) {
      return (
        <DataExtractionFilters
          onFetchDocumentTypeOptions={handleFetchDocumentTypesOptions}
        />
      );
    }

    return null;
  };

  const handleDeleteDataExtraction = useConfirmDataExtractionDeleteCallback(
    useBlockingDeleteDataExtractionCallback(async (id: string) => {
      await dispatchWithUnwrap(deleteDataExtraction({
        id,
        actionOrigin: extractionHistoryDashboardActionOrigin,
      }));
    }),
  );

  const renderOverlay = () => {
    if (displayConfirmDataExtractionDelete) {
      return (
        <ConfirmPopup
          confirmText='Delete Results'
          declineText='No, Go Back'
          message='Are you sure you want to delete processing results?'
          title='Delete Results'
          loading={deleteDataExtractionInProgress}
          onPopupClose={resetConfirmDataExtractionDelete}
          onConfirmClick={onConfirmDataExtractionDelete}
        />
      );
    }

    return null;
  };

  const redirectToExtractData = () => {
    routeBuilder.withParams({
      [DataExtractionUiAction.Run]: '',
    }).go(AppRoutes.extractData);
  };

  const closeContextualView = useCloseContextualView(DATA_EXTRACTION_VIEW_QUERY_PARAM);

  const renderContextualView = () => {
    if (dataExtractionId) {
      return <DataExtractionContextualView
        dataExtractionId={dataExtractionId}
        onClose={closeContextualView}
      />
    }

    return null;
  };

  return (
    <MainLayout
      contextualView={renderContextualView()}
      rightSidePopupView={renderFiltersView()}
      closeRightSidePopupView={handleCloseFiltersPanel}
      closeContextualView={closeContextualView}
      overlay={renderOverlay()}
    >
      <PageWrapperWithFooter>
        <PageContent noPadding>
          <ExtractionHistoryDashboard
            onCreateDataExtraction={() => redirectToExtractData()}
            onDeleteDataExtraction={handleDeleteDataExtraction}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default ExtractionHistory;
