import { ReduxState } from 'types/ReduxState';
import createSelectorWithDispatch from 'hooks/createSelectorWithDispatch';
import { getOrganizationInfo } from 'Organization/OrganizationStore';

const useOrganizationInfo = createSelectorWithDispatch(
  getOrganizationInfo,
  (state: ReduxState) => state.organization.detailedInfo,
);

export default useOrganizationInfo;
