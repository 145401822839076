import { DocumentTypeApi } from 'api/DocumentTypeApi';
import { setDocumentTypes } from 'DocumentTypes/DocumentTypesStore';
import {
  pagination as documentTypeDashboardPagination,
  documentTypesDashboardActionOrigin,
} from './DocumentTypesDashboardStore';
import { AnyAction, MiddlewareAPI, Dispatch, Middleware } from 'redux';
import { ReduxState } from 'types/ReduxState';
import { isFulfilled } from '@reduxjs/toolkit';
import {
  createDocumentType,
  deleteDocumentType,
  duplicateDocumentType,
  getDocumentTypes,
  updateDocumentType,
} from 'DocumentTypes/Thunks';
import { get } from 'lodash';

const createDocumentTypesDashboardMiddlewares = (
  api: DocumentTypeApi,
  pagination: typeof documentTypeDashboardPagination,
  path: string | string[],
  actionOrigins: string[],
) => {
  const paginationMiddleware = pagination.getItemsFetcherMiddleware(async (params, dispatch) => {
    const { items, total } = await api.search(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
        selectedMembers: params.selectedMembers,
      },
      params.sortingType,
    );

    dispatch(setDocumentTypes(items));

    return {
      items: items.map((documentType) => documentType.id),
      total,
    };
  });

  const updatesMiddleware = (({ dispatch, getState }: MiddlewareAPI<Dispatch<AnyAction>, ReduxState>) => (
    next: (action: AnyAction) => any,
  ) => (action: AnyAction) => {
    const result = next(action);

    if (
      !isFulfilled(deleteDocumentType)(action) && 
      !isFulfilled(createDocumentType)(action) &&
      !isFulfilled(updateDocumentType)(action) &&
      !isFulfilled(duplicateDocumentType)(action)
    ) {
      return result;
    }

    if (!action.meta.actionOrigin || !actionOrigins.includes(action.meta.actionOrigin)) {
      return result;
    }

    const state = getState();
    const { filters, sortingType } = get(state, path);

    //TODO [dl] find a way to fetch a count of items that will fit into the infinite scroll container
    dispatch(getDocumentTypes({
      filters: {
        search: filters.search,
        selectedMembers: filters.teamMembers,
        offset: 0,
      },
      sortingType,
      actionOrigin: documentTypesDashboardActionOrigin,
    }) as unknown as AnyAction)

    return result;
  }) as Middleware;

  return {
    paginationMiddleware,
    updatesMiddleware,
  }
};

export default createDocumentTypesDashboardMiddlewares;
