import React, { FC } from 'react';
import SIZES from 'components/BillingDashboard/Invoices/TableColumnSizes';
import { TableRow, TableBodyCell } from 'components/Table';
import { SkeletonRectangle } from 'components/Skeleton';

const InvoicesTableSkeletonRow: FC = () => {
  return (
    <TableRow>
      <TableBodyCell width={SIZES.InvoiceDate}>
        <SkeletonRectangle width="160px" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.Description}>
        <SkeletonRectangle width="230px" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.InvoiceNumber}>
        <SkeletonRectangle width="80px" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.Amount}>
        <SkeletonRectangle width="80px" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.Status}>
        <SkeletonRectangle width="80px" height="16px" color="primary6" />
      </TableBodyCell>
    </TableRow>
  );
};

export default InvoicesTableSkeletonRow;
