import { TableHead, TableHeadCell } from 'components/Table';
import React, { FC } from 'react'
import { TableColumnSize } from '../TableColumnSize';
import { DataExtractionSortingField } from 'api/DataExtractionApi';

interface DataExtractionDesktopHeaderRowProps {
  className?: string;
  getSortingType: (field: DataExtractionSortingField) => boolean | undefined;
  onSortingChange: (field: DataExtractionSortingField) => void;
}

const DataExtractionDesktopHeaderRow: FC<DataExtractionDesktopHeaderRowProps> = ({
  className,
  getSortingType,
  onSortingChange,
}) => {
  return (
    <TableHead sticky className={className}>
      <TableHeadCell
        width={TableColumnSize.Date}
        ascending={getSortingType(DataExtractionSortingField.CreatedAt)}
        onClick={() => onSortingChange(DataExtractionSortingField.CreatedAt)}
      >
        Date
      </TableHeadCell>
      <TableHeadCell
        width={TableColumnSize.FileName}
        ascending={getSortingType(DataExtractionSortingField.FileName)}
        onClick={() => onSortingChange(DataExtractionSortingField.FileName)}
      >
        File Name
      </TableHeadCell>
      <TableHeadCell
        width={TableColumnSize.FileType}
        ascending={getSortingType(DataExtractionSortingField.FileType)}
        onClick={() => onSortingChange(DataExtractionSortingField.FileType)}
      >
        File Type
      </TableHeadCell>
      <TableHeadCell
        width={TableColumnSize.DocumentType}
        ascending={getSortingType(DataExtractionSortingField.DocumentTypeNames)}
        onClick={() => onSortingChange(DataExtractionSortingField.DocumentTypeNames)}
      >
        Document Type
      </TableHeadCell>
      <TableHeadCell
        width={TableColumnSize.Pages}
        ascending={getSortingType(DataExtractionSortingField.PagesCount)}
        onClick={() => onSortingChange(DataExtractionSortingField.PagesCount)}
      >
        Pages
      </TableHeadCell>
      <TableHeadCell
        width={TableColumnSize.ProcessingTime}
        ascending={getSortingType(DataExtractionSortingField.TotalDurationTime)}
        onClick={() => onSortingChange(DataExtractionSortingField.TotalDurationTime)}
      >
        Processing Time
      </TableHeadCell>
      <TableHeadCell
        withActionsCell
        width={TableColumnSize.Result}
        ascending={getSortingType(DataExtractionSortingField.Status)}
        onClick={() => onSortingChange(DataExtractionSortingField.Status)}
      >
        Result
      </TableHeadCell>
    </TableHead>
  )
}

export default DataExtractionDesktopHeaderRow;
