import React, { FC } from 'react';
import clsx from 'clsx';
import { AddToImage, WarningIcon } from 'static/images';

import styles from './DataExtractionResultOutputInfoPanel.module.scss';

type DataExtractionInfoPanelType = 'warning' | 'info';

interface DataExtractionResultOutputInfoPanelProps {
  className?: string;
  type: DataExtractionInfoPanelType;
  text: string | React.ReactNode;
}

const DataExtractionResultOutputInfoPanel: FC<DataExtractionResultOutputInfoPanelProps> = ({
  className,
  type,
  text,
}) => {
  const getClassNameByType = () => {
    switch (type) {
      case 'warning':
        return styles.warning;
      case 'info':
        return styles.info;
      default:
        return styles.info;
    }
  }

  const renderIconByType = () => {
    switch (type) {
      case 'warning':
        return <WarningIcon className={styles.icon} />;
      case 'info':
        return <AddToImage className={styles.icon} />;
      default:
        return <AddToImage className={styles.icon} />;
    }
  }

  return (
    <div className={clsx(styles.container, getClassNameByType(), className)}>
      <div>
        {renderIconByType()}
      </div>
      <p className={styles.text}>
        {text}
      </p>
    </div>
  );
};

export default DataExtractionResultOutputInfoPanel;
