import React, { ReactElement, useEffect, useState } from 'react';
import MainLayout, { PageContent, PageWrapper } from 'components/MainLayout';
import { createPublicApiKey, deletePublicApiKey, getPublicApiKeys } from '../../PublicApi/ActionCreator';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../types/ReduxState';
import useDispatchWithUnwrap from '../../hooks/useDispatchWithUnwrap';
import notification from 'handlers/notification/notificationActionCreator';
import GeneratePublicApiKeyPopup from './GeneratePublicApiKeyPopup';
import WithIdentityConfirmActionPopup from '../../components/WithIdentityConfirmationActionPopup';
import ApiCredentials from '../../components/ApiCredentials';
import styles from './ApiCredentials.module.scss';

const ApiCredentialsPage = () => {
  const dispatch = useDispatchWithUnwrap();

  const [apiKey, setApiKey] = useState<string>('');
  const [
    generateApiMode,
    setGenerateApiMode,
  ] = useState<boolean>(false);
  const [
    publicApiKeyIdToDelete,
    setPublicApiKeyIdToDelete,
  ] = useState<string | null>(null);

  const { keys } = useSelector((state: ReduxState) => state.publicApi);
  const currentUser = useSelector((state: ReduxState) => state.users.currentUser);

  const handleGenerateApiKeyPopupClose = async () => {
    setGenerateApiMode(false);
    setApiKey('');
  };

  const handleConfirmDeleteApiKeyPopupClose = async () => {
    setPublicApiKeyIdToDelete(null);
  };

  const handleConfirmDeleteApiKey = async () => {
    if (!publicApiKeyIdToDelete) {
      return;
    }

    await dispatch(deletePublicApiKey(publicApiKeyIdToDelete));
    await dispatch(getPublicApiKeys());

    notification.createNotification('The API Key has been removed.', 'success', dispatch);

    setPublicApiKeyIdToDelete(null);
  };

  const handleConfirmCreate = async () => {
    const publicApiKey = await dispatch(createPublicApiKey());

    setApiKey(publicApiKey.apiKey);
    await dispatch(getPublicApiKeys());
  };

  const getOverlay = (): ReactElement | null => {
    if (generateApiMode) {
      return (
        <GeneratePublicApiKeyPopup
          apiKey={apiKey}
          onConfirmCreate={handleConfirmCreate}
          onClose={handleGenerateApiKeyPopupClose}
        />
      );
    }

    if (publicApiKeyIdToDelete) {
      return (
        <WithIdentityConfirmActionPopup
          actionName="Delete API Key"
          onConfirmationComplete={handleConfirmDeleteApiKey}
          onClose={handleConfirmDeleteApiKeyPopupClose}
        />
      );
    }

    return null;
  };


  useEffect(() => {
    dispatch(getPublicApiKeys());
  }, []);

  return (
    <MainLayout overlay={getOverlay()}>
      <PageWrapper>
        <PageContent className={styles.container}>
          <ApiCredentials
            apiKeys={keys}
            currentUser={currentUser ?? undefined}
            onDeleteKey={(id) => setPublicApiKeyIdToDelete(id)}
            onCreateKey={() => setGenerateApiMode(true)}
          />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default ApiCredentialsPage;
