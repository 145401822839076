import { createContext, useContext } from 'react';
import DocumentParsingsRestApi from '../api/DocumentParsingsApi';
import DataExtractionRestApi from '../api/DataExtractionApi';
import DataExtractionResultRestApi from '../api/DataExtractionResultApi';
import DocumentTypeRestApi from '../api/DocumentTypeApi';
import PollingRestApi from '../api/PollingApi';
import DocumentTokenApi from 'api/DocumentTokenApi';

export interface IApiProviderValue {
  documentParsingsApi: DocumentParsingsRestApi;
  dataExtractionsApi: DataExtractionRestApi;
  documentTypeApi: DocumentTypeRestApi;
  documentTokenApi: DocumentTokenApi;
  dataExtractionResultsApi: DataExtractionResultRestApi;
  pollingApi: PollingRestApi;
}

export type Services =
  | DocumentParsingsRestApi
  | DataExtractionRestApi
  | DataExtractionResultRestApi
  | PollingRestApi
  | DocumentTypeRestApi
  | DocumentTokenApi;

const ApiContext = createContext<IApiProviderValue>({} as IApiProviderValue);

const getService = <ServiceType extends Services>(serviceName: keyof IApiProviderValue) => () => {
  const services = useContext(ApiContext);

  return services[serviceName] as ServiceType;
};

export const useDocumentParsingsApi = getService<DocumentParsingsRestApi>('documentParsingsApi');
export const useDataExtractionsApi = getService<DataExtractionRestApi>('dataExtractionsApi');
export const useDataExtractionResultsApi = getService<
  DataExtractionResultRestApi
>('dataExtractionResultsApi');
export const useDocumentTypeApi = getService<DocumentTypeRestApi>('documentTypeApi');
export const usePollingApi = getService<PollingRestApi>('pollingApi');
export const useDocumentTokenApi = getService<DocumentTokenApi>('documentTokenApi');

const ApiProvider = ApiContext.Provider;

export default ApiProvider;
