import React, { FC } from 'react';
import clsx from 'clsx';
import { ShareImageCustomColor } from 'static/images';
import styles from './NeedHelpSection.module.scss';

export interface NeedHelpSectionProps {
  className?: string;
  link?: string;
  linkTitle?: string;
}

const DEFAULT_NEED_HELP_LINK = 'https://synthetiq.readme.io/reference';
const DEFAULT_LINK_TITLE = 'User Guide';

const NeedHelpSection: FC<NeedHelpSectionProps> = ({
  className,
  link = DEFAULT_NEED_HELP_LINK,
  linkTitle = DEFAULT_LINK_TITLE,
}) => {
  const renderUserGuideLink = () => (
    <a className={styles.userGuideLink} href={link} rel="noreferrer" target="_blank">
      {linkTitle}
      <ShareImageCustomColor />
    </a>
  );

  return (
    <div className={clsx(styles.helpSection, className)}>
      Need Help? Visit our {renderUserGuideLink()}
    </div>
  );
};

export default NeedHelpSection;
