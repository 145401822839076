export enum DataExtractionResultsTableColumnSize {
  FileName = 54,
  FileType = 10,
  DocumentType = 25,
  Pages = 8,
}

export enum MobileTableColumnSize {
  Date = 70,
  Result = 30,
}
