
export enum NavigationLinkName {
  ExtractData = 'Extract Data',
  ApiCredentials = 'API Credentials',
  Webhooks = 'Webhooks',
  IpAddressWhitelist = 'IP Address Whitelist',
  ExtractionHistory = 'Extraction History',
  DocumentTypes = 'Document Types',
  Developers = 'Developers',
}

export type NavigationLinkIdType = keyof (typeof NavigationLinkName) | 'ExtractData' | 'NotFound';

type NavigationLinkIdMapType = Record<NavigationLinkIdType, NavigationLinkIdType>;

export const NavigationLinkIdMap = Object.keys(NavigationLinkName).reduce((map, key) => {
  return {
    ...map,
    [key]: key,
  };
}, {
  ExtractData: 'ExtractData',
  NotFound: 'NotFound',
}) as NavigationLinkIdMapType;

export default NavigationLinkName;
