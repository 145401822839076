import { History, Location } from 'history';
import PoorRouteBuilder, { QueryParams } from './PoorRouteBuilder';
import joinUrls from 'url-join';
import AppSectionRoutes from './AppSectionRoutes';

const withRoot = <RoutesType extends Record<string, string>>(root: string, routes: RoutesType): RoutesType => {
  return Object.keys(routes).reduce((mappedRoutes, key) => {
    return {
      ...mappedRoutes,
      [key]: joinUrls(root, routes[key]),
    };
  }, {}) as RoutesType;
};

export enum ExternalRoutes {
  ContactSales = 'https://synthetiq.co/contact-sales',
  ContactSupport = 'https://synthetiq.co/contact-support',
  MasterAgreement = 'https://synthetiq.co/master-subscription-agreement',
  PrivacyPolicy = 'https://synthetiq.co/privacy-policy',
}

export const AppRoutes = {
  extractData: '/extract-data',
  documentTypes: '/document-types',
  extractionHistory: '/extraction-history',
  apiCredentials: '/developers/api-credentials',
  webhooks: '/developers/webhooks',
  ipWhitelist: '/developers/ip-address-whitelist',
  logout: '/logout',
  teamMembers: '/team',
  billing: '/billing',
  customerPortal: '/plan-and-billing/portal',
  contactUs: '/contact-us',
  auth: withRoot(AppSectionRoutes.auth, {
    signIn: '/sign-in',
    acceptInvite: '/accept-invite/:token',
    emailVerification: '/email-verification',
    cancelledSubscription: '/subscription-cancelled',
    createAccount: '/create-account',
    forgotPassword: '/forgot',
    inactiveUser: '/user-not-active',
    loggedOut: '/logged-out',
    pastDueSubscription: '/subscription-past-due',
    resetPassword: '/reset-password',
    mfa: '/mfa',
  }),
};

export default class RouteBuilder extends PoorRouteBuilder {
  constructor(
    protected history: History,
    protected location: Location,
    protected queryParams?: URLSearchParams,
  ) {
    super(queryParams);
  }

  public withParams(queryParams: QueryParams | URLSearchParams): RouteBuilder {
    return super.withParams(queryParams) as RouteBuilder;
  }

  public get currentQueryParamsRaw(): string {
    return this.location.search;
  }

  public get currentQueryParams(): URLSearchParams {
    return new URLSearchParams(this.location.search);
  }

  public get currentPathname(): string {
    return this.location.pathname;
  }

  public go(...routes: string[]): void {
    const targetRoute = this.build(...routes);

    this.history.push(targetRoute);
  }

  public goToLocation(location: Location): void {
    this.history.push(location);
  }

  public goBack(): void {
    this.history.goBack();
  }

  protected clone(queryParams?: URLSearchParams): RouteBuilder {
    return new RouteBuilder(this.history, this.location, queryParams);
  }
}
