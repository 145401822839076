import React, { FC } from 'react';
import PlanDetailsColumnSize from 'components/BillingDashboard/Overview/PlanDetails/PlanDetailsColumnSize';
import Table, { TableBody, TableBodyCell, TableHead, TableRow } from 'components/Table';
import PlanDetailsSkeleton from './PlanDetailsSkeleton';
import formatMonetaryValue from '../../../../utils/formatMonetaryValue';
import styles from './PlanDetails.module.scss';

interface PlanDetailsProps {
  freeCreditsRemaining?: number | null;
  pricePerPage?: number;
  currency?: string | null;
}

const PlanDetails: FC<PlanDetailsProps> = ({
  freeCreditsRemaining,
  pricePerPage,
  currency,
}) => {
  const isLoading =
    !currency ||
    pricePerPage === undefined;

  return isLoading ? (
    <PlanDetailsSkeleton />
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        Pricing Details
      </div>
      <Table>
        <TableHead />
        <TableBody>
          <TableRow disableHover>
            <TableBodyCell width={PlanDetailsColumnSize.Name} className={styles.nameCell}>Price Per Page</TableBodyCell>
            <TableBodyCell width={PlanDetailsColumnSize.Value} className={styles.valueCell}>
              {pricePerPage && formatMonetaryValue(pricePerPage / 100, { currency })}
            </TableBodyCell>
          </TableRow>
          {freeCreditsRemaining && <TableRow disableHover>
            <TableBodyCell width={PlanDetailsColumnSize.Name} className={styles.nameCell}>
              Free Credits Remaining
            </TableBodyCell>
            <TableBodyCell width={PlanDetailsColumnSize.Value} className={styles.valueCell}>
              {formatMonetaryValue(freeCreditsRemaining / 100, { currency })}
            </TableBodyCell>
          </TableRow>}
        </TableBody>
      </Table>
    </div>
  );
};

export default PlanDetails;
