import { DocumentTypeField } from 'api/DocumentTypeFieldApi';
import { FC } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import DocumentTypeFieldForm, {
  DocumentTypeFieldViewData,
} from 'components/DocumentType/Forms/DocumentTypeFieldForm/DocumentTypeFieldForm';

import styles from './DocumentTypeFieldPopup.module.scss';

interface DocumentTypeFieldPopupProps {
  title: string;
  isActionOnProgress: boolean;
  submitButtonTitle: string;
  usePortal?: boolean;
  documentTypeField?: DocumentTypeField;
  documentTypeFieldChildren?: DocumentTypeField[];
  message?: string;
  onClose: () => void;
  onSubmit: (fieldViewData: DocumentTypeFieldViewData) => void;
}

const DocumentTypeFieldPopup: FC<DocumentTypeFieldPopupProps> = ({
  title,
  isActionOnProgress,
  usePortal = false,
  submitButtonTitle,
  documentTypeField,
  documentTypeFieldChildren,
  onClose,
  onSubmit,
}) => {
  return (
    <PopUp
      title={title}
      closable={!isActionOnProgress}
      closePopUp={onClose}
      usePortal={usePortal}
    >
      <PopUpContent className={styles.popupContent}>
        <DocumentTypeFieldForm
          isActionOnProgress={isActionOnProgress}
          submitButtonTitle={submitButtonTitle}
          onSubmit={onSubmit}
          field={documentTypeField}
          children={documentTypeFieldChildren}
        />
      </PopUpContent>
    </PopUp>
  )
}

export default DocumentTypeFieldPopup;
