import { FC, useState } from 'react';
import DataExtractionResultOutputTableRow from '../DataExtractionResultOutputTableRow/DataExtractionResultOutputTableRow';
import { DropdownImage } from 'static/images';
import clsx from 'clsx';
import Accordion from 'components/Accordion';
import {
  MappedDataExtractionResult,
  DataExtractionResultObjectValue,
  DataExtractionResultFlatValue,
} from 'components/DataExtractionResultOverview/DataExtractionResultOutputSection/DataExtractionResultOutputTable/mapDataExtractionResult';

import styles from './DataExtractionResultOutputTableCollapsibleRow.module.scss';

interface DataExtractionResultOutputTableCollapsibleRowProps {
  result: MappedDataExtractionResult;
  hasTopBorder?: boolean;
}

const DataExtractionResultOutputTableCollapsibleRow: FC<DataExtractionResultOutputTableCollapsibleRowProps> = ({
  result,
  hasTopBorder = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderParentRow = () => (
    <DataExtractionResultOutputTableRow
      result={{key: result.key, value: 'Object'}}
      valueClassName={styles.parentValue}
      arrowIcon={<DropdownImage className={clsx(styles.arrowIcon, isOpen && styles.arrowIconExpanded)} />}
      disableHover={false}
      hasTopBorder={hasTopBorder}
    />
  );

  return (
    <Accordion
    triggerComponent={renderParentRow()}
    open={isOpen}
    collapsibleContainerClassName={styles.collapsibleContainer}
    onOpen={setIsOpen}
  >
    {Object.entries(result.value as DataExtractionResultObjectValue).map(([key, value]) => (
      <DataExtractionResultOutputTableRow
        key={key}
        result={{ key, value: value as DataExtractionResultFlatValue }}
        nameCellClassName={styles.childNameCell}
      />
    ))}
  </Accordion>
  )
}

export default DataExtractionResultOutputTableCollapsibleRow;
