import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import Header from 'components/LeftNav/LeftNavigationHeader';
import UserCard from 'components/LeftNav/UserCard';
import NavigationLinks from 'components/LeftNav/NavigationLinks';
import { toggleLeftNavExpand } from 'components/MainLayout/leftNavReducer';
import { VectorRightImage } from 'static/images';
import productSectionList from './productSectionList';
import getAvatarUrl from 'api/utils/getAvatarUrl';
import AccountMenu from 'components/LeftNav/AccountMenu';
import { getLeftNavigationStateByLocation } from 'components/MainLayout/utils';
import { AppRoutes } from 'routes/RouteBuilder';
import useRouteBuilder from 'hooks/useRouteBuilder';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getOrganizationInfo } from 'Organization/OrganizationStore';
import styles from './LeftNavigation.module.scss';
import clsx from 'clsx';

interface LeftNavigationBarProps {
  isOpenedOnMobile: boolean;
  onClose: () => void;
}

const LeftNavigationBar: FC<LeftNavigationBarProps> = ({
  isOpenedOnMobile,
  onClose,
}) => {
  const routeBuilder = useRouteBuilder();

  const {
    activeNavigationLinkId,
    noLinks,
    activeNestedLinkId,
  } = getLeftNavigationStateByLocation(routeBuilder.currentPathname);

  const dispatch = useDispatch();
  const dispatchThunk = useDispatchWithUnwrap();
  const { isOpen } = useSelector((state: ReduxState) => state.myAccount);
  const currentUser = useSelector((state: ReduxState) => state.users.currentUser);
  const isLeftNavOpen = useSelector((state: ReduxState) => state.leftNavigation.isLeftNavOpen);
  const [
    accountMenuOpenState,
    setAccountMenuOpenState,
  ] = useState<boolean>(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const organizationInfo = useSelector((state: ReduxState) => state.organization.detailedInfo);

  useEffect(() => {
    if (!organizationInfo) {
      dispatchThunk(getOrganizationInfo());
    }
  }, []);

  if (!currentUser || !organizationInfo) {
    return null;
  }

  const handleClickOnLogo = () => routeBuilder.go(AppRoutes.extractData);

  const setActiveLinkTitle = (route: string) => {
    onClose();
    routeBuilder.go(route);
  };

  const toggleExpand = (value: boolean) => {
    dispatch(toggleLeftNavExpand(value));
  };

  const renderAccountMenu = () => {
    return <AccountMenu
      onClose={() => setAccountMenuOpenState(false)}
      anchorElement={footerRef?.current}
    />;
  }

  const handleExpand = () => toggleExpand(true);
  const handleCollapse = () => toggleExpand(false);

  return (
    <div className={clsx(
      styles.navBarContainer,
      isLeftNavOpen ? styles.navBarContainerExpanded : styles.navBarContainerCollapsed,
      isOpenedOnMobile && styles.isOpenedOnMobile,
    )}>
      <Header
        isExpanded={isLeftNavOpen}
        isOpenedOnMobile={isOpenedOnMobile}
        handleClickOnLogo={handleClickOnLogo}
      />
      <div className={styles.toggleButton} onClick={isLeftNavOpen ? handleCollapse : handleExpand}>
        <VectorRightImage className={isLeftNavOpen ? styles.toggleImgOpened : styles.toggleImg} />
        <div className={styles.imageTooltip}>
          <p className={styles.tooltipText}>{isLeftNavOpen ? 'Hide Menu' : 'Show Menu'}</p>
        </div>
      </div>
      {accountMenuOpenState && renderAccountMenu()}
      <NavigationLinks
        isExpanded={isLeftNavOpen}
        isOpenedOnMobile={isOpenedOnMobile}
        links={noLinks ? [] : productSectionList}
        activeLinkId={activeNavigationLinkId}
        activeNestedLinkId={activeNestedLinkId}
        setActiveLinkTitle={setActiveLinkTitle}
      />
      <UserCard
        firstName={currentUser.firstName || ''}
        lastName={currentUser.lastName || ''}
        color={currentUser.color}
        avatarUrl={getAvatarUrl(currentUser.avatarId)}
        companyName={organizationInfo.name}
        onClick={() => setAccountMenuOpenState(true)}
        isActive={isOpen}
        ref={footerRef}
        className={styles.userCard}
      />
    </div>
  );
};

export default LeftNavigationBar;
