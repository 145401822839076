import React, { ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import { FolderImage } from 'static/images';
import LinkButton from 'components/LinkButton';
import Footer from 'components/Footer';

import styles from './NoItems.module.scss';

export interface NoItemsProps {
  title: React.ReactNode;
  titleClassName?: string;
  subtitle?: string;
  subtitleClassName?: string;
  icon?: ReactNode;
  buttonMessage?: string;
  buttonClassName?: string;
  className?: string;
  onButtonClick?: () => void;
  buttonImage?: ReactElement;
  buttonTooltip?: React.ReactNode;
  buttonImageClassName?: string;
  buttonHoverableTooltip?: boolean;
  isButtonDisabled?: boolean;
  wrapButton?: (buttonContent: React.ReactNode) => React.ReactNode;
  footer?: boolean;
}

const NoItems = ({
  className,
  icon,
  title,
  titleClassName,
  buttonMessage,
  buttonClassName,
  subtitle,
  subtitleClassName,
  onButtonClick,
  buttonImage,
  buttonTooltip,
  buttonImageClassName,
  isButtonDisabled,
  buttonHoverableTooltip,
  wrapButton = (button) => button,
  footer,
}: NoItemsProps) => (
  <>
    <div className={clsx(styles.noItemsContainer, className)}>
      {icon || <FolderImage />}
      <div className={clsx(styles.title, titleClassName)}>{title}</div>
      {subtitle && <p className={clsx(styles.subtitle, subtitleClassName)}>{subtitle}</p>}
      {buttonMessage && wrapButton((
        <LinkButton
          disabled={isButtonDisabled}
          tooltip={buttonTooltip}
          className={buttonClassName}
          onClick={() => onButtonClick!()}
          hoverableTooltip={buttonHoverableTooltip}
        >
          {buttonImage && <div className={clsx(styles.buttonImage, buttonImageClassName)}>{buttonImage}</div>}
          {buttonMessage}
        </LinkButton>
      ))}
    </div>
    {footer && <Footer separator className={styles.footer} />}
  </>
);

export default NoItems;
