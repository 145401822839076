import React, { ReactNode } from 'react';
import PopUpHeader from './PopUpHeader';
import { CloseImage } from 'static/images';
import clsx from 'clsx';

import styles from './PopUpHeaderWithClose.module.scss';

interface PopUpHeaderWithCloseProps {
  title: ReactNode;
  titleTooltip?: React.ReactNode;
  titleIcons?: React.ReactNode;
  onClose?: () => void;
  closable?: boolean;
  titleClassName?: string;
}

const PopUpHeaderWithClose = ({
  title,
  onClose,
  closable = true,
  titleTooltip,
  titleIcons,
  titleClassName,
}: PopUpHeaderWithCloseProps) => {
  return (
    <PopUpHeader title={title} titleTooltip={titleTooltip} titleIcons={titleIcons} titleClassName={titleClassName}>
      {closable && (
        <button
          type="button"
          className={clsx(styles.closeBtn, !closable && styles.closeBtn__disabled)}
          onClick={onClose}
          disabled={!closable}
        >
          <CloseImage />
        </button>
      )}
    </PopUpHeader>
  );
};

export default PopUpHeaderWithClose;
