import { DataExtractionApi } from 'api/DataExtractionApi';
import createExtractionHistoryDashboardPagination, { DATA_EXTRACTIONS_RESULTS_PER_PAGE_DEFAULT } from './Pagination';
import { setDataExtractions } from 'DataExtractions/DataExtractionsStore';
import { AnyAction, MiddlewareAPI, Dispatch, Middleware } from 'redux';
import { ReduxState } from 'types/ReduxState';
import { isFulfilled } from '@reduxjs/toolkit';
import { createDataExtraction, deleteDataExtraction, getDataExtractions } from 'DataExtractions/Thunks';
import { get } from 'lodash';
import { extractionHistoryDashboardActionOrigin } from './ExtractionHistoryDashboardStore';

const createExtractionHistoryDashboardMiddlewares = (
  api: DataExtractionApi,
  pagination: ReturnType<typeof createExtractionHistoryDashboardPagination>,
  path: string | string[],
  actionOrigins: string[],
) => {
  const paginationMiddleware = pagination.getItemsFetcherMiddleware(async (
    params,
    dispatch,
  ) => {
    const {
      search,
      perPage,
      page,
      sortingType,
      ...restParams
    } = params;

    const { items, total } = await api.search({
      count: perPage,
      offset: (page - 1) * perPage,
      search,
      createdAtRange: restParams.createdAtRange,
      documentTypeId: restParams.documentTypeId || undefined,
      status: restParams.status || undefined,
    }, params.sortingType);

    dispatch(setDataExtractions(items));

    return {
      items: items.map((item) => item.id),
      total,
    };
  });

  const updatesMiddleware = (({ dispatch, getState }: MiddlewareAPI<Dispatch<AnyAction>, ReduxState>) => (
    next: (action: AnyAction) => any,
  ) => (action: AnyAction) => {
    const result = next(action);

    if (
      !isFulfilled(createDataExtraction)(action) &&
      !isFulfilled(deleteDataExtraction)(action)
    ) {
      return result;
    }

    if (!action.meta.actionOrigin || !actionOrigins.includes(action.meta.actionOrigin)) {
      return result;
    }

    const state = getState();
    const { filters, sortingType } = get(state, path);

    dispatch(getDataExtractions({
      filters: {
        search: filters.search,
        teamMembers: filters.teamMembers,
        offset: 0,
        count: DATA_EXTRACTIONS_RESULTS_PER_PAGE_DEFAULT,
        createdAtRange: filters.createdAtRange,
        documentTypeId: filters.documentTypeId,
        status: filters.status,
        fileType: filters.fileType,
      },
      sortingType,
      actionOrigin: extractionHistoryDashboardActionOrigin,
    }) as unknown as AnyAction)

    return result;
  }) as Middleware;

  return {
    paginationMiddleware,
    updatesMiddleware,
  }
};

export default createExtractionHistoryDashboardMiddlewares;
