import { FC, useState } from 'react'
import {
  ChangeFiltersPayload,
  ExtractionHistoryDashboardFiltersState,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/Filters/ExtractionHistoryDashboardFiltersStore';
import { DataExtractionFileType, DataExtractionStatus } from 'api/DataExtractionApi';
import FilterLayout from 'components/FilterLayout';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Button from 'components/Button/Button';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';

import styles from './DataExtractionFilters.module.scss';

const getTagColorByStatus = (status: DataExtractionStatus) => {
  switch (status) {
    case DataExtractionStatus.Failed:
      return styles.redTagColor;
    case DataExtractionStatus.Processing:
      return styles.blueTagColor;
    case DataExtractionStatus.Passed:
      return styles.greenTagColor;
    case DataExtractionStatus.Undetected:
      return styles.grayTagColor;
    default:
      return null as never;
  }
}

const dataExtractionResultOptions = Object.values(DataExtractionStatus).map((status) => ({
  name: status,
  value: status,
  color: getTagColorByStatus(status),
}))
interface DataExtractionFiltersProps {
  filters: ExtractionHistoryDashboardFiltersState;
  onFetchDocumentTypeOptions: (search: string) => Promise<Option[]>;
  onClear: () => void;
  onApply: (data: ChangeFiltersPayload) => void;
  onClose: () => void;
}

const DataExtractionFilters: FC<DataExtractionFiltersProps> = ({
  filters,
  onFetchDocumentTypeOptions,
  onClear,
  onClose,
  onApply,
}) => {
  const [fromDate, setFromDate] = useState(filters.createdAtRange.from);
  const [toDate, setToDate] = useState(filters.createdAtRange.to);
  const [selectedDocumentType, setSelectedDocumentType] = useState<string | null>(filters.documentTypeId);
  const [selectedFileType, setSelectedFileType] = useState<DataExtractionFileType | null>(filters.fileType);
  const [selectedStatus, setSelectedStatus] = useState<DataExtractionStatus | null>(filters.status);

  const handleOnDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromDate(from);
    setToDate(to);
  };

  const handleApplyClick = () => {
    onApply({
      createdAtRange: {
        from: fromDate,
        to: toDate,
      },
      fileType: selectedFileType,
      status: selectedStatus,
      documentTypeId: selectedDocumentType,
    });
  };

  const isApplyButtonDisabled = () => {
    return filters.createdAtRange.from === fromDate &&
      filters.createdAtRange.to === toDate &&
      filters.documentTypeId === selectedDocumentType &&
      filters.fileType === selectedFileType &&
      filters.status === selectedStatus;
  }

  return (
    <FilterLayout
      onClearFiltersClick={onClear}
      onClose={onClose}
      filterTitleClassName={styles.filterTitle}
    >
      <AutoCompletion
        labelTitle='Document Type'
        value={selectedDocumentType || ''}
        onChange={(option) => setSelectedDocumentType(option.value)}
        fetchOptions={onFetchDocumentTypeOptions}
        loading={Boolean(selectedDocumentType) && !selectedDocumentType}
      />
      <AutoCompletion
        labelTitle='File Type'
        value={selectedFileType || ''}
        onChange={(option) => setSelectedFileType(option.value as DataExtractionFileType)}
        options={Object.values(DataExtractionFileType).map((fileType) => ({ name: fileType.toUpperCase(), value: fileType }))}
      />
      <AutoCompletion
        labelTitle='Date Extraction Result'
        value={selectedStatus || ''}
        isLabelTag
        onChange={(option) => setSelectedStatus(option.value as DataExtractionStatus)}
        options={dataExtractionResultOptions}
      />
      <DateRangePicker
        label="Data Extraction Date"
        from={fromDate}
        to={toDate}
        datePickerFromClassName={styles.dateRangePicker}
        onChange={handleOnDateRangeChange}
      />
      <Button
        kind="primary"
        size="form"
        disabled={isApplyButtonDisabled()}
        onClick={handleApplyClick}>
        Apply Filter
      </Button>
    </FilterLayout>
  )
}

export default DataExtractionFilters;
