import { createReducer } from '@reduxjs/toolkit';
import mountChildReducers from 'utils/childReducers';
import endpointEventsTableReducer, {
  getInitialState as getEndpointEventsTableInitialState,
} from './EndpointEventsTable/EndpointEventsTableStore';
import { DashboardState } from 'DashboardSlice/createDashboardSlice';
import { BaseDashboardFiltersState } from 'DashboardSlice/createDashboardFiltersSlice';
import { WebhookEventSortingType } from 'api/WebhookEventsApi';

export interface WebhookEndpointDetailsState {
  endpointEventsTable: DashboardState<BaseDashboardFiltersState, WebhookEventSortingType>;
}

export const getInitialState = (): WebhookEndpointDetailsState => ({
  endpointEventsTable: getEndpointEventsTableInitialState(),
});

const webhookEndpointDetailsReducer = createReducer(getInitialState(), () => {});

const childReducers = {
  endpointEventsTable: endpointEventsTableReducer,
};

export default mountChildReducers(webhookEndpointDetailsReducer, childReducers);
