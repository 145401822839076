import React, { useState } from 'react';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'components/MainLayout';
import { UpdateOrganizationParams } from 'api/OrganizationApi';
import BillingDashboard from 'components/BillingDashboard';
import useBlockingRequest from 'hooks/useBlockingRequest';
import { updateOrganizationInfo } from 'Organization/OrganizationStore';
import useOrganizationInfo from 'hooks/useOrganizationInfo';
import EditCompanyInformationPopup from 'components/BillingDashboard/Overview/EditCompanyInformationPopup';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

const Billing = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [
    displayEditCompanyInformationPopup,
    setDisplayEditCompanyInformationPopup,
  ] = useState(false);
  const companyInfo = useOrganizationInfo();

  const openEditCompanyInformationPopup = () => setDisplayEditCompanyInformationPopup(true);
  const closeEditCompanyInformationPopup = () => setDisplayEditCompanyInformationPopup(false);

  const [isEditCompanyInformationInProgress, handleCompanyInformationEdit] = useBlockingRequest(
    async ({ legalAddress, name }: UpdateOrganizationParams) => {
      await dispatchWithUnwrap(updateOrganizationInfo({ legalAddress, name }));
      setDisplayEditCompanyInformationPopup(false);
    },
  );

  const renderOverlay = () => {
    if (displayEditCompanyInformationPopup && companyInfo) {
      return (
        <EditCompanyInformationPopup
          companyInfo={companyInfo}
          onSaveChanges={handleCompanyInformationEdit}
          onClose={closeEditCompanyInformationPopup}
          isEditInProgress={isEditCompanyInformationInProgress}
        />
      );
    }

    return null;
  };

  return (
    <MainLayout overlay={renderOverlay()}>
      <PageWrapperWithFooter>
        <PageContent>
          <BillingDashboard
            onCompanyInfoEdit={openEditCompanyInformationPopup}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Billing;
