import React from 'react';
import { TableRow, TableBodyCell } from 'components/Table';
import { SkeletonText, SkeletonRectangle } from 'components/Skeleton';
import CompanyInformationColumnSize from 'components/BillingDashboard/Overview/CompanyInformation/CompanyInformationColumnSize';
import styles from './CompanyInformationSkeleton.module.scss';

const TABLE_ROWS_COUNT = 2;

const CompanyInformationSkeleton = () => {
  return (
    <>
      <SkeletonText color="primary20" width="360px" height="18px" lineHeight="36px" marginBottom="16px" />
      {new Array(TABLE_ROWS_COUNT).fill(null).map((_, index) => (
        <TableRow key={index} disableHover className={styles.tableRow}>
          <TableBodyCell width={CompanyInformationColumnSize.Name}>
            <SkeletonRectangle width="120px" height="10px" color="primary6" />
          </TableBodyCell>
          <TableBodyCell width={CompanyInformationColumnSize.Value}>
            <SkeletonRectangle width="360px" height="10px" color="primary20" />
          </TableBodyCell>
        </TableRow>
      ))}
    </>
  );
};

export default CompanyInformationSkeleton;
