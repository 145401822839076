import React, { DetailedHTMLProps, ButtonHTMLAttributes, FC } from 'react';
import clsx from 'clsx';
import { CheckedImage } from 'static/images';
import { Link } from 'react-router-dom';

import styles from './ActionPopUpItem.module.scss';

type HTMLButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface ActionPopUpItemProps extends HTMLButtonProps {
  danger?: boolean;
  selected?: boolean;
  href?: string;
  onLinkClick?: () => void;
}

const ActionPopUpItem: FC<ActionPopUpItemProps> = ({
  onLinkClick,
  danger,
  className,
  disabled,
  children,
  selected,
  href,
  ...props
}) => {
  const buttonStyle = clsx(
    styles.popUpItem,
    danger && styles.dangerButton,
    disabled && styles.disabled,
    selected && styles.selected,
    className,
  );

  if (href && !disabled) {
    return (
      <Link
        to={href}
        type="button"
        className={buttonStyle}
        onClick={onLinkClick}
      >
        {children}
        {selected && <CheckedImage className={styles.checkIcon} />}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      type="button"
      className={buttonStyle}
      {...props}
    >
      {children}
      {selected && <CheckedImage className={styles.checkIcon} />}
    </button>
  );
};

export default ActionPopUpItem;
