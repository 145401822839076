export enum MyAccountPopupType {
  UploadAvatar = 'UploadAvatar',
  ChangeEmail = 'ChangeEmail',
  ChangePhone = 'ChangePhone',
  DeletePhone = 'DeletePhone',
  ChangePassword = 'ChangePassword',
}

export interface MyAccountState {
  avatar?: Blob | null;
  isOpen: boolean;
  popup: MyAccountPopupType | null;
}
