import { createSelector } from 'reselect';
import { ReduxState } from 'types/ReduxState';
import { NullableItems } from 'pagination';

export const getEndpointsById = (state: ReduxState) => {
  return state.webhooks.endpoints.endpointsById;
};

export const createGetEndpointsSelector = (ids: NullableItems<string>) => {
  return createSelector([getEndpointsById], (dataById) => {
    return ids.map((id) => id? dataById[id]: null);
  });
};
