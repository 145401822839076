import React, { FC, ReactElement, useRef } from 'react';
import QuestionIcon from 'components/QuestionIcon';
import { useResizeDetector } from 'react-resize-detector';
import clsx from 'clsx';
import { TooltipType } from 'components/QuestionIcon/QuestionIcon';
import styles from './ChartLayout.module.scss';

interface ChartLayoutProps {
  title?: string;
  secondaryTitle?: string;
  tooltip?: string | ReactElement;
  tooltipType?: TooltipType;
  className?: string;
  targetRef?: React.RefObject<HTMLDivElement>;
  onResize?: (width: number, height: number) => void;
}

const ChartLayout: FC<ChartLayoutProps> = ({ title,
  targetRef, secondaryTitle, tooltip, className, children, tooltipType, onResize }) => {
  const divRef = useRef<HTMLDivElement>(null);

  const paddings = {
    top: parseInt(styles.paddingTop, 10),
    bottom: parseInt(styles.paddingBottom, 10),
  };

  useResizeDetector({
    targetRef: targetRef || divRef,
    onResize: (width, height) => {
      if (width && height) {
        onResize?.(width, height - paddings.top - paddings.bottom);
      }
    },
    refreshRate: 1,
    refreshMode: 'debounce', // when it's set throttle there is an error "ResizeObserver loop completed with undelivered notifications", by switching between pages
  });

  return (
    <div className={clsx(styles.container, className)} ref={!targetRef ? divRef : undefined}>
      <div className={styles.titleLine}>
        {title && <p className={styles.title}>{title}</p>}
        {tooltip && (
          <QuestionIcon
            tooltipClassName={styles.hintTooltip}
            className={styles.hint}
            tooltip={tooltip}
            size={20}
            tooltipType={tooltipType}
          />
        )}
        {secondaryTitle && <p className={styles.secondaryTitle}>{secondaryTitle}</p>}
      </div>
      <div className={styles.chart}>{children}</div>
    </div>
  );
};

export default ChartLayout;
