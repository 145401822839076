import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import validateCreateAccountInviteForm, { LABELS } from './validateCreateAccountInviteForm';
import { AcceptInviteData } from 'api/UserInviteApi';
import AuthInput from '../Inputs/AuthInput';
import AuthPasswordInput from '../Inputs/AuthPasswordInput';
import AuthButton from '../Inputs/AuthButton';
import { ExternalRoutes } from '../../../routes/RouteBuilder';
import ExternalLink from '../../ExternalLink/ExternalLink';
import ExternalProvidersAuthSection from '../ExternalProvidersAuthSection';

import authLinkStyles from '../Inputs/AuthLink/AuthLink.module.scss';
import styles from './CreateAccountInviteForm.module.scss';

interface CreateAccountInviteFormProps {
  onSubmit: (data: AcceptInviteData) => Promise<void>;
  inviteToken: string;
}

const CreateAccountInviteForm: FC<CreateAccountInviteFormProps> = ({ onSubmit, inviteToken }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const lastNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [errors, setErrors] = useState<Partial<AcceptInviteData>>({});

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (setValue: (value: string) => void, key: keyof AcceptInviteData) => (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(e.target.value);

    setErrors({ ...errors, [key]: '' });
  };

  const handleBlur = (key: keyof AcceptInviteData, value: string) => () => {
    const validationResult = validateCreateAccountInviteForm({ [key]: value });
    setErrors({ ...errors, [key]: validationResult[key] });
  };

  const getFormData = () => ({ firstName, lastName, password });

  const handleSubmit = async () => {
    const data = getFormData();
    const newErrors = validateCreateAccountInviteForm(data);
    setErrors(errors);

    if (Object.keys(newErrors).length === 0) {
      await onSubmit(data);
    }
  };

  const handleSignInStarted = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const handleSignInFailed = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const formData = getFormData();
  const continueIsDisabled = Object.values(validateCreateAccountInviteForm(formData)).length > 0 || isLoading;

  return (
    <div>
      <ExternalProvidersAuthSection
        redirectOnSuccess
        inviteToken={inviteToken}
        onSignInStarted={handleSignInStarted}
        onSignInFailed={handleSignInFailed}
      />
      <div className={styles.fullNameContainer}>
        <AuthInput
          labelTitle="First Name"
          containerClassName={styles.nameInput}
          required
          autoFocus
          onChange={handleInputChange(setFirstName, 'firstName')}
          onBlur={handleBlur('firstName', firstName)}
          value={firstName}
          tabIndex={1}
          errorMessage={errors.firstName}
          onKeyDown={TriggerEventOnEnterKeyDown(lastNameRef, 'focus')}
        />
        <AuthInput
          labelTitle="Last Name"
          containerClassName={styles.nameInput}
          required
          onChange={handleInputChange(setLastName, 'lastName')}
          onBlur={handleBlur('lastName', lastName)}
          value={lastName}
          tabIndex={2}
          errorMessage={errors.lastName}
          onKeyDown={TriggerEventOnEnterKeyDown(passwordRef, 'focus')}
          inputRef={lastNameRef}
        />
      </div>
      <AuthPasswordInput
        labelTitle={LABELS.password}
        onChange={handleInputChange(setPassword, 'password')}
        value={password}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        ref={passwordRef}
        autoComplete="new-password"
        errorMessage={errors.password}
        onBlur={handleBlur('password', password)}
      />
      <div className={styles.footer}>
        By clicking Create Account you agree to our{' '}
        <ExternalLink className={authLinkStyles.link} href={ExternalRoutes.MasterAgreement}>Master Subscription Agreement</ExternalLink>{' '}
        and{' '}
        <ExternalLink className={authLinkStyles.link} href={ExternalRoutes.PrivacyPolicy}>Privacy Policy</ExternalLink>.
      </div>
      <AuthButton
        kind="primary"
        size="form"
        onClick={handleSubmit}
        fullWidth
        ref={submitButtonRef}
        className={styles.createAccount}
        disabled={continueIsDisabled}
        isLoading={isLoading}
      >
        Create Account
      </AuthButton>
    </div>
  );
};

export default CreateAccountInviteForm;
