import React, { FC } from 'react';
import clsx from 'clsx';
import LoginDetailsColumnSizes from './LoginDetailsColumnSizes';
import LinkButton from 'components/LinkButton';
import { formatPhoneNumber } from 'components/PhoneInput';
import Table, { TableBody, TableBodyCell, TableHead, TableRow } from 'components/Table';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import { User, UserAuthProvider } from 'api/UserApi';
import VerificationStatusTag from '../VerificationStatusTag';
import { Tag } from 'components/Tag';
import styles from './UserLoginDetails.module.scss';

interface UserLoginDetailsProps {
  user: User;
  className: string;
  onChangeEmail?: () => void;
  onChangePassword?: () => void;
  onChangePhone?: () => void;
  onDeletePhone?: () => void;
  isMfaVerified?: boolean;
}

const UserLoginDetails: FC<UserLoginDetailsProps> = ({
  user,
  className,
  onChangeEmail,
  onChangePassword,
  onChangePhone,
  onDeletePhone,
  isMfaVerified,
}) => {
  const isManagedByExternalProvider = user.authProvider !== UserAuthProvider.Synthetiq;

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.headerContainer}>
        <h2 className={styles.header}>Login Details</h2>
        {isManagedByExternalProvider && <Tag className={styles.tag} color='electric-blue'>Managed by {user.authProvider}</Tag>}
      </div>
      <Table>
        <TableHead />
        <TableBody>
          <TableRow disableHover>
            <TableBodyCell width={LoginDetailsColumnSizes.Name} className={styles.nameCell}>
              Email Address
            </TableBodyCell>
            <TableBodyCell width={LoginDetailsColumnSizes.Value} className={styles.valueCell}>
              <div className={styles.nameInValueCell}>Email Address</div>
              <div className={styles.topLine}>
                <p className={styles.value}>{user.email}</p>
                <VerificationStatusTag className={styles.status} verified={user.isEmailVerified || false} />
              </div>
              <LinkButton
                className={styles.action}
                onClick={onChangeEmail}
                disabled={user.authProvider !== UserAuthProvider.Synthetiq}
              >
                Change Email Address
              </LinkButton>
            </TableBodyCell>
          </TableRow>
          {!isManagedByExternalProvider && (
            <>
              <TableRow disableHover>
                <TableBodyCell width={LoginDetailsColumnSizes.Name} className={styles.nameCell}>
                  Phone Number
                </TableBodyCell>
                <TableBodyCell width={LoginDetailsColumnSizes.Value} className={styles.valueCell}>
                  <div className={styles.nameInValueCell}>Phone Number</div>
                  <div className={styles.topLine}>
                    {user.phone ? formatPhoneNumber(user.phone) : <TableBodyCellNoContent />}
                    {user.phone && <VerificationStatusTag className={styles.status} verified={isMfaVerified || false} />}
                  </div>
                  <LinkButton
                    className={styles.action}
                    onClick={user.isMfaEnabled ? onDeletePhone : onChangePhone}
                  >
                    {user.isMfaEnabled ? 'Disable Phone Authentication' : 'Enable Phone Authentication'}
                  </LinkButton>
                </TableBodyCell>
              </TableRow>
              <TableRow disableHover>
                <TableBodyCell width={LoginDetailsColumnSizes.Name} className={styles.nameCell}>
                  Password
                </TableBodyCell>
                <TableBodyCell width={LoginDetailsColumnSizes.Value} className={styles.valueCell}>
                  <div className={styles.nameInValueCell}>Password</div>
                  <div className={styles.topLine}>
                    <p className={styles.value}>•••••••••••••</p>
                  </div>
                  <LinkButton
                    className={styles.action}
                    onClick={onChangePassword}
                  >
                    Reset Password
                  </LinkButton>
                </TableBodyCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default UserLoginDetails;
