import ChartLayout from 'components/Charts/ChartLayout/ChartLayout';
import React, { FC, useState } from 'react';
import styles from './DocumentTypeChart.module.scss';
import { PerformanceOverviewDocumentType } from 'api/PerformanceOverviewApi';
import PieChartWithLegend from 'components/Charts/PieChartWithLegend/PieChartWithLegend';
import useFormattedDocumentTypes from 'components/HomeDashboard/DocumentTypeOverview/DocumentTypeChart/useFormattedDocumentTypes';
import { PieChartDataItem } from 'components/Charts/PieChart/PieChart';
import { round } from 'lodash';

interface DocumentTypeChartProps {
  documentTypes: PerformanceOverviewDocumentType[] | null;
  targetRef: React.RefObject<HTMLDivElement>;
}

const WIDTH_OF_ELEMENTS_WITHOUT_CHART = 60;
const HEIGHT_OF_ELEMENTS_WITHOUT_CHART = 82;

const DECIMAL_COUNT = 2;

const calculateChartSize = (width: number, height: number) => {
  return {
    width: width - WIDTH_OF_ELEMENTS_WITHOUT_CHART,
    height: height - HEIGHT_OF_ELEMENTS_WITHOUT_CHART,
  };
};

const DocumentTypeChart: FC<DocumentTypeChartProps> = ({
  targetRef,
  documentTypes,
}) => {
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });

  const handleOnResize = (width: number, height: number) => {
    if (width !== chartSize.width || height !== chartSize.height) {
      setChartSize(calculateChartSize(width, height));
    }
  };

  const formattedDocumentTypes = useFormattedDocumentTypes(documentTypes);

  const totalFiles = formattedDocumentTypes?.reduce((total, { value }) => total + value, 0);

  const formatTooltipTitle = (item: PieChartDataItem) => {
    return `${item.name} - ${round(item.value / totalFiles! * 100, DECIMAL_COUNT)}%`;
  };

  return (
    <ChartLayout
      onResize={handleOnResize}
      className={styles.chartLayout}
      targetRef={targetRef}
    >
      {formattedDocumentTypes && (
        <PieChartWithLegend
          data={formattedDocumentTypes}
          width={chartSize.width}
          height={chartSize.height}
          formatTooltipTitle={formatTooltipTitle}
          tooltipLabel="Files"
        />
      )}
    </ChartLayout>
  );
};

export default DocumentTypeChart;
