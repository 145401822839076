import React, { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import PopUpCore from './PopUpCore';
import PopUpHeaderWithClose from './PopUpHeaderWithClose';
import Spinner from 'components/Spinner';
import Portal from 'components/Portal';
import styles from './PopUpContent.module.scss';

export enum PopUpFooterType {
  Warning = 'warning',
  Info = 'info',
}

interface PopUpProps {
  children: ReactNode;
  closePopUp: () => void;
  title: string | React.ReactNode;
  classNames?: Partial<{
    popup: string;
    popupCore: string;
    sectionClassName?: string;
    title: string;
  }>;
  isLoading?: boolean;
  footerText?: string | React.ReactNode;
  footerType?: PopUpFooterType;
  titleIcons?: React.ReactNode;
  titleTooltip?: React.ReactNode;
  closable?: boolean;
  usePortal?: boolean;
}

const PopUp = forwardRef<HTMLElement, PopUpProps>(
  (
    {
      children,
      closePopUp,
      title,
      classNames = {},
      isLoading,
      footerText,
      footerType,
      titleTooltip,
      closable,
      usePortal,
      titleIcons,
    },
    ref,
  ) => {
    const renderPopup = () => (
      <div className={clsx(styles.popup, classNames.popup)}>
        <PopUpCore
          className={classNames.popupCore}
          sectionClassName={classNames.sectionClassName}
          ref={ref}
          footerText={footerText}
          footerType={footerType}
        >
          <PopUpHeaderWithClose
            titleIcons={titleIcons}
            titleTooltip={titleTooltip}
            title={title}
            onClose={closePopUp}
            closable={closable}
            titleClassName={classNames.title}
          />
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Spinner size={24} background="light" />
            </div>
          ) : (
            children
          )}
        </PopUpCore>
      </div>
    );

    if (usePortal) {
      return <Portal tagName="div">{renderPopup()}</Portal>;
    }

    return renderPopup();
  },
);

export default PopUp;
