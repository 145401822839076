import RestrictPermissionsPage from 'pages/RestrictPermissions';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { PermissionGroup } from '../enums/PermissionGroup';

interface RestrictedSectionProps {
  permissionGroups: PermissionGroup[];
  pageName: string;
}

const RestrictedByPermissionGroupsSection: FC<RestrictedSectionProps> = ({
  permissionGroups,
  children,
  pageName,
}) => {
  const { user } = useSelector((state: ReduxState) => state.sessionInfo);

  if (!user || !permissionGroups.includes(user.permissionGroup)) {
    return <>{children}</>;
  }

  return (
    <RestrictPermissionsPage
      pageName={pageName}
    />
  );
};

export default RestrictedByPermissionGroupsSection;
