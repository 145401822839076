import generalSettingsUiReducer, {
  getInitialState as getGeneralSettingsUIInitialState,
  GeneralSettingsUIState,
} from './ui';
import { createReducer } from '@reduxjs/toolkit';
import mountChildReducers from 'utils/childReducers';

export interface GeneralSettingsState {
  ui: GeneralSettingsUIState;
}

const getInitialState = () => ({
  ui: getGeneralSettingsUIInitialState(),
});

const childReducers = {
  ui: generalSettingsUiReducer,
};

const generalSettingsReducer = createReducer(getInitialState(), () => {});

export default mountChildReducers(generalSettingsReducer, childReducers);
