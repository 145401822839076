import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { ReactComponent as CopyImage } from 'static/images/copy.svg';
import copy from 'copy-to-clipboard';
import WrapperWithTooltip from 'components/Tooltip';
import { CheckedImage } from 'static/images';

import styles from './ClickToCopyButton.module.scss';

enum State {
  Default = 'Default',
  Copied = 'Copied',
}

interface ClickToCopyButtonProps {
  value: string;
  className?: string;
  entityName?: string;
  title?: string;
  copyIconClassName?: string;
  titleClassName?: string;
}

const STATE_RESET_TIME = 2000;

const ClickToCopyButton: FC<ClickToCopyButtonProps> = ({
  className,
  value,
  entityName,
  title,
  copyIconClassName,
  titleClassName,
}) => {
  const [state, setState] = useState(State.Default);

  const handleCopyClick = () => {
    if (state === State.Copied) {
      return;
    }

    const isCopied = copy(value!);

    if (isCopied) {
      setState(State.Copied);

      setTimeout(() => setState(State.Default), STATE_RESET_TIME);
    }
  };

  return (
    <WrapperWithTooltip tooltip={state === State.Copied ? 'Copied!' : `Click to copy${entityName ? ` ${entityName}` : ''}`}>
      <div className={clsx(styles.container, className)} onClick={handleCopyClick}>
          {state === State.Default && <CopyImage className={clsx(styles.copyIcon, copyIconClassName)} />}
          {state === State.Copied && <CheckedImage className={styles.checkedIcon} />}
          <p className={clsx(styles.value, titleClassName)}>{title || value}</p>
      </div>
    </WrapperWithTooltip>
  );
};

export default ClickToCopyButton;
