import React, { forwardRef, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { Option } from 'components/SelectInput/SelectInput';
import DropdownItem from 'components/SelectInput/DropdownItem';

import styles from 'components/SelectInput/SelectInput.module.scss';

interface DropdownContentProps {
  options: Option[];
  handleSelect: (option: Option) => void;
  autoscroll: boolean;
  selectedValue?: Option['value'] | Option['value'][];
  containerClassName?: string;
  isLabelTag?: boolean;
  onItemFocus: (id: string) => void;
  optionToFocusId: string | null;
}

const DropdownContent = forwardRef<HTMLDivElement, DropdownContentProps>(
  (
    {
      options,
      selectedValue,
      handleSelect,
      optionToFocusId,
      onItemFocus,
      containerClassName,
      autoscroll,
      isLabelTag,
    },
    ref,
  ) => {
    const optionsListClassName = clsx(styles.optionsList, containerClassName);
    const elementToFocusRef = useRef<HTMLDivElement>(null);
    const lastElementRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      if (autoscroll && elementToFocusRef.current && lastElementRef.current) {
        elementToFocusRef.current!.scrollIntoView({
          behavior: 'auto',
        });
      }
    }, [!!elementToFocusRef.current, !!lastElementRef.current, optionToFocusId]);

    const lastItemId = useMemo(() => {
      return options[options.length - 1]?.id;
    }, [options]);

    const getElementRef = (option: Option) => {
      if (autoscroll && option.id === optionToFocusId) {
        return elementToFocusRef;
      }

      if (option.id === lastItemId) {
        return lastElementRef;
      }

      return null;
    };

    const handleFocusItem = useCallback((id: string) => {
      onItemFocus(id);
    }, [options]);

    const renderItem = (option: Option) => {
      const isSelected = Array.isArray(selectedValue)
        ? selectedValue.includes(option.value)
        : selectedValue === option.value;

      return (
        <div ref={getElementRef(option)} key={option.id}>
          <DropdownItem
            key={option.id}
            option={option}
            onFocus={handleFocusItem}
            handleSelect={handleSelect}
            selected={isSelected}
            highlighted={optionToFocusId === option.id}
            isLabelTag={isLabelTag}
          />
        </div>
      );
    }

    return (
      <div className={optionsListClassName} ref={ref}>
        {options.map(renderItem)}
        {!options.length && <div className={styles.defaultOption}>No results found</div>}
      </div>
    );
  },
);

export default React.memo(DropdownContent);
