import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { createGetDocumentTypesSelector } from 'DocumentTypes/Selectors';
import useStateReset from 'hooks/useStateReset';
import { DocumentTypesDashboardActionType } from 'GeneralSettings/ui/DocumentTypesDashboard/DocumentTypesDashboardActionType';
import {
  DocumentTypesDashboardFiltersState,
} from 'GeneralSettings/ui/DocumentTypesDashboard/Filters/DocumentTypesDashboardFiltersStore';
import { getDocumentTypes } from 'DocumentTypes/Thunks';
import {
  changeMembers,
  changeSearchValue,
  documentTypesDashboardActionOrigin,
  pagination,
} from 'GeneralSettings/ui/DocumentTypesDashboard/DocumentTypesDashboardStore';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import DocumentTypesDashboard from './DocumentTypesDashboard';
import { User } from 'api/UserApi';
import useAbortSignal from 'hooks/useAbortSignal';
import { DocumentType } from 'api/DocumentTypeApi';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import { calculateDocumentTypesPerPage } from 'GeneralSettings/ui/DocumentTypesDashboard/Pagination';
import { useResizeDetector } from 'react-resize-detector';

const LOAD_DOCUMENT_TYPES_AFTER_SEARCH_DELAY = 300;

interface DocumentTypesDashboardConnectorProps {
  containerId: string;
  members: User[];
  onDeleteDocumentType: (documentType: DocumentType) => void;
  onCreateDocumentType: () => void;
  onEditDocumentType: (documentType: DocumentType) => void;
  onDuplicateDocumentType: (documentType: DocumentType) => void;
}

const DocumentTypesDashboardConnector: FC<DocumentTypesDashboardConnectorProps> = ({
  containerId,
  members,
  onCreateDocumentType,
  onDeleteDocumentType,
  onEditDocumentType,
  onDuplicateDocumentType,
}) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const routeBuilder = useRouteBuilder();
  const ref = useRef<HTMLDivElement>(null);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useResizeDetector({
    targetRef: ref,
    onResize: (width, height) => {
      if (windowSize.width !== window.innerWidth || windowSize.height !== window.innerHeight) {
        debouncedLoadDocumentTypes(filters, {
          width: Math.max(width || 0, window.innerWidth),
          height: Math.max(height || 0, window.innerHeight),
        });

        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      }
    },
  })

  const {
    filters,
    sortingType,
  } = useSelector((state: ReduxState) => state.generalSettings.ui.documentTypesDashboard);

  const paginationParams = {
    selectedMembers: filters.teamMembers,
    search: filters.search,
    sortingType,
  };

  const documentTypeIds = pagination.usePaginatedItems(paginationParams);

  const paginationProps = pagination.usePagination(paginationParams);

  const getDocumentTypesSelector = useMemo(
    () => createGetDocumentTypesSelector(documentTypeIds),
    [documentTypeIds],
  );

  const documentTypes = useSelector(getDocumentTypesSelector);

  useStateReset(DocumentTypesDashboardActionType.ResetState);

  const requestDocumentTypes = useAbortSignal((
    abortSignal: AbortSignal,
    filtersToApply: DocumentTypesDashboardFiltersState,
    containerWidth?: { width: number, height: number },
  ) => {
    dispatchWithUnwrap(getDocumentTypes({
      filters: {
        search: filtersToApply.search,
        selectedMembers: filtersToApply.teamMembers,
        count: calculateDocumentTypesPerPage(containerWidth),
      },
      sortingType,
      actionOrigin: documentTypesDashboardActionOrigin,
    }))
  })

  const debouncedLoadDocumentTypes = useMemo(() =>
    debounce(requestDocumentTypes, LOAD_DOCUMENT_TYPES_AFTER_SEARCH_DELAY), []);

  useEffect(() => {
    debouncedLoadDocumentTypes(filters);
  }, [
    filters.search,
    filters.teamMembers,
  ]);

  const handleSearch = (search: string) => {
    dispatch(changeSearchValue(search));

    debouncedLoadDocumentTypes({
      ...filters,
      search,
      count: calculateDocumentTypesPerPage(),
    })
  };

  const handleChangeSelectedMembers = (membersToSet: User[]) => {
    dispatch(changeMembers(membersToSet.map((member) => member.id)));
  };

  const handleOpenDocumentTypeDetails = (documentTypeId: string) => {
    const documentTypeDetailsLink = routeBuilder
      .withParams({ edit: documentTypeId })
      .build(AppRoutes.documentTypes);

    routeBuilder.go(documentTypeDetailsLink);
  }

  const hasMore = paginationProps.itemsTotal !== undefined
    ? paginationProps.itemsTotal !== documentTypes.length
    : false;
  const noItems = !filters.search && paginationProps.itemsTotal === 0;

  return (
    <div ref={ref}>
      <DocumentTypesDashboard
        documentTypes={documentTypes}
        filters={filters}
        members={members}
        containerId={containerId}
        hasMore={hasMore}
        noItems={noItems}
        paginationProps={paginationProps}
        onSearch={handleSearch}
        onChangeSelectedUsers={handleChangeSelectedMembers}
        onCreateDocumentType={onCreateDocumentType}
        onLoadMore={paginationProps.nextPage}
        onDeleteDocumentType={onDeleteDocumentType}
        onEditDocumentType={onEditDocumentType}
        onDuplicateDocumentType={onDuplicateDocumentType}
        onDocumentTypeSelect={handleOpenDocumentTypeDetails}
      />
    </div>
  )
}

export default DocumentTypesDashboardConnector;
