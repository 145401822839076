import React, { useEffect, useState } from 'react';
import { useAuthProvider } from 'providers/AuthProvider';
import AuthLayout from 'components/Auth/AuthLayout';
import Loading from 'pages/Loading';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import useRouteBuilder from '../../hooks/useRouteBuilder';
import notification from 'handlers/notification/notificationActionCreator';
import { AppRoutes } from '../../routes/RouteBuilder';
import { AcceptInviteData } from '../../api/UserInviteApi';
import InvitationLinkExpired from 'pages/InvitationLinkExpired';
import CreateAccountInviteForm from '../../components/Auth/CreateAccountInviteForm';
import TrialBlock from '../../components/Auth/TrialBlock';
import AuthFormFooter from '../../components/Auth/AuthFormFooter';

interface IRouteParams {
  token?: string;
}

const CreateAccountInvite = () => {
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const routeBuilder = useRouteBuilder();
  const { token } = useParams<IRouteParams>();
  const [isLinkVerifyInProgress, setIsLinkVerifyInProgress] = useState(true);
  const [hasLinkExpiredOrInvalid, setLinkHasExpiredOrInvalid] = useState(false);

  useEffect(() => {
    if (token) {
      authProvider.validateInviteToken(token).then(async (isValid) => {
        setIsLinkVerifyInProgress(false);
        setLinkHasExpiredOrInvalid(!isValid);
      });
    }
  }, [token]);

  if (!token) {
    return null;
  }

  const onSuccess = (notificationText: string) => {
    notification.createNotification(notificationText, 'success', dispatch);
    authProvider.emitLogout();
  };

  const handleSubmit = async (data: AcceptInviteData) => {
    await authProvider.acceptInvite(token, data);

    onSuccess('Your account has been created. Please, sign in.');
  };

  const handleSignInClick = () => {
    routeBuilder.go(AppRoutes.auth.signIn);
  };

  if (isLinkVerifyInProgress) {
    return <Loading />;
  }

  if (hasLinkExpiredOrInvalid) {
    return <InvitationLinkExpired onSignInClick={handleSignInClick} />;
  }

  const renderFormFooter = () => {
    return (
      <AuthFormFooter
        linkText="Sign In"
        linkTo={routeBuilder.build(AppRoutes.auth.signIn)}
        message="Already have an account?"
      />
    );
  };

  return (
    <AuthLayout
      title="Create Your Account"
      secondaryBlock={<TrialBlock type="acceptInvite" />}
      subTitle={'You’re invited to the team on Synthetiq!'}
      formFooter={renderFormFooter()}
    >
      <CreateAccountInviteForm onSubmit={handleSubmit} inviteToken={token} />
    </AuthLayout>
  );
};

export default CreateAccountInvite;
