import React, { FC } from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import styles from './EditUserFormSkeleton.module.scss';

const EditUserFormSkeleton: FC<{}> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.skeletonInput}>
        <SkeletonRectangle width="160px" height="16px" color="primary6" />
        <SkeletonRectangle width="600px" height="52px" color="primary6" marginTop="8px" />
      </div>
      <div className={styles.skeletonInput}>
        <SkeletonRectangle width="160px" height="16px" color="primary6" />
        <SkeletonRectangle width="600px" height="52px" color="primary6" marginTop="8px" />
      </div>
      <div className={styles.skeletonInput}>
        <SkeletonRectangle width="160px" height="16px" color="primary6" />
        <SkeletonRectangle width="600px" height="52px" color="primary6" marginTop="8px" />
      </div>
      <div className={styles.skeletonInput}>
        <SkeletonRectangle width="160px" height="16px" color="primary6" />
        <SkeletonRectangle width="600px" height="52px" color="primary6" marginTop="8px" />
      </div>
    </div>
  );
};

export default EditUserFormSkeleton;
