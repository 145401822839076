import React, { FC, useState } from 'react';
import clsx from 'clsx';
import ButtonWithImage from 'components/ButtonWithImage';
import Button from 'components/Button';
import { Collapse } from '@material-ui/core';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import Header from 'components/Base/Header';
import { VectorBottomImage } from 'static/images';
import styles from './DetailsForm.module.scss';

export interface DetailsFormField {
  label: React.ReactNode;
  value: React.ReactNode;
  key?: string | number;
  valueClassName?: string;
  alignStart?: boolean;
}

export interface DetailsFormProps {
  className?: string;
  isEditMode?: boolean;
  fields: Array<DetailsFormField> | null;
  hiddenFields?: Array<DetailsFormField> | null;
  toggleHiddenFieldsButtonTitle?: string;
  noFieldsMessage?: string;
  labelWidth?: string;
  columns?: number;
  onSwitchEditMode?: () => void;
  title?: string;
  noFieldsContent?: React.ReactNode;
  editButtonMessage?: string;
  hideEditButton?: boolean;
  isEditInformationButtonDisabled?: boolean;
  detailsFormHeaderClassName?: string;
  isSaveChangesInProgress?: boolean;
  isSaveChangesButtonDisabled?: boolean;
  onSaveChanges?: () => void;
  titleType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const DEFAULT_COLUMNS = 2;
const DEFAULT_LABEL_WIDTH = '50%';

const DetailsForm: FC<DetailsFormProps> = ({
  className,
  fields,
  hiddenFields,
  columns = DEFAULT_COLUMNS,
  labelWidth = DEFAULT_LABEL_WIDTH,
  isEditMode,
  title,
  onSwitchEditMode,
  isSaveChangesInProgress,
  onSaveChanges,
  hideEditButton,
  toggleHiddenFieldsButtonTitle,
  noFieldsMessage,
  isEditInformationButtonDisabled,
  editButtonMessage = 'Edit Information',
  isSaveChangesButtonDisabled,
  noFieldsContent = null,
  detailsFormHeaderClassName,
  titleType,
  children,
}) => {
  const [showHiddenFields, setShowHiddenFields] = useState(false);

  const isFirstFieldInColumn = (index: number) => {
    return index < columns;
  };

  const renderField = (field: DetailsFormField, index: number, hideTopBorder?: boolean) => (
    <div
      key={field.key ?? `${field.label}-${index}`}
      className={clsx(
        styles.field,
        field.alignStart && styles.alignedStartField,
        !hideTopBorder && isFirstFieldInColumn(index) && styles.withTopBorderField,
      )}
    >
      <div style={{ width: labelWidth }} className={styles.fieldLabel}>
        {field.label}
      </div>
      <div style={{ width: `calc(100% - ${labelWidth})` }} className={clsx(styles.fieldValue, field.valueClassName)}>
        {field.value || <TableBodyCellNoContent />}
      </div>
    </div>
  );

  const renderToggleHiddenFieldsButtonField = () => (
    <div className={styles.toggleHiddenFieldsButtonField} onClick={() => setShowHiddenFields(!showHiddenFields)}>
      <div className={clsx(styles.toggleHiddenFieldButton, showHiddenFields && styles.toggledHiddenFieldButton)}>
        <p>{showHiddenFields ? `Hide ${toggleHiddenFieldsButtonTitle}` : `Show ${toggleHiddenFieldsButtonTitle}`}</p>
        <VectorBottomImage />
      </div>
    </div>
  );

  const renderContent = () => {
    if (isEditMode) {
      return children;
    }

    if (!fields) {
      return noFieldsContent;
    }

    if (!fields.length) {
      return (
        <div className={styles.noFieldsContent}>
          {noFieldsMessage}
        </div>
      );
    }

    return (
      <>
        {fields.map((field, index) => renderField(field, index))}
        {hiddenFields && <Collapse in={showHiddenFields}>
          {hiddenFields.map((field, index) => renderField(field, index, true))}
        </Collapse>}
        {hiddenFields && renderToggleHiddenFieldsButtonField()}
      </>
    );
  }

  const detailsFormContentStyle = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  };

  return (
    <div className={clsx(styles.detailsForm, className)}>
      {title && <div
        className={clsx(styles.detailsFormHeader, detailsFormHeaderClassName, isEditMode && styles.editModeDetailsFormHeader)}
      >
        <Header type={titleType || 'h4'}>{title}</Header>
        {!isEditMode && !hideEditButton && <ButtonWithImage
          disabled={!fields || isEditInformationButtonDisabled}
          className={styles.editInformationButton}
          kind="edit"
          onClick={onSwitchEditMode}
          title={editButtonMessage}
        />}
      </div>}
      {isEditMode && <div className={styles.editFormActions}>
        <Button
          onClick={() => onSwitchEditMode?.()}
          className={styles.closeEditorButton}
          kind="secondary"
        >
          Close Editor
        </Button>
        <Button
          className={styles.saveChangesButton}
          isLoading={isSaveChangesInProgress}
          disabled={isSaveChangesButtonDisabled}
          onClick={onSaveChanges}
          kind="primary"
        >
          Save Changes
        </Button>
      </div>}
      <div style={detailsFormContentStyle} className={styles.detailsFormContent}>
        {renderContent()}
      </div>
    </div>
  );
};

export default React.memo(DetailsForm) as typeof DetailsForm;
