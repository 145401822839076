import './initializeReactPdf';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRoutes from './routes';
import { authHandler, store, history } from './stores';
import ApiProvider, { IApiProviderValue } from './providers/ApiServiceProvider';
import ErrorBoundary from './components/ErrorBoundary';
import AppInitializer from './AppInitializer';
import AuthProvider from './providers/AuthProvider';
import { LayoutMountPoint } from './layout';
import { Router } from 'react-router';
import { subscribeToHistoryChange } from './RedirectionsMiddleware';
import {
  dataExtractionsApi,
  dataExtractionResultsApi,
  documentParsingsApi,
  pollingApi,
  documentTypeApi,
  documentTokenApi,
} from 'stores/api';
import AuthorizedOnly from 'components/AuthorizedOnly';
import GlobalOverlays from './pages/Main';
import CaptchaProvider from './CaptchaProvider';
import HeadScripts from './HeadScripts';
import * as Sentry from '@sentry/react';
import { env } from 'env';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.scss';

const apiProviderValue: IApiProviderValue = {
  documentParsingsApi,
  dataExtractionsApi,
  dataExtractionResultsApi,
  pollingApi,
  documentTypeApi,
  documentTokenApi,
};

if (env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,
    environment: env.REACT_APP_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <>
    <HeadScripts />
    <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
      <Router history={history}>
        <ErrorBoundary>
          <Provider store={store}>
            <CaptchaProvider>
              <AuthProvider value={authHandler}>
                <ApiProvider value={apiProviderValue}>
                  <LayoutMountPoint>
                    <AppInitializer>
                      <AppRoutes />
                    </AppInitializer>
                  </LayoutMountPoint>
                  <AuthorizedOnly>
                    <GlobalOverlays />
                  </AuthorizedOnly>
                </ApiProvider>
              </AuthProvider>
            </CaptchaProvider>
          </Provider>
        </ErrorBoundary>
      </Router>
    </GoogleOAuthProvider>
  </>,
  document.getElementById('root'),
  /**
   * It is important to subscribe to history changes after ReactRouter does,
   * otherwise synchronous calls to push()/replace() from the history.listen callback are silently swallowed
   * without triggering a rerender which leads to the UI state not being consistent with the address bar.
   */
  () => store.dispatch(subscribeToHistoryChange()),
);
