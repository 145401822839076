import React, { FC } from 'react';
import InvoiceStatus from 'BillingPage/InvoiceStatus';
import { Tag } from 'components/Tag';
import { TagColor } from 'components/Tag/Tag';
import { Invoice } from 'api/BillingApi';

interface InvoiceStatusTagProps {
  invoice: Invoice;
}

const InvoiceStatusTag: FC<InvoiceStatusTagProps> = ({ invoice }) => {
  const getTagColor = (): TagColor => {
    switch (invoice.status.toLowerCase()) {
      case InvoiceStatus.Paid:
        return 'green';
      case InvoiceStatus.Open:
        return 'blue';
      case InvoiceStatus.Uncollectible:
        return 'red';
      case InvoiceStatus.Void:
        return 'gray';
      default:
        return 'gray';
    }
  };

  return <Tag color={getTagColor()}>{invoice.status}</Tag>;
};

export default InvoiceStatusTag;
