import React, { FC } from 'react';
import { MainLogo } from '../../../static/images';

import styles from './LeftNavigationHeader.module.scss';
import clsx from 'clsx';

interface LeftNavigationHeaderProps {
  isExpanded: boolean;
  isOpenedOnMobile: boolean;
  handleClickOnLogo: (event: React.MouseEvent<SVGSVGElement>) => void;
}

const LeftNavigationHeader: FC<LeftNavigationHeaderProps> = ({
  isExpanded,
  isOpenedOnMobile,
  handleClickOnLogo,
}) => {
  const classes = clsx(
    styles.headerContainer,
    isExpanded ? styles.mainLogoContainerExpanded : styles.mainLogoContainerCollapsed,
    isOpenedOnMobile && styles.mobileOpened,
  );

  return (
    <div className={classes}>
      <div className={styles.innerContainer}>
        <MainLogo className={styles.mainLogo} onClick={handleClickOnLogo} />
      </div>
    </div>
  );
};

export default LeftNavigationHeader;
