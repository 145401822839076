import React, { FC } from 'react';
import ExternalLink from '../../ExternalLink/ExternalLink';
import clsx from 'clsx';
import { ExternalRoutes } from '../../../routes/RouteBuilder';

import styles from './AuthFooter.module.scss';

interface AuthFooterProps {
  className?: string;
}

const AuthFooter: FC<AuthFooterProps> = ({ className }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.copyright}>© 2023 DigiFi, Inc.</div>
      <div className={styles.links}>
        <ExternalLink className={styles.link} href={ExternalRoutes.MasterAgreement}>Master Subscription Agreement</ExternalLink>
        <ExternalLink className={styles.link} href={ExternalRoutes.PrivacyPolicy}>Privacy Policy</ExternalLink>
      </div>
    </div>
  );
};

export default AuthFooter;
