import React, { FC } from 'react';
import styles from './FlexHorizontalSpacer.module.scss';

const FlexHorizontalSpacer: FC = () => {
  return (
    <div className={styles.container} />
  );
};

export default FlexHorizontalSpacer;
