import React, { FC, PropsWithChildren } from 'react';
import ShareButton from '../ShareButton';
import { ButtonProps } from '../Button/Button';

const ExternalSourceButton: FC<PropsWithChildren<ButtonProps & { externalLink: string }>> = ({
  children,
  externalLink,
  ...props
}) => {
  const openExternalLink = () => window.open(`${externalLink}`)

  return <ShareButton onClick={openExternalLink} {...props}>{children}</ShareButton>;
};

export default ExternalSourceButton;
