import { FC, useState } from 'react'
import ContextualPopupDropdownWrapper from 'components/ContextualPopupDropdownWrapper';
import TimeRange from 'enums/TimeRange';
import clsx from 'clsx';
import { DropdownImage } from 'static/images';

import styles from './TimeRangeInput.module.scss';

const timeRangeLabels = {
  [TimeRange.Last7Days]: 'Last 7 days',
  [TimeRange.ThisMonth]: 'This Month',
  [TimeRange.AllTime]: 'All Time',
}

interface TimeRangeInputProps {
  isLoading: boolean;
  className?: string;
  selectedValue?: TimeRange;
  onChange: (value: TimeRange) => void;
}

const TimeRangeInput: FC<TimeRangeInputProps> = ({
  isLoading,
  className,
  onChange,
  selectedValue,
}) => {
  const [value, setValue] = useState<TimeRange>(
    selectedValue || TimeRange.Last7Days,
  );

  const handleSelectChange = (valueToSelect: TimeRange) => {
    setValue(valueToSelect);

    onChange(valueToSelect);
  };

  return (
    <ContextualPopupDropdownWrapper<HTMLDivElement, { id: TimeRange, label: string }>
      items={Object.values(TimeRange).map((timeRange) => ({ id: timeRange, label: timeRangeLabels[timeRange] }))}
      onSelectItem={(item) => handleSelectChange(item.id)}
      closeOnSelect
      selectedItemId={value}
    >
      {(ref, showDropdown) => (
        <div
          ref={ref}
          onClick={() => !isLoading && showDropdown(true)}
          className={clsx(styles.timeRangeDropdown, className, isLoading && styles.disabled)}
        >
          {timeRangeLabels[value]} <DropdownImage className={styles.dropdownIcon} />
        </div>
      )}
    </ContextualPopupDropdownWrapper>
  );
}

export default TimeRangeInput;
