import React, { FC } from 'react';
import { User } from 'api/UserApi';
import EmailCodeConfirmationStep from 'components/WithIdentityConfirmationActionPopup/Steps/EmailCodeConfirmationStep';

export interface WithSSOIdentityConfirmActionPopupProps {
  currentUser: User
  actionName: string;
  onSubmit: () => Promise<void>;
}

const WithExternalProviderConfirmActionPopupContent: FC<WithSSOIdentityConfirmActionPopupProps> = ({
  actionName,
  onSubmit,
  currentUser,
}) => {
  return (
    <EmailCodeConfirmationStep
      currentUser={currentUser}
      buttonText={actionName}
      onSubmit={onSubmit}
    />
  );
};

export default WithExternalProviderConfirmActionPopupContent;
