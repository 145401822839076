import WrapperWithTooltip from '../Tooltip';
import Button from '../Button';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { ButtonProps } from '../Button/Button';

const ButtonWithTooltip: FC<PropsWithChildren<ButtonProps & { tooltip?: ReactNode | null }>> = ({
  tooltip,
  children,
  ...props
}) => {
  return (
    <WrapperWithTooltip tooltip={tooltip}>
      <Button
        {...props}
      >
        {children}
      </Button>
    </WrapperWithTooltip>
  );
};

export default ButtonWithTooltip;
