import MainLayout, { PageContent, PageWrapper } from 'components/MainLayout';
import { useParams } from 'react-router';
import WebhookTab from './WebhookTab';
import { TabSwitchOption } from 'components/TabSwitch';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import WebhooksOverview from 'components/WebhooksOverview/WebhooksOverview';
import { ReduxState } from 'types/ReduxState';
import { useSelector } from 'react-redux';
import { EventsFilters } from 'components/WebhooksOverview/Filters';
import useBlockingRequest from 'hooks/useBlockingRequest';
import { CreateWebhookEndpointParams, UpdateWebhookEndpointParams } from 'api/WebhookEndpointsApi';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useEffect, useState } from 'react';
import {
  createWebhookEndpoint,
  deleteWebhookEndpoint,
  generateNewWebhookEndpointSecret,
  getWebhookEndpoint,
  updateWebhookEndpoint,
} from 'Webhooks/Endpoints/Thunks';
import { CreateEndpointPopup, EventDetailsPopup } from 'components/WebhooksOverview/Popups';
import { endpointsDashboardActionOrigin } from 'GeneralSettings/ui/WebhooksDashboards/EndpointsDashboard/EndpointsDashboardStore';
import useConfirmChanges from 'hooks/useConfirmChanges';
import ConfirmPopup from 'components/ConfirmPopup/ConfirmPopup';
import { deleteWebhookEvent, retryWebhookEvent } from 'Webhooks/Events/Thunks';
import {
  eventsDashboardActionOrigin,
} from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/EventsDashboardStore';
import { WebhookEvent } from 'api/WebhookEventsApi';
import { useCloseContextualView } from 'components/MainLayout/utils';
import EndpointDetailsContextualView from 'components/WebhooksOverview/ContextualViews/EndpointDetailsContextualView/EndpointDetailsContextualView';
import { endpointEventsTableActionOrigin } from 'GeneralSettings/ui/WebhooksDashboards/EndpointDetails/EndpointEventsTable/EndpointEventsTableStore';

const WebhookEndpoints = () => {
  const routeBuilder = useRouteBuilder();
  const { tab } = useParams<{ tab: WebhookTab }>();
  const { filters } = useSelector((state: ReduxState) => state.generalSettings.ui.webhooksDashboards.eventsDashboard);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const closeContextualView = useCloseContextualView();

  const webhookEndpointId = routeBuilder.currentQueryParams.get('edit');

  const webhookEndpoint = useSelector((state: ReduxState) => {
    return webhookEndpointId ? state.webhooks.endpoints.endpointsById[webhookEndpointId] : null
  });

  useEffect(() => {
    if (webhookEndpointId) {
      dispatchWithUnwrap(getWebhookEndpoint(webhookEndpointId));
    }
  }, [webhookEndpointId]);

  const [displayCreateWebhookEndpointPopup, setDisplayCreateWebhookEndpointPopup] = useState(false);
  const [webhookEventToShowDetails, setWebhookEventToShowDetails] = useState<WebhookEvent | null>(null);

  const [isCreateNewWebhookEndpointInProgress, useBlockingWebhookEndpointCreationCallback] = useBlockingRequest();
  const [isDeleteWebhookInProgress, useBlockingDeleteWebhookCallback] = useBlockingRequest();
  const [isWebhookStatusChangeInProgress, useBlockingWebhookStatusChangeCallback] = useBlockingRequest();
  const [isWebhookEventDeleteInProgress, useBlockingWebhookEventDeleteCallback] = useBlockingRequest();
  const [isResendEventInProgress, useBlockingResendCallback] = useBlockingRequest();
  const [isNewEndpointGeneratingInProgress, useBlockingNewWebhookEndpointGenerateCallback] = useBlockingRequest();
  const [isWebhookEndpointUpdateInProgress, useBlockingWebhookEndpointUpdateCallback] = useBlockingRequest();

  const [
    displayConfirmDeleteWebhook,
    resetConfirmDeleteWebhook,
    onConfirmDeleteWebhook,
    useConfirmDeleteWebhookCallback,
  ] = useConfirmChanges();

  const [
    displayConfirmWebhookStatusChange,
    resetConfirmWebhookStatusChange,
    onConfirmWebhookStatusChange,
    useConfirmWebhookStatusChangeCallback,
    isNextStatusActive,
  ] = useConfirmChanges<boolean>();

  const [
    displayConfirmDeleteWebhookEvent,
    resetConfirmDeleteWebhookEvent,
    onConfirmDeleteWebhookEvent,
    useConfirmDeleteWebhookEventCallback,
  ] = useConfirmChanges();

  const [
    displayConfirmGenerateNewWebhookEndpointSecret,
    resetConfirmGenerateNewWebhookEndpointSecret,
    onConfirmGenerateNewWebhookEndpointSecret,
    useConfirmGenerateWebhookEndpointSecretCallback,
  ] = useConfirmChanges();

  const handleCreateWebhookEndpointPopup = useBlockingWebhookEndpointCreationCallback(
    async (createWebhookEndpointParams: CreateWebhookEndpointParams) => {
      await dispatchWithUnwrap(createWebhookEndpoint({
        ...createWebhookEndpointParams,
        actionOrigin: endpointsDashboardActionOrigin,
      }));
      setDisplayCreateWebhookEndpointPopup(false);
    },
  );

  const handleDeleteWebhook = useConfirmDeleteWebhookCallback(useBlockingDeleteWebhookCallback(async (webhookId: string) => {
    await dispatchWithUnwrap(deleteWebhookEndpoint({
      id: webhookId,
      actionOrigin: endpointsDashboardActionOrigin,
    }));

    routeBuilder.go('?');

    resetConfirmDeleteWebhook();
  }));

  const handleChangeWebhookStatus = useConfirmWebhookStatusChangeCallback(
    useBlockingWebhookStatusChangeCallback(async (webhookId: string, active: boolean) => {
      await dispatchWithUnwrap(updateWebhookEndpoint({
        id: webhookId,
        active,
        actionOrigin: endpointsDashboardActionOrigin,
      }));

      resetConfirmWebhookStatusChange();
    }),
    (webhookId, active: boolean) => !active,
  );

  const handleDeleteWebhookEvent = useConfirmDeleteWebhookEventCallback(
    useBlockingWebhookEventDeleteCallback(async (webhookEventId: string) => {
      await dispatchWithUnwrap(deleteWebhookEvent({
        webhookEventId,
        actionOrigin: tab === WebhookTab.Events
          ? eventsDashboardActionOrigin
          : endpointEventsTableActionOrigin,
      }));

      resetConfirmDeleteWebhookEvent();
      setWebhookEventToShowDetails(null);
    }),
  );

  const handleResendWebhookEvent = useBlockingResendCallback(async (webhookEventId: string) => {
    await dispatchWithUnwrap(retryWebhookEvent(webhookEventId));

    if (webhookEventToShowDetails) {
      setWebhookEventToShowDetails(null);
    }
  });

  const handleGenerateNewWebhookEndpointSecret = useConfirmGenerateWebhookEndpointSecretCallback(
    useBlockingNewWebhookEndpointGenerateCallback(async (webhookId: string) => {
      await dispatchWithUnwrap(generateNewWebhookEndpointSecret(webhookId));

      resetConfirmGenerateNewWebhookEndpointSecret();
    }),
  );

  const handleUpdateWebhookEndpoint = useBlockingWebhookEndpointUpdateCallback(
    async (webhookEndpointIdToUpdate: string, updateParams: Partial<UpdateWebhookEndpointParams>) => {
      await dispatchWithUnwrap(updateWebhookEndpoint({
        id: webhookEndpointIdToUpdate,
        ...updateParams,
      }));
    },
  );

  const handleTabChange = ({ id }: TabSwitchOption) => {
    routeBuilder.go(AppRoutes.webhooks, id);
  };

  const renderRightSidePopupView = () => {
    if (filters.showFiltersPanel) {
      return (
        <EventsFilters />
      );
    }

    return null;
  };

  const renderSpecificOverlay = () => {
    if (displayCreateWebhookEndpointPopup) {
      return (
        <CreateEndpointPopup
          onSubmit={handleCreateWebhookEndpointPopup}
          onClose={() => setDisplayCreateWebhookEndpointPopup(false)}
          isCreating={isCreateNewWebhookEndpointInProgress}
        />
      );
    }

    if (displayConfirmDeleteWebhook) {
      return (
        <ConfirmPopup
          title="Delete Endpoint"
          message="Are you sure you want to delete this endpoint?"
          confirmText="Yes, Delete Endpoint"
          declineText="No, Go Back"
          onPopupClose={resetConfirmDeleteWebhook}
          onConfirmClick={() => onConfirmDeleteWebhook()}
          loading={isDeleteWebhookInProgress}
        />
      );
    }

    if (displayConfirmWebhookStatusChange) {
      const nextStatus = isNextStatusActive ? 'inactive' : 'active';

      return (
        <ConfirmPopup
          title="Change Endpoint Status"
          message={`Are you sure you want to change endpoint status to ${nextStatus}?`}
          confirmText="Yes, Change Status"
          declineText="No, Go Back"
          onPopupClose={resetConfirmWebhookStatusChange}
          onConfirmClick={onConfirmWebhookStatusChange}
          loading={isWebhookStatusChangeInProgress}
        />
      );
    }

    if (webhookEventToShowDetails) {
      return (
        <EventDetailsPopup
          webhookEvent={webhookEventToShowDetails}
          onResendEvent={handleResendWebhookEvent}
          onDeleteEvent={handleDeleteWebhookEvent}
          onClose={() => setWebhookEventToShowDetails(null)}
          isResending={isResendEventInProgress}
        />
      );
    }

    if (displayConfirmGenerateNewWebhookEndpointSecret) {
      return (
        <ConfirmPopup
          title="Generate New Key"
          message="Are you sure you want to generate a new key? This will replace your current key, which will become immediately invalid."
          confirmText="Yes, Generate Key"
          declineText="No, Go Back"
          onPopupClose={resetConfirmGenerateNewWebhookEndpointSecret}
          onConfirmClick={onConfirmGenerateNewWebhookEndpointSecret}
          loading={isNewEndpointGeneratingInProgress}
        />
      );
    }

    return null;
  }

  const renderOverlay = () => {
    const specificOverlay = renderSpecificOverlay();

    if (!specificOverlay && !displayConfirmDeleteWebhookEvent) {
      return null;
    }

    return (
      <>
        {specificOverlay}
        {displayConfirmDeleteWebhookEvent && (
          <ConfirmPopup
            title="Delete Webhook Event"
            message="Are you sure you want to delete this webhook event?"
            confirmText="Yes, Delete Event"
            declineText="No, Go Back"
            onPopupClose={resetConfirmDeleteWebhookEvent}
            onConfirmClick={onConfirmDeleteWebhookEvent}
            loading={isWebhookEventDeleteInProgress}
          />
        )}
      </>
    );
  }

  const renderContextualView = () => {
    if (!webhookEndpointId) {
      return null;
    }

    return (
      <EndpointDetailsContextualView
        webhookEndpointId={webhookEndpointId}
        webhookEndpoint={webhookEndpoint}
        onDeleteWebhook={handleDeleteWebhook}
        onDeleteWebhookEvent={handleDeleteWebhookEvent}
        onChangeWebhookStatus={handleChangeWebhookStatus}
        onWebhookEventClick={(webhookEvent) => setWebhookEventToShowDetails(webhookEvent)}
        onGenerateNewWebhookEndpointSecret={handleGenerateNewWebhookEndpointSecret}
        onResendWebhookEvent={handleResendWebhookEvent}
        isWebhookEndpointUpdateInProgress={isWebhookEndpointUpdateInProgress}
        onUpdateWebhookEndpoint={handleUpdateWebhookEndpoint}
        onClose={closeContextualView}
      />
    );
  };

  return (
    <MainLayout
      rightSidePopupView={renderRightSidePopupView()}
      overlay={renderOverlay()}
      closeContextualView={closeContextualView}
      contextualView={renderContextualView()}
    >
      <PageWrapper>
        <PageContent>
          <WebhooksOverview
            tab={tab || WebhookTab.Endpoints}
            onTabChange={handleTabChange}
            onEndpointCreate={() => setDisplayCreateWebhookEndpointPopup(true)}
            onEndpointDelete={handleDeleteWebhook}
            onEndpointStatusChange={handleChangeWebhookStatus}
            onEventDelete={handleDeleteWebhookEvent}
            onEventResend={handleResendWebhookEvent}
            onEventDisplayDetails={(webhookEvent) => setWebhookEventToShowDetails(webhookEvent)}
          />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default WebhookEndpoints;
