import React, { FC } from 'react';
import clsx from 'clsx';
import OverflowedText from 'components/OverflowedText';
import styles from './TableStub.module.scss';

export interface TableStubColumn {
  title?: string;
  width?: number;
}

export interface TableStubProps {
  className?: string;
  columns: TableStubColumn[];
  description: string;
  descriptionIndex?: number;
  rows?: number;
}

const DEFAULT_ROWS_COUNT = 14;
const DESCRIPTION_INDEX = 2;
const DEFAULT_COLUMN_WIDTH = 100;

const TableStub: FC<TableStubProps> = ({
  className,
  columns,
  description,
  rows = DEFAULT_ROWS_COUNT,
  descriptionIndex = DESCRIPTION_INDEX,
}) => {
  const renderHeaderColumn = (column: TableStubColumn, index: number) => {
    return (
      <div
        style={{ width: `${column.width ?? DEFAULT_COLUMN_WIDTH}%` }}
        className={styles.tableStubColumn}
        key={`${column.title}-${index}`}
      >
        <OverflowedText>
          {column.title}
        </OverflowedText>
      </div>
    );
  };

  const renderRow = (index: number) => {
    return (
      <div key={index} className={styles.tableStubRow}>
        {index === descriptionIndex && description}
      </div>
    );
  };

  const showHeader = columns.every(({ title }) => title);

  return (
    <div className={clsx(styles.tableStub, className)}>
      {showHeader && (
        <div className={styles.tableStubColumns}>
          {columns.map(renderHeaderColumn)}
        </div>
      )}
      <div className={styles.tableStubRows}>
        {new Array(rows).fill(null).map((key, index) => renderRow(index))}
      </div>
    </div>
  );
};

export default TableStub;
