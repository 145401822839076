import { DataExtractionSortingType, DataExtractionStatus } from 'api/DataExtractionApi';
import { DateRange } from 'api/Types';
import Pagination from 'pagination';
import { ReduxState } from 'types/ReduxState';

export const DATA_EXTRACTIONS_RESULTS_PER_PAGE_DEFAULT = 15;

export interface ExtractionHistoryDashboardPaginationParams {
  search: string;
  selectedMembers: string[];
  sortingType: DataExtractionSortingType;
  status: DataExtractionStatus | null;
  documentTypeId: string | null;
  fileType: string | null;
  createdAtRange?: DateRange;
}

const createExtractionHistoryDashboardPagination = <
  Params extends ExtractionHistoryDashboardPaginationParams
>(namespace: string[] | string) => {
  return Pagination<ReduxState, string, Params>(
    namespace,
    DATA_EXTRACTIONS_RESULTS_PER_PAGE_DEFAULT,
    ({ search }) => (search ? ['searched', search] : []),
  );
};

export default createExtractionHistoryDashboardPagination;
