import { createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import EndpointsActionType from './EndpointsActionType';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import { WebhookEndpoint } from 'api/WebhookEndpointsApi';
import {
  createWebhookEndpoint,
  generateNewWebhookEndpointSecret,
  getWebhookEndpoint,
  getWebhookEndpoints,
  updateWebhookEndpoint,
} from './Thunks';

export interface EndpointsState {
  endpointsById: Record<string, WebhookEndpoint>
}

export const getInitialState = (): EndpointsState => ({
  endpointsById: {},
});

export const setWebhookEndpoints = createAction<WebhookEndpoint[]>(EndpointsActionType.SetWebhookEndpoints);

const endpointsReducer = createReducer<EndpointsState>(getInitialState(), (builder) => {
  builder.addCase(setWebhookEndpoints, (state, action) => {
    state.endpointsById = {
      ...state.endpointsById,
      ...normalizeEntityArray(action.payload),
    };
  });

  builder.addCase(getWebhookEndpoints.fulfilled, (state, action) => {
    state.endpointsById = {
      ...state.endpointsById,
      ...normalizeEntityArray(action.payload.items),
    };
  });

  builder.addCase(generateNewWebhookEndpointSecret.fulfilled, (state, action) => {
    state.endpointsById[action.payload.id].endpointSecret = action.payload.endpointSecret;
  });

  builder.addMatcher(
    isAnyOf(getWebhookEndpoint.fulfilled, updateWebhookEndpoint.fulfilled, createWebhookEndpoint.fulfilled),
    (state, action) => {
      state.endpointsById = {
        ...state.endpointsById,
        [action.payload.id]: action.payload,
      };
    });
});

export default endpointsReducer;
