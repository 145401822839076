import { LEFT_NAV_TOGGLE_EXPAND } from './ActionTypes';
import { AnyAction } from 'redux';

interface ToggleLeftNavExpandAction extends AnyAction {
  payload: boolean;
}

export const toggleLeftNavExpand = (payload: boolean): ToggleLeftNavExpandAction => ({
  type: LEFT_NAV_TOGGLE_EXPAND,
  payload,
});
