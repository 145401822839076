import React, { FC, useState } from 'react';
import styles from './PieChart.module.scss';
import { Pie } from '@visx/shape';
import clsx from 'clsx';
import { Group } from '@visx/group';
import { darken } from '@material-ui/core';
import ChartTooltip from 'components/Charts/components/ChartTooltip/ChartTooltip';
import { ProvidedProps } from '@visx/shape/lib/shapes/Pie';

export interface PieChartDataItem {
  name: string;
  value: number;
}

export interface PieChartProps {
  disabled?: boolean;
  width: number;
  height: number;
  data: PieChartDataItem[];
  formatTooltipTitle: (item: PieChartDataItem) => string;
  tooltipLabel: string;
}

const OUTER_RADIUS_SIZE = 1;
const INNER_RADIUS_SIZE = 0.61;
const PAD_ANGLE = 0.02; // Affects space between the pie slices

export const PIE_CHART_COLORS = [
  '#53C530',
  '#45C3FF',
  '#EC4D3D',
  '#FFC312',
];

interface Coordinates {
  x: number;
  y: number;
}

const PieChart: FC<PieChartProps> = ({
  disabled,
  height,
  width,
  data,
  formatTooltipTitle,
  tooltipLabel,
}) => {
  const [activePieElement, setActivePieElement] = useState<PieChartDataItem | null>(null);
  const [activePieElementCenter, setActivePieElementCenter] = useState<Coordinates | null>(null);

  const outerRadius = (Math.min(width, height) / 2) * OUTER_RADIUS_SIZE;
  const innerRadius = (Math.min(width, height) / 2) * INNER_RADIUS_SIZE;

  const handlePieElementMouseEnter = (item: PieChartDataItem, x: number, y: number) => {
    setActivePieElement(item);
    setActivePieElementCenter({ x, y });
  };

  const handlePieElementMouseLeave = (item: PieChartDataItem) => {
    setActivePieElement((previousItem) => previousItem?.name === item.name ? null : previousItem);
  };

  const activePieElementColorIndex = activePieElement && data.map(({ name }) => name).indexOf(activePieElement.name);

  const renderPieElement = (pie: ProvidedProps<PieChartDataItem>) => {
    return pie.arcs.map((arc, index) => {
      const { name } = arc.data;

      const arcPath = pie.path(arc);

      const arcFill = activePieElement?.name === name
        ? darken(PIE_CHART_COLORS[index % PIE_CHART_COLORS.length], 0.02)
        : PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]

      return (
        <g key={`arc-${name}-${index}`}>
          <path
            className={styles.pieChartElement}
            d={arcPath || undefined}
            fill={arcFill}
            onMouseEnter={(event) => handlePieElementMouseEnter(arc.data, event.pageX, event.pageY)}
            onMouseLeave={() => handlePieElementMouseLeave(arc.data)}
          />
        </g>
      );
    });
  };

  return (
    <svg
      className={clsx(styles.container, disabled && styles.disabled)}
      width={width}
      height={height}
    >
      <Group top={height / 2} left={width / 2}>
        <Pie
          data={data}
          pieValue={(item) => item.value}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          padAngle={data.length > 1 ? PAD_ANGLE : 0}
          children={renderPieElement}
        />
      </Group>
      {activePieElement && !disabled && (
        <ChartTooltip
          className={styles.tooltip}
          x={activePieElementCenter?.x || 0}
          y={activePieElementCenter?.y || 0}
          xData={formatTooltipTitle(activePieElement)}
          yData={activePieElement.value}
          label={tooltipLabel}
          color={PIE_CHART_COLORS[activePieElementColorIndex!]}
        />
      )}
    </svg>
  );
};

export default PieChart;
