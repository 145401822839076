import { DataExtractionStatus } from 'api/DataExtractionApi';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { TagColor } from 'components/Tag/Tag';
import { ReactNode } from 'react';

export const getDataExtractionTagColor = (dataExtractionStatus: DataExtractionStatus): TagColor => {
  switch (dataExtractionStatus) {
    case DataExtractionStatus.Failed:
      return 'red';
    case DataExtractionStatus.Processing:
      return 'blue';
    case DataExtractionStatus.Passed:
      return 'green';
    case DataExtractionStatus.Undetected:
      return 'gray';
    default:
      return 'red';
  }
}

export const getDataExtractionStatusTooltip = (dataExtractionStatus: DataExtractionStatus): ReactNode => {
  switch (dataExtractionStatus) {
    case DataExtractionStatus.Failed:
      return (
        <>
          Data extraction was not successful <br/>
          due to internal reasons.<br/>
          Please contact <ExternalLink href="mailto:support@synthetiq.co">support@synthetiq.co</ExternalLink>
          .
        </>
      );
    case DataExtractionStatus.Processing:
      return (
        <>
          Data extraction process is currently in progress.
        </>
      );
    case DataExtractionStatus.Passed:
      return (
        <>
          Data extraction was successful.
        </>
      );
    case DataExtractionStatus.Undetected:
      return (
        <>
          Document type could not be detected.
        </>
      );
    default:
      return null;
  }
};
