import React, { FC } from 'react'
import { TableBodyCell, TableRow } from 'components/Table';
import DataExtractionStatusTag from 'components/DataExtractionStatusTag';
import { DataExtraction, DataExtractionStatus } from 'api/DataExtractionApi';
import { User } from 'api/UserApi';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import { MobileTableColumnSize } from '../../TableColumnSize';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import OverflowedText from 'components/OverflowedText';
import DocumentTypeLabel from 'components/DocumentTypeLabel';

import styles from './DataExtractionMobileRow.module.scss';

interface DataExtractionMobileRowProps {
  className?: string;
  dataExtraction: DataExtraction;
  user?: User;
  onViewDataExtraction: (dataExtraction: DataExtraction) => void;
}

const DataExtractionMobileRow: FC<DataExtractionMobileRowProps> = ({
  className,
  dataExtraction,
  user,
  onViewDataExtraction,
}) => {
  return (
    <TableRow
      onClick={() => onViewDataExtraction(dataExtraction)}
      className={className}
    >
      <TableBodyCell width={MobileTableColumnSize.Date} noPadding className={styles.userAvatarCell}>
        <div className={styles.userAvatarContainer}>
          <WithSystemApiUserAvatar
            size="small"
            className={styles.userAvatar}
            user={user}
          />
          <OverflowedText className={styles.overflowedText}>
            {dataExtraction.fileNames?.length === 1 ? dataExtraction.fileNames[0] : 'Multiple Files'}
          </OverflowedText>
        </div>
        <DocumentTypeLabel
          isProcessing={!!dataExtraction.statusSummary?.[DataExtractionStatus.Processing]}
          isAutoDetected={dataExtraction.isAutoDetected}
          documentType={dataExtraction.documentTypeNames?.[0]}
        />
      </TableBodyCell>
      <TableBodyCell width={MobileTableColumnSize.Result} className={styles.resultCell}>
        {dataExtraction.statusSummary && Object.entries(dataExtraction.statusSummary).map(([status, value]) => {
          return <DataExtractionStatusTag status={status as DataExtractionStatus} count={value} />;
        })}
        <p className={styles.date}>{formatDate(dataExtraction.createdAt, DateTimeFormat.ShortForMobile)}</p>
      </TableBodyCell>
    </TableRow>
  )
}

export default DataExtractionMobileRow;
