import { DataExtractionStatus } from 'api/DataExtractionApi';
import { Tag } from 'components/Tag';
import WrapperWithTooltip from 'components/Tooltip';
import { FC } from 'react';
import { getDataExtractionStatusTooltip, getDataExtractionTagColor } from 'utils/tagUtils';
import styles from './DataExtractionStatusTag.module.scss';
import WrapperWithHoverableTooltip from 'components/WrapperWithHoverableTooltip';
import clsx from 'clsx';

interface DataExtractionStatusTagProps {
  className?: string;
  status: DataExtractionStatus;
  count?: number;
}

const DataExtractionStatusTag: FC<DataExtractionStatusTagProps> = ({
  className,
  status,
  count,
}) => {
  const TooltipWrapper = status === DataExtractionStatus.Failed
    ? WrapperWithHoverableTooltip
    : WrapperWithTooltip;

  return (
    <TooltipWrapper tooltip={getDataExtractionStatusTooltip(status)} className={styles.tooltip} placement="top">
      <Tag color={getDataExtractionTagColor(status)} className={clsx(styles.tag, className)}>
        {status} {count && count !== 1 ? `(${count})` : ''}
      </Tag>
    </TooltipWrapper>
  );
};

export default DataExtractionStatusTag;
