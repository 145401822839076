import { PublicApiWhitelist } from 'api/PublicApiWhitelistApi';
import { CurrentUser, UserAuthProvider } from 'api/UserApi';
import IpAddressTable from 'components/IpAddressWhitelist/IpAddressTable';
import QuestionIcon from 'components/QuestionIcon';
import useLocalSortingWithSaving from 'hooks/useLocalSortingWithSaving';
import { MAX_API_WHITELISTS_PER_ORGANIZATION } from 'PublicApi/constants';
import React, { FC } from 'react';
import { PermissionGroup } from 'enums/PermissionGroup';
import ButtonWithTooltip from 'components/ButtonWithTooltip';

import styles from './IpAddressWhitelist.module.scss';

interface IpAddressWhitelistProps {
  currentUser: CurrentUser | null;
  whitelists?: PublicApiWhitelist[];
  onAddIpAddress: () => void;
  onDeleteIpAddress: (id: string) => void;
}

const SORTING_STORAGE_KEY = 'ip-address-whitelist-sorting';

const IpAddressWhitelist: FC<IpAddressWhitelistProps> = ({
  whitelists,
  currentUser,
  onAddIpAddress,
  onDeleteIpAddress,
}) => {
  const sortedWhitelists = useLocalSortingWithSaving(
    whitelists,
    'createdAt',
    SORTING_STORAGE_KEY,
  );

  const isExternalAuthProvider = currentUser?.authProvider !== UserAuthProvider.Synthetiq;

  const isDisabled = !currentUser ||
    currentUser.permissionGroup !== PermissionGroup.Owner ||
    (!isExternalAuthProvider && !currentUser.isMfaEnabled);

  const maxApiWhitelistLimitReached = !!whitelists && whitelists.length >= MAX_API_WHITELISTS_PER_ORGANIZATION;

  const getMfaDisabledTooltip = () => {
    return (
      <div className={styles.tooltip}>
        Enable 2-factor phone authentication in your <br />
        user profile before adding IP address
      </div>
    );
  };

  const getDisableTooltip = () => {
    if (currentUser && currentUser.permissionGroup !== PermissionGroup.Owner) {
      return (
        <div className={styles.tooltip}>
          Only users with the permission level of “Owner” <br />
          can edit API credentials or whitelisted IP addresses
        </div>
      );
    }

    if (currentUser && isExternalAuthProvider) {
      return null;
    }

    if (currentUser && !currentUser.isMfaEnabled) {
      return getMfaDisabledTooltip();
    }

    return null;
  };

  const isMfaEnabled = () => {
    return currentUser?.isMfaEnabled;
  };

  const getDisableTooltipForApiWhitelist = () => {
    if (!isExternalAuthProvider && !isMfaEnabled()) {
      return getMfaDisabledTooltip();
    }

    if (maxApiWhitelistLimitReached) {
      return (
        <div className={styles.tooltip}>
          You can't add more than {MAX_API_WHITELISTS_PER_ORGANIZATION} IP Addresses
        </div>
      );
    }

    return null;
  }

  const handleOnAdd = () => {
    if (isExternalAuthProvider || !maxApiWhitelistLimitReached && isMfaEnabled()) {
      onAddIpAddress();
    }
  };

  const ipAddressTooltip = (
    <div className={styles.tooltip}>
      IP address whitelist enhances API security  <br />
      by restricting access to specific  <br />
      IP addresses. This is highly recommended.
    </div>
  );

  const isButtonDisabled = maxApiWhitelistLimitReached || (!isExternalAuthProvider && !isMfaEnabled());

  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>
        IP Address Whitelist
        <QuestionIcon className={styles.questionIcon} tooltip={ipAddressTooltip} size={20} />
        <ButtonWithTooltip
          tooltip={getDisableTooltipForApiWhitelist()}
          disabled={isButtonDisabled}
          kind="primary"
          size="default"
          onClick={handleOnAdd}
          alwaysClickable
        >
          Add IP Address
        </ButtonWithTooltip>
      </div>
      <IpAddressTable
        isDisabled={isDisabled}
        disableTooltip={getDisableTooltip()}
        ipAddresses={sortedWhitelists.items}
        onDelete={onDeleteIpAddress}
        getSortingType={sortedWhitelists.getSortingType}
        onSort={sortedWhitelists.changeSorting}
      />
    </div>
  );
};

export default IpAddressWhitelist;
