import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { LeftArrowImage, RightArrowImage } from 'static/images';
import { PaginationProps } from 'pagination';
import TextInput from 'components/TextInput';
import { debounce } from 'lodash';
import clsx from 'clsx';
import maskNumberValue from 'utils/masks/maskNumberValue';

import styles from './Pagination.module.scss';

const PAGE_NUMBER_INPUT_CHANGE_DEBOUNCE_INTERVAL = 400;

const isValidPageNumberInput = (pageNumberInput: string, pagesCount: number) => {
  const numberValue = Number(pageNumberInput);

  return !Number.isNaN(numberValue) && numberValue > 0 && numberValue <= pagesCount;
};

const Pagination: FC<PaginationProps> = ({
  page,
  pagesCount,
  nextPage,
  prevPage,
  itemsPerPage,
  itemsTotal,
  setPage,
  containerClassName,
}) => {
  const [pageInputValue, setPageInputValue] = useState(page.toString());

  const setPageUsingInput = (inputValue: string, pagesLimit: number) => {
    if (!isValidPageNumberInput(inputValue, pagesLimit)) {
      return;
    }

    const numberValue = Number(inputValue);

    if (numberValue === page) {
      return;
    }

    setPage(numberValue);
  };

  const setPageUsingInputDebounced = useMemo(
    () => debounce(setPageUsingInput, PAGE_NUMBER_INPUT_CHANGE_DEBOUNCE_INTERVAL),
    [setPage, page],
  );

  useEffect(() => {
    setPageUsingInputDebounced(pageInputValue, pagesCount!);
  }, [pageInputValue]);

  useEffect(() => {
    setPageInputValue(page.toString());
  }, [page]);

  const handlePageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value && !isValidPageNumberInput(value, pagesCount!)) {
      return;
    }

    setPageInputValue(value);
  };

  if (!itemsTotal) {
    return null;
  }

  const firstElementNumberOnPage = (page - 1) * itemsPerPage + 1;
  const lastElementNumberOnPage = Math.min(page * itemsPerPage, itemsTotal);

  return (
    <div className={clsx(styles.paginationContainer, containerClassName)}>
      <div className={styles.paginationContainer__pages}>
        <div className={styles.pagesDescriptionContainer}>
          <span>Page</span>
          <div className={styles.inputContainer}>
            <TextInput value={pageInputValue} onChange={handlePageInputChange} containerClassName={styles.pageValue} />
          </div>
          <span>of {maskNumberValue(pagesCount || 0)} </span>
        </div>
        <div className={styles.paginationContainer__buttons}>
          <button
            type="button"
            onClick={prevPage}
            className={page === 1 || page === 0 ? styles.disabledButton : styles.button}
          >
            <LeftArrowImage />
          </button>
          <button
            type="button"
            onClick={nextPage}
            className={page === pagesCount ? styles.disabledButton : styles.button}
          >
            <RightArrowImage />
          </button>
        </div>
      </div>
      <div>
        <p>{`${firstElementNumberOnPage}-${lastElementNumberOnPage} out of ${maskNumberValue(itemsTotal)}`}</p>
      </div>
    </div>
  );
};

export default Pagination;
