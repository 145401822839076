import React, { FC } from 'react';
import Footer from 'components/Footer';
import MainLayout from 'components/MainLayout';
import PageFooter from 'components/MainLayout/PageFooter';
import PageContent from 'components/MainLayout/PageContent';
import PageWrapper from 'components/MainLayout/PageWrapper';
import RestrictPermissions from 'components/RestrictPermissions';

interface RestrictPermissionsPageProps {
  pageName: string;
}

const RestrictPermissionsPage: FC<RestrictPermissionsPageProps> = ({ pageName }) => {
  return (
    <MainLayout>
      <PageWrapper>
        <PageContent noPadding>
          <RestrictPermissions pageName={pageName} />
        </PageContent>
        <PageFooter>
          <Footer separator />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
};

export default RestrictPermissionsPage;
