import DetailsHeader from 'components/DetailsHeader';
import { DataExtractionStatus } from 'api/DataExtractionApi';
import { FC, useRef } from 'react';
import { User } from 'api/UserApi';
import { RowAction } from 'components/RowActions/RowActions';
import DataExtractionResultInfoPanel from './DataExtractionResultInfoPanel/DataExtractionResultInfoPanel';
import DataExtractionResultDocumentPreview from './DataExtractionResultDocumentPreview/DataExtractionResultDocumentPreview';
import DataExtractionResultOutputSection from './DataExtractionResultOutputSection/DataExtractionResultOutputSection';
import ButtonWithDropdown from '../ButtonWithDropdown';
import DataExtractionStatusTag from 'components/DataExtractionStatusTag';
import clsx from 'clsx';
import { DataExtractionResult } from '../../api/DataExtractionResultApi';
import { ExportFileType } from '../../api/DataExtractionExportApi';

import styles from './DataExtractionResultOverview.module.scss';

interface DataExtractionResultOverviewProps {
  dataExtractionResult?: DataExtractionResult;
  members: User[];
  onExportDataExtractionResult: (resultId: string, exportFileType: ExportFileType) => void;
}

const DataExtractionResultOverview: FC<DataExtractionResultOverviewProps> = ({
  dataExtractionResult,
  members,
  onExportDataExtractionResult,
}) => {
  const resultContainerRef = useRef<HTMLDivElement>(null);
  const user = dataExtractionResult?.createdBy
    ? members.find((member) => member.id === dataExtractionResult.createdBy)
    : null;

  const actionInfo = dataExtractionResult ? {
    performedBy: user,
    actionDateAt: dataExtractionResult.createdAt,
    actionName: 'Processed',
    shortActionName: 'Proc.',
  } : null;

  const isProcessingInProgress = dataExtractionResult?.status === DataExtractionStatus.Processing;

  const actions: RowAction[] | null = dataExtractionResult ? [
    {
      title: 'Export Results to CSV',
      handler: () => onExportDataExtractionResult(
        dataExtractionResult.id,
        ExportFileType.Csv,
      ),
      disabled: isProcessingInProgress,
      itemClassName: styles.exportResultsItem,
    },
    {
      title: 'Export Results to JSON',
      handler: () => onExportDataExtractionResult(
        dataExtractionResult.id,
        ExportFileType.Json,
      ),
      disabled: isProcessingInProgress,
      itemClassName: styles.exportResultsItem,
    },
  ] : null;

  const handleExportDataExtractionResult = (exportFileType: ExportFileType) => {
    onExportDataExtractionResult(dataExtractionResult!.id, exportFileType);
  };

  const renderExportDataButton = () => {
    return <ButtonWithDropdown
      actions={[{ type: ExportFileType.Csv, title: 'CSV' }, { type: ExportFileType.Json, title: 'JSON' }]}
      onActionClick={handleExportDataExtractionResult}
      title="Export Results"
      buttonClassName={styles.exportResultsButton}
      disabled={isProcessingInProgress}
    />;
  };

  const subtitleTags = dataExtractionResult
    ? (<DataExtractionStatusTag status={dataExtractionResult.status} />)
    : null;

  return (
    <div className={styles.container}>
      <DetailsHeader
        title='Processing Results'
        titleSectionClassName={styles.titleSectionClassName}
        titleClassName={styles.detailsHeaderTitle}
        actionsButtonClassName={styles.detailsHeaderActionsButton}
        actionsContainerClassName={styles.detailsHeaderActionsContainer}
        referenceId={dataExtractionResult?.id}
        actionInfo={actionInfo}
        actions={actions}
        subtitleTags={subtitleTags}
        tagsContainerClassName={styles.tagsContainer}
        renderAfterRowActions={renderExportDataButton}
      />
      <DataExtractionResultInfoPanel
        dataExtractionResult={dataExtractionResult}
      />
      <div className={styles.resultContainer} ref={resultContainerRef}>
        {dataExtractionResult && (
          <DataExtractionResultDocumentPreview
            dataExtractionResult={dataExtractionResult}
            className={clsx(styles.column, styles.preview)}
            resizeContainerRef={resultContainerRef}
          />
        )}
        <DataExtractionResultOutputSection
          className={styles.column}
          dataExtractionResult={dataExtractionResult?.data}
          dataExtractionStatus={dataExtractionResult?.status}
          documentType={dataExtractionResult?.documentType}
          dataExtractionResultId={dataExtractionResult?.id}
        />
      </div>
    </div>
  )
}

export default DataExtractionResultOverview;
