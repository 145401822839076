import { createReducer } from '@reduxjs/toolkit';
import { UsersDashboardFiltersActionType } from './UsersDashboardFiltersActionType';
import reduceReducers from 'reduce-reducers';
import createDashboardFiltersSlice, {
  BaseDashboardFiltersState,
  DashboardFiltersActionType,
  getDashboardFiltersInitialState,
} from 'DashboardSlice/createDashboardFiltersSlice';
import { UserStatus } from 'api/UserApi';
import { PermissionGroup } from 'enums/PermissionGroup';

export interface ChangeFiltersPayload {
  statuses?: UserStatus[];
  permissionGroup?: PermissionGroup | null;
  isMfaEnabled?: boolean | null;
}

export interface UsersDashboardFiltersState extends BaseDashboardFiltersState {
  statuses: UserStatus[];
  permissionGroup: PermissionGroup | null;
  isMfaEnabled?: boolean | null;
}

const getInitialState = (): UsersDashboardFiltersState => ({
  ...getDashboardFiltersInitialState(),
  statuses: [UserStatus.Active, UserStatus.Pending],
  permissionGroup: null,
  isMfaEnabled: null,
});

const actionTypes = {
  [DashboardFiltersActionType.ChangeSearchValue]: UsersDashboardFiltersActionType.ChangeSearchValue,
  [DashboardFiltersActionType.ChangeFilters]: UsersDashboardFiltersActionType.ChangeFilters,
  [DashboardFiltersActionType.ClearFilters]: UsersDashboardFiltersActionType.ClearFilters,
  [DashboardFiltersActionType.ChangeMembers]: UsersDashboardFiltersActionType.ChangeMembers,
  [DashboardFiltersActionType.ShowFiltersPanel]: UsersDashboardFiltersActionType.ShowFiltersPanel,
  [DashboardFiltersActionType.HideFiltersPanel]: UsersDashboardFiltersActionType.HideFiltersPanel,
};

const {
  reducer,
  actions,
} = createDashboardFiltersSlice<UsersDashboardFiltersState, ChangeFiltersPayload, typeof actionTypes>(
  'users',
  getInitialState,
  actionTypes,
);

const usersDashboardFiltersReducer = createReducer(getInitialState(), (builder) => {
  builder.addCase(actions.changeFilters, (state, action) => {
    if (action.payload.statuses !== undefined) {
      state.statuses = action.payload.statuses;
    }

    if (action.payload.permissionGroup !== undefined) {
      state.permissionGroup = action.payload.permissionGroup;
    }

    if (action.payload.isMfaEnabled !== undefined) {
      state.isMfaEnabled = action.payload.isMfaEnabled;
    }
  });

  builder.addCase(actions.clearFilters, (state) => {
    state.statuses = [UserStatus.Active, UserStatus.Pending];
    state.permissionGroup = null;
    state.isMfaEnabled = null;
  });
});

export {
  actions,
  getInitialState,
};

export default reduceReducers(usersDashboardFiltersReducer, reducer);
