import { forwardRef } from 'react';
import TextInput, { TextInputPropsSingleLine } from '../../../TextInput/TextInput';
import clsx from 'clsx';

import styles from './AuthInput.module.scss';

const AuthInput = forwardRef<HTMLInputElement, TextInputPropsSingleLine>((props, ref) => {
  return (
    <TextInput
      {...props}
      ref={ref}
      className={clsx(styles.input, props.className)}
      containerClassName={clsx(styles.inputContainer, props.containerClassName)}
      errorClassName={clsx(styles.error, props.errorClassName)}
    />
  );
});

export default AuthInput;
