export class RequestError extends Error {
  constructor(
    message: string,
    readonly responseStatus: number,
    readonly code?: string,
    readonly errorType?: string,
    readonly data?: Record<string, unknown>,
  ) {
    super(message);
  }
}

const getRequestErrorData = async (
  response: Response,
): Promise<{ message: string; type?: string; data?: Record<string, unknown>; code?: string }> => {
  // not all failed requests will provide a JSON response
  try {
    const body = await response.json();
    const getErrorMessage = () => {
      if (body.message) {
        return body.message;
      }

      if (!body.data) {
        return body.error?.message || response.statusText;
      }

      return typeof body.data.error === 'string' ? body.data.error : body.data.error.message;
    };
    const message = getErrorMessage();

    return { message, type: body.data?.errorType || body.data?.error?.name, data: body.data, code: body.code };
  } catch (error) {
    return { message: response.statusText };
  }
};

export const readResponseAsJSON = async (response: Response) => {
  if (response.ok) {
    return response.json();
  }

  const {
    message,
    type,
    data,
    code,
  } = await getRequestErrorData(response);

  const { status } = response;

  throw new RequestError(message, status, code, type, data);
};
