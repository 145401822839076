import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { UserStatus } from 'api/UserApi';

export interface UseOrganizationMembersParams {
  includeInactive?: boolean;
}

const useOrganizationMembers = (params?: UseOrganizationMembersParams)  => {
  const usersMap = useSelector((state: ReduxState) => state.users.userById);

  return useMemo(() => {
    const users = Object.values(usersMap);

    const filteredUsers = params?.includeInactive
      ? users
      : users.filter((user) => user.status === UserStatus.Active);

    return filteredUsers.sort((firstUser, secondUser) => {
      return (firstUser.firstName || '').localeCompare(secondUser.firstName || '');
    });
  }, [usersMap, params?.includeInactive]);
};

export default useOrganizationMembers;
