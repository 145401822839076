export enum WebhookEventWithEndpointColumnSize {
  Endpoint = 35,
  EventType = 12,
  EventDate = 10,
  DocumentType = 15,
  Status = 10,
  LastRunAt = 8,
  NextRunAt = 10,
}

export enum WebhookEventColumnSize {
  DocumentType = 22,
  EventType = 18,
  EventDate = 20,
  Status = 12,
  LastRunAt = 20,
  NextRunAt = 8,
}
