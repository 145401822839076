import usersDashboardPagination from './Pagination';
import { UserApi } from 'api/UserApi';
import { saveUsers } from 'Users/UsersStore';

export default (api: UserApi, pagination: typeof usersDashboardPagination) => {
  return pagination.getItemsFetcherMiddleware(async (params, dispatch) => {
    const { items, total } = await api.find(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
        permissionGroup: params.permissionGroup ?? undefined,
        isEmailVerified: params.isEmailVerified,
        isMfaEnabled: params.isMfaEnabled,
        statuses: params.statuses,
      },
      params.sortingType,
    );

    dispatch(saveUsers());

    return {
      items: items.map((user) => user.id),
      total,
    };
  });
};
