import React, { FC, ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconClose } from './close.svg';
import {
  ErrorNotificationIcon,
  InfoNotificationIcon,
  SuccessIcon,
  WarningNotificationIcon,
} from 'static/images';
import { unhandledCase } from 'utils/unhandledCase';

import styles from './styles.module.scss';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface NotificationProps {
  type?: NotificationType;
  children: ReactNode;
  onClose: () => void;
}

const Notification: FC<NotificationProps> = ({ type = 'info', onClose, children }) => {
  const className = clsx(styles.notification, styles[`notification_${type}`]);

  const getNotificationIcon = (): ReactElement => {
    switch (type) {
      case 'info':
        return <InfoNotificationIcon className={styles.icon} />;
      case 'success':
        return <SuccessIcon className={styles.icon} />;
      case 'error':
        return <ErrorNotificationIcon className={styles.icon} />;
      case 'warning':
        return <WarningNotificationIcon className={styles.icon} />;
      default:
        return unhandledCase(type);
    }
  };

  return (
    <div className={className}>
      {getNotificationIcon()}
      <div className={styles.notification__message}>{children}</div>
      <div className={styles.notification__closeIcon} onClick={onClose}>
        <IconClose />
      </div>
    </div>
  );
};

export default Notification;
