import { useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import FilterLayout from 'components/FilterLayout';
import Button from 'components/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { WebhookEventStatus } from 'api/WebhookEventsApi';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import {
  ChangeFiltersPayload,
  EventsDashboardFiltersState,
} from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/Filters/EventsDashboardFiltersStore';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import EventTypeMultiselect from 'components/EventTypeMultiselect';
import areArraysEqual from 'utils/areArraysEqual';
import styles from './EventsFilters.module.scss';

interface EventsFiltersProps {
  filters: EventsDashboardFiltersState;
  onClose: () => void;
  onApply: (filters: ChangeFiltersPayload) => void;
  onClear: () => void;
  onFetchDocumentTypeOptions: (search: string) => Promise<Option[]>;
}

const getStatusColor = (status: WebhookEventStatus) => {
  if (status === WebhookEventStatus.Failed) {
    return styles.errorColor;
  }

  return styles.successColor;
}

const AVAILABLE_STATUSES = [
  {
    id: WebhookEventStatus.Succeeded,
    name: capitalize(WebhookEventStatus.Succeeded),
    color: getStatusColor(WebhookEventStatus.Succeeded),
  },
  {
    id: WebhookEventStatus.Failed,
    name: capitalize(WebhookEventStatus.Failed),
    color: getStatusColor(WebhookEventStatus.Failed),
  },
];

const EventsFilters = ({
  filters,
  onClose,
  onApply,
  onClear,
  onFetchDocumentTypeOptions,
}: EventsFiltersProps) => {
  const { eventTypes, dueCreatedDateRange, statuses, documentTypeId } = filters;
  
  const [selectedEventTypes, setSelectedEventTypes] = useState(eventTypes);
  const [fromCreatedDate, setFromCreatedDate] = useState(dueCreatedDateRange.from);
  const [toCreatedDate, setToCreatedDate] = useState(dueCreatedDateRange.to);
  const [selectedDocumentType, setSelectedDocumentType] = useState(documentTypeId);

  const dropdownSelectedStatuses = useMemo(() => {
    return statuses.map((status) => ({
      id: status,
      name: capitalize(status),
      color: getStatusColor(status),
    }));
  }, [statuses]);

  const [statusSelectProps, selectedStatuses, setSelectedStatuses] = useAutoCompletionMultiSelectProps(
    AVAILABLE_STATUSES,
    dropdownSelectedStatuses,
    (label) => label.id,
    (label) => label.name,
  );

  const handleOnCreatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromCreatedDate(from);
    setToCreatedDate(to);
  };

  const handleSelectEventType = (eventType: string) => {
    setSelectedEventTypes([...selectedEventTypes, eventType]);
  };

  const handleDeselectEventType = (eventTypeToDeselect: string) => {
    setSelectedEventTypes(selectedEventTypes.filter((eventType) => eventType !== eventTypeToDeselect));
  };

  const handleApplyClick = () => {
    onApply({
      dueCreatedDateRange: {
        from: fromCreatedDate,
        to: toCreatedDate,
      },
      eventTypes: selectedEventTypes,
      statuses: selectedStatuses.map((status) => status.id),
      documentTypeId: selectedDocumentType || null,
    });
  };

  const isApplyButtonDisabled = () => {
    const selectedStatusesIds = selectedStatuses.map(({ id }) => id);

    return areArraysEqual(eventTypes, selectedEventTypes)
      && areArraysEqual(statuses, selectedStatusesIds)
      && dueCreatedDateRange.to === toCreatedDate
      && dueCreatedDateRange.from === fromCreatedDate
      && documentTypeId === selectedDocumentType;
  };

  useEffect(() => {
    setSelectedEventTypes(eventTypes);
    setSelectedStatuses(dropdownSelectedStatuses);
    setFromCreatedDate(dueCreatedDateRange.from);
    setToCreatedDate(dueCreatedDateRange.to);
    setSelectedDocumentType(documentTypeId);
  }, [statuses, eventTypes, dueCreatedDateRange, documentTypeId]);

  return (
    <FilterLayout
      onClearFiltersClick={onClear}
      onClose={onClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        labelTitle='Document Type'
        className={styles.documentTypeAutoCompletion}
        value={selectedDocumentType || ''}
        onChange={(option) => setSelectedDocumentType(option.value || null)}
        fetchOptions={onFetchDocumentTypeOptions}
        loading={Boolean(selectedDocumentType) && !selectedDocumentType}
      />
      <AutoCompletionMultiselect
        containerClassName={styles.statusMultiselectContainer}
        id="status"
        labelTitle="Status"
        isLabelTags {...statusSelectProps}
      />
      <EventTypeMultiselect
        labelTitle="Event Type"
        id="eventTypes"
        selectedEventTypes={selectedEventTypes}
        onSelectEventType={handleSelectEventType}
        onDeselectEventType={handleDeselectEventType}
        hideDescription
      />
      <DateRangePicker
        from={fromCreatedDate}
        to={toCreatedDate}
        onChange={handleOnCreatedDateRangeChange}
        label="Event Date"
      />
      <Button
        kind="primary"
        size="form"
        disabled={isApplyButtonDisabled()}
        className={styles.applyFiltersButton}
        onClick={handleApplyClick}
      >
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default EventsFilters;
