import { Route } from 'react-router-dom';
import NotFoundPage from 'pages/NotFound';
import Home from 'pages/Home';
import { Switch } from 'react-router';
import Auth from './Auth';
import AppSectionRoutes from './AppSectionRoutes';
import { AppRoutes } from './RouteBuilder';
import ExtractionHistory from '../pages/ExtractionHistory';
import DocumentTypes from '../pages/DocumentTypes';
import ApiCredentials from '../pages/ApiCredentials';
import Webhooks from '../pages/Webhooks';
import CompanyInformationUsers from '../pages/CompanyInformationUsers';
import RestrictedByPermissionGroupsSection from './RestrictedByPermissionGroupsSection';
import { PermissionGroup } from '../enums/PermissionGroup';
import Logout from '../pages/Logout';
import DataExtractionResult from '../pages/DataExtractionResult';
import IpAddressWhitelistPage from '../pages/IpAddressWhitelist/IpAddressWhitelist';
import Billing from '../pages/Billing';

const Router = () => (
  <Switch>
    <Route path={AppSectionRoutes.auth}>
      <Auth />
    </Route>
    <Route path={AppRoutes.extractData}>
      <Home />
    </Route>
    <Route path={`${AppRoutes.extractionHistory}/:dataExtraction`}>
      <DataExtractionResult />
    </Route>
    <Route path={AppRoutes.extractionHistory}>
      <ExtractionHistory />
    </Route>
    <Route path={AppRoutes.documentTypes}>
      <DocumentTypes />
    </Route>
    <Route path={AppRoutes.apiCredentials}>
      <RestrictedByPermissionGroupsSection
        permissionGroups={[PermissionGroup.User]}
        pageName="API Credentials"
      >
        <ApiCredentials />
      </RestrictedByPermissionGroupsSection>
    </Route>
    <Route path={`${AppRoutes.webhooks}/:tab?`}>
      <RestrictedByPermissionGroupsSection
        permissionGroups={[PermissionGroup.User]}
        pageName="Webhooks"
      >
        <Webhooks />
      </RestrictedByPermissionGroupsSection>
    </Route>
    <Route path={AppRoutes.ipWhitelist}>
      <RestrictedByPermissionGroupsSection
        permissionGroups={[PermissionGroup.User]}
        pageName="API Address Whitelist"
      >
        <IpAddressWhitelistPage />
      </RestrictedByPermissionGroupsSection>
    </Route>
    <Route path={AppRoutes.teamMembers}>
      <RestrictedByPermissionGroupsSection
        permissionGroups={[PermissionGroup.Developer, PermissionGroup.User]}
        pageName="Team Members"
      >
        <CompanyInformationUsers />
      </RestrictedByPermissionGroupsSection>
    </Route>
    <Route path={AppRoutes.logout}>
      <Logout />
    </Route>
    <Route path={AppRoutes.billing}>
      <RestrictedByPermissionGroupsSection
        permissionGroups={[PermissionGroup.Developer, PermissionGroup.User]}
        pageName="Billing"
      >
        <Billing />
      </RestrictedByPermissionGroupsSection>
    </Route>
    <Route path="*">
      <NotFoundPage />
    </Route>
  </Switch>
);

export default Router;
