import { IClassificationResult } from 'api/DataExtractionApi';
import LinkButton from 'components/LinkButton';
import React, { FC } from 'react';
import styles from './OtherDocumentTypes.module.scss';
import { round } from 'lodash';
import { EnvironmentType } from 'constants/EnvironmentType';
import { env } from 'env';

interface OtherDocumentTypesProps {
  detectedDocumentTypes: IClassificationResult[] | null;
  selectedDocumentTypeId: string | null;
  onDocumentTypeChange: (documentTypeId: string) => void;
}

const OtherDocumentTypes: FC<OtherDocumentTypesProps> = ({
  detectedDocumentTypes,
  selectedDocumentTypeId,
  onDocumentTypeChange,
}) => {
  const filteredDetectedDocumentTypes = detectedDocumentTypes
    ?.filter(({ documentTypeId }) => documentTypeId !== selectedDocumentTypeId);

  if (!filteredDetectedDocumentTypes || filteredDetectedDocumentTypes.length === 0) {
    return null;
  }

  const renderDocumentTypeName = (documentTypeName: string, precision: number) => {
    if (env.REACT_APP_ENVIRONMENT !== EnvironmentType.Production) {
      return `${documentTypeName} ${round(precision * 100)}%`;
    }

    return documentTypeName;
  };

  return (
    <div className={styles.otherDocumentTypesContainer}>
      It might be:{' '}
      {filteredDetectedDocumentTypes.map(({ documentTypeId, documentTypeName, precision }, index) => (
        <>
          <LinkButton
            key={documentTypeId}
            className={styles.otherDocumentType}
            onClick={() => onDocumentTypeChange(documentTypeId)}
          >
            {renderDocumentTypeName(documentTypeName, precision)}
          </LinkButton>
          {index < filteredDetectedDocumentTypes.length - 1 ? ', ' : '.'}
        </>
      ))}
    </div>
  );
};

export default OtherDocumentTypes;
