import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import Loading from 'pages/Loading';
import AuthEventEmitter, { AuthEventMessage, OrganizationSubscriptionStatus } from 'eventHandlers/AuthEventEmitter';
import { updateSessionInfo } from 'SessionInfo/ActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthProvider } from 'providers/AuthProvider';
import { logOut } from 'stores/Actions';
import { SessionInfo } from 'SessionInfo/Types';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { once } from 'lodash';
import { getCurrentUser, getUsers } from 'Users/Thunks';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import { UsersSortingField } from 'api/UserApi';
import { ReduxState } from './types/ReduxState';
import { useLocation } from 'react-router-dom';
import { AUTHENTICATION_ROUTES } from 'RedirectionsMiddleware';

const ORGANIZATION_SUBSCRIPTION_ERROR_STATUSES = [
  OrganizationSubscriptionStatus.Canceled,
  OrganizationSubscriptionStatus.IncompleteExpired,
  OrganizationSubscriptionStatus.Unpaid,
];

const AppInitializer: FC = ({ children }) => {
  const authProvider = useAuthProvider();
  const dispatch = useDispatch();
  const dispatchThunk = useDispatchWithUnwrap();
  const [isLoading, setIsLoading] = useState(authProvider.isLoggedIn());

  const userSessionInfo = useSelector((state: ReduxState) => state.sessionInfo.user);
  const organizationSessionInfo = useSelector((state: ReduxState) => state.sessionInfo.organization);
  const isOrganizationDataLoaded = useSelector((state: ReduxState) => state.users.loaded);

  const routeBuilder = useRouteBuilder();

  const location = useLocation();

  const isOrganizationDataAvailable = useMemo(() => {
    return !!userSessionInfo?.isActive
      && !userSessionInfo?.isEmailNotVerified
      && !userSessionInfo.isMfaIncomplete
      && !!organizationSessionInfo?.subscriptionStatus
      && !ORGANIZATION_SUBSCRIPTION_ERROR_STATUSES.includes(organizationSessionInfo?.subscriptionStatus)
      && !AUTHENTICATION_ROUTES.includes(location.pathname);
  }, [userSessionInfo, organizationSessionInfo, location.pathname]);

  useEffect(() => {
    if (isOrganizationDataAvailable) {
      loadOrganizationData();
    }
  }, [isOrganizationDataAvailable]);

  const handleSessionInfoUpdate = (newSessionInfo: Partial<SessionInfo>) => {
    dispatch(updateSessionInfo(newSessionInfo));
  };

  const loadOrganizationData = async () => {
    if (!organizationSessionInfo?.id) {
      return;
    }

    await dispatchThunk(getUsers({ filters: {}, sortingType: { field: UsersSortingField.FirstName, ascending: true } }));
  };

  const initialize = useRef(once(async () => {
    if (authProvider.isLoggedIn()) {
      setIsLoading(true);

      try {
        await dispatchThunk(getCurrentUser());
      } finally {
        setIsLoading(false);
      }
    }
  }));

  const handleUnauthorizedRequest = () => {
    authProvider.clearState();

    dispatch(logOut());
  };

  const handleLogoutRequest = async () => {
    await authProvider.logout();

    authProvider.clearState();

    dispatch(logOut());

    routeBuilder.go(AppRoutes.extractData);
  };

  useEffect(() => {
    initialize.current();

    const unsubscribeCallbacks = [
      AuthEventEmitter.on(AuthEventMessage.SessionInfoUpdate, handleSessionInfoUpdate),
      AuthEventEmitter.on(AuthEventMessage.UnauthorizedRequest, handleUnauthorizedRequest),
      AuthEventEmitter.on(AuthEventMessage.Logout, handleLogoutRequest),
    ];

    return () => unsubscribeCallbacks.forEach((callback) => callback());
  }, []);

  if (isLoading || (!isOrganizationDataLoaded && isOrganizationDataAvailable)) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default AppInitializer;
