import FilePreview, { PreviewMode } from 'components/FilePreview/FilePreview';
import ProcessingDocumentLoader, { ProcessingDocumentLoaderStatus } from 'components/ProcessingDocumentLoader/ProcessingDocumentLoader';
import { DocumentExtension } from 'enums/DocumentExtension';
import React, { FC, MutableRefObject, memo, useMemo } from 'react';
import { getFilenameAndExtension } from 'utils/filenameUtils';
import styles from './CreateDataExtractionFilePreview.module.scss';

interface CreateDataExtractionFilePreviewProps {
  file: File;
  resizeContainerRef: MutableRefObject<HTMLDivElement | null>;
  isLoading: boolean;
  onClose?: () => void;
}

const CreateDataExtractionFilePreview: FC<CreateDataExtractionFilePreviewProps> = memo(({
  file,
  isLoading,
  onClose,
}) => {
  const previewLink = useMemo(() => URL.createObjectURL(file), [file]);

  const {
    extension,
  } = getFilenameAndExtension(file.name);

  return (
    <FilePreview
      previewLink={previewLink}
      fileExtension={extension as DocumentExtension}
      filename={file.name}
      className={styles.imagePreview}
      onClose={onClose}
      isLoading={isLoading}
      loadingIcon={<ProcessingDocumentLoader status={ProcessingDocumentLoaderStatus.Loading} />}
      disabled
      mode={PreviewMode.ReadOnly}
    />
  );
});

export default CreateDataExtractionFilePreview;
