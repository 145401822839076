import React, { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import FocusLock from 'react-focus-lock';
import { PopUpFooterType } from './PopUp';

import styles from './PopUpCore.module.scss';

interface PopUpProps {
  children: ReactNode;
  className?: string;
  footerText?: string | React.ReactNode;
  footerType?: PopUpFooterType;
  sectionClassName?: string;
}

const getFooterClassName = (footerType?: PopUpFooterType) => {
  switch (footerType) {
    case PopUpFooterType.Warning:
      return styles.warning;
    case PopUpFooterType.Info:
      return styles.info;
    default:
      return styles.warning;
  }
}

const PopUpCore = forwardRef<HTMLElement, PopUpProps>(({
  children,
  className,
  footerText,
  footerType,
  sectionClassName,
}, ref) => {
  return (
    <CSSTransition
      in
      appear
      timeout={Number(styles.animationTimeout)}
      classNames={{
        appear: styles.popupAppear,
        appearActive: styles.popupAppearActive,
        appearDone: styles.popupAppearDone,
      }}
    >
      <div className={clsx(styles.popupCore, className)}>
        <FocusLock className={styles.focusLockContainer} autoFocus={false}>
          <section className={clsx(styles.popupMain, sectionClassName)} ref={ref}>
            {children}
            {footerText && (
              <p className={clsx(styles.footerText, getFooterClassName(footerType))}>
                {footerType === PopUpFooterType.Warning && <span>Warning: </span>}
                {footerText}
              </p>
            )}
          </section>
        </FocusLock>
      </div>
    </CSSTransition>
  );
});

export default PopUpCore;
