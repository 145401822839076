import { AutoCompletionProps } from 'components/AutoCompletionMultiselect';
import { noop } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { Option } from 'components/SelectInput/SelectInput';
import { nanoid } from 'nanoid';

export interface UseAutoCompletionMultiSelectOptions<Item> {
  renderInputOptionPrefix?: (item: Item) => ReactElement,
  renderListOptionPrefix?: (item: Item) => ReactElement,
  getOptionColor?: (item: Item) => string | undefined;
}

const useAutoCompletionMultiSelectProps = <Item>(
  items: Item[],
  initialSelectedItems: Item[],
  getValue: (item: Item) => string,
  getName: (item: Item) => string,
  options?: UseAutoCompletionMultiSelectOptions<Item>,
  dependencies?: unknown[],
): [Omit<AutoCompletionProps, 'labelTitle' | 'id'>, Item[], (newItem: Item[]) => void] => {
  const [isChanged, setIsChanged] = useState(false);
  const { renderInputOptionPrefix, renderListOptionPrefix, getOptionColor } = options || {};
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const jointItems = [...items, ...initialSelectedItems].filter((item, index, array) => array.indexOf(item) === index);

  const mapItemToOption = (item: Item) => ({
    id: nanoid(),
    value: getValue(item),
    type: '',
    name: getName(item),
    icon: renderListOptionPrefix?.(item),
    color: ((item as unknown) as Option).color ?? getOptionColor?.(item),
  });

  const getByValue = (value: string) => jointItems.find((item) => getValue(item) === value)!;

  const updateIsChanged = () => {
    if (!isChanged) {
      setIsChanged(true);
    }
  };

  const props: Omit<AutoCompletionProps, 'labelTitle' | 'id'> = {
    options: items.map(mapItemToOption),
    selectedOptions: selectedItems.map((item) => getValue(item)),
    onInputChange: noop,
    onSelect: (addedItemValue) => {
      if (!selectedItems.find((selectedItem) => getValue(selectedItem) === addedItemValue)) {
        const addedItem = items.find((item) => getValue(item) === addedItemValue)!;

        updateIsChanged();
        setSelectedItems([...selectedItems, addedItem]);
      }
    },
    onDeleteOption: (deletedItemValue) => {
      updateIsChanged();
      setSelectedItems(selectedItems.filter((selectedItem) => getValue(selectedItem) !== deletedItemValue));
    },
    renderInputOptionPrefix: renderInputOptionPrefix
      ? (value: string) => renderInputOptionPrefix(getByValue(value))
      : undefined,
    getOptionByOptionValue: (optionValue: string) =>
      jointItems.map(mapItemToOption).find(({ value }) => value === optionValue)!,
  };

  useEffect(() => {
    if (dependencies && !isChanged) {
      setSelectedItems(initialSelectedItems);
    }
  }, dependencies);

  return [props, selectedItems, setSelectedItems];
};

export default useAutoCompletionMultiSelectProps;
