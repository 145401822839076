import React, { FC } from 'react';
import styles from './AutoDetectedLabel.module.scss';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';
import OverflowedText from 'components/OverflowedText';

interface AutoDetectedLabelProps {
  text?: string;
  className?: string;
}

const AutoDetectedLabel: FC<AutoDetectedLabelProps> = ({
  text,
  className,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      {text && <OverflowedText className={styles.text}>{text}</OverflowedText>}
      <WrapperWithTooltip tooltip="Document type was auto-detected.">
        <div className={styles.label}>A</div>
      </WrapperWithTooltip>
    </div>
  );
};

export default AutoDetectedLabel;
