import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useRouteBuilder from 'hooks/useRouteBuilder';
import useStateReset from 'hooks/useStateReset';
import {
  changeMembers,
  changeSearchValue,
  changeSortingType,
  clearFilters,
  extractionHistoryDashboardActionOrigin,
  hideFiltersPanel,
  pagination,
  resetState,
  showFiltersPanel,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryDashboardStore';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { ExtractionHistoryDashboardPaginationParams } from 'GeneralSettings/ui/ExtractionHistoryDashboard/Pagination';
import { createGetDataExtractionsSelector } from 'DataExtractions/Selectors';
import { AppRoutes } from 'routes/RouteBuilder';
import ExtractionHistoryDashboard from './ExtractionHistoryDashboard';
import useAbortSignal from 'hooks/useAbortSignal';
import {
  ExtractionHistoryDashboardFiltersState,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/Filters/ExtractionHistoryDashboardFiltersStore';
import { exportDataExtraction, getDataExtractions } from 'DataExtractions/Thunks';
import {
  DataExtraction,
  DataExtractionSortingField,
  DataExtractionSortingType,
  DataExtractionStatus,
} from 'api/DataExtractionApi';
import { debounce } from 'lodash';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { User } from 'api/UserApi';
import { batch } from 'react-redux';
import { usePolling } from 'hooks/usePolling';
import { ExportFileType } from '../../../../api/DataExtractionExportApi';
import { useGenerateContextualViewLink } from '../../../MainLayout/utils';
import {
  DataExtractionQueryAttribute,
} from '../../../../GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryQueryAttribute';

const LOAD_DATA_EXTRACTIONS_AFTER_SEARCH_DELAY = 300;

interface ExtractionHistoryDashboardConnectorProps {
  onCreateDataExtraction: () => void;
  onDeleteDataExtraction: (dataExtractionId: string) => void;
}

const ExtractionHistoryDashboardConnector: FC<ExtractionHistoryDashboardConnectorProps> = ({
  onCreateDataExtraction,
  onDeleteDataExtraction,
}) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const members = useOrganizationMembers();

  const routeBuilder = useRouteBuilder();

  const generateDataExtractionContextualViewLink = useGenerateContextualViewLink(
    DataExtractionQueryAttribute.ExtractionIdQueryAttribute,
  );

  useStateReset(resetState);

  const {
    filters,
    sortingType,
  } = useSelector((state: ReduxState) => state.generalSettings.ui.extractionHistoryDashboard);

  const paginationParams: ExtractionHistoryDashboardPaginationParams = {
    selectedMembers: filters.teamMembers,
    search: filters.search,
    sortingType,
    createdAtRange: filters.createdAtRange,
    documentTypeId: filters.documentTypeId,
    fileType: filters.fileType,
    status: filters.status,
  };

  const dataExtractionIds = pagination.usePaginatedItems(paginationParams);

  const paginationProps = pagination.usePagination(paginationParams);

  const getDataExtractionsSelector = useMemo(
    () => createGetDataExtractionsSelector(dataExtractionIds),
    [dataExtractionIds],
  );

  const dataExtractions = useSelector(getDataExtractionsSelector);

  const handleOpenViewDataExtraction = (dataExtraction: DataExtraction) => {
    const dataExtractionLink = dataExtraction.fileNames.length > 1
      ? generateDataExtractionContextualViewLink(dataExtraction.id)
      : routeBuilder.build(AppRoutes.extractionHistory, dataExtraction.id);

    routeBuilder.go(dataExtractionLink);
  };

  const handleExportDataExtraction = async (dataExtractionId: string, exportFileType: ExportFileType) => {
    await dispatchWithUnwrap(exportDataExtraction({
      dataExtractionId,
      exportFileType,
    }));
  };

  const requestDataExtractions = useAbortSignal((
    _abortSignal: AbortSignal,
    filtersToApply: ExtractionHistoryDashboardFiltersState,
    sortingTypeToApply: DataExtractionSortingType,
  ) => {
    dispatchWithUnwrap(getDataExtractions({
      filters: {
        search: filtersToApply.search,
        offset: 0,
        count: pagination.getDefaultItemsCountPerPage(),
        createdAtRange: filtersToApply.createdAtRange,
        documentTypeId: filtersToApply.documentTypeId || undefined,
        fileType: filtersToApply.fileType || undefined,
        status: filtersToApply.status || undefined,
        teamMembers: filtersToApply.teamMembers,
      },
      sortingType: sortingTypeToApply,
      actionOrigin: extractionHistoryDashboardActionOrigin,
    }));
  });

  const debouncedLoadDataExtractions = useMemo(
    () => debounce(requestDataExtractions, LOAD_DATA_EXTRACTIONS_AFTER_SEARCH_DELAY),
    [],
  );

  useEffect(() => {
    debouncedLoadDataExtractions(filters, sortingType);
  }, [
    filters.search,
    filters.teamMembers,
    filters.createdAtRange,
    filters.documentTypeId,
    filters.fileType,
    filters.status,
    sortingType,
    pagination,
  ]);

  const handleSearch = (search: string) => {
    dispatch(changeSearchValue(search));

    debouncedLoadDataExtractions({
      ...filters,
      search,
      offset: 0,
      count: pagination.getDefaultItemsCountPerPage(),
    })
  };

  const handleOpenFiltersPanel = () => {
    dispatch(showFiltersPanel());
  };

  const handleChangeSelectedMembers = (users: User[]) => {
    dispatch(changeMembers(users.map(({ id }) => id)));
  };

  const handleSort = (field: DataExtractionSortingField, ascending: boolean) => {
    dispatch(changeSortingType({ field, ascending }));
  };

  const handleClearFilters = () => {
    batch(() => {
      dispatch(clearFilters());
      dispatch(hideFiltersPanel());
    })
  };

  const existingDataExtractions  = dataExtractions.reduce((
    result,
    dataExtraction,
  ) => {
    if (dataExtraction) {
      result.push(dataExtraction);
    }

    return result;
  }, [] as DataExtraction[]);

  const processingIds = existingDataExtractions.reduce((result, dataExtraction) =>{
    if (dataExtraction && !!dataExtraction.statusSummary?.[DataExtractionStatus.Processing]) {
      result.push(dataExtraction.id);
    }

    return result;
  }, [] as string[]);

  usePolling(
    processingIds,
    () => debouncedLoadDataExtractions(filters, sortingType),
  );

  return (
    <div>
      <ExtractionHistoryDashboard
        filters={filters}
        dataExtractions={dataExtractions}
        paginationProps={paginationProps}
        sortingType={sortingType}
        onClearFilters={handleClearFilters}
        onFiltersButtonClick={handleOpenFiltersPanel}
        onSearch={handleSearch}
        members={members}
        onChangeSelectedMembers={handleChangeSelectedMembers}
        onSort={handleSort}
        onViewDataExtraction={handleOpenViewDataExtraction}
        onCreateDataExtraction={onCreateDataExtraction}
        onDeleteDataExtraction={onDeleteDataExtraction}
        onExportDataExtraction={handleExportDataExtraction}
      />
    </div>
  )
}

export default ExtractionHistoryDashboardConnector;
