import { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { batch } from 'react-redux';
import {
  changeFilters,
  clearFilters,
  hideFiltersPanel,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryDashboardStore';
import {
  ChangeFiltersPayload,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/Filters/ExtractionHistoryDashboardFiltersStore';
import DataExtractionFilters from './DataExtractionFilters';
import { Option } from 'components/SelectInput/SelectInput';

interface DataExtractionFiltersConnectorProps {
  onFetchDocumentTypeOptions: (search: string) => Promise<Option[]>;
}

const DataExtractionFiltersConnector: FC<DataExtractionFiltersConnectorProps> = ({
  onFetchDocumentTypeOptions,
}) => {
  const dispatch = useDispatch();

  const { filters } = useSelector((state: ReduxState) => state.generalSettings.ui.extractionHistoryDashboard);

  const handleClearFilters = () => {
    batch(() => {
      dispatch(clearFilters());
      dispatch(hideFiltersPanel());
    })
  }

  const handleApplyFilters = (filtersToApply: ChangeFiltersPayload) => {
    batch(() => {
      dispatch(changeFilters(filtersToApply));
      dispatch(hideFiltersPanel());
    });
  }

  const handleCloseFiltersPanel = () => {
    dispatch(hideFiltersPanel());
  }

  return (
    <DataExtractionFilters 
      filters={filters}
      onFetchDocumentTypeOptions={onFetchDocumentTypeOptions}
      onClear={handleClearFilters}
      onApply={handleApplyFilters}
      onClose={handleCloseFiltersPanel}
    />
  )
}

export default DataExtractionFiltersConnector;
