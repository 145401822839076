import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateWebhookEndpointParams, UpdateWebhookEndpointParams,
  WebhookEndpoint, WebhookEndpointSortingType,
} from 'api/WebhookEndpointsApi';
import { DataFilter, TableViewData } from 'api/Types';
import EndpointsActionType from './EndpointsActionType';
import { webhookEndpointsApi } from 'stores/api';
import { ActionOriginData, DataWithActionOrigin } from 'types/action-origin';

export const getWebhookEndpoints = createAsyncThunk<
  TableViewData<WebhookEndpoint>,
  DataWithActionOrigin<{ filters: DataFilter, sortingType: WebhookEndpointSortingType; }>,
  { fulfilledMeta: ActionOriginData }
>(
  EndpointsActionType.GetWebhookEndpoints,
  async ({ filters, sortingType, actionOrigin }, thunkApi) => {
    return thunkApi.fulfillWithValue(await webhookEndpointsApi.find(filters, sortingType), {
      ...filters,
      actionOrigin,
    });
  },
);

export const getWebhookEndpoint = createAsyncThunk<WebhookEndpoint, string>(
  EndpointsActionType.GetWebhookEndpoint,
  (id) => {
    return webhookEndpointsApi.findById(id);
  },
);

export const createWebhookEndpoint = createAsyncThunk<
  WebhookEndpoint,
  DataWithActionOrigin<CreateWebhookEndpointParams>,
  { fulfilledMeta: ActionOriginData }
>(
  EndpointsActionType.CreateWebhookEndpoint,
  async ({ actionOrigin, ...restParams }, thunkApi) => {
    return thunkApi.fulfillWithValue(await webhookEndpointsApi.create(restParams), {
      actionOrigin,
    });
  },
);

export const generateNewWebhookEndpointSecret = createAsyncThunk<{ id: string, endpointSecret: string; }, string>(
  EndpointsActionType.GenerateNewWebhookEndpointSecret,
  async (id: string) => {
    return {
      id,
      endpointSecret: await webhookEndpointsApi.generateNewEndpointSecret(id),
    };
  },
);

export const updateWebhookEndpoint = createAsyncThunk<
  WebhookEndpoint,
  DataWithActionOrigin<{ id: string; } & UpdateWebhookEndpointParams>,
  { fulfilledMeta: ActionOriginData }
>(
  EndpointsActionType.UpdateWebhookEndpoint,
  async ({ actionOrigin, ...restParams }, thunkApi) => {
    const { id, ...updateParams } = restParams;

    return thunkApi.fulfillWithValue(await webhookEndpointsApi.update(id, updateParams), {
      actionOrigin,
    });
  },
);

export const deleteWebhookEndpoint = createAsyncThunk<
  WebhookEndpoint,
  DataWithActionOrigin<{ id: string }>,
  { fulfilledMeta: ActionOriginData }
>(
  EndpointsActionType.DeleteWebhookEndpoint,
  async ({ id, actionOrigin }, thunkApi) => {
    return thunkApi.fulfillWithValue(await webhookEndpointsApi.delete(id), {
      actionOrigin,
    });
  },
);
