import React, { FC } from 'react';
import { Tag } from 'components/Tag';

interface VerificationStatusTagProps {
  verified: boolean;
  className?: string;
}

const VerificationStatusTag: FC<VerificationStatusTagProps> = ({ verified, className }) => {
  return (
    <Tag className={className} invertColors={verified} color={verified ? 'green' : 'gray'}>
      {verified ? 'verified' : 'not verified'}
    </Tag>
  );
};

export default VerificationStatusTag;
