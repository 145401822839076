import React, { FC, ReactElement, ReactNode } from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import clsx from 'clsx';

import styles from './WrapperWithHoverableTooltip.module.scss';

interface WrapperWithHoverableTooltipProps {
  tooltip: ReactNode;
  className?: string;
  placement?: TooltipProps['placement']
}

const WrapperWithHoverableTooltip: FC<WrapperWithHoverableTooltipProps> = ({
  tooltip,
  children,
  className,
  placement = 'bottom',
}) => {
  return (
    <Tooltip
      classes={{ popperInteractive: clsx(styles.tooltip, className) }}
      title={tooltip || ''}
      placement={placement}
      interactive
    >
      {children as ReactElement}
    </Tooltip>
  );
};

export default WrapperWithHoverableTooltip;
