import React, { FC } from 'react';
import { BillingImage } from 'static/images';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../types/ReduxState';
import { PermissionGroup } from '../../../enums/PermissionGroup';
import BillingPortalLink from 'components/BillingPortalLink';

import styles from './PaymentProcessingFailureAlert.module.scss';

const PaymentProcessingFailureAlert: FC = () => {
  const { user } = useSelector((state: ReduxState) => state.sessionInfo);
  const userIsOwner = user?.permissionGroup === PermissionGroup.Owner;

  return (
    <div className={styles.container}>
      <BillingImage className={styles.icon} />
      <div className={styles.message}>
        <p className={styles.message}>
          Payment processing has encountered a failure. Please make a payment to avoid losing account access.
        </p>
        {userIsOwner && <BillingPortalLink />}
      </div>
    </div>
  );
};

export default PaymentProcessingFailureAlert;
