import { Helmet } from "react-helmet";
import { EnvironmentType } from 'constants/EnvironmentType';
import { env } from 'env';

const HeadScripts = () => {
  if (env.REACT_APP_ENVIRONMENT !== EnvironmentType.Production) {
    return null;
  }

  return (
    <div>
      <Helmet>
        {/* Google Analytics */}
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-BZ3VZQCWYV'></script>
        <script>
          {/* eslint-disable-next-line */}
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-BZ3VZQCWYV');`}
        </script>

        {/* Chat Widget */}
        <script>
          {/* eslint-disable-next-line */}
          {`!function(e,t){if(!e.groove){var i=function(e,t){return Array.prototype.slice.call(e,t)},a={widget:null,loadedWidgets:{ },classes:{Shim:null,Embeddable:function(){this._beforeLoadCallQueue = [], this.shim = null, this.finalized = !1;var e=function(e){var t=i(arguments,1);if(this.finalized){if(!this[e])throw new TypeError(e+"() is not a valid widget method");this[e].apply(this,t)}else this._beforeLoadCallQueue.push([e,t])};this.initializeShim=function(){a.classes.Shim && (this.shim = new a.classes.Shim(this))},this.exec=e,this.init=function(){e.apply(this, ["init"].concat(i(arguments, 0))), this.initializeShim()},this.onShimScriptLoad=this.initializeShim.bind(this),this.onload=void 0}},scriptLoader:{callbacks:{ },states:{ },load:function(e,i){if("pending"!==this.states[e]){this.states[e] = "pending";var a=t.createElement("script");a.id=e,a.type="text/javascript",a.async=!0,a.src=i;var s=this;a.addEventListener("load",(function(){s.states[e] = "completed", (s.callbacks[e] || []).forEach((function (e) { e() }))}),!1);var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n)}},addListener:function(e,t){"completed" !== this.states[e] ? (this.callbacks[e] || (this.callbacks[e] = []), this.callbacks[e].push(t)) : t()}},createEmbeddable:function(){var t=new a.classes.Embeddable;return e.Proxy?new Proxy(t,{get:function(e,t){return e instanceof a.classes.Embeddable?Object.prototype.hasOwnProperty.call(e,t)||"onload"===t?e[t]:function(){e.exec.apply(e, [t].concat(i(arguments, 0)))}:e[t]}}):t},createWidget:function(){var e=a.createEmbeddable();return a.scriptLoader.load("groove-script","https://8585ff45-2859-43e8-8fa0-eb3b4cb444c6.widget.cluster.groovehq.com/api/loader"),a.scriptLoader.addListener("groove-iframe-shim-loader",e.onShimScriptLoad),e}};e.groove=a}}(window,document); window.groove.widget = window.groove.createWidget(); window.groove.widget.init('8585ff45-2859-43e8-8fa0-eb3b4cb444c6', { });`}
        </script>

        {/* FullStory */}
        <script>
          {/* eslint-disable-next-line */}
          {`window['_fs_host'] = 'fullstory.com'; window['_fs_script'] = 'edge.fullstory.com/s/fs.js'; window['_fs_org'] = 'ES3CT'; window['_fs_namespace'] = 'FS'; (function(m,n,e,t,l,o,g,y){ if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;} g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[]; o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script; y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y); g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)}; g.anonymize=function(){g.identify(!!0)}; g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)}; g.log = function(a,b){g("log",[a,b])}; g.consent=function(a){g("consent",!arguments.length||a)}; g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)}; g.clearUserCookie=function(){}; g.setVars=function(n, p){g('setVars',[n,p]);}; g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y]; if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)}; g._v="1.3.0"; })(window,document,window['_fs_namespace'],'script','user');`}
        </script>
      </Helmet>
    </div>
  )
}

export default HeadScripts;
