export type Size = { width: number, height: number };

const scaleImageToRect = (imageWidth: number, imageHeight: number, rectWidth: number, rectHeight: number): Size => {
  if (imageWidth <= rectWidth && imageHeight <= rectHeight) {
    return { width: imageWidth, height: imageHeight };
  }

  const commonCoefficient = Math.max(
    imageWidth / rectWidth,
    imageHeight / rectHeight,
  );

  const newWidth = imageWidth / commonCoefficient;
  const newHeight = imageHeight / commonCoefficient;

  return newWidth > rectWidth || newHeight > rectHeight
    ? scaleImageToRect(newWidth, newHeight, rectWidth, rectHeight)
    : { width: newWidth, height: newHeight };
};

export default scaleImageToRect;
