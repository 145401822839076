import Lottie from 'lottie-react';
import animationData from './animationData.json';
import styles from './LogoLoader.module.scss';

const LogoLoader = () => {
  return (
    <div className={styles.container}>
      <Lottie animationData={animationData} className={styles.animationContainer} />
    </div>
  );
};

export default LogoLoader;
