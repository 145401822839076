import React, { FC, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import DataExtractionContextualView from './DataExtractionContextualView';
import { AppRoutes } from 'routes/RouteBuilder';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { ReduxState } from 'types/ReduxState';
import { exportDataExtraction, getDataExtraction } from '../../DataExtractions/Thunks';
import {
  pagination,
  changeSortingType,
  dataExtractionResultsViewActionOrigin,
  resetState,
} from 'GeneralSettings/ui/DataExtractionResultsView/DataExtractionResultsViewStore';
import { createGetDataExtractionResultsSelector } from '../../DataExtractionResults/Selectors';
import {
  DataExtractionResult,
  DataExtractionResultFilters,
  DataExtractionResultSortingField,
  DataExtractionResultsSortingType,
} from '../../api/DataExtractionResultApi';
import { getDataExtractionResults } from '../../DataExtractionResults/Thunks';
import {
  DATA_EXTRACTION_RESULTS_PER_PAGE_DEFAULT,
} from '../../GeneralSettings/ui/DataExtractionResultsView/Pagination';
import {
  DataExtractionQueryAttribute,
} from '../../GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryQueryAttribute';
import DataExtractionBasicActionsConnector from '../ExtractionHistory/Connectors/DataExtractionBasicActionsConnector';
import useOrganizationMembers from '../../hooks/useOrganizationMembers';
import { ExportFileType } from '../../api/DataExtractionExportApi';
import useStateReset from '../../hooks/useStateReset';

export interface IDataExtractionContextualViewConnectorProps {
  dataExtractionId: string;
  onClose: () => void;
}

const DataExtractionContextualViewConnector: FC<IDataExtractionContextualViewConnectorProps> = ({
  onClose,
  dataExtractionId,
}) => {
  const routeBuilder = useRouteBuilder();
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  useStateReset(resetState);

  const members = useOrganizationMembers();

  const dataExtraction = useSelector((state: ReduxState) => {
    return state.dataExtractions.dataExtractionsById[dataExtractionId];
  });

  useEffect(() => {
    if (!dataExtraction) {
      dispatchWithUnwrap(getDataExtraction(dataExtractionId));
    }
  }, [dataExtractionId]);

  const dataExtractionResultsSortingType = useSelector((state: ReduxState) => {
    return state.generalSettings.ui.dataExtractionResultsView.sortingType;
  });

  const dataExtractionResultsPaginationParams = {
    sortingType: dataExtractionResultsSortingType,
    dataExtractionId,
  };

  const dataExtractionResultsIds = pagination.usePaginatedItems(
    dataExtractionResultsPaginationParams,
  );
  const decisionResultsPaginationProps = pagination.usePagination(
    dataExtractionResultsPaginationParams,
  );

  const getDataExtractionResultsSelector = useMemo(() => {
    return createGetDataExtractionResultsSelector(dataExtractionResultsIds);
  }, [dataExtractionResultsIds]);

  const dataExtractionResults = useSelector(getDataExtractionResultsSelector);

  const requestDataExtractionResults = (
    filtersToApply: DataExtractionResultFilters,
    sortingTypeToApply: DataExtractionResultsSortingType,
  ) => {
    dispatchWithUnwrap(getDataExtractionResults({
      filters: {
        offset: 0,
        count: DATA_EXTRACTION_RESULTS_PER_PAGE_DEFAULT,
        ...filtersToApply,
      },
      sortingType: sortingTypeToApply,
      actionOrigin: dataExtractionResultsViewActionOrigin,
    }));
  };

  useEffect(() => {
    requestDataExtractionResults({
      dataExtractionId,
    }, dataExtractionResultsSortingType);
  }, [dataExtractionId, dataExtractionResultsSortingType]);

  const generateResultLink = (result: DataExtractionResult) => {
    if (!dataExtraction) {
      return '';
    }

    return routeBuilder
      .withParams({ [DataExtractionQueryAttribute.DataExtractionResultIdQueryAttribute]: result.id })
      .build(AppRoutes.extractionHistory, result.dataExtractionId);
  };

  const handleDecisionResultClick = (result: DataExtractionResult) => {
    routeBuilder.go(generateResultLink(result));
  };

  const handleSortDecisionResults = (field: DataExtractionResultSortingField, ascending: boolean) => {
    dispatch(changeSortingType({ field, ascending }));
  };

  const [,
    handleExportDataExtractionResult,
    [currentDownloadingResult],
  ] = useAsyncActionCallback(async (resultId: string, exportFileType: ExportFileType) => {
    await dispatchWithUnwrap(exportDataExtraction({
      dataExtractionId,
      resultId,
      exportFileType,
    }));
  }, []);

  const handleDeleteComplete = useCallback(() => {
    routeBuilder.go(AppRoutes.extractionHistory);
  }, []);

  return (
    <DataExtractionBasicActionsConnector onDeleteComplete={handleDeleteComplete}>
      {({ handleDownloadResults, handleDeleteDataExtraction, isResultsDownloadInProgress }) => (
        <DataExtractionContextualView
          members={members}
          dataExtraction={dataExtraction}
          onClose={onClose}
          dataExtractionResults={dataExtractionResults}
          resultsPaginationProps={decisionResultsPaginationProps}
          resultsSortingType={dataExtractionResultsSortingType}
          isResultsDownloadInProgress={isResultsDownloadInProgress}
          downloadingResultId={currentDownloadingResult || null}
          onResultClick={handleDecisionResultClick}
          onDataExtractionDelete={handleDeleteDataExtraction}
          onResultsDownload={handleDownloadResults}
          onSortResults={handleSortDecisionResults}
          onExportDataExtractionResult={handleExportDataExtractionResult}
        />
      )}
    </DataExtractionBasicActionsConnector>
  );
};

export default DataExtractionContextualViewConnector;
