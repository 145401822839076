import { env } from 'env';
import APIRest, { FetchOptions, HttpMethod, RequestBody } from 'api/APIRest';
import { DataFilter, DataViewSortingType } from 'api/Types';

export enum FetchMethodType {
  Post = 'POST',
  Get = 'GET',
  Put = 'PUT',
  Delete = 'DELETE',
}

export default abstract class BaseSystemApi<Resource = unknown> extends APIRest {
  protected resourceName = '';

  protected origin = env.REACT_APP_API_URL as string;

  protected getResources<Result = Resource[]>(
    params: URLSearchParams = new URLSearchParams(),
    abortSignal?: AbortSignal,
  ): Promise<Result> {
    let queryString = params.toString();
    if (queryString) {
      queryString = `?${queryString}`;
    }

    return this.fetch<Result>(`/${this.resourceName}/${queryString}`, 'GET', undefined, { abortSignal });
  }

  protected getResourceById<Result = Resource>(id: string, urlSearchParams?: URLSearchParams): Promise<Result> {
    return this.fetch<Result>(urlSearchParams ? `/${this.resourceName}/${id}?${urlSearchParams}` : `/${this.resourceName}/${id}`);
  }

  protected createResource<Result = Resource>(body: RequestBody, options: Partial<FetchOptions> = {}): Promise<Result> {
    return this.fetch<Result>(`/${this.resourceName}`, 'POST', body, options);
  }

  protected deleteResource<Result = void>(id: string): Promise<Result> {
    return this.fetch<Result>(`/${this.resourceName}/${id}`, 'DELETE');
  }

  protected updateResource<Result = Resource>(id: string, body: RequestBody): Promise<Result> {
    return this.fetch<Result>(`/${this.resourceName}/${id}`, 'PUT', body);
  }

  protected getPaginationUrlSearchParams(filters?: DataFilter, sortingType?: DataViewSortingType): URLSearchParams {
    const params = new URLSearchParams();

    if (filters?.offset) {
      params.set('offset', filters.offset.toString());
    }

    if (filters?.count) {
      params.set('count', filters.count.toString());
    }

    if (filters?.search) {
      params.set('search', filters.search);
    }

    if (sortingType) {
      params.set('sortField', sortingType.field);
      params.set('sortDirection', sortingType.ascending ? 'asc' : 'desc');
    }

    return params;
  }

  protected async fetch<ResBody, ReqBody extends RequestBody = RequestBody>(
    url: string,
    method: HttpMethod = 'GET',
    body?: ReqBody,
    options: Partial<FetchOptions> = {},
  ) {
    return super.fetch<ResBody, ReqBody>(url, method, body, {
      ...options,
      headers: {
        ...options.headers,
      },
    })
  }

  protected get absoluteOrigin(): string {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}${this.origin}`;
  }
}
