import React, { ReactNode } from 'react';
import clsx from 'clsx';
import WrapperWithTooltip, { TooltipPosition } from 'components/Tooltip';
import WrapperWithHoverableTooltip from 'components/WrapperWithHoverableTooltip/WrapperWithHoverableTooltip';

import styles from './QuestionIcon.module.scss';

export type TooltipType = 'default' | 'hoverable';

export interface QuestionIconProps {
  tooltip: ReactNode;
  tooltipPosition?: TooltipPosition;
  className?: string;
  tooltipClassName?: string;
  size?: number;
  tooltipType?: TooltipType;
  disabled?: boolean;
}

const DEFAULT_SIZE = 16;
const DEFAULT_TOOLTIP_POSITION: TooltipPosition = 'top';

const QuestionIcon = ({
  tooltip,
  tooltipPosition = DEFAULT_TOOLTIP_POSITION,
  tooltipClassName,
  className,
  size = DEFAULT_SIZE,
  tooltipType = 'default',
  disabled,
}: QuestionIconProps) => {
  const Tooltip = tooltipType === 'default' ? WrapperWithTooltip : WrapperWithHoverableTooltip;

  return (
    <Tooltip
      tooltip={disabled ? null : tooltip}
      position={tooltipPosition}
      placement="bottom"
      className={tooltipClassName}
    >
      <p
        style={{ width: size, height: size, minHeight: size, minWidth: size }}
        className={clsx(styles.icon, disabled && styles.disabledIcon, className)}
      />
    </Tooltip>
  );
};

export default QuestionIcon;
