import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'handlers';
import createAuthHandler from 'AuthHandler';
import AuthEventEmitter from 'eventHandlers/AuthEventEmitter';
import responseHandlers from 'api/ResponseHandlers';
import thunk from 'redux-thunk';
import RedirectionsMiddleware from 'RedirectionsMiddleware';
import AuthRestApi from 'api/AuthApi';
import history from './history';
import {
  pagination as documentTemplateDashboardPagination,
  documentTypesDashboardActionOrigin,
  documentTypesDashboardStatePath,
  filtersAndSortingSaver as documentTypesDashboardFiltersAndSortingSaver,
} from 'GeneralSettings/ui/DocumentTypesDashboard/DocumentTypesDashboardStore';
import createDocumentTypesDashboardMiddlewares from 'GeneralSettings/ui/DocumentTypesDashboard/DocumentTypesDashboardMiddleware';
import {
  filtersAndSortingSaver as usersDashboardFiltersAndSortingSaver,
  pagination as usersDashboardPagination,
} from 'GeneralSettings/ui/UsersDashboard/UsersDashboardStore';
import {
  dataExtractionResultsApi,
  dataExtractionsApi,
  documentTypeApi,
  userApi,
  webhookEndpointsApi,
  webhookEventsApi,
} from './api';
import usersDashboardUpdatesMiddleware from 'GeneralSettings/ui/UsersDashboard/UsersDashboardUpdatesMiddleware';
import createUsersDashboardPaginationMiddleware from 'GeneralSettings/ui/UsersDashboard/UsersDashboardPaginationMiddleware';
import errorNotificationsMiddleware from 'middlewares/ErrorNotificationsMiddleware';
import createExtractionHistoryDashboardPaginationMiddlewares from 'GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryDashboardMiddleware';
import createDataExtractionResultsViewMiddlewares from 'GeneralSettings/ui/DataExtractionResultsView/DataExtractionResultsViewPaginationMiddleware';
import {
  pagination as extractionHistoryDashboardPagination,
  extractionHistoryDashboardActionOrigin,
  extractionHistoryDashboardStatePath,
  filtersAndSortingSaver as extractionHistoryDashboardFiltersAndSortingSaver,
} from 'GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryDashboardStore';
import {
  pagination as dataExtractionResultsViewPagination,
  filtersAndSortingSaver as dataExtractionResultsViewFiltersAndSortingSaver, dataExtractionResultsViewActionOrigin,
} from 'GeneralSettings/ui/DataExtractionResultsView/DataExtractionResultsViewStore';
import AsyncThunkRejectionNotificationMiddleware from 'middlewares/AsyncThunkRejectionNotificationMiddleware';
import webhookEndpointNotificationsMiddleware from 'Webhooks/Endpoints/EndpointNotificationsMiddleware';
import webhookEventNotificationsMiddleware from 'Webhooks/Events/EventsNotificationsMiddleware';
import
createWebhookEventsDashboardMiddlewares
  from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/EventsDashboardMiddlewares';
import {
  pagination as webhookEventsDashboardPagination,
  eventsDashboardActionOrigin as webhookEventsDashboardActionOrigin,
  eventsDashboardStatePath as webhookEventsDashboardStatePath,
  filtersAndSortingSaver as webhookEventsDashboardFiltersAndSortingSaver,
} from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/EventsDashboardStore';
import
createWebhookEndpointsDashboardMiddlewares
  from 'GeneralSettings/ui/WebhooksDashboards/EndpointsDashboard/EndpointsDashboardMiddlewares';
import {
  pagination as webhookEndpointsDashboardPagination,
  endpointsDashboardActionOrigin as webhookEndpointsDashboardActionOrigin,
  endpointsDashboardStatePath as webhookEndpointsDashboardStatePath,
  filtersAndSortingSaver as webhookEndpointsDashboardFiltersAndSortingSaver,
} from 'GeneralSettings/ui/WebhooksDashboards/EndpointsDashboard/EndpointsDashboardStore';
import
createWebhookEndpointEventsTableMiddlewares
  from 'GeneralSettings/ui/WebhooksDashboards/EndpointDetails/EndpointEventsTable/EndpointEventsTableMiddlewares';
import {
  pagination as webhookEndpointEventsTablePagination,
  endpointEventsTableActionOrigin as webhookEndpointEventsTableActionOrigin,
  endpointEventsTableStatePath as webhookEndpointEventsTableStatePath,
} from 'GeneralSettings/ui/WebhooksDashboards/EndpointDetails/EndpointEventsTable/EndpointEventsTableStore';

export const authHandler = createAuthHandler({
  authApi: new AuthRestApi(responseHandlers),
  authEvents: AuthEventEmitter,
  localStorage,
});

const redirectionsMiddleware = RedirectionsMiddleware(authHandler, history);
const {
  paginationMiddleware: documentTypesPaginationMiddleware,
  updatesMiddleware: documentTypesUpdatesMiddleware,
} = createDocumentTypesDashboardMiddlewares(
  documentTypeApi,
  documentTemplateDashboardPagination,
  documentTypesDashboardStatePath,
  [documentTypesDashboardActionOrigin],
);

const usersDashboardPaginationMiddleware = createUsersDashboardPaginationMiddleware(
  userApi,
  usersDashboardPagination,
);

const {
  paginationMiddleware: dataExtractionsDashboardPaginationMiddleware,
  updatesMiddleware: dataExtractionsDashboardUpdatesMiddleware,
} = createExtractionHistoryDashboardPaginationMiddlewares(
  dataExtractionsApi,
  extractionHistoryDashboardPagination,
  extractionHistoryDashboardStatePath,
  [extractionHistoryDashboardActionOrigin, dataExtractionResultsViewActionOrigin],
);

const {
  paginationMiddleware: dataExtractionResultsViewPaginationMiddleware,
} = createDataExtractionResultsViewMiddlewares(
  dataExtractionResultsApi,
  dataExtractionResultsViewPagination,
);

const {
  paginationMiddleware: webhookEventsPaginationMiddleware,
  updatesMiddleware: webhookEventsUpdatesMiddleware,
} = createWebhookEventsDashboardMiddlewares(
  webhookEventsApi,
  webhookEventsDashboardPagination,
  webhookEventsDashboardStatePath,
  [webhookEventsDashboardActionOrigin],
);

const {
  paginationMiddleware: webhookEndpointsPaginationMiddleware,
  updatesMiddleware: webhookEndpointsUpdatesMiddleware,
} = createWebhookEndpointsDashboardMiddlewares(
  webhookEndpointsApi,
  webhookEndpointsDashboardPagination,
  webhookEndpointsDashboardStatePath,
  [webhookEndpointsDashboardActionOrigin],
);

const {
  paginationMiddleware: webhookEndpointEventsTablePaginationMiddleware,
  updatesMiddleware: webhookEndpointEventsTableUpdatesMiddleware,
} = createWebhookEndpointEventsTableMiddlewares(
  webhookEventsApi,
  webhookEndpointEventsTablePagination,
  webhookEndpointEventsTableStatePath,
  [webhookEndpointEventsTableActionOrigin],
)

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunk,
    redirectionsMiddleware,
    errorNotificationsMiddleware,
    AsyncThunkRejectionNotificationMiddleware,
    documentTypesPaginationMiddleware,
    documentTypesUpdatesMiddleware,
    usersDashboardUpdatesMiddleware,
    usersDashboardPaginationMiddleware,
    usersDashboardFiltersAndSortingSaver.getMiddleware(),
    dataExtractionsDashboardPaginationMiddleware,
    dataExtractionResultsViewPaginationMiddleware,
    dataExtractionsDashboardUpdatesMiddleware,
    webhookEventNotificationsMiddleware,
    webhookEventsPaginationMiddleware,
    webhookEventsUpdatesMiddleware,
    webhookEndpointNotificationsMiddleware,
    webhookEndpointsPaginationMiddleware,
    webhookEndpointsUpdatesMiddleware,
    webhookEventsDashboardFiltersAndSortingSaver.getMiddleware(),
    webhookEndpointsDashboardFiltersAndSortingSaver.getMiddleware(),
    webhookEndpointEventsTablePaginationMiddleware,
    webhookEndpointEventsTableUpdatesMiddleware,
    extractionHistoryDashboardFiltersAndSortingSaver.getMiddleware(),
    dataExtractionResultsViewFiltersAndSortingSaver.getMiddleware(),
    documentTypesDashboardFiltersAndSortingSaver.getMiddleware(),
  ],
});

export { history };

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
