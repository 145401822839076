import { createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import { DocumentType } from '../api/DocumentTypeApi';
import { DocumentTypesActionType } from './DocumentTypesActionType';
import normalizeEntityArray from '../utils/normalizeEntityArray';
import { getDocumentType, getDocumentTypes, updateDocumentType } from './Thunks';

export interface DocumentTypesState {
  documentTypesById: Record<string, DocumentType>
}

export const getInitialState = (): DocumentTypesState => ({
  documentTypesById: {},
});

export const setDocumentTypes = createAction<DocumentType[]>(DocumentTypesActionType.SetDocumentTypes);
export const resetDocumentTypes = createAction(DocumentTypesActionType.ResetDocumentTypes);

const documentTypesReducer = createReducer<DocumentTypesState>(getInitialState(), (builder) => {
  builder.addCase(setDocumentTypes, (state, action) => {
    state.documentTypesById = {
      ...state.documentTypesById,
      ...normalizeEntityArray(action.payload),
    };
  });

  builder.addCase(getDocumentTypes.fulfilled, (state, action) => {
    state.documentTypesById = {
      ...state.documentTypesById,
      ...normalizeEntityArray(action.payload.items),
    };
  });

  builder.addCase(resetDocumentTypes, (state) => {
    state.documentTypesById = {};
  });

  builder.addMatcher(isAnyOf(getDocumentType.fulfilled, updateDocumentType.fulfilled), (state, action) => {
    state.documentTypesById[action.payload.id] = action.payload;
  });
});

export default documentTypesReducer;
