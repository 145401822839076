import { validateNameField } from 'utils/validation/validation';

const MIN_LENGTH = 2;

interface PersonalDetails {
  firstName: string;
  lastName: string;
}

export interface PersonalDetailsValidationResult {
  firstName?: string;
  lastName?: string;
}

export const LABELS = {
  firstName: 'First Name',
  lastName: 'Last Name',
};

const validatePersonalDetails = (
  data: Partial<PersonalDetails>,
): PersonalDetailsValidationResult => {
  const errors: PersonalDetailsValidationResult = Object.keys(data).reduce((result, key) => {
    if (!data[key] || data[key].length === 0) {
      return { ...result, [key]: `${LABELS[key]} is required` };
    }

    if (data[key]?.length < MIN_LENGTH) {
      return { ...result, [key]: `Minimum length is ${MIN_LENGTH}` };
    }

    return result;
  }, {});

  const firstNameErrorMessage = validateNameField(data.firstName || '');

  if (!errors.firstName && firstNameErrorMessage) {
    errors.firstName = firstNameErrorMessage;
  }

  const lastNameErrorMessage = validateNameField(data.lastName || '');

  if (!errors.lastName && lastNameErrorMessage) {
    errors.lastName = lastNameErrorMessage;
  }

  return errors;
};

export default validatePersonalDetails;
