import { FC } from 'react';
import QuestionIcon from 'components/QuestionIcon';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import WebhookTab from 'pages/Webhooks/WebhookTab';
import { TabLinkType } from 'components/TabSwitch/TabSwitch';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { AppRoutes } from 'routes/RouteBuilder';
import { EndpointsDashboard, EventsDashboard } from './Dashboards';
import { WebhookEvent } from 'api/WebhookEventsApi';
import styles from './WebhooksOverview.module.scss';

const TABS: TabSwitchOption[] = [{
  label: 'Endpoints',
  id: WebhookTab.Endpoints,
  type: TabLinkType.Link,
}, {
  label: 'Events',
  id: WebhookTab.Events,
  type: TabLinkType.Link,
}];

interface WebhooksOverviewProps {
  tab: WebhookTab;
  onTabChange: (tab: TabSwitchOption) => void;
  onEndpointCreate: () => void;
  onEndpointDelete: (id: string) => void;
  onEndpointStatusChange: (id: string, status: boolean) => void;
  onEventDelete: (id: string) => void;
  onEventResend: (id: string) => void;
  onEventDisplayDetails: (event: WebhookEvent) => void;
}

const WebhooksOverview: FC<WebhooksOverviewProps> = ({
  tab,
  onTabChange,
  onEndpointCreate,
  onEndpointDelete,
  onEndpointStatusChange,
  onEventDelete,
  onEventResend,
  onEventDisplayDetails,
}) => {
  const routeBuilder = useRouteBuilder();

  const getTabLink = ({ id: tabName }: TabSwitchOption) => {
    return routeBuilder.build(AppRoutes.webhooks, tabName);
  };

  const renderQuestionIconTooltip = () => (
    <>
      <p>Use webhooks to asynchronously receive</p>
      <p>results when data extraction is complete</p>
    </>
  );

  const renderTabContent = () => {
    switch (tab) {
      case WebhookTab.Endpoints:
        return (
          <EndpointsDashboard
            onCreate={onEndpointCreate}
            onDelete={onEndpointDelete}
            onStatusChange={onEndpointStatusChange}
          />
        );
      case WebhookTab.Events:
        return (
          <EventsDashboard
            onDelete={onEventDelete}
            onResend={onEventResend}
            onDisplayDetails={onEventDisplayDetails}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>Synthetiq Webhooks</h2>
        <QuestionIcon size={24} tooltip={renderQuestionIconTooltip()} />
      </div>
      <TabSwitch
        tabs={TABS}
        selectedTabId={tab}
        onSelect={onTabChange}
        getTabLink={getTabLink}
      />
      <div className={styles.tabContent}>
        {renderTabContent()}
      </div>
    </>
  );
};

export default WebhooksOverview;
