import clsx from 'clsx';
import React, { FC } from 'react';
import StickyContainer from 'components/StickyContainer/StickyContainer';

import styles from './TableHead.module.scss';

interface TableHeadProps {
  sticky?: boolean;
  className?: string;
  columnsClassName?: string;
  stickyMarginTop?: number;
  stickyRootElement?: HTMLElement;
}

const TableHead: FC<TableHeadProps> = ({
  sticky,
  columnsClassName,
  className,
  stickyMarginTop = 0,
  stickyRootElement = document.body,
  children,
}) => {
  return (
    <StickyContainer
      sticky={sticky}
      stickyTopOffset={stickyMarginTop}
      stickyRootElement={stickyRootElement}
      className={clsx(styles.tableHead, className)}
    >
      {() => (
        <div className={clsx(styles.columns, columnsClassName)}>{children}</div>
      )}
    </StickyContainer>
  );
};

export default TableHead;
