import { DataExtractionResultApi } from '../../../api/DataExtractionResultApi';
import createDataExtractionResultsViewPagination from './Pagination';
import { setDataExtractionResults } from '../../../DataExtractionResults/DataExtractionResultsStore';

const createDataExtractionResultsViewMiddlewares = (
  api: DataExtractionResultApi,
  pagination: ReturnType<typeof createDataExtractionResultsViewPagination>,
) => {
  const paginationMiddleware = pagination.getItemsFetcherMiddleware(async (
    params,
    dispatch,
  ) => {
    const {
      perPage,
      page,
      sortingType,
      ...restParams
    } = params;

    const { items, total } = await api.search({
      count: perPage,
      offset: (page - 1) * perPage,
      dataExtractionId: restParams.dataExtractionId,
    }, params.sortingType);

    dispatch(setDataExtractionResults(items));

    return {
      items: items.map((item) => item.id),
      total,
    };
  });

  return {
    paginationMiddleware,
  };
};

export default createDataExtractionResultsViewMiddlewares;
