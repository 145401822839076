import React, { useEffect } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import SecurePhoneAuthenticationForm from 'components/Auth/SecurePhoneAuthenticationForm';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { useAuthProvider } from 'providers/AuthProvider';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import useTimer from 'hooks/useTimer';
import { RESEND_CODE_TIMEOUT } from 'constants/AuthenticationCodeConstants';
import AuthFormFooter from 'components/Auth/AuthFormFooter/AuthFormFooter';

import styles from './SecurePhoneAuthentication.module.scss';

const SecurePhoneAuthentication = () => {
  const { seconds, restart, isRunning } = useTimer(RESEND_CODE_TIMEOUT);
  const accountDetails = useSelector((state: ReduxState) => state.users.currentUser);
  const authProvider = useAuthProvider();
  const dispatch = useDispatch();

  const handleLogout = () => {
    authProvider.emitLogout()
  };

  const getSubTitle = () => {
    return (
      <>
        Please enter the code sent to <div className={styles.subTitlePhone}>{accountDetails?.phone}</div>
      </>
    );
  };

  const resendCode = async () => {
    try {
      await authProvider.resendPhoneVerificationCode();

      notification.createNotification(
        getMessage(MessageType.AuthenticationCodeSent, { target: accountDetails?.phone }),
        'success',
        dispatch,
      );

      restart();
    } catch (error) {
      notification.createNotification(error.message, 'error', dispatch);
    }
  };

  const onSubmit = async (code: string) => {
    try {
      await authProvider.verifyPhoneVerificationCode(code);
    } catch (error) {
      notification.createNotification(error.message, 'error', dispatch);
    }
  };

  useEffect(() => {
    resendCode();
  }, []);

  return (
    <AuthLayout
      title="Phone Authentication"
      subTitle={getSubTitle()}
      subTitleClassName={styles.subTitle}
      formFooter={<AuthFormFooter onClick={handleLogout} linkText="Back to Sign In" />}
    >
      <SecurePhoneAuthenticationForm
        onResendCode={resendCode}
        resendInSeconds={isRunning ? seconds : 0}
        onSubmit={onSubmit}
        buttonText="Authenticate"
      />
    </AuthLayout>
  );
};

export default SecurePhoneAuthentication;
