import Pagination from 'pagination';
import { ReduxState } from 'types/ReduxState';
import { WebhookEventSortingType, WebhookEventStatus } from 'api/WebhookEventsApi';

export const WEBHOOK_EVENTS_PER_PAGE_DEFAULT = 15;

export interface WebhookEventsPaginationParams {
  search?: string;
  sortingType: WebhookEventSortingType;
  webhookId?: string;
  eventTypes?: string[];
  statuses?: WebhookEventStatus[];
  dueCreatedDateFrom?: Date | null;
  dueCreatedDateTo?: Date | null;
}

const createEventsDashboardPagination = <Params extends WebhookEventsPaginationParams>(namespace: string[] | string) => {
  return Pagination<ReduxState, string, Params>(
    namespace,
    WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
    ({ search }) => (search ? ['searched', search] : []),
  );
};

export default createEventsDashboardPagination;
