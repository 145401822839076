export type ContentType = 'application/json' | 'text/html' | null;

const getBasicHeaders = (contentType?: ContentType): Headers => {
  const headers = new Headers();
  if (contentType) {
    headers.set('Accept', contentType);
    headers.set('Content-Type', contentType);
  }
  return headers;
};

export default getBasicHeaders;
