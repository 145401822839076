import { OrganizationInfoHeader } from 'eventHandlers/AuthEventEmitter';
import base64ToObject from 'utils/base64ToObject';

const ORGANIZATION_HEADER = 'x-synthetic-organization';

const getOrganizationInfo = (response: Response): OrganizationInfoHeader | null => {
  const organizationHeaderValue = response.headers.get(ORGANIZATION_HEADER);

  if (organizationHeaderValue) {
    const organization = base64ToObject<OrganizationInfoHeader>(organizationHeaderValue);

    return organization;
  }

  return null;
};

export default getOrganizationInfo;
