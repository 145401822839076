import { Option } from '../components/SelectInput/SelectInput';
import { PermissionGroup } from '../enums/PermissionGroup';

const permissionGroupOptions: Option[] = [{
  name: 'Owner',
  value: PermissionGroup.Owner,
  description: 'Grants full access to all sections, with no restrictions',
},{
  name: 'Developer',
  value: PermissionGroup.Developer,
  description: 'Grants full access to all sections except Team Members and Billing',
},{
  name: 'User',
  value: PermissionGroup.User,
  description: 'Grants access only to the Extract Data, Extraction History, Document Types and My Profile sections',
}];

export default permissionGroupOptions;
