import SignInForm from 'components/Auth/SignInForm/SignInForm';
import { useAuthProvider } from 'providers/AuthProvider';
import AuthLayout from 'components/Auth/AuthLayout';
import notification from 'handlers/notification/notificationActionCreator';
import { useDispatch } from 'react-redux';
import { SignInData } from 'api/AuthApi';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getCurrentUser } from 'Users/Thunks';
import useRouteBuilder from '../../hooks/useRouteBuilder';
import { AppRoutes } from '../../routes/RouteBuilder';
import AuthFormFooter from '../../components/Auth/AuthFormFooter/AuthFormFooter';

const SignIn = () => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const authProvider = useAuthProvider();
  const routeBuilder = useRouteBuilder();

  const handleSignIn = async (data: SignInData): Promise<void> => {
    try {
      await authProvider.signIn(data);
      await dispatchWithUnwrap(getCurrentUser());
    } catch (error) {
      notification.createNotification(error.message, 'error', dispatch);
    }
  };

  const renderFormFooter = () => {
    return (
      <AuthFormFooter
        linkText='Create Account'
        linkTo={routeBuilder.build(AppRoutes.auth.createAccount)}
        message='New User?'
      />
    );
  };

  return (
    <AuthLayout
      title="Welcome Back"
      subTitle="Please sign in to your account."
      formFooter={renderFormFooter()}
    >
      <SignInForm onSubmit={handleSignIn} />
    </AuthLayout>
  );
};

export default SignIn;
