import { createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import { CurrentUser, User } from 'api/UserApi';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import {
  updateAccountDetails,
  verifyChangeEmailCode,
  verifyChangePhoneCode,
  verifyDeletePhoneCode,
} from 'MyAccount/ActionCreator';
import { UsersActionType } from './ActionTypes';
import { getCurrentUser, getUser, getUsers, inviteUser, updateUser } from './Thunks';

export interface UsersState {
  userById: Record<string, User>;
  currentUser: CurrentUser | null;
  loaded: boolean,
}

const initialState: UsersState = {
  userById: {},
  currentUser: null,
  loaded: false,
};

export const saveUsers = createAction(UsersActionType.SaveUsers);

const usersReducer = createReducer(initialState, (builder) => {
  builder.addCase(getUsers.fulfilled, (state, action) => {
    state.userById = {
      ...state.userById,
      ...normalizeEntityArray(action.payload.items),
    };

    state.loaded = true;
  });

  builder.addCase(inviteUser.fulfilled, (state, action) => {
    if (action.payload) {
      state.userById[action.payload.id] = action.payload;
    }
  });

  builder.addMatcher(isAnyOf(getUser.fulfilled, updateUser.fulfilled), (state, action) => {
    state.userById[action.payload.id] = action.payload;
  });

  builder.addMatcher(
    isAnyOf(
      getCurrentUser.fulfilled,
      updateAccountDetails.fulfilled,
      updateAccountDetails.fulfilled,
      verifyChangeEmailCode.fulfilled,
      verifyChangePhoneCode.fulfilled,
      verifyDeletePhoneCode.fulfilled,
    ),
    (state, action) => {
      state.userById[action.payload.id] = action.payload;
      state.currentUser = state.currentUser?.id === action.payload.id
        ? { ...state.currentUser, ...action.payload }
        : action.payload;
    },
  );
});

export default usersReducer;
