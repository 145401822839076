import { FC, useEffect, useState } from 'react';
import DataExtractionResultOverview from './DataExtractionResultOverview';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { usePolling } from 'hooks/usePolling';
import { getDataExtractionResult, getDataExtractionResults } from '../../DataExtractionResults/Thunks';
import { DataExtractionResultSortingField, DataExtractionStatus } from '../../api/DataExtractionResultApi';
import { ExportFileType } from '../../api/DataExtractionExportApi';
import useQueryParam from '../../hooks/useQueryParam';
import {
  DataExtractionQueryAttribute,
} from '../../GeneralSettings/ui/ExtractionHistoryDashboard/ExtractionHistoryQueryAttribute';
import {
  dataExtractionResultsViewActionOrigin,
  resetState,
} from '../../GeneralSettings/ui/DataExtractionResultsView/DataExtractionResultsViewStore';
import useStateReset from '../../hooks/useStateReset';
import { DataExtractionResultsActionType } from '../../DataExtractionResults/DataExtractionResultsActionType';

interface DataExtractionResultOverviewConnectorProps {
  dataExtractionId: string;
  onExportDataExtractionResult: (resultId: string, exportFileType: ExportFileType) => void;
}

const DataExtractionResultConnector: FC<DataExtractionResultOverviewConnectorProps> = ({
  dataExtractionId,
  onExportDataExtractionResult,
}) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const organizationMembers = useOrganizationMembers();

  useStateReset(resetState, DataExtractionResultsActionType.ResetDataExtractionResults);

  const dataExtractionResultId = useQueryParam(
    DataExtractionQueryAttribute.DataExtractionResultIdQueryAttribute,
  );
  const [firstResultId, setFirstResultId] = useState('');

  const dataExtractionResult = useSelector((state: ReduxState) => {
    return firstResultId ? state.dataExtractionResults.resultsById[firstResultId] : undefined;
  });

  useEffect(() => {
    dispatchWithUnwrap(
      getDataExtractionResults(
        {
          actionOrigin: dataExtractionResultsViewActionOrigin,
          filters: {
            ...(dataExtractionResultId ? { id: dataExtractionResultId } : {}),
            dataExtractionId,
            count: 1,
          },
          sortingType: {
            field: DataExtractionResultSortingField.FileName,
            ascending: true,
          },
        },
    )).then(({ items }) => {
      const [firstDataExtractionResult] = items;

      if (firstDataExtractionResult) {
        setFirstResultId(firstDataExtractionResult.id);
      }
    });
  }, [dataExtractionId]);

  const pollingIds = dataExtractionResult && dataExtractionResult.status === DataExtractionStatus.Processing
    ? [dataExtractionResult.dataExtractionId]
    : [];

  usePolling(
    pollingIds,
    () => {
      dispatchWithUnwrap(getDataExtractionResult({
        id: firstResultId!,
        actionOrigin: dataExtractionResultsViewActionOrigin,
      }),
    );
  });

  return (
    <DataExtractionResultOverview
      dataExtractionResult={dataExtractionResult}
      members={organizationMembers}
      onExportDataExtractionResult={onExportDataExtractionResult}
    />
  )
}

export default DataExtractionResultConnector;
