import React, { useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import Users from 'components/Users/Dashboards/UsersDashboard';
import { ReduxState } from 'types/ReduxState';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'components/MainLayout';
import { useCloseContextualView } from 'components/MainLayout/utils';
import { EditUserContextualView, InviteUserContextualView } from 'components/Users/ContextualViews';
import UsersFilter, { ApplyUsersFiltersPayload } from 'components/Users/Filters/UsersFilters/UsersFilters';
import notification from 'handlers/notification/notificationActionCreator';
import useRouteBuilder from 'hooks/useRouteBuilder';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { InviteUserParams } from 'api/UserInviteApi';
import { cancelInvitation, getUser, inviteUser, resendUserInvite, updateUser } from 'Users/Thunks';
import useAsyncActionCallback from 'hooks/useAsyncActionCallback';
import { UpdateUserPrams, User } from 'api/UserApi';
import {
  clearFilters,
  hideFiltersPanel,
  changeFilters,
  showFiltersPanel,
} from 'GeneralSettings/ui/UsersDashboard/UsersDashboardStore';
import getMessage, { MessageType } from 'constants/messages';
import permissionGroupOptions from '../../constants/permissionTypeOptions';
import ConfirmPopup from '../../components/ConfirmPopup';
import useConfirmChanges from '../../hooks/useConfirmChanges';

const CompanyInformationUsers = () => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const routeBuilder = useRouteBuilder();

  const userIdToEdit = routeBuilder.currentQueryParams.get('edit');

  const filters = useSelector(
    (state: ReduxState) => state.generalSettings.ui.usersDashboard.filters,
  );
  const userToEdit = useSelector(
  (state: ReduxState) => {
      return userIdToEdit ? state.users.userById[userIdToEdit] : null;
    },
  );
  const currentUser = useSelector((state: ReduxState) => state.users.currentUser);

  const [
    displayConfirmCancelInvitation,
    resetConfirmCancelInvitation,
    onConfirmCancelInvitation,
    createConfirmCancelInvitationCallback,
  ] = useConfirmChanges();

  useEffect(() => {
    if (userToEdit) {
      dispatchWithUnwrap(getUser(userToEdit.id));
    }
  }, [userIdToEdit]);

  const closeContextualView = useCloseContextualView();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(clearFilters());
      dispatch(hideFiltersPanel());
    });
  };

  const openFiltersPanel = () => {
    dispatch(showFiltersPanel());
  };

  const handleApplyFilters = (params: ApplyUsersFiltersPayload) => {
    batch(() => {
      dispatch(changeFilters({
        statuses: filters.statuses,
        isMfaEnabled: params.isMfaEnabled ?? null,
        permissionGroup: params.permissionGroup ?? null,
      }));
      dispatch(hideFiltersPanel());
    });
  };

  const handleCloseFilters = () => {
    dispatch(hideFiltersPanel());
  };

  const [isInviteSendingInProgress, handleSubmitInviteNewUser] = useAsyncActionCallback(async (params: InviteUserParams) => {
    const user = await dispatchWithUnwrap(inviteUser(params));

    if (user) {
      closeContextualView();

      notification.createNotification(`Email invitation sent to ${user.email}.`, 'success', dispatch);
    }
  }, [closeContextualView]);

  const [
    isUserUpdateInProgress,
    handleSubmitUserUpdate,
  ] = useAsyncActionCallback(async (params: UpdateUserPrams) => {
    if (!userIdToEdit) {
      return;
    }

    await dispatchWithUnwrap(updateUser({ id: userIdToEdit, ...params }));

    closeContextualView();
  }, [userIdToEdit, closeContextualView]);

  const handleResendUserInvite = async () => {
    if (!userToEdit) {
      return;
    }

    await dispatchWithUnwrap(resendUserInvite(userToEdit.id));

    notification.createNotification(
      getMessage(MessageType.InvitationSent, { email: userToEdit.email }),
      'success',
      dispatch,
    );
  };

  const [
    isInviteCancellationInProgress,
    handleCancelUserInvitation,
  ] = useAsyncActionCallback(async (user: User) => {
    await dispatchWithUnwrap(cancelInvitation(user.id));

    notification.createNotification(
      getMessage(MessageType.InvitationCancelled, { email: user.email }),
      'success',
      dispatch,
    );
  }, []);

  const handleCancelUserToEditInvitation = async () => {
    if (!userToEdit) {
      return;
    }

    await handleCancelUserInvitation(userToEdit);

    closeContextualView();
  };

  const handleCancelUserInvitationWithConfirm = createConfirmCancelInvitationCallback(handleCancelUserInvitation);
  const handleCancelUserToEditInvitationWithConfirm = createConfirmCancelInvitationCallback(handleCancelUserToEditInvitation);

  const renderRightSidePopup = () => {
    if (!filters.showFiltersPanel) {
      return null;
    }

    return (
      <UsersFilter
        onApply={handleApplyFilters}
        onClear={handleClearFilters}
        onClose={handleCloseFilters}
        permissionGroups={permissionGroupOptions}
        permissionGroup={filters.permissionGroup ?? undefined}
        isMfaEnabled={filters.isMfaEnabled ?? undefined}
      />
    );
  };

  const renderContextualView = () => {
    if (routeBuilder.currentQueryParams.has('new')) {
      return (
        <InviteUserContextualView
          onSubmit={handleSubmitInviteNewUser}
          onClose={closeContextualView}
          permissionGroups={permissionGroupOptions}
          isInviteSendingInProgress={isInviteSendingInProgress}
        />
      );
    }

    if (userIdToEdit && currentUser) {
      return (
        <EditUserContextualView
          user={userToEdit}
          onSubmit={handleSubmitUserUpdate}
          onClose={closeContextualView}
          selfEdit={currentUser.id === userIdToEdit}
          permissionGroups={permissionGroupOptions}
          isUserUpdateInProgress={isUserUpdateInProgress}
          onCancelInvitation={handleCancelUserToEditInvitationWithConfirm}
          onResendInvite={handleResendUserInvite}
        />
      );
    }

    return null;
  };

  const renderOverlay = () => {
    if (displayConfirmCancelInvitation) {
      return (
        <ConfirmPopup
          title="Cancel Invitation"
          message="Are you sure you want to cancel invitation for this user?"
          confirmText="Yes, Cancel Invitation"
          declineText="No, Go Back"
          onPopupClose={resetConfirmCancelInvitation}
          onConfirmClick={onConfirmCancelInvitation}
          loading={isInviteCancellationInProgress}
        />
      );
    }
    return null;
  };

  return (
    <MainLayout
      contextualView={renderContextualView()}
      closeContextualView={closeContextualView}
      rightSidePopupView={renderRightSidePopup()}
      closeRightSidePopupView={handleCloseFilters}
      overlay={renderOverlay()}
    >
      <PageWrapperWithFooter>
        <PageContent noPadding>
          <Users
            showFiltersPanel={openFiltersPanel}
            onClearFilters={handleClearFilters}
            onCancelUserInvitation={handleCancelUserInvitationWithConfirm}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default CompanyInformationUsers;
