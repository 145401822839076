import React, { FC, useState } from 'react';
import { User } from 'api/UserApi';
import PhoneCodeConfirmationStep from 'components/WithIdentityConfirmationActionPopup/Steps/PhoneCodeConfirmationStep';
import PasswordConfirmationStep from 'components/WithIdentityConfirmationActionPopup/Steps/PasswordConfirmationStep';

enum ConfirmPasswordUserTypeActionStep {
  ConfirmPassword = 'Confirm Password',
  ConfirmPhone = 'Confirm Phone Number',
}

export interface WithPasswordIdentityConfirmActionPopupProps {
  currentUser: User
  actionName: string;
  onSubmit: () => Promise<void>;
}

const WithPasswordIdentityConfirmActionPopupContent: FC<WithPasswordIdentityConfirmActionPopupProps> = ({
  actionName,
  onSubmit,
  currentUser,
}) => {
  const [step, setStep] = useState(ConfirmPasswordUserTypeActionStep.ConfirmPassword);

  return (
    <>
      {step === ConfirmPasswordUserTypeActionStep.ConfirmPassword && (
        <PasswordConfirmationStep
          onSubmit={() => setStep(ConfirmPasswordUserTypeActionStep.ConfirmPhone)}
        />
      )}
      {step === ConfirmPasswordUserTypeActionStep.ConfirmPhone && (
        <PhoneCodeConfirmationStep
          currentUser={currentUser}
          buttonText={actionName}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default WithPasswordIdentityConfirmActionPopupContent;
