import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { getButtonSizeClassName, getButtonTypeClassName } from 'components/Button/Button';
import ButtonLoader from 'components/ButtonLoader';

import styles from './ButtonLikeToggle.module.scss';

export type HTMLCheckBoxProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface ButtonLikeToggleProps extends Omit<HTMLCheckBoxProps, 'onChange'> {
  onChange: (e: ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
  isLoading?: boolean;
}

const ButtonLikeToggle = ({
  checked,
  className,
  children,
  onChange,
  disabled = false,
  isLoading = false,
  ...props
}: ButtonLikeToggleProps) => {
  const labelClassName = clsx(
    styles.label,
    getButtonSizeClassName('default'),
    getButtonTypeClassName(checked ? 'warning' : 'secondary'),
    disabled && styles.disabledLabel,
    className,
  );

  const labelContent = typeof children === 'function' ? children(checked) : children;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = Boolean(event.target.checked);
    onChange(event, isChecked);
  };

  return (
    <label className={labelClassName}>
      {isLoading ? (
        <ButtonLoader dotColor={styles.secondaryDotColor} className={styles.loader} />
      ) : (
        <>
          <input
            type="checkbox"
            checked={checked}
            className={styles.checkbox}
            onChange={handleInputChange}
            disabled={disabled}
            {...props}
          />
          {labelContent}
        </>
      )}
    </label>
  );
};

export default ButtonLikeToggle;
