import React, { FC } from 'react';
import clsx from 'clsx';
import { CloseImage } from 'static/images';
import styles from './ContextualViewContainer.module.scss';

export interface ContextualViewContainerProps {
  className?: string;
  onClose: () => void;
  minimizeSize?: boolean;
  containerId?: string;
}

const ContextualViewContainer: FC<ContextualViewContainerProps> = ({
  className,
  children,
  onClose,
  minimizeSize,
  containerId,
}) => (
  <div
    id={containerId}
    className={clsx(styles.contextualViewContainer, minimizeSize && styles.minimizedContextualViewContainer, className)}
  >
    {children}
    <CloseImage onClick={onClose} className={styles.closeImage} />
  </div>
);

export default ContextualViewContainer;
