import React from 'react';
import { MenuProps } from '@material-ui/core';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import UserAvatar from 'components/UserAvatar';
import getAvatarUrl from 'api/utils/getAvatarUrl';

import styles from './RestUsersPopupList.module.scss';

export interface AvatarUserInfo {
  id: string;
  firstName: string | null;
  lastName: string | null;
  color: string;
  avatarId?: string;
}

interface RestUsersPopupListProps<UserType extends AvatarUserInfo> extends MenuProps {
  users: UserType[];
  selectedUsers?: UserType[];
  onUserSelect?: (user: UserType) => void;
}

const RestUsersPopupList = <UserType extends AvatarUserInfo>({
  users,
  selectedUsers,
  onUserSelect,
  ...menuProps
}: RestUsersPopupListProps<UserType>) => {
  const isSelected = (user: AvatarUserInfo) => !!selectedUsers?.find(({ id }) => id === user.id);

  return (
    <ContextualPopUp
      {...menuProps}
      PopoverClasses={{ paper: styles.popup }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ maxHeight: styles.contextualPopUpMaxHeight }}
    >
      {users.map((user) => (
        <ActionPopUpItem className={styles.popup__user} onClick={() => onUserSelect?.(user)} key={user.id}>
          <UserAvatar
            firstName={user.firstName || ''}
            lastName={user.lastName || ''}
            imageUrl={getAvatarUrl(user.avatarId)}
            active={isSelected(user)}
            className={styles.popup__user__image}
            size={10}
            color={user.color}
          />
          {user.firstName} {user.lastName}
        </ActionPopUpItem>
      ))}
    </ContextualPopUp>
  );
};

export default RestUsersPopupList;
