import { PerformanceOverviewDocumentType } from 'api/PerformanceOverviewApi';
import { useMemo } from 'react';

const NUMBER_OF_DOCUMENT_TYPES_TO_SHOW = 3;

const useFormattedDocumentTypes = (documentTypes: PerformanceOverviewDocumentType[] | null) => {
  return useMemo(() => {
    if (!documentTypes) {
      return documentTypes;
    }

    const groups = documentTypes.reduce((result, type) => {
      result[type.documentType.name] = (result[type.documentType.name] || 0) + 1;

      return result;
    }, {});

    const sortedDocumentType = Object.keys(groups).sort((nameA, nameB) => groups[nameB] - groups[nameA]);

    return [
      ...sortedDocumentType.slice(0, NUMBER_OF_DOCUMENT_TYPES_TO_SHOW).map((documentTypeName) => ({
        name: documentTypeName,
        value: groups[documentTypeName],
      })),
      {
        name: 'Other',
        value: sortedDocumentType
          .slice(NUMBER_OF_DOCUMENT_TYPES_TO_SHOW)
          .reduce((total, documentTypeName) => total + groups[documentTypeName], 0),
      },
    ].filter(({ value }) => value > 0);
  }, [documentTypes]);
};

export default useFormattedDocumentTypes;
