import { createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import { getDataExtractionResult, getDataExtractionResults } from './Thunks';
import {
  DataExtractionResult,
} from '../api/DataExtractionResultApi';
import normalizeEntityArray from '../utils/normalizeEntityArray';
import { DataExtractionResultsActionType } from './DataExtractionResultsActionType';

export interface DataExtractionResultsState {
  resultsById: Record<string, DataExtractionResult>;
}

export const getInitialState = (): DataExtractionResultsState => ({
  resultsById: {},
});

export const setDataExtractionResults = createAction<DataExtractionResult[]>(
  DataExtractionResultsActionType.SetDataExtractionResults,
);

export const resetDataExtractionResults = createAction<DataExtractionResult[]>(
  DataExtractionResultsActionType.ResetDataExtractionResults,
);

const dataExtractionResultsReducer = createReducer<
  DataExtractionResultsState
>(getInitialState(), (builder) => {
  builder.addCase(setDataExtractionResults, (
    state,
    action,
  ) => {
    state.resultsById = {
      ...state.resultsById,
      ...normalizeEntityArray(action.payload),
    };
  });

  builder.addCase(resetDataExtractionResults, getInitialState);

  builder.addCase(getDataExtractionResults.fulfilled, (state, action) => {
    state.resultsById = {
      ...state.resultsById,
      ...normalizeEntityArray(action.payload.items),
    };
  });

  builder.addMatcher(isAnyOf(
    getDataExtractionResult.fulfilled,
  ), (state, action) => {
    state.resultsById = {
      ...state.resultsById,
      [action.payload.id]: action.payload,
    };
  });
});

export default dataExtractionResultsReducer;
