import React, { useState, DetailedHTMLProps, InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import useRandomIdFallback from 'hooks/randomIdFallback';
import Label from 'components/Label';

import styles from './PasswordInput.module.scss';

type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface InputProps extends Omit<HTMLInputProps, 'ref'> {
  id?: string;
  labelTitle: string;
  errorMessage?: string;
  topRightElement?: ReactNode;
  containerClassName?: string;
  labelInnerContainerClassName?: string;
  eyeButtonClassName?: string;
  passwordShownClassName?: string;
  passwordHiddenClassName?: string;
  focusClassName?: string;
  errorClassName?: string;
}

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(({
  id: providedId,
  labelTitle,
  onChange,
  errorMessage,
  value,
  topRightElement,
  containerClassName,
  required,
  labelInnerContainerClassName,
  eyeButtonClassName,
  passwordShownClassName,
  passwordHiddenClassName,
  focusClassName,
  errorClassName,
  ...inputProps
}, ref) => {
  const id = useRandomIdFallback(providedId);
  const [isVisible, setVisible] = useState(false);
  const [isFocused, setFocus] = useState(false);
  const toggleFocusInput = () => {
    setFocus(!isFocused);
  };
  const toggleVisibility = () => {
    setVisible(!isVisible);
  };
  const labelClassNames = clsx(
    styles.inputContainer__input,
    isFocused && clsx(styles.inputFocus, focusClassName),
    errorMessage && clsx(styles.inputError, errorClassName),
  );

  return (
    <div
      className={clsx(
        styles.inputContainer,
        containerClassName, isVisible ? passwordShownClassName : passwordHiddenClassName,
      )}
    >
      <div className={styles.labelWithLink}>
        <Label htmlFor={id} className={styles.label} required={required}>
          {labelTitle}
        </Label>
        {topRightElement}
      </div>
      <label className={labelClassNames}>
        <div className={clsx(styles.labelInnerContainer, labelInnerContainerClassName)}>
          <input
            type={isVisible ? 'text' : 'password'}
            id={id}
            value={value}
            onFocus={toggleFocusInput}
            onBlur={toggleFocusInput}
            onChange={onChange}
            autoComplete="none"
            className={styles.input}
            ref={ref}
            {...inputProps}
          />
          <button
            type="button"
            tabIndex={-1}
            className={clsx(isVisible ? styles.hideButton : styles.showButton, eyeButtonClassName)}
            onClick={toggleVisibility}
          >
            {' '}
          </button>
        </div>
      </label>
      {errorMessage && <p className={styles.inputContainer__error}>{errorMessage}</p>}
    </div>
  );
},
);

export default PasswordInput;
