import { FC, useEffect } from 'react';
import Loading from 'pages/Loading';
import useRouteBuilder from 'hooks/useRouteBuilder';
import { useAuthProvider } from 'providers/AuthProvider';
import { useDispatch } from 'react-redux';
import { AppRoutes } from 'routes/RouteBuilder';
import { logOut } from 'stores/Actions';

const Logout: FC = () => {
  const routeBuilder = useRouteBuilder();
  const authProvider = useAuthProvider();
  const dispatch = useDispatch();

  const logout = async () => {
    await authProvider.logout();
    authProvider.clearState();

    routeBuilder.go(AppRoutes.auth.signIn);

    dispatch(logOut());
  };

  useEffect(() => {
    logout();
  }, []);

  return <Loading />;
};

export default Logout;
