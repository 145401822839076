import DateTimeUtils from 'utils/dateUtils';
import splitDateRange from 'utils/splitDateRange';
import { DateRange } from 'utils/dateFormat';
import { round } from 'lodash';

export interface DashboardChartPoint {
  x: number;
  y: number;
}

export type PerformanceOverviewDashboardChart = {
  points: DashboardChartPoint[];
  total: number;
};

export interface GroupInformation {
  startDate: string;
}

export interface PerformanceOverviewCountInformation extends GroupInformation {
  filesProcessed: number;
  pagesProcessed: number;
  avgProcessingTime: number;
}

export interface PerformanceOverviewInformationResult {
  totalFilesProcessed: number;
  totalPagesProcessed: number;
  totalAvgProcessingTime: number;
  groups: PerformanceOverviewCountInformation[];
}

const parseDateXValue = (date: string) => DateTimeUtils.parse(date).toNumber();

const fillZeroDates = (
  points: DashboardChartPoint[],
  dateRange: DateRange,
  organizationCreatedDate?: string,
): DashboardChartPoint[] => {
  const ranges = splitDateRange(dateRange.from || new Date(organizationCreatedDate!), dateRange.to || new Date());

  return ranges.map((date) => {
    const x0 = parseDateXValue(date);

    const point = points.find(({ x }) => x === x0);

    return {
      x: x0,
      y: point?.y || 0,
    };
  });
};

const convertToSeconds = (milliseconds: number) => round(milliseconds / 1000, 2);

export const mapData = (
  total: number,
  groups: GroupInformation[],
  dateRange: DateRange,
  yKey: string,
  organizationCreatedDate?: string,
) => {
  const mappedGroups = groups.map((group) => ({
    x: parseDateXValue(group.startDate),
    y: group[yKey],
  }));

  return {
    total,
    points: fillZeroDates(mappedGroups, dateRange, organizationCreatedDate),
  };
};

export const mapTimeData = (
  total: number,
  groups: GroupInformation[],
  dateRange: DateRange,
  yKey: string,
  organizationCreatedDate?: string,
) => {
  const mappedGroups = groups.map((group) => ({
    x: parseDateXValue(group.startDate),
    y: convertToSeconds(group[yKey]),
  }));

  return {
    total: convertToSeconds(total),
    points: fillZeroDates(mappedGroups, dateRange, organizationCreatedDate),
  };
}
