import { IFileState } from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/validateFileState';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import { Tag } from 'components/Tag';
import React, { FC } from 'react';
import styles from './FilesDetailsPopup.module.scss';
import { DataExtractionMode } from 'api/DataExtractionApi';
import FilesTable from 'components/ExtractionHistory/Forms/CreateDataExtractionForm/FilesDetailsPopup/FilesTable';

interface FilesDetailsPopupProps {
  filesState: Record<string, IFileState>;
  mode: DataExtractionMode;
  onClose: () => void;
  onDelete: (id: string) => void;
}

const FilesDetailsPopup: FC<FilesDetailsPopupProps> = ({
  filesState,
  mode,
  onClose,
  onDelete,
}) => {
  const files = Object.values(filesState);

  return (
    <PopUp title="Documents" closePopUp={onClose}>
      <PopUpContent className={styles.container}>
        <Tag className={styles.tag}>
          {files.length} Files
        </Tag>
        <FilesTable
          className={styles.table}
          files={files}
          mode={mode}
          onDelete={onDelete}
        />
      </PopUpContent>
    </PopUp>
  );
};

export default FilesDetailsPopup;
