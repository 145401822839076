import BaseSystemApi from './BaseApi';

export interface IPasswordConfirmationApi {
  confirmPassword: (password: string) => Promise<void>;
}

export default class PasswordConfirmationRestApi extends BaseSystemApi implements IPasswordConfirmationApi {
  protected resourceName = '/password-confirmation';

  public async confirmPassword(password: string) {
    await this.fetch(this.resourceName, 'POST', { password });
  }
}
