import React, { useCallback } from 'react'
import EventsFilters from './EventsFilters'
import { batch } from 'react-redux';
import { useDocumentTypeApi } from 'providers/ApiServiceProvider';
import { useDispatch } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import {
  changeFilters,
  clearFilters,
  hideFiltersPanel,
} from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/EventsDashboardStore';
import { ChangeFiltersPayload } from 'GeneralSettings/ui/WebhooksDashboards/EventsDashboard/Filters/EventsDashboardFiltersStore';
import { useSelector } from 'react-redux';
import { DocumentTypeSortingField } from 'api/DocumentTypeApi';

const EventsFiltersConnector = () => {
  const dispatch = useDispatch();
  const documentTypeApi = useDocumentTypeApi();
  const { filters } = useSelector((state: ReduxState) => state.generalSettings.ui.webhooksDashboards.eventsDashboard);

  const handleClearFilters = () => {
    batch(() => {
      dispatch(clearFilters());
      dispatch(hideFiltersPanel());
    })
  }

  const handleApplyFilters = (filtersToApply: ChangeFiltersPayload) => {
    batch(() => {
      dispatch(changeFilters(filtersToApply));
      dispatch(hideFiltersPanel());
    });
  }

  const handleCloseFiltersPanel = () => {
    dispatch(hideFiltersPanel());
  }

  const handleFetchDocumentTypesOptions = useCallback(
    async (search: string) => {
      const { items: documentTypes } = await documentTypeApi.search(
        { search },
        { field: DocumentTypeSortingField.Name, ascending: true },
      );

      return documentTypes.map((documentType) => ({
        name: documentType.name,
        value: documentType.id,
      }));
    },
    [],
  );

  return (
    <EventsFilters
      filters={filters}
      onApply={handleApplyFilters}
      onClear={handleClearFilters}
      onClose={handleCloseFiltersPanel}
      onFetchDocumentTypeOptions={handleFetchDocumentTypesOptions}
    />
  )
}

export default EventsFiltersConnector;
