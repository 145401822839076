import BaseSystemApi from './BaseApi';

export interface PublicApiKey {
  id: string;
  createdAt: Date;
  createdBy: string;
  lastUsedAt: number | null;
}

export interface GeneratedPublicApiKey extends PublicApiKey {
  apiKey: string;
}

export interface PublicApiKeyApi {
  getAll(): Promise<PublicApiKey[]>;
  create(): Promise<GeneratedPublicApiKey>;
  delete(id: string): Promise<void>;
}

export default class PublicApiKeyRestApi extends BaseSystemApi implements PublicApiKeyApi {
  protected resourceName = 'api-keys';

  public getAll(): Promise<PublicApiKey[]> {
    return this.getResources();
  }

  public create(): Promise<GeneratedPublicApiKey> {
    return this.createResource({});
  }

  public delete(id: string): Promise<void> {
    return this.deleteResource(id);
  }
}
