import { createReducer } from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import mountChildReducers from 'utils/childReducers';
import WebhooksActionType from './WebhooksActionType';
import webhooksEndpointsReducer, {
  getInitialState as getWebhooksEndpointsInitialState,
  EndpointsState,
} from './Endpoints/EndpointsStore';
import webhookEventsReducer, {
  getInitialState as getWebhookEventsInitialState,
  EventsState,
} from './Events/EventsStore';

export interface WebhooksState {
  endpoints: EndpointsState;
  events: EventsState;
}

export const getInitialState = (): WebhooksState => ({
  endpoints: getWebhooksEndpointsInitialState(),
  events: getWebhookEventsInitialState(),
});

const webhooksReducer = createReducer<WebhooksState>(
  getInitialState(),
  () => {},
);

const childReducers = {
  endpoints: webhooksEndpointsReducer,
  events: webhookEventsReducer,
};

export default withStateReset(
  mountChildReducers(webhooksReducer, childReducers),
  WebhooksActionType.ResetState,
  () => ({ ...getInitialState() }),
);
