import React, { ChangeEvent, FC, useState } from 'react';
import TextInput from 'components/TextInput';
import { validateEmail } from 'utils/validation/validation';
import Button from 'components/Button';
import styles from './ProvideEmailStep.module.scss';
export interface ProvideEmailStepProps {
  buttonText: string;
  initialEmail: string;
  name?: string;
  loading?: boolean;
  onSubmit: (email: string) => void;
}

const ProvideEmailStep: FC<ProvideEmailStepProps> = ({
  name,
  initialEmail,
  onSubmit,
  buttonText,
  loading,
}) => {
  const [email, setEmail] = useState(initialEmail);

  const isEmailInvalid = !!validateEmail(email);

  const handleEmailFieldValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <div className={styles.message}>Please provide {name ? `${name}'s` : 'your'} new email address.</div>
      <TextInput
        placeholder="email@domain.com"
        labelTitle="Email Address"
        value={email}
        required
        onChange={handleEmailFieldValueChange}
      />
      <Button
        className={styles.button}
        disabled={isEmailInvalid}
        size="form"
        isLoading={loading}
        onClick={() => onSubmit(email)}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ProvideEmailStep;
