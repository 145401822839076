import React, { CSSProperties, ComponentType } from 'react';
import Notification from 'components/Notification';
import { NotificationItem, NotificationComponents } from 'handlers/notification/notificationTypes';
import clsx from 'clsx';

import styles from './Notifications.module.scss';

export interface NotificationListProps<CustomNotificationComponents extends Record<string, ComponentType<any>>> {
  notificationList: NotificationItem[];
  hideNotification: (id: string) => void;
  customNotificationsComponents: CustomNotificationComponents;
  className?: string;
  style?: CSSProperties;
}

const DEFAULT_STYLE = {};

const NotificationList = <CustomNotificationComponents extends Record<string, ComponentType<any>>>({
  notificationList,
  hideNotification,
  className,
  style = DEFAULT_STYLE,
  customNotificationsComponents,
}: NotificationListProps<CustomNotificationComponents>) => {
  const containerClassName = clsx(styles.notificationList, className);

  const renderNotification = (notification: string | NotificationComponents) => {
    if (typeof notification === 'string') {
      return notification;
    }

    const NotificationComponent = customNotificationsComponents[notification.componentType];
    const notificationProps = notification.props || {};

    return <NotificationComponent {...notificationProps} />;
  };

  return (
    <div className={containerClassName} style={style}>
      {notificationList &&
        notificationList.map((item: NotificationItem, index: number) => {
          return (
            <Notification key={index} type={item.type} onClose={() => hideNotification(item.id)}>
              {renderNotification(item.notification)}
            </Notification>
          );
        })}
    </div>
  );
};

export default NotificationList;
