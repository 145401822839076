import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreatePublicApiWhitelistParams } from 'api/PublicApiWhitelistApi';
import PublicApiActionType from 'PublicApi/ActionTypes';
import { publicApiKeyApi, publicApiWhitelistApi } from 'stores/api';

export const getPublicApiKeys = createAsyncThunk(
  PublicApiActionType.GetAllApiKeys,
  async () => {
    return publicApiKeyApi.getAll();
  },
);

export const createPublicApiKey = createAsyncThunk(
  PublicApiActionType.CreateApiKey,
  async () => {
    return publicApiKeyApi.create();
  },
);

export const deletePublicApiKey = createAsyncThunk(
  PublicApiActionType.DeleteApiKey,
  async (id: string) => {
    return publicApiKeyApi.delete(id);
  },
);

export const getPublicApiWhitelists = createAsyncThunk(
  PublicApiActionType.GetAllWhitelists,
  async () => {
    return publicApiWhitelistApi.getAll();
  },
);

export const createPublicApiWhitelist = createAsyncThunk(
  PublicApiActionType.CreateApiWhitelist,
  async (whitelists: CreatePublicApiWhitelistParams[]) => {
    return publicApiWhitelistApi.create(whitelists);
  },
);

export const deletePublicApiWhitelist = createAsyncThunk(
  PublicApiActionType.DeleteApiWhitelist,
  async (id: string) => {
    return publicApiWhitelistApi.delete(id);
  },
);
