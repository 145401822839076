import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Switch, SwitchClassKey, SwitchProps, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface StyledSwitchProps extends SwitchProps {
  classes: Styles;
}

const StyledSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 38,
      height: 20,
      padding: 0,
      left: 1,
      margin: '8px 12px 8px 8px',
    },
    switchBase: {
      padding: 1,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(18px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#1960F6',
          opacity: 1,
          border: 'none',
        },
        '&$disabled': {
          color: '#A5D5FC',
        },
        '&$disabled + $track': {
          backgroundColor: '#E8F4FE',
          border: '1px solid #A5D5FC',
          color: '#A5D5FC',
        },
      },
      '&$focusVisible $thumb': {
        color: '#1e95f8',
        border: `6px solid ${theme.palette.common.white}`,
      },
      '&$disabled': {
        color: '#CFD3D9',
        '& + $track': {
          backgroundColor: '#F1F2F4',
          opacity: 1,
          border: '1px solid #E7E9EC',
        },
        '& > span > input': {
          opacity: 0,
        },
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 18 / 2,
      backgroundColor: '#CFD3D9',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    disabled: {},
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: StyledSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...props}
    />
  );
});

export default StyledSwitch;
