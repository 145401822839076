import React, { FC, useEffect } from 'react';
import ContextualViewContainer from 'components/ContextualViewContainer';
import MyAccountProfile from 'components/MyAccountProfile';
import { UpdateParams } from 'components/MyAccountProfile/PersonalDetails/PersonalDetails';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { closeMyAccount, getOriginalAvatar, openMyAccountPopup, updateAccountDetails } from 'MyAccount/ActionCreator';
import { MyAccountPopupType } from 'MyAccount/Types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';
import { getCurrentUser } from 'Users/Thunks';
import styles from './MyAccount.module.scss';

const MyAccount: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();

  const { avatar } = useSelector((state: ReduxState) => state.myAccount);
  const currentUser = useSelector((state: ReduxState) => state.users.currentUser);
  const sessionInfo = useSelector((state: ReduxState) => state.sessionInfo);

  useEffect(() => {
    if (!avatar && currentUser?.avatarId) {
      dispatchWithUnwrap(getOriginalAvatar());
    }
  }, [currentUser]);

  useEffect(() => {
    dispatchWithUnwrap(getCurrentUser());
  }, []);

  if (!currentUser) {
    return null;
  }

  const handleOnUpdate = async (params: UpdateParams) => {
    await dispatchWithUnwrap(updateAccountDetails({
      firstName: params.firstName,
      lastName: params.lastName,
    }));
  };

  const handleEditAvatarClick = () => {
    dispatch(openMyAccountPopup(MyAccountPopupType.UploadAvatar));
  };

  const openPopup = (type: MyAccountPopupType) => () => {
    dispatch(openMyAccountPopup(type));
  };

  return (
    <ContextualViewContainer onClose={() => dispatch(closeMyAccount())} className={styles.myAccountContextualViewContainer}>
      <MyAccountProfile
        user={currentUser}
        onUpdate={handleOnUpdate}
        onEditAvatarClick={handleEditAvatarClick}
        onChangeEmail={openPopup(MyAccountPopupType.ChangeEmail)}
        onChangePhone={openPopup(MyAccountPopupType.ChangePhone)}
        onDeletePhone={openPopup(MyAccountPopupType.DeletePhone)}
        onChangePassword={openPopup(MyAccountPopupType.ChangePassword)}
        isMfaVerified={!sessionInfo.user?.isMfaIncomplete}
      />
    </ContextualViewContainer>
  );
};

export default MyAccount;
