import { FC, useState } from 'react';
import { DocumentExtension } from 'enums/DocumentExtension';
import { DataExtractionStatus } from 'api/DataExtractionApi';
import ButtonWithImage from 'components/ButtonWithImage';
import clsx from 'clsx';
import ProcessingDocumentLoader, {
  ProcessingDocumentLoaderStatus,
} from 'components/ProcessingDocumentLoader/ProcessingDocumentLoader';
import FilePreviewConnector from 'components/FilePreview/FilePreviewConnector';
import styles from './DataExtractionResultDocumentPreview.module.scss';
import { PreviewMode } from 'components/FilePreview/FilePreview';
import { DataExtractionResult } from 'api/DataExtractionResultApi';

interface DataExtractionResultDocumentPreviewProps {
  dataExtractionResult: DataExtractionResult;
  className?: string;
  resizeContainerRef: React.RefObject<HTMLDivElement>;
}

const DataExtractionResultDocumentPreview: FC<DataExtractionResultDocumentPreviewProps> = ({
  dataExtractionResult,
  className,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
  };

  const handleFullScreen = () => {
    setIsFullScreen(true);
  };

  const handleDocumentLoad = () => {
    setIsDocumentLoading(false);
  };

  const isLoading = isDocumentLoading || dataExtractionResult.status === DataExtractionStatus.Processing;
  const isFullScreenViewDisabled = isLoading;

  const renderDisabledIcon = () => {
    return (
      <ProcessingDocumentLoader
        status={isDocumentLoading ? ProcessingDocumentLoaderStatus.Loading : ProcessingDocumentLoaderStatus.Analyzing}
      />
    )
  }

  const filePreviewClassName = clsx(
    styles.filePreview,
    !isLoading && !isFullScreen && styles.positionStatic,
    isFullScreen && styles.fullScreenPreview,
  );

  const renderPreview = () => {
    const defaultProps = {
      fileId: dataExtractionResult.id,
      fileExtension: dataExtractionResult.fileType as unknown as DocumentExtension,
      filename: `${dataExtractionResult.originalFileName}.${dataExtractionResult.fileType}`,
      className: filePreviewClassName,
      isLoading,
      loadingIcon: renderDisabledIcon(),
      isFullscreen: isFullScreen,
      onExitFullScreen: handleExitFullScreen,
      onDocumentLoad: handleDocumentLoad,
      onFullscreen: handleFullScreen,
    }

    return (
      <FilePreviewConnector
        {...defaultProps}
        mode={PreviewMode.Interactive}
      />
    )
  }

  const renderFullScreenButtonText = () => {
    return (
      <div className={styles.fullScreenButtonText}>Full-Screen <div className={styles.fullScreenButtonTextView}>View</div></div>
    );
  };

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.headerContainer}>
        <h5 className={styles.title}>Document Preview</h5>
        <ButtonWithImage
          title={renderFullScreenButtonText()}
          kind='expand'
          className={clsx(styles.fullScreenButton, isFullScreenViewDisabled && styles.disabledFullScreenButton)}
          disabled={isFullScreenViewDisabled}
          onClick={handleFullScreen}
        />
      </div>
      {renderPreview()}
    </div>
  )
}

export default DataExtractionResultDocumentPreview;
