import React, { FC } from 'react';
import { NoItems } from 'static/images';
import styles from './HomeDashboardChartNoData.module.scss';

interface HomeDashboardChartNoDataProps {
}

const HomeDashboardChartNoData: FC<HomeDashboardChartNoDataProps> = () => {
  return (
    <div className={styles.container}>
      <NoItems className={styles.image} />
      <div className={styles.message}>No document types have been used.</div>
    </div>
  );
};

export default HomeDashboardChartNoData;
