import {
  pagination as eventsDashboardPagination,
  eventsDashboardActionOrigin,
} from './EventsDashboardStore';
import { AnyAction, MiddlewareAPI, Dispatch, Middleware } from 'redux';
import { ReduxState } from 'types/ReduxState';
import { isFulfilled } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { WEBHOOK_EVENTS_PER_PAGE_DEFAULT } from './Pagination';
import { setWebhookEvents } from 'Webhooks/Events/EventsStore';
import { WebhookEventsApi } from 'api/WebhookEventsApi';
import { deleteWebhookEvent, getWebhookEvents } from 'Webhooks/Events/Thunks';

const createEventsDashboardMiddlewares = (
  api: WebhookEventsApi,
  pagination: typeof eventsDashboardPagination,
  path: string | string[],
  actionOrigins: string[],
) => {
  const paginationMiddleware = pagination.getItemsFetcherMiddleware(async (params, dispatch) => {
    const {
      search,
      perPage,
      page,
      sortingType,
      dueCreatedDateFrom,
      dueCreatedDateTo,
      eventTypes,
      statuses,
      webhookId,
    } = params;

    const { items, total } = await api.find({
      count: perPage,
      offset: (page - 1) * perPage,
      search,
      dueCreatedDateFrom,
      dueCreatedDateTo,
      eventTypes,
      statuses,
      webhookId,
    }, sortingType);

    dispatch(setWebhookEvents(items));

    return {
      items: items.map((documentType) => documentType.id),
      total,
    };
  });

  const updatesMiddleware = (({ dispatch, getState }: MiddlewareAPI<Dispatch<AnyAction>, ReduxState>) => (
    next: (action: AnyAction) => any,
  ) => (action: AnyAction) => {
    const result = next(action);

    if (
      !isFulfilled(deleteWebhookEvent)(action)
    ) {
      return result;
    }

    if (!action.meta.actionOrigin || !actionOrigins.includes(action.meta.actionOrigin)) {
      return result;
    }

    const state = getState();
    const { filters, sortingType } = get(state, path);

    dispatch(getWebhookEvents({
      filters: {
        search: filters.search,
        offset: 0,
        count: WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
        ...filters,
      },
      sortingType,
      actionOrigin: eventsDashboardActionOrigin,
    }) as unknown as AnyAction)

    return result;
  }) as Middleware;

  return {
    paginationMiddleware,
    updatesMiddleware,
  }
};

export default createEventsDashboardMiddlewares;
