import React from 'react';
import clsx from 'clsx';
import styles from './ModeSwitcherButton.module.scss';
import WrapperWithLink from 'components/WrapperWithLink';

interface KeyNameItem<ModeType = string> {
  key: ModeType;
  name: string;
}

interface ModeSwitcherButtonProps<ModeType = string> {
  modes: KeyNameItem<ModeType>[];
  mode: ModeType;
  onChange?: (mode: ModeType) => void;
  className?: string;
  disabled: boolean;
}

const ModeSwitcherButton = <ModeType extends string>({
  mode,
  disabled,
  modes,
  onChange,
  className,
}: ModeSwitcherButtonProps<ModeType>) => {
  const renderButton = ({ key, name }: KeyNameItem<ModeType>) => {
    const classes = clsx(
      styles.root__button,
      key === mode && styles.root__button__selected,
    );

    return (
      <WrapperWithLink onClick={() => !disabled && onChange?.(key)} className={classes}>
        {name}
      </WrapperWithLink>
    );
  };

  return (
    <div className={clsx(styles.root, className)}>
      {modes.map(renderButton)}
    </div>
  );
};

export default ModeSwitcherButton;
