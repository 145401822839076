import React, { ReactElement, Fragment, useEffect, useRef } from 'react';
import clsx from 'clsx';
import ScrollableTabs from 'components/ScrollableTabs';
import ButtonWithImage from 'components/ButtonWithImage';
import WrapperWithLink from 'components/WrapperWithLink';
import WrapperWithTooltip from 'components/Tooltip';
import ContextualPopupDropdownWrapper from 'components/ContextualPopupDropdownWrapper';
import { BurgerImage, RemoveTabImage } from 'static/images';
import StickyContainer from 'components/StickyContainer';
import styles from './TabSwitch.module.scss';

export enum TabLinkType {
  Default = 'default',
  Button = 'button',
  Link = 'link',
}

export interface TabSwitchOption<TabSwitchOptionLabelType = ReactElement | string> {
  label: TabSwitchOptionLabelType;
  id: string;
  type?: TabLinkType;
  link?: string;
  ref?: React.RefObject<HTMLButtonElement>;
  disableDelete?: boolean;
  onClick?: () => void;
}

interface TabSwitchProps<TabSwitchOptionLabelType> {
  tabs: TabSwitchOption<TabSwitchOptionLabelType>[];
  selectedTabId?: string;
  onSelect?: (tab: TabSwitchOption<TabSwitchOptionLabelType>) => void;
  className?: string;
  buttonsTabIndex?: number;
  allowTabDelete?: boolean;
  onTabDelete?: (tab: TabSwitchOption<TabSwitchOptionLabelType>) => void;
  tabClassName?: string;
  buttonTabClassName?: string;
  removeTabClassName?: string;
  tabsClassName?: string;
  borderContainerClassName?: string;
  hideBorder?: boolean;
  onTabButtonClick?: (tabId: string, defaultTabAction?: () => void) => void;
  sticky?: boolean;
  stickyRootElement?: HTMLElement;
  stickyTopOffset?: number;
  getTabLink?: (tab: TabSwitchOption<TabSwitchOptionLabelType>) => string;
  scrollToTabOnMount?: boolean;
}

const TabSwitch = <TabSwitchOptionType,>({
  tabs,
  selectedTabId,
  onSelect,
  onTabDelete,
  allowTabDelete,
  className,
  tabClassName,
  buttonsTabIndex,
  buttonTabClassName,
  removeTabClassName,
  borderContainerClassName,
  onTabButtonClick,
  hideBorder,
  sticky = false,
  stickyRootElement = document.body,
  stickyTopOffset = 0,
  getTabLink,
  tabsClassName,
  scrollToTabOnMount,
}: TabSwitchProps<TabSwitchOptionType>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = containerRef.current;

    if (!scrollToTabOnMount || !element || !selectedTabId) {
      return;
    }

    const tab = element.querySelector(`#${generateHTMLTabId(selectedTabId)}`);

    if (!tab) {
      return;
    }

    // scroll on next tick
    setTimeout(() => {
      tab.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    });
  }, [scrollToTabOnMount]);

  const renderTabLabel = (tab: TabSwitchOption<TabSwitchOptionType>) => <label htmlFor={tab.id}>{tab.label}</label>;

  const renderRemoveTabImage = (tab: TabSwitchOption<TabSwitchOptionType>) =>
    allowTabDelete && !tab.disableDelete ? (
      <div className={clsx(styles.closeImage, removeTabClassName)} onClick={() => onTabDelete?.(tab)}>
        <RemoveTabImage />
      </div>
    ) : null;

  const generateHTMLTabId = (tabId: string) => {
    return `switch-tab-${tabId}`;
  };

  const handleTabClick = (tab: TabSwitchOption<TabSwitchOptionType>) => {
    switch (tab.type) {
      case 'button': {
        return onTabButtonClick ? onTabButtonClick(tab.id, tab.onClick) : tab.onClick?.()
      }
      default: {
        return onSelect?.(tab);
      }
    }
  };

  const handleSelectTabItemInContextualPopup = (tab: TabSwitchOption<TabSwitchOptionType>) => {
    const element = containerRef.current;

    const tabElement = element?.querySelector(`#${generateHTMLTabId(tab.id)}`);

    if (tabElement) {
      tabElement.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    }

    handleTabClick(tab);
  };

  return (
    <StickyContainer
      ref={containerRef}
      sticky={sticky}
      stickyTopOffset={stickyTopOffset}
      stickyRootElement={stickyRootElement}
      className={className}
    >
      {({ isStuck }) => (
        <>
          <ScrollableTabs
            className={clsx(styles.tabs, tabsClassName)}
            scrollableElementsClassName={styles.scrollableElements}
            scrollableTabContainerClassName={styles.scrollableTabContainer}
            additionalScrollElements={(
              <ContextualPopupDropdownWrapper<SVGSVGElement, TabSwitchOption<TabSwitchOptionType>>
                title="All Tabs"
                items={tabs}
                selectedItemId={selectedTabId}
                onSelectItem={handleSelectTabItemInContextualPopup}
                closeOnSelect
              >
                {(burgerImageRef, setShowAllTabs) => (
                  <WrapperWithTooltip tooltip="View all tabs">
                    <BurgerImage
                      onClick={() => setShowAllTabs(true)}
                      ref={burgerImageRef}
                      className={styles.viewAllTabsButton}
                    />
                </WrapperWithTooltip>
                )}
              </ContextualPopupDropdownWrapper>
            )}
          >
            {tabs.map((tab) => (
              <Fragment key={tab.id}>
                {(!tab.type || tab.type === 'default') && (
                  <div id={generateHTMLTabId(tab.id)} className={clsx(styles.tab, tabClassName)}>
                    <div className={clsx(styles.defaultTabContent, selectedTabId === tab.id && styles.checked)}>
                      <input
                        type="radio"
                        id={tab.id}
                        name="switch"
                        checked={selectedTabId === tab.id}
                        onChange={() => handleTabClick(tab)}
                      />
                      {renderTabLabel(tab)}
                      {renderRemoveTabImage(tab)}
                    </div>
                  </div>
                )}
                {tab.type === 'button' && (
                  <div id={generateHTMLTabId(tab.id)} className={clsx(styles.tab, buttonTabClassName)} key={tab.id}>
                    <label className={styles.addTabButton}>
                      <ButtonWithImage
                        ref={tab.ref}
                        title={tab.label as unknown as ReactElement | string}
                        kind="add"
                        onClick={() => handleTabClick(tab)}
                        tabIndex={buttonsTabIndex}
                      />
                    </label>
                  </div>
                )}
                {tab.type === 'link' && (
                  <WrapperWithLink
                    id={generateHTMLTabId(tab.id)}
                    className={clsx(styles.tab, tabClassName)}
                    link={tab.link || getTabLink?.(tab)}
                    onClick={() => handleTabClick(tab)}
                  >
                    <div className={clsx(styles.defaultTabContent, selectedTabId === tab.id && styles.checked)}>
                      {renderTabLabel(tab)}
                      {renderRemoveTabImage(tab)}
                    </div>
                  </WrapperWithLink>
                )}
              </Fragment>
            ))}
          </ScrollableTabs>
          {!hideBorder && !isStuck && (
            <div className={clsx(styles.borderContainer, borderContainerClassName)}>
              <div className={styles.border} />
            </div>
          )}
        </>
      )}
    </StickyContainer>
  );
};

export default TabSwitch;
