import { useState, FocusEvent, useMemo } from 'react';

type InputValidator = (value: string) => string;

export interface InputValidationOptions<
  Element extends HTMLElement = HTMLInputElement,
  FocusEventType = FocusEvent<Element>,
> {
  value: string;
  validators?: InputValidator[];
  onFocus?: (event: FocusEventType) => void;
  onBlur?: (event?: FocusEvent<Element>) => void;
  required?: boolean;
  disabledValidation?: boolean;
}

export const validateRequiredField = (value: string) => {
  return !value.trim() ? 'This field is required' : '';
};

const validateField = (validators: InputValidator[], value: string) => {
  for (const validator of validators) {
    const result = validator(value);

    if (result) {
      return result;
    }
  }

  return '';
};

const useInputValidation = <Element extends HTMLElement = HTMLInputElement, FocusEventType = FocusEvent<Element>>({
  value,
  validators,
  onBlur,
  onFocus,
  disabledValidation,
  required,
}: InputValidationOptions<Element, FocusEventType>): [
  (event?: FocusEvent<Element>) => void,
  (event: FocusEventType) => void,
  string,
  boolean,
  () => void,
  () => void,
] => {
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  const memoizedValidators = useMemo(() => {
    return [...(required ? [validateRequiredField] : []), ...validators || []];
  }, [validators, required]);

  const validationResult = useMemo(() => {
    if (!required && !value) {
      return '';
    }

    return !disabledValidation ? validateField(memoizedValidators, value) : '';
  }, [value, memoizedValidators, disabledValidation, required]);

  const showErrorMessage = () => {
    setDisplayErrorMessage(true);
  };

  const hideErrorMessage = () => {
    setDisplayErrorMessage(false);
  };

  const handleBlur = (event?: FocusEvent<Element>): void => {
    showErrorMessage();

    onBlur?.(event);
  };

  const handleFocus = (event: FocusEventType): void => {
    setDisplayErrorMessage(false);

    onFocus?.(event);
  };

  return [
    handleBlur,
    handleFocus,
    displayErrorMessage ? validationResult : '',
    !validationResult,
    showErrorMessage,
    hideErrorMessage,
  ];
};

export default useInputValidation;
