import React, { forwardRef, DetailedHTMLProps, TextareaHTMLAttributes } from 'react';
import { TextareaAutosize as MuiTextareaAutosize } from '@material-ui/core';

export type TextareaAutosizeProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  maxRows?: number;
  minRows?: number;
};

const DEFAULT_TEXT_AREA_MAX_ROWS = 10;

const TextareaAutosize = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(({ maxRows, minRows, ...props }, ref) => {
  return <MuiTextareaAutosize {...props} maxRows={maxRows || DEFAULT_TEXT_AREA_MAX_ROWS} minRows={minRows} ref={ref} />;
});

export default TextareaAutosize;
