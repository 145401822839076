import BaseSystemApi from './BaseApi';
import { DateRange } from './Types';

export interface PerformanceOverviewDocumentType {
  id: string;
  documentType: {
    id: string;
    name: string;
  };
  pagesCount: number;
}

interface PerformanceOverviewGroup {
  startDate: string;
  filesProcessed: number;
  pagesProcessed: number;
  avgProcessingTime: number;
}

export interface PerformanceOverviewInformation {
  totalFilesProcessed: number;
  totalPagesProcessed: number;
  totalAvgProcessingTime: number;
  groups: PerformanceOverviewGroup[];
  organizationCreatedAt?: string;
}

export interface PerformanceOverviewApi {
  getDocumentTypes(dateRange: DateRange): Promise<PerformanceOverviewDocumentType[]>;
  getPerformanceOverview(dateRange: DateRange): Promise<PerformanceOverviewInformation>;
}

export default class PerformanceOverviewRestApi extends BaseSystemApi implements PerformanceOverviewApi {
  protected resourceName = '/performance-overview';

  public async getDocumentTypes(dateRange: DateRange) {
    const params = new URLSearchParams();

    if (dateRange?.from) {
      params.append('dateFrom', dateRange.from.toISOString());
    }

    if (dateRange?.to) {
      params.append('dateTo', dateRange.to.toISOString());
    }

    return this.fetch<PerformanceOverviewDocumentType[]>(this.resourceName + `/document-types?${params}`, 'GET');
  }

  public async getPerformanceOverview(dateRange: DateRange): Promise<PerformanceOverviewInformation> {
    const params = new URLSearchParams();

    if (dateRange?.from) {
      params.append('dateFrom', dateRange.from.toISOString());
    }

    if (dateRange?.to) {
      params.append('dateTo', dateRange.to.toISOString());
    }

    params.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    return this.fetch<PerformanceOverviewInformation>(this.resourceName + `?${params}`, 'GET');
  }
}
