import React, { FC, ChangeEvent, useState, useRef } from 'react';
import TopRightButton from 'components/TopRightInputButton';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import TimerMessage from 'TimerMessage/TimeMessage';
import AuthButton from '../Inputs/AuthButton/AuthButton';
import AuthInput from '../Inputs/AuthInput/AuthInput';

import styles from './SecurePhoneAuthenticationForm.module.scss';

interface SecurePhoneAuthenticationFormProps {
  onResendCode: () => void;
  resendInSeconds: number;
  onSubmit: (code: string) => Promise<void>;
  buttonText: string;
}

const CODE_LENGTH = 6;

const SecurePhoneAuthenticationForm: FC<SecurePhoneAuthenticationFormProps> = ({
  resendInSeconds,
  onResendCode,
  onSubmit,
  buttonText,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [code, setCode] = useState('');

  const topRightElement = (
    <TopRightButton disabled={resendInSeconds > 0} onClick={onResendCode}>
      Resend Code
    </TopRightButton>
  );

  const submitIsDisabled = code.length !== CODE_LENGTH;

  return (
    <div className={styles.container}>
      <AuthInput
        labelTitle="Authentication Code"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
        placeholder={`${CODE_LENGTH} digits`}
        topRightElement={topRightElement}
        containerClassName={styles.codeInput}
        value={code}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        autoFocus
        required
      />
      {!!resendInSeconds && (
        <TimerMessage
          className={styles.timerMessage}
        >
          You can request another code in <strong>{resendInSeconds}s</strong>
        </TimerMessage>
      )}
      <AuthButton
        kind="primary"
        size="form"
        onClick={() => onSubmit(code)}
        type="submit"
        fullWidth
        className={styles.submitButton}
        disabled={submitIsDisabled}
        ref={submitButtonRef}
      >
        {buttonText}
      </AuthButton>
    </div>
  );
};

export default SecurePhoneAuthenticationForm;
