import React, { FC } from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import {
  WebhookEvent,
  WebhookEventSortingField,
  WebhookEventSortingType,
} from 'api/WebhookEventsApi';
import useSorting from 'hooks/useSorting';
import Table, { TableHead, TableHeadCell, TableBody, TableActionCell, TableBodyContent } from 'components/Table';
import Pagination from 'components/Pagination';
import WebhookEventItemRow from './EventItemRow';
import { WebhookEventColumnSize, WebhookEventWithEndpointColumnSize } from './TableColumnSize';
import SkeletonWebhookEventItemRow from './SkeletonEventItemRow';

interface EventsTableProps {
  source: NullableItems<WebhookEvent>;
  paginationProps: PaginationProps;
  sortingType: WebhookEventSortingType;
  searchInputValue: string;
  onSort: (field: WebhookEventSortingField, ascending: boolean) => void;
  onRowClick: (webhookEvent: WebhookEvent) => void;
  onDelete: (id: string) => void;
  onResend: (id: string) => void;
  withEndpointColumn?: boolean;
}

const EventsTable: FC<EventsTableProps> = ({
  source,
  paginationProps,
  sortingType,
  searchInputValue,
  onSort,
  onRowClick,
  onDelete,
  onResend,
  withEndpointColumn,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderSkeletonTableRow = (index: number) => (
    <SkeletonWebhookEventItemRow withEndpointColumn={withEndpointColumn} key={index} />
  );

  const renderTableRow = (item: WebhookEvent) => {
    return (
      <WebhookEventItemRow
        key={item.id}
        searchInputValue={searchInputValue}
        webhookEvent={item}
        onClick={() => onRowClick(item)}
        onDelete={onDelete}
        onResend={onResend}
        withEndpointColumn={withEndpointColumn}
      />
    );
  };

  const ColumnSize = withEndpointColumn ? WebhookEventWithEndpointColumnSize : WebhookEventColumnSize;

  return (
    <>
      <Table>
        <TableHead sticky>
          {withEndpointColumn && (
            <TableHeadCell
              width={WebhookEventWithEndpointColumnSize.Endpoint}
              ascending={getSortingType(WebhookEventSortingField.Endpoint)}
              onClick={() => changeSorting(WebhookEventSortingField.Endpoint)}
            >
              Endpoint
            </TableHeadCell>
          )}
          <TableHeadCell
            width={ColumnSize.DocumentType}
          >
            Document Type
          </TableHeadCell>
          <TableHeadCell
            width={ColumnSize.EventType}
            ascending={getSortingType(WebhookEventSortingField.EventType)}
            onClick={() => changeSorting(WebhookEventSortingField.EventType)}
          >
            Event Type
          </TableHeadCell>
          <TableHeadCell
            width={ColumnSize.EventDate}
            ascending={getSortingType(WebhookEventSortingField.CreatedAt)}
            onClick={() => changeSorting(WebhookEventSortingField.CreatedAt)}
          >
            Event Date
          </TableHeadCell>
          <TableHeadCell
            ascending={getSortingType(WebhookEventSortingField.Status)}
            onClick={() => changeSorting(WebhookEventSortingField.Status)}
            width={ColumnSize.Status}
          >
            Status
          </TableHeadCell>
          <TableHeadCell
            width={ColumnSize.LastRunAt}
            ascending={getSortingType(WebhookEventSortingField.LastRunAt)}
            onClick={() => changeSorting(WebhookEventSortingField.LastRunAt)}
          >
            Last Run
          </TableHeadCell>
          <TableHeadCell
            width={ColumnSize.NextRunAt}
            ascending={getSortingType(WebhookEventSortingField.NextRunAt)}
            onClick={() => changeSorting(WebhookEventSortingField.NextRunAt)}
          >
            Next Run
          </TableHeadCell>
          <TableActionCell />
        </TableHead>
        <TableBody>
          <TableBodyContent
            renderSkeletonTableRow={renderSkeletonTableRow}
            renderTableRow={renderTableRow}
            rows={source}
          />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </>
  );
};

export default EventsTable;
