import React, { FC } from 'react';
import PersonalDetails from 'components/MyAccountProfile/PersonalDetails';
import { UpdateParams } from 'components/MyAccountProfile/PersonalDetails/PersonalDetails';
import UserLoginDetails from 'components/MyAccountProfile/UserLoginDetails';
import { User } from 'api/UserApi';
import styles from './MyAccountProfile.module.scss';

interface MyAccountProfileProps {
  user: User;
  onUpdate: (params: UpdateParams) => Promise<void>;
  onEditAvatarClick: () => void;
  onChangeEmail: () => void;
  onChangePhone: () => void;
  onDeletePhone: () => void;
  onChangePassword: () => void;
  isMfaVerified?: boolean;
}

const MyAccountProfile: FC<MyAccountProfileProps> = ({
  user,
  onUpdate,
  onEditAvatarClick,
  onChangeEmail,
  onChangePhone,
  onDeletePhone,
  onChangePassword,
  isMfaVerified,
}) => {
  return (
    <div>
      <div className={styles.header}>My Account</div>
      <PersonalDetails
        user={user}
        className={styles.block}
        onUpdate={onUpdate}
        onEditAvatarClick={onEditAvatarClick}
      />
      <UserLoginDetails
        user={user}
        className={styles.block}
        onChangeEmail={onChangeEmail}
        onChangePhone={onChangePhone}
        onDeletePhone={onDeletePhone}
        onChangePassword={onChangePassword}
        isMfaVerified={isMfaVerified}
      />
    </div>
  );
};

export default MyAccountProfile;
