import BaseApi from 'api/BaseApi';
import { DownloadResponse } from './APIRest';

export enum ExportFileType {
  Json = 'json',
  Csv = 'csv',
}

interface ExportParams {
  exportFileType?: ExportFileType;
  resultId?: string;
}

export interface DataExtractionExportApi {
  export(dataExtractionId: string, params: ExportParams): Promise<DownloadResponse>;
}

export default class DataExtractionExportRestApi extends BaseApi implements DataExtractionExportApi {
  protected resourceName = 'data-extraction-export';

  public async export(dataExtractionId: string, params?: ExportParams) {
    const urlSearchParams = new URLSearchParams();

    if (params?.exportFileType) {
      urlSearchParams.append('exportFileType', params.exportFileType);
    }

    if (params?.resultId) {
      urlSearchParams.append('resultId', params.resultId);
    }

    return await this.download(`/${this.resourceName}/${dataExtractionId}?${urlSearchParams}`);
  }
}
