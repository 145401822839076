import React, { FC } from 'react';
import InviteUserForm, { InviteUserFormProps } from 'components/Users/Forms/InviteUserForm/InviteUserForm';
import ContextualViewContainer from 'components/ContextualViewContainer';
import Header from 'components/Base/Header';

export interface InviteUserContextualViewProps extends InviteUserFormProps {
  onClose: () => void;
}

const InviteUserContextualView: FC<InviteUserContextualViewProps> = ({
  onClose,
  ...restProps
}) => {
  return (
    <ContextualViewContainer minimizeSize onClose={onClose}>
      <Header type="h2">Add User</Header>
      <InviteUserForm {...restProps} />
    </ContextualViewContainer>
  );
};

export default InviteUserContextualView;
