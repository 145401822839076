import React, { FC, useState } from 'react';
import FilterLayout from 'components/FilterLayout';
import Button from 'components/Button';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import { PermissionGroup } from 'enums/PermissionGroup';
import styles from 'components/Users/Filters/UsersFilters/UsersFilter.module.scss';

const PHONE_AUTHENTICATION_OPTIONS: Option[] = [
  {
    name: 'Enabled',
    value: 'true',
  },
  {
    name: 'Disabled',
    value: 'false',
  },
];

export interface ApplyUsersFiltersPayload {
  permissionGroup: PermissionGroup | undefined;
  isMfaEnabled: boolean | undefined;
}

export interface UsersFilterProps {
  permissionGroups: Option[];
  permissionGroup: PermissionGroup | undefined;
  isMfaEnabled: boolean | undefined;
  onApply: (params: ApplyUsersFiltersPayload) => void;
  onClose: () => void;
  onClear: () => void;
}

const UsersFilter: FC<UsersFilterProps> = ({
  onClear,
  onClose,
  onApply,
  permissionGroups,
  permissionGroup: derivedPermissionGroup,
  isMfaEnabled: derivedIsMfaEnabled,
}) => {
  const [
    permissionGroup,
    setPermissionGroup,
  ] = useState(derivedPermissionGroup);
  const [
    isMfaEnabled,
    setIsMfaEnabled,
  ] = useState(derivedIsMfaEnabled);

  const handlePermissionGroupInputValueChange = (option: Option) => {
    const value = option.value ? option.value as PermissionGroup : undefined;

    setPermissionGroup(value);
  };

  const handlePhoneAuthenticationInputValueChange = (option: Option) => {
    setIsMfaEnabled(option.value ? option.value === 'true' : undefined);
  };

  const handleApplyFiltersButtonClick = () => {
    onApply({
      permissionGroup,
      isMfaEnabled,
    });
  };

  const areFiltersChanged = derivedPermissionGroup !== permissionGroup
    || derivedIsMfaEnabled !== isMfaEnabled;

  return (
    <FilterLayout
      onClearFiltersClick={onClear}
      onClose={onClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        className={styles.permissionGroupSelect}
        labelTitle="Permissions Group"
        onChange={handlePermissionGroupInputValueChange}
        value={permissionGroup}
        options={permissionGroups}
      />
      <AutoCompletion
        labelTitle="Phone Authentication"
        onChange={handlePhoneAuthenticationInputValueChange}
        value={isMfaEnabled?.toString()}
        options={PHONE_AUTHENTICATION_OPTIONS}
      />
      <Button
        kind="primary"
        size="form"
        className={styles.applyFilterButton}
        disabled={!areFiltersChanged}
        onClick={handleApplyFiltersButtonClick}
      >
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default UsersFilter;
