import BaseApi from 'api/BaseApi';
import { DownloadResponse } from './APIRest';
import { DataFilter, DataViewSortingType, TableViewData } from './Types';

export interface DataExtractionResult {
  id: string;
  documentType?: {
    id: string;
    name: string;
  };
  status: DataExtractionStatus;
  fileName: string;
  originalFileName: string;
  fileType: DataExtractionFileType;
  totalDurationTime?: number;
  pagesCount?: number;
  data: string;
  dataExtractionId: string;
  createdBy?: string | null;
  createdAt: Date;
  mode: DataExtractionMode;
  isAutoDetected: boolean;
}

export enum DataExtractionMode {
  Quality = 'quality',
  Speed = 'speed',
}

export enum DataExtractionFileType {
  Pdf = 'pdf',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Bmp = 'bmp',
  Webp = 'webp',
}

export enum DataExtractionStatus {
  Passed = 'passed',
  Failed = 'failed',
  Processing = 'processing',
  Undetected = 'undetected',
}

export enum DataExtractionResultSortingField {
  FileName = 'fileName',
  FileType = 'fileType',
  PagesCount = 'pagesCount',
}

export interface DataExtractionResultFilters extends DataFilter {
  dataExtractionId: string | null;
  id?: string | null;
}

export type DataExtractionResultsSortingType = DataViewSortingType<DataExtractionResultSortingField>

export interface DataExtractionResultApi {
  search(
    filters: DataExtractionResultFilters,
    sortingType?: DataExtractionResultsSortingType,
  ): Promise<TableViewData<DataExtractionResult>>;
  getPublicLink(id: string): Promise<string>;
  findById(id: string): Promise<DataExtractionResult>;
  download(id: string): Promise<DownloadResponse>;
}

export default class DataExtractionRestApi extends BaseApi implements DataExtractionResultApi {
  protected resourceName = 'data-extraction-results';

  public async search(
    filters: DataExtractionResultFilters,
    sortingType?: DataExtractionResultsSortingType,
  ): Promise<TableViewData<DataExtractionResult>> {
    const urlSearchParams = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters.dataExtractionId) {
      urlSearchParams.append('dataExtractionId', filters.dataExtractionId);
    }

    if (filters.id) {
      urlSearchParams.append('id', filters.id);
    }

    return this.getResources<TableViewData<DataExtractionResult>>(urlSearchParams);
  }

  public async delete(id: string): Promise<void> {
    return this.deleteResource(id);
  }

  public async findById(id: string): Promise<DataExtractionResult> {
    return this.getResourceById<DataExtractionResult>(id);
  }

  public async getPublicLink(id: string):Promise<string> {
    const { token } = await this.fetch<{ token: string; }>(
      `/${this.resourceName}/${id}/preview`,
      'GET',
    );

    const params = new URLSearchParams();

    params.set('token', token);

    return `${this.absoluteOrigin}/document-preview?${params}`;
  }

  public async download(id: string): Promise<DownloadResponse> {
    return super.download(`/${this.resourceName}/${id}/download`);
  }
}
