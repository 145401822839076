import { FC } from 'react'
import { MobileTableColumnSize } from '../TableColumnSize';
import { TableBodyCell, TableRow } from 'components/Table';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';

import styles from './SkeletonDataExtractionMobileRow.module.scss';

interface SkeletonDataExtractionMobileRowProps {
  className?: string;
}

const SkeletonDataExtractionMobileRow: FC<SkeletonDataExtractionMobileRowProps> = ({
  className,
}) => {
  return (
    <TableRow disableHover className={className}>
      <TableBodyCell width={MobileTableColumnSize.Date} noPadding className={styles.userAvatarCell}>
        <div className={styles.userAvatarContainer}>
          <SkeletonCircle width="28px" height="28px" color="primary6" marginRight="8px" />
          <SkeletonRectangle width="80%" height="16px" color="primary6" />
        </div>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={MobileTableColumnSize.Result} className={styles.resultCell}>
        <SkeletonRectangle className={styles.status} width="80%" height="16px" color="primary6" />
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
    </TableRow>
  )
}

export default SkeletonDataExtractionMobileRow;
