import { OrganizationSubscriptionStatus } from 'eventHandlers/AuthEventEmitter';
import { AuthHandler, isLoggedIn } from 'AuthHandler';
import { History, createLocation, locationsAreEqual, Location } from 'history';
import { matchPath } from 'react-router';
import RouteBuilder, { AppRoutes } from 'routes/RouteBuilder';
import { ReduxState } from 'types/ReduxState';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { tap } from 'lodash';
import joinUrls from 'url-join';

export const AUTHENTICATION_ROUTES = [
  AppRoutes.auth.signIn,
  AppRoutes.auth.mfa,
  AppRoutes.auth.emailVerification,
  AppRoutes.auth.inactiveUser,
  AppRoutes.auth.cancelledSubscription,
  AppRoutes.auth.pastDueSubscription,
  AppRoutes.auth.createAccount,
];

const NO_AUTH_REQUIRED_ROUTES = [
  AppRoutes.auth.signIn,
  AppRoutes.auth.forgotPassword,
  AppRoutes.auth.resetPassword,
  AppRoutes.auth.createAccount,
  AppRoutes.auth.loggedOut,
];

const SHOULD_SKIP_REDIRECTS_ROUTES = [AppRoutes.auth.acceptInvite];

const ACTION_TYPE_SUBSCRIBE_TO_HISTORY_CHANGE = Symbol('ACTION_TYPE_SUBSCRIBE_TO_HISTORY_CHANGE');

export const subscribeToHistoryChange = () => ({
  type: ACTION_TYPE_SUBSCRIBE_TO_HISTORY_CHANGE,
});

const RedirectionsMiddleware = (
  authHandler: AuthHandler,
  history: History,
) => (({ getState }: MiddlewareAPI<Dispatch<AnyAction>, ReduxState>) => (next: Dispatch<AnyAction>) => {
  let routeBuilder = new RouteBuilder(history, history.location);

  const redirect = (...path: string[]) => {
    const nextLocation = createLocation(joinUrls(path));

    const isNewLocation = !locationsAreEqual({
      ...history.location,
      key: undefined,
    }, nextLocation);

    if (isNewLocation) {
      routeBuilder.goToLocation(nextLocation);
    }
  };

  const handleRedirectToDashboard = () => {
    if (!isLoggedIn()) {
      return;
    }

    redirect(AppRoutes.extractData);
  };

  const handleRedirects = (location: Location) => {
    routeBuilder = new RouteBuilder(history, location);

    const {
      sessionInfo: { user, organization },
      users,
    } = getState();

    const accountDetails = users.currentUser;

    if (matchPath(routeBuilder.currentPathname, { path: SHOULD_SKIP_REDIRECTS_ROUTES })) {
      return;
    }

    if (!authHandler.isLoggedIn() && !matchPath(routeBuilder.currentPathname, { path: NO_AUTH_REQUIRED_ROUTES })) {
      redirect(AppRoutes.auth.signIn);
      return;
    }

    if (!user || !accountDetails?.id) {
      return;
    }

    const { isMfaIncomplete, isEmailNotVerified, isActive } = user;

    if (isMfaIncomplete) {
      redirect(AppRoutes.auth.mfa);
      return;
    }

    if (isEmailNotVerified) {
      redirect(AppRoutes.auth.emailVerification);
      return;
    }

    if (!isActive) {
      redirect(AppRoutes.auth.inactiveUser);
      return;
    }

    if (
      organization?.subscriptionStatus === OrganizationSubscriptionStatus.Canceled ||
      organization?.subscriptionStatus === OrganizationSubscriptionStatus.IncompleteExpired
    ) {
      redirect(AppRoutes.auth.cancelledSubscription);
      return;
    }

    if (organization?.subscriptionStatus === OrganizationSubscriptionStatus.Unpaid) {
      redirect(AppRoutes.auth.pastDueSubscription);
      return;
    }

    if (matchPath(routeBuilder.currentPathname, { path: AUTHENTICATION_ROUTES }) || routeBuilder.currentPathname === '/') {
      handleRedirectToDashboard();
    }
  }

  handleRedirects(history.location);

  return (action: AnyAction) => {
    if (action.type === ACTION_TYPE_SUBSCRIBE_TO_HISTORY_CHANGE) {
      /**
       * It is important to subscribe to history changes after ReactRouter does,
       * otherwise synchronous calls to push()/replace() from the history.listen callback are silently swallowed
       * without triggering a re-render which leads to the UI state not being consistent with the address bar.
       */
      history.listen(handleRedirects);
      return action;
    }
    return tap(next(action), () => handleRedirects(history.location));
  };
}) as Middleware;

export default RedirectionsMiddleware;
