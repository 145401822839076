import React from 'react';
import clsx from 'clsx';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonColorType from 'components/Skeleton/SkeletonColorType';

import styles from './SkeletonCircle.module.scss';

interface SkeletonCircleProps {
  width: string;
  height: string;
  color: SkeletonColorType;
  className?: string;
  stopAnimation?: boolean;
  marginLeft?: string;
  marginRight?: string;
}

const SkeletonCircle = ({ width, height, color, className, stopAnimation, marginLeft, marginRight }: SkeletonCircleProps) => {
  return (
    <SkeletonRectangle
      stopAnimation={stopAnimation}
      width={width}
      height={height}
      color={color}
      className={clsx(styles.skeletonCircle, className)}
      marginLeft={marginLeft}
      marginRight={marginRight}
    />
  );
};

export default SkeletonCircle;
