import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import DocumentTypeForm from 'components/DocumentType/Forms/DocumentTypeForm/DocumentTypeForm';
import { DocumentType } from 'api/DocumentTypeApi';

interface DocumentTypePopupProps<DocumentTypeData> {
  title: string;
  isActionOnProgress: boolean;
  submitButtonTitle: string;
  usePortal?: boolean;
  documentType?: DocumentType;
  message?: string;
  onClose: () => void;
  onSubmit: (params: DocumentTypeData) => void;
}

const DocumentTypePopup = <DocumentTypeData,>({
  title,
  isActionOnProgress,
  usePortal = false,
  documentType,
  submitButtonTitle,
  message,
  onClose,
  onSubmit,
}: DocumentTypePopupProps<DocumentTypeData>) => {
  return (
    <PopUp
      title={title}
      closable={!isActionOnProgress}
      closePopUp={onClose}
      usePortal={usePortal}
    >
      <PopUpContent hasTopMargin>
        <DocumentTypeForm
          data={{
            name: documentType?.name,
            id: documentType?.id,
          }}
          message={message}
          submitButtonTitle={submitButtonTitle}
          isActionOnProgress={isActionOnProgress}
          onSubmit={async (data) => await onSubmit(data as DocumentTypeData)}
        />
      </PopUpContent>
    </PopUp>
  )
}

export default DocumentTypePopup;
