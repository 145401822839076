import { SignInData } from 'api/AuthApi';
import { validateEmail } from 'utils/validation/validation';

export type ValidateSignInFormResult = Partial<Record<keyof SignInData, string>>;

const validateSignInForm = (data: Omit<SignInData, 'token'>): ValidateSignInFormResult => {
  const errors: ValidateSignInFormResult = {};

  const emailError = validateEmail(data.email);

  if (emailError) {
    errors.email = emailError;
  }

  if (data.password.length === 0) {
    errors.password = 'Your password is required';
  }

  return errors;
};

export default validateSignInForm;
