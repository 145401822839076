import { createReducer } from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import mountChildReducers from 'utils/childReducers';
import WebhooksDashboardsActionType from './WebhooksDashboardsActionType';
import endpointsDashboardReducer, {
  getInitialState as getEndpointsDashboardInitialState,
} from './EndpointsDashboard/EndpointsDashboardStore';
import eventsDashboardReducer, {
  getInitialState as getEventsDashboardInitialState,
} from './EventsDashboard/EventsDashboardStore';
import { DashboardState } from 'DashboardSlice/createDashboardSlice';
import { WebhookEndpointSortingType } from 'api/WebhookEndpointsApi';
import { EventsDashboardFiltersState } from './EventsDashboard/Filters/EventsDashboardFiltersStore';
import { WebhookEventSortingType } from 'api/WebhookEventsApi';
import { BaseDashboardFiltersState } from 'DashboardSlice/createDashboardFiltersSlice';
import endpointDetailsReducer, {
  WebhookEndpointDetailsState,
  getInitialState as getEndpointDetailsInitialState,
} from './EndpointDetails/EndpointDetailsStore';

export interface WebhooksDashboardsState {
  endpointsDashboard: DashboardState<BaseDashboardFiltersState, WebhookEndpointSortingType>;
  eventsDashboard: DashboardState<EventsDashboardFiltersState, WebhookEventSortingType>;
  endpointDetails: WebhookEndpointDetailsState;
}

export const getInitialState = (): WebhooksDashboardsState => ({
  endpointsDashboard: getEndpointsDashboardInitialState(),
  eventsDashboard: getEventsDashboardInitialState(),
  endpointDetails: getEndpointDetailsInitialState(),
});

const webhooksDashboardsReducer = createReducer<WebhooksDashboardsState>(
  getInitialState(),
  () => {},
);

const childReducers = {
  endpointsDashboard: endpointsDashboardReducer,
  eventsDashboard: eventsDashboardReducer,
  endpointDetails: endpointDetailsReducer,
};

export default withStateReset(
  mountChildReducers(webhooksDashboardsReducer, childReducers),
  WebhooksDashboardsActionType.ResetState,
  () => ({ ...getInitialState() }),
);
