import moment, { Moment } from 'moment';

export const getStartOfTheDay = (date: Date) => {
  return moment(date).startOf('day').toDate();
};

export const getEndOfTheDay = (date: Date) => {
  return moment(date).endOf('day').toDate();
};

interface DateTimeDurationObject {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
  years?: number;
  months?: number;
  weeks?: number;
}

type DateTimeUnit = 'day' | 'week' | 'month' | 'year' | 'hour' | 'minute' | 'second' | 'millisecond';

export default class DateTimeUtils {
  private rawDate: Moment;

  constructor(date?: string | Date | number, formatString?: string, utc?: boolean) {
    if (!utc) {
      this.rawDate = date ? this.parseDate(date, formatString) : moment();

      return;
    }

    this.rawDate = moment.utc(date);
  }

  public static now() {
    return new DateTimeUtils();
  }

  public static parse(date: string | Date | number, formatString?: string): DateTimeUtils {
    return new DateTimeUtils(date, formatString);
  }

  public static utc(date?: string | Date | number): DateTimeUtils {
    return new DateTimeUtils(date, undefined, true);
  }

  public format(formatString: string): string {
    return moment(this.rawDate).format(formatString);
  }

  public toDate(): Date {
    return this.rawDate.toDate();
  }

  public toNumber(): number {
    return this.rawDate.valueOf();
  }

  public add(duration: DateTimeDurationObject): DateTimeUtils {
    this.rawDate = this.rawDate.add(duration);

    return this;
  }

  public subtract(duration: DateTimeDurationObject): DateTimeUtils {
    this.rawDate = this.rawDate.subtract(duration);

    return this;
  }

  public startOf(unit: DateTimeUnit): DateTimeUtils {
    this.rawDate = this.rawDate.startOf(unit);

    return this;
  }

  public endOf(unit: DateTimeUnit): DateTimeUtils {
    this.rawDate = this.rawDate.endOf(unit);

    return this;
  }

  public toIsoString(): string {
    return this.rawDate.toISOString();
  }

  public isValid(): boolean {
    return this.rawDate.isValid();
  }

  public day(): number {
    return this.rawDate.day();
  }

  public fromNow(): string {
    return this.rawDate.fromNow();
  }

  public isSame(date: string | Date | number): boolean {
    return this.rawDate.isSame(date);
  }

  public isBefore(date?: string | Date | number): boolean {
    return this.rawDate.isBefore(date);
  }

  public difference(date: string | Date | number, unit: DateTimeUnit): number {
    return this.rawDate.diff(date, unit);
  }

  private parseDate(date: string | Date | number, formatString?: string): Moment {
    return formatString
      ? moment(date, formatString, true)
      : moment(date);
  }
}
