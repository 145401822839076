import { DataFilter, DataViewSortingType, TableViewData } from 'api/Types';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import BaseSystemApi from './BaseApi';

export interface WebhookEvent {
  id: string;
  webhook: {
    id: string;
    url: string;
  };
  status: WebhookEventStatus;
  wasAborted?: boolean;
  event: {
    id: string;
    type: string;
    data: Record<string, unknown>;
  }
  retries: number;
  lastRunResponse?: number;
  lastRunAt: Date;
  nextRunAt?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface WebhookEventDataFilter extends DataFilter {
  statuses?: WebhookEventStatus[];
  webhookId?: string;
  eventTypes?: string[];
  dueCreatedDateTo?: Date | null;
  dueCreatedDateFrom?: Date | null;
  documentTypeId?: string | null;
}

export enum WebhookEventSortingField {
  Endpoint = 'endpoint',
  EventType = 'eventType',
  CreatedAt = 'createdAt',
  Status = 'status',
  LastRunAt = 'lastRunAt',
  NextRunAt = 'nextRunAt',
}

export enum WebhookEventStatus {
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export type WebhookEventSortingType = DataViewSortingType<WebhookEventSortingField>;

export interface WebhookEventsApi {
  find(filter: WebhookEventDataFilter, sortingType: WebhookEventSortingType): Promise<TableViewData<WebhookEvent>>;
  retry(id: string): Promise<void>;
  findById(id: string): Promise<WebhookEvent>;
  delete(id: string): Promise<WebhookEvent>;
}

export default class WebhookEventsRestApi extends BaseSystemApi<WebhookEvent>
  implements WebhookEventsApi {
  protected resourceName = 'webhook-events';

  public find(filters: WebhookEventDataFilter, sortingType: WebhookEventSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters.eventTypes) {
      filters.eventTypes.forEach((eventType) => params.append('eventTypes', eventType));
    }

    if (filters.statuses) {
      filters.statuses.forEach((status) => params.append('statuses', status));
    }

    if (filters.webhookId) {
      params.append('webhookId', filters.webhookId);
    }

    if (filters.dueCreatedDateTo) {
      params.append('dueCreatedDateTo', formatDate(filters.dueCreatedDateTo, DateTimeFormat.ISOString));
    }

    if (filters.dueCreatedDateFrom) {
      params.append('dueCreatedDateFrom', formatDate(filters.dueCreatedDateFrom, DateTimeFormat.ISOString))
    }

    if (filters.documentTypeId) {
      params.append('documentTypeId', filters.documentTypeId);
    }

    return this.getResources<TableViewData<WebhookEvent>>(params);
  }

  public findById(id: string) {
    return this.getResourceById(id);
  }

  public retry(id: string) {
    return this.fetch<void>(`/${this.resourceName}/${id}/retry`, 'PUT');
  }

  public delete(id: string) {
    return this.deleteResource<WebhookEvent>(id);
  }
}
