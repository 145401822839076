import BaseSystemApi from './BaseApi';

export interface PollingApi {
  polling(ids: string[]): Promise<string[]>;
}

export default class PollingRestApi extends BaseSystemApi implements PollingApi {
  protected resourceName = 'polling';

  public async polling(ids: string[]): Promise<string[]> {
    const urlSearchParams = new URLSearchParams();

    ids.forEach((id) => urlSearchParams.append('ids', id));

    return this.fetch<string[]>(`/${this.resourceName}?${urlSearchParams.toString()}`, 'GET');
  }
}
