import moment, { Moment } from 'moment';
import MomentAdapter from '@date-io/moment';
import { useMemo } from 'react';

const useUtilsClass = (dateFormat: string) => {
  const UtilsClass = useMemo(() => {
    return class LocalizedUtils extends MomentAdapter {
      getCalendarHeaderText(date: Moment): string {
        return date.format('MMMM, YYYY');
      }

      // rewrite added to add date format that is not normally used by function
      date = (dateString: string | null): Moment => {
        // copied from original function
        if (dateString === null) {
          return (null as unknown) as Moment;
        }

        return dateString ? moment(dateString, dateFormat, true) : moment();
      }

      // the format function has been changed in moment v2, so we rewrite it here to add backwards compatibility
      // should be deleted once we migrate to latest mui
      public format = (date: Moment, format: string) => {
        return moment(date).format(format);
      }

      // moment v2 does not have these methods built in but @material-ui/pickers uses them
      // so to not downgrade the moment library we just adding these methods here
      getDayText(date: Date) {
        return moment(date).format('D');
      }

      public getYearText(date: Date) {
        return moment(date).format('yyyy');
      }

      public getDatePickerHeaderText(date: Date) {
        return moment(date).format('ddd, MMM D');
      }

      public getDateTimePickerHeaderText(date: Date) {
        return moment(date).format('MMM D');
      }

      public getMonthText(date: Date) {
        return moment(date).format('MMMM');
      }

      public getHourText(date: Date, ampm: boolean) {
        return moment(date).format(ampm ? 'hh' : 'HH');
      }

      public getMinuteText(date: Date) {
        return moment(date).format('mm');
      }

      public getSecondText(date: Date) {
        return moment(date).format('ss');
      }
    };
  }, [dateFormat]);

  return UtilsClass;
};

export default useUtilsClass;
