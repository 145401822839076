import React, { FC, useMemo } from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import { User, UsersSortingField, UsersSortingType, UserStatus } from 'api/UserApi';
import UsersTable from 'components/Users/Tables/UsersTable';
import TableLayout from 'components/Table/TableLayout';
import { UsersDashboardFiltersState } from 'GeneralSettings/ui/UsersDashboard/Filters/UsersDashboardFiltersStore';
import Header from 'components/Base/Header';
import Button from 'components/Button';
import WrapperWithLink from 'components/WrapperWithLink';

import styles from './UsersDashboard.module.scss';

interface UsersDashboardProps {
  users: NullableItems<User>;
  paginationProps: PaginationProps;
  onAddUserButtonClick: () => void;
  onEditUser: (user: User) => void;
  onCancelUserInvitation: (user: User) => void;
  onUpdateUserStatus: (user: User, status: UserStatus) => Promise<void>;
  filters: UsersDashboardFiltersState;
  currentUserId?: string;
  onSearch: (search: string) => void;
  onChangeToggle: (active: boolean) => void;
  sortingType: UsersSortingType;
  onSort: (field: UsersSortingField, ascending: boolean) => void;
  addNewUserPageLink: string;
  getEditUserPageLink: (user: User) => string;
  onClearFilters: () => void;
  onFiltersButtonClick: () => void;
}

const UsersDashboard: FC<UsersDashboardProps> = ({
  users,
  paginationProps,
  filters,
  onEditUser,
  onCancelUserInvitation,
  onUpdateUserStatus,
  currentUserId,
  onSearch,
  sortingType,
  onSort,
  getEditUserPageLink,
  onClearFilters,
  onFiltersButtonClick,
  onAddUserButtonClick,
  onChangeToggle,
  addNewUserPageLink,
}) => {
  const renderTable = () => (
    <UsersTable
      users={users}
      sortingType={sortingType}
      onEditUser={onEditUser}
      onUpdateUserStatus={onUpdateUserStatus}
      onCancelUserInvitation={onCancelUserInvitation}
      currentUserId={currentUserId}
      paginationProps={paginationProps}
      onSort={onSort}
      getEditUserPageLink={getEditUserPageLink}
      search={filters.search}
    />
  );

  const renderDashboardHeader = () => {
    return (
      <div className={styles.dashboardHeader}>
        <Header type="h2">Team Members</Header>
        <WrapperWithLink link={addNewUserPageLink} onClick={onAddUserButtonClick}>
          <Button kind="primary">Add User</Button>
        </WrapperWithLink>
      </div>
    );
  };

  const areFiltersApplied = useMemo(() => {
    return filters.isMfaEnabled !== null
      || filters.permissionGroup !== null
  }, [filters.isMfaEnabled, filters.permissionGroup]);

  return (
    <TableLayout
      renderHeader={renderDashboardHeader}
      items={users}
      areItemsLoaded={paginationProps.itemsTotal !== undefined}
      areFiltersApplied={areFiltersApplied}
      search={filters.search}
      noItemsTitle="No users added."
      renderTable={renderTable}
      onClearFilters={onClearFilters}
      onFiltersButtonClick={onFiltersButtonClick}
      onSearch={onSearch}
      toggleSwitchLabel={'Show Inactive'}
      onChangeToggle={onChangeToggle}
      toggleButtonActive={filters.statuses.includes(UserStatus.Inactive)}
      filtersButtonsClassName={styles.filtersButtons}
      showToggleButton
    />
  );
};

export default UsersDashboard;
