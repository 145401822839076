import React, { ChangeEvent, FC, useRef, useState } from 'react';
import validateForgotPasswordForm, {
  ForgotPasswordFormData,
  ValidateForgotPasswordFormResult,
} from './validateForgotPasswordForm';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import AuthInput from '../Inputs/AuthInput/AuthInput';
import AuthButton from '../Inputs/AuthButton/AuthButton';

import styles from './ForgotPasswordForm.module.scss';

interface ForgotPasswordFormProps {
  onSubmit: (data: ForgotPasswordFormData) => Promise<void>;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ onSubmit }) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<ValidateForgotPasswordFormResult>({});

  const handleChangeEmailValue = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({});

    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    const validationResult = validateForgotPasswordForm({ email });

    setErrors(validationResult);

    if (Object.keys(validationResult).length === 0) {
      await onSubmit({ email });
    }
  };

  const handleBlur = () => {
    const validationResult = validateForgotPasswordForm({ email });

    setErrors(validationResult);
  };

  const submitIsDisabled = Object.keys(validateForgotPasswordForm({ email })).length > 0;

  return (
    <div className={styles.container}>
      <AuthInput
        labelTitle="Email Address"
        value={email}
        errorMessage={errors.email}
        containerClassName={styles.email}
        onChange={handleChangeEmailValue}
        onBlur={handleBlur}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        required
      />
      <AuthButton
        kind="primary"
        size="form"
        onClick={handleSubmit}
        fullWidth
        className={styles.submitButton}
        disabled={submitIsDisabled}
      >
        Reset Password
      </AuthButton>
    </div>
  );
};

export default ForgotPasswordForm;
