import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { usePollingApi } from '../providers/ApiServiceProvider';

const POLLING_DELAY = 10000; // 10s

export const usePolling = (
  ids: string[],
  dispatchAfterPolling: () => void,
) => {
  const idsRef = useRef<string[]>(ids);
  const pollingApi = usePollingApi();

  if (!isEqual(idsRef.current, ids)) {
    idsRef.current = ids;
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const pollData = async () => {
      const stillInProcessIds = await pollingApi.polling(ids);

      if (stillInProcessIds.length === ids.length) {
        timer = setTimeout(pollData, POLLING_DELAY);
      } else {
        dispatchAfterPolling();
      }
    };

    if (ids.length > 0) {
      pollData();
    }

    return () => clearTimeout(timer);
  }, [idsRef.current]);
};
