import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/ReduxState';

const AuthorizedOnly: FC = ({ children }) => {
  const session = useSelector((state: ReduxState) => state.sessionInfo);

  if (!session.user || !session.organization || session.user.isMfaIncomplete) {
    return null;
  }

  return (<>{children}</>);
};

export default AuthorizedOnly;
