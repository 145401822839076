import { env } from 'env';
import APIRest from 'api/APIRest';
import AppHeader from 'enums/AppHeader';

export interface CreateAccountInformation {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName?: string;
}

export interface CreateAccountData {
  accountInformation: CreateAccountInformation;
  token: string;
}

export interface SignInData {
  email: string;
  password: string;
  token: string;
}

export interface GoogleSignInData {
  token?: string;
  code?: string;
  inviteToken?: string;
}

export interface AuthApi {
  signIn(data: SignInData): Promise<void>;
  verifyPhoneVerificationCode(code: string): Promise<void>;
  resendPhoneVerificationCode(): Promise<void>;
  sendForgotPasswordLink(email: string): Promise<void>;
  resetPassword(password: string, token: string): Promise<void>;
  createAccount(data: CreateAccountData): Promise<void>;
  resendVerificationEmail(): Promise<void>;
  logout(): Promise<void>;
  verifyEmailVerificationCode(code: string): Promise<void>;
  signInWithGoogle(data: GoogleSignInData): Promise<void>;
}

export default class AuthRestApi extends APIRest implements AuthApi {
  protected origin = env.REACT_APP_API_URL as string;

  public signIn(data: SignInData): Promise<void> {
    return this.fetch('/sessions', 'POST', {
      email: data.email,
      password: data.password,
    }, {
      isBlockingRequest: true,
      headers: {
        [AppHeader.CaptchaToken]: data.token,
      },
    });
  }

  public verifyPhoneVerificationCode(code: string): Promise<void> {
    return this.fetch(`/phone-verification/${code}`, 'PUT');
  }

  public resendPhoneVerificationCode(): Promise<void> {
    return this.fetch('/phone-verification', 'POST', {
      isBlockingRequest: true,
    });
  }

  public sendForgotPasswordLink(email: string): Promise<void> {
    return this.fetch('/reset-password', 'POST', { email });
  }

  public resetPassword(password: string, token: string): Promise<void> {
    return this.fetch('/reset-password', 'PUT', { password, token });
  }

  public createAccount(data: CreateAccountData): Promise<void> {
    return this.fetch('/organizations', 'POST', {
      companyName: data.accountInformation.companyName,
      firstName: data.accountInformation.firstName,
      lastName: data.accountInformation.lastName,
      email: data.accountInformation.email,
      password: data.accountInformation.password,
    }, {
      headers: {
        [AppHeader.CaptchaToken]: data.token,
      },
    });
  }

  public resendVerificationEmail(): Promise<void> {
    return this.fetch('/email-verification', 'POST');
  }

  public logout(): Promise<void> {
    return this.fetch('/sessions', 'DELETE');
  }

  public verifyEmailVerificationCode(code: string): Promise<void> {
    return this.fetch(`/email-verification/${code}`, 'PUT');
  }

  public signInWithGoogle(data: GoogleSignInData): Promise<void> {
    return this.fetch('/google-sessions', 'POST', data);
  }
}
