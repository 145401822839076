import { createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import { DocumentTypeField } from 'api/DocumentTypeFieldApi';
import { DocumentTypeFieldsActionType } from './DocumentTypeFieldsActionType';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import {
  batchCreateDocumentTypeFields,
  batchUpdateDocumentTypeFields,
  createDocumentTypeField,
  getDocumentTypeFields,
  updateDocumentTypeField,
} from './Thunks';

export interface DocumentTypeFieldsState {
  fieldsById: Record<string, DocumentTypeField>;
  isLoading: boolean;
}

export const initialState: DocumentTypeFieldsState = {
  fieldsById: {},
  isLoading: false,
};

export const resetDocumentTypeFields = createAction(DocumentTypeFieldsActionType.ResetDocumentTypeFields);

const documentTypeFieldsReducer = createReducer<DocumentTypeFieldsState>(initialState, (builder) => {
  builder.addCase(getDocumentTypeFields.fulfilled, (state, action) => {
    state.fieldsById = normalizeEntityArray(action.payload);
    state.isLoading = false;
  });

  builder.addCase(getDocumentTypeFields.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(resetDocumentTypeFields, () => initialState);

  builder.addMatcher(isAnyOf(
    createDocumentTypeField.fulfilled,
    updateDocumentTypeField.fulfilled,
  ), (state, action) => {
    state.fieldsById[action.payload.id] = action.payload;
  });

  builder.addMatcher(isAnyOf(
    batchCreateDocumentTypeFields.fulfilled,
    batchUpdateDocumentTypeFields.fulfilled,
  ), (state, action) => {
    state.fieldsById = {
      ...state.fieldsById,
      ...normalizeEntityArray(action.payload),
    };
  });
});

export default documentTypeFieldsReducer;
