import useSubscriptionStatus from './useSubscriptionStatus';

export enum FreeTrialStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

const useFreeTrialStatus = () => {
  const {
    hasDefaultPaymentMethod,
    isLegalAddressProvided,
  } = useSubscriptionStatus();

  return hasDefaultPaymentMethod && isLegalAddressProvided ? FreeTrialStatus.ACTIVE : FreeTrialStatus.INACTIVE;
};

export default useFreeTrialStatus;
