import { DashboardActionType, IBaseCreateDashboardSliceParams, createDashboardSlice } from 'DashboardSlice/createDashboardSlice';
import createEndpointEventsTableFiltersSlice, {
} from './Filters/EndpointEventsTableFiltersStore';
import EndpointEventsTableActionType from './EndpointEventsTableActionType';
import createEndpointEventsTablePagination, { WEBHOOK_EVENTS_PER_PAGE_DEFAULT } from './Pagination';
import { WebhookEvent, WebhookEventSortingField, WebhookEventSortingType } from 'api/WebhookEventsApi';
import { BaseDashboardFiltersState } from 'DashboardSlice/createDashboardFiltersSlice';
import { WebhookEventsActionOrigin, getWebhookEvents } from 'Webhooks/Events/Thunks';
import { DataFilter } from 'api/Types';

const endpointEventsTableActionOrigin = WebhookEventsActionOrigin.WebhookEndpointEventsTable;
const endpointEventsTableStatePath = ['generalSettings', 'ui', 'webhooksDashboards', 'endpointDetails', 'endpointEventsTable'];

export const createEndpointEventsTableSlice = ({
  namespace,
  path,
  actionOrigins,
}: IBaseCreateDashboardSliceParams) => {
  const {
    actions: filtersActions,
    getInitialState: getFiltersInitialState,
    reducer: filtersReducer,
  } = createEndpointEventsTableFiltersSlice(namespace);

  const pagination = createEndpointEventsTablePagination(path);

  const actionTypes = {
    [DashboardActionType.ResetState]: EndpointEventsTableActionType.ResetState,
    [DashboardActionType.ChangeSortingType]: EndpointEventsTableActionType.ChangeSortingType,
  };

  const defaultEndpointEventsTableSlice = createDashboardSlice<
    WebhookEvent,
    DataFilter,
    WebhookEventSortingType,
    BaseDashboardFiltersState,
    typeof actionTypes
  >({
    namespace,
    path,
    actionOrigins,
    itemsPerPage: WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
    defaultSorting: { field: WebhookEventSortingField.CreatedAt, ascending: false },
    getItems: getWebhookEvents,
    actionTypes,
    filtersAndSortingProps: [
      'eventTypes',
      'statuses',
      'dueCreatedDateRange',
      'documentTypeId',
    ],
    filtersReducer,
    getFiltersInitialState,
    filtersActions,
  });

  const {
    actions,
    reducer,
    filtersAndSortingSaver,
    getInitialState,
  } = defaultEndpointEventsTableSlice;

  return {
    actions,
    reducer: pagination.wrapReducer(reducer),
    pagination,
    filtersAndSortingSaver,
    getInitialState,
    filtersActions,
  }
};

const EndpointEventsTableSlice = createEndpointEventsTableSlice({
  namespace: 'EndpointEventsTable',
  path: endpointEventsTableStatePath,
  actionOrigins: [endpointEventsTableActionOrigin],
});

const {
  actions,
  reducer,
  pagination,
  filtersAndSortingSaver,
  getInitialState,
  filtersActions,
} = EndpointEventsTableSlice;

export {
  getInitialState,
  filtersAndSortingSaver,
  pagination,
  endpointEventsTableActionOrigin,
  endpointEventsTableStatePath,
}

export const {
  resetState,
  changeSortingType,
} = actions;

export const {
  changeSearchValue,
} = filtersActions;

export default reducer;
